import * as actionTypes from '../action-types'
import { TAuditLog } from '../action-types'

export interface IAuditLogsState {
  auditLogs: TAuditLog[] | []
  areAuditLogsLoading: boolean
  fetchAuditLogsError: actionTypes.TNetworkError | null

  tableSorterState: actionTypes.TSorterState | undefined
  tableFilterState: actionTypes.TFilterState
}

const initialState: IAuditLogsState = {
  auditLogs: [],
  areAuditLogsLoading: false,
  fetchAuditLogsError: null,

  tableSorterState: undefined,
  tableFilterState: undefined,
}

export default function auditLogsReducer(
  state = initialState,
  action: actionTypes.AuditLogsActionTypes
): IAuditLogsState {
  switch (action.type) {
    case actionTypes.FETCH_AUDIT_LOGS_REQUESTED: {
      return {
        ...state,
        fetchAuditLogsError: null,
        areAuditLogsLoading: true,
      }
    }

    case actionTypes.FETCH_AUDIT_LOGS_SUCCEEDED: {
      return {
        ...state,
        fetchAuditLogsError: null,
        areAuditLogsLoading: false,
        auditLogs: action.auditLogs,
      }
    }

    case actionTypes.FETCH_AUDIT_LOGS_ERROR: {
      return {
        ...state,
        fetchAuditLogsError: action.error,
        areAuditLogsLoading: false,
      }
    }

    case actionTypes.SET_AUDIT_LOGS_TABLE_FILTER_STATE: {
      return {
        ...state,
        tableFilterState: action.tableFilterState,
      }
    }

    case actionTypes.SET_AUDIT_LOGS_TABLE_SORTER_STATE: {
      return {
        ...state,
        tableSorterState: action.tableSorterState,
      }
    }

    default:
      return state
  }
}
