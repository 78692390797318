import { AxiosResponse } from 'axios'
import { TAdminPayload } from './admin'

export const LOGIN_REQUESTED = 'LOGIN_REQUESTED'
export const LOGIN_SUCCEEDED = 'LOGIN_SUCCEEDED'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGIN_CANCELLED = 'LOGIN_CANCELLED'
export const SAVE_AUTH_DATA = 'SAVE_AUTH_DATA'

export const LOGOUT_REQUESTED = 'LOGOUT_REQUESTED'
export const DELETE_TOKEN = 'DELETE_TOKEN'
export const LOGOUT_SUCCEEDED = 'LOGOUT_SUCCEEDED'

export const AUTHENTICATION_REQUESTED = 'AUTHENTICATION_REQUESTED'
export const AUTHENTICATION_SUCCEEDED = 'AUTHENTICATION_SUCCEEDED'
export const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR'
export const AUTHENTICATION_CANCELLED = 'AUTHENTICATION_CANCELLED'

export const REQUEST_PASSWORD_RESET_REQUESTED = 'REQUEST_PASSWORD_RESET_REQUESTED'
export const REQUEST_PASSWORD_RESET_SUCCEEDED = 'REQUEST_PASSWORD_RESET_SUCCEEDED'
export const REQUEST_PASSWORD_RESET_ERROR = 'REQUEST_PASSWORD_RESET_ERROR'
export const REQUEST_PASSWORD_RESET_CANCELLED = 'REQUEST_PASSWORD_RESET_CANCELLED'

export const PASSWORD_RESET_TOKEN_CHECK_REQUESTED = 'PASSWORD_RESET_TOKEN_CHECK_REQUESTED'
export const PASSWORD_RESET_TOKEN_CHECK_SUCCEEDED = 'PASSWORD_RESET_TOKEN_CHECK_SUCCEEDED'
export const PASSWORD_RESET_TOKEN_CHECK_ERROR = 'PASSWORD_RESET_TOKEN_CHECK_ERROR'
export const PASSWORD_RESET_TOKEN_CHECK_CANCELLED = 'PASSWORD_RESET_TOKEN_CHECK_CANCELLED'

export const PASSWORD_RESET_REQUESTED = 'PASSWORD_RESET_REQUESTED'
export const PASSWORD_RESET_SUCCEEDED = 'PASSWORD_RESET_SUCCEEDED'
export const PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR'
export const PASSWORD_RESET_CANCELLED = 'PASSWORD_RESET_CANCELLED'

export type TNetworkError = null | AxiosResponse<any>

export type TLoginPayload = { tenant: string; user: string; password: string }

export type TRequestPasswordResetPayload = { tenant: string; user: string }

export type TPasswordResetPayload = {
  tenant: string
  id: string
  password: string
  passwordConfirmation: string
}

export type TPasswordResetTokenCheckPayload = {
  tenant: string
  id: string
  token: string
}

export type TUserRoleSpecificReadWrite =
  | 'Suppliers_read'
  | 'Suppliers_write'
  | 'BomElements_read'
  | 'BomElements_write'
  | 'Offers_read'
  | 'Offers_write'
  | 'Boms_read'
  | 'Boms_write'
  | 'PurchasePlans_read'
  | 'PurchasePlans_write'
  | 'Orders_read'
  | 'Orders_write'
  | 'Producers_read'
  | 'Producers_write'
  | 'Services_read'
  | 'Services_write'
  | 'ImplementationCosts_read'
  | 'ImplementationCosts_write'
  | 'Warehouses_read'
  | 'Warehouses_write'
  | 'AuditLogs_read'
  | 'Users_read'
  | 'Users_write'

export type TAuthData = null | {
  token: string
  userId: string
  username: string
  roles: TUserRoleSpecificReadWrite[]
}

// LOGIN_REQUESTED

export interface LoginRequestedAction {
  type: typeof LOGIN_REQUESTED
  payload: TLoginPayload
}

export interface LoginSucceeded {
  type: typeof LOGIN_SUCCEEDED
}

export interface LoginError {
  type: typeof LOGIN_ERROR
  error: TNetworkError
}

export interface LoginCancelled {
  type: typeof LOGIN_CANCELLED
}

// SAVE_AUTH_DATA

export interface SaveAuthData {
  type: typeof SAVE_AUTH_DATA
  authData: TAuthData
}

// LOGOUT_REQUESTED

export interface LogoutRequestedAction {
  type: typeof LOGOUT_REQUESTED
}

export interface LogoutSucceeded {
  type: typeof LOGOUT_SUCCEEDED
}

// DELETE_TOKEN

export interface DeleteTokenAction {
  type: typeof DELETE_TOKEN
}

// AUTHENTICATION_REQUESTED

export interface AuthenticationRequestedAction {
  type: typeof AUTHENTICATION_REQUESTED
  payload: TAdminPayload
}

export interface AuthenticationSucceeded {
  type: typeof AUTHENTICATION_SUCCEEDED
}

export interface AuthenticationError {
  type: typeof AUTHENTICATION_ERROR
  error: TNetworkError
}

export interface AuthenticationCancelled {
  type: typeof AUTHENTICATION_CANCELLED
}

// REQUEST_PASSWORD_RESET_REQUESTED

export interface RequestPasswordResetAction {
  type: typeof REQUEST_PASSWORD_RESET_REQUESTED
  payload: TRequestPasswordResetPayload
}

export interface RequestPasswordResetSucceeded {
  type: typeof REQUEST_PASSWORD_RESET_SUCCEEDED
}

export interface RequestPasswordResetError {
  type: typeof REQUEST_PASSWORD_RESET_ERROR
  error: TNetworkError
}

export interface RequestPasswordResetCancelled {
  type: typeof REQUEST_PASSWORD_RESET_CANCELLED
}

// PASSWORD_RESET_TOKEN_CHECK_REQUESTED

export interface PasswordResetTokenCheckAction {
  type: typeof PASSWORD_RESET_TOKEN_CHECK_REQUESTED
  payload: TPasswordResetTokenCheckPayload
}

export interface PasswordResetTokenCheckSucceeded {
  type: typeof PASSWORD_RESET_TOKEN_CHECK_SUCCEEDED
}

export interface PasswordResetTokenCheckError {
  type: typeof PASSWORD_RESET_TOKEN_CHECK_ERROR
  error: TNetworkError
}

export interface PasswordResetTokenCheckCancelled {
  type: typeof PASSWORD_RESET_TOKEN_CHECK_CANCELLED
}

// PASSWORD_RESET_REQUESTED

export interface PasswordResetAction {
  type: typeof PASSWORD_RESET_REQUESTED
  payload: TPasswordResetPayload
}

export interface PasswordResetSucceeded {
  type: typeof PASSWORD_RESET_SUCCEEDED
}

export interface PasswordResetError {
  type: typeof PASSWORD_RESET_ERROR
  error: TNetworkError
}

export interface PasswordResetCancelled {
  type: typeof PASSWORD_RESET_CANCELLED
}

export type AuthActionTypes =
  | LoginRequestedAction
  | LoginSucceeded
  | LoginError
  | LoginCancelled
  | SaveAuthData
  | LogoutRequestedAction
  | DeleteTokenAction
  | LogoutSucceeded
  | AuthenticationRequestedAction
  | AuthenticationSucceeded
  | AuthenticationError
  | AuthenticationCancelled
  | RequestPasswordResetAction
  | RequestPasswordResetSucceeded
  | RequestPasswordResetError
  | RequestPasswordResetCancelled
  | PasswordResetTokenCheckAction
  | PasswordResetTokenCheckSucceeded
  | PasswordResetTokenCheckError
  | PasswordResetTokenCheckCancelled
  | PasswordResetAction
  | PasswordResetSucceeded
  | PasswordResetError
  | PasswordResetCancelled
