import React, { useState, useEffect } from 'react'
import { Card, CardBody, Container, Row, Spinner } from 'reactstrap'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form } from 'formik'
import { CButton, CModal, CModalBody, CModalHeader } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilCheckCircle } from '@coreui/icons'
import * as Yup from 'yup'

import {
  BasicFormField,
  ThreeDots,
  CustomErrorMessage,
  PasswordResetErrorPage,
} from '../../components'
import { TRootState } from '../../store/reducers'
import * as actionTypes from '../../store/action-types'

export const PasswordReset: React.FC<{}> = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tenant, id } = useParams<{ tenant: string; id: string }>()
  const passwordResetId = id

  const [didFormValidationOccur, setDidFormValidationOccur] = useState(false)

  const authState = useSelector((state: TRootState) => state.auth)

  const minimumPasswordLength = 12

  const passwordRegExp = new RegExp(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\~\(\)\'\_\+\=\.-])/
  )

  useEffect(() => {
    if (!passwordResetId) {
      history.replace(`/${tenant}/login`)
    }

    dispatch({
      type: actionTypes.PASSWORD_RESET_TOKEN_CHECK_REQUESTED,
      payload: { token: passwordResetId, tenant: tenant },
    })
  }, [passwordResetId])

  // This is a hack because close button in modal acts as a submit button
  useEffect(() => {
    const closeModalButton = document.getElementsByClassName('close')

    if (closeModalButton[0]) {
      closeModalButton[0].setAttribute('type', 'button')
    }
  }, [authState.isPasswordResetSuccessful])

  const PasswordResetSchema = Yup.object().shape({
    password: Yup.string()
      .required('To pole jest wymagane!')
      .min(minimumPasswordLength, `Wymagane minimum ${minimumPasswordLength} znaków!`)
      .matches(passwordRegExp, 'Wymagane znaki: specjalne, liczby, duże i małe litery!'),
    passwordConfirmation: Yup.string()
      .required('To pole jest wymagane!')
      .min(minimumPasswordLength, `Wymagane minimum ${minimumPasswordLength} znaków!`)
      .oneOf([Yup.ref('password'), ''], 'Hasła muszą się zgadzać!'),
  })

  const getPasswordResetErrorMessage = (status: number | undefined | null) => {
    switch (status) {
      case 401:
        return 'Brak uwierzytelnienia!'
      case 403:
        return 'Sesja wygasła!'
      case 404:
        return 'Błąd 404! Nie odnaleziono zasobu lub zapytania do serwera!'
      case 405:
        return 'Błąd 405! Zastosowano niedozwoloną metodę HTTP!'
      case 410:
        return 'Sesja zmiany hasła wygasła!'
      case 500:
        return '500! Wystąpił wewnętrzny błąd serwera!'
      default:
        return `Wystąpił  ${status ? `błąd ${status}` : 'nieznany błąd serwera'}!`
    }
  }

  return !authState.isPasswordResetTokenCheckPending &&
    !authState.isPasswordResetTokenValid &&
    authState.passwordResetTokenCheckError ? (
    <PasswordResetErrorPage status={authState.passwordResetTokenCheckError.status} />
  ) : (
    <Container className="app vh-100 d-flex flex-column align-items-center justify-content-center">
      {authState.isPasswordResetTokenCheckPending &&
        !authState.isPasswordResetTokenValid &&
        !authState.passwordResetTokenCheckError && <Spinner />}

      {!authState.isPasswordResetTokenCheckPending &&
        authState.isPasswordResetTokenValid &&
        !authState.passwordResetTokenCheckError && (
          <Row>
            <Card className="pb-2 pt-0 px-3 mb-5" style={{ width: '500px' }}>
              <CardBody>
                <Formik
                  initialValues={{
                    password: '',
                    passwordConfirmation: '',
                  }}
                  validationSchema={PasswordResetSchema}
                  onSubmit={(values) => {
                    setDidFormValidationOccur(true)

                    dispatch({
                      type: actionTypes.PASSWORD_RESET_REQUESTED,
                      payload: {
                        tenant,
                        id: passwordResetId,
                        password: values.password,
                        passwordConfirmation: values.passwordConfirmation,
                      },
                    })
                  }}
                  validateOnBlur={false}
                  validateOnChange={didFormValidationOccur}
                >
                  {({ values, errors, resetForm }) => (
                    <Form
                      onChange={() => {
                        if (errors.passwordConfirmation) {
                          setDidFormValidationOccur(true)
                        }

                        if (!errors.passwordConfirmation && authState.passwordResetError) {
                          dispatch({ type: actionTypes.PASSWORD_RESET_CANCELLED })
                        }
                      }}
                    >
                      <h2 className="mb-3 text-center">Resetowanie hasła</h2>
                      <p className="mb-3 text-muted text-center">
                        Wprowadź nowe hasło do konta Polysystem:{' '}
                        <strong>
                          {tenant.replace(/\b\w/g, (letter: string) => letter.toUpperCase())}
                        </strong>
                      </p>
                      <p className={`text-center ${!authState?.passwordResetError && 'mb-4'}`}>
                        Najlepsze hasła to sentencje albo ciąg znaków składający się ze znaków
                        specjalnych, liczb, dużych i małych liter
                      </p>

                      {!errors?.passwordConfirmation && authState?.passwordResetError && (
                        <CustomErrorMessage
                          wrapperClassNames="my-3"
                          customErrorMessageText={getPasswordResetErrorMessage(
                            authState?.passwordResetError?.status ||
                              authState?.passwordResetError?.data?.status
                          )}
                        />
                      )}

                      <BasicFormField
                        fieldId="password-reset-password"
                        fieldLabel="Nowe hasło"
                        fieldIcon="icon-lock"
                        formikFieldName="password"
                        fieldValue={values.password}
                        fieldError={errors.password}
                        fieldType="password"
                        placeholder="Nowe hasło"
                        inputGroupCustomClasses="mb-3"
                      />

                      <BasicFormField
                        fieldId="password-reset-password-confirmation"
                        fieldLabel="Potwierdź nowe hasło"
                        fieldIcon="icon-lock"
                        formikFieldName="passwordConfirmation"
                        fieldValue={values.passwordConfirmation}
                        fieldError={errors.passwordConfirmation}
                        fieldType="password"
                        placeholder="Potwierdź nowe hasło"
                        inputGroupCustomClasses="mb-4 pb-1"
                      />

                      <div className="pt-3 d-flex justify-content-center w-100">
                        <CButton
                          color="primary"
                          className="px-1 mr-3 w-100"
                          variant="outline"
                          type="button"
                          onClick={() => {
                            dispatch({
                              type: actionTypes.PASSWORD_RESET_CANCELLED,
                            })

                            history.push(`/${tenant}/login`)
                          }}
                        >
                          Anuluj
                        </CButton>
                        <CButton
                          color="info"
                          className="px-5 ml-3 w-100"
                          type="submit"
                          disabled={
                            authState.isPasswordResetPending ||
                            (didFormValidationOccur &&
                              (errors.password || errors.passwordConfirmation))
                              ? true
                              : false
                          }
                        >
                          {authState.isPasswordResetPending ? (
                            <>
                              Zapisywanie
                              <ThreeDots />
                            </>
                          ) : (
                            'Zapisz'
                          )}
                        </CButton>
                      </div>

                      <CModal
                        onClose={() => {
                          dispatch({
                            type: actionTypes.PASSWORD_RESET_CANCELLED,
                          })

                          resetForm()
                        }}
                        closeOnBackdrop={false}
                        show={authState.isPasswordResetSuccessful}
                        backdrop
                        addContentClass="py-4 px-5 mb-5"
                        centered={true}
                        style={{ maxWidth: '600px' }}
                      >
                        <CModalHeader className="c-modal-header-grid" closeButton>
                          <div
                            style={{ borderBottom: '1px solid #bbb' }}
                            className="text-center d-flex flex-column align-items-center mt-2"
                          >
                            <h2>Nowe hasło zostało zapisane!</h2>
                            <div className="d-flex mt-3 mb-4">
                              <CIcon
                                icon={cilCheckCircle}
                                customClassName="request-password-reset-success-modal-icon background-color-success"
                              />
                            </div>
                          </div>
                        </CModalHeader>
                        <CModalBody className="text-center">
                          <h5 className="mt-2 mb-3">Zaloguj się używając nowego hasła</h5>
                          <p>Kliknij na przycisk poniżej by przejść do strony logowania</p>

                          {authState.passwordResetError && (
                            <CustomErrorMessage
                              wrapperClassNames="mb-0 mt-4"
                              customErrorMessageText={getPasswordResetErrorMessage(
                                authState?.passwordResetError?.status ||
                                  authState?.passwordResetError?.data?.status
                              )}
                            />
                          )}

                          <div className="pt-4 d-flex justify-content-center w-100">
                            <CButton
                              color="info"
                              className="px-2 w-50"
                              type="button"
                              onClick={() => {
                                dispatch({
                                  type: actionTypes.PASSWORD_RESET_CANCELLED,
                                })

                                history.push(`/${tenant}/login`)
                              }}
                            >
                              Strona logowania
                            </CButton>
                          </div>
                        </CModalBody>
                      </CModal>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Row>
        )}
    </Container>
  )
}
