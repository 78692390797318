import * as actionTypes from '../store/action-types'
import { globalCurrencyFallback } from './constants'

export const currencyExchanger = (
  initialAmount: number | null,
  fromCurrency: actionTypes.TCurrencyCode | undefined | null,
  toCurrency: actionTypes.TCurrencyCode | undefined | null,
  rates: actionTypes.TFixerExchangeRate
): number => {
  function round(num: number) {
    const base = 10 ** 2
    return parseFloat((Math.round(num * base) / base).toFixed(2))
  }

  if (initialAmount && fromCurrency && toCurrency) {
    if (fromCurrency !== globalCurrencyFallback) {
      const baseCurrencyAmount = initialAmount * rates[fromCurrency]

      return round(baseCurrencyAmount / rates[toCurrency])
    } else {
      return round(initialAmount / rates[toCurrency])
    }
  } else {
    return 0
  }
}
