import React, { useState, useEffect, useCallback, ChangeEvent, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { Container, InputGroup, InputGroupAddon, InputGroupText, Spinner } from 'reactstrap'
import { Formik, Form, ErrorMessage, Field, FastField } from 'formik'
import * as Yup from 'yup'
import { CCard, CCardBody, CButton, CDataTable, CLabel } from '@coreui/react'
import { Select } from '@material-ui/core'
import NumberFormat from 'react-number-format'
import { confirmAlert } from 'react-confirm-alert'

import * as actionTypes from '../../../store/action-types'
import { TRootState } from '../../../store/reducers'
import {
  BasicFormField,
  CustomErrorMessage,
  ThreeDots,
  FormActionCancelButton,
  ConfirmActionModal,
} from '../../../components'
import {
  getErrorMessageFromStatus,
  globalThousandSeparator,
  globalDecimalSeparator,
  inputLabelSpacingBottom,
  inputFieldSpacingBottom,
  toDateInputValue,
  successMessageDuration,
  preventNavigationChange,
  convertNumericStringToNumber,
} from '../../../utils'

export const WaresReplenishment: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tenant, id } = useParams<{
    tenant: string
    id: string
  }>()
  const paramsManagedWarehouseId = id

  const [didFormValidationOccur, setDidFormValidationOccur] = useState(false)
  const [isWaresReplenishmentModifiedAndUnsaved, setIsWaresReplenishmentModifiedAndUnsaved] =
    useState(false)

  const authState = useSelector((state: TRootState) => state.auth)
  const warehousesState = useSelector((state: TRootState) => state.warehouses)

  const [waresReplenishmentFilterState, setWaresReplenishmentFilterState] =
    useState<actionTypes.TFilterState>(undefined)

  const [waresReplenishmentSorterState, setWaresReplenishmentSorterState] =
    useState<actionTypes.TSorterState>({ column: '', asc: true })

  const closeWaresReplenishment = useCallback(() => {
    history.push(`/${tenant}/admin/warehouse-management/${paramsManagedWarehouseId}`)
  }, [dispatch, history, tenant, paramsManagedWarehouseId])

  const generateBomElementsListFromDelivery = (
    bomElementsInDelivery: actionTypes.IBomElementInWarehouseDelivery[]
  ) =>
    bomElementsInDelivery?.map(
      (bomElement: actionTypes.IBomElementInWarehouseDelivery, bomElementIndex: number) => ({
        ...bomElement,
        position: `${bomElementIndex + 1}.`,
      })
    )

  const isWarehouseEditable = authState?.authData?.roles?.includes('Warehouses_write')

  const emptyDeliveryItemsInForm = []
  const selectDeliveryPlaceholderValue = ''

  const managedWarehouseTypes: actionTypes.TWarehouseType[] | undefined = useMemo(
    () =>
      warehousesState?.warehouses?.find(
        (warehouse: actionTypes.TWarehouseLight) =>
          warehouse.id === Number(paramsManagedWarehouseId)
      )?.types,
    [warehousesState.warehouses, paramsManagedWarehouseId]
  )

  useEffect(() => {
    if (managedWarehouseTypes && !managedWarehouseTypes?.includes(actionTypes.waresTypeNumber)) {
      closeWaresReplenishment()
    }
  }, [managedWarehouseTypes, history])

  // Fetch warehouse deliveries if they were not fetched before
  useEffect(() => {
    if (
      location.pathname.includes('wares-replenishment') &&
      tenant &&
      authState?.authData &&
      authState?.authData?.roles?.includes('Warehouses_read') &&
      isWarehouseEditable &&
      !warehousesState?.managedWarehouseDeliveries
    ) {
      dispatch({
        type: actionTypes.FETCH_WAREHOUSE_MANAGEMENT_DELIVERIES_REQUESTED,
        payload: { tenant: tenant, token: authState.authData?.token, id: paramsManagedWarehouseId },
      })
    }
  }, [
    dispatch,
    tenant,
    authState.authData,
    authState.isAuthenticated,
    authState.isAuthenticationPending,
    location,
    paramsManagedWarehouseId,
    warehousesState?.managedWarehouseDeliveries,
  ])

  // Prevent navigation back and forth plus reload if modified
  useEffect(() => {
    preventNavigationChange(
      history,
      isWaresReplenishmentModifiedAndUnsaved,
      isWarehouseEditable,
      'replenishment',
      paramsManagedWarehouseId,
      ''
    )

    // Without pathname in location there is no tab change detection
  }, [location.pathname, history, isWaresReplenishmentModifiedAndUnsaved, isWarehouseEditable])

  // Close wares replenishment if document was generated and fetch warehouse deliveries again
  useEffect(() => {
    if (warehousesState.isWaresReplenishmentDocumentGenerated) {
      setIsWaresReplenishmentModifiedAndUnsaved(false)

      if (
        tenant &&
        authState?.authData &&
        authState?.authData?.roles?.includes('Warehouses_read')
      ) {
        dispatch({
          type: actionTypes.FETCH_WAREHOUSE_MANAGEMENT_DELIVERIES_REQUESTED,
          payload: {
            tenant: tenant,
            token: authState.authData?.token,
            id: paramsManagedWarehouseId,
          },
        })
      }

      setTimeout(() => {
        closeWaresReplenishment()
      }, successMessageDuration)
    }
  }, [warehousesState.isWaresReplenishmentDocumentGenerated])

  // Unmount Component
  useEffect(() => {
    return () => {
      dispatch({
        type: actionTypes.SET_DELIVERY_FROM_ORDER_IN_WARES_REPLENISHMENT,
        deliveryFromOrder: undefined,
      })

      dispatch({
        type: actionTypes.CLOSE_WARES_REPLENISHMENT,
      })
    }
  }, [])

  const WaresReplenishmentSchema = Yup.object().shape({
    deliveryDocument: Yup.string().required('To pole jest wymagane!'),
    selectedDelivery: Yup.string().required('Wybierz dostawę!'),
  })

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center">
      <CCard>
        <CCardBody className="warehouse-details">
          <h4 className="text-center mb-4">
            {authState?.authData?.roles?.includes('Warehouses_write') ? 'Przyjęcie towarów' : ''}
          </h4>

          <div className="d-flex justify-content-end list-fixed-create-new-button-wrapper">
            <FormActionCancelButton closeFunction={closeWaresReplenishment} closeAction={''} />
          </div>
          {warehousesState.areManagedWarehouseDeliveriesLoading ? (
            <div
              style={{
                height: '300px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Spinner />
            </div>
          ) : (
            <Formik
              initialValues={{
                deliveryDate: toDateInputValue(new Date().toISOString()),
                deliveryDocument: '',
                selectedDelivery:
                  JSON.stringify(warehousesState?.deliveryFromOrderInWaresReplenishment) || '',
                deliveryItems: warehousesState?.deliveryFromOrderInWaresReplenishment?.bomElements
                  ? generateBomElementsListFromDelivery(
                      warehousesState?.deliveryFromOrderInWaresReplenishment?.bomElements
                    )
                  : emptyDeliveryItemsInForm,
              }}
              validationSchema={WaresReplenishmentSchema}
              onSubmit={(values) => {
                if (isWarehouseEditable) {
                  setDidFormValidationOccur(true)
                  setIsWaresReplenishmentModifiedAndUnsaved(false)

                  const replenishmentDetails:
                    | actionTypes.TMaterialsOrWaresInReplenishment[]
                    | undefined = values?.deliveryItems?.map(
                    (replenishmentItem: actionTypes.IBomElementInWarehouseDelivery) => {
                      return {
                        // There is no orderLineId in the Warehouse Delivery BE
                        orderDeliveryOrderLineId: null,
                        quantity: Number(replenishmentItem?.receivedQuantity) || null,
                      }
                    }
                  )

                  const selectedDelivery: actionTypes.IWarehouseManagementDelivery =
                    values?.selectedDelivery ? JSON.parse(values?.selectedDelivery) : undefined

                  dispatch({
                    type: actionTypes.GENERATE_WARES_REPLENISHMENT_DOCUMENT_REQUESTED,
                    payload: {
                      tenant: tenant,
                      token: authState.authData?.token,
                      documentData: {
                        warehouseId: Number(paramsManagedWarehouseId),
                        date: values?.deliveryDate || '',
                        referenceNumber: selectedDelivery?.orderNumber || '',
                        replenishmentDetails: replenishmentDetails,
                        orderDeliveryId: selectedDelivery?.id || 0,
                      },
                    },
                  })
                }
              }}
              enableReinitialize={!isWaresReplenishmentModifiedAndUnsaved}
              validateOnBlur={false}
              validateOnChange={didFormValidationOccur}
            >
              {({ values, errors, setFieldValue, handleChange }) => (
                <Form>
                  {/*
                   * Display Network Error Message
                   */}

                  {!isWaresReplenishmentModifiedAndUnsaved &&
                    warehousesState?.waresReplenishmentDocumentGenerateError && (
                      <CustomErrorMessage
                        wrapperClassNames="mb-4"
                        customErrorMessageText={getErrorMessageFromStatus(
                          'create',
                          warehousesState?.waresReplenishmentDocumentGenerateError?.status,
                          'danych przyjęcia towarów'
                        )}
                      />
                    )}

                  <div className="settings-view-double-grid-fields">
                    <BasicFormField
                      fieldId="warehouse-management-wares-replenishment-delivery-date"
                      fieldLabel="Data dostawy"
                      fieldIcon="cil-calendar"
                      formikFieldName="deliveryDate"
                      fieldValue={values.deliveryDate}
                      fieldError={errors.deliveryDate}
                      fieldType="date"
                      placeholder=""
                    />

                    <BasicFormField
                      fieldId="warehouse-management-wares-replenishment-delivery-document"
                      fieldLabel="Dokument dostawy"
                      fieldIcon="cil-short-text"
                      formikFieldName="deliveryDocument"
                      fieldValue={values.deliveryDocument}
                      fieldError={errors.deliveryDocument}
                      fieldType="text"
                      placeholder="Wpisz numer WZ"
                    />
                  </div>

                  <div className="mb-4">
                    <CLabel
                      htmlFor="warehouse-management-wares-replenishment-delivery-id"
                      className={inputLabelSpacingBottom}
                    >
                      Zamówienie (Dostawa)
                    </CLabel>
                    <InputGroup
                      id="warehouse-management-wares-replenishment-delivery-id"
                      className={`${inputFieldSpacingBottom} dropdown-selector`}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText
                          className={errors.selectedDelivery && 'text-danger input-error-icon'}
                        >
                          <i className="cil-truck"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <ErrorMessage
                        name="selectedDelivery"
                        component="span"
                        className="text-danger input-error-message"
                      />
                      <Field
                        as={Select}
                        name="selectedDelivery"
                        value={values.selectedDelivery}
                        variant="outlined"
                        native
                        className={`item-selector element-selector w-100 text-center ${
                          String(values.selectedDelivery) === selectDeliveryPlaceholderValue &&
                          'element-selector-placeholder'
                        } ${errors.selectedDelivery && 'invalid-native-selector'}`}
                        onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                          const changeDelivery = (selectedDeliveryString: string) => {
                            setIsWaresReplenishmentModifiedAndUnsaved(false)
                            setDidFormValidationOccur(false)

                            if (selectedDeliveryString) {
                              // This is important to change the displayed value in select
                              event.target.value = selectedDeliveryString

                              const selectedDelivery: actionTypes.IWarehouseManagementDelivery =
                                JSON.parse(selectedDeliveryString)

                              if (selectedDelivery) {
                                const bomElementsInDelivery = generateBomElementsListFromDelivery(
                                  selectedDelivery?.bomElements
                                )

                                setFieldValue('deliveryItems', bomElementsInDelivery)
                              }
                            } else {
                              setFieldValue('deliveryItems', emptyDeliveryItemsInForm)
                            }

                            return handleChange(event)
                          }

                          if (isWaresReplenishmentModifiedAndUnsaved) {
                            confirmAlert({
                              closeOnEscape: true,
                              message: String(event?.target?.value),
                              customUI: ({ message, onClose }) => {
                                return (
                                  <ConfirmActionModal
                                    mode="changeDelivery"
                                    onClose={onClose}
                                    confirmMessageJSX={
                                      <>
                                        <strong>Masz niezapisane zmiany!</strong>
                                        <br />
                                        Czy na pewno chcesz zmienić dostawę?
                                      </>
                                    }
                                    onConfirmFunction={() => {
                                      setIsWaresReplenishmentModifiedAndUnsaved(false)

                                      changeDelivery(message)
                                    }}
                                  />
                                )
                              },
                            })
                          } else {
                            changeDelivery(String(event?.target?.value))
                          }
                        }}
                      >
                        <option
                          className="select-option-placeholder text-center"
                          value={selectDeliveryPlaceholderValue}
                          disabled={false}
                        >
                          Wybierz dostawę z zamówienia
                        </option>
                        {warehousesState?.managedWarehouseDeliveries
                          ?.filter(
                            (warehouseDelivery: actionTypes.IWarehouseManagementDelivery) =>
                              // this should be warehouseDelivery?.type from BE
                              warehouseDelivery?.bomElements[0]?.type ===
                              actionTypes.waresTypeNumber
                          )
                          ?.sort(
                            (
                              warehouseDeliveryFirst: actionTypes.IWarehouseManagementDelivery,
                              warehouseDeliveryNext: actionTypes.IWarehouseManagementDelivery
                            ) =>
                              Date.parse(warehouseDeliveryFirst.closestDeliveryDate) -
                              Date.parse(warehouseDeliveryNext.closestDeliveryDate)
                          )
                          ?.map((warehouseDelivery: actionTypes.IWarehouseManagementDelivery) => {
                            return (
                              <option
                                key={`warehouse-delivery-option-${warehouseDelivery.id}`}
                                value={JSON.stringify(warehouseDelivery)}
                                className="text-center"
                              >
                                {warehouseDelivery?.orderNumber || 'Zamówienie bez numeru'} —
                                Dostawa {warehouseDelivery?.deliveryNumber || 'bez numeru'} —{' '}
                                {toDateInputValue(warehouseDelivery?.closestDeliveryDate) ||
                                  'brak daty'}
                              </option>
                            )
                          })}
                      </Field>
                    </InputGroup>
                  </div>

                  <CDataTable
                    tableFilterValue={waresReplenishmentFilterState}
                    sorterValue={waresReplenishmentSorterState}
                    onSorterValueChange={(sorterState: actionTypes.TSorterState) => {
                      setWaresReplenishmentSorterState(sorterState)
                    }}
                    onTableFilterChange={(tableFilterState: string) => {
                      setWaresReplenishmentFilterState(tableFilterState)
                    }}
                    loading={warehousesState?.areManagedWarehouseDeliveriesLoading}
                    striped={!!warehousesState?.managedWarehouseDeliveries?.length}
                    border
                    sorter
                    tableFilter={{ label: ' ', placeholder: 'Szukaj w tabeli...' }}
                    addTableClasses="vertical-middle-list-table warehouse-replenishment-table"
                    cleaner
                    onRowClick={undefined}
                    fields={[
                      {
                        key: 'position',
                        label: '#',
                        _style: { width: '40px', textAlign: 'center' },
                      },
                      {
                        key: 'bomElementName',
                        label: 'Nazwa towaru',
                        _style: { width: '320px' },
                      },
                      {
                        key: 'receivedQuantity',
                        label: 'Ilość',
                        _style: { width: '120px', textAlign: 'right' },
                      },
                    ]}
                    items={values?.deliveryItems || undefined}
                    noItemsViewSlot={
                      <div className="no-items-in-table">
                        {values?.deliveryItems && values?.selectedDelivery
                          ? 'Brak towarów w dostawie!'
                          : 'Nie wybrano dostawy!'}
                      </div>
                    }
                    scopedSlots={{
                      receivedQuantity: (
                        itemFromDelivery: actionTypes.IBomElementInWarehouseDelivery,
                        indexOfItemFromDelivery: number
                      ) => {
                        const indexOfStabilizedItem = values?.deliveryItems?.findIndex(
                          (deliveryItem: actionTypes.IBomElementInWarehouseDelivery) =>
                            deliveryItem.bomElementId === itemFromDelivery.bomElementId
                        )

                        return (
                          <td>
                            <InputGroup
                              id={`${
                                values?.selectedDelivery
                                  ? JSON.parse(values?.selectedDelivery)?.id
                                  : 'empty'
                              }-${
                                itemFromDelivery.bomElementId
                              }-wares-replenishment-delivery-item-group-id`}
                              style={{ pointerEvents: 'all' }}
                            >
                              <ErrorMessage
                                name={`deliveryItems.${indexOfStabilizedItem}.receivedQuantity`}
                                component="span"
                                className="text-danger input-error-message"
                              />
                              <FastField
                                as={NumberFormat}
                                displayType="input"
                                thousandSeparator={globalThousandSeparator}
                                decimalSeparator={globalDecimalSeparator}
                                decimalScale={0}
                                fixedDecimalScale={false}
                                allowNegative={false}
                                allowLeadingZeros={true}
                                placeholder={''}
                                name={`deliveryItems.${indexOfStabilizedItem}.receivedQuantity`}
                                value={itemFromDelivery.receivedQuantity || ''}
                                className="form-control text-right"
                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                  itemFromDelivery.hasChanged = true

                                  setIsWaresReplenishmentModifiedAndUnsaved(true)

                                  handleChange(event)
                                }}
                                onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                                  if (
                                    itemFromDelivery &&
                                    itemFromDelivery?.hasChanged &&
                                    Number(
                                      convertNumericStringToNumber(
                                        itemFromDelivery?.receivedQuantity
                                      )
                                    ) > Number(itemFromDelivery?.expectedQuantity)
                                  ) {
                                    confirmAlert({
                                      closeOnEscape: true,
                                      customUI: ({ onClose }) => {
                                        return (
                                          <ConfirmActionModal
                                            mode="replenishment"
                                            onClose={() => {
                                              setFieldValue(
                                                `deliveryItems.${indexOfStabilizedItem}.receivedQuantity`,
                                                ''
                                              )

                                              event?.target?.focus()

                                              onClose()
                                            }}
                                            confirmMessageJSX={
                                              <>
                                                {itemFromDelivery?.bomElementName ? (
                                                  <>
                                                    <strong>{`${itemFromDelivery?.bomElementName}: `}</strong>{' '}
                                                    <br />
                                                  </>
                                                ) : (
                                                  ''
                                                )}
                                                Wpisana ilość przekracza ilość spodziewaną!
                                                <br />
                                                Czy wartość{' '}
                                                <strong>
                                                  {itemFromDelivery.receivedQuantity}
                                                </strong>{' '}
                                                jest prawidłowa?
                                              </>
                                            }
                                            onConfirmFunction={() => {
                                              itemFromDelivery.hasChanged = false

                                              onClose()
                                            }}
                                          />
                                        )
                                      },
                                    })
                                  }
                                }}
                                // Might be useful:
                                required
                              />
                            </InputGroup>
                          </td>
                        )
                      },
                    }}
                  />

                  <CButton
                    color="success"
                    className="save-button w-100 mt-3 mb-2"
                    type="submit"
                    onClick={() => {
                      setDidFormValidationOccur && setDidFormValidationOccur(true)
                    }}
                    disabled={
                      warehousesState?.isWaresReplenishmentDocumentGenerating ||
                      warehousesState?.isWaresReplenishmentDocumentGenerated ||
                      (didFormValidationOccur
                        ? Boolean(errors.deliveryItems && errors.deliveryDocument)
                        : false)
                    }
                  >
                    {warehousesState?.isWaresReplenishmentDocumentGenerating ? (
                      <>
                        Generowanie PZ
                        <ThreeDots />
                      </>
                    ) : warehousesState?.isWaresReplenishmentDocumentGenerated ? (
                      <>Wygenerowano PZ!</>
                    ) : (
                      'Generuj PZ'
                    )}
                  </CButton>
                </Form>
              )}
            </Formik>
          )}
        </CCardBody>
      </CCard>
    </Container>
  )
}
