import React, { useEffect, useMemo } from 'react'
import { Container } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { CDataTable, CCard, CCol, CCardBody, CButton } from '@coreui/react'

import * as actionTypes from '../../store/action-types'
import { TRootState } from '../../store/reducers'
import { getEmptyListMessage, makeSearchBoxFixed } from '../../utils'

const ImplementationCosts: React.FC<{}> = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tenant } = useParams<{ tenant: string }>()

  const authState = useSelector((state: TRootState) => state.auth)
  const implementationCostsState = useSelector((state: TRootState) => state.implementationCosts)

  // Make search box fixed
  useEffect(() => {
    makeSearchBoxFixed()
  }, [makeSearchBoxFixed])

  const memoizedTableItems = useMemo(
    () =>
      implementationCostsState?.implementationCosts?.map(
        (implementationCost: actionTypes.TImplementationCost) => {
          return {
            ...implementationCost,
            name: implementationCost?.name || '',
            icon: '🖊️',
          }
        }
      ),
    [implementationCostsState.implementationCosts]
  )

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center">
      <CCol xl="7">
        <CCard>
          <CCardBody className="pb-2">
            <div className="d-flex justify-content-end list-fixed-create-new-button-wrapper">
              {authState?.authData?.roles.includes('ImplementationCosts_write') && (
                <CButton
                  color="info"
                  variant="outline"
                  size="50"
                  onClick={() => {
                    history.push(`/${tenant}/admin/implementation-costs/create/general-information`)
                  }}
                >
                  Nowy Tooling
                </CButton>
              )}
            </div>
            <CDataTable
              tableFilterValue={implementationCostsState?.tableFilterState}
              sorterValue={implementationCostsState?.tableSorterState}
              onSorterValueChange={(sorterState: actionTypes.TSorterState) =>
                dispatch({
                  type: actionTypes.SET_IMPLEMENTATION_COSTS_TABLE_SORTER_STATE,
                  tableSorterState: sorterState,
                })
              }
              onTableFilterChange={(tableFilterState: string) =>
                dispatch({
                  type: actionTypes.SET_IMPLEMENTATION_COSTS_TABLE_FILTER_STATE,
                  tableFilterState: tableFilterState,
                })
              }
              loading={implementationCostsState.areImplementationCostsLoading}
              striped={!!implementationCostsState.implementationCosts.length}
              border
              sorter
              tableFilter={{ label: ' ', placeholder: 'Szukaj w tabeli...' }}
              cleaner
              addTableClasses="vertical-middle-list-table"
              onRowClick={(implementationCost: actionTypes.TImplementationCost) => {
                history.push(
                  `/${tenant}/admin/implementation-costs/edit/${implementationCost.id}/general-information`
                )
              }}
              fields={[
                {
                  key: 'icon',
                  label: '',
                  sorter: false,
                  filter: false,
                  _style: {
                    width: '25px',
                    display: !implementationCostsState.implementationCosts.length ? 'none' : '',
                  },
                },
                { key: 'name', label: 'Nazwa', _style: { width: '100%' } },
              ]}
              items={memoizedTableItems}
              noItemsViewSlot={
                <div className="no-items-in-table">
                  {getEmptyListMessage(
                    implementationCostsState.fetchImplementationCostsError,
                    implementationCostsState.areImplementationCostsLoading,
                    'toolingu',
                    implementationCostsState?.implementationCosts?.length
                  )}
                </div>
              }
            />
          </CCardBody>
        </CCard>
      </CCol>
    </Container>
  )
}

export default ImplementationCosts
