import React, { useEffect } from 'react'
import { Container } from 'reactstrap'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

export const Logout: React.FC<{}> = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tenant } = useParams<{ tenant: string }>()

  useEffect(() => {
    dispatch({ type: 'LOGOUT_REQUESTED' })

    setTimeout(() => {
      history.push(`/${tenant}/login`)
    }, 2000)
  }, [dispatch, history, tenant])

  return (
    <Container className="app vh-100 d-flex flex-column align-items-center justify-content-center">
      <div className="row">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">
              <i className="fa fa-unlock-alt"></i>&nbsp; Wylogowano!
            </h4>
          </div>
          <div className="modal-body">
            Pomyślnie wylogowano z{' '}
            <strong>{tenant.replace(/\b\w/g, (letter: string) => letter.toUpperCase())}</strong>!
          </div>
        </div>
      </div>
    </Container>
  )
}
