import * as actionTypes from '../action-types'

export interface IPurchasePlansState {
  purchasePlans: actionTypes.TPurchasePlan[] | any
  arePurchasePlansLoading: boolean
  fetchPurchasePlansError: actionTypes.TNetworkError | null

  tableSorterState: actionTypes.TSorterState | undefined
  tableFilterState: actionTypes.TFilterState

  purchasePlanCreateFromBomsModalVisibility: boolean

  isPurchasePlanCreatingFromBoms: boolean
  purchasePlanCreateFromBomsError: actionTypes.TNetworkError
  isPurchasePlanCreatedFromBoms: boolean

  isSinglePurchasePlanLoading: boolean
  singlePurchasePlanFetchError: actionTypes.TNetworkError
  singlePurchasePlan: actionTypes.TPurchasePlan | null

  isPurchasePlanEditSaving: boolean
  purchasePlanEditSaveError: actionTypes.TNetworkError
  isPurchasePlanEditSaved: boolean

  isPurchasePlanDeleting: boolean
  purchasePlanDeleteError: actionTypes.TNetworkError
  isPurchasePlanDeleted: boolean

  currentlyManagedPurchasePlanId: number | null

  purchasePlanDetailsBomElementsTableSorterState: actionTypes.TSorterState | undefined
  purchasePlanDetailsImplementationCostsTableSorterState: actionTypes.TSorterState | undefined
  purchasePlanDetailsServicesTableSorterState: actionTypes.TSorterState | undefined

  isOrderGenerating: boolean
  isOrderGenerated: boolean
  generateOrderError: actionTypes.TNetworkError | null
  generatedOrderId: number | null

  purchasePlanBomElementModalTabs: actionTypes.IPurchasePlanDetailsInModal[] | null
  isPurchasePlanBomElementLoading: boolean
  purchasePlanBomElementModalError: actionTypes.TNetworkError | null
  purchasePlanBomElementModalVisibility: boolean

  purchasePlanImplementationCostModalTabs: actionTypes.IPurchasePlanDetailsInModal[] | null
  isPurchasePlanImplementationCostLoading: boolean
  purchasePlanImplementationCostModalError: actionTypes.TNetworkError | null
  purchasePlanImplementationCostModalVisibility: boolean

  purchasePlanServiceModalTabs: actionTypes.IPurchasePlanDetailsInModal[] | null
  isPurchasePlanServiceLoading: boolean
  purchasePlanServiceModalError: actionTypes.TNetworkError | null
  purchasePlanServiceModalVisibility: boolean
}

const initialState: IPurchasePlansState = {
  purchasePlans: [],
  fetchPurchasePlansError: null,
  arePurchasePlansLoading: false,

  tableSorterState: undefined,
  tableFilterState: undefined,

  purchasePlanCreateFromBomsModalVisibility: false,

  isPurchasePlanCreatingFromBoms: false,
  purchasePlanCreateFromBomsError: null,
  isPurchasePlanCreatedFromBoms: false,

  isSinglePurchasePlanLoading: false,
  singlePurchasePlanFetchError: null,
  singlePurchasePlan: null,

  isPurchasePlanEditSaving: false,
  purchasePlanEditSaveError: null,
  isPurchasePlanEditSaved: false,

  isPurchasePlanDeleting: false,
  purchasePlanDeleteError: null,
  isPurchasePlanDeleted: false,
  currentlyManagedPurchasePlanId: null,

  purchasePlanDetailsBomElementsTableSorterState: undefined,
  purchasePlanDetailsImplementationCostsTableSorterState: undefined,
  purchasePlanDetailsServicesTableSorterState: undefined,

  isOrderGenerating: false,
  isOrderGenerated: false,
  generateOrderError: null,
  generatedOrderId: null,

  purchasePlanBomElementModalTabs: null,
  isPurchasePlanBomElementLoading: false,
  purchasePlanBomElementModalError: null,
  purchasePlanBomElementModalVisibility: false,

  purchasePlanImplementationCostModalTabs: null,
  isPurchasePlanImplementationCostLoading: false,
  purchasePlanImplementationCostModalError: null,
  purchasePlanImplementationCostModalVisibility: false,

  purchasePlanServiceModalTabs: null,
  isPurchasePlanServiceLoading: false,
  purchasePlanServiceModalError: null,
  purchasePlanServiceModalVisibility: false,
}

export default function purchasePlansReducer(
  state = initialState,
  action: actionTypes.PurchasePlansActionTypes
): IPurchasePlansState {
  switch (action.type) {
    /* FETCH_PURCHASE_PLANS_REQUESTED */

    case actionTypes.FETCH_PURCHASE_PLANS_REQUESTED: {
      return {
        ...state,
        fetchPurchasePlansError: null,
        arePurchasePlansLoading: true,

        generatedOrderId: null,
        isOrderGenerated: false,
      }
    }

    case actionTypes.FETCH_PURCHASE_PLANS_SUCCEEDED: {
      return {
        ...state,
        purchasePlans: action.purchasePlans,
        arePurchasePlansLoading: false,
      }
    }

    case actionTypes.FETCH_PURCHASE_PLANS_ERROR: {
      return {
        ...state,
        fetchPurchasePlansError: action.error,
        arePurchasePlansLoading: false,
      }
    }

    /* SET_PURCHASE_PLANS_TABLE_FILTER_STATE */

    case actionTypes.SET_PURCHASE_PLANS_TABLE_FILTER_STATE: {
      return {
        ...state,
        tableFilterState: action.tableFilterState,
      }
    }

    case actionTypes.SET_PURCHASE_PLANS_TABLE_SORTER_STATE: {
      return {
        ...state,
        tableSorterState: action.tableSorterState,
      }
    }

    /* FETCH_SINGLE_PURCHASE_PLAN */

    case actionTypes.FETCH_SINGLE_PURCHASE_PLAN_REQUESTED: {
      return {
        ...state,
        fetchPurchasePlansError: null,
        isSinglePurchasePlanLoading: true,
        singlePurchasePlanFetchError: null,

        isPurchasePlanEditSaving: false,
        purchasePlanEditSaveError: null,

        isPurchasePlanDeleting: false,
        purchasePlanDeleteError: null,

        generatedOrderId: null,
        isOrderGenerated: false,
        generateOrderError: null,
      }
    }

    case actionTypes.FETCH_SINGLE_PURCHASE_PLAN_SUCCEEDED: {
      return {
        ...state,
        singlePurchasePlan: action.singlePurchasePlan,
        isSinglePurchasePlanLoading: false,
        purchasePlanEditSaveError: null,

        generatedOrderId: null,
        isOrderGenerated: false,
      }
    }

    case actionTypes.FETCH_SINGLE_PURCHASE_PLAN_ERROR: {
      return {
        ...state,
        singlePurchasePlanFetchError: action.error,
        isSinglePurchasePlanLoading: false,

        generatedOrderId: null,
        isOrderGenerated: false,
      }
    }

    /* CREATE_PURCHASE_PLAN */

    case actionTypes.CREATE_PURCHASE_PLAN_FROM_BOMS_REQUESTED: {
      return {
        ...state,
        isPurchasePlanCreatingFromBoms: true,
        isPurchasePlanCreatedFromBoms: false,
      }
    }

    case actionTypes.CREATE_PURCHASE_PLAN_FROM_BOMS_SUCCEEDED: {
      return {
        ...state,
        isPurchasePlanCreatingFromBoms: false,
        isPurchasePlanCreatedFromBoms: true,
      }
    }

    case actionTypes.CREATE_PURCHASE_PLAN_FROM_BOMS_ERROR: {
      return {
        ...state,
        purchasePlanCreateFromBomsError: action.error,
        isPurchasePlanCreatingFromBoms: false,
        isPurchasePlanCreatedFromBoms: false,
      }
    }

    /* EDIT_PURCHASE_PLAN */

    case actionTypes.EDIT_PURCHASE_PLAN_REQUESTED: {
      return {
        ...state,
        isPurchasePlanEditSaving: true,
        isPurchasePlanEditSaved: false,
        purchasePlanEditSaveError: null,
      }
    }

    case actionTypes.EDIT_PURCHASE_PLAN_SUCCEEDED: {
      return {
        ...state,
        isPurchasePlanEditSaving: false,
        isPurchasePlanEditSaved: true,
        purchasePlanEditSaveError: null,
      }
    }

    case actionTypes.EDIT_PURCHASE_PLAN_ERROR: {
      return {
        ...state,
        purchasePlanEditSaveError: action.error,
        isPurchasePlanEditSaving: false,
        isPurchasePlanEditSaved: false,
      }
    }

    /* DELETE_PURCHASE_PLAN */

    case actionTypes.DELETE_PURCHASE_PLAN_REQUESTED: {
      return {
        ...state,
        isPurchasePlanDeleting: true,
        isPurchasePlanDeleted: false,
        currentlyManagedPurchasePlanId: action.payload.id,
      }
    }

    case actionTypes.DELETE_PURCHASE_PLAN_SUCCEEDED: {
      return {
        ...state,
        arePurchasePlansLoading: false,
        isPurchasePlanDeleting: false,
        isPurchasePlanDeleted: true,
        purchasePlans: state.purchasePlans.filter(
          (purchasePlan: actionTypes.TPurchasePlan) => purchasePlan.id !== action.id
        ),
        currentlyManagedPurchasePlanId: null,
      }
    }

    case actionTypes.DELETE_PURCHASE_PLAN_ERROR: {
      return {
        ...state,
        isPurchasePlanDeleting: false,
        isPurchasePlanDeleted: false,
        purchasePlanEditSaveError: action.error,
        currentlyManagedPurchasePlanId: null,
      }
    }

    /* UNLOCK_PURCHASE_PLAN_ACTIONS */

    case actionTypes.UNLOCK_PURCHASE_PLAN_ACTIONS: {
      return {
        ...state,
        isPurchasePlanEditSaving: false,
        isPurchasePlanEditSaved: false,
        isPurchasePlanDeleting: false,
        isPurchasePlanDeleted: false,

        // There is UNLOCK_GENERATED_ORDER for more
      }
    }

    /* SET_PURCHASE_PLAN_DETAILS_BOM_ELEMENTS_TABLE_SORTER_STATE */

    case actionTypes.SET_PURCHASE_PLAN_DETAILS_BOM_ELEMENTS_TABLE_SORTER_STATE: {
      return {
        ...state,
        purchasePlanDetailsBomElementsTableSorterState: action.tableSorterState,
      }
    }

    /* SET_PURCHASE_PLAN_DETAILS_IMPLEMENTATION_COSTS_TABLE_SORTER_STATE */

    case actionTypes.SET_PURCHASE_PLAN_DETAILS_IMPLEMENTATION_COSTS_TABLE_SORTER_STATE: {
      return {
        ...state,
        purchasePlanDetailsImplementationCostsTableSorterState: action.tableSorterState,
      }
    }

    /* SET_PURCHASE_PLAN_DETAILS_SERVICES_TABLE_SORTER_STATE */

    case actionTypes.SET_PURCHASE_PLAN_DETAILS_SERVICES_TABLE_SORTER_STATE: {
      return {
        ...state,
        purchasePlanDetailsServicesTableSorterState: action.tableSorterState,
      }
    }

    /* BOM_ELEMENT */

    case actionTypes.FETCH_PURCHASE_PLAN_BOM_ELEMENT_REQUESTED: {
      return {
        ...state,
        isPurchasePlanBomElementLoading: true,
        purchasePlanBomElementModalError: null,
      }
    }

    case actionTypes.FETCH_PURCHASE_PLAN_BOM_ELEMENT_SUCCEEDED: {
      return {
        ...state,
        purchasePlanBomElementModalError: null,
        isPurchasePlanBomElementLoading: false,
        purchasePlanBomElementModalTabs: action.purchasePlanBomElementModalTabs,
      }
    }

    case actionTypes.FETCH_PURCHASE_PLAN_BOM_ELEMENT_ERROR: {
      return {
        ...state,
        purchasePlanBomElementModalError: action.error,
        isPurchasePlanBomElementLoading: false,
      }
    }

    /* IMPLEMENTATION_COST */

    case actionTypes.FETCH_PURCHASE_PLAN_IMPLEMENTATION_COST_REQUESTED: {
      return {
        ...state,
        isPurchasePlanImplementationCostLoading: true,
        purchasePlanImplementationCostModalError: null,
      }
    }

    case actionTypes.FETCH_PURCHASE_PLAN_IMPLEMENTATION_COST_SUCCEEDED: {
      return {
        ...state,
        purchasePlanImplementationCostModalError: null,
        isPurchasePlanImplementationCostLoading: false,
        purchasePlanImplementationCostModalTabs: action.purchasePlanImplementationCostModalTabs,
      }
    }

    case actionTypes.FETCH_PURCHASE_PLAN_IMPLEMENTATION_COST_ERROR: {
      return {
        ...state,
        purchasePlanImplementationCostModalError: action.error,
        isPurchasePlanImplementationCostLoading: false,
      }
    }

    /* SERVICE */

    case actionTypes.FETCH_PURCHASE_PLAN_SERVICE_REQUESTED: {
      return {
        ...state,
        isPurchasePlanServiceLoading: true,
        purchasePlanServiceModalError: null,
      }
    }

    case actionTypes.FETCH_PURCHASE_PLAN_SERVICE_SUCCEEDED: {
      return {
        ...state,
        purchasePlanServiceModalError: null,
        isPurchasePlanServiceLoading: false,
        purchasePlanServiceModalTabs: action.purchasePlanServiceModalTabs,
      }
    }

    case actionTypes.FETCH_PURCHASE_PLAN_SERVICE_ERROR: {
      return {
        ...state,
        purchasePlanServiceModalError: action.error,
        isPurchasePlanServiceLoading: false,
      }
    }

    /* OPEN CLOSE MODALS */

    case actionTypes.OPEN_PURCHASE_PLAN_CREATE_FROM_BOMS_MODAL: {
      return {
        ...state,
        purchasePlanCreateFromBomsError: null,
        purchasePlanCreateFromBomsModalVisibility: true,
      }
    }

    case actionTypes.CLOSE_PURCHASE_PLAN_CREATE_FROM_BOMS_MODAL: {
      return {
        ...state,
        isPurchasePlanEditSaving: false,
        isPurchasePlanCreatedFromBoms: false,
        purchasePlanCreateFromBomsError: null,
        purchasePlanCreateFromBomsModalVisibility: false,
      }
    }

    case actionTypes.OPEN_PURCHASE_PLAN_BOM_ELEMENT_MODAL: {
      return {
        ...state,
        purchasePlanBomElementModalVisibility: true,
      }
    }

    case actionTypes.CLOSE_PURCHASE_PLAN_BOM_ELEMENT_MODAL: {
      return {
        ...state,
        purchasePlanBomElementModalError: null,
        purchasePlanBomElementModalVisibility: false,
        purchasePlanBomElementModalTabs: null,
      }
    }

    case actionTypes.OPEN_PURCHASE_PLAN_IMPLEMENTATION_COST_MODAL: {
      return {
        ...state,
        purchasePlanImplementationCostModalVisibility: true,
      }
    }

    case actionTypes.CLOSE_PURCHASE_PLAN_IMPLEMENTATION_COST_MODAL: {
      return {
        ...state,
        purchasePlanImplementationCostModalError: null,
        purchasePlanImplementationCostModalVisibility: false,
        purchasePlanImplementationCostModalTabs: null,
      }
    }

    case actionTypes.OPEN_PURCHASE_PLAN_SERVICE_MODAL: {
      return {
        ...state,
        purchasePlanServiceModalVisibility: true,
      }
    }

    case actionTypes.CLOSE_PURCHASE_PLAN_SERVICE_MODAL: {
      return {
        ...state,
        purchasePlanServiceModalError: null,
        purchasePlanServiceModalVisibility: false,
        purchasePlanServiceModalTabs: null,
      }
    }

    /* GENERATE_ORDER*/

    case actionTypes.GENERATE_ORDER_REQUESTED: {
      return {
        ...state,
        generateOrderError: null,
        isOrderGenerating: true,
        isOrderGenerated: false,
        generatedOrderId: null,
      }
    }

    case actionTypes.GENERATE_ORDER_SUCCEEDED: {
      return {
        ...state,
        generateOrderError: null,
        isOrderGenerating: false,
        isOrderGenerated: true,
        generatedOrderId: action.generatedOrderId,
      }
    }

    case actionTypes.GENERATE_ORDER_ERROR: {
      return {
        ...state,
        generateOrderError: action.error,
        isOrderGenerating: false,
        isOrderGenerated: false,
        generatedOrderId: null,
      }
    }

    case actionTypes.UNLOCK_GENERATED_ORDER: {
      return {
        ...state,
        generateOrderError: null,
        isOrderGenerating: false,
        isOrderGenerated: false,
        generatedOrderId: null,
      }
    }

    default:
      return state
  }
}
