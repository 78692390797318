import React, { SyntheticEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { CButton } from '@coreui/react'

import * as actionTypes from '../store/action-types'
import { TRootState } from '../store/reducers'
import { ThreeDots } from './three-dots'
import { confirmAlert } from 'react-confirm-alert'
import { ConfirmActionModal } from './confirm-action-modal'

export interface IListActionButton {
  mainActionName: 'copy' | 'archive' | 'delete'
  buttonColor: 'info' | 'dark' | 'danger'
  classNames: string
  typeOfAction: string
  itemFromList: actionTypes.TLightItemInList
  currentlyManagedItemId: number | null
  isItemCopying: boolean
  isItemArchiving: boolean
  isItemDeleting: boolean
  iconName: string
}

export const ListActionButton: React.FC<IListActionButton> = ({
  mainActionName,
  buttonColor,
  classNames,
  typeOfAction,
  itemFromList,
  currentlyManagedItemId,
  isItemCopying,
  isItemArchiving,
  isItemDeleting,
  iconName,
}) => {
  const { tenant } = useParams<{ tenant: string }>()
  const authState = useSelector((state: TRootState) => state.auth)
  let dispatch = useDispatch()

  let isOneButtonClicked =
    (mainActionName === 'copy'
      ? isItemCopying
      : mainActionName === 'archive'
      ? isItemArchiving
      : mainActionName === 'delete'
      ? isItemDeleting
      : null) && currentlyManagedItemId === itemFromList.id

  return (
    <CButton
      color={buttonColor}
      variant="outline"
      size="50"
      className={`list-action-button ${
        isItemDeleting && currentlyManagedItemId === itemFromList.id
          ? 'deleting-list-item-button'
          : classNames
      }`}
      onClick={(event: SyntheticEvent) => {
        const listItemName = itemFromList?.name || ''

        if (mainActionName !== 'copy') {
          confirmAlert({
            closeOnEscape: true,
            customUI: ({ onClose }) => {
              return (
                <ConfirmActionModal
                  mode={mainActionName}
                  onClose={onClose}
                  confirmMessageJSX={
                    <>
                      {`Czy na pewno chcesz ${
                        mainActionName === 'archive' ? 'zarchiwizować' : 'usunąć'
                      }?`}
                      {listItemName ? (
                        <>
                          <br />
                          <strong>{listItemName}</strong>
                        </>
                      ) : (
                        ''
                      )}
                    </>
                  }
                  onConfirmFunction={() =>
                    dispatch({
                      type: typeOfAction,
                      payload: {
                        tenant: tenant,
                        token: authState.authData?.token,

                        id: itemFromList.id,
                        name: listItemName,
                      },
                    })
                  }
                />
              )
            },
          })
        } else if (mainActionName === 'copy') {
          dispatch({
            type: typeOfAction,
            payload: {
              tenant: tenant,
              token: authState.authData?.token,

              id: itemFromList.id,
              name: `${listItemName} - Kopia`,
            },
          })
        }

        // This line blocks the opening of BOM on checkbox/row click
        event.stopPropagation()
      }}
      disabled={
        (mainActionName === 'delete' && !itemFromList.canDelete) ||
        isItemArchiving ||
        isItemCopying ||
        isItemDeleting
      }
      style={{
        pointerEvents:
          (mainActionName === 'delete' && !itemFromList.canDelete) ||
          isItemArchiving ||
          isItemCopying ||
          isItemDeleting
            ? 'none'
            : 'all',
      }}
    >
      {isOneButtonClicked ? (
        <div className={`spinner-border very-small-spinner text-${buttonColor}`} role="status">
          <span className="sr-only">
            <ThreeDots />
          </span>
        </div>
      ) : (
        <i className={iconName}></i>
      )}
    </CButton>
  )
}
