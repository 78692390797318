import { call, put, takeLatest, delay } from 'redux-saga/effects'

import { sectionFetch, producerCall, deleteItemCall } from './api'
import * as actionTypes from '../action-types'
import { successMessageDuration } from '../../utils'

export function* fetchProducers(action: actionTypes.fetchProducersAction) {
  try {
    const response = yield call(sectionFetch, action.payload, 'producers')
    yield put({ type: actionTypes.FETCH_PRODUCERS_SUCCEEDED, producers: response.data })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_PRODUCERS_ERROR,
      error: error?.response,
    })
  }
}

export function* createProducer(action: actionTypes.createProducerAction) {
  try {
    const response = yield call(producerCall, action.payload)
    yield put({
      type: actionTypes.CREATE_PRODUCER_SUCCEEDED,
      producer: { ...action.payload.producer, id: response.data },
    })
    yield delay(successMessageDuration)
    yield put({
      type: actionTypes.CLOSE_PRODUCER_MODAL,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.CREATE_PRODUCER_ERROR,
      error: error?.response,
    })
  }
}

export function* editProducer(action: actionTypes.editProducerAction) {
  try {
    yield call(producerCall, action.payload)
    yield put({
      type: actionTypes.EDIT_PRODUCER_SUCCEEDED,
      producer: action.payload.producer,
    })
    yield delay(successMessageDuration)
    yield put({
      type: actionTypes.CLOSE_PRODUCER_MODAL,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.EDIT_PRODUCER_ERROR,
      error: error?.response,
    })
  }
}

export function* deleteProducer(action: actionTypes.deleteProducerAction) {
  try {
    yield call(deleteItemCall, action.payload, 'producers')
    yield put({
      type: actionTypes.DELETE_PRODUCER_SUCCEEDED,
      id: action.payload.id,
    })
    yield delay(successMessageDuration)
    yield put({
      type: actionTypes.CLOSE_PRODUCER_MODAL,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.DELETE_PRODUCER_ERROR,
      error: error?.response,
    })
  }
}

export function* producersSaga() {
  yield takeLatest(actionTypes.FETCH_PRODUCERS_REQUESTED, fetchProducers)
  yield takeLatest(actionTypes.CREATE_PRODUCER_REQUESTED, createProducer)
  yield takeLatest(actionTypes.DELETE_PRODUCER_REQUESTED, deleteProducer)
  yield takeLatest(actionTypes.EDIT_PRODUCER_REQUESTED, editProducer)
}
