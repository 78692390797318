import { makeDoubleDigit } from './display-local-date'

export const toDateTableValue = function (
  date: string | null | undefined,
  withHours: boolean = false
) {
  if (!date) {
    return ''
  } else {
    let local = new Date(date)
    local.setMinutes(local.getMinutes() - local.getTimezoneOffset())

    // add Settings GMT based time in the future
    return `${local.getFullYear()}-${makeDoubleDigit(local.getMonth() + 1)}-${makeDoubleDigit(
      local.getDate()
    )}${
      withHours
        ? ` ${makeDoubleDigit(local.getHours())}:${makeDoubleDigit(local.getMinutes())}`
        : ''
    }`
  }
}
