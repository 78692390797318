import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { CDataTable } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilZoom } from '@coreui/icons'

import {
  TBomElementInOrder,
  TImplementationCostInOrder,
  TServiceInOrder,
} from '../store/action-types'
import {
  convertQuantityToNumericString,
  convertNumberToNumericString,
  toDateTableValue,
  noSearchResultsText,
} from '../utils'
import { TNetworkError } from '../store/action-types'

export interface IConnectedOrdersTableItem
  extends TImplementationCostInOrder,
    TServiceInOrder,
    TBomElementInOrder {}

interface IConnectedOrdersTable {
  mode: 'bom-element' | 'implementation-cost' | 'service'
  areItemOrdersLoading: boolean
  editedItemOrders: IConnectedOrdersTableItem[] | null
  fetchItemOrdersError: TNetworkError
  tenant: string
}

export const ConnectedOrdersTable: React.FC<IConnectedOrdersTable> = ({
  mode,
  areItemOrdersLoading,
  editedItemOrders,
  fetchItemOrdersError,
  tenant,
}) => {
  const memoizedTableItems = useMemo(
    () =>
      editedItemOrders?.map((order: IConnectedOrdersTableItem) => {
        return {
          ...order,
          open: '',

          quantity:
            mode === 'implementation-cost' ? 1 : convertQuantityToNumericString(order?.quantity),
          price: convertNumberToNumericString(
            mode === 'bom-element'
              ? order?.bomElementPrice
              : mode === 'implementation-cost'
              ? order?.implementationCostPrice
              : order.servicePrice
          ),
          currency: convertNumberToNumericString(
            mode === 'bom-element'
              ? order?.bomElementPriceCurrency
              : mode === 'implementation-cost'
              ? order?.implementationCostPriceCurrency
              : order.servicePriceCurrency
          ),
          createDate: toDateTableValue(String(order?.createDate || ''), false),
          orderNumber: order?.orderNumber || '',
        }
      }),
    [editedItemOrders]
  )

  return (
    <div className="connected-orders-data-table-wrapper">
      <CDataTable
        sorterValue={{ column: 'createDate', asc: false }}
        loading={areItemOrdersLoading}
        border
        sorter
        striped
        tableFilter={{ label: ' ', placeholder: 'Szukaj w tabeli...' }}
        cleaner
        addTableClasses="item-inner-details-table item-inner-details-table--connected-items"
        fields={[
          {
            key: 'open',
            label: 'Podgląd',
            sorter: false,
            filter: false,
            _style: { width: '70px' },
          },
          {
            key: 'quantity',
            label: 'Ilość',
            _style: { width: '110px' },
          },
          {
            key: 'price',
            label: 'Cena',
            _style: { width: '110px' },
          },
          {
            key: 'currency',
            label: 'CCY',
            _style: { width: '40px' },
          },
          {
            key: 'createDate',
            label: 'Data',
            _style: { width: '100px' },
          },
          {
            key: 'orderNumber',
            label: 'Numer wewnętrzny',
            _style: { width: '1fr' },
          },
        ]}
        items={memoizedTableItems}
        noItemsViewSlot={
          <div className="no-items-in-table">
            {fetchItemOrdersError
              ? `Wystąpił błąd ${fetchItemOrdersError?.status} podczas pobierania zamówień ${
                  mode === 'bom-element'
                    ? 'tego elementu'
                    : mode === 'implementation-cost'
                    ? 'tego toolingu'
                    : 'tej usługi'
                }!`
              : memoizedTableItems?.length
              ? noSearchResultsText
              : `${
                  mode === 'bom-element'
                    ? 'Ten element'
                    : mode === 'implementation-cost'
                    ? 'Ten tooling'
                    : 'Ta usługa'
                } nie jest obecnie w żadnych zamówieniach!`}
          </div>
        }
        scopedSlots={{
          open: (order: IConnectedOrdersTableItem) => (
            <td>
              <Link
                to={`/${tenant}/admin/order/${order.orderId}/general-information`}
                className="button info d-flex justify-content-center"
                style={{ padding: '0.4rem 0.75rem' }}
              >
                <CIcon icon={cilZoom} />
              </Link>
            </td>
          ),
        }}
      />
    </div>
  )
}
