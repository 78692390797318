import { noSearchResultsText } from './constants'
import { TNetworkError } from '../store/action-types'
import { getErrorMessageFromStatus } from './get-error-message-from-status'

export const getEmptyListMessage = (
  itemsFetchError: TNetworkError,
  areItemsLoading: boolean,
  itemsName: string | 'elementów' | 'ofert',
  itemsLength: number | null | undefined
): string => {
  return areItemsLoading
    ? `Pobieranie ${itemsName}...`
    : itemsFetchError
    ? getErrorMessageFromStatus('fetch', itemsFetchError?.status, itemsName)
    : !itemsLength
    ? `Brak ${itemsName} do wyświetlenia!`
    : noSearchResultsText
}
