import React, { RefObject, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react'
import { Container, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import {
  CCard,
  CCol,
  CCardBody,
  CTabs,
  CTabContent,
  CTabPane,
  CNavLink,
  CNavItem,
  CNav,
  CLabel,
  CButton,
  CDataTable,
  CModal,
  CModalHeader,
} from '@coreui/react'
import { useHistory, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { Select } from '@material-ui/core'
import { ErrorMessage, FastField, FieldArray, Form, Formik, FormikErrors } from 'formik'
import { confirmAlert } from 'react-confirm-alert'
import deepEqual from 'deep-equal'
import * as Yup from 'yup'

import * as actionTypes from '../../store/action-types'
import { TRootState } from '../../store/reducers'
import {
  getErrorMessageFromStatus,
  inputFieldSpacingBottom,
  inputLabelSpacingBottom,
  preventNavigationChange,
  SimplifiedCurrencies,
} from '../../utils'
import {
  BasicFormField,
  ConfirmActionModal,
  CountrySelectField,
  FormActionCancelButton,
  FormActionSaveButton,
  CustomErrorMessage,
} from '../../components'
import { TFormTab, topLabelsOffsetFromNavigationTabs } from '../admin'

export const settingsNavTabs: TFormTab[] = [
  { name: 'company', title: 'Firmowe', isEditOnly: false },
  { name: 'finance', title: 'Finansowe', isEditOnly: false },
  { name: 'other', title: 'Inne', isEditOnly: false },
]

const Settings: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tenant, tab } = useParams<{
    tenant: string
    tab: string
  }>()
  const paramsSettingsTab = tab

  const companySettingsAddressCountryRefs = useRef([])
  const financeSettingsBankAddressCountryRef: RefObject<any> = useRef()

  const [currentlyEditedBankAccountIndex, setCurrentlyEditedBankAccountIndex] = useState<number>(0)
  const [isBankAccountModalVisible, setIsBankAccountModalVisible] = useState<boolean>(false)
  const [bankAccountModalMode, setBankAccountModalMode] = useState<actionTypes.TFormMode>('create')
  const [unsavedBankAccountDetails, setUnsavedBankAccountDetails] =
    useState<actionTypes.TBankAccountSetting | null>(null)

  const [areSettingsModifiedAndUnsaved, setAreSettingsModifiedAndUnsaved] = useState<boolean>(false)
  const [didFormValidationOccur, setDidFormValidationOccur] = useState(false)

  const authState = useSelector((state: TRootState) => state.auth)
  const settingsState = useSelector((state: TRootState) => state.settings)

  const historyState: any = history?.location?.state

  const closeSettings = useCallback(() => {
    if (historyState?.fromPageToSettings?.pathname) {
      history.push(historyState?.fromPageToSettings?.pathname)
    } else {
      history.push(`/${tenant}/admin/boms`)
    }
  }, [history, tenant, dispatch])

  const isThereNetworkError =
    settingsState?.settingsSaveError?.status || settingsState?.fetchSettingsError?.status

  const areSettingsEditable = true

  useEffect(() => {
    if (!tab) {
      history.replace(`/${tenant}/admin/settings/company`)
    }
  }, [tab])

  // This is a hack because close button in modal acts as a submit button
  useEffect(() => {
    const closeModalButton = document.getElementsByClassName('close')

    if (closeModalButton[0]) {
      closeModalButton[0].setAttribute('type', 'button')
    }
  }, [isBankAccountModalVisible])

  // Remove label from search box in finance list of banks
  useEffect(() => {
    if (tab === 'finance') {
      // Do not use makeSearchBoxFixed here
      const searchBox = window.document.getElementsByClassName('col-sm-6')[0]

      searchBox.parentElement?.parentElement?.classList.add('settings-finance-banks-list--search')
    }
  }, [tab])

  // Track the changes again
  useEffect(() => {
    if (settingsState.areSettingsSaved) {
      setAreSettingsModifiedAndUnsaved(false)
    }
  }, [settingsState.areSettingsSaved])

  // Prevent navigation back and forth plus reload if modified
  useEffect(() => {
    preventNavigationChange(
      history,
      areSettingsModifiedAndUnsaved,
      areSettingsEditable,
      'settings',
      '',
      ''
    )
    // Without pathname in location there is no tab change detection
  }, [location.pathname, history, areSettingsModifiedAndUnsaved, areSettingsEditable])

  // Unmount Component
  useEffect(() => {
    return () => {
      dispatch({ type: actionTypes.SETTINGS_ACTIONS_UNLOCK })
    }
  }, [])

  const SettingsSchema = Yup.object().shape({
    financeSettings: Yup.object().shape({
      bankAccounts: Yup.array().of(
        Yup.object().shape({
          iban: Yup.string().required('To pole jest wymagane!').nullable(),
          currencyCode: Yup.string()
            .notOneOf(['', null, undefined], 'Wymagane!')
            .required('Wymagane!')
            .nullable(),
          bankName: Yup.string().required('To pole jest wymagane!').nullable(),
        })
      ),
    }),
  })

  return (
    // className="m-0 d-flex"
    // for centering: d-flex flex-column align-items-center justify-content-center
    <Container className="m-0 d-flex">
      <CCol xl={tab === 'finance' ? '12' : tab === 'other' ? '5' : '7'}>
        <CCard className="mb-0">
          <CCardBody className="settings-view-card-body">
            <h4 className={`text-center ${isThereNetworkError ? 'mb-3' : 'mb-4'}`}>Ustawienia</h4>

            <Formik
              enableReinitialize={true}
              initialValues={{
                companySettings: {
                  name: settingsState?.settings?.companySettings?.name || '',
                  nip: settingsState?.settings?.companySettings?.nip || '',
                  regon: settingsState?.settings?.companySettings?.regon || '',
                  krs: settingsState?.settings?.companySettings?.krs || '',
                  euVatId: settingsState?.settings?.companySettings?.euVatId || '',
                  eori: settingsState?.settings?.companySettings?.eori || '',
                  addresses: settingsState?.settings?.companySettings?.addresses || [
                    {
                      name: '',
                      address1: '',
                      address2: '',
                      city: '',
                      post: '',
                      country: '',
                      uuid: uuidv4(),
                    },
                  ],
                  // Code above keeps the main address always visible
                },
                financeSettings: {
                  bankAccounts:
                    settingsState?.settings?.financeSettings?.bankAccounts ||
                    ([] as actionTypes.TBankAccountSetting[]),
                },
                otherSettings: {
                  defaultSystemCurrency:
                    settingsState?.settings?.otherSettings?.defaultSystemCurrency || '',
                  timeZone: settingsState?.settings?.otherSettings?.timeZone || '',
                },
              }}
              validationSchema={SettingsSchema}
              onSubmit={(values) => {
                if (areSettingsEditable) {
                  setDidFormValidationOccur(true)

                  dispatch({
                    type: actionTypes.SAVE_SETTINGS_REQUESTED,
                    payload: {
                      tenant: tenant,
                      token: authState.authData?.token,
                      settings: values,
                    },
                  })
                }
              }}
              validateOnBlur={true}
              validateOnChange={didFormValidationOccur || true}
            >
              {({ initialValues, values, errors, setFieldValue, resetForm }) => (
                <Form
                  onChange={() => {
                    // Values here are always 1 step behind
                    let isModified = !deepEqual(values, initialValues, { strict: false })

                    if (isModified) {
                      /* If form is brought to its initial state then it is not modified */
                      setAreSettingsModifiedAndUnsaved(true)
                    } else {
                      setAreSettingsModifiedAndUnsaved(false)
                    }

                    if (errors) {
                      setDidFormValidationOccur(true)
                    }
                  }}
                >
                  {/*
                   * Display Network Error Message
                   */}

                  {settingsState?.fetchSettingsError && (
                    <CustomErrorMessage
                      wrapperClassNames="mt-2 mb-4"
                      customErrorMessageText={getErrorMessageFromStatus(
                        'fetch',
                        settingsState?.fetchSettingsError?.status,
                        'ustawień'
                      )}
                    />
                  )}

                  {settingsState?.settingsSaveError && (
                    <CustomErrorMessage
                      wrapperClassNames="mt-2 mb-4"
                      customErrorMessageText={getErrorMessageFromStatus(
                        'edit',
                        settingsState?.settingsSaveError?.status,
                        'ustawień'
                      )}
                    />
                  )}

                  {/*
                   * Form Tabs
                   */}

                  <CTabs
                    activeTab={paramsSettingsTab ? paramsSettingsTab : settingsNavTabs[0].name}
                  >
                    <CNav variant="tabs" className="mb-4 d-flex justify-content-start">
                      {settingsNavTabs?.map((modalTab: TFormTab) => {
                        if (modalTab.isEditOnly) {
                          return null
                        } else {
                          return (
                            <CNavItem key={modalTab.name}>
                              <CNavLink
                                // className={`${
                                //   modalTab.name === 'company' && errors
                                //     ? 'text-danger is-invalid'
                                //     : ''
                                // }`}
                                data-tab={modalTab.name}
                                onClick={() => {
                                  history.replace(`/${tenant}/admin/settings/${modalTab.name}`)
                                }}
                              >
                                {modalTab.title}
                              </CNavLink>
                            </CNavItem>
                          )
                        }
                      })}
                    </CNav>

                    <CTabContent fade={true} className="pt-2">
                      {/*
                       * Company Settings Tab
                       */}

                      <CTabPane
                        data-tab="company"
                        style={{ marginTop: topLabelsOffsetFromNavigationTabs }}
                      >
                        {paramsSettingsTab === 'company' && (
                          <>
                            <div className="settings-view-double-grid-fields mb-1">
                              <BasicFormField
                                fieldId="settings-company-name"
                                fieldLabel="Pełna nazwa firmy"
                                fieldIcon="cil-short-text"
                                formikFieldName="companySettings.name"
                                fieldValue={values.companySettings.name}
                                fieldError={''}
                                fieldType="text"
                                placeholder="Pełna nazwa firmy"
                              />

                              <BasicFormField
                                fieldId="settings-company-nip"
                                fieldLabel="NIP"
                                fieldIcon="cil-bank"
                                formikFieldName="companySettings.nip"
                                fieldValue={values.companySettings.nip}
                                fieldError={''}
                                fieldType="text"
                                placeholder="NIP"
                              />

                              <BasicFormField
                                fieldId="settings-company-regon"
                                fieldLabel="REGON"
                                fieldIcon="cil-bank"
                                formikFieldName="companySettings.regon"
                                fieldValue={values.companySettings.regon}
                                fieldError={''}
                                fieldType="text"
                                placeholder="REGON"
                              />

                              <BasicFormField
                                fieldId="settings-company-krs"
                                fieldLabel="KRS"
                                fieldIcon="cil-bank"
                                formikFieldName="companySettings.krs"
                                fieldValue={values.companySettings.krs}
                                fieldError={''}
                                fieldType="text"
                                placeholder="KRS"
                              />

                              <BasicFormField
                                fieldId="settings-company-eu-vat-id"
                                fieldLabel="EU VAT ID"
                                fieldIcon="cil-code"
                                formikFieldName="companySettings.euVatId"
                                fieldValue={values.companySettings.euVatId}
                                fieldError={''}
                                fieldType="text"
                                placeholder="EU VAT ID"
                              />

                              <BasicFormField
                                fieldId="settings-company-eori"
                                fieldLabel="EORI"
                                fieldIcon="cil-code"
                                formikFieldName="companySettings.eori"
                                fieldValue={values.companySettings.eori}
                                fieldError={''}
                                fieldType="text"
                                placeholder="EORI"
                              />
                            </div>

                            <FieldArray
                              name="companySettings.addresses"
                              render={(arrayHelpers) => (
                                <div>
                                  {values?.companySettings?.addresses?.map(
                                    (
                                      addressItem: actionTypes.TAddressSetting,
                                      addressItemIndex: number
                                    ) => {
                                      return (
                                        <div
                                          key={`company-settings-addresses-key-${addressItemIndex}-${addressItem.uuid}`}
                                        >
                                          <p className="mt-3">
                                            <strong>
                                              {addressItemIndex === 0
                                                ? `Adres główny`
                                                : `Adres dodatkowy ${addressItemIndex}`}
                                            </strong>
                                          </p>
                                          <div className="company-settings-address-double-grid my-3">
                                            <div className="settings-view-double-grid-fields">
                                              <BasicFormField
                                                fieldId={`settings-company-addresses-${addressItemIndex}-street`}
                                                fieldLabel="Ulica"
                                                fieldIcon="cil-home"
                                                formikFieldName={`companySettings.addresses.${addressItemIndex}.address1`}
                                                fieldValue={addressItem.address1}
                                                fieldError={''}
                                                fieldType="text"
                                                placeholder="Ulica"
                                              />

                                              <BasicFormField
                                                fieldId={`settings-company-addresses-${addressItemIndex}-post`}
                                                fieldLabel="Kod pocztowy"
                                                fieldIcon="cil-envelope-closed"
                                                formikFieldName={`companySettings.addresses.${addressItemIndex}.post`}
                                                fieldValue={addressItem.post}
                                                fieldError={''}
                                                fieldType="text"
                                                placeholder="Kod pocztowy"
                                                isShortLengthInput
                                              />

                                              <BasicFormField
                                                fieldId={`settings-company-addresses-${addressItemIndex}-city`}
                                                fieldLabel="Miasto"
                                                fieldIcon="cil-building"
                                                formikFieldName={`companySettings.addresses.${addressItemIndex}.city`}
                                                fieldValue={addressItem.city}
                                                fieldError={''}
                                                fieldType="text"
                                                placeholder="Miasto"
                                                isShortLengthInput
                                              />

                                              <CountrySelectField
                                                fieldId={`settings-company-addresses-${addressItemIndex}-country`}
                                                fieldLabel="Kraj"
                                                fieldIcon="cil-globe-alt"
                                                formikFieldName={`companySettings.addresses.${addressItemIndex}.country`}
                                                fieldValue={addressItem.country}
                                                fieldError={''}
                                                placeholder="Wybierz z listy lub wpisz nazwę kraju"
                                                setFieldValue={setFieldValue}
                                                refCountryTypeahead={null}
                                                listOfRefs={companySettingsAddressCountryRefs}
                                                indexOfItemInList={addressItemIndex}
                                              />
                                            </div>

                                            <div>
                                              <CButton
                                                color="danger"
                                                type="button"
                                                variant="outline"
                                                onClick={() => {
                                                  for (
                                                    let i = addressItemIndex;
                                                    i < values.companySettings.addresses.length;
                                                    i++
                                                  ) {
                                                    const addressItemCountryTypeahead: any =
                                                      companySettingsAddressCountryRefs?.current[i]

                                                    const addressItemCountryTypeaheadNext: any =
                                                      companySettingsAddressCountryRefs?.current[
                                                        i + 1
                                                      ]

                                                    if (
                                                      addressItemCountryTypeahead &&
                                                      addressItemCountryTypeaheadNext
                                                    ) {
                                                      addressItemCountryTypeahead.inputNode.value =
                                                        addressItemCountryTypeaheadNext?.inputNode
                                                          ?.value
                                                          ? String(
                                                              addressItemCountryTypeaheadNext
                                                                ?.inputNode?.value
                                                            )
                                                          : ''

                                                      addressItemCountryTypeahead.state.text =
                                                        addressItemCountryTypeaheadNext?.state?.text
                                                          ? String(
                                                              addressItemCountryTypeaheadNext?.state
                                                                ?.text
                                                            )
                                                          : ''

                                                      // This is very important to keep the order of deleted elements
                                                      addressItemCountryTypeahead.state.selected =
                                                        addressItemCountryTypeaheadNext?.state?.selected
                                                    }

                                                    if (
                                                      i + 1 ===
                                                      values.companySettings.addresses.length
                                                    ) {
                                                      arrayHelpers.remove(addressItemIndex)
                                                    }
                                                  }
                                                }}
                                                className="select-option-remove-button"
                                                style={{
                                                  display:
                                                    addressItemIndex === 0 ? 'none' : 'initial',
                                                }}
                                                disabled={addressItemIndex === 0}
                                              >
                                                <i className="cil-trash"></i>
                                              </CButton>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    }
                                  )}

                                  <div className="d-flex justify-content-center my-3">
                                    <CButton
                                      color="info"
                                      type="button"
                                      variant="outline"
                                      onClick={() => {
                                        let newAddress: actionTypes.TAddressSetting = {
                                          name: '',
                                          address1: '',
                                          address2: '',
                                          city: '',
                                          post: '',
                                          country: '',
                                          uuid: uuidv4(),
                                        }

                                        arrayHelpers.push(newAddress)
                                      }}
                                      disabled={
                                        settingsState.areSettingsSaving ||
                                        settingsState.areSettingsLoading
                                      }
                                      className="px-4"
                                    >
                                      Dodaj adres
                                    </CButton>
                                  </div>
                                </div>
                              )}
                            />
                          </>
                        )}
                      </CTabPane>

                      {/*
                       * Finance Settings Tab
                       */}

                      <CTabPane
                        data-tab="finance"
                        style={{ marginTop: topLabelsOffsetFromNavigationTabs }}
                      >
                        {paramsSettingsTab === 'finance' && (
                          <FieldArray
                            name="financeSettings.bankAccounts"
                            render={(arrayHelpers) => (
                              <>
                                <CModal
                                  onClose={() => {
                                    if (bankAccountModalMode === 'create') {
                                      arrayHelpers.remove(
                                        values?.financeSettings?.bankAccounts.length - 1
                                      )
                                    }

                                    if (bankAccountModalMode === 'edit') {
                                      arrayHelpers.replace(
                                        currentlyEditedBankAccountIndex,
                                        unsavedBankAccountDetails
                                      )
                                    }

                                    setIsBankAccountModalVisible(false)
                                  }}
                                  size="lg"
                                  show={isBankAccountModalVisible}
                                  centered
                                  backdrop
                                  closeOnBackdrop={false}
                                  addContentClass="py-4 px-5"
                                >
                                  <CModalHeader className="c-modal-header-grid mb-3" closeButton>
                                    <h4 className={`text-center`}>
                                      {bankAccountModalMode === 'create'
                                        ? 'Nowy rachunek bankowy'
                                        : 'Edytuj rachunek bankowy'}
                                    </h4>
                                  </CModalHeader>

                                  <div className="finance-settings-bank-iban-double-grid">
                                    <BasicFormField
                                      fieldId={`settings-finance-bank-account-iban-${currentlyEditedBankAccountIndex}-${values?.financeSettings?.bankAccounts[currentlyEditedBankAccountIndex]?.uuid}`}
                                      fieldLabel="IBAN / Numer rachunku"
                                      fieldIcon="cil-bank"
                                      formikFieldName={`financeSettings.bankAccounts.${currentlyEditedBankAccountIndex}.iban`}
                                      fieldValue={
                                        values?.financeSettings?.bankAccounts[
                                          currentlyEditedBankAccountIndex
                                        ]?.iban || ''
                                      }
                                      fieldError={
                                        (errors?.financeSettings !== undefined &&
                                          errors?.financeSettings?.bankAccounts !== undefined &&
                                          (
                                            errors?.financeSettings?.bankAccounts[
                                              currentlyEditedBankAccountIndex
                                            ] as FormikErrors<actionTypes.TBankAccountSetting>
                                          )?.iban) ||
                                        null
                                      }
                                      fieldType="text"
                                      placeholder="IBAN / Numer rachunku"
                                      isShortLengthInput
                                    />

                                    <div>
                                      <CLabel
                                        htmlFor={`settings-finance-bank-account-currency-${currentlyEditedBankAccountIndex}-${values?.financeSettings?.bankAccounts[currentlyEditedBankAccountIndex]?.uuid}`}
                                        className={`${inputLabelSpacingBottom}`}
                                      >
                                        Waluta
                                      </CLabel>
                                      <InputGroup
                                        id={`settings-finance-bank-account-currency-${currentlyEditedBankAccountIndex}-${values?.financeSettings?.bankAccounts[currentlyEditedBankAccountIndex]?.uuid}`}
                                        className={`${inputFieldSpacingBottom}`}
                                      >
                                        <InputGroupAddon addonType="prepend">
                                          <InputGroupText
                                            className={
                                              errors?.financeSettings !== undefined &&
                                              errors?.financeSettings?.bankAccounts !== undefined &&
                                              (
                                                errors?.financeSettings?.bankAccounts[
                                                  currentlyEditedBankAccountIndex
                                                ] as FormikErrors<actionTypes.TBankAccountSetting>
                                              )?.currencyCode
                                                ? 'text-danger input-error-icon'
                                                : ''
                                            }
                                          >
                                            <i className="cil-money"></i>
                                          </InputGroupText>
                                        </InputGroupAddon>

                                        <ErrorMessage
                                          name={`financeSettings.bankAccounts.${currentlyEditedBankAccountIndex}.currencyCode`}
                                          component="span"
                                          className="text-danger input-error-message"
                                        />

                                        <FastField
                                          as={Select}
                                          name={`financeSettings.bankAccounts.${currentlyEditedBankAccountIndex}.currencyCode`}
                                          native
                                          className={`default-system-currency-selector ${
                                            errors?.financeSettings !== undefined &&
                                            errors?.financeSettings?.bankAccounts !== undefined &&
                                            (
                                              errors?.financeSettings?.bankAccounts[
                                                currentlyEditedBankAccountIndex
                                              ] as FormikErrors<actionTypes.TBankAccountSetting>
                                            )?.currencyCode &&
                                            'invalid-native-selector'
                                          }`}
                                          variant="outlined"
                                          placeholder=""
                                          value={
                                            values?.financeSettings?.bankAccounts[
                                              currentlyEditedBankAccountIndex
                                            ]?.currencyCode || ''
                                          }
                                        >
                                          <option
                                            key={`financeSettings-bankAccounts-${currentlyEditedBankAccountIndex}-${values?.financeSettings?.bankAccounts[currentlyEditedBankAccountIndex]?.uuid}-currencyCode-null-currency-...`}
                                            value={''}
                                          >
                                            ...
                                          </option>

                                          {SimplifiedCurrencies?.map(
                                            (
                                              currencyCode: actionTypes.TCurrencyCode,
                                              currencyIndex: number
                                            ) => {
                                              return (
                                                <option
                                                  key={`${currencyIndex}-${currencyCode}-${values?.financeSettings?.bankAccounts[currentlyEditedBankAccountIndex]?.uuid}`}
                                                  data-current={currencyCode}
                                                  value={currencyCode}
                                                >
                                                  {currencyCode}
                                                </option>
                                              )
                                            }
                                          )}
                                        </FastField>
                                      </InputGroup>
                                    </div>
                                  </div>

                                  <div className="settings-view-double-grid-fields">
                                    <BasicFormField
                                      fieldId={`settings-finance-swift-code-${currentlyEditedBankAccountIndex}-${values?.financeSettings?.bankAccounts[currentlyEditedBankAccountIndex]?.uuid}`}
                                      fieldLabel="SWIFT code"
                                      fieldIcon="cil-barcode"
                                      formikFieldName={`financeSettings.bankAccounts.${currentlyEditedBankAccountIndex}.swiftCode`}
                                      fieldValue={
                                        values?.financeSettings?.bankAccounts[
                                          currentlyEditedBankAccountIndex
                                        ]?.swiftCode || ''
                                      }
                                      fieldError={''}
                                      fieldType="text"
                                      placeholder="SWIFT code"
                                    />

                                    <BasicFormField
                                      fieldId={`settings-finance-routing-number-${currentlyEditedBankAccountIndex}-${values?.financeSettings?.bankAccounts[currentlyEditedBankAccountIndex]?.uuid}`}
                                      fieldLabel="Routing number"
                                      fieldIcon="cil-barcode"
                                      formikFieldName={`financeSettings.bankAccounts.${currentlyEditedBankAccountIndex}.routingNumber`}
                                      fieldValue={
                                        values?.financeSettings?.bankAccounts[
                                          currentlyEditedBankAccountIndex
                                        ]?.routingNumber || ''
                                      }
                                      fieldError={''}
                                      fieldType="text"
                                      placeholder="Routing number"
                                      isShortLengthInput
                                    />

                                    <BasicFormField
                                      fieldId={`settings-finance-bank-name-${currentlyEditedBankAccountIndex}-${values?.financeSettings?.bankAccounts[currentlyEditedBankAccountIndex]?.uuid}`}
                                      fieldLabel="Nazwa banku"
                                      fieldIcon="cil-short-text"
                                      formikFieldName={`financeSettings.bankAccounts.${currentlyEditedBankAccountIndex}.bankName`}
                                      fieldValue={
                                        values?.financeSettings?.bankAccounts[
                                          currentlyEditedBankAccountIndex
                                        ]?.bankName || ''
                                      }
                                      fieldError={
                                        (errors?.financeSettings !== undefined &&
                                          errors?.financeSettings?.bankAccounts !== undefined &&
                                          (
                                            errors.financeSettings.bankAccounts[
                                              currentlyEditedBankAccountIndex
                                            ] as FormikErrors<actionTypes.TBankAccountSetting>
                                          )?.bankName) ||
                                        null
                                      }
                                      fieldType="text"
                                      placeholder="Nazwa banku"
                                    />

                                    <BasicFormField
                                      fieldId={`settings-finance-bank-branch-name-${currentlyEditedBankAccountIndex}-${values?.financeSettings?.bankAccounts[currentlyEditedBankAccountIndex]?.uuid}`}
                                      fieldLabel="Nazwa oddziału"
                                      fieldIcon="cil-short-text"
                                      formikFieldName={`financeSettings.bankAccounts.${currentlyEditedBankAccountIndex}.bankBranchName`}
                                      fieldValue={
                                        values?.financeSettings?.bankAccounts[
                                          currentlyEditedBankAccountIndex
                                        ]?.bankBranchName || ''
                                      }
                                      fieldError={''}
                                      fieldType="text"
                                      placeholder="Nazwa oddziału"
                                    />
                                  </div>

                                  <p className="mt-2">
                                    <strong>Adres banku</strong>
                                  </p>

                                  <div className="settings-view-double-grid-fields">
                                    <BasicFormField
                                      fieldId={`settings-finance-bank-address-${currentlyEditedBankAccountIndex}-${values?.financeSettings?.bankAccounts[currentlyEditedBankAccountIndex]?.uuid}`}
                                      fieldLabel="Ulica"
                                      fieldIcon="cil-home"
                                      formikFieldName={`financeSettings.bankAccounts.${currentlyEditedBankAccountIndex}.bankAddress1`}
                                      fieldValue={
                                        values?.financeSettings?.bankAccounts[
                                          currentlyEditedBankAccountIndex
                                        ]?.bankAddress1 || ''
                                      }
                                      fieldError={''}
                                      fieldType="text"
                                      placeholder="Ulica"
                                    />

                                    <BasicFormField
                                      fieldId={`settings-finance-bank-postal-code-${currentlyEditedBankAccountIndex}-${values?.financeSettings?.bankAccounts[currentlyEditedBankAccountIndex]?.uuid}`}
                                      fieldLabel="Kod pocztowy"
                                      fieldIcon="cil-envelope-closed"
                                      formikFieldName={`financeSettings.bankAccounts.${currentlyEditedBankAccountIndex}.bankPost`}
                                      fieldValue={
                                        values?.financeSettings?.bankAccounts[
                                          currentlyEditedBankAccountIndex
                                        ]?.bankPost || ''
                                      }
                                      fieldError={''}
                                      fieldType="text"
                                      placeholder="Kod pocztowy"
                                    />

                                    <BasicFormField
                                      fieldId={`settings-finance-bank-city-${currentlyEditedBankAccountIndex}-${values?.financeSettings?.bankAccounts[currentlyEditedBankAccountIndex]?.uuid}`}
                                      fieldLabel="Miasto"
                                      fieldIcon="cil-building"
                                      formikFieldName={`financeSettings.bankAccounts.${currentlyEditedBankAccountIndex}.bankCity`}
                                      fieldValue={
                                        values?.financeSettings?.bankAccounts[
                                          currentlyEditedBankAccountIndex
                                        ]?.bankCity || ''
                                      }
                                      fieldError={''}
                                      fieldType="text"
                                      placeholder="Miasto"
                                    />

                                    <CountrySelectField
                                      fieldId={`settings-finance-bank-country-${currentlyEditedBankAccountIndex}-${values?.financeSettings?.bankAccounts[currentlyEditedBankAccountIndex]?.uuid}`}
                                      fieldLabel="Kraj"
                                      fieldIcon="cil-globe-alt"
                                      formikFieldName={`financeSettings.bankAccounts.${currentlyEditedBankAccountIndex}.bankCountry`}
                                      fieldValue={
                                        values?.financeSettings?.bankAccounts[
                                          currentlyEditedBankAccountIndex
                                        ]?.bankCountry || ''
                                      }
                                      fieldError={''}
                                      placeholder="Wybierz z listy lub wpisz nazwę kraju"
                                      setFieldValue={setFieldValue}
                                      refCountryTypeahead={financeSettingsBankAddressCountryRef}
                                    />
                                  </div>

                                  <div className="d-flex justify-content-end mt-3 mb-1">
                                    <CButton
                                      color="primary"
                                      variant="outline"
                                      className="cancel-button mr-4"
                                      type="button"
                                      onClick={() => {
                                        if (bankAccountModalMode === 'create') {
                                          arrayHelpers.remove(
                                            values?.financeSettings?.bankAccounts.length - 1
                                          )
                                        }

                                        if (bankAccountModalMode === 'edit') {
                                          arrayHelpers.replace(
                                            currentlyEditedBankAccountIndex,
                                            unsavedBankAccountDetails
                                          )
                                        }

                                        setIsBankAccountModalVisible(false)
                                      }}
                                    >
                                      Anuluj
                                    </CButton>

                                    <CButton
                                      color="info"
                                      className="save-button"
                                      type="button"
                                      onClick={() => {
                                        setIsBankAccountModalVisible(false)
                                      }}
                                      disabled={Boolean(
                                        (errors?.financeSettings !== undefined &&
                                          errors?.financeSettings?.bankAccounts !== undefined &&
                                          (
                                            errors?.financeSettings?.bankAccounts[
                                              currentlyEditedBankAccountIndex
                                            ] as FormikErrors<actionTypes.TBankAccountSetting>
                                          )?.iban) ||
                                          (errors?.financeSettings !== undefined &&
                                            errors?.financeSettings?.bankAccounts !== undefined &&
                                            (
                                              errors?.financeSettings?.bankAccounts[
                                                currentlyEditedBankAccountIndex
                                              ] as FormikErrors<actionTypes.TBankAccountSetting>
                                            )?.currencyCode) ||
                                          (errors?.financeSettings !== undefined &&
                                            errors?.financeSettings?.bankAccounts !== undefined &&
                                            (
                                              errors?.financeSettings?.bankAccounts[
                                                currentlyEditedBankAccountIndex
                                              ] as FormikErrors<actionTypes.TBankAccountSetting>
                                            )?.bankName)
                                      )}
                                    >
                                      {bankAccountModalMode === 'edit' ? 'Zapisz' : 'Dodaj'}
                                    </CButton>
                                  </div>
                                </CModal>

                                <CDataTable
                                  loading={settingsState.areSettingsLoading}
                                  striped={true}
                                  border
                                  sorter
                                  addTableClasses="vertical-middle-list-table settings-banks-list"
                                  tableFilter={{ label: ' ', placeholder: 'Szukaj w tabeli...' }}
                                  cleaner
                                  items={values?.financeSettings?.bankAccounts}
                                  fields={[
                                    {
                                      key: 'currencyCode',
                                      label: 'CCY',
                                      _style: {
                                        width: '30px',
                                        textAlign: 'left',
                                        verticalAlign: 'middle',
                                      },
                                    },
                                    {
                                      key: 'iban',
                                      label: 'IBAN / Numer rachunku',
                                      _style: {
                                        width: '300px',
                                        textAlign: 'left',
                                        verticalAlign: 'middle',
                                      },
                                    },
                                    {
                                      key: 'bankName',
                                      label: 'Nazwa banku',
                                      _style: {
                                        width: '170px',
                                        textAlign: 'left',
                                        verticalAlign: 'middle',
                                      },
                                    },
                                    {
                                      key: 'combinedAddress',
                                      label: 'Adres banku',
                                      _sorter: true,
                                      _style: {
                                        width: '1fr',
                                        textAlign: 'left',
                                        verticalAlign: 'middle',
                                      },
                                    },
                                    {
                                      key: 'swiftCode',
                                      label: 'SWIFT code',
                                      _style: {
                                        width: '120px',
                                        textAlign: 'left',
                                        verticalAlign: 'middle',
                                      },
                                    },
                                    {
                                      key: 'edit',
                                      label: '',
                                      _style: { width: '45px' },
                                    },
                                    {
                                      key: 'delete',
                                      label: '',
                                      _style: { width: '45px' },
                                    },
                                  ]}
                                  noItemsViewSlot={
                                    <div className="no-items-in-table">
                                      Brak dodanych adresów bankowych
                                    </div>
                                  }
                                  scopedSlots={{
                                    combinedAddress: (
                                      bankAccountDetails: actionTypes.TBankAccountSetting,
                                      bankAccountDetailsIndex: number
                                    ) => (
                                      <td>
                                        {`${
                                          bankAccountDetails?.bankBranchName
                                            ? bankAccountDetails?.bankBranchName + ', '
                                            : ''
                                        }${
                                          bankAccountDetails?.bankAddress1
                                            ? bankAccountDetails?.bankAddress1 + ', '
                                            : ''
                                        }${
                                          bankAccountDetails?.bankPost
                                            ? bankAccountDetails?.bankPost + ', '
                                            : ''
                                        }${
                                          bankAccountDetails?.bankCity
                                            ? bankAccountDetails?.bankCity + ', '
                                            : ''
                                        }${
                                          bankAccountDetails?.bankCountry
                                            ? bankAccountDetails?.bankCountry
                                            : ''
                                        }`}
                                      </td>
                                    ),
                                    edit: (
                                      bankAccountDetails: actionTypes.TBankAccountSetting,
                                      bankAccountDetailsIndex: number
                                    ) => (
                                      <td>
                                        <CButton
                                          color="dark"
                                          variant="outline"
                                          size="50"
                                          className="list-action-button edit-list-item-button"
                                          style={{ pointerEvents: 'all' }}
                                          onClick={() => {
                                            setBankAccountModalMode('edit')

                                            setUnsavedBankAccountDetails(bankAccountDetails)

                                            setCurrentlyEditedBankAccountIndex(
                                              bankAccountDetailsIndex
                                            )

                                            setIsBankAccountModalVisible(true)
                                          }}
                                        >
                                          <i className="cil-pen"></i>
                                        </CButton>
                                      </td>
                                    ),
                                    delete: (
                                      bankAccountDetails: actionTypes.TBankAccountSetting,
                                      bankAccountDetailsIndex: number
                                    ) => (
                                      <td className={`text-center`}>
                                        <CButton
                                          color="danger"
                                          variant="outline"
                                          size="50"
                                          className="list-action-button delete-list-item-button"
                                          style={{ pointerEvents: 'all' }}
                                          onClick={() => {
                                            confirmAlert({
                                              closeOnEscape: true,
                                              customUI: ({ onClose }) => {
                                                return (
                                                  <ConfirmActionModal
                                                    mode="delete"
                                                    onClose={onClose}
                                                    confirmMessageJSX={
                                                      <>
                                                        Czy na pewno chcesz usunąć wybrane konto
                                                        bankowe?
                                                      </>
                                                    }
                                                    onConfirmFunction={() =>
                                                      arrayHelpers.remove(bankAccountDetailsIndex)
                                                    }
                                                  />
                                                )
                                              },
                                            })
                                          }}
                                        >
                                          <i className="cil-trash"></i>
                                        </CButton>
                                      </td>
                                    ),
                                  }}
                                />

                                <div className="d-flex justify-content-center">
                                  <CButton
                                    color="info"
                                    variant="outline"
                                    className="my-3 px-4"
                                    type="button"
                                    onClick={() => {
                                      let newlyCreatedBankAccountAddress: actionTypes.TBankAccountSetting =
                                        {
                                          currencyCode: '',
                                          iban: '',
                                          swiftCode: '',
                                          routingNumber: '',
                                          bankName: '',
                                          bankBranchName: '',
                                          bankAddress1: '',
                                          bankAddress2: '',
                                          bankPost: '',
                                          bankCity: '',
                                          bankCountry: '',
                                          uuid: uuidv4(),
                                        }

                                      arrayHelpers.push(newlyCreatedBankAccountAddress)

                                      setBankAccountModalMode('create')

                                      setCurrentlyEditedBankAccountIndex(
                                        values?.financeSettings?.bankAccounts.length
                                      )

                                      setIsBankAccountModalVisible(true)
                                    }}
                                  >
                                    Nowy rachunek bankowy
                                  </CButton>
                                </div>
                              </>
                            )}
                          />
                        )}
                      </CTabPane>

                      {/*
                       * Other Settings Tab
                       */}

                      <CTabPane
                        data-tab="other"
                        style={{ marginTop: topLabelsOffsetFromNavigationTabs }}
                      >
                        {/* This is a hack for blinking sections after the tab is changed, especially the large bank accounts data table */}
                        {paramsSettingsTab === 'other' && (
                          <div className="d-flex flex-column justify-content-center align-items-center mb-3">
                            <div className="mt-1">
                              <CLabel
                                htmlFor="settings-other-default-system-currency"
                                className={`${inputLabelSpacingBottom}`}
                              >
                                Główna waluta
                              </CLabel>
                              <InputGroup
                                id="settings-other-default-system-currency"
                                className={`${inputFieldSpacingBottom}`}
                              >
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="cil-money"></i>
                                  </InputGroupText>
                                </InputGroupAddon>

                                <FastField
                                  as={Select}
                                  name="otherSettings.defaultSystemCurrency"
                                  native
                                  className="default-system-currency-selector"
                                  variant="outlined"
                                  placeholder=""
                                  value={values?.otherSettings?.defaultSystemCurrency || ''}
                                >
                                  {!values?.otherSettings?.defaultSystemCurrency && (
                                    <option
                                      key={`settings-other-default-system-currency-null-currency-...`}
                                      value={''}
                                    >
                                      ...
                                    </option>
                                  )}

                                  {SimplifiedCurrencies?.map(
                                    (
                                      currencyCode: actionTypes.TCurrencyCode,
                                      currencyIndex: number
                                    ) => {
                                      return (
                                        <option
                                          key={`${currencyIndex}-${currencyCode}`}
                                          data-current={currencyCode}
                                          value={currencyCode}
                                        >
                                          {currencyCode}
                                        </option>
                                      )
                                    }
                                  )}
                                </FastField>
                              </InputGroup>
                            </div>

                            <div className="mt-2">
                              <CLabel
                                htmlFor="settings-other-timezone"
                                className={`${inputLabelSpacingBottom}`}
                              >
                                Strefa czasowa
                              </CLabel>
                              <InputGroup
                                id="settings-other-timezone"
                                className={`${inputFieldSpacingBottom}`}
                              >
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="cil-clock"></i>
                                  </InputGroupText>
                                </InputGroupAddon>

                                <FastField
                                  as={Select}
                                  name="otherSettings.timeZone"
                                  native
                                  className="default-system-currency-selector"
                                  variant="outlined"
                                  placeholder=""
                                  style={{ pointerEvents: 'none' }}
                                  disabled
                                >
                                  <option
                                    key={values?.otherSettings?.timeZone}
                                    data-current={values?.otherSettings?.timeZone}
                                    value={values?.otherSettings?.timeZone}
                                  >
                                    {values?.otherSettings?.timeZone || ''}
                                  </option>
                                </FastField>
                              </InputGroup>
                            </div>
                          </div>
                        )}
                      </CTabPane>
                    </CTabContent>
                  </CTabs>

                  {/*
                   * Do not replace this with FormActionsPanel because there is no Delete
                   */}

                  <div className={`pt-3 pb-1 d-flex justify-content-end`}>
                    <FormActionCancelButton closeFunction={closeSettings} closeAction={''} />

                    {!settingsState.fetchSettingsError && (
                      <div className="ml-2">
                        <FormActionSaveButton
                          mode={'edit'}
                          isSaving={settingsState.areSettingsSaving}
                          isSaved={settingsState.areSettingsSaved}
                          isDeleting={false}
                          isDeleted={false}
                          formErrorsBool={Boolean(false)}
                          didFormValidationOccur={didFormValidationOccur}
                          setDidFormValidationOccur={setDidFormValidationOccur}
                        />
                      </div>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </CCardBody>
        </CCard>
      </CCol>
    </Container>
  )
}

export default Settings
