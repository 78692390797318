import { globalCurrencyFallback } from '../../utils'
import * as actionTypes from '../action-types'

export interface ISettingsState {
  settings: actionTypes.ISettings
  areSettingsLoading: boolean
  fetchSettingsError: actionTypes.TNetworkError

  areSettingsSaving: boolean
  settingsSaveError: actionTypes.TNetworkError
  areSettingsSaved: boolean
}

const initialState: ISettingsState = {
  settings: { otherSettings: { defaultSystemCurrency: globalCurrencyFallback, timeZone: '' } },
  areSettingsLoading: false,
  fetchSettingsError: null,

  areSettingsSaving: false,
  settingsSaveError: null,
  areSettingsSaved: false,
}

export default function settingsReducer(
  state = initialState,
  action: actionTypes.SettingsActionTypes
): ISettingsState {
  switch (action.type) {
    case actionTypes.FETCH_SETTINGS_REQUESTED: {
      return {
        ...state,
        fetchSettingsError: null,
        areSettingsLoading: true,
      }
    }

    case actionTypes.FETCH_SETTINGS_SUCCEEDED: {
      return {
        ...state,
        settings: action.settings,
        areSettingsLoading: false,
      }
    }

    case actionTypes.FETCH_SETTINGS_ERROR: {
      return {
        ...state,
        fetchSettingsError: action.error,
        areSettingsLoading: false,
      }
    }

    case actionTypes.SAVE_SETTINGS_REQUESTED: {
      return {
        ...state,
        settingsSaveError: null,
        areSettingsSaving: true,
        areSettingsSaved: false,
      }
    }

    case actionTypes.SAVE_SETTINGS_SUCCEEDED: {
      return {
        ...state,
        areSettingsSaving: false,
        areSettingsSaved: true,
        settings: action.settings,
      }
    }

    case actionTypes.SAVE_SETTINGS_ERROR: {
      return {
        ...state,
        settingsSaveError: action.error,
        areSettingsSaving: false,
        areSettingsSaved: false,
      }
    }

    case actionTypes.SETTINGS_ACTIONS_UNLOCK: {
      return {
        ...state,
        areSettingsSaving: false,
        areSettingsSaved: false,
      }
    }

    default:
      return state
  }
}
