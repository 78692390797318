import React, { useEffect, useState, useMemo } from 'react'
import { Container } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { CDataTable, CCard, CCol, CCardBody, CButton } from '@coreui/react'

import * as actionTypes from '../../store/action-types'
import { TRootState } from '../../store/reducers'
import { getEmptyListMessage, makeSearchBoxFixed } from '../../utils'
import { ProducerModal } from './producer-modal'

const Producers: React.FC<{}> = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tenant, id } = useParams<{ tenant: string; id: string }>()
  const paramsProducerId = id

  const authState = useSelector((state: TRootState) => state.auth)
  const producersState = useSelector((state: TRootState) => state.producers)

  const [producerModalMode, setProducerModalMode] = useState<actionTypes.TFormMode>('create')

  // Open create modal
  useEffect(() => {
    if (
      tenant &&
      authState.authData &&
      location.pathname.includes(`/${tenant}/admin/producers/create`) &&
      !producersState.producerModalVisibility
    ) {
      dispatch({
        type: actionTypes.OPEN_PRODUCER_MODAL,
        editedProducer: null,
      })
    }
  }, [
    dispatch,
    tenant,
    authState,
    producersState.producers,
    history,
    location.pathname,
    producerModalMode,
    producersState.producerModalVisibility,
  ])

  // Set data for editedProducer in modal when clicked on row or opened in new window
  useEffect(() => {
    if (tenant && authState.authData && paramsProducerId && producersState.producers) {
      setProducerModalMode('edit')

      if (producersState.producers.length) {
        const foundProducer = producersState.producers.find(
          (prod: actionTypes.TProducer) => Number(prod.id) === Number(paramsProducerId)
        )

        if (foundProducer) {
          dispatch({
            type: actionTypes.OPEN_PRODUCER_MODAL,
            editedProducer: foundProducer,
          })
        } else if (!producersState.isProducerDeleted) {
          history.replace(`/${tenant}/admin/producers`)
        }
      }
    }
  }, [dispatch, tenant, authState, paramsProducerId, producersState.producers, history])

  // Close Producer Modal when going back in browser history
  useEffect(() => {
    if (
      !paramsProducerId &&
      producersState.producerModalVisibility &&
      !location.pathname.includes('producers/create') &&
      !location.pathname.includes('producers/edit')
    ) {
      dispatch({
        type: actionTypes.CLOSE_PRODUCER_MODAL,
      })
    }
  }, [dispatch, paramsProducerId, producersState.producerModalVisibility, location.pathname])

  // Make search box fixed
  useEffect(() => {
    makeSearchBoxFixed()
  }, [makeSearchBoxFixed])

  const memoizedTableItems = useMemo(
    () =>
      producersState?.producers?.map((producer: actionTypes.TProducer) => {
        return {
          ...producer,
          name: producer?.name || '',
          code: producer?.code || '',
          icon: '🖊️',
        }
      }),
    [producersState.producers]
  )

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center">
      <CCol xl="7">
        <CCard>
          <CCardBody className="pb-2">
            {authState?.authData?.roles?.includes('Producers_write') && (
              <div className="d-flex justify-content-end list-fixed-create-new-button-wrapper">
                <CButton
                  color="info"
                  variant="outline"
                  size="50"
                  onClick={() => {
                    history.push(`/${tenant}/admin/producers/create`)
                    setProducerModalMode('create')
                    dispatch({ type: actionTypes.OPEN_PRODUCER_MODAL })
                  }}
                >
                  Nowy Producent
                </CButton>
              </div>
            )}

            <CDataTable
              tableFilterValue={producersState?.tableFilterState}
              sorterValue={producersState?.tableSorterState}
              onSorterValueChange={(sorterState: actionTypes.TSorterState) =>
                dispatch({
                  type: actionTypes.SET_PRODUCERS_TABLE_SORTER_STATE,
                  tableSorterState: sorterState,
                })
              }
              onTableFilterChange={(tableFilterState: string) =>
                dispatch({
                  type: actionTypes.SET_PRODUCERS_TABLE_FILTER_STATE,
                  tableFilterState: tableFilterState,
                })
              }
              loading={producersState.areProducersLoading}
              striped={!!producersState.producers.length}
              border
              sorter
              tableFilter={{ label: ' ', placeholder: 'Szukaj w tabeli...' }}
              cleaner
              addTableClasses="vertical-middle-list-table"
              onRowClick={(producer: actionTypes.TProducer) => {
                history.push(`/${tenant}/admin/producers/edit/${producer.id}`)
              }}
              fields={[
                {
                  key: 'icon',
                  label: '',
                  sorter: false,
                  filter: false,
                  _style: { width: '5%', display: !producersState.producers.length ? 'none' : '' },
                },
                { key: 'name', label: 'Nazwa', _style: { width: '46%', textAlign: 'left' } },
                { key: 'code', label: 'Kod (Skrót)', _style: { width: '46%', textAlign: 'left' } },
              ]}
              items={memoizedTableItems}
              noItemsViewSlot={
                <div className="no-items-in-table">
                  {getEmptyListMessage(
                    producersState.fetchProducersError,
                    producersState.areProducersLoading,
                    'producentów',
                    producersState?.producers?.length
                  )}
                </div>
              }
            />
          </CCardBody>
        </CCard>
      </CCol>
      {producersState.producerModalVisibility && <ProducerModal mode={producerModalMode} />}
    </Container>
  )
}

export default Producers
