import * as actionTypes from '../action-types'

export interface IWarehousesState {
  warehouses: actionTypes.TWarehouseLight[] | []
  areWarehousesLoading: boolean
  fetchWarehousesError: actionTypes.TNetworkError

  tableSorterState: actionTypes.TSorterState | undefined
  tableFilterState: actionTypes.TFilterState
  lastTabOpen: string | undefined

  isWarehouseCreating: boolean
  warehouseCreateError: actionTypes.TNetworkError
  isWarehouseCreated: boolean

  isSingleWarehouseLoading: boolean
  editedWarehouse: actionTypes.TWarehouseLight | null
  singleWarehouseFetchError: actionTypes.TNetworkError

  isWarehouseEditSaving: boolean
  warehouseEditSaveError: actionTypes.TNetworkError
  isWarehouseEditSaved: boolean

  isWarehouseDeleting: boolean
  isWarehouseDeleted: boolean
  warehouseDeleteError: actionTypes.TNetworkError

  currentlyManagedWarehouseId: number | null

  managedWarehouseDeliveries: actionTypes.IWarehouseManagementDelivery[] | null
  areManagedWarehouseDeliveriesLoading: boolean
  fetchManagedWarehouseDeliveriesError: actionTypes.TNetworkError

  managedWarehouseDeliveriesTableSorterState: actionTypes.TSorterState | undefined
  managedWarehouseDeliveriesTableFilterState: actionTypes.TFilterState

  isWarehouseInventoryControlDataLoading: boolean
  warehouseInventoryControlData: Omit<
    actionTypes.IInventoryControlFormData,
    'inventoryControlDate' | 'inventoryControlWarehouseId'
  > | null
  fetchWarehouseInventoryControlDataError: actionTypes.TNetworkError

  isWarehouseInventoryControlDataSaving: boolean
  isWarehouseInventoryControlDataSaved: boolean
  saveWarehouseInventoryControlError: actionTypes.TNetworkError

  areInventoriesOfWarehousesLoading: boolean
  inventoriesOfWarehouses: actionTypes.IInventoriesOfWarehousesDataItem[] | null
  fetchInventoriesOfWarehousesError: actionTypes.TNetworkError

  areDocumentsOfWarehousesLoading: boolean
  documentsOfWarehouses: actionTypes.IDocumentsOfWarehousesTableItem[] | []
  fetchDocumentsOfWarehousesError: actionTypes.TNetworkError

  deliveryFromOrderInMaterialsReplenishment: actionTypes.IWarehouseManagementDelivery | undefined

  isMaterialsReplenishmentDocumentGenerating: boolean
  isMaterialsReplenishmentDocumentGenerated: boolean
  materialsReplenishmentDocumentGenerateError: actionTypes.TNetworkError

  deliveryFromOrderInWaresReplenishment: actionTypes.IWarehouseManagementDelivery | undefined

  isWaresReplenishmentDocumentGenerating: boolean
  isWaresReplenishmentDocumentGenerated: boolean
  waresReplenishmentDocumentGenerateError: actionTypes.TNetworkError

  isFinishedProductsReplenishmentDocumentGenerating: boolean
  isFinishedProductsReplenishmentDocumentGenerated: boolean
  finishedProductsReplenishmentDocumentGenerateError: actionTypes.TNetworkError

  managedWarehouseFinishedProductsProductionComissions: any[] | null

  isMoveReplenishmentDocumentGenerating: boolean
  isMoveReplenishmentDocumentGenerated: boolean
  moveReplenishmentDocumentGenerateError: actionTypes.TNetworkError
}

const initialState: IWarehousesState = {
  warehouses: [],
  areWarehousesLoading: false,
  fetchWarehousesError: null,

  tableSorterState: undefined,
  tableFilterState: undefined,
  lastTabOpen: undefined,

  isWarehouseCreating: false,
  warehouseCreateError: null,
  isWarehouseCreated: false,

  isSingleWarehouseLoading: false,
  editedWarehouse: null,
  singleWarehouseFetchError: null,

  isWarehouseEditSaving: false,
  warehouseEditSaveError: null,
  isWarehouseEditSaved: false,

  isWarehouseDeleting: false,
  isWarehouseDeleted: false,
  warehouseDeleteError: null,

  currentlyManagedWarehouseId: null,

  managedWarehouseDeliveries: null,
  areManagedWarehouseDeliveriesLoading: false,
  fetchManagedWarehouseDeliveriesError: null,

  managedWarehouseDeliveriesTableSorterState: undefined,
  managedWarehouseDeliveriesTableFilterState: undefined,

  isWarehouseInventoryControlDataLoading: false,
  warehouseInventoryControlData: null,
  fetchWarehouseInventoryControlDataError: null,

  isWarehouseInventoryControlDataSaving: false,
  isWarehouseInventoryControlDataSaved: false,
  saveWarehouseInventoryControlError: null,

  areInventoriesOfWarehousesLoading: false,
  inventoriesOfWarehouses: null,
  fetchInventoriesOfWarehousesError: null,

  areDocumentsOfWarehousesLoading: false,
  // {
  //   documentType: 'MM',
  //   documentNumber: 'MM-2213',
  //   invoices: 'jak tutaj?',
  //   orderNumber: 'Z/21/11/11',
  //   warehouseName: 'Olsztyn',
  //   warehouseId: 6,
  //   supplierAndReceiver: 'Nazwa - Imię i Nazwisko',
  //   quantity: 2222,
  //   nettoValue: 2222,
  //   nettoValueCurrency: 'PLN',
  //   documentCreateDate: '2000/22/22',
  // }
  documentsOfWarehouses: [],
  fetchDocumentsOfWarehousesError: null,

  deliveryFromOrderInMaterialsReplenishment: undefined,

  isMaterialsReplenishmentDocumentGenerating: false,
  isMaterialsReplenishmentDocumentGenerated: false,
  materialsReplenishmentDocumentGenerateError: null,

  deliveryFromOrderInWaresReplenishment: undefined,

  isWaresReplenishmentDocumentGenerating: false,
  isWaresReplenishmentDocumentGenerated: false,
  waresReplenishmentDocumentGenerateError: null,

  isFinishedProductsReplenishmentDocumentGenerating: false,
  isFinishedProductsReplenishmentDocumentGenerated: false,
  finishedProductsReplenishmentDocumentGenerateError: null,

  managedWarehouseFinishedProductsProductionComissions: null,

  isMoveReplenishmentDocumentGenerating: false,
  isMoveReplenishmentDocumentGenerated: false,
  moveReplenishmentDocumentGenerateError: null,
}

export default function warehousesReducer(
  state = initialState,
  action: actionTypes.WarehousesActionTypes
): IWarehousesState {
  switch (action.type) {
    case actionTypes.FETCH_WAREHOUSES_REQUESTED: {
      return {
        ...state,
        areWarehousesLoading: true,
        fetchWarehousesError: null,
      }
    }

    case actionTypes.FETCH_WAREHOUSES_SUCCEEDED: {
      return {
        ...state,
        areWarehousesLoading: false,
        warehouses: action.warehouses,
      }
    }

    case actionTypes.FETCH_WAREHOUSES_ERROR: {
      return {
        ...state,
        areWarehousesLoading: false,
        fetchWarehousesError: action.error,
      }
    }

    case actionTypes.SET_WAREHOUSES_TABLE_FILTER_STATE: {
      return {
        ...state,
        tableFilterState: action.tableFilterState,
      }
    }

    case actionTypes.SET_WAREHOUSES_TABLE_SORTER_STATE: {
      return {
        ...state,
        tableSorterState: action.tableSorterState,
      }
    }

    case actionTypes.CREATE_WAREHOUSE_REQUESTED: {
      return {
        ...state,
        isWarehouseCreating: true,
        isWarehouseCreated: false,
      }
    }

    case actionTypes.CREATE_WAREHOUSE_SUCCEEDED: {
      return {
        ...state,
        isWarehouseCreating: false,
        isWarehouseCreated: true,
        warehouses: [...state.warehouses, action.warehouse],
      }
    }

    case actionTypes.CREATE_WAREHOUSE_ERROR: {
      return {
        ...state,
        warehouseCreateError: action.error,
        isWarehouseCreating: false,
        isWarehouseCreated: false,
      }
    }

    case actionTypes.EDIT_WAREHOUSE_REQUESTED: {
      return {
        ...state,
        warehouseEditSaveError: null,
        isWarehouseEditSaving: true,
        isWarehouseEditSaved: false,
      }
    }

    case actionTypes.EDIT_WAREHOUSE_SUCCEEDED: {
      return {
        ...state,
        isWarehouseEditSaving: false,
        isWarehouseEditSaved: true,
        warehouses: state.warehouses?.map((warehouse: actionTypes.TWarehouseLight) => {
          if (warehouse.id === action.warehouse.id) {
            return action.warehouse
          } else {
            return warehouse
          }
        }),
      }
    }

    case actionTypes.EDIT_WAREHOUSE_ERROR: {
      return {
        ...state,
        warehouseEditSaveError: action.error,
        isWarehouseEditSaving: false,
        isWarehouseEditSaved: false,
      }
    }

    case actionTypes.DELETE_WAREHOUSE_REQUESTED: {
      return {
        ...state,
        warehouseDeleteError: null,
        isWarehouseDeleting: true,
        isWarehouseDeleted: false,
        currentlyManagedWarehouseId: action.payload.id,
      }
    }

    case actionTypes.DELETE_WAREHOUSE_SUCCEEDED: {
      return {
        ...state,
        isWarehouseDeleting: false,
        isWarehouseDeleted: true,
        warehouses: state.warehouses.filter(
          (warehouse: actionTypes.TWarehouseLight) => warehouse.id !== action.warehouse.id
        ),
        currentlyManagedWarehouseId: null,
      }
    }

    case actionTypes.DELETE_WAREHOUSE_ERROR: {
      return {
        ...state,
        warehouseDeleteError: action.error,
        isWarehouseDeleting: false,
        isWarehouseDeleted: false,
        currentlyManagedWarehouseId: null,
      }
    }

    case actionTypes.OPEN_WAREHOUSE_DETAILS: {
      return {
        ...state,
        warehouseCreateError: null,
        warehouseEditSaveError: null,
        warehouseDeleteError: null,

        editedWarehouse: action.editedWarehouse,
      }
    }

    case actionTypes.CLOSE_WAREHOUSE_DETAILS: {
      return {
        ...state,
        warehouseCreateError: null,
        warehouseEditSaveError: null,
        warehouseDeleteError: null,
        singleWarehouseFetchError: null,

        isSingleWarehouseLoading: false,
        isWarehouseCreating: false,
        isWarehouseEditSaving: false,
        isWarehouseDeleting: false,

        editedWarehouse: null,
        isWarehouseCreated: false,
        isWarehouseEditSaved: false,
        isWarehouseDeleted: false,
      }
    }

    case actionTypes.WAREHOUSE_ACTIONS_UNLOCK: {
      return {
        ...state,
        isWarehouseEditSaved: false,
        isWarehouseCreated: false,
        isWarehouseDeleted: false,
        isWarehouseEditSaving: false,
        isWarehouseCreating: false,
        isWarehouseDeleting: false,
      }
    }

    // FETCH_WAREHOUSE_MANAGEMENT_DELIVERIES

    case actionTypes.FETCH_WAREHOUSE_MANAGEMENT_DELIVERIES_REQUESTED: {
      return {
        ...state,
        areManagedWarehouseDeliveriesLoading: true,
        fetchManagedWarehouseDeliveriesError: null,
        currentlyManagedWarehouseId: action?.payload?.id || null,
      }
    }

    case actionTypes.FETCH_WAREHOUSE_MANAGEMENT_DELIVERIES_SUCCEEDED: {
      return {
        ...state,
        areManagedWarehouseDeliveriesLoading: false,
        managedWarehouseDeliveries: action.managedWarehouseDeliveries,
      }
    }

    case actionTypes.FETCH_WAREHOUSE_MANAGEMENT_DELIVERIES_ERROR: {
      return {
        ...state,
        areManagedWarehouseDeliveriesLoading: false,
        fetchManagedWarehouseDeliveriesError: action.error,
      }
    }

    case actionTypes.SET_MANAGED_WAREHOUSE_DELIVERIES_TABLE_FILTER_STATE: {
      return {
        ...state,
        managedWarehouseDeliveriesTableFilterState: action.tableFilterState,
      }
    }

    case actionTypes.SET_MANAGED_WAREHOUSE_DELIVERIES_TABLE_SORTER_STATE: {
      return {
        ...state,
        managedWarehouseDeliveriesTableSorterState: action.tableSorterState,
      }
    }

    // SET_MANAGED_WAREHOUSE_ID

    case actionTypes.SET_CURRENTLY_MANAGED_WAREHOUSE_ID: {
      return {
        ...state,
        currentlyManagedWarehouseId: action.currentlyManagedWarehouseId,
      }
    }

    // WAREHOUSE_INVENTORY_CONTROL

    case actionTypes.FETCH_WAREHOUSE_INVENTORY_CONTROL_DATA_REQUESTED: {
      return {
        ...state,
        isWarehouseInventoryControlDataLoading: true,
        fetchWarehouseInventoryControlDataError: null,
      }
    }

    case actionTypes.FETCH_WAREHOUSE_INVENTORY_CONTROL_DATA_SUCCEEDED: {
      return {
        ...state,
        isWarehouseInventoryControlDataLoading: false,
        warehouseInventoryControlData: {
          inventoryControlTotalPrice: '',
          inventoryControlTotalPriceCurrency: '',
          details: action.warehouseInventoryControlData?.details || [],
        },
        fetchWarehouseInventoryControlDataError: null,
      }
    }

    case actionTypes.FETCH_WAREHOUSE_INVENTORY_CONTROL_DATA_ERROR: {
      return {
        ...state,
        isWarehouseInventoryControlDataLoading: false,
        fetchWarehouseInventoryControlDataError: action.error,
      }
    }

    // SAVE_WAREHOUSE_INVENTORY_CONTROL

    case actionTypes.SAVE_WAREHOUSE_INVENTORY_CONTROL_DATA_REQUESTED: {
      return {
        ...state,
        isWarehouseInventoryControlDataSaving: true,
        saveWarehouseInventoryControlError: null,
      }
    }

    case actionTypes.SAVE_WAREHOUSE_INVENTORY_CONTROL_DATA_SUCCEEDED: {
      return {
        ...state,
        isWarehouseInventoryControlDataSaving: false,
        isWarehouseInventoryControlDataSaved: true,
        saveWarehouseInventoryControlError: null,
      }
    }

    case actionTypes.SAVE_WAREHOUSE_INVENTORY_CONTROL_DATA_ERROR: {
      return {
        ...state,
        isWarehouseInventoryControlDataSaving: false,
        isWarehouseInventoryControlDataSaved: false,
        saveWarehouseInventoryControlError: action.error,
      }
    }

    case actionTypes.UNLOCK_WAREHOUSE_INVENTORY_CONTROL: {
      return {
        ...state,
        isWarehouseInventoryControlDataSaving: false,
        isWarehouseInventoryControlDataSaved: false,
        saveWarehouseInventoryControlError: null,
      }
    }

    case actionTypes.CLOSE_WAREHOUSE_INVENTORY_CONTROL: {
      return {
        ...state,
        isWarehouseInventoryControlDataSaving: false,
        isWarehouseInventoryControlDataSaved: false,
        saveWarehouseInventoryControlError: null,

        isWarehouseInventoryControlDataLoading: false,
        warehouseInventoryControlData: null,
        fetchWarehouseInventoryControlDataError: null,
      }
    }

    // INVENTORIES_OF_WAREHOUSES

    case actionTypes.FETCH_INVENTORIES_OF_WAREHOUSES_REQUESTED: {
      return {
        ...state,
        areInventoriesOfWarehousesLoading: true,
        fetchInventoriesOfWarehousesError: null,
      }
    }

    case actionTypes.FETCH_INVENTORIES_OF_WAREHOUSES_SUCCEEDED: {
      return {
        ...state,
        fetchInventoriesOfWarehousesError: null,
        areInventoriesOfWarehousesLoading: false,
        inventoriesOfWarehouses: action.inventoriesOfWarehouses,
      }
    }

    case actionTypes.FETCH_INVENTORIES_OF_WAREHOUSES_ERROR: {
      return {
        ...state,
        fetchInventoriesOfWarehousesError: action.error,
        areInventoriesOfWarehousesLoading: false,
      }
    }

    // MATERIALS_REPLENISHMENT

    case actionTypes.SET_DELIVERY_FROM_ORDER_IN_MATERIALS_REPLENISHMENT: {
      return {
        ...state,
        deliveryFromOrderInMaterialsReplenishment: action.deliveryFromOrder,
      }
    }

    case actionTypes.GENERATE_MATERIALS_REPLENISHMENT_DOCUMENT_REQUESTED: {
      return {
        ...state,
        isMaterialsReplenishmentDocumentGenerating: true,
        materialsReplenishmentDocumentGenerateError: null,
      }
    }

    case actionTypes.GENERATE_MATERIALS_REPLENISHMENT_DOCUMENT_SUCCEEDED: {
      return {
        ...state,
        isMaterialsReplenishmentDocumentGenerating: false,
        isMaterialsReplenishmentDocumentGenerated: true,
        materialsReplenishmentDocumentGenerateError: null,
      }
    }

    case actionTypes.GENERATE_MATERIALS_REPLENISHMENT_DOCUMENT_ERROR: {
      return {
        ...state,
        isMaterialsReplenishmentDocumentGenerating: false,
        isMaterialsReplenishmentDocumentGenerated: false,
        materialsReplenishmentDocumentGenerateError: action.error,
      }
    }

    case actionTypes.CLOSE_MATERIALS_REPLENISHMENT: {
      return {
        ...state,
        isMaterialsReplenishmentDocumentGenerating: false,
        isMaterialsReplenishmentDocumentGenerated: false,
        materialsReplenishmentDocumentGenerateError: null,
      }
    }

    // WARES_REPLENISHMENT

    case actionTypes.SET_DELIVERY_FROM_ORDER_IN_WARES_REPLENISHMENT: {
      return {
        ...state,
        deliveryFromOrderInWaresReplenishment: action.deliveryFromOrder,
      }
    }

    case actionTypes.GENERATE_WARES_REPLENISHMENT_DOCUMENT_REQUESTED: {
      return {
        ...state,
        isWaresReplenishmentDocumentGenerating: true,
        waresReplenishmentDocumentGenerateError: null,
      }
    }

    case actionTypes.GENERATE_WARES_REPLENISHMENT_DOCUMENT_SUCCEEDED: {
      return {
        ...state,
        isWaresReplenishmentDocumentGenerating: false,
        isWaresReplenishmentDocumentGenerated: true,
        waresReplenishmentDocumentGenerateError: null,
      }
    }

    case actionTypes.GENERATE_WARES_REPLENISHMENT_DOCUMENT_ERROR: {
      return {
        ...state,
        isWaresReplenishmentDocumentGenerating: false,
        isWaresReplenishmentDocumentGenerated: false,
        waresReplenishmentDocumentGenerateError: action.error,
      }
    }

    case actionTypes.CLOSE_WARES_REPLENISHMENT: {
      return {
        ...state,
        isWaresReplenishmentDocumentGenerating: false,
        isWaresReplenishmentDocumentGenerated: false,
        waresReplenishmentDocumentGenerateError: null,
      }
    }

    // FINISHED_PRODUCTS_REPLENISHMENT

    case actionTypes.GENERATE_FINISHED_PRODUCTS_REPLENISHMENT_DOCUMENT_REQUESTED: {
      return {
        ...state,
        isFinishedProductsReplenishmentDocumentGenerating: true,
        finishedProductsReplenishmentDocumentGenerateError: null,
      }
    }

    case actionTypes.GENERATE_FINISHED_PRODUCTS_REPLENISHMENT_DOCUMENT_SUCCEEDED: {
      return {
        ...state,
        isFinishedProductsReplenishmentDocumentGenerating: false,
        isFinishedProductsReplenishmentDocumentGenerated: true,
        finishedProductsReplenishmentDocumentGenerateError: null,
      }
    }

    case actionTypes.GENERATE_FINISHED_PRODUCTS_REPLENISHMENT_DOCUMENT_ERROR: {
      return {
        ...state,
        isFinishedProductsReplenishmentDocumentGenerating: false,
        isFinishedProductsReplenishmentDocumentGenerated: false,
        finishedProductsReplenishmentDocumentGenerateError: action.error,
      }
    }

    case actionTypes.CLOSE_FINISHED_PRODUCTS_REPLENISHMENT: {
      return {
        ...state,
        isFinishedProductsReplenishmentDocumentGenerating: false,
        isFinishedProductsReplenishmentDocumentGenerated: false,
        finishedProductsReplenishmentDocumentGenerateError: null,
      }
    }

    // MOVE_REPLENISHMENT

    case actionTypes.GENERATE_MOVE_REPLENISHMENT_DOCUMENT_REQUESTED: {
      return {
        ...state,
        isMoveReplenishmentDocumentGenerating: true,
        moveReplenishmentDocumentGenerateError: null,
      }
    }

    case actionTypes.GENERATE_MOVE_REPLENISHMENT_DOCUMENT_SUCCEEDED: {
      return {
        ...state,
        isMoveReplenishmentDocumentGenerating: false,
        isMoveReplenishmentDocumentGenerated: true,
        moveReplenishmentDocumentGenerateError: null,
      }
    }

    case actionTypes.GENERATE_MOVE_REPLENISHMENT_DOCUMENT_ERROR: {
      return {
        ...state,
        isMoveReplenishmentDocumentGenerating: false,
        isMoveReplenishmentDocumentGenerated: false,
        moveReplenishmentDocumentGenerateError: action.error,
      }
    }

    case actionTypes.CLEAR_MOVE_REPLENISHMENT_ERROR: {
      return {
        ...state,

        moveReplenishmentDocumentGenerateError: null,
      }
    }

    case actionTypes.CLOSE_MOVE_REPLENISHMENT: {
      return {
        ...state,
        isMoveReplenishmentDocumentGenerating: false,
        isMoveReplenishmentDocumentGenerated: false,
        moveReplenishmentDocumentGenerateError: null,
      }
    }

    default:
      return state
  }
}
