import React from 'react'
import { useDispatch } from 'react-redux'
import { CButton } from '@coreui/react'

import { ConfirmActionModal, ThreeDots } from './index'
import { confirmAlert } from 'react-confirm-alert'
import { TFormMode } from '../store/action-types'

export type TDeleteDispatch = {
  deleteAction: string | null
  deletePayload: {
    tenant: string
    token: string | undefined
    id: number | undefined
    userId?: string
  } | null
}

export type TFormState = {
  isSaving: boolean
  isSaved: boolean
  isDeleting: boolean
  isDeleted: boolean
  setIsOneFieldChanged?: (arg: boolean) => void
  phrasesInDelete?: string[]
  canDelete?: boolean
}

export interface IFormActionCancelButton {
  closeFunction: () => any
  closeAction: string
}

export interface IFormActionSaveButton extends TFormState {
  mode: TFormMode
  formErrorsBool: boolean
  setDidFormValidationOccur: any
  didFormValidationOccur: boolean
  canSave?: boolean
}

export interface IFormActionDeleteButton extends TDeleteDispatch, TFormState {
  disabledDeleteButtonClassNames?: string
  confirmDeleteMessageJSX?: JSX.Element
}

export interface IFormActionsPanel
  extends IFormActionSaveButton,
    IFormActionCancelButton,
    IFormActionDeleteButton {
  padding: string
  isEditable?: boolean
}

export const FormActionDeleteButton: React.FC<IFormActionDeleteButton> = ({
  isSaving,
  isSaved,
  isDeleted,
  isDeleting,
  deleteAction,
  deletePayload,
  phrasesInDelete,
  setIsOneFieldChanged,
  canDelete = true,
  disabledDeleteButtonClassNames,
  confirmDeleteMessageJSX = <>Czy na pewno chcesz usunąć obiekt?</>,
}) => {
  const dispatch = useDispatch()

  return (
    <CButton
      color="danger"
      className={`delete-button ${
        disabledDeleteButtonClassNames && !canDelete
          ? disabledDeleteButtonClassNames
          : `${!canDelete}`
      }`}
      variant={isDeleting ? '' : 'outline'}
      type="button"
      style={
        phrasesInDelete
          ? {
              width:
                // zamówien is not an error here
                phrasesInDelete[0].includes('zamówien') && (isDeleted || isDeleting)
                  ? '195px'
                  : phrasesInDelete[0] === 'BOM'
                  ? '130px'
                  : '150px',
            }
          : {}
      }
      onClick={() => {
        setIsOneFieldChanged && setIsOneFieldChanged(false)

        confirmAlert({
          closeOnEscape: true,
          customUI: ({ onClose }) => {
            return (
              <ConfirmActionModal
                mode="delete"
                onClose={onClose}
                confirmMessageJSX={confirmDeleteMessageJSX}
                onConfirmFunction={() =>
                  dispatch({
                    type: deleteAction,
                    payload: deletePayload,
                  })
                }
              />
            )
          },
        })
      }}
      disabled={!canDelete || isDeleting || isSaving || isSaved || isDeleted}
    >
      {isDeleting ? (
        <>
          Usuwanie{`${phrasesInDelete ? ` ${phrasesInDelete[1]}` : ''}`}
          <ThreeDots />
        </>
      ) : isDeleted ? (
        <>Usunięto{`${phrasesInDelete ? ` ${phrasesInDelete[0]}` : ''}`}!</>
      ) : (
        <>Usuń{`${phrasesInDelete ? ` ${phrasesInDelete[0]}` : ''}`}</>
      )}
    </CButton>
  )
}

export const FormActionCancelButton: React.FC<IFormActionCancelButton> = ({
  closeFunction,
  closeAction,
}) => {
  const dispatch = useDispatch()

  return (
    <CButton
      color="primary"
      className="cancel-button"
      variant="outline"
      type="button"
      onClick={() => {
        closeFunction ? closeFunction() : dispatch({ type: closeAction })
      }}
    >
      Wyjdź
    </CButton>
  )
}

export const FormActionSaveButton: React.FC<IFormActionSaveButton> = ({
  mode,
  isSaving,
  isSaved,
  isDeleted,
  isDeleting,
  formErrorsBool,
  setDidFormValidationOccur,
  didFormValidationOccur,
  canSave = true,
}) => {
  return (
    <CButton
      color="success"
      className="save-button ml-4"
      type="submit"
      onClick={() => {
        setDidFormValidationOccur && setDidFormValidationOccur(true)
      }}
      disabled={
        !canSave ||
        isDeleting ||
        isDeleted ||
        (!isSaving && isSaved ? true : isSaving) ||
        isSaved ||
        (didFormValidationOccur ? formErrorsBool : false)
      }
    >
      {isSaving ? (
        <>
          {mode === 'create' ? 'Dodawanie' : 'Zapisywanie'}
          <ThreeDots />
        </>
      ) : isSaved ? (
        <>{mode === 'create' ? 'Dodano!' : 'Zapisano!'}</>
      ) : mode === 'create' ? (
        'Dodaj'
      ) : (
        'Zapisz'
      )}
    </CButton>
  )
}

export const FormActionsPanel: React.FC<IFormActionsPanel> = ({
  mode,
  padding,
  isSaving,
  isSaved,
  isDeleted,
  isDeleting,
  deletePayload,
  deleteAction,
  formErrorsBool,
  setDidFormValidationOccur,
  didFormValidationOccur,
  closeAction,
  closeFunction,
  phrasesInDelete,
  setIsOneFieldChanged,
  canDelete,
  isEditable = true,
  disabledDeleteButtonClassNames,
  confirmDeleteMessageJSX,
}) => {
  return (
    <div
      className={`${padding} d-flex ${
        mode === 'edit' ? 'justify-content-between' : 'justify-content-end'
      }`}
    >
      {(mode === 'edit' && deletePayload && deleteAction && isEditable && (
        <FormActionDeleteButton
          isSaving={isSaving}
          isSaved={isSaved}
          isDeleting={isDeleting}
          isDeleted={isDeleted}
          deleteAction={deleteAction}
          deletePayload={deletePayload}
          phrasesInDelete={phrasesInDelete}
          setIsOneFieldChanged={setIsOneFieldChanged}
          canDelete={canDelete}
          disabledDeleteButtonClassNames={disabledDeleteButtonClassNames}
          confirmDeleteMessageJSX={confirmDeleteMessageJSX}
        />
      )) || (
        // This line is used to move the save and cancel buttons to the right when we do not need to delete an item
        <div></div>
      )}

      <div>
        <FormActionCancelButton closeFunction={closeFunction} closeAction={closeAction} />

        {isEditable && (
          <FormActionSaveButton
            mode={mode}
            isSaving={isSaving}
            isSaved={isSaved}
            isDeleted={isDeleted}
            isDeleting={isDeleting}
            formErrorsBool={formErrorsBool}
            setDidFormValidationOccur={setDidFormValidationOccur}
            didFormValidationOccur={didFormValidationOccur}
          />
        )}
      </div>
    </div>
  )
}
