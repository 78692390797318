import { TNetworkError } from '.'

export const FETCH_TODAY_CURRENCY_EXCHANGE_REQUESTED = 'FETCH_TODAY_CURRENCY_EXCHANGE_REQUESTED'
export const FETCH_TODAY_CURRENCY_EXCHANGE_SUCCEEDED = 'FETCH_TODAY_CURRENCY_EXCHANGE_SUCCEEDED'
export const FETCH_TODAY_CURRENCY_EXCHANGE_ERROR = 'FETCH_TODAY_CURRENCY_EXCHANGE_ERROR'

export const FETCH_SINGLE_ATTACHMENT_REQUESTED = 'FETCH_SINGLE_ATTACHMENT_REQUESTED'
export const FETCH_SINGLE_ATTACHMENT_SUCCEEDED = 'FETCH_SINGLE_ATTACHMENT_SUCCEEDED'
export const FETCH_SINGLE_ATTACHMENT_ERROR = 'FETCH_SINGLE_ATTACHMENT_ERROR'

export const DELETE_SINGLE_ATTACHMENT_REQUESTED = 'DELETE_SINGLE_ATTACHMENT_REQUESTED'
export const DELETE_SINGLE_ATTACHMENT_SUCCEEDED = 'DELETE_SINGLE_ATTACHMENT_SUCCEEDED'
export const DELETE_SINGLE_ATTACHMENT_ERROR = 'DELETE_SINGLE_ATTACHMENT_ERROR'
export const CLEAR_SINGLE_ATTACHMENT_ERRORS = 'CLEAR_SINGLE_ATTACHMENT_ERRORS'

export const SET_SIDEBAR_VISIBILITY = 'SET_SIDEBAR_VISIBILITY'

export const SET_SIDEBAR_MINIMIZE = 'SET_SIDEBAR_MINIMIZE'

export type TFormMode = 'create' | 'edit'

export type TAdminPayload = { tenant: string; token: string }

export type TSorterState = {
  column: string
  asc: boolean
}

export type TFilterState = string | undefined

export type TLightItem = {
  id: number
  name?: string
}

export type TNBPExchangeRate = {
  code: string
  currency: string
  mid: number
}

export interface IAttachmentsInfo {
  entityId: number
  // 1 - BomElement
  // 2 - Offer
  // 3 - Service
  // 4 - Order
  // 5 - Implementation Cost
  fileAttachmentTypeId: 1 | 2 | 3 | 4 | 5
}

export interface IAttachmentUpload extends IAttachmentsInfo {
  // uuid of details view that attachments belongs to, for example uuid of whole bomElement
  entityUuid: string

  type: string
  name: string
  altName: string | undefined | null
  base64EncodedFile: string
}

export interface IUploadSingleAttachmentPayload extends TAdminPayload {
  uploadAttachment: IAttachmentUpload
}

export interface IFetchItemAttachmentsPayload extends TAdminPayload, IAttachmentsInfo {}

export interface IAttachmentFetch extends TAdminPayload {
  id: number
  attachmentType: string
  fileName: string
  download: boolean
}

export interface IGeneratedOrderPDFInfo extends TAdminPayload {
  id: number
  type: string
  name: string
  download: boolean
}

export type TAttachment = {
  id: number
  name: string
  displayName: string
  type?: string
  altName?: string
}

export type TLightItemInList = {
  name: string
  id: number | string
  canDelete: boolean
}

export interface ISingleItemActionPayload extends TAdminPayload {
  id: number
}

export interface IEditItemOnListPayload extends TLightItem, TAdminPayload {}

export type TFixerExchangeRate = {
  [name: string]: number
}

// SET_SIDEBAR_VISIBILITY

export interface setSidebarVisibilityAction {
  type: typeof SET_SIDEBAR_VISIBILITY
  sidebarVisibility: false | 'responsive'
}

// SET_SIDEBAR_MINIMIZE

export interface setSidebarMinimizeAction {
  type: typeof SET_SIDEBAR_MINIMIZE
  sidebarMinimize: boolean
}

// FETCH_TODAY_CURRENCY_EXCHANGE_REQUESTED

export interface fetchTodayCurrencyExchangeAction {
  type: typeof FETCH_TODAY_CURRENCY_EXCHANGE_REQUESTED
  payload: string
}

export interface fetchTodayCurrencyExchangeSucceeded {
  type: typeof FETCH_TODAY_CURRENCY_EXCHANGE_SUCCEEDED
  todayCurrencyExchange: any
}

export interface fetchTodayCurrencyExchangeError {
  type: typeof FETCH_TODAY_CURRENCY_EXCHANGE_ERROR
  error: TNetworkError
}

// FETCH_SINGLE_ATTACHMENT_REQUESTED

export interface fetchSingleAttachmentAction {
  type: typeof FETCH_SINGLE_ATTACHMENT_REQUESTED
  payload: IAttachmentFetch
  section: string
}

export interface fetchSingleAttachmentSucceeded {
  type: typeof FETCH_SINGLE_ATTACHMENT_SUCCEEDED
}

export interface fetchSingleAttachmentError {
  type: typeof FETCH_SINGLE_ATTACHMENT_ERROR
  error: TNetworkError
}

// DELETE_SINGLE_ATTACHMENT_REQUESTED

export interface deleteSingleAttachmentAction {
  type: typeof DELETE_SINGLE_ATTACHMENT_REQUESTED
  payload: IAttachmentFetch
  section: string
}

export interface deleteSingleAttachmentSucceeded {
  type: typeof DELETE_SINGLE_ATTACHMENT_SUCCEEDED
}

export interface deleteSingleAttachmentError {
  type: typeof DELETE_SINGLE_ATTACHMENT_ERROR
  error: TNetworkError
}

export interface clearSingleAttachmentErrors {
  type: typeof CLEAR_SINGLE_ATTACHMENT_ERRORS
}

export type AdminActionTypes =
  | setSidebarVisibilityAction
  | setSidebarMinimizeAction
  | fetchTodayCurrencyExchangeAction
  | fetchTodayCurrencyExchangeSucceeded
  | fetchTodayCurrencyExchangeError
  | fetchSingleAttachmentAction
  | fetchSingleAttachmentSucceeded
  | fetchSingleAttachmentError
  | deleteSingleAttachmentAction
  | deleteSingleAttachmentSucceeded
  | deleteSingleAttachmentError
  | clearSingleAttachmentErrors
