import React, { SyntheticEvent, useEffect, useMemo } from 'react'
import { Container } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { CDataTable, CCard, CCardBody, CButton } from '@coreui/react'

import * as actionTypes from '../../store/action-types'
import { TRootState } from '../../store/reducers'
import { getEmptyListMessage, makeSearchBoxFixed } from '../../utils'
import { ListActionButton } from '../../components'

const Users: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tenant } = useParams<{ tenant: string }>()

  const authState = useSelector((state: TRootState) => state.auth)
  const usersState = useSelector((state: TRootState) => state.users)

  // Make search box fixed
  useEffect(() => {
    makeSearchBoxFixed()
  }, [makeSearchBoxFixed])

  const memoizedTableItems = useMemo(
    () =>
      usersState?.users?.map((user: actionTypes.TUser) => {
        return {
          ...user,
          userName: user?.userName || '',
          firstName: user?.firstName || '',
          lastName: user?.lastName || '',
          userProfiles: user?.userProfiles
            ? user?.userProfiles
                ?.map((userProfile: actionTypes.TLightUserProfile) => userProfile.name)
                .join(', ')
            : '',
          icon: '🖊️',
        }
      }),
    [usersState.users]
  )

  return (
    <Container className="d-flex flex-column align-items-center justify-content-between">
      <CCard>
        <CCardBody className="pb-2">
          {authState?.authData?.roles?.includes('Users_write') && (
            <div
              style={{ zIndex: 10 }}
              className="d-flex justify-content-end list-fixed-create-new-button-wrapper"
            >
              <CButton
                color="info"
                variant="outline"
                onClick={() => {
                  history.push(`/${tenant}/admin/users/create`)
                }}
                // Do not remove this nor change to mr-4
                style={{ marginRight: '105px' }}
              >
                Nowy Użytkownik
              </CButton>

              <CButton
                color="info"
                variant="outline"
                onClick={() => {
                  history.push(`/${tenant}/admin/profiles`)
                }}
              >
                Profile
              </CButton>
            </div>
          )}

          <CDataTable
            tableFilterValue={usersState?.tableFilterState}
            sorterValue={usersState?.tableSorterState}
            onSorterValueChange={(sorterState: actionTypes.TSorterState) => {
              dispatch({
                type: actionTypes.SET_USERS_TABLE_SORTER_STATE,
                tableSorterState: sorterState,
              })
            }}
            onTableFilterChange={(tableFilterState: string) => {
              dispatch({
                type: actionTypes.SET_USERS_TABLE_FILTER_STATE,
                tableFilterState: tableFilterState,
              })
            }}
            loading={usersState.areUsersLoading}
            striped={usersState.users ? !!usersState.users.length : false}
            border
            sorter
            tableFilter={{ label: ' ', placeholder: 'Szukaj w tabeli...' }}
            cleaner
            onRowClick={(user: actionTypes.TUser) => {
              history.push(`/${tenant}/admin/users/edit/${user.userId}`)
            }}
            addTableClasses="vertical-middle-list-table purchase-plans-list-table"
            fields={[
              {
                key: 'icon',
                label: '',
                sorter: false,
                filter: false,
                _style: {
                  width: '40px',
                  display: !usersState.users.length ? 'none' : '',
                },
              },
              {
                key: 'userName',
                label: 'Polyend ID',
                _style: { width: '250px' },
              },
              {
                key: 'firstName',
                label: 'Imię',
                _style: { width: '200px' },
              },
              {
                key: 'lastName',
                label: 'Nazwisko',
                _style: { width: '200px' },
              },
              {
                key: 'userProfiles',
                label: 'Profile',
                _style: { width: '200px' },
              },
              {
                key: 'delete',
                label: '',
                _style: { width: '45px' },
              },
            ]}
            items={memoizedTableItems}
            noItemsViewSlot={
              <div className="no-items-in-table">
                {getEmptyListMessage(
                  usersState.fetchUsersError,
                  usersState.areUsersLoading,
                  'użytkowników',
                  usersState?.users?.length
                )}
              </div>
            }
            scopedSlots={{
              delete: (itemFromList: actionTypes.TUser) => (
                <td
                  className="text-center"
                  style={{ pointerEvents: 'none' }}
                  onClick={(event: SyntheticEvent) => {
                    if (!itemFromList.canDelete) {
                      event.preventDefault()
                      event.stopPropagation()
                      return null
                    } else {
                      return undefined
                    }
                  }}
                >
                  <ListActionButton
                    mainActionName="delete"
                    buttonColor="danger"
                    classNames="delete-list-item-button"
                    typeOfAction={actionTypes.DELETE_USER_REQUESTED}
                    iconName="cil-trash"
                    itemFromList={{
                      id: itemFromList?.userId,
                      name: itemFromList?.userName ? `użytkownika ${itemFromList?.userName}` : '',
                      canDelete: true,
                    }}
                    currentlyManagedItemId={usersState.currentlyManagedUserId}
                    // Change this when those two options will be added later maybe
                    isItemCopying={false}
                    isItemArchiving={false}
                    isItemDeleting={usersState.isUserDeleting}
                  />
                </td>
              ),
            }}
          />
        </CCardBody>
      </CCard>
    </Container>
  )
}

export default Users
