import React, { useEffect, useMemo } from 'react'
import { Container } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { CDataTable, CCard, CCardBody, CButton } from '@coreui/react'

import * as actionTypes from '../../store/action-types'
import { TRootState } from '../../store/reducers'
import {
  convertNumbersInTableRows,
  getEmptyListMessage,
  makeSearchBoxFixed,
  toDateTableValue,
} from '../../utils'
import { minimumWidthForWideDetailsView } from '../admin'

const Offers: React.FC<{}> = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tenant } = useParams<{ tenant: string }>()

  const offersState = useSelector((state: TRootState) => state.offers)
  const authState = useSelector((state: TRootState) => state.auth)

  // Make search box fixed
  useEffect(() => {
    makeSearchBoxFixed()
  }, [makeSearchBoxFixed])

  useEffect(() => {
    if (window.innerWidth < minimumWidthForWideDetailsView) {
      dispatch({ type: actionTypes.SET_SIDEBAR_VISIBILITY, sidebarVisibility: false })
    }
  }, [dispatch])

  const memoizedTableItems = useMemo(
    () =>
      offersState?.offers?.map((offer: actionTypes.TLightOfferInTable) => {
        return {
          ...offer,
          icon: '🖊️',
          offerNumber: offer?.offerNumber || '',
          customNumber: offer?.customOfferNumber || '',
          bomElementName: `${offer?.bomElementName || ''}${
            offer?.bomElementMpn ? ` / ${offer?.bomElementMpn}` : ''
          }${offer?.bomElementDin ? ` / ${offer?.bomElementDin}` : ''}${
            offer?.implementationCostId || offer?.serviceId ? '\n' : ''
          }${offer?.implementationCostId ? '🔧' : ''} ${offer?.serviceId ? '⚙️' : ''}`,
          supplierName: offer?.supplierName || '',
          quantity: offer?.quantity || '',
          bomElementPrice: offer?.bomElementPrice || '',
          finalPrice: (offer?.finalPrice && Math.round(offer?.finalPrice * 100) / 100) || '',
          bomElementPriceCurrency: offer?.bomElementPriceCurrency || '',
          expirationDate: toDateTableValue(offer?.expirationDate) || '',
          productionLeadTime: offer?.productionLeadTime || '',
          deliveryLeadTime: offer?.deliveryLeadTime || '',
        }
      }),
    [offersState.offers]
  )

  // Here the numbers are converted in table on each state change of the table
  useEffect(() => {
    convertNumbersInTableRows(offersState.offers, [6, 7], [5])
  }, [memoizedTableItems, offersState.tableFilterState, offersState.tableSorterState])

  return (
    <Container
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ maxWidth: '1500px' }}
    >
      <CCard>
        <CCardBody className="pb-2">
          {authState?.authData?.roles?.includes('Offers_write') && (
            <div className="d-flex justify-content-end list-fixed-create-new-button-wrapper">
              <CButton
                color="info"
                variant="outline"
                onClick={() => {
                  history.push(`/${tenant}/admin/offers/create/information`)
                }}
              >
                Nowa Oferta
              </CButton>
            </div>
          )}

          <CDataTable
            tableFilterValue={offersState?.tableFilterState}
            sorterValue={offersState?.tableSorterState}
            onSorterValueChange={(sorterState: actionTypes.TSorterState) => {
              dispatch({
                type: actionTypes.SET_OFFERS_TABLE_SORTER_STATE,
                tableSorterState: sorterState,
              })
            }}
            onTableFilterChange={(tableFilterState: string) => {
              dispatch({
                type: actionTypes.SET_OFFERS_TABLE_FILTER_STATE,
                tableFilterState: tableFilterState,
              })
            }}
            loading={offersState.areOffersLoading}
            striped={!!offersState.offers.length}
            border
            sorter
            tableFilter={{ label: ' ', placeholder: 'Szukaj w tabeli...' }}
            addTableClasses="vertical-middle-list-table offers-list-table"
            cleaner
            onRowClick={(offer: actionTypes.TOffer) => {
              history.push(`/${tenant}/admin/offers/edit/${offer.id}/information`)
            }}
            fields={[
              {
                key: 'icon',
                label: '',
                sorter: false,
                filter: false,
                _style: { width: '50px', display: !offersState.offers.length ? 'none' : '' },
              },
              {
                key: 'offerNumber',
                label: 'Numer wewnętrzny',
                _style: { width: '160px', textAlign: 'left' },
              },
              {
                key: 'customNumber',
                label: 'Numer oferty dostawcy',
                _style: { width: '180px', textAlign: 'left' },
              },
              {
                key: 'bomElementName',
                label: 'Element',
                _style: { width: '360px', textAlign: 'left' },
              },
              {
                key: 'supplierName',
                label: 'Dostawca',
                _style: { width: '200px', textAlign: 'left' },
              },
              { key: 'quantity', label: 'Ilość', _style: { width: '200px', textAlign: 'left' } },
              {
                key: 'bomElementPrice',
                label: 'Cena jedn.',
                _style: { width: '100px', textAlign: 'left' },
              },
              {
                key: 'finalPrice',
                label: 'Cena końcowa',
                _style: { width: '120px', textAlign: 'left' },
              },
              {
                key: 'bomElementPriceCurrency',
                label: 'CCY',
                _style: { width: '60px', textAlign: 'left' },
              },
              {
                key: 'expirationDate',
                label: 'Data wygaśnięcia',
                _style: { width: '125px', textAlign: 'left' },
              },
              {
                key: 'productionLeadTime',
                label: 'LT pr.',
                _style: { width: '50px', textAlign: 'left' },
              },
              {
                key: 'deliveryLeadTime',
                label: 'LT dos.',
                _style: { width: '50px', textAlign: 'left' },
              },
            ]}
            items={memoizedTableItems}
            noItemsViewSlot={
              <div className="no-items-in-table">
                {getEmptyListMessage(
                  offersState.fetchOffersError,
                  offersState.areOffersLoading,
                  'ofert',
                  offersState?.offers?.length
                )}
              </div>
            }
          />
        </CCardBody>
      </CCard>
    </Container>
  )
}

export default Offers
