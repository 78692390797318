import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { Container, Spinner } from 'reactstrap'
import { Formik, Form, FormikHandlers, FormikHelpers, Field } from 'formik'
import * as Yup from 'yup'
import {
  CTabs,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CCardBody,
  CCard,
  CInput,
} from '@coreui/react'
import deepEqual from 'deep-equal'

import * as actionTypes from '../../store/action-types'
import { TRootState } from '../../store/reducers'
import {
  BasicFormField,
  FormFixedActionsPanel,
  ThreeDots,
  CustomErrorMessage,
} from '../../components'
import {
  totalBomCostCalculator,
  successMessageDuration,
  getErrorMessageFromStatus,
  globalCurrencyFallback,
  preventNavigationChange,
  convertNumericStringToNumber,
} from '../../utils'
import { minimumWidthForWideDetailsView, offlineCurrencyExchangeRates, TFormTab } from '../admin'
import { BomDetailsElementsTable } from './bom-elements-table'
import { BomDetailsServicesTable } from './bom-services-table'
import { BomDetailsImplementationCostsTable } from './bom-implementation-costs-table'
import { BomDetailsGraphs } from './bom-graphs'
import { ITotalBomCostCalculatorValues } from '../../store/action-types'
import {
  BomDetailsCalculationsTable,
  getBomCalculationIncomeForAPI,
} from './bom-calculations-table'

export const numeralsTextAlign = 'left'
export const tableRowItemNameLeftPadding = '5px'

export interface IBomDetailsTableValues {
  values: ITotalBomCostCalculatorValues
  errors: any
  setFieldValue: FormikHelpers<any>['setFieldValue']
  handleChange: FormikHandlers['handleChange']
  setIsBomModifiedAndUnsaved: any
  isBomChangeOccuring: boolean
  didFormValidationOccur?: boolean
  validateForm?: FormikHelpers<any>['validateForm']
}

export const bomNavTabs: TFormTab[] = [
  { name: 'elements', title: 'Elementy', isEditOnly: false },
  { name: 'implementation-costs', title: 'Tooling', isEditOnly: false },
  { name: 'services', title: 'Usługi', isEditOnly: false },
  { name: 'graphs', title: 'Wykresy', isEditOnly: false },
  { name: 'calculations', title: 'Kalkulacje', isEditOnly: false },
]

export const BomDetails: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tenant, mode, id, tab } = useParams<{
    tenant: string
    tab: string
    mode: actionTypes.TFormMode
    id: string
  }>()
  const paramsBomTab = tab
  const paramsBomId = id

  const authState = useSelector((state: TRootState) => state.auth)
  const bomsState = useSelector((state: TRootState) => state.boms)
  const adminState = useSelector((state: TRootState) => state.admin)
  const settingsState = useSelector((state: TRootState) => state.settings)
  const [isBomModifiedAndUnsaved, setIsBomModifiedAndUnsaved] = useState<boolean>(false)
  const [didFormValidationOccur, setDidFormValidationOccur] = useState(false)

  const isBomEditable =
    authState?.authData?.roles?.includes('Boms_write') && !bomsState?.singleBomFetchError

  const exchangeRates = adminState.todayCurrencyExchange
    ? adminState.todayCurrencyExchange.rates
    : offlineCurrencyExchangeRates

  const isThereNetworkError =
    bomsState?.bomCreateError?.status ||
    bomsState?.bomEditSaveError?.status ||
    bomsState?.bomDeleteError?.status ||
    bomsState?.singleBomFetchError?.status

  const isBomChangeOccuring =
    bomsState.isBomCreating ||
    bomsState.isBomEditSaving ||
    bomsState.isBomDeleting ||
    bomsState.isBomCreated ||
    bomsState.isBomEditSaved ||
    bomsState.isBomDeleted

  const closeBomDetails = useCallback(() => {
    dispatch({ type: actionTypes.SET_SIDEBAR_VISIBILITY, sidebarVisibility: 'responsive' })
    history.push(`/${tenant}/admin/boms`)
  }, [dispatch, history, tenant])

  // Fetch data for BOM edit and clean store for BOM create
  useEffect(() => {
    if (tenant && authState.authData && paramsBomId && mode === 'edit') {
      dispatch({
        type: actionTypes.FETCH_SINGLE_BOM_REQUESTED,
        payload: { tenant: tenant, token: authState.authData?.token, id: paramsBomId },
      })
    } else if (mode === 'create') {
      dispatch({
        type: actionTypes.CLOSE_BOM_DETAILS,
      })
    }
  }, [dispatch, tenant, authState, paramsBomId, mode])

  // Hide sidebar on smaller screens
  useEffect(() => {
    if (window.innerWidth < minimumWidthForWideDetailsView) {
      dispatch({ type: actionTypes.SET_SIDEBAR_VISIBILITY, sidebarVisibility: false })
    }
  }, [dispatch])

  // Redirect on not found editable BOM
  useEffect(() => {
    if (bomsState.singleBomFetchError && bomsState.singleBomFetchError.status === 404) {
      history.replace(`/${tenant}/admin/boms`)
    }
  }, [dispatch, bomsState.singleBomFetchError, history, tenant])

  // Fix the path
  useEffect(() => {
    if (tenant && authState.authData) {
      const tabsArray = bomNavTabs?.map((navTab: TFormTab) =>
        mode === 'edit' ? navTab.name : !navTab.isEditOnly ? navTab.name : undefined
      )

      if (paramsBomTab === undefined || !tabsArray.includes(paramsBomTab)) {
        history.replace(
          `/${tenant}/admin/boms/${mode === 'create' ? 'create' : `edit/${paramsBomId}`}/${
            bomNavTabs[0].name
          }`
        )
      }
    }
  }, [tenant, authState, history, paramsBomId, paramsBomTab, mode])

  // Close BOM details on a successful delete
  useEffect(() => {
    if (!bomsState.isBomDeleting && !bomsState.bomDeleteError && bomsState.isBomDeleted) {
      setTimeout(() => {
        closeBomDetails()
      }, successMessageDuration)
    }
  }, [bomsState.isBomDeleting, bomsState.bomDeleteError, bomsState.isBomDeleted, closeBomDetails])

  // Close BOM details on a successful create and open edit window
  useEffect(() => {
    if (!bomsState.isBomCreating && !bomsState.bomCreateError && bomsState.isBomCreated) {
      setTimeout(() => {
        const newestBom = bomsState.boms[bomsState.boms.length - 1]

        if (newestBom) {
          setIsBomModifiedAndUnsaved(false)
          history.push(`/${tenant}/admin/boms/edit/${newestBom?.id}/${bomsState.lastTabOpen}`)
        }
      }, successMessageDuration)
    }
  }, [bomsState.isBomCreating, bomsState.bomCreateError, bomsState.isBomCreated])

  // Track the changes again
  useEffect(() => {
    if (bomsState.isBomEditSaving || bomsState.isBomCreating) {
      setIsBomModifiedAndUnsaved(false)
    }

    if (bomsState.isBomEditSaved || bomsState.isBomCreated || bomsState.isBomDeleted) {
      setTimeout(() => {
        setIsBomModifiedAndUnsaved(false)
      }, successMessageDuration)
    }

    if (bomsState.bomEditSaveError || bomsState.bomCreateError) {
      setIsBomModifiedAndUnsaved(true)
    }
  }, [
    bomsState.isBomEditSaving,
    bomsState.isBomCreating,
    bomsState.isBomDeleting,
    bomsState.isBomEditSaved,
    bomsState.isBomCreated,
    bomsState.isBomDeleted,
    bomsState.bomEditSaveError,
    bomsState.bomCreateError,
  ])

  // Prevent navigation back and forth plus reload if modified
  useEffect(() => {
    preventNavigationChange(
      history,
      isBomModifiedAndUnsaved,
      isBomEditable,
      'boms',
      paramsBomId,
      mode
    )
    // Without pathname in location there is no tab change detection
  }, [location.pathname, history, isBomModifiedAndUnsaved, isBomEditable])

  // Unmount Component
  useEffect(() => {
    return () => {
      dispatch({
        type: actionTypes.CLOSE_BOM_DETAILS,
      })
    }
  }, [])

  const unchosenCategoryCondition = (category: number | undefined) =>
    category !== undefined && category !== -1

  const bomElementObjectSchema = Yup.object().shape(
    {
      bomElementName: Yup.string().when(['category'], {
        is: (category) => unchosenCategoryCondition(category),
        then: Yup.string().required(),
      }),
      bomElementId: Yup.mixed().when(['category'], {
        is: (category) => unchosenCategoryCondition(category),
        then: Yup.number().required(),
      }),
      category: Yup.number()
        .when(['bomElementId'], {
          is: (bomElementId) => bomElementId,
          then: Yup.number().notOneOf([-1]),
        })
        .required(),
      quantity: Yup.string().when(['category'], {
        is: (category) => unchosenCategoryCondition(category),
        then: Yup.string().min(0.01, 'Zbyt mała ilość elementu').required('Ilość jest wymagana'),
      }),
      estimatedPrice: Yup.string().when(['category'], {
        is: (category) => unchosenCategoryCondition(category),
        then: Yup.string().required(),
      }),
      estimatedLeadTimeFrom: Yup.number().when(['category'], {
        is: (category) => unchosenCategoryCondition(category),
        then: Yup.number().min(1).required().nullable(),
      }),
      estimatedLeadTimeTo: Yup.number().when(['category'], {
        is: (category) => unchosenCategoryCondition(category),
        then: Yup.number().min(1).required().min(Yup.ref('estimatedLeadTimeFrom'), '-1').nullable(),
      }),
    },
    [['category', 'bomElementId']]
  )

  const BomSchema = Yup.object().shape(
    {
      name: Yup.string().required(' '),

      bomElementDetails: Yup.array().when(['bomImplementationCostDetails', 'bomServiceDetails'], {
        is: (bomImplementationCostDetails, bomServiceDetails) =>
          bomImplementationCostDetails?.length || bomServiceDetails?.length,

        then: Yup.array().of(bomElementObjectSchema),
        otherwise: Yup.array()
          .of(bomElementObjectSchema)
          .min(2, 'Wymagane minimum jeden element lub usługa lub tooling!')
          .required('Brak elementów w tabeli!'),
      }),
      bomImplementationCostDetails: Yup.array().when(['bomServiceDetails', 'bomElementDetails'], {
        is: (bomServiceDetails, bomElementDetails) =>
          bomElementDetails?.length || bomServiceDetails?.length,

        then: Yup.array().of(
          Yup.object().shape({
            bomImplementationCostName: Yup.string().required(),
            bomImplementationCostId: Yup.number().required(),
            quantity: Yup.number().min(1, 'Zbyt mała ilość').required('Ilość jest wymagana'),
            estimatedPrice: Yup.string().required().nullable(),
          })
        ),
        otherwise: Yup.array()
          .of(
            Yup.object().shape({
              bomImplementationCostName: Yup.string().required(),
              bomImplementationCostId: Yup.number().required(),
              quantity: Yup.number().min(1, 'Zbyt mała ilość').required('Ilość jest wymagana'),
              estimatedPrice: Yup.string().required().nullable(),
            })
          )
          .min(1, 'Wymagane minimum jeden element lub usługa lub tooling!')
          .required('Brak toolingu w tabeli!'),
      }),

      bomServiceDetails: Yup.array().when(['bomElementDetails', 'bomImplementationCostDetails'], {
        is: (bomElementDetails, bomImplementationCostDetails) =>
          bomElementDetails?.length || bomImplementationCostDetails?.length,

        then: Yup.array().of(
          Yup.object().shape({
            bomServiceName: Yup.string().required(),
            bomServiceId: Yup.number().required(),
            quantity: Yup.number().min(1, 'Zbyt mała ilość').required('Ilość jest wymagana'),
            estimatedPrice: Yup.string().required().nullable(),
          })
        ),
        otherwise: Yup.array()
          .of(
            Yup.object().shape({
              bomServiceName: Yup.string().required(),
              bomServiceId: Yup.number().required(),
              quantity: Yup.number().min(1, 'Zbyt mała ilość').required('Ilość jest wymagana'),
              estimatedPrice: Yup.string().required().nullable(),
            })
          )
          .min(1, 'Wymagane minimum jeden element lub usługa lub tooling!')
          .required('Brak usług w tabeli!'),
      }),

      bomCalculations: Yup.array().of(
        Yup.object().shape({
          msrp: Yup.string().required(),
          taxRate: Yup.string().required(),
          dealerMargin: Yup.string()
            .nullable()
            .test(
              'negativeDealerMargin',
              'Marża dealera jest ujemna!',
              (dealerMargin) => !dealerMargin?.includes('-')
            ),
          distributorMargin: Yup.string()
            .nullable()
            .test(
              'negativeDistributorMargin',
              'Marża dystrybutora jest ujemna!',
              (distributorMargin) => !distributorMargin?.includes('-')
            ),
        })
      ),
    },
    [
      ['bomElementDetails', 'bomImplementationCostDetails'],
      ['bomElementDetails', 'bomServiceDetails'],
      ['bomImplementationCostDetails', 'bomServiceDetails'],
      ['bomImplementationCostDetails', 'bomElementDetails'],
      ['bomServiceDetails', 'bomElementDetails'],
      ['bomServiceDetails', 'bomImplementationCostDetails'],
    ]
  )

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center">
      <CCard>
        <CCardBody className="bom-details" style={{ padding: '1.25rem 2.25rem' }}>
          {
            <Formik
              initialValues={{
                name: bomsState.editedBom?.name || '',
                estimatedQuantity: bomsState.editedBom?.estimatedQuantity || 1,
                purchasePlanQuantity: bomsState.editedBom?.purchasePlanQuantity || '',
                purchasePlanNumber: bomsState.editedBom?.purchasePlanNumber || '',
                purchasePlanId: bomsState.editedBom?.purchasePlanId,
                estimatedTotalBomCost: 0,
                estimatedTotalBomCostCurrency:
                  bomsState.editedBom?.estimatedTotalBomCostCurrency ||
                  settingsState?.settings?.otherSettings?.defaultSystemCurrency ||
                  globalCurrencyFallback,
                offerTotalBomCost: 0,
                offerTotalBomCostCurrency:
                  bomsState.editedBom?.offerTotalBomCostCurrency ||
                  settingsState?.settings?.otherSettings?.defaultSystemCurrency ||
                  globalCurrencyFallback,
                orderTotalBomCost: 0,
                orderTotalBomCostCurrency:
                  bomsState.editedBom?.orderTotalBomCostCurrency ||
                  settingsState?.settings?.otherSettings?.defaultSystemCurrency ||
                  globalCurrencyFallback,
                isTotalCostPerUnit: false,
                bomElementDetails:
                  mode === 'edit'
                    ? bomsState.editedBom?.bomElementDetails?.sort(
                        (
                          bomElementToSort: actionTypes.IBomFullElement,
                          nextBomElement: actionTypes.IBomFullElement
                        ) =>
                          Number(bomElementToSort?.category) - Number(nextBomElement?.category) ||
                          Number(bomElementToSort?.position) - Number(nextBomElement?.position)
                      ) || []
                    : [],
                bomServiceDetails: bomsState.editedBom?.bomServiceDetails || [],
                bomImplementationCostDetails:
                  bomsState.editedBom?.bomImplementationCostDetails || [],
                bomCalculations: bomsState.editedBom?.bomCalculations || [],
              }}
              validationSchema={BomSchema}
              onSubmit={(values) => {
                if (isBomEditable) {
                  setDidFormValidationOccur(true)

                  dispatch({
                    type:
                      mode === 'create'
                        ? actionTypes.CREATE_BOM_REQUESTED
                        : actionTypes.EDIT_BOM_REQUESTED,
                    payload: {
                      tenant: tenant,
                      token: authState.authData?.token,
                      lastTabOpen: mode === 'create' ? paramsBomId : paramsBomTab,
                      canDelete: bomsState.editedBom?.canDelete,
                      bom: {
                        name: values.name,
                        estimatedQuantity:
                          convertNumericStringToNumber(values?.estimatedQuantity) || null,
                        estimatedTotalBomCostCurrency: values.estimatedTotalBomCostCurrency,
                        offerTotalBomCostCurrency: values.offerTotalBomCostCurrency,
                        orderTotalBomCostCurrency: values.orderTotalBomCostCurrency,

                        bomElementDetails: values?.bomElementDetails
                          ?.map(
                            (
                              elementInBomTable: actionTypes.IBomFullElement,
                              indexOfElementInBomTable: number
                            ) => {
                              let {
                                bomElementMeasurementType,
                                bomElementName,
                                bomElementCentimetersInPiece,
                                offers,
                                offer,
                                lastOrderPrice,
                                lastOrderPriceCurrency,
                                cost,
                                ...destructuredElementInBomTable
                              } = elementInBomTable

                              return {
                                ...destructuredElementInBomTable,
                                position: indexOfElementInBomTable + 1,
                                quantity: convertNumericStringToNumber(elementInBomTable?.quantity),
                                estimatedPrice: convertNumericStringToNumber(
                                  elementInBomTable?.estimatedPrice
                                ),
                                offerId: elementInBomTable?.offerId || null,
                                estimatedLeadTimeFrom: convertNumericStringToNumber(
                                  elementInBomTable.estimatedLeadTimeFrom
                                ),
                                estimatedLeadTimeTo: convertNumericStringToNumber(
                                  elementInBomTable.estimatedLeadTimeTo
                                ),
                                cost: convertNumericStringToNumber(elementInBomTable?.cost) || null,
                              }
                            }
                          )
                          ?.filter((elementInBomTable: any) => elementInBomTable?.category !== -1),

                        bomImplementationCostDetails: values?.bomImplementationCostDetails?.map(
                          (implementationCostInBomTable: actionTypes.IBomImplementationCost) => {
                            let {
                              offer,
                              lastOrderPrice,
                              lastOrderPriceCurrency,
                              cost,
                              ...destructuredImplementationCostInBomTable
                            } = implementationCostInBomTable

                            return {
                              ...destructuredImplementationCostInBomTable,
                              quantity: convertNumericStringToNumber(
                                implementationCostInBomTable?.quantity
                              ),
                              estimatedPrice: convertNumericStringToNumber(
                                implementationCostInBomTable?.estimatedPrice
                              ),
                            }
                          }
                        ),

                        bomServiceDetails: values?.bomServiceDetails?.map(
                          (serviceInBomTable: actionTypes.IBomService) => {
                            let {
                              offer,
                              lastOrderPrice,
                              lastOrderPriceCurrency,
                              cost,
                              ...destructuredServiceInBomTable
                            } = serviceInBomTable

                            return {
                              ...destructuredServiceInBomTable,
                              quantity: convertNumericStringToNumber(serviceInBomTable?.quantity),
                              estimatedPrice: convertNumericStringToNumber(
                                serviceInBomTable?.estimatedPrice
                              ),
                            }
                          }
                        ),

                        bomCalculations: values?.bomCalculations?.map(
                          (bomCalculation: actionTypes.IBomCalculation) => {
                            return {
                              ...bomCalculation,
                              msrp: convertNumericStringToNumber(bomCalculation?.msrp),
                              msrpCurrency: bomCalculation.msrpCurrency,
                              taxRate:
                                String(bomCalculation.taxRate) === '0'
                                  ? 0
                                  : convertNumericStringToNumber(bomCalculation.taxRate) || null,

                              dealerMargin:
                                bomCalculation?.dealerMargin === '0' ||
                                bomCalculation?.dealerMargin === 0
                                  ? 0
                                  : convertNumericStringToNumber(bomCalculation?.dealerMargin) ||
                                    null,
                              dealerPrice:
                                convertNumericStringToNumber(bomCalculation?.dealerPrice) || null,
                              dealerPriceCurrency: bomCalculation.dealerPriceCurrency,
                              dealerQuantity: convertNumericStringToNumber(
                                bomCalculation.dealerQuantity
                              ),
                              distributorMargin:
                                bomCalculation?.distributorMargin === '0' ||
                                bomCalculation?.distributorMargin === 0
                                  ? 0
                                  : convertNumericStringToNumber(
                                      bomCalculation?.distributorMargin
                                    ) || null,
                              distributorPrice:
                                convertNumericStringToNumber(bomCalculation?.distributorPrice) ||
                                null,
                              distributorPriceCurrency: bomCalculation.distributorPriceCurrency,
                              distributorQuantity: convertNumericStringToNumber(
                                bomCalculation.distributorQuantity
                              ),
                              income: getBomCalculationIncomeForAPI(bomCalculation, exchangeRates),
                              incomeCurrency: bomCalculation.incomeCurrency,
                              bomId: mode === 'create' ? 0 : Number(paramsBomId),
                            }
                          }
                        ),

                        totalCost: totalBomCostCalculator(
                          values,
                          adminState,
                          'estimated',
                          values?.isTotalCostPerUnit
                        ),
                        ...(bomsState.editedBom?.id && { id: Number(bomsState.editedBom?.id) }),
                      },
                    },
                  })
                }
              }}
              // Run tests if this does not break forms on change events
              enableReinitialize={true}
              /* Do not use that as it creates a very big lag while typing in a big dynamic form */
              validateOnBlur={false}
              validateOnChange={false}
            >
              {({ initialValues, values, errors, setFieldValue, handleChange, validateForm }) => (
                <Form
                  onChange={() => {
                    // Values here are always 1 step behind
                    let isModified = !deepEqual(values, initialValues, { strict: false })

                    if (isModified) {
                      /* If form is brought to its initial state then it is not modified */
                      setIsBomModifiedAndUnsaved(true)
                    } else {
                      setIsBomModifiedAndUnsaved(false)
                    }
                  }}
                >
                  <div
                    className={`form-fixed-panel-in-header form-fixed-panel-in-header--bom ${
                      adminState.sidebarVisibility === 'responsive' && !adminState.sidebarMinimize
                        ? 'form-fixed-panel-in-header--bom-smaller-screen-with-sidebar'
                        : ''
                    } ${
                      adminState.sidebarMinimize && adminState.sidebarVisibility
                        ? 'form-fixed-panel-in-header--bom-smaller-screen-with-sidebar-minimized'
                        : ''
                    }`}
                  >
                    {/*
                     *
                     */}

                    <FormFixedActionsPanel
                      title={
                        !bomsState.isSingleBomLoading ? (
                          mode === 'edit' ? (
                            `BOM ${
                              initialValues?.name ||
                              bomsState?.boms?.find(
                                (bom: actionTypes.TBomLight) => Number(paramsBomId) === bom.id
                              )?.name ||
                              '...'
                            }`
                          ) : (
                            'Nowy BOM'
                          )
                        ) : (
                          <>
                            BOM
                            <ThreeDots />
                          </>
                        )
                      }
                      mode={mode}
                      section="bom"
                      isSaving={
                        mode === 'create' ? bomsState.isBomCreating : bomsState.isBomEditSaving
                      }
                      isSaved={
                        mode === 'create' ? bomsState.isBomCreated : bomsState.isBomEditSaved
                      }
                      isDeleting={bomsState.isBomDeleting}
                      isDeleted={bomsState.isBomDeleted}
                      didFormValidationOccur={false}
                      formErrorsBool={
                        !Boolean(
                          errors.name ||
                            errors.bomElementDetails ||
                            errors.bomImplementationCostDetails ||
                            errors.bomServiceDetails ||
                            errors.bomCalculations
                        )
                      }
                      closeAction={actionTypes.CLOSE_BOM_DETAILS}
                      deleteAction={actionTypes.DELETE_BOM_REQUESTED}
                      deletePayload={{
                        tenant: tenant,
                        token: authState.authData?.token,
                        id: bomsState.editedBom?.id,
                      }}
                      closeFunction={closeBomDetails}
                      setDidFormValidationOccur={setDidFormValidationOccur}
                      canDelete={
                        bomsState.isSingleBomLoading ? false : bomsState?.editedBom?.canDelete
                      }
                      isEditable={isBomEditable}
                      disabledDeleteButtonClassNames="delete-details-button"
                      canSave={!bomsState.isSingleBomLoading}
                      confirmDeleteMessageJSX={
                        <>
                          Czy na pewno chcesz usunąć BOM?
                          {initialValues?.name ? (
                            <>
                              <br />
                              <strong>{initialValues?.name}</strong>
                            </>
                          ) : (
                            ''
                          )}
                        </>
                      }
                    />
                  </div>

                  {bomsState.isSingleBomLoading ? (
                    <div
                      style={{
                        height: '250px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Spinner />
                    </div>
                  ) : (
                    <>
                      {/*
                       * Display Network Error Message
                       */}

                      {bomsState?.bomCreateError && (
                        <CustomErrorMessage
                          wrapperClassNames="my-2"
                          messageClassNames="text-center"
                          customErrorMessageText={getErrorMessageFromStatus(
                            'create',
                            bomsState?.bomCreateError?.status
                          )}
                        />
                      )}

                      {bomsState?.singleBomFetchError && (
                        <CustomErrorMessage
                          wrapperClassNames="my-2"
                          messageClassNames="text-center"
                          customErrorMessageText={getErrorMessageFromStatus(
                            'fetch',
                            bomsState?.singleBomFetchError?.status,
                            'BOM'
                          )}
                        />
                      )}

                      {bomsState?.bomEditSaveError && (
                        <CustomErrorMessage
                          wrapperClassNames="my-2"
                          messageClassNames="text-center"
                          customErrorMessageText={getErrorMessageFromStatus(
                            'edit',
                            bomsState?.bomEditSaveError?.status,
                            'BOM'
                          )}
                        />
                      )}

                      {bomsState?.bomDeleteError && (
                        <CustomErrorMessage
                          wrapperClassNames="my-2"
                          messageClassNames="text-center"
                          customErrorMessageText={getErrorMessageFromStatus(
                            'delete',
                            bomsState?.bomDeleteError?.status,
                            'BOM'
                          )}
                        />
                      )}

                      {!adminState.todayCurrencyExchange && (
                        <div className="custom-alert alert-warning my-3">
                          <span className="custom-alert-danger-icon">
                            <i className="fa cil-money" aria-hidden="true"></i>
                          </span>
                          <div className="alert-message text-center">
                            Uwaga! Używane kursy walut nie są z bieżącego dnia!
                          </div>
                        </div>
                      )}

                      <div className={`bom-grid-fields ${isThereNetworkError ? 'pt-3' : 'pt-2'}`}>
                        <BasicFormField
                          fieldId="bom-name"
                          fieldLabel="Nazwa"
                          fieldIcon="cil-short-text"
                          formikFieldName="name"
                          fieldError={errors.name}
                          fieldValue={values.name}
                          fieldType="text"
                          placeholder="Wprowadź nazwę BOM"
                          isDisabled={isBomChangeOccuring}
                        />

                        <BasicFormField
                          fieldId="bom-number-of-purchase-plan"
                          fieldLabel="Zapotrzebowanie"
                          fieldIcon="cil-opentype"
                          formikFieldName="purchasePlanNumber"
                          fieldError={errors.purchasePlanNumber}
                          fieldValue={values.purchasePlanNumber}
                          fieldType="text"
                          placeholder="Brak"
                          isDisabled
                        />

                        <BasicFormField
                          fieldId="bom-estimated-quantity"
                          fieldLabel="Ilość szacunkowa"
                          fieldIcon="cil-asterisk"
                          formikFieldName="estimatedQuantity"
                          fieldError={errors.estimatedQuantity}
                          fieldValue={values.estimatedQuantity}
                          fieldType="number"
                          placeholder="Wprowadź ilość"
                          amountMeasurementType={1}
                          isDisabled={isBomChangeOccuring}
                        />

                        <BasicFormField
                          fieldId="bom-cost-estimated"
                          fieldLabel={`Koszt szacunkowy${
                            values?.isTotalCostPerUnit ? ' jednostkowy' : ''
                          }`}
                          fieldIcon="cil-money"
                          formikFieldName="estimatedTotalBomCost"
                          fieldError={errors.estimatedTotalBomCost}
                          fieldValue={
                            totalBomCostCalculator(
                              values,
                              adminState,
                              'estimated',
                              values?.isTotalCostPerUnit
                            ) || ''
                          }
                          fieldType="text"
                          placeholder="Brak"
                          fieldCurrencyName="estimatedTotalBomCostCurrency"
                          fieldCurrencyValue={values.estimatedTotalBomCostCurrency}
                          handleChange={handleChange}
                          isDisabled
                        />

                        <BasicFormField
                          fieldId="bom-cost-from-offers"
                          fieldLabel={`Koszt ofertowy${
                            values?.isTotalCostPerUnit ? ' jednostkowy' : ''
                          }`}
                          fieldIcon="cil-money"
                          formikFieldName="offerTotalBomCost"
                          fieldError={errors.offerTotalBomCost}
                          fieldValue={
                            totalBomCostCalculator(
                              values,
                              adminState,
                              'offer',
                              values?.isTotalCostPerUnit
                            ) || ''
                          }
                          fieldType="text"
                          placeholder="Brak"
                          fieldCurrencyName="offerTotalBomCostCurrency"
                          fieldCurrencyValue={values.offerTotalBomCostCurrency}
                          handleChange={handleChange}
                          isDisabled
                        />

                        <BasicFormField
                          fieldId="bom-cost-from-orders"
                          fieldLabel={`Koszt zakupu${
                            values?.isTotalCostPerUnit ? ' jednostkowy' : ''
                          }`}
                          fieldIcon="cil-money"
                          formikFieldName="orderTotalBomCost"
                          fieldError={errors.orderTotalBomCost}
                          fieldValue={
                            totalBomCostCalculator(
                              values,
                              adminState,
                              'order',
                              values?.isTotalCostPerUnit
                            ) || ''
                          }
                          fieldType="text"
                          placeholder="Brak"
                          fieldCurrencyName="orderTotalBomCostCurrency"
                          fieldCurrencyValue={values.orderTotalBomCostCurrency}
                          handleChange={handleChange}
                          isDisabled
                        />

                        <div className="unit-checkbox-wrapper">
                          <i className="cil-loop-1"></i>

                          <Field
                            as={CInput}
                            type="checkbox"
                            name="isTotalCostPerUnit"
                            checked={values?.isTotalCostPerUnit}
                          />
                        </div>
                      </div>

                      {/*
                       * BOM Tables
                       */}

                      <CTabs
                        // Here the dynamic :tab in path URL differs between the create and edit modes
                        activeTab={paramsBomTab}
                      >
                        <CNav variant="tabs" className="mb-0 mt-2">
                          {bomNavTabs?.map((navTab: TFormTab) => {
                            if (navTab.isEditOnly && mode === 'create') {
                              return null
                            } else {
                              return (
                                <CNavItem key={navTab.name}>
                                  <CNavLink
                                    className={`${
                                      (navTab.name === 'elements' &&
                                        errors.bomElementDetails &&
                                        typeof errors.bomElementDetails === 'string' &&
                                        (values.bomImplementationCostDetails?.length ||
                                        values.bomServiceDetails?.length
                                          ? false
                                          : !values.bomElementDetails?.length)) ||
                                      (navTab.name === 'elements' &&
                                        errors.bomElementDetails &&
                                        typeof errors.bomElementDetails !== 'string' &&
                                        // filter undefined errors
                                        errors?.bomElementDetails?.some((error: any) => error) &&
                                        values?.bomElementDetails?.length) ||
                                      //
                                      (navTab.name === 'implementation-costs' &&
                                        errors.bomImplementationCostDetails &&
                                        typeof errors.bomImplementationCostDetails === 'string' &&
                                        (values.bomElementDetails?.length ||
                                        values.bomServiceDetails?.length
                                          ? false
                                          : !values.bomImplementationCostDetails?.length)) ||
                                      (navTab.name === 'implementation-costs' &&
                                        errors.bomImplementationCostDetails &&
                                        typeof errors.bomImplementationCostDetails !== 'string' &&
                                        values.bomImplementationCostDetails?.length) ||
                                      //
                                      (navTab.name === 'services' &&
                                        errors.bomServiceDetails &&
                                        typeof errors.bomServiceDetails === 'string' &&
                                        (values.bomElementDetails?.length ||
                                        values.bomImplementationCostDetails?.length
                                          ? false
                                          : !values.bomServiceDetails?.length)) ||
                                      (navTab.name === 'services' &&
                                        errors.bomServiceDetails &&
                                        typeof errors.bomServiceDetails !== 'string' &&
                                        values.bomServiceDetails?.length) ||
                                      //
                                      (navTab.name === 'calculations' &&
                                        errors.bomCalculations &&
                                        values.bomCalculations?.length)
                                        ? 'text-danger is-invalid'
                                        : ''
                                    }`}
                                    data-tab={navTab.name}
                                    onClick={() => {
                                      history.replace(
                                        `/${tenant}/admin/boms/${
                                          mode === 'edit' ? `edit/${paramsBomId}` : 'create'
                                        }/${navTab.name}`
                                      )
                                    }}
                                  >
                                    {navTab.title}
                                  </CNavLink>
                                </CNavItem>
                              )
                            }
                          })}
                        </CNav>
                        <CTabContent>
                          <CTabPane data-tab="elements">
                            <BomDetailsElementsTable
                              values={values}
                              errors={errors}
                              setFieldValue={setFieldValue}
                              handleChange={handleChange}
                              setIsBomModifiedAndUnsaved={setIsBomModifiedAndUnsaved}
                              isBomChangeOccuring={isBomChangeOccuring}
                              didFormValidationOccur={didFormValidationOccur}
                              validateForm={validateForm}
                            />
                          </CTabPane>
                          <CTabPane data-tab="services">
                            <BomDetailsServicesTable
                              values={values}
                              errors={errors}
                              setFieldValue={setFieldValue}
                              handleChange={handleChange}
                              setIsBomModifiedAndUnsaved={setIsBomModifiedAndUnsaved}
                              isBomChangeOccuring={isBomChangeOccuring}
                            />
                          </CTabPane>
                          <CTabPane data-tab="implementation-costs">
                            <BomDetailsImplementationCostsTable
                              values={values}
                              errors={errors}
                              setFieldValue={setFieldValue}
                              handleChange={handleChange}
                              setIsBomModifiedAndUnsaved={setIsBomModifiedAndUnsaved}
                              isBomChangeOccuring={isBomChangeOccuring}
                            />
                          </CTabPane>
                          <CTabPane data-tab="calculations">
                            <BomDetailsCalculationsTable
                              values={values}
                              errors={errors}
                              setFieldValue={setFieldValue}
                              handleChange={handleChange}
                              setIsBomModifiedAndUnsaved={setIsBomModifiedAndUnsaved}
                              isBomChangeOccuring={isBomChangeOccuring}
                            />
                          </CTabPane>
                          <CTabPane data-tab="graphs">
                            <BomDetailsGraphs values={values} />
                          </CTabPane>
                        </CTabContent>
                      </CTabs>
                    </>
                  )}
                </Form>
              )}
            </Formik>
          }
        </CCardBody>
      </CCard>
    </Container>
  )
}
