import { call, put, takeLatest, delay } from 'redux-saga/effects'

import {
  sectionFetch,
  orderCall,
  deleteItemCall,
  itemAttachmentsFetch,
  addSingleAttachmentCall,
  singleItemFetch,
  generateOrderPDFCall,
} from './api'
import * as actionTypes from '../action-types'
import { editSuccessMessageDuration, successMessageDuration } from '../../utils'

export function* fetchOrders(action: actionTypes.fetchOrdersAction) {
  try {
    const response = yield call(sectionFetch, action.payload, 'orders')
    yield put({ type: actionTypes.FETCH_ORDERS_SUCCEEDED, orders: response.data })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_ORDERS_ERROR,
      error: error?.response,
    })
  }
}

export function* fetchSingleOrder(action: actionTypes.fetchSingleOrderAction) {
  try {
    const response = yield call(singleItemFetch, action.payload, `orders/edit/${action.payload.id}`)
    yield put({ type: actionTypes.FETCH_SINGLE_ORDER_SUCCEEDED, editedOrder: response.data })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_SINGLE_ORDER_ERROR,
      error: error?.response,
    })
  }
}

export function* editOrder(action: actionTypes.editOrderAction) {
  try {
    yield call(orderCall, action.payload)
    yield put({
      type: actionTypes.EDIT_ORDER_SUCCEEDED,
      order: { ...action.payload.order },
    })
    yield delay(successMessageDuration)
    yield put({
      type: actionTypes.ORDER_ACTIONS_UNLOCK,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.EDIT_ORDER_ERROR,
      error: error?.response,
    })
    if (window && window.document) {
      window.scrollTo({ top: 0 })
    }
  }
}

export function* fetchOrderAttachments(action: actionTypes.fetchOrderAttachmentsAction) {
  try {
    const response = yield call(itemAttachmentsFetch, action.payload)
    yield put({
      type: actionTypes.FETCH_ORDER_ATTACHMENTS_SUCCEEDED,
      orderAttachments: response.data,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_ORDER_ATTACHMENTS_ERROR,
      error: error?.response,
    })
  }
}

export function* uploadOrderAttachment(action: actionTypes.uploadOrderAttachmentAction) {
  try {
    const response = yield call(addSingleAttachmentCall, action.payload)

    yield put({
      type: actionTypes.UPLOAD_ORDER_ATTACHMENT_SUCCEEDED,
      attachment: {
        id: response.data?.id,
        name: response.data?.name || '',
      },
    })
    yield delay(editSuccessMessageDuration)
    yield put({
      type: actionTypes.ORDER_ACTIONS_UNLOCK,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.UPLOAD_ORDER_ATTACHMENT_ERROR,
      error: error?.response,
    })
  }
}

export function* deleteOrder(action: actionTypes.deleteOrderAction) {
  try {
    yield call(deleteItemCall, action.payload, 'orders')
    yield put({
      type: actionTypes.DELETE_ORDER_SUCCEEDED,
      id: action.payload.id,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.DELETE_ORDER_ERROR,
      error: error?.response,
    })
  }
}

export function* generateOrderPDF(action: actionTypes.generateOrderPDFAction) {
  try {
    const response = yield call(generateOrderPDFCall, action.payload)
    yield put({
      type: actionTypes.GENERATE_ORDER_PDF_SUCCEEDED,
      generatedOrderPDFInfo: response.data,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.GENERATE_ORDER_PDF_ERROR,
      error: error?.response,
    })
  }
}

export function* ordersSaga() {
  yield takeLatest(actionTypes.FETCH_ORDERS_REQUESTED, fetchOrders)
  yield takeLatest(actionTypes.FETCH_SINGLE_ORDER_REQUESTED, fetchSingleOrder)

  yield takeLatest(actionTypes.EDIT_ORDER_REQUESTED, editOrder)

  yield takeLatest(actionTypes.FETCH_ORDER_ATTACHMENTS_REQUESTED, fetchOrderAttachments)
  yield takeLatest(actionTypes.UPLOAD_ORDER_ATTACHMENT_REQUESTED, uploadOrderAttachment)

  yield takeLatest(actionTypes.DELETE_ORDER_REQUESTED, deleteOrder)

  yield takeLatest(actionTypes.GENERATE_ORDER_PDF_REQUESTED, generateOrderPDF)
}
