import * as actionTypes from '../action-types'

export interface IAuthState {
  tenant: string
  user: string
  isLoginPending: boolean
  loginError: actionTypes.TNetworkError
  authData: actionTypes.TAuthData
  isAuthDataSaved: boolean
  isAuthenticationPending: boolean
  isAuthenticated: boolean | undefined

  isRequestPasswordResetPending: boolean
  isRequestPasswordResetSuccessful: boolean
  requestPasswordResetError: actionTypes.TNetworkError

  isPasswordResetTokenValid: boolean
  isPasswordResetTokenCheckPending: boolean
  passwordResetTokenCheckError: actionTypes.TNetworkError

  isPasswordResetPending: boolean
  isPasswordResetSuccessful: boolean
  passwordResetError: actionTypes.TNetworkError
}

const initialState: IAuthState = {
  tenant: '',
  user: '',
  isLoginPending: false,
  loginError: null,
  authData: null,
  isAuthDataSaved: false,
  isAuthenticationPending: true,
  isAuthenticated: undefined,

  isRequestPasswordResetPending: false,
  isRequestPasswordResetSuccessful: false,
  requestPasswordResetError: null,

  isPasswordResetTokenValid: false,
  isPasswordResetTokenCheckPending: false,
  passwordResetTokenCheckError: null,

  isPasswordResetPending: false,
  isPasswordResetSuccessful: false,
  passwordResetError: null,
}

export default function authReducer(
  state = initialState,
  action: actionTypes.AuthActionTypes
): IAuthState {
  switch (action.type) {
    case actionTypes.LOGIN_REQUESTED: {
      return {
        ...state,
        tenant: action.payload.tenant,
        user: action.payload.user,
        isLoginPending: true,
        loginError: null,
      }
    }

    case actionTypes.LOGIN_SUCCEEDED: {
      return {
        ...state,
        isLoginPending: false,
        loginError: null,
        isAuthenticated: true,
      }
    }

    case actionTypes.LOGIN_ERROR: {
      return {
        ...state,
        isLoginPending: false,
        loginError: action.error,
        isAuthenticated: false,
      }
    }

    case actionTypes.LOGIN_CANCELLED: {
      return {
        ...state,
        isLoginPending: false,
        loginError: null,
        isAuthenticated: false,
      }
    }

    case actionTypes.SAVE_AUTH_DATA: {
      return {
        ...state,
        authData: action?.authData,
        isAuthDataSaved: true,
      }
    }

    case actionTypes.LOGOUT_REQUESTED: {
      return {
        ...state,
        isLoginPending: false,
        loginError: null,
      }
    }

    case actionTypes.DELETE_TOKEN: {
      return {
        ...state,
        authData: null,
        isAuthenticated: false,
      }
    }

    case actionTypes.LOGOUT_SUCCEEDED: {
      return {
        ...state,
        user: '',
        isAuthenticated: false,
      }
    }

    case actionTypes.AUTHENTICATION_REQUESTED: {
      return {
        ...state,
        isAuthenticationPending: true,
      }
    }

    case actionTypes.AUTHENTICATION_SUCCEEDED: {
      return {
        ...state,
        isAuthenticationPending: false,
        isAuthenticated: true,
      }
    }

    case actionTypes.AUTHENTICATION_ERROR: {
      return {
        ...state,
        isAuthenticationPending: false,
        isAuthenticated: false,
      }
    }

    case actionTypes.AUTHENTICATION_CANCELLED: {
      return {
        ...state,
        isAuthenticationPending: false,
        isAuthenticated: undefined,
      }
    }

    case actionTypes.REQUEST_PASSWORD_RESET_REQUESTED: {
      return {
        ...state,
        isRequestPasswordResetPending: true,
        requestPasswordResetError: null,
      }
    }

    case actionTypes.REQUEST_PASSWORD_RESET_SUCCEEDED: {
      return {
        ...state,
        isRequestPasswordResetPending: false,
        isRequestPasswordResetSuccessful: true,
        requestPasswordResetError: null,
      }
    }

    case actionTypes.REQUEST_PASSWORD_RESET_ERROR: {
      return {
        ...state,
        isRequestPasswordResetPending: false,
        requestPasswordResetError: action.error,
      }
    }

    case actionTypes.REQUEST_PASSWORD_RESET_CANCELLED: {
      return {
        ...state,
        isRequestPasswordResetPending: false,
        isRequestPasswordResetSuccessful: false,
        requestPasswordResetError: null,
      }
    }

    case actionTypes.PASSWORD_RESET_TOKEN_CHECK_REQUESTED: {
      return {
        ...state,
        isPasswordResetTokenCheckPending: true,
        passwordResetTokenCheckError: null,
      }
    }

    case actionTypes.PASSWORD_RESET_TOKEN_CHECK_SUCCEEDED: {
      return {
        ...state,
        isPasswordResetTokenCheckPending: false,
        isPasswordResetTokenValid: true,
        passwordResetTokenCheckError: null,
      }
    }

    case actionTypes.PASSWORD_RESET_TOKEN_CHECK_ERROR: {
      return {
        ...state,
        isPasswordResetTokenCheckPending: false,
        isPasswordResetTokenValid: false || true,
        passwordResetTokenCheckError: null,
      }
    }

    case actionTypes.PASSWORD_RESET_TOKEN_CHECK_CANCELLED: {
      return {
        ...state,
        isPasswordResetTokenCheckPending: false,
        isPasswordResetTokenValid: false,
        passwordResetTokenCheckError: null,
      }
    }

    case actionTypes.PASSWORD_RESET_REQUESTED: {
      return {
        ...state,
        isPasswordResetPending: true,
        passwordResetError: null,
      }
    }

    case actionTypes.PASSWORD_RESET_SUCCEEDED: {
      return {
        ...state,
        isPasswordResetPending: false,
        isPasswordResetSuccessful: true,
        passwordResetError: null,
      }
    }

    case actionTypes.PASSWORD_RESET_ERROR: {
      return {
        ...state,
        isPasswordResetPending: false,
        passwordResetError: action.error,
      }
    }

    case actionTypes.PASSWORD_RESET_CANCELLED: {
      return {
        ...state,
        isPasswordResetPending: false,
        isPasswordResetSuccessful: false,
        passwordResetError: null,
      }
    }

    default:
      return state
  }
}
