import {
  TAdminPayload,
  ISingleItemActionPayload,
  IAttachmentUpload,
  TAttachment,
  IAttachmentFetch,
  IUploadSingleAttachmentPayload,
  IFetchItemAttachmentsPayload,
  TFilterState,
  TSorterState,
} from './admin'
import { TNetworkError } from './auth'
import { TSupplier } from './suppliers'
import { TOffer } from './offers'
import { TCurrencyCode, TOrder } from '.'

export const FETCH_SERVICES_REQUESTED = 'FETCH_SERVICES_REQUESTED'
export const FETCH_SERVICES_SUCCEEDED = 'FETCH_SERVICES_SUCCEEDED'
export const FETCH_SERVICES_ERROR = 'FETCH_SERVICES_ERROR'

export const SET_SERVICES_TABLE_FILTER_STATE = 'SET_SERVICES_TABLE_FILTER_STATE'
export const SET_SERVICES_TABLE_SORTER_STATE = 'SET_SERVICES_TABLE_SORTER_STATE'

export const FETCH_SERVICE_BOMS_REQUESTED = 'FETCH_SERVICE_BOMS_REQUESTED'
export const FETCH_SERVICE_BOMS_SUCCEEDED = 'FETCH_SERVICE_BOMS_SUCCEEDED'
export const FETCH_SERVICE_BOMS_ERROR = 'FETCH_SERVICE_BOMS_ERROR'

export const FETCH_SERVICE_OFFERS_REQUESTED = 'FETCH_SERVICE_OFFERS_REQUESTED'
export const FETCH_SERVICE_OFFERS_SUCCEEDED = 'FETCH_SERVICE_OFFERS_SUCCEEDED'
export const FETCH_SERVICE_OFFERS_ERROR = 'FETCH_SERVICE_OFFERS_ERROR'

export const FETCH_SERVICE_ORDERS_REQUESTED = 'FETCH_SERVICE_ORDERS_REQUESTED'
export const FETCH_SERVICE_ORDERS_SUCCEEDED = 'FETCH_SERVICE_ORDERS_SUCCEEDED'
export const FETCH_SERVICE_ORDERS_ERROR = 'FETCH_SERVICE_ORDERS_ERROR'

export const CREATE_SERVICE_REQUESTED = 'CREATE_SERVICE_REQUESTED'
export const CREATE_SERVICE_SUCCEEDED = 'CREATE_SERVICE_SUCCEEDED'
export const CREATE_SERVICE_ERROR = 'CREATE_SERVICE_ERROR'

export const EDIT_SERVICE_REQUESTED = 'EDIT_SERVICE_REQUESTED'
export const EDIT_SERVICE_SUCCEEDED = 'EDIT_SERVICE_SUCCEEDED'
export const EDIT_SERVICE_ERROR = 'EDIT_SERVICE_ERROR'

export const DELETE_SERVICE_REQUESTED = 'DELETE_SERVICE_REQUESTED'
export const DELETE_SERVICE_SUCCEEDED = 'DELETE_SERVICE_SUCCEEDED'
export const DELETE_SERVICE_ERROR = 'DELETE_SERVICE_ERROR'

export const FETCH_SERVICE_ATTACHMENTS_REQUESTED = 'FETCH_SERVICE_ATTACHMENTS_REQUESTED'
export const FETCH_SERVICE_ATTACHMENTS_SUCCEEDED = 'FETCH_SERVICE_ATTACHMENTS_SUCCEEDED'
export const FETCH_SERVICE_ATTACHMENTS_ERROR = 'FETCH_SERVICE_ATTACHMENTS_ERROR'

export const UPLOAD_SERVICE_ATTACHMENT_REQUESTED = 'UPLOAD_SERVICE_ATTACHMENT_REQUESTED'
export const UPLOAD_SERVICE_ATTACHMENT_SUCCEEDED = 'UPLOAD_SERVICE_ATTACHMENT_SUCCEEDED'
export const UPLOAD_SERVICE_ATTACHMENT_ERROR = 'UPLOAD_SERVICE_ATTACHMENT_ERROR'

export const DELETE_SERVICE_ATTACHMENT_SUCCEEDED = 'DELETE_SERVICE_ATTACHMENT_SUCCEEDED'

export const OPEN_SERVICE_DETAILS = 'OPEN_SERVICE_DETAILS'
export const CLOSE_SERVICE_DETAILS = 'CLOSE_SERVICE_DETAILS'

export const SERVICE_DETAILS_ACTIONS_UNLOCK = 'SERVICE_DETAILS_ACTIONS_UNLOCK'

export type TService = {
  name: string
  price?: number

  supplierId: number
  supplierInfos?: TSupplier[] | null

  description: string

  orders: TServiceInOrder[] | null

  canDelete: boolean

  id: number
  uuid: string | null
}

export type TServiceInOrder = {
  orderId: number
  createDate: string
  quantity: number
  servicePrice: number
  servicePriceCurrency: TCurrencyCode
  orderNumber: string
}

export type TServiceBom = {
  serviceDetails: any[]
  // productId is no longer there
  productId: number
  name: string
  id: number
}

export type TServiceAttachmentUpload = IAttachmentUpload & {
  serviceId: number
}

export type TServiceAttachment = TAttachment & {
  serviceId: number
}

export interface ICreateServicePayload extends TAdminPayload {
  service: TService
}

// FETCH_SERVICES

export interface fetchServicesAction {
  type: typeof FETCH_SERVICES_REQUESTED
  payload: TAdminPayload
}

export interface fetchServicesSucceeded {
  type: typeof FETCH_SERVICES_SUCCEEDED
  services: TService[]
}

export interface fetchServicesError {
  type: typeof FETCH_SERVICES_ERROR
  error: TNetworkError
}

// SET_SERVICES_TABLE_FILTER_STATE

export interface setServicesTableFilterState {
  type: typeof SET_SERVICES_TABLE_FILTER_STATE
  tableFilterState: TFilterState
}

// SET_SERVICES_TABLE_SORTER_STATE

export interface setServicesTableSorterState {
  type: typeof SET_SERVICES_TABLE_SORTER_STATE
  tableSorterState: TSorterState
}

// CREATE_SERVICE

export interface createServiceAction {
  type: typeof CREATE_SERVICE_REQUESTED
  payload: ICreateServicePayload
}

export interface createServiceSucceeded {
  type: typeof CREATE_SERVICE_SUCCEEDED
  service: TService
}

export interface createServiceError {
  type: typeof CREATE_SERVICE_ERROR
  error: TNetworkError
}

// FETCH_SERVICE_BOMS

export interface fetchServiceBomsAction {
  type: typeof FETCH_SERVICE_BOMS_REQUESTED
  payload: ISingleItemActionPayload
}

export interface fetchServiceBomsSucceeded {
  type: typeof FETCH_SERVICE_BOMS_SUCCEEDED
  serviceBoms: TServiceBom[]
}

export interface fetchServiceBomsError {
  type: typeof FETCH_SERVICE_BOMS_ERROR
  error: TNetworkError
}

// FETCH_SERVICE_OFFERS

export interface fetchServiceOffersAction {
  type: typeof FETCH_SERVICE_OFFERS_REQUESTED
  payload: ISingleItemActionPayload
}

export interface fetchServiceOffersSucceeded {
  type: typeof FETCH_SERVICE_OFFERS_SUCCEEDED
  serviceOffers: TOffer[]
}

export interface fetchServiceOffersError {
  type: typeof FETCH_SERVICE_OFFERS_ERROR
  error: TNetworkError
}

// FETCH_SERVICE_ORDERS

export interface fetchServiceOrdersAction {
  type: typeof FETCH_SERVICE_ORDERS_REQUESTED
  payload: ISingleItemActionPayload
}

export interface fetchServiceOrdersSucceeded {
  type: typeof FETCH_SERVICE_ORDERS_SUCCEEDED
  serviceOrders: TOrder[]
}

export interface fetchServiceOrdersError {
  type: typeof FETCH_SERVICE_ORDERS_ERROR
  error: TNetworkError
}

// EDIT_SERVICE

export interface editServiceAction {
  type: typeof EDIT_SERVICE_REQUESTED
  payload: ICreateServicePayload
}

export interface editServiceSucceeded {
  type: typeof EDIT_SERVICE_SUCCEEDED
  service: TService
}

export interface editServiceError {
  type: typeof EDIT_SERVICE_ERROR
  error: TNetworkError
}

// DELETE_SERVICE

export interface deleteServiceAction {
  type: typeof DELETE_SERVICE_REQUESTED
  payload: ISingleItemActionPayload
}

export interface deleteServiceSucceeded {
  type: typeof DELETE_SERVICE_SUCCEEDED
  id: number
}

export interface deleteServiceError {
  type: typeof DELETE_SERVICE_ERROR
  error: TNetworkError
}

// FETCH_SERVICE_ATTACHMENTS

export interface fetchServiceAttachmentsAction {
  type: typeof FETCH_SERVICE_ATTACHMENTS_REQUESTED
  payload: IFetchItemAttachmentsPayload
}

export interface fetchServiceAttachmentsSucceeded {
  type: typeof FETCH_SERVICE_ATTACHMENTS_SUCCEEDED
  serviceAttachments: TServiceAttachment[]
}

export interface fetchServiceAttachmentsError {
  type: typeof FETCH_SERVICE_ATTACHMENTS_ERROR
  error: TNetworkError
}

// UPLOAD_SERVICE_ATTACHMENT

export interface uploadServiceAttachmentAction {
  type: typeof UPLOAD_SERVICE_ATTACHMENT_REQUESTED
  payload: IUploadSingleAttachmentPayload
}

export interface uploadServiceAttachmentSucceeded {
  type: typeof UPLOAD_SERVICE_ATTACHMENT_SUCCEEDED
  attachment: TServiceAttachment
}

export interface uploadServiceAttachmentError {
  type: typeof UPLOAD_SERVICE_ATTACHMENT_ERROR
  error: TNetworkError
}

// DELETE_SERVICE_ATTACHMENT

export interface deleteServiceAttachmentSucceeded {
  type: typeof DELETE_SERVICE_ATTACHMENT_SUCCEEDED
  payload: IAttachmentFetch
}

// DETAILS

export interface openServiceDetailsAction {
  type: typeof OPEN_SERVICE_DETAILS
  editedService: TService | null
}

export interface closeServiceDetailsAction {
  type: typeof CLOSE_SERVICE_DETAILS
}

export interface serviceActionsUnlockAction {
  type: typeof SERVICE_DETAILS_ACTIONS_UNLOCK
}

export type ServicesActionTypes =
  | fetchServicesAction
  | fetchServicesSucceeded
  | fetchServicesError
  | setServicesTableFilterState
  | setServicesTableSorterState
  | fetchServiceBomsAction
  | fetchServiceBomsSucceeded
  | fetchServiceBomsError
  | fetchServiceOffersAction
  | fetchServiceOffersSucceeded
  | fetchServiceOffersError
  | fetchServiceOrdersAction
  | fetchServiceOrdersSucceeded
  | fetchServiceOrdersError
  | createServiceAction
  | createServiceSucceeded
  | createServiceError
  | editServiceAction
  | editServiceSucceeded
  | editServiceError
  | deleteServiceAction
  | deleteServiceSucceeded
  | deleteServiceError
  | fetchServiceAttachmentsAction
  | fetchServiceAttachmentsSucceeded
  | fetchServiceAttachmentsError
  | uploadServiceAttachmentAction
  | uploadServiceAttachmentSucceeded
  | uploadServiceAttachmentError
  | deleteServiceAttachmentSucceeded
  | openServiceDetailsAction
  | closeServiceDetailsAction
  | serviceActionsUnlockAction
