import React, { useState, useEffect } from 'react'
import { Button, Card, CardBody, Container, Row } from 'reactstrap'
import { useParams, useHistory, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { BasicFormField, ThreeDots, CustomErrorMessage } from '../../components'
import { TRootState } from '../../store/reducers'
import * as actionTypes from '../../store/action-types'

export const Login: React.FC<{}> = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tenant } = useParams<{ tenant: string }>()
  const [didFormValidationOccur, setDidFormValidationOccur] = useState(false)

  const authState = useSelector((state: TRootState) => state.auth)

  useEffect(() => {
    if (authState.isAuthenticated === undefined) {
      dispatch({
        type: actionTypes.AUTHENTICATION_REQUESTED,
        payload: {
          token: authState?.authData?.token || '',
          tenant: tenant,
        },
      })
    }

    if (
      !authState.isAuthenticationPending &&
      authState.isAuthenticated === true &&
      authState?.authData?.token
    ) {
      history.replace(`/${tenant}/admin/suppliers`)
    }
  }, [
    dispatch,
    tenant,
    authState.authData,
    authState.isAuthenticated,
    authState.isAuthenticationPending,
    history,
  ])

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Nieprawidłowy format adresu e-mail!')
      .required('To pole jest wymagane!'),
    password: Yup.string().required('To pole jest wymagane!'),
  })

  const getLoginErrorMessage = (status: number | undefined | null) => {
    switch (status) {
      case 400:
        return 'Błąd 400! Wysłano zły format adresu e-mail!'
      case 401:
        return 'Nieprawidłowy e-mail lub hasło!'
      case 403:
        return 'Nie masz dostępu do tego zasobu!'
      case 404:
        return 'Błąd 404! Nie odnaleziono zasobu lub zapytania do serwera!'
      case 405:
        return 'Błąd 405! Zastosowano niedozwoloną metodę HTTP!'
      case 417:
        return 'Podana firma nie istnieje w naszej bazie danych!'
      case 500:
        return '500! Wystąpił wewnętrzny błąd serwera!'
      default:
        return `Wystąpił ${status ? `błąd ${status}` : 'nieznany błąd serwera'}!`
    }
  }

  return (
    <Container className="app vh-100 d-flex flex-column align-items-center justify-content-center">
      <Row>
        <Card className="pb-2 pt-0 px-3 mb-5" style={{ width: '500px' }}>
          <CardBody>
            <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              validationSchema={LoginSchema}
              onSubmit={(values) => {
                setDidFormValidationOccur(true)

                dispatch({
                  type: actionTypes.LOGIN_REQUESTED,
                  payload: {
                    tenant,
                    user: values.email,
                    password: values.password,
                  },
                })
              }}
              validateOnBlur={false}
              validateOnChange={didFormValidationOccur}
            >
              {({ values, errors }) => (
                <Form
                  onChange={() => {
                    if (errors.email || errors.password) {
                      setDidFormValidationOccur(true)
                    }
                    if (!errors.email && !errors.password && authState.loginError) {
                      dispatch({ type: actionTypes.LOGIN_CANCELLED })
                    }
                  }}
                >
                  <h2 className="mb-3 text-center">Logowanie</h2>
                  <p className="text-muted text-center mb-3">
                    Zaloguj się do swojego konta Polysystem:{' '}
                    <strong>
                      {tenant.replace(/\b\w/g, (letter: string) => letter.toUpperCase())}
                    </strong>
                  </p>

                  {!errors.email && !errors.password && authState?.loginError && (
                    <CustomErrorMessage
                      wrapperClassNames="mb-3"
                      customErrorMessageText={getLoginErrorMessage(
                        authState?.loginError?.status || authState?.loginError?.data?.status
                      )}
                    />
                  )}

                  <BasicFormField
                    fieldId="login-email"
                    fieldLabel="E-mail"
                    fieldIcon="icon-user"
                    formikFieldName="email"
                    fieldValue={values.email}
                    fieldError={errors.email}
                    fieldType="text"
                    placeholder="E-mail"
                    inputGroupCustomClasses="mb-3"
                    autoCompleteValue="email"
                  />

                  <BasicFormField
                    fieldId="login-password"
                    fieldLabel="Hasło"
                    fieldIcon="icon-lock"
                    formikFieldName="password"
                    fieldValue={values.password}
                    fieldError={errors.password}
                    fieldType="password"
                    placeholder="Hasło"
                    // This is not a bug below
                    inputGroupCustomClasses=" "
                    autoCompleteValue="current-password"
                  />

                  <div className="d-flex justify-content-left mb-3 mt-2">
                    <Link to={`/${tenant}/request-password-reset`} className="text-muted">
                      Resetowanie hasła
                    </Link>
                  </div>

                  <div className="pt-2 d-flex justify-content-center w-100">
                    <Button
                      color="info"
                      className="px-5 w-100"
                      type="submit"
                      disabled={authState.isLoginPending || !!errors.email || !!errors.password}
                    >
                      {authState.isLoginPending ? (
                        <>
                          Logowanie
                          <ThreeDots />
                        </>
                      ) : (
                        'Zaloguj się'
                      )}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </Row>
    </Container>
  )
}
