import { call, put, takeLatest, delay } from 'redux-saga/effects'

import {
  sectionFetch,
  offerCall,
  deleteItemCall,
  itemAttachmentsFetch,
  addSingleAttachmentCall,
  singleItemFetch,
} from './api'
import * as actionTypes from '../action-types'
import { editSuccessMessageDuration, successMessageDuration } from '../../utils'

export function* fetchOffers(action: actionTypes.fetchOffersAction) {
  try {
    const response = yield call(sectionFetch, action.payload, 'offers')
    yield put({ type: actionTypes.FETCH_OFFERS_SUCCEEDED, offers: response.data })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_OFFERS_ERROR,
      error: error?.response,
    })
  }
}

export function* createOffer(action: actionTypes.createOfferAction) {
  try {
    const response = yield call(offerCall, action.payload)

    yield put({
      type: actionTypes.CREATE_OFFER_SUCCEEDED,
      // Change this to an object from response.data
      offer: {
        ...action.payload.offer,
        id: response.data ? response.data.id : action.payload.offer.id,
      },
    })

    // This is fired after offer creation
    if (response.data) {
      yield put({
        type: actionTypes.FETCH_OFFERS_REQUESTED,
        payload: { tenant: action.payload.tenant, token: action.payload?.token },
      })
    }

    yield delay(successMessageDuration)

    yield put({
      type: actionTypes.OFFER_ACTIONS_UNLOCK,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.CREATE_OFFER_ERROR,
      error: error?.response,
    })
  }
}

export function* fetchSingleOffer(action: actionTypes.fetchSingleOfferAction) {
  try {
    const response = yield call(singleItemFetch, action.payload, `offers/edit/${action.payload.id}`)
    yield put({
      type: actionTypes.FETCH_SINGLE_OFFER_SUCCEEDED,
      editedOffer: response.data,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_SINGLE_OFFER_ERROR,
      error: error?.response,
    })
  }
}

export function* editOffer(action: actionTypes.editOfferAction) {
  try {
    const response = yield call(offerCall, action.payload)

    yield put({
      type: actionTypes.EDIT_OFFER_SUCCEEDED,
      // Change this to an object from response.data
      offer: {
        ...action.payload.offer,
        id: response.data ? response.data.id : action.payload.offer.id,
      },
    })

    // This is fired after offer edit
    if (response.data) {
      yield put({
        type: actionTypes.FETCH_OFFERS_REQUESTED,
        payload: { tenant: action.payload.tenant, token: action.payload?.token },
      })
    }

    yield delay(successMessageDuration)

    yield put({
      type: actionTypes.OFFER_ACTIONS_UNLOCK,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.EDIT_OFFER_ERROR,
      error: error?.response,
    })
  }
}

export function* deleteOffer(action: actionTypes.deleteOfferAction) {
  try {
    yield call(deleteItemCall, action.payload, 'offers')
    yield put({
      type: actionTypes.DELETE_OFFER_SUCCEEDED,
      id: action.payload.id,
    })
    yield delay(successMessageDuration)
    yield put({
      type: actionTypes.OFFER_ACTIONS_UNLOCK,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.DELETE_OFFER_ERROR,
      error: error?.response,
    })
  }
}

export function* fetchOfferAttachments(action: actionTypes.fetchOfferAttachmentsAction) {
  try {
    const response = yield call(itemAttachmentsFetch, action.payload)
    yield put({
      type: actionTypes.FETCH_OFFER_ATTACHMENTS_SUCCEEDED,
      offerAttachments: response.data,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_OFFER_ATTACHMENTS_ERROR,
      error: error?.response,
    })
  }
}

export function* uploadOfferAttachment(action: actionTypes.uploadOfferAttachmentAction) {
  try {
    const response = yield call(addSingleAttachmentCall, action.payload)

    yield put({
      type: actionTypes.UPLOAD_OFFER_ATTACHMENT_SUCCEEDED,
      attachment: {
        id: response.data.id,
        name: response.data?.name || '',
      },
    })
    yield delay(editSuccessMessageDuration)
    yield put({
      type: actionTypes.OFFER_ACTIONS_UNLOCK,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.UPLOAD_OFFER_ATTACHMENT_ERROR,
      error: error?.response,
    })
  }
}

export function* offersSaga() {
  yield takeLatest(actionTypes.FETCH_OFFERS_REQUESTED, fetchOffers)
  yield takeLatest(actionTypes.FETCH_SINGLE_OFFER_REQUESTED, fetchSingleOffer)
  yield takeLatest(actionTypes.CREATE_OFFER_REQUESTED, createOffer)
  yield takeLatest(actionTypes.FETCH_OFFER_ATTACHMENTS_REQUESTED, fetchOfferAttachments)
  yield takeLatest(actionTypes.UPLOAD_OFFER_ATTACHMENT_REQUESTED, uploadOfferAttachment)
  yield takeLatest(actionTypes.EDIT_OFFER_REQUESTED, editOffer)
  yield takeLatest(actionTypes.DELETE_OFFER_REQUESTED, deleteOffer)
}
