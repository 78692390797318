import React, { useState, useEffect } from 'react'
import { Card, CardBody, Container, Row } from 'reactstrap'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form } from 'formik'
import { CButton, CModal, CModalBody, CModalHeader } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilEnvelopeLetter } from '@coreui/icons'
import * as Yup from 'yup'

import { BasicFormField, ThreeDots, CustomErrorMessage } from '../../components'
import { TRootState } from '../../store/reducers'
import * as actionTypes from '../../store/action-types'

export const RequestPasswordReset: React.FC<{}> = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tenant } = useParams<{ tenant: string }>()

  const [didFormValidationOccur, setDidFormValidationOccur] = useState(false)

  const authState = useSelector((state: TRootState) => state.auth)

  // This is a hack because close button in modal acts as a submit button
  useEffect(() => {
    const closeModalButton = document.getElementsByClassName('close')

    if (closeModalButton[0]) {
      closeModalButton[0].setAttribute('type', 'button')
    }
  }, [authState.isRequestPasswordResetSuccessful])

  const RequestPasswordResetSchema = Yup.object().shape({
    email: Yup.string()
      .email('Nieprawidłowy format adresu e-mail!')
      .required('To pole jest wymagane!'),
  })

  const getRequestPasswordResetErrorMessage = (status: number | undefined | null) => {
    switch (status) {
      case 401:
        return 'Brak uwierzytelnienia!'
      case 403:
        return 'Nie masz dostępu do tego zasobu!'
      case 404:
        return 'Błąd 404! Nie odnaleziono zasobu lub zapytania do serwera!'
      case 405:
        return 'Błąd 405! Zastosowano niedozwoloną metodę HTTP!'
      case 500:
        return '500! Wystąpił wewnętrzny błąd serwera!'
      default:
        return `Wystąpił  ${status ? `błąd ${status}` : 'nieznany błąd serwera'}!`
    }
  }

  return (
    <Container className="app vh-100 d-flex flex-column align-items-center justify-content-center">
      <Row>
        <Card className="pb-2 pt-0 px-3 mb-5" style={{ width: '500px' }}>
          <CardBody>
            <Formik
              initialValues={{
                email: '',
              }}
              validationSchema={RequestPasswordResetSchema}
              onSubmit={(values) => {
                setDidFormValidationOccur(true)

                dispatch({
                  type: actionTypes.REQUEST_PASSWORD_RESET_REQUESTED,
                  payload: {
                    tenant,
                    user: values.email,
                  },
                })
              }}
              validateOnBlur={false}
              validateOnChange={didFormValidationOccur}
            >
              {({ values, errors, resetForm }) => (
                <Form
                  onChange={() => {
                    if (errors.email) {
                      setDidFormValidationOccur(true)
                    }

                    if (!errors.email && authState.requestPasswordResetError) {
                      dispatch({ type: actionTypes.REQUEST_PASSWORD_RESET_CANCELLED })
                    }
                  }}
                >
                  <h2 className="mb-3 text-center">Resetowanie hasła</h2>
                  <p className={`text-muted text-center mb-3`}>
                    Wprowadź e-mail związany z twoim kontem, na który otrzymasz link do zresetowania
                    hasła w Polysystem:{' '}
                    <strong>
                      {tenant.replace(/\b\w/g, (letter: string) => letter.toUpperCase())}
                    </strong>
                  </p>

                  {!errors.email && authState.requestPasswordResetError && (
                    <CustomErrorMessage
                      wrapperClassNames="my-2"
                      customErrorMessageText={getRequestPasswordResetErrorMessage(
                        authState?.requestPasswordResetError?.status ||
                          authState?.requestPasswordResetError?.data?.status
                      )}
                    />
                  )}

                  <BasicFormField
                    fieldId="request-password-reset-email"
                    fieldLabel="E-mail"
                    fieldIcon="icon-user"
                    formikFieldName="email"
                    fieldValue={values.email}
                    fieldError={errors.email}
                    fieldType="text"
                    placeholder="E-mail"
                    inputGroupCustomClasses="mb-4"
                    autoCompleteValue="email"
                  />

                  <div className="pt-3 d-flex justify-content-center w-100">
                    <CButton
                      color="primary"
                      className="px-1 mr-3 w-100"
                      variant="outline"
                      type="button"
                      onClick={() => {
                        dispatch({
                          type: actionTypes.REQUEST_PASSWORD_RESET_CANCELLED,
                        })

                        history.push(`/${tenant}/login`)
                      }}
                    >
                      Anuluj
                    </CButton>
                    <CButton
                      color="info"
                      className="px-5 ml-3 w-100"
                      type="submit"
                      disabled={
                        authState.isRequestPasswordResetPending ||
                        (didFormValidationOccur && errors.email)
                          ? true
                          : false
                      }
                    >
                      {authState.isRequestPasswordResetPending ? (
                        <>
                          Wysyłanie
                          <ThreeDots />
                        </>
                      ) : (
                        'Wyślij'
                      )}
                    </CButton>
                  </div>

                  <CModal
                    onClose={() => {
                      dispatch({
                        type: actionTypes.REQUEST_PASSWORD_RESET_CANCELLED,
                      })

                      resetForm()
                    }}
                    closeOnBackdrop={false}
                    size="lg"
                    show={authState.isRequestPasswordResetSuccessful}
                    backdrop
                    addContentClass="py-4 px-5"
                    centered={true}
                    style={{ maxWidth: '680px' }}
                  >
                    <CModalHeader className="c-modal-header-grid" closeButton>
                      <div
                        style={{ borderBottom: '1px solid #bbb' }}
                        className="text-center d-flex flex-column align-items-center mt-2"
                      >
                        <h2>
                          Sprawdź skrzynkę e-mail, na którą został wysłany link do zresetowania
                          hasła
                        </h2>
                        <div className="d-flex mt-3 mb-4">
                          <CIcon
                            icon={cilEnvelopeLetter}
                            customClassName="request-password-reset-success-modal-icon"
                          />
                        </div>
                      </div>
                    </CModalHeader>
                    <CModalBody>
                      <h5 className="mt-2 mb-3">Brak oczekiwanego maila w skrzynce?</h5>
                      <p>
                        Sprawdź folder spam lub poczekaj kilka minut, jeśli problem nadal występuje
                        możesz wysłać ponowną prośbę o link klikając na przycisk poniżej
                      </p>

                      {authState?.requestPasswordResetError && (
                        <CustomErrorMessage
                          wrapperClassNames="mb-0 mt-4"
                          customErrorMessageText={getRequestPasswordResetErrorMessage(
                            authState?.requestPasswordResetError?.status ||
                              authState?.requestPasswordResetError?.data?.status
                          )}
                        />
                      )}

                      <div className="pt-4 d-flex justify-content-center w-100">
                        <CButton
                          color="info"
                          className="px-2 w-50"
                          style={{ maxWidth: '200px' }}
                          type="submit"
                          disabled={
                            authState.isRequestPasswordResetPending ||
                            (didFormValidationOccur && errors.email)
                              ? true
                              : false
                          }
                        >
                          {authState.isRequestPasswordResetPending ? (
                            <>
                              Wysyłanie ponownie
                              <ThreeDots />
                            </>
                          ) : (
                            'Wyślij ponownie'
                          )}
                        </CButton>
                      </div>
                    </CModalBody>
                  </CModal>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </Row>
    </Container>
  )
}
