import { TNetworkError } from './auth'
import {
  TAdminPayload,
  IEditItemOnListPayload,
  TLightItem,
  TFilterState,
  TSorterState,
  ISingleItemActionPayload,
} from './admin'
import { TCurrencyCode } from './boms'
import { TUserLight } from '.'

export const FETCH_WAREHOUSES_REQUESTED = 'FETCH_WAREHOUSES_REQUESTED'
export const FETCH_WAREHOUSES_ERROR = 'FETCH_WAREHOUSES_ERROR'
export const FETCH_WAREHOUSES_SUCCEEDED = 'FETCH_WAREHOUSES_SUCCEEDED'

export const SET_WAREHOUSES_TABLE_FILTER_STATE = 'SET_WAREHOUSES_TABLE_FILTER_STATE'
export const SET_WAREHOUSES_TABLE_SORTER_STATE = 'SET_WAREHOUSES_TABLE_SORTER_STATE'

export const CREATE_WAREHOUSE_REQUESTED = 'CREATE_WAREHOUSE_REQUESTED'
export const CREATE_WAREHOUSE_SUCCEEDED = 'CREATE_WAREHOUSE_SUCCEEDED'
export const CREATE_WAREHOUSE_ERROR = 'CREATE_WAREHOUSE_ERROR'

export const EDIT_WAREHOUSE_REQUESTED = 'EDIT_WAREHOUSE_REQUESTED'
export const EDIT_WAREHOUSE_SUCCEEDED = 'EDIT_WAREHOUSE_SUCCEEDED'
export const EDIT_WAREHOUSE_ERROR = 'EDIT_WAREHOUSE_ERROR'

export const DELETE_WAREHOUSE_REQUESTED = 'DELETE_WAREHOUSE_REQUESTED'
export const DELETE_WAREHOUSE_SUCCEEDED = 'DELETE_WAREHOUSE_SUCCEEDED'
export const DELETE_WAREHOUSE_ERROR = 'DELETE_WAREHOUSE_ERROR'

export const OPEN_WAREHOUSE_DETAILS = 'OPEN_WAREHOUSE_DETAILS'
export const CLOSE_WAREHOUSE_DETAILS = 'CLOSE_WAREHOUSE_DETAILS'

export const WAREHOUSE_ACTIONS_UNLOCK = 'WAREHOUSE_ACTIONS_UNLOCK'

export const FETCH_WAREHOUSE_MANAGEMENT_DELIVERIES_REQUESTED =
  'FETCH_WAREHOUSE_MANAGEMENT_DELIVERIES_REQUESTED'
export const FETCH_WAREHOUSE_MANAGEMENT_DELIVERIES_ERROR =
  'FETCH_WAREHOUSE_MANAGEMENT_DELIVERIES_ERROR'
export const FETCH_WAREHOUSE_MANAGEMENT_DELIVERIES_SUCCEEDED =
  'FETCH_WAREHOUSE_MANAGEMENT_DELIVERIES_SUCCEEDED'

export const SET_MANAGED_WAREHOUSE_DELIVERIES_TABLE_FILTER_STATE =
  'SET_MANAGED_WAREHOUSE_DELIVERIES_TABLE_FILTER_STATE'
export const SET_MANAGED_WAREHOUSE_DELIVERIES_TABLE_SORTER_STATE =
  'SET_MANAGED_WAREHOUSE_DELIVERIES_TABLE_SORTER_STATE'

export const SET_CURRENTLY_MANAGED_WAREHOUSE_ID = 'SET_CURRENTLY_MANAGED_WAREHOUSE_ID'

export const FETCH_WAREHOUSE_INVENTORY_CONTROL_DATA_REQUESTED =
  'FETCH_WAREHOUSE_INVENTORY_CONTROL_DATA_REQUESTED'
export const FETCH_WAREHOUSE_INVENTORY_CONTROL_DATA_ERROR =
  'FETCH_WAREHOUSE_INVENTORY_CONTROL_DATA_ERROR'
export const FETCH_WAREHOUSE_INVENTORY_CONTROL_DATA_SUCCEEDED =
  'FETCH_WAREHOUSE_INVENTORY_CONTROL_DATA_SUCCEEDED'

export const SAVE_WAREHOUSE_INVENTORY_CONTROL_DATA_REQUESTED =
  'SAVE_WAREHOUSE_INVENTORY_CONTROL_DATA_REQUESTED'
export const SAVE_WAREHOUSE_INVENTORY_CONTROL_DATA_ERROR =
  'SAVE_WAREHOUSE_INVENTORY_CONTROL_DATA_ERROR'
export const SAVE_WAREHOUSE_INVENTORY_CONTROL_DATA_SUCCEEDED =
  'SAVE_WAREHOUSE_INVENTORY_CONTROL_DATA_SUCCEEDED'

export const UNLOCK_WAREHOUSE_INVENTORY_CONTROL = 'UNLOCK_WAREHOUSE_INVENTORY_CONTROL'
export const CLOSE_WAREHOUSE_INVENTORY_CONTROL = 'CLOSE_WAREHOUSE_INVENTORY_CONTROL'

export const FETCH_INVENTORIES_OF_WAREHOUSES_REQUESTED = 'FETCH_INVENTORIES_OF_WAREHOUSES_REQUESTED'
export const FETCH_INVENTORIES_OF_WAREHOUSES_ERROR = 'FETCH_INVENTORIES_OF_WAREHOUSES_ERROR'
export const FETCH_INVENTORIES_OF_WAREHOUSES_SUCCEEDED = 'FETCH_INVENTORIES_OF_WAREHOUSES_SUCCEEDED'

export const SET_DELIVERY_FROM_ORDER_IN_MATERIALS_REPLENISHMENT =
  'SET_DELIVERY_FROM_ORDER_IN_MATERIALS_REPLENISHMENT'

export const GENERATE_MATERIALS_REPLENISHMENT_DOCUMENT_REQUESTED =
  'GENERATE_MATERIALS_REPLENISHMENT_DOCUMENT_REQUESTED'
export const GENERATE_MATERIALS_REPLENISHMENT_DOCUMENT_ERROR =
  'GENERATE_MATERIALS_REPLENISHMENT_DOCUMENT_ERROR'
export const GENERATE_MATERIALS_REPLENISHMENT_DOCUMENT_SUCCEEDED =
  'GENERATE_MATERIALS_REPLENISHMENT_DOCUMENT_SUCCEEDED'

export const CLOSE_MATERIALS_REPLENISHMENT = 'CLOSE_MATERIALS_REPLENISHMENT'

export const SET_DELIVERY_FROM_ORDER_IN_WARES_REPLENISHMENT =
  'SET_DELIVERY_FROM_ORDER_IN_WARES_REPLENISHMENT'

export const GENERATE_WARES_REPLENISHMENT_DOCUMENT_REQUESTED =
  'GENERATE_WARES_REPLENISHMENT_DOCUMENT_REQUESTED'
export const GENERATE_WARES_REPLENISHMENT_DOCUMENT_ERROR =
  'GENERATE_WARES_REPLENISHMENT_DOCUMENT_ERROR'
export const GENERATE_WARES_REPLENISHMENT_DOCUMENT_SUCCEEDED =
  'GENERATE_WARES_REPLENISHMENT_DOCUMENT_SUCCEEDED'

export const CLOSE_WARES_REPLENISHMENT = 'CLOSE_WARES_REPLENISHMENT'

export const GENERATE_FINISHED_PRODUCTS_REPLENISHMENT_DOCUMENT_REQUESTED =
  'GENERATE_FINISHED_PRODUCTS_REPLENISHMENT_DOCUMENT_REQUESTED'
export const GENERATE_FINISHED_PRODUCTS_REPLENISHMENT_DOCUMENT_ERROR =
  'GENERATE_FINISHED_PRODUCTS_REPLENISHMENT_DOCUMENT_ERROR'
export const GENERATE_FINISHED_PRODUCTS_REPLENISHMENT_DOCUMENT_SUCCEEDED =
  'GENERATE_FINISHED_PRODUCTS_REPLENISHMENT_DOCUMENT_SUCCEEDED'

export const CLOSE_FINISHED_PRODUCTS_REPLENISHMENT = 'CLOSE_FINISHED_PRODUCTS_REPLENISHMENT'

export const GENERATE_MOVE_REPLENISHMENT_DOCUMENT_REQUESTED =
  'GENERATE_MOVE_REPLENISHMENT_DOCUMENT_REQUESTED'
export const GENERATE_MOVE_REPLENISHMENT_DOCUMENT_ERROR =
  'GENERATE_MOVE_REPLENISHMENT_DOCUMENT_ERROR'
export const GENERATE_MOVE_REPLENISHMENT_DOCUMENT_SUCCEEDED =
  'GENERATE_MOVE_REPLENISHMENT_DOCUMENT_SUCCEEDED'

export const CLEAR_MOVE_REPLENISHMENT_ERROR = 'CLEAR_MOVE_REPLENISHMENT_ERROR'

export const CLOSE_MOVE_REPLENISHMENT = 'CLOSE_MOVE_REPLENISHMENT'

export type TWarehouseLight = {
  name: string
  address1: string
  city: string
  post: string
  country: string
  types: TWarehouseType[]
  usersWithAccess: TUsersWithWarehouseAccess

  id: number
  uuid: string
  canDelete: boolean
}

export type TUsersWithWarehouseAccess = TUserLight[]

/*
 * Warehouse Types
 */
export const materialsTypeNumber = 1
export const waresTypeNumber = 2
export const finishedProductsTypeNumber = 4

export const warehouseTypesObject = {
  [materialsTypeNumber]: 'Materiały',
  [waresTypeNumber]: 'Towary',
  3: 'Trzeci',
  [finishedProductsTypeNumber]: 'Wyroby gotowe',
}

export type TWarehouseType =
  | typeof materialsTypeNumber
  | typeof waresTypeNumber
  | 3
  | typeof finishedProductsTypeNumber

/*
 * Documents of Warehouses Types
 */

export const receiveOrderType = 1
export const warehouseShiftType = 2

export const documentsOfWarehousesTypesObject = {
  [receiveOrderType]: 'PZ',
  [warehouseShiftType]: 'MM',
}

export type TWarehouseDocumentType = typeof receiveOrderType | typeof warehouseShiftType

/*
 * Actions interfaces
 */

export interface ICreateWarehousePayload extends TAdminPayload {
  warehouse: TWarehouseLight
  canDelete: boolean
}

export interface IWarehouseManagementDelivery {
  // values for column 1
  orderNumber: string
  customOrderNumber: string
  deliveryNumber: number | string

  // values for column 2, 3,
  bomElements: IBomElementInWarehouseDelivery[]

  // no values for column 4, 5
  // unplannedMissingQuantity - this per bomElementInDelivery better

  closestDeliveryDate: string
  lastDeliveryDate: string

  // Dokument WZ
  goodsReceivedNote: string
  deliveryNote: string

  bomNames: string[]
  type?: typeof materialsTypeNumber | typeof waresTypeNumber
  id: number
}

export interface IBomElementInWarehouseDelivery {
  bomElementId: number
  bomElementName: string
  warehouseQuantity: number
  plannedQuantity: number
  expectedQuantity: number

  unplannedMissingQuantity: number | null
  plannedMissingQuantity: number | null

  orderDeliveryOrderLineId: number

  type?: typeof materialsTypeNumber | typeof waresTypeNumber
  receivedQuantity?: number
  hasChanged?: boolean
}

// Inventory Control

export interface IWarehouseInventoryControlDataItem {
  // my FE
  bomElementSymbol: string
  bomElementName: string
  warehouseQuantity: number
  realQuantity: number | null
  purchasePrice: number
  purchasePriceCurrency: TCurrencyCode
  totalPrice: number | string | null
  totalPriceCurrency: TCurrencyCode | null
  development: string | null
  id: number
  uuid: string

  // wrong current BE below
  bomElementId: number
  bomElementMpn: string
  bomElementSymbolDin: string
  bomInfos: string[] | null
  bookedPlanned: number | null
  bookedUnplanned: number | null
  // warehouseQuantity: number | null
  lastDeliveryDate: string
  nextDeliveryDate: string
  nextDeliveryValue: string
  referenceNumber: string
  type: number
  warehouseId: number
  warehouseName: string
}

// Inventories of Warehouses

export interface IInventoriesOfWarehousesDataItem {
  bomElementId: number
  bomElementMpn: string
  bomElementName: string
  bomElementSymbolDin: string

  warehouseName: string
  warehouseId: number
  type: number

  bookedPlanned: number | null
  bookedUnplanned: number | null
  warehouseQuantity: number
  lastDeliveryDate: string | null
  nextDeliveryDate: string | null
  nextDeliveryValue: number | null
  referenceNumber: string | null

  bomInfos: any
}

export interface IInventoriesOfWarehousesTableItem {
  bomElementId: number
  bomElementName: string
  bomElementCode: string

  warehouseName: string
  warehouseId: number
  type: number
  warehouseTypeName: string

  bookedPlanned: string | number
  bookedUnplanned: string | number
  warehouseQuantity: string | number

  lastDeliveryDate: string
  nextDeliveryDate: string
  nextDeliveryValue: string | number

  productName: string
}

// Documents of Warehouses

export interface IDocumentsOfWarehousesTableItem {
  documentType: string
  documentNumber: string
  invoices: string
  orderNumber: string
  warehouseName: string
  warehouseId: number
  supplierAndReceiver: string
  quantity: number
  nettoValue: number
  nettoValueCurrency: string
  documentCreateDate: string
}

// Materials Replenishment

export type TMaterialsOrWaresInReplenishment = {
  orderDeliveryOrderLineId: number | null
  quantity: number | null
}

export interface IGenerateMaterialsOrWaresReplenishmentDocumentPayload extends TAdminPayload {
  documentData: {
    warehouseId: number
    date: string
    referenceNumber: string
    replenishmentDetails: TMaterialsOrWaresInReplenishment[]
    orderDeliveryId: number
  }
}

// Move Replenishment

export type TMoveReplenishmentItem = {
  bomElementId: number
  quantity: number
}

export interface IGenerateMoveReplenishmentDocumentPayload extends TAdminPayload {
  documentData: {
    date: string
    referenceNumber: string

    fromWarehouseId: number
    fromType: number
    toWarehouseId: number
    toType: number

    details: TMoveReplenishmentItem[]
  }
}

export type TMoveReplenishmentTableItem = {
  bomElementId: number
  bomElementName: string
  type: number
  warehouseQuantity: number | string
  quantity: number | string
  position: number | string

  hasChanged: boolean
}

// FETCH_WAREHOUSES

export interface fetchWarehousesAction {
  type: typeof FETCH_WAREHOUSES_REQUESTED
  payload: TAdminPayload
}

export interface fetchWarehousesSucceeded {
  type: typeof FETCH_WAREHOUSES_SUCCEEDED
  warehouses: TWarehouseLight[]
}

export interface fetchWarehousesError {
  type: typeof FETCH_WAREHOUSES_ERROR
  error: TNetworkError
}

// SET_WAREHOUSES_TABLE_FILTER_STATE

export interface setWarehousesTableFilterState {
  type: typeof SET_WAREHOUSES_TABLE_FILTER_STATE
  tableFilterState: TFilterState
}

// SET_WAREHOUSES_TABLE_SORTER_STATE

export interface setWarehousesTableSorterState {
  type: typeof SET_WAREHOUSES_TABLE_SORTER_STATE
  tableSorterState: TSorterState
}

// CREATE_WAREHOUSE

export interface createWarehouseAction {
  type: typeof CREATE_WAREHOUSE_REQUESTED
  payload: ICreateWarehousePayload
}

export interface createWarehouseSucceeded {
  type: typeof CREATE_WAREHOUSE_SUCCEEDED
  warehouse: TWarehouseLight
}

export interface createWarehouseError {
  type: typeof CREATE_WAREHOUSE_ERROR
  error: TNetworkError
}

// EDIT_WAREHOUSE

export interface editWarehouseAction {
  type: typeof EDIT_WAREHOUSE_REQUESTED
  payload: ICreateWarehousePayload
}

export interface editWarehouseSucceeded {
  type: typeof EDIT_WAREHOUSE_SUCCEEDED
  warehouse: TWarehouseLight
}

export interface editWarehouseError {
  type: typeof EDIT_WAREHOUSE_ERROR
  error: TNetworkError
}

// OPEN_WAREHOUSE_DETAILS

export interface openWarehouseDetailsAction {
  type: typeof OPEN_WAREHOUSE_DETAILS
  editedWarehouse: TWarehouseLight | null
}

export interface closeWarehouseDetailsAction {
  type: typeof CLOSE_WAREHOUSE_DETAILS
}

export interface warehouseActionsUnlockAction {
  type: typeof WAREHOUSE_ACTIONS_UNLOCK
}

/*
 * Actions on Warehouses List
 */

// DELETE_WAREHOUSE

export interface deleteWarehouseAction {
  type: typeof DELETE_WAREHOUSE_REQUESTED
  payload: IEditItemOnListPayload
}

export interface deleteWarehouseSucceeded {
  type: typeof DELETE_WAREHOUSE_SUCCEEDED
  warehouse: TLightItem
}

export interface deleteWarehouseError {
  type: typeof DELETE_WAREHOUSE_ERROR
  error: TNetworkError
}

// FETCH_WAREHOUSES

export interface fetchWarehouseManagementDeliveriesAction {
  type: typeof FETCH_WAREHOUSE_MANAGEMENT_DELIVERIES_REQUESTED
  payload: ISingleItemActionPayload
}

export interface fetchWarehouseManagementDeliveriesSucceeded {
  type: typeof FETCH_WAREHOUSE_MANAGEMENT_DELIVERIES_SUCCEEDED
  managedWarehouseDeliveries: IWarehouseManagementDelivery[]
}

export interface fetchWarehouseManagementDeliveriesError {
  type: typeof FETCH_WAREHOUSE_MANAGEMENT_DELIVERIES_ERROR
  error: TNetworkError
}

// SET_WAREHOUSES_TABLE_FILTER_STATE

export interface setWarehousesTableFilterState {
  type: typeof SET_WAREHOUSES_TABLE_FILTER_STATE
  tableFilterState: TFilterState
}

// SET_WAREHOUSES_TABLE_SORTER_STATE

export interface setManagedWarehouseDeliveriesTableSorterState {
  type: typeof SET_MANAGED_WAREHOUSE_DELIVERIES_TABLE_SORTER_STATE
  tableSorterState: TSorterState
}

export interface setManagedWarehouseDeliveriesTableFilterState {
  type: typeof SET_MANAGED_WAREHOUSE_DELIVERIES_TABLE_FILTER_STATE
  tableFilterState: TFilterState
}

/*
 * Actions on Managed Warehouse
 */

// SET_CURRENTLY_MANAGED_WAREHOUSE_ID

export interface IInventoryControlFormData {
  inventoryControlWarehouseId: number
  inventoryControlDate: string
  inventoryControlTotalPrice: number | string
  inventoryControlTotalPriceCurrency: TCurrencyCode
  details: IWarehouseInventoryControlDataItem[]
}

export interface setCurrentlyManagedWarehouseIdAction {
  type: typeof SET_CURRENTLY_MANAGED_WAREHOUSE_ID
  currentlyManagedWarehouseId: number
}

// FETCH_WAREHOUSE_INVENTORY_CONTROL_DATA

export interface fetchWarehouseInventoryControlDataAction {
  type: typeof FETCH_WAREHOUSE_INVENTORY_CONTROL_DATA_REQUESTED
  payload: ISingleItemActionPayload
}

export interface fetchWarehouseInventoryControlDataSucceeded {
  type: typeof FETCH_WAREHOUSE_INVENTORY_CONTROL_DATA_SUCCEEDED
  warehouseInventoryControlData: Omit<
    IInventoryControlFormData,
    'inventoryControlDate' | 'inventoryControlWarehouseId'
  > | null
}

export interface fetchWarehouseInventoryControlDataError {
  type: typeof FETCH_WAREHOUSE_INVENTORY_CONTROL_DATA_ERROR
  error: TNetworkError
}

// SAVE_WAREHOUSE_INVENTORY_CONTROL_DATA

export interface ISaveWarehouseInventoryControlDataPayload extends TAdminPayload {
  saveInventoryControlData: {
    inventoryControlDate: string
    inventoryControlWarehouseId: number
    details: Omit<
      IInventoryControlFormData,
      'inventoryControlTotalPrice' | 'inventoryControlTotalPriceCurrency'
    >
  }
}

export interface saveWarehouseInventoryControlDataAction {
  type: typeof SAVE_WAREHOUSE_INVENTORY_CONTROL_DATA_REQUESTED
  payload: ISaveWarehouseInventoryControlDataPayload
}

export interface saveWarehouseInventoryControlDataSucceeded {
  type: typeof SAVE_WAREHOUSE_INVENTORY_CONTROL_DATA_SUCCEEDED
}

export interface saveWarehouseInventoryControlDataError {
  type: typeof SAVE_WAREHOUSE_INVENTORY_CONTROL_DATA_ERROR
  error: TNetworkError
}

export interface unlockWarehouseInventoryControl {
  type: typeof UNLOCK_WAREHOUSE_INVENTORY_CONTROL
}

export interface closeWarehouseInventoryControl {
  type: typeof CLOSE_WAREHOUSE_INVENTORY_CONTROL
}

// FETCH_INVENTORIES_OF_WAREHOUSES

export interface fetchInventoriesOfWarehousesAction {
  type: typeof FETCH_INVENTORIES_OF_WAREHOUSES_REQUESTED
  payload: TAdminPayload
}

export interface fetchInventoriesOfWarehousesSucceeded {
  type: typeof FETCH_INVENTORIES_OF_WAREHOUSES_SUCCEEDED
  inventoriesOfWarehouses: IInventoriesOfWarehousesDataItem[]
}

export interface fetchInventoriesOfWarehousesError {
  type: typeof FETCH_INVENTORIES_OF_WAREHOUSES_ERROR
  error: TNetworkError
}

// MATERIALS_REPLENISHMENT

export interface setDeliveryFromOrderInMaterialsReplenishment {
  type: typeof SET_DELIVERY_FROM_ORDER_IN_MATERIALS_REPLENISHMENT
  deliveryFromOrder: IWarehouseManagementDelivery
}

// GENERATE_MATERIALS_REPLENISHMENT_DOCUMENT

export interface generateMaterialsReplenishmentDocumentAction {
  type: typeof GENERATE_MATERIALS_REPLENISHMENT_DOCUMENT_REQUESTED
  payload: IGenerateMaterialsOrWaresReplenishmentDocumentPayload
}

export interface generateMaterialsReplenishmentDocumentSucceeded {
  type: typeof GENERATE_MATERIALS_REPLENISHMENT_DOCUMENT_SUCCEEDED
}

export interface generateMaterialsReplenishmentDocumentError {
  type: typeof GENERATE_MATERIALS_REPLENISHMENT_DOCUMENT_ERROR
  error: TNetworkError
}

export interface closeMaterialsReplenishment {
  type: typeof CLOSE_MATERIALS_REPLENISHMENT
}

// WARES_REPLENISHMENT

export interface setDeliveryFromOrderInWaresReplenishment {
  type: typeof SET_DELIVERY_FROM_ORDER_IN_WARES_REPLENISHMENT
  deliveryFromOrder: IWarehouseManagementDelivery
}

// GENERATE_WARES_REPLENISHMENT_DOCUMENT

export interface generateWaresReplenishmentDocumentAction {
  type: typeof GENERATE_WARES_REPLENISHMENT_DOCUMENT_REQUESTED
  payload: IGenerateMaterialsOrWaresReplenishmentDocumentPayload
}

export interface generateWaresReplenishmentDocumentSucceeded {
  type: typeof GENERATE_WARES_REPLENISHMENT_DOCUMENT_SUCCEEDED
}

export interface generateWaresReplenishmentDocumentError {
  type: typeof GENERATE_WARES_REPLENISHMENT_DOCUMENT_ERROR
  error: TNetworkError
}

export interface closeWaresReplenishment {
  type: typeof CLOSE_WARES_REPLENISHMENT
}

// FINISHED_PRODUCTS_REPLENISHMENT

// export interface setDeliveryFromOrderInMaterialsReplenishment {
//   type: typeof SET_DELIVERY_FROM_ORDER_IN_FINISHED_PRODUCTS_REPLENISHMENT
//   deliveryFromOrder: IWarehouseManagementDelivery
// }

// GENERATE_FINISHED_PRODUCTS_REPLENISHMENT_DOCUMENT

export interface generateFinishedProductsReplenishmentDocumentAction {
  type: typeof GENERATE_FINISHED_PRODUCTS_REPLENISHMENT_DOCUMENT_REQUESTED
  payload: any
}

export interface generateFinishedProductsReplenishmentDocumentSucceeded {
  type: typeof GENERATE_FINISHED_PRODUCTS_REPLENISHMENT_DOCUMENT_SUCCEEDED
}

export interface generateFinishedProductsReplenishmentDocumentError {
  type: typeof GENERATE_FINISHED_PRODUCTS_REPLENISHMENT_DOCUMENT_ERROR
  error: TNetworkError
}

export interface closeFinishedProductsReplenishment {
  type: typeof CLOSE_FINISHED_PRODUCTS_REPLENISHMENT
}

// GENERATE_MOVE_REPLENISHMENT_DOCUMENT

export interface generateMoveReplenishmentDocumentAction {
  type: typeof GENERATE_MOVE_REPLENISHMENT_DOCUMENT_REQUESTED
  payload: IGenerateMoveReplenishmentDocumentPayload
}

export interface generateMoveReplenishmentDocumentSucceeded {
  type: typeof GENERATE_MOVE_REPLENISHMENT_DOCUMENT_SUCCEEDED
}

export interface generateMoveReplenishmentDocumentError {
  type: typeof GENERATE_MOVE_REPLENISHMENT_DOCUMENT_ERROR
  error: TNetworkError
}

export interface clearMoveReplenishmentErrorAction {
  type: typeof CLEAR_MOVE_REPLENISHMENT_ERROR
}

export interface closeMoveReplenishment {
  type: typeof CLOSE_MOVE_REPLENISHMENT
}

export type WarehousesActionTypes =
  | fetchWarehousesAction
  | fetchWarehousesError
  | fetchWarehousesSucceeded
  | setWarehousesTableFilterState
  | setWarehousesTableSorterState
  | createWarehouseAction
  | createWarehouseSucceeded
  | createWarehouseError
  | deleteWarehouseAction
  | deleteWarehouseSucceeded
  | deleteWarehouseError
  | editWarehouseAction
  | editWarehouseSucceeded
  | editWarehouseError
  | openWarehouseDetailsAction
  | closeWarehouseDetailsAction
  | warehouseActionsUnlockAction
  | fetchWarehouseManagementDeliveriesAction
  | fetchWarehouseManagementDeliveriesSucceeded
  | fetchWarehouseManagementDeliveriesError
  | setManagedWarehouseDeliveriesTableSorterState
  | setManagedWarehouseDeliveriesTableFilterState
  | setCurrentlyManagedWarehouseIdAction
  | fetchWarehouseInventoryControlDataAction
  | fetchWarehouseInventoryControlDataSucceeded
  | fetchWarehouseInventoryControlDataError
  | saveWarehouseInventoryControlDataAction
  | saveWarehouseInventoryControlDataSucceeded
  | saveWarehouseInventoryControlDataError
  | closeWarehouseInventoryControl
  | unlockWarehouseInventoryControl
  | fetchInventoriesOfWarehousesAction
  | fetchInventoriesOfWarehousesSucceeded
  | fetchInventoriesOfWarehousesError
  | setDeliveryFromOrderInMaterialsReplenishment
  | generateMaterialsReplenishmentDocumentAction
  | generateMaterialsReplenishmentDocumentSucceeded
  | generateMaterialsReplenishmentDocumentError
  | closeMaterialsReplenishment
  | setDeliveryFromOrderInWaresReplenishment
  | generateWaresReplenishmentDocumentAction
  | generateWaresReplenishmentDocumentSucceeded
  | generateWaresReplenishmentDocumentError
  | closeWaresReplenishment
  | generateFinishedProductsReplenishmentDocumentAction
  | generateFinishedProductsReplenishmentDocumentSucceeded
  | generateFinishedProductsReplenishmentDocumentError
  | closeFinishedProductsReplenishment
  | generateMoveReplenishmentDocumentAction
  | generateMoveReplenishmentDocumentSucceeded
  | generateMoveReplenishmentDocumentError
  | clearMoveReplenishmentErrorAction
  | closeMoveReplenishment
