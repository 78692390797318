import { call, put, takeLatest, delay } from 'redux-saga/effects'

import {
  sectionFetch,
  deleteItemCall,
  purchasePlanCall,
  singlePurchasePlanBomElementFetch,
  generateOrderCall,
  singlePurchasePlanImplementationCostFetch,
  singlePurchasePlanServiceFetch,
  singleItemFetch,
} from './api'
import * as actionTypes from '../action-types'
import { successMessageDuration } from '../../utils'

export function* fetchPurchasePlans(action: actionTypes.fetchPurchasePlansAction) {
  try {
    const response = yield call(sectionFetch, action.payload, 'purchasePlans')
    yield put({ type: actionTypes.FETCH_PURCHASE_PLANS_SUCCEEDED, purchasePlans: response.data })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_PURCHASE_PLANS_ERROR,
      error: error?.response,
    })
  }
}

export function* fetchSinglePurchasePlan(action: actionTypes.fetchSingleBomAction) {
  try {
    const response = yield call(
      singleItemFetch,
      action.payload,
      `purchasePlans/edit/${action.payload.id}`
    )
    yield put({
      type: actionTypes.FETCH_SINGLE_PURCHASE_PLAN_SUCCEEDED,
      singlePurchasePlan: response.data,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_SINGLE_PURCHASE_PLAN_ERROR,
      error: error?.response,
    })
  }
}

export function* editPurchasePlan(action: actionTypes.editPurchasePlanAction) {
  try {
    yield call(purchasePlanCall, action.payload)
    yield put({
      type: actionTypes.EDIT_PURCHASE_PLAN_SUCCEEDED,
      purchasePlan: { ...action.payload.purchasePlan },
    })
    yield delay(successMessageDuration)
    yield put({
      type: actionTypes.UNLOCK_PURCHASE_PLAN_ACTIONS,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.EDIT_PURCHASE_PLAN_ERROR,
      error: error?.response,
    })
    if (window && window.document) {
      window.scrollTo({ top: 0 })
    }
  }
}

export function* fetchPurchasePlanBomElement(
  action: actionTypes.fetchPurchasePlanBomElementAction
) {
  try {
    yield put({ type: actionTypes.OPEN_PURCHASE_PLAN_BOM_ELEMENT_MODAL })
    const response = yield call(singlePurchasePlanBomElementFetch, action.payload)
    yield put({
      type: actionTypes.FETCH_PURCHASE_PLAN_BOM_ELEMENT_SUCCEEDED,
      purchasePlanBomElementModalTabs: response.data,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_PURCHASE_PLAN_BOM_ELEMENT_ERROR,
      error: error?.response,
    })
    // maybe this is no longer needed if modal is opened on first try
    yield put({ type: actionTypes.OPEN_PURCHASE_PLAN_BOM_ELEMENT_MODAL })
  }
}

export function* fetchPurchasePlanImplementationCost(
  action: actionTypes.fetchPurchasePlanImplementationCostAction
) {
  try {
    yield put({ type: actionTypes.OPEN_PURCHASE_PLAN_IMPLEMENTATION_COST_MODAL })
    const response = yield call(singlePurchasePlanImplementationCostFetch, action.payload)
    yield put({
      type: actionTypes.FETCH_PURCHASE_PLAN_IMPLEMENTATION_COST_SUCCEEDED,
      purchasePlanImplementationCostModalTabs: response.data,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_PURCHASE_PLAN_IMPLEMENTATION_COST_ERROR,
      error: error?.response,
    })
    // maybe this is no longer needed if modal is opened on first try
    yield put({ type: actionTypes.OPEN_PURCHASE_PLAN_IMPLEMENTATION_COST_MODAL })
  }
}

export function* fetchPurchasePlanService(action: actionTypes.fetchPurchasePlanServiceAction) {
  try {
    yield put({ type: actionTypes.OPEN_PURCHASE_PLAN_SERVICE_MODAL })
    const response = yield call(singlePurchasePlanServiceFetch, action.payload)
    yield put({
      type: actionTypes.FETCH_PURCHASE_PLAN_SERVICE_SUCCEEDED,
      purchasePlanServiceModalTabs: response.data,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_PURCHASE_PLAN_SERVICE_ERROR,
      error: error?.response,
    })
    // maybe this is no longer needed if modal is opened on first try
    yield put({ type: actionTypes.OPEN_PURCHASE_PLAN_SERVICE_MODAL })
  }
}

export function* createPurchasePlanFromBoms(action: actionTypes.createPurchasePlanFromBomsAction) {
  try {
    yield call(purchasePlanCall, action.payload)

    yield call(fetchPurchasePlans, {
      type: actionTypes.FETCH_PURCHASE_PLANS_REQUESTED,
      payload: { token: action.payload.token, tenant: action.payload.tenant },
    })

    yield put({
      type: actionTypes.CREATE_PURCHASE_PLAN_FROM_BOMS_SUCCEEDED,
    })

    yield delay(successMessageDuration)

    yield put({
      type: actionTypes.CLOSE_PURCHASE_PLAN_CREATE_FROM_BOMS_MODAL,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.CREATE_PURCHASE_PLAN_FROM_BOMS_ERROR,
      error: error?.response,
    })
  }
}

export function* deletePurchasePlan(action: actionTypes.deletePurchasePlanAction) {
  try {
    yield call(deleteItemCall, action.payload, 'purchasePlans')
    yield put({
      type: actionTypes.DELETE_PURCHASE_PLAN_SUCCEEDED,
      id: action.payload.id,
    })
    yield delay(successMessageDuration)

    yield put({
      type: actionTypes.UNLOCK_PURCHASE_PLAN_ACTIONS,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.DELETE_PURCHASE_PLAN_ERROR,
      error: error?.response,
    })
  }
}

export function* generateOrder(action: actionTypes.generateOrderAction) {
  try {
    let orderResponse = yield call(generateOrderCall, action.payload)

    yield put({
      type: actionTypes.GENERATE_ORDER_SUCCEEDED,
      generatedOrderId: orderResponse?.data?.id || null,
    })

    yield delay(successMessageDuration)

    yield put({
      type: actionTypes.FETCH_ORDERS_REQUESTED,
      payload: { tenant: action.payload.tenant, token: action.payload.token },
    })

    yield put({
      type: actionTypes.UNLOCK_GENERATED_ORDER,
    })

    yield put({
      type: actionTypes.UNLOCK_PURCHASE_PLAN_ACTIONS,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.GENERATE_ORDER_ERROR,
      error: error.purchasePlanResponse.data
        ? error.purchasePlanResponse.data
        : error.purchasePlanResponse,
    })
  }
}

export function* purchasePlansSaga() {
  yield takeLatest(actionTypes.FETCH_PURCHASE_PLANS_REQUESTED, fetchPurchasePlans)
  yield takeLatest(actionTypes.CREATE_PURCHASE_PLAN_FROM_BOMS_REQUESTED, createPurchasePlanFromBoms)

  yield takeLatest(actionTypes.FETCH_SINGLE_PURCHASE_PLAN_REQUESTED, fetchSinglePurchasePlan)
  yield takeLatest(actionTypes.EDIT_PURCHASE_PLAN_REQUESTED, editPurchasePlan)

  yield takeLatest(
    actionTypes.FETCH_PURCHASE_PLAN_BOM_ELEMENT_REQUESTED,
    fetchPurchasePlanBomElement
  )
  yield takeLatest(
    actionTypes.FETCH_PURCHASE_PLAN_IMPLEMENTATION_COST_REQUESTED,
    fetchPurchasePlanImplementationCost
  )

  yield takeLatest(actionTypes.FETCH_PURCHASE_PLAN_SERVICE_REQUESTED, fetchPurchasePlanService)

  yield takeLatest(actionTypes.DELETE_PURCHASE_PLAN_REQUESTED, deletePurchasePlan)
  yield takeLatest(actionTypes.GENERATE_ORDER_REQUESTED, generateOrder)
}
