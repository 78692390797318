///<reference types="webpack-env" />

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from './store/store'

import './index.css'
import App from './app'
import * as serviceWorker from './serviceWorker'

const render = (Component: React.FC) => {
  return ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <Component />
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  )
}

render(App)

if (module?.hot) {
  module?.hot?.accept('./app', () => {
    const NextApp = require('./app').default
    render(NextApp)
  })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
