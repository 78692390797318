import React, { useEffect, useMemo } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Container } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { CDataTable, CCard, CCol, CCardBody, CButton } from '@coreui/react'

import * as actionTypes from '../../store/action-types'
import { TRootState } from '../../store/reducers'
import { getEmptyListMessage, makeSearchBoxFixed } from '../../utils'
import { serviceNavTabs } from './service-details'

const Services: React.FC<{}> = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tenant } = useParams<{ tenant: string }>()

  const authState = useSelector((state: TRootState) => state.auth)
  const suppliersState = useSelector((state: TRootState) => state.suppliers)
  const servicesState = useSelector((state: TRootState) => state.services)

  const mainTab = serviceNavTabs[0].name

  // Make search box fixed
  useEffect(() => {
    makeSearchBoxFixed()
  }, [makeSearchBoxFixed])

  const memoizedTableItems = useMemo(
    () =>
      servicesState?.services?.map((service: actionTypes.TService) => {
        return {
          ...service,
          name: service?.name || '',
          supplierNames:
            service?.supplierInfos
              ?.map(
                (supplierInService: any) =>
                  suppliersState.suppliers.find(
                    (supplier: actionTypes.TSupplier) =>
                      Number(supplier.id) === Number(supplierInService?.supplierId)
                  )?.name
              )
              ?.join(' / ') || '',
          icon: '🖊️',
        }
      }),
    [servicesState.services, suppliersState.suppliers]
  )

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center">
      <CCol xl="7">
        <CCard>
          <CCardBody className="pb-2">
            {authState?.authData?.roles?.includes('Services_write') && (
              <div className="d-flex justify-content-end list-fixed-create-new-button-wrapper">
                <CButton
                  color="info"
                  variant="outline"
                  size="50"
                  onClick={() => {
                    history.push(`/${tenant}/admin/services/create/${mainTab}`)
                  }}
                >
                  Nowa Usługa
                </CButton>
              </div>
            )}

            <CDataTable
              tableFilterValue={servicesState?.tableFilterState}
              sorterValue={servicesState?.tableSorterState}
              onSorterValueChange={(sorterState: actionTypes.TSorterState) =>
                dispatch({
                  type: actionTypes.SET_SERVICES_TABLE_SORTER_STATE,
                  tableSorterState: sorterState,
                })
              }
              onTableFilterChange={(tableFilterState: string) =>
                dispatch({
                  type: actionTypes.SET_SERVICES_TABLE_FILTER_STATE,
                  tableFilterState: tableFilterState,
                })
              }
              loading={servicesState.areServicesLoading}
              striped={!!servicesState.services.length}
              border
              sorter
              tableFilter={{ label: ' ', placeholder: 'Szukaj w tabeli...' }}
              cleaner
              addTableClasses="vertical-middle-list-table"
              onRowClick={(service: actionTypes.TService) => {
                history.push(`/${tenant}/admin/services/edit/${service.id}/${mainTab}`)
              }}
              fields={[
                {
                  key: 'icon',
                  label: '',
                  sorter: false,
                  filter: false,
                  _style: {
                    width: '25px',
                    display: !servicesState.services.length ? 'none' : '',
                  },
                },
                { key: 'name', label: 'Nazwa', _style: { width: '40%' } },
                {
                  key: 'supplierNames',
                  label: 'Dostawcy',
                  _style: { width: '60%' },
                },
              ]}
              items={memoizedTableItems}
              noItemsViewSlot={
                <div className="no-items-in-table">
                  {getEmptyListMessage(
                    servicesState.fetchServicesError,
                    servicesState.areServicesLoading,
                    'usług',
                    servicesState?.services?.length
                  )}
                </div>
              }
            />
          </CCardBody>
        </CCard>
      </CCol>
    </Container>
  )
}

export default Services
