import NumberFormat from 'react-number-format'
import { globalDecimalSeparator, globalThousandSeparator } from '.'

export const globalNumberFormatClass: any = new NumberFormat({
  displayType: 'input',
  thousandSeparator: globalThousandSeparator,
  decimalSeparator: globalDecimalSeparator,
})

export const convertNumericStringToNumber = (
  numericString: string | number | null | undefined
): number | null => {
  if (typeof numericString === 'string') {
    let convertedFloat = parseFloat(globalNumberFormatClass.removeFormatting(numericString))

    if (convertedFloat && !isNaN(convertedFloat)) {
      return convertedFloat
    }
  }

  if (typeof numericString === 'number') {
    return numericString
  }

  return null
}
