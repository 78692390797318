import { TNetworkError } from './auth'
import {
  TAdminPayload,
  ISingleItemActionPayload,
  IEditItemOnListPayload,
  TLightItem,
  TFilterState,
  TSorterState,
} from './admin'
import { TLightOfferInTable } from '.'

export const FETCH_BOMS_REQUESTED = 'FETCH_BOMS_REQUESTED'
export const FETCH_BOMS_ERROR = 'FETCH_BOMS_ERROR'
export const FETCH_BOMS_SUCCEEDED = 'FETCH_BOMS_SUCCEEDED'

export const SET_BOMS_TABLE_FILTER_STATE = 'SET_BOMS_TABLE_FILTER_STATE'
export const SET_BOMS_TABLE_SORTER_STATE = 'SET_BOMS_TABLE_SORTER_STATE'

export const CREATE_BOM_REQUESTED = 'CREATE_BOM_REQUESTED'
export const CREATE_BOM_SUCCEEDED = 'CREATE_BOM_SUCCEEDED'
export const CREATE_BOM_ERROR = 'CREATE_BOM_ERROR'

export const ARCHIVE_BOM_REQUESTED = 'ARCHIVE_BOM_REQUESTED'
export const ARCHIVE_BOM_SUCCEEDED = 'ARCHIVE_BOM_SUCCEEDED'
export const ARCHIVE_BOM_ERROR = 'ARCHIVE_BOM_ERROR'

export const COPY_BOM_REQUESTED = 'COPY_BOM_REQUESTED'
export const COPY_BOM_SUCCEEDED = 'COPY_BOM_SUCCEEDED'
export const COPY_BOM_ERROR = 'COPY_BOM_ERROR'

export const FETCH_SINGLE_BOM_REQUESTED = 'FETCH_SINGLE_BOM_REQUESTED'
export const FETCH_SINGLE_BOM_ERROR = 'FETCH_SINGLE_BOM_ERROR'
export const FETCH_SINGLE_BOM_SUCCEEDED = 'FETCH_SINGLE_BOM_SUCCEEDED'

export const EDIT_BOM_REQUESTED = 'EDIT_BOM_REQUESTED'
export const EDIT_BOM_SUCCEEDED = 'EDIT_BOM_SUCCEEDED'
export const EDIT_BOM_ERROR = 'EDIT_BOM_ERROR'

export const DELETE_BOM_REQUESTED = 'DELETE_BOM_REQUESTED'
export const DELETE_BOM_SUCCEEDED = 'DELETE_BOM_SUCCEEDED'
export const DELETE_BOM_ERROR = 'DELETE_BOM_ERROR'

export const OPEN_BOM_DETAILS = 'OPEN_BOM_DETAILS'
export const CLOSE_BOM_DETAILS = 'CLOSE_BOM_DETAILS'
export const BOM_ACTIONS_UNLOCK = 'BOM_ACTIONS_UNLOCK'

export type TCurrencyCode = 'CNY' | 'EUR' | 'GBP' | 'PLN' | 'USD' | string | undefined

export type TCurrencyISO = {
  code: TCurrencyCode
  decimalDigits: number
  englishName: string
  number: string
  symbol?: string

  isObsolete?: boolean
  majorUnit?: number
  minorUnit?: number
  namespace?: string
  validFrom?: string
  validTo?: string
}

export type TBomLight = {
  name: string
  id: number
  canDelete: boolean
  icon?: string
}

export interface IBomFullElement {
  bomElementId: number | null
  bomElementMeasurementType: 1 | 2
  bomElementName: string
  bomElementCentimetersInPiece: number | null
  categoryPlaceholderBeforeAssignment?: number
  category: number
  position: undefined | number

  quantity: number | null | ''

  estimatedPrice: number | null | string
  estimatedPriceCurrency: TCurrencyCode

  offers: TLightOfferInTable[] | []
  offer: TLightOfferInTable | null
  offerId: number | null

  lastOrderPrice: number | null | string
  lastOrderPriceCurrency: TCurrencyCode | null

  estimatedLeadTimeFrom: number | null | ''
  estimatedLeadTimeTo: number | null | ''

  cost: number | undefined | null | string

  bomId?: number
  id?: number | null
  uuId: string
}

export interface IBomAdditionalCost {
  quantity: number | null | ''

  estimatedPrice: number | null | string
  estimatedPriceCurrency: TCurrencyCode

  offer: TLightOfferInTable | null
  offerId: number | null

  lastOrderPrice: number | null | string
  lastOrderPriceCurrency: TCurrencyCode

  cost: number | undefined | null | string

  isChecked: boolean
  bomId?: number
  id: number | null
  uuId: string
}

export interface IBomService extends IBomAdditionalCost {
  bomServiceId: number | null
  bomServiceName: string
}

export interface IBomImplementationCost extends IBomAdditionalCost {
  bomImplementationCostId: number | null
  bomImplementationCostName: string
}

export interface IBomCalculation {
  msrp: number | string
  msrpCurrency: TCurrencyCode

  taxRate: number | string

  distributorPrice: number | string
  distributorPriceCurrency: TCurrencyCode
  distributorMargin: number | string
  distributorQuantity: number | string

  dealerPrice: number | string
  dealerPriceCurrency: TCurrencyCode
  dealerMargin: number | string
  dealerQuantity: number | ''

  income: number | string
  incomeCurrency: TCurrencyCode

  bomId?: number
  id: number | null
  uuId: string
}

export interface IBomFull {
  name: string
  estimatedQuantity: number
  purchasePlanQuantity: string | number | null
  purchasePlanNumber?: string | null
  purchasePlanId?: number | null

  estimatedTotalBomCostCurrency: TCurrencyCode
  offerTotalBomCostCurrency: TCurrencyCode
  orderTotalBomCostCurrency: TCurrencyCode

  bomElementDetails: IBomFullElement[]
  bomServiceDetails: IBomService[]
  bomImplementationCostDetails: IBomImplementationCost[]
  bomCalculations: IBomCalculation[]

  canDelete?: boolean
  id?: number
}

export interface ITotalBomCostCalculatorValues extends IBomFull {
  estimatedTotalBomCost: number
  estimatedTotalBomCostCurrency: TCurrencyCode
  offerTotalBomCost: number
  offerTotalBomCostCurrency: TCurrencyCode
  orderTotalBomCost: number
  orderTotalBomCostCurrency: TCurrencyCode
  isTotalCostPerUnit: boolean
}

/*
 * Actions interfaces
 */

export interface ICreateBomPayload extends TAdminPayload {
  bom: IBomFull
  lastTabOpen: string
  canDelete: boolean
}

// FETCH_BOMS

export interface fetchBomsAction {
  type: typeof FETCH_BOMS_REQUESTED
  payload: TAdminPayload
}

export interface fetchBomsSucceeded {
  type: typeof FETCH_BOMS_SUCCEEDED
  boms: TBomLight[]
}

export interface fetchBomsError {
  type: typeof FETCH_BOMS_ERROR
  error: TNetworkError
}

// SET_BOMS_TABLE_FILTER_STATE

export interface setBomsTableFilterState {
  type: typeof SET_BOMS_TABLE_FILTER_STATE
  tableFilterState: TFilterState
}

// SET_BOMS_TABLE_SORTER_STATE

export interface setBomsTableSorterState {
  type: typeof SET_BOMS_TABLE_SORTER_STATE
  tableSorterState: TSorterState
}

// CREATE_BOM

export interface createBomAction {
  type: typeof CREATE_BOM_REQUESTED
  payload: ICreateBomPayload
}

export interface createBomSucceeded {
  type: typeof CREATE_BOM_SUCCEEDED
  bom: TBomLight
}

export interface createBomError {
  type: typeof CREATE_BOM_ERROR
  error: TNetworkError
}

// FETCH_SINGLE_BOM

export interface fetchSingleBomAction {
  type: typeof FETCH_SINGLE_BOM_REQUESTED
  payload: ISingleItemActionPayload
}

export interface fetchSingleBomSucceeded {
  type: typeof FETCH_SINGLE_BOM_SUCCEEDED
  editedBom: IBomFull
}

export interface fetchSingleBomError {
  type: typeof FETCH_SINGLE_BOM_ERROR
  error: TNetworkError
}

// EDIT_BOM

export interface editBomAction {
  type: typeof EDIT_BOM_REQUESTED
  payload: ICreateBomPayload
}

export interface editBomSucceeded {
  type: typeof EDIT_BOM_SUCCEEDED
  bom: TBomLight
}

export interface editBomError {
  type: typeof EDIT_BOM_ERROR
  error: TNetworkError
}

export interface openBomDetailsAction {
  type: typeof OPEN_BOM_DETAILS
}

export interface closeBomDetailsAction {
  type: typeof CLOSE_BOM_DETAILS
}

export interface bomActionsUnlockAction {
  type: typeof BOM_ACTIONS_UNLOCK
}

/*
 * Actions on BOMs List
 */

// COPY BOM

export interface copyBomAction {
  type: typeof COPY_BOM_REQUESTED
  payload: IEditItemOnListPayload
}

export interface copyBomSucceeded {
  type: typeof COPY_BOM_SUCCEEDED
  bom: TBomLight
}

export interface copyBomError {
  type: typeof COPY_BOM_ERROR
  error: TNetworkError
}

// ARCHIVE_BOM

export interface archiveBomAction {
  type: typeof ARCHIVE_BOM_REQUESTED
  payload: IEditItemOnListPayload
}

export interface archiveBomSucceeded {
  type: typeof ARCHIVE_BOM_SUCCEEDED
  bom: TLightItem
}

export interface archiveBomError {
  type: typeof ARCHIVE_BOM_ERROR
  error: TNetworkError
}

// DELETE_BOM

export interface deleteBomAction {
  type: typeof DELETE_BOM_REQUESTED
  payload: IEditItemOnListPayload
}

export interface deleteBomSucceeded {
  type: typeof DELETE_BOM_SUCCEEDED
  bom: TLightItem
}

export interface deleteBomError {
  type: typeof DELETE_BOM_ERROR
  error: TNetworkError
}

export type BomsActionTypes =
  | fetchBomsAction
  | fetchBomsError
  | fetchBomsSucceeded
  | setBomsTableFilterState
  | setBomsTableSorterState
  | createBomAction
  | createBomSucceeded
  | createBomError
  | copyBomAction
  | copyBomSucceeded
  | copyBomError
  | archiveBomAction
  | archiveBomSucceeded
  | archiveBomError
  | deleteBomAction
  | deleteBomSucceeded
  | deleteBomError
  | fetchSingleBomAction
  | fetchSingleBomError
  | fetchSingleBomSucceeded
  | editBomAction
  | editBomSucceeded
  | editBomError
  | openBomDetailsAction
  | closeBomDetailsAction
  | bomActionsUnlockAction
