import React, { useState, useEffect, RefObject, useRef, useCallback, ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { Container, InputGroup, InputGroupAddon, InputGroupText, Spinner, Table } from 'reactstrap'
import { Formik, Form, Field, ErrorMessage, FieldArray, FastField } from 'formik'
import * as Yup from 'yup'
import {
  CButton,
  CLabel,
  CTabs,
  CTabContent,
  CNav,
  CNavItem,
  CNavLink,
  CTabPane,
  CTextarea,
  CCard,
  CCardBody,
} from '@coreui/react'
import { Typeahead } from 'react-bootstrap-typeahead'
import Select from '@material-ui/core/Select'
import deepEqual from 'deep-equal'
import { v4 as uuidv4 } from 'uuid'

import * as actionTypes from '../../store/action-types'
import { TRootState } from '../../store/reducers'
import {
  BasicFormField,
  FormActionDeleteButton,
  FormActionSaveButton,
  AttachmentsUploadPanel,
  ConnectedOffersTable,
  ConnectedOrdersTable,
  IConnectedOrdersTableItem,
  ConnectedBomsTable,
  ThreeDots,
  getUploadedFileType,
  CustomErrorMessage,
  FormActionCancelButton,
} from '../../components'
import {
  inputLabelSpacingBottom,
  inputFieldSpacingBottom,
  convertNumericStringToNumber,
  successMessageDuration,
  maximumDescriptionLength,
  getErrorMessageFromStatus,
  preventNavigationChange,
} from '../../utils'
import { TFormTab, topLabelsOffsetFromNavigationTabs } from '../admin'

export const bomElementNavTabs: TFormTab[] = [
  { name: 'general-information', title: 'Informacje ogólne', isEditOnly: false },
  { name: 'additional-information', title: 'Informacje dodatkowe', isEditOnly: false },
  { name: 'offers', title: 'Oferty', isEditOnly: true },
  { name: 'orders', title: 'Zamówienia', isEditOnly: true },
  { name: 'attachments', title: 'Załączniki', isEditOnly: false },
]

export const BomElementDetails: React.FC = () => {
  const dispatch = useDispatch()
  const refProducerTypeahead: RefObject<any> = useRef()
  const history = useHistory()
  const { tenant, mode, id, tab } = useParams<{
    tenant: string
    mode: actionTypes.TFormMode
    id: string
    tab: string
  }>()
  const paramsBomElementId = id
  const paramsBomElementTab = tab

  const [didFormValidationOccur, setDidFormValidationOccur] = useState(false)
  const [isBomElementModifiedAndUnsaved, setIsBomElementModifiedAndUnsaved] =
    useState<boolean>(false)
  const [selectedProducer, setSelectedProducer] = useState(undefined)

  const authState = useSelector((state: TRootState) => state.auth)
  const bomElementsState = useSelector((state: TRootState) => state.bomElements)
  const producersState = useSelector((state: TRootState) => state.producers)
  const suppliersState = useSelector((state: TRootState) => state.suppliers)

  const isThereNetworkError =
    bomElementsState?.bomElementCreateError?.status ||
    bomElementsState?.bomElementEditSaveError?.status ||
    bomElementsState?.bomElementDeleteError?.status ||
    bomElementsState?.singleBomElementFetchError?.status

  const isBomElementEditable =
    authState?.authData?.roles?.includes('BomElements_write') &&
    !bomElementsState?.singleBomElementFetchError

  const initialFileInput: any = undefined

  const closeBomElementDetails = useCallback(() => {
    history.push(`/${tenant}/admin/bom-elements`)
  }, [dispatch, history, tenant])

  // Fetch data for Element edit and clean store for Element create
  useEffect(() => {
    if (tenant && authState.authData) {
      if (paramsBomElementId && mode === 'edit') {
        dispatch({
          type: actionTypes.FETCH_SINGLE_BOM_ELEMENT_REQUESTED,
          payload: { tenant: tenant, token: authState.authData?.token, id: paramsBomElementId },
        })
      } else if (mode === 'create') {
        dispatch({
          type: actionTypes.SET_EDITED_BOM_ELEMENT,
          bomElement: null,
        })
        dispatch({
          type: actionTypes.BOM_ELEMENT_ACTIONS_UNLOCK,
        })
      }
    }
  }, [dispatch, tenant, authState, paramsBomElementId, mode])

  // Fix the path in edit
  useEffect(() => {
    if (tenant && authState.authData) {
      const tabsArray = bomElementNavTabs?.map((navTab: TFormTab) =>
        mode === 'edit' ? navTab.name : !navTab.isEditOnly ? navTab.name : undefined
      )

      if (paramsBomElementTab === undefined || !tabsArray.includes(paramsBomElementTab)) {
        history.replace(
          `/${tenant}/admin/bom-elements/${
            mode === 'create' ? 'create' : `edit/${paramsBomElementId}`
          }/${bomElementNavTabs[0].name}`
        )
      }
    }
  }, [tenant, authState, paramsBomElementId, history, paramsBomElementTab, mode])

  // Fetch additional element information only in edit mode
  useEffect(() => {
    if (tenant && authState.isAuthenticated && authState.authData && paramsBomElementId) {
      dispatch({
        type: actionTypes.FETCH_BOM_ELEMENT_BOMS_REQUESTED,
        payload: { tenant: tenant, token: authState.authData?.token, id: paramsBomElementId },
      })

      dispatch({
        type: actionTypes.FETCH_BOM_ELEMENT_OFFERS_REQUESTED,
        payload: { tenant: tenant, token: authState.authData?.token, id: paramsBomElementId },
      })

      dispatch({
        type: actionTypes.FETCH_BOM_ELEMENT_ATTACHMENTS_REQUESTED,
        payload: {
          tenant: tenant,
          token: authState.authData?.token,
          entityId: Number(paramsBomElementId),
          fileAttachmentTypeId: 1,
        },
      })
    }
  }, [dispatch, tenant, authState.isAuthenticated, authState.authData, paramsBomElementId])

  // Close the view on a successful element creation
  useEffect(() => {
    if (
      (!bomElementsState.isBomElementCreating &&
        !bomElementsState.bomElementCreateError &&
        bomElementsState.isBomElementCreated) ||
      (!bomElementsState.isBomElementDeleting &&
        !bomElementsState.bomElementDeleteError &&
        bomElementsState.isBomElementDeleted)
    ) {
      setTimeout(() => {
        closeBomElementDetails()
      }, successMessageDuration)
    }
  }, [
    bomElementsState.isBomElementCreated,
    bomElementsState.isBomElementDeleted,
    closeBomElementDetails,
  ])

  // Track the changes again
  useEffect(() => {
    if (
      bomElementsState.isBomElementEditSaved ||
      bomElementsState.isBomElementCreated ||
      bomElementsState.isBomElementDeleted
    ) {
      setIsBomElementModifiedAndUnsaved(false)
    }
  }, [
    bomElementsState.isBomElementEditSaved,
    bomElementsState.isBomElementCreated,
    bomElementsState.isBomElementDeleted,
  ])

  // Prevent navigation back and forth plus reload if modified
  useEffect(() => {
    preventNavigationChange(
      history,
      isBomElementModifiedAndUnsaved,
      isBomElementEditable,
      'bom-elements',
      paramsBomElementId,
      mode
    )

    // Without pathname in location there is no tab change detection
  }, [location.pathname, history, isBomElementModifiedAndUnsaved, isBomElementEditable])

  // Unmount Component
  useEffect(() => {
    return () => {
      dispatch({
        type: actionTypes.SET_EDITED_BOM_ELEMENT,
        bomElement: null,
      })

      dispatch({ type: actionTypes.BOM_ELEMENT_ACTIONS_UNLOCK })

      dispatch({
        type: actionTypes.CLEAR_SINGLE_ATTACHMENT_ERRORS,
      })
    }
  }, [])

  const BomElementSchema = Yup.object().shape(
    {
      name: Yup.string().required('To pole jest wymagane!'),
      producerId: Yup.number().nullable().required('To pole jest wymagane!'),
      mpn: Yup.string().when('symbolDin', {
        is: (symbolDin) => !symbolDin || !symbolDin.length,
        then: Yup.string().required('Wymagany MPN lub DIN!'),
        otherwise: Yup.string().nullable(),
      }),
      symbolDin: Yup.string().when('mpn', {
        is: (mpn) => !mpn || !mpn.length,
        then: Yup.string().required('Wymagany DIN lub MPN!'),
        otherwise: Yup.string().nullable(),
      }),
    },
    [['mpn', 'symbolDin']]
  )

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center">
      <>
        <CCard>
          <CCardBody className="bom-element-details">
            {bomElementsState.isSingleBomElementLoading ? (
              <>
                <h4 className={`text-center ${isThereNetworkError ? 'mb-2 pb-2' : 'mb-3 pb-2'}`}>
                  Element
                  <ThreeDots />
                </h4>
                <div
                  style={{
                    height: '360px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Spinner />
                </div>
              </>
            ) : (
              <Formik
                enableReinitialize={false}
                initialValues={{
                  name: bomElementsState.editedBomElement?.name || '',

                  producerName: bomElementsState.editedBomElement?.producerId
                    ? bomElementsState.editedBomElement?.producerName
                    : '',
                  producerId: bomElementsState.editedBomElement?.producerId || null,
                  mpn: bomElementsState.editedBomElement?.mpn || '',
                  symbolDin: bomElementsState.editedBomElement?.symbolDin || '',
                  // bomElementCategoryOtherValue in category means Pozostałe
                  category:
                    bomElementsState.editedBomElement?.category ||
                    actionTypes.bomElementCategoryOtherValue,
                  type: bomElementsState.editedBomElement?.type || 0,

                  description: bomElementsState.editedBomElement?.description || '',
                  measurementType: bomElementsState.editedBomElement?.measurementType || 1,
                  centimetersInPiece: bomElementsState.editedBomElement?.centimetersInPiece || '',
                  supplierInfos: bomElementsState.editedBomElement?.supplierInfos || [],
                  replacementBomElementId:
                    bomElementsState.editedBomElement?.replacementBomElementId || 'placeholder',

                  newAttachmentFileInput: initialFileInput,
                  newAttachmentAltName: '',
                  uuid: mode === 'create' ? uuidv4() : bomElementsState.editedBomElement?.uuid,
                }}
                validationSchema={BomElementSchema}
                onSubmit={(values) => {
                  if (isBomElementEditable) {
                    setDidFormValidationOccur(true)

                    const shortenedSuppliers = values.supplierInfos?.map(
                      (supplierInfo: actionTypes.TSupplier) => ({
                        id: supplierInfo.id,
                      })
                    )

                    dispatch({
                      type:
                        mode === 'create'
                          ? actionTypes.CREATE_BOM_ELEMENT_REQUESTED
                          : actionTypes.EDIT_BOM_ELEMENT_REQUESTED,
                      payload: {
                        tenant: tenant,
                        token: authState.authData?.token,
                        bomElement: {
                          ...values,
                          type: Number(values.type),
                          category: Number(values.category),
                          supplierInfos: shortenedSuppliers,
                          replacementBomElementId: Number(values.replacementBomElementId),
                          centimetersInPiece:
                            convertNumericStringToNumber(values.centimetersInPiece) || null,
                          measurementType: Number(values.measurementType),

                          ...(bomElementsState.editedBomElement?.id && {
                            id: bomElementsState.editedBomElement?.id,
                          }),
                        },
                      },
                    })
                  }
                }}
                validateOnBlur={false}
                validateOnChange={didFormValidationOccur}
              >
                {({ initialValues, values, errors, setFieldValue, handleChange }) => (
                  <Form
                    onChange={() => {
                      // Values here are always 1 step behind
                      let isModified = !deepEqual(values, initialValues, { strict: false })

                      if (isModified) {
                        /* If form is brought to its initial state then it is not modified */
                        setIsBomElementModifiedAndUnsaved(true)
                      } else {
                        setIsBomElementModifiedAndUnsaved(false)
                      }

                      if (errors.name || errors.mpn || errors.producerId) {
                        setDidFormValidationOccur(true)
                      }
                    }}
                  >
                    <h4
                      className={`text-center ${isThereNetworkError ? 'mb-2 pb-2' : 'mb-3 pb-2'}`}
                    >
                      {mode === 'edit'
                        ? `${
                            bomElementsState?.editedBomElement?.name ||
                            bomElementsState?.bomElements?.find(
                              (bomElement: actionTypes.TLightBomElement) =>
                                bomElement.id === Number(paramsBomElementId)
                            )?.name ||
                            'Nieznany Element'
                          }`
                        : 'Nowy Element'}
                    </h4>

                    {/*
                     * Display Network Error Message
                     */}

                    {bomElementsState?.bomElementCreateError && (
                      <CustomErrorMessage
                        wrapperClassNames="mb-4"
                        customErrorMessageText={getErrorMessageFromStatus(
                          'create',
                          bomElementsState?.bomElementCreateError?.status
                        )}
                      />
                    )}

                    {bomElementsState?.singleBomElementFetchError && (
                      <CustomErrorMessage
                        wrapperClassNames="mb-4"
                        customErrorMessageText={getErrorMessageFromStatus(
                          'fetch',
                          bomElementsState?.singleBomElementFetchError?.status,
                          'elementu'
                        )}
                      />
                    )}

                    {bomElementsState?.bomElementEditSaveError && (
                      <CustomErrorMessage
                        wrapperClassNames="mb-4"
                        customErrorMessageText={getErrorMessageFromStatus(
                          'edit',
                          bomElementsState?.bomElementEditSaveError?.status,
                          'elementu'
                        )}
                      />
                    )}

                    {bomElementsState?.bomElementDeleteError && (
                      <CustomErrorMessage
                        wrapperClassNames="mb-4"
                        customErrorMessageText={getErrorMessageFromStatus(
                          'delete',
                          bomElementsState?.bomElementDeleteError?.status,
                          'elementu'
                        )}
                      />
                    )}

                    {/*
                     * Form Tabs
                     */}

                    <CTabs activeTab={paramsBomElementTab}>
                      <CNav variant="tabs" className="mb-4">
                        {bomElementNavTabs?.map((modalTab: TFormTab) => {
                          if (modalTab.isEditOnly && mode === 'create') {
                            return null
                          } else {
                            return (
                              <CNavItem key={modalTab.name}>
                                <CNavLink
                                  className={`${
                                    modalTab.name === 'general-information' &&
                                    (errors.name || errors.producerId || errors.mpn)
                                      ? 'text-danger is-invalid'
                                      : ''
                                  }`}
                                  data-tab={modalTab.name}
                                  onClick={() => {
                                    history.replace(
                                      `/${tenant}/admin/bom-elements/${
                                        mode === 'edit' ? `edit/${paramsBomElementId}` : 'create'
                                      }/${modalTab.name}`
                                    )
                                  }}
                                >
                                  {modalTab.title}
                                </CNavLink>
                              </CNavItem>
                            )
                          }
                        })}
                      </CNav>
                      <CTabContent>
                        {/*
                         * General Information Tab
                         */}

                        <CTabPane
                          data-tab="general-information"
                          style={{ marginTop: topLabelsOffsetFromNavigationTabs }}
                        >
                          <div className="bom-element-grid-fields mb-1">
                            <BasicFormField
                              fieldId="bom-element-name"
                              fieldLabel="Nazwa"
                              fieldIcon="cil-short-text"
                              formikFieldName="name"
                              fieldValue={values.name}
                              fieldError={errors.name}
                              fieldType="text"
                              placeholder="Wprowadź nazwę elementu"
                            />

                            <div>
                              <CLabel
                                htmlFor="bom-element-producer-id"
                                className={`${inputLabelSpacingBottom}`}
                              >
                                Producent
                              </CLabel>
                              <InputGroup
                                id="bom-element-producer-id"
                                className={`${
                                  values.producerId === -1 &&
                                  !producersState.producers.some(
                                    (producer: actionTypes.TProducer) =>
                                      producer.name
                                        .toLowerCase()
                                        .includes(values!.producerName!.toLowerCase())
                                  ) &&
                                  'new-producer-color-field-highlight'
                                } ${inputFieldSpacingBottom} ${errors.producerId && 'is-invalid'}`}
                              >
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText
                                    className={errors.producerId && 'text-danger input-error-icon'}
                                  >
                                    <i className="cil-factory"></i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <ErrorMessage
                                  name="producerId"
                                  component="span"
                                  className="text-danger input-error-message"
                                />
                                <Typeahead
                                  placeholder="Wybierz z listy lub wprowadź nazwę nowego producenta"
                                  isInvalid={Boolean(errors?.producerId)}
                                  id="element-producer-typeahead"
                                  onChange={(selected: actionTypes.TProducer[]) => {
                                    if (selected.length > 0) {
                                      setSelectedProducer(selectedProducer)
                                      setFieldValue('producerId', selected[0].id)
                                      setFieldValue('producerName', selected[0].name)

                                      // This has to be reapeated for the error to disappear
                                      setFieldValue('producerId', selected[0].id)

                                      setIsBomElementModifiedAndUnsaved(true)
                                    }
                                  }}
                                  selected={selectedProducer}
                                  onInputChange={(text) => {
                                    if (text.length > 0) {
                                      setFieldValue('producerName', text)

                                      const matchedProducer: actionTypes.TProducer =
                                        producersState.producers.find(
                                          (producer: actionTypes.TProducer) =>
                                            producer.name.toLowerCase() === text.toLowerCase()
                                        )

                                      if (matchedProducer) {
                                        setFieldValue('producerId', matchedProducer.id)

                                        refProducerTypeahead.current.hideMenu()

                                        // This has to be repeated for the error to disappear
                                        setFieldValue('producerId', matchedProducer.id)

                                        setIsBomElementModifiedAndUnsaved(true)
                                      } else {
                                        setFieldValue('producerId', -1)

                                        setSelectedProducer(undefined)
                                      }
                                    } else {
                                      setFieldValue('producerId', null)
                                      setSelectedProducer(undefined)
                                    }
                                  }}
                                  defaultInputValue={values?.producerName || ''}
                                  options={producersState.producers}
                                  labelKey="name"
                                  emptyLabel={
                                    values.producerId === -1
                                      ? `W słowniku utworzy się wpisany producent`
                                      : 'Nie znaleziono producentów'
                                  }
                                  ref={refProducerTypeahead}
                                />
                              </InputGroup>
                            </div>
                          </div>

                          <div className="bom-element-grid-fields">
                            <BasicFormField
                              fieldId="bom-element-producer-code"
                              fieldLabel="MPN"
                              fieldIcon="cil-barcode"
                              formikFieldName="mpn"
                              fieldValue={values.mpn}
                              fieldError={errors.mpn}
                              fieldType="text"
                              placeholder="Wprowadź MPN"
                              isShortLengthInput
                            />

                            <BasicFormField
                              fieldId="bom-element-symbol-din"
                              fieldLabel="Symbol / DIN"
                              fieldIcon="cil-tag"
                              formikFieldName="symbolDin"
                              fieldValue={values.symbolDin}
                              fieldError={errors.symbolDin}
                              fieldType="text"
                              placeholder="Wprowadź symbol lub DIN"
                              isShortLengthInput
                            />
                          </div>

                          <div className="bom-element-grid-fields">
                            <div>
                              <CLabel
                                htmlFor="bom-element-category"
                                className={`${inputLabelSpacingBottom}`}
                              >
                                Kategoria w BOM
                              </CLabel>
                              <InputGroup
                                id="bom-element-category"
                                className={`${inputFieldSpacingBottom}`}
                              >
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText
                                    className={errors.category && 'text-danger input-error-icon'}
                                  >
                                    <i className="cil-storage"></i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <ErrorMessage
                                  name="category"
                                  component="span"
                                  className="text-danger input-error-message"
                                />
                                <Field
                                  as={Select}
                                  name="category"
                                  variant="outlined"
                                  native
                                  className={`item-selector element-selector w-100 ${
                                    errors.type && 'form-control is-invalid'
                                  }`}
                                  onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                                    if (document && document.activeElement) {
                                      const elem: any = document.activeElement
                                      elem.blur()
                                    }

                                    handleChange(event)
                                  }}
                                >
                                  {Object.keys(actionTypes.OBomElementCategory)?.map(
                                    (categoryValue: actionTypes.TBomElementCategoryValue) => (
                                      <option
                                        key={`bom-element-category-option-${categoryValue}`}
                                        value={Number(categoryValue)}
                                      >
                                        {actionTypes.OBomElementCategory[Number(categoryValue)]}
                                      </option>
                                    )
                                  )}
                                </Field>
                              </InputGroup>
                            </div>

                            <div>
                              <CLabel
                                htmlFor="bom-element-type"
                                className={`${inputLabelSpacingBottom}`}
                              >
                                Rodzaj w Magazynie
                              </CLabel>
                              <InputGroup
                                id="bom-element-type"
                                className={`${inputFieldSpacingBottom}`}
                              >
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText
                                    className={errors.type && 'text-danger input-error-icon'}
                                  >
                                    <i className="cil-storage"></i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <ErrorMessage
                                  name="type"
                                  component="span"
                                  className="text-danger input-error-message"
                                />
                                <Field
                                  as={Select}
                                  name="type"
                                  variant="outlined"
                                  native
                                  className={`item-selector element-selector w-100 ${
                                    errors.type && 'form-control is-invalid'
                                  }`}
                                  onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                                    if (document && document.activeElement) {
                                      const elem: any = document.activeElement
                                      elem.blur()
                                    }

                                    handleChange(event)
                                  }}
                                >
                                  <option value={1}>Materiał</option>
                                  <option value={2}>Towar</option>
                                </Field>
                              </InputGroup>
                            </div>
                          </div>
                        </CTabPane>

                        {/*
                         * Additional Information Tab
                         */}

                        <CTabPane
                          data-tab="additional-information"
                          style={{ marginTop: topLabelsOffsetFromNavigationTabs }}
                        >
                          <CLabel
                            htmlFor="bom-element-description"
                            className={inputLabelSpacingBottom}
                          >
                            Opis elementu
                          </CLabel>
                          <InputGroup
                            id="bom-element-description"
                            className={inputFieldSpacingBottom}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText
                                className={errors.description && 'text-danger input-error-icon'}
                              >
                                <i className="cil-layers"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <ErrorMessage
                              name="description"
                              component="span"
                              className="text-danger input-error-message"
                            />
                            <Field
                              as={CTextarea}
                              name="description"
                              maxLength={maximumDescriptionLength}
                              placeholder="Wprowadź opis elementu..."
                              className={`item-description ${
                                errors.description && 'form-control is-invalid'
                              }`}
                            />
                          </InputGroup>

                          <div className="bom-element-grid-fields bom-element-grid-fields--measurement">
                            <div>
                              <CLabel
                                htmlFor="bom-element-measurement-type"
                                className={inputLabelSpacingBottom}
                              >
                                Jednostka
                              </CLabel>
                              <InputGroup
                                id="bom-element-measurement-type"
                                className={`${inputFieldSpacingBottom} dropdown-selector`}
                              >
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="cil-ethernet"></i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <ErrorMessage
                                  name="measurementType"
                                  component="span"
                                  className="text-danger input-error-message"
                                />
                                <FastField
                                  as={Select}
                                  name="measurementType"
                                  variant="outlined"
                                  native
                                  value={values.measurementType}
                                  className="item-selector  supplier-selector w-100"
                                >
                                  <option value={1}>szt.</option>
                                  <option value={2}>rolka</option>
                                </FastField>
                              </InputGroup>
                            </div>

                            <BasicFormField
                              fieldId="bom-element-cm-in-piece"
                              fieldLabel="Długość rolki [cm]"
                              fieldIcon="cil-contrast"
                              formikFieldName="centimetersInPiece"
                              fieldValue={values.centimetersInPiece}
                              fieldError={errors.centimetersInPiece}
                              fieldType="number"
                              placeholder="Wprowadź ilość cm na rolce"
                              amountMeasurementType={2}
                            />

                            <div>
                              <CLabel
                                htmlFor="bom-element-replacement"
                                className={`${inputLabelSpacingBottom}`}
                              >
                                Zamiennik
                              </CLabel>
                              <InputGroup
                                id="bom-element-replacement"
                                className={`${inputFieldSpacingBottom}`}
                              >
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText
                                    className={
                                      errors.replacementBomElementId &&
                                      'text-danger input-error-icon'
                                    }
                                  >
                                    <i className="cil-memory"></i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <ErrorMessage
                                  name="replacementBomElementId"
                                  component="span"
                                  className="text-danger input-error-message"
                                />
                                <Field
                                  as={Typeahead}
                                  placeholder="Wybierz z listy lub wpisz nazwę zamiennika elementu"
                                  isInvalid={false}
                                  id="replacementBomElementId"
                                  onChange={(
                                    selectedBomElementsArray: actionTypes.TBomElement[]
                                  ) => {
                                    const selectedElement = selectedBomElementsArray[0]

                                    if (selectedElement) {
                                      setFieldValue(
                                        `replacementBomElementId`,
                                        selectedElement.id,
                                        false
                                      )
                                    }
                                  }}
                                  onInputChange={(text: string) => {
                                    if (text.length < 1) {
                                      setFieldValue(`replacementBomElementId`, null, false)
                                    }
                                  }}
                                  defaultInputValue={
                                    bomElementsState?.bomElements?.find(
                                      (bomElement: actionTypes.TBomElement) =>
                                        bomElement.id === values.replacementBomElementId
                                    )?.name
                                  }
                                  // Here we traverse the array of TBomElement objects
                                  options={bomElementsState.bomElements
                                    ?.filter(
                                      (bomElement: actionTypes.TBomElement) =>
                                        bomElement.id !== values.replacementBomElementId
                                    )
                                    ?.map((bomElement: actionTypes.TBomElement) => ({
                                      ...bomElement,
                                      name: `${bomElement.name} | MPN: ${
                                        bomElement?.mpn || 'brak'
                                      } | DIN: ${bomElement?.symbolDin || 'brak'}`,
                                    }))}
                                  labelKey="name"
                                  emptyLabel={
                                    values.replacementBomElementId
                                      ? `Wybrany element: ${
                                          bomElementsState?.bomElements?.find(
                                            (bomElement: actionTypes.TBomElement) =>
                                              bomElement.id === values.replacementBomElementId
                                          )?.name || ''
                                        }`
                                      : 'Nie znaleziono elementów'
                                  }
                                />
                              </InputGroup>
                            </div>
                          </div>

                          <FieldArray
                            name="supplierInfos"
                            render={(arrayHelpers) => (
                              <div>
                                <CLabel
                                  htmlFor="bom-element-supplier-selector"
                                  className={`${inputLabelSpacingBottom}`}
                                >
                                  Dostawcy
                                </CLabel>
                                <InputGroup
                                  id="bom-element-supplier-selector"
                                  className={`${inputFieldSpacingBottom} flex-nowrap`}
                                >
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText
                                      className={
                                        errors.supplierInfos && 'text-danger input-error-icon'
                                      }
                                    >
                                      <i className="cil-address-book"></i>
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <ErrorMessage
                                    name="supplierSelector"
                                    component="span"
                                    className="text-danger input-error-message"
                                  />
                                  <Field
                                    as={Select}
                                    name="supplierSelector"
                                    variant="outlined"
                                    native
                                    value="placeholder"
                                    className="item-selector  supplier-selector w-100"
                                    onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                                      if (document && document.activeElement) {
                                        const activeField: any = document.activeElement
                                        activeField.blur()
                                      }

                                      const matchedSupplier: any = suppliersState?.suppliers?.find(
                                        (supplier: actionTypes.TSupplier) =>
                                          Number(supplier.id) === Number(event.currentTarget.value)
                                      )

                                      matchedSupplier.bomElementUuid = values.uuid

                                      arrayHelpers.push(matchedSupplier)
                                    }}
                                  >
                                    <option value="placeholder" disabled hidden>
                                      {values?.supplierInfos?.length
                                        ? 'Wybierz dodatkowego dostawcę'
                                        : 'Wybierz dostawcę'}
                                    </option>
                                    {suppliersState?.suppliers?.length > 0 ? (
                                      suppliersState?.suppliers?.map(
                                        (supplier: actionTypes.TSupplier) => {
                                          if (
                                            !values.supplierInfos.find(
                                              (supplierInfo: actionTypes.TSupplier) =>
                                                supplierInfo.id === supplier.id
                                            )
                                          ) {
                                            return (
                                              <option key={supplier.id} value={supplier.id}>
                                                {supplier.name}
                                              </option>
                                            )
                                          } else {
                                            return null
                                          }
                                        }
                                      )
                                    ) : (
                                      <option value="null" disabled>
                                        Brak dostawców do wyboru!
                                      </option>
                                    )}
                                    )
                                  </Field>
                                </InputGroup>
                                {values?.supplierInfos?.length > 0 && (
                                  <Table bordered striped className="item-inner-details-table mb-3">
                                    <thead>
                                      <tr>
                                        <th style={{ width: '45px', textAlign: 'center' }}>#</th>
                                        <th style={{ width: '70%' }}>Dostawca</th>
                                        <th style={{ width: '30%' }}>Kraj</th>
                                        <th style={{ width: '50px' }}></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {values?.supplierInfos?.map(
                                        (supplier: actionTypes.TSupplier, indexOfSupplier) => (
                                          <tr key={`supplier-${supplier.name}-${supplier.id}`}>
                                            <td style={{ textAlign: 'center' }}>
                                              {indexOfSupplier + 1}.
                                            </td>
                                            <td>{supplier.name}</td>
                                            <td>{supplier.country}</td>
                                            <td>
                                              <CButton
                                                color="danger"
                                                type="button"
                                                variant="outline"
                                                onClick={() => arrayHelpers.remove(indexOfSupplier)}
                                                className="list-action-button"
                                              >
                                                <i className="cil-trash"></i>
                                              </CButton>
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </Table>
                                )}
                              </div>
                            )}
                          />

                          {mode === 'edit' && (
                            <ConnectedBomsTable
                              mode="bom-element"
                              editedItemBoms={bomElementsState.editedBomElementBoms}
                              fetchItemBomsError={bomElementsState.fetchBomElementBomsError}
                              tenant={tenant}
                            />
                          )}
                        </CTabPane>

                        {/*
                         * Offers Tab
                         */}

                        <CTabPane data-tab="offers">
                          <ConnectedOffersTable
                            mode="bom-element"
                            areItemOffersLoading={bomElementsState.areBomElementOffersLoading}
                            editedItemOffers={bomElementsState.editedBomElementOffers}
                            fetchItemOffersError={bomElementsState.fetchBomElementOffersError}
                            tenant={tenant}
                          />
                        </CTabPane>

                        {/*
                         * Orders Tab
                         */}

                        <CTabPane data-tab="orders">
                          <ConnectedOrdersTable
                            mode="bom-element"
                            areItemOrdersLoading={bomElementsState.areBomElementOrdersLoading}
                            editedItemOrders={
                              (bomElementsState?.editedBomElement
                                ?.orders as IConnectedOrdersTableItem[]) || null
                            }
                            fetchItemOrdersError={bomElementsState.fetchBomElementOrdersError}
                            tenant={tenant}
                          />
                        </CTabPane>

                        {/*
                         * Attachments Tab
                         */}

                        <CTabPane
                          data-tab="attachments"
                          style={{ marginTop: topLabelsOffsetFromNavigationTabs }}
                        >
                          <AttachmentsUploadPanel
                            attachments={bomElementsState.editedBomElementAttachments}
                            fetchAttachmentsError={bomElementsState.fetchBomElementAttachmentsError}
                            isAttachmentUploading={bomElementsState.isBomElementAttachmentUploading}
                            isAttachmentUploaded={bomElementsState.isBomElementAttachmentUploaded}
                            attachmentUploadError={
                              bomElementsState.bomElementAttachmentUploadingError
                            }
                            newAttachmentFileInput={values.newAttachmentFileInput}
                            newAttachmentAltNameError={errors.newAttachmentAltName}
                            dispatchAttachmentUpload={(encodedFile) => {
                              dispatch({
                                type: actionTypes.UPLOAD_BOM_ELEMENT_ATTACHMENT_REQUESTED,
                                payload: {
                                  tenant: tenant,
                                  token: authState.authData?.token,
                                  uploadAttachment: {
                                    fileAttachmentTypeId: 1,
                                    entityUuid: values.uuid,
                                    entityId: paramsBomElementId
                                      ? Number(paramsBomElementId)
                                      : null,

                                    type: getUploadedFileType(
                                      values.newAttachmentFileInput?.type,
                                      values.newAttachmentFileInput?.name
                                    ),
                                    name: values.newAttachmentFileInput?.name,
                                    base64EncodedFile: encodedFile,
                                    altName: values?.newAttachmentAltName || null,
                                  },
                                },
                              })

                              mode === 'create' && setIsBomElementModifiedAndUnsaved(true)
                            }}
                            dispatchModalActionsUnlock={() =>
                              dispatch({ type: actionTypes.BOM_ELEMENT_ACTIONS_UNLOCK })
                            }
                            dispatchSingleAttachmentFetch={(
                              attachmentId,
                              attachmentType,
                              fileName,
                              download
                            ) =>
                              dispatch({
                                type: actionTypes.FETCH_SINGLE_ATTACHMENT_REQUESTED,
                                payload: {
                                  tenant: tenant,
                                  token: authState.authData?.token,
                                  id: attachmentId,
                                  attachmentType: attachmentType,
                                  fileName: fileName,
                                  download: download,
                                },
                              })
                            }
                            dispatchSingleAttachmentDelete={(attachmentId) =>
                              dispatch({
                                type: actionTypes.DELETE_SINGLE_ATTACHMENT_REQUESTED,
                                payload: {
                                  tenant: tenant,
                                  token: authState.authData?.token,
                                  id: attachmentId,
                                },
                                section: 'bomElements',
                              })
                            }
                            isEditable={isBomElementEditable}
                            isUploadDisabled={
                              bomElementsState.isSingleBomElementLoading ||
                              bomElementsState.isBomElementEditSaving ||
                              bomElementsState.isBomElementDeleting ||
                              bomElementsState.isBomElementCreating ||
                              bomElementsState.isBomElementEditSaved ||
                              bomElementsState.isBomElementCreated ||
                              bomElementsState.isBomElementDeleted ||
                              bomElementsState.isBomElementAttachmentUploading ||
                              !values.newAttachmentFileInput
                            }
                            setFieldValue={setFieldValue}
                            noAttachmentsTextFirstWords="Ten element"
                          />
                        </CTabPane>
                      </CTabContent>
                    </CTabs>

                    {/*
                     * Do not replace this with FormActionsPanel
                     */}

                    <div
                      className={`pt-3 pb-1 d-flex ${
                        mode === 'edit' && isBomElementEditable
                          ? 'justify-content-between'
                          : 'justify-content-end'
                      }`}
                    >
                      {mode === 'edit' && isBomElementEditable && (
                        <FormActionDeleteButton
                          isSaving={bomElementsState.isBomElementEditSaving}
                          isSaved={bomElementsState.isBomElementEditSaved}
                          isDeleting={bomElementsState.isBomElementDeleting}
                          isDeleted={bomElementsState.isBomElementDeleted}
                          deletePayload={{
                            tenant: tenant,
                            token: authState.authData?.token,
                            id: bomElementsState.editedBomElement?.id,
                          }}
                          deleteAction={actionTypes.DELETE_BOM_ELEMENT_REQUESTED}
                          canDelete={bomElementsState.editedBomElement?.canDelete}
                          disabledDeleteButtonClassNames="delete-details-button"
                          confirmDeleteMessageJSX={
                            <>
                              Czy na pewno chcesz usunąć cały element?
                              {initialValues?.name ? (
                                <>
                                  <br />
                                  <strong>{initialValues?.name}</strong>
                                </>
                              ) : (
                                ''
                              )}
                            </>
                          }
                        />
                      )}
                      <div>
                        <FormActionCancelButton
                          closeFunction={closeBomElementDetails}
                          closeAction={''}
                        />

                        {isBomElementEditable && (
                          <FormActionSaveButton
                            mode={mode}
                            isSaving={
                              mode === 'create'
                                ? bomElementsState.isBomElementCreating
                                : bomElementsState.isBomElementEditSaving
                            }
                            isSaved={
                              mode === 'create'
                                ? bomElementsState.isBomElementCreated
                                : bomElementsState.isBomElementEditSaved
                            }
                            isDeleting={bomElementsState.isBomElementDeleting}
                            isDeleted={bomElementsState.isBomElementDeleted}
                            formErrorsBool={Boolean(errors.name || errors.mpn || errors.producerId)}
                            didFormValidationOccur={didFormValidationOccur}
                            setDidFormValidationOccur={setDidFormValidationOccur}
                          />
                        )}
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </CCardBody>
        </CCard>
      </>
    </Container>
  )
}
