import React from 'react'
import { CFooter } from '@coreui/react'

export const Footer: React.FC<{}> = () => {
  return (
    <CFooter fixed={false}>
      <div className="mfs-auto">
        <a href="/client" target="_blank" rel="noopener noreferrer">
          Polyend System™ &nbsp;{new Date().getFullYear()}
        </a>
      </div>
    </CFooter>
  )
}
