import React, { useState, useEffect, useCallback, ChangeEvent, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { Container, InputGroup, InputGroupAddon, InputGroupText, Spinner } from 'reactstrap'
import { Formik, Form, ErrorMessage, Field, FastField } from 'formik'
import * as Yup from 'yup'
import { CCard, CCardBody, CButton, CDataTable, CLabel } from '@coreui/react'
import { Select, SelectProps } from '@material-ui/core'
import NumberFormat from 'react-number-format'
import { confirmAlert } from 'react-confirm-alert'

import * as actionTypes from '../../../store/action-types'
import { TRootState } from '../../../store/reducers'
import {
  BasicFormField,
  CustomErrorMessage,
  ThreeDots,
  FormActionCancelButton,
  ConfirmActionModal,
} from '../../../components'
import {
  getErrorMessageFromStatus,
  globalThousandSeparator,
  globalDecimalSeparator,
  inputLabelSpacingBottom,
  inputFieldSpacingBottom,
  toDateInputValue,
  convertNumericStringToNumber,
  successMessageDuration,
  preventNavigationChange,
  convertNumbersInTableRows,
} from '../../../utils'
import {
  finishedProductsTypeNumber,
  materialsTypeNumber,
  waresTypeNumber,
} from '../../../store/action-types'
import { lengthOfWarehouseNameInConfirmModal } from './warehouse-management'

export const MoveReplenishment: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tenant, id } = useParams<{
    tenant: string
    id: string
  }>()
  const paramsManagedWarehouseId = id

  const [didFormValidationOccur, setDidFormValidationOccur] = useState(false)
  const [isMoveReplenishmentModifiedAndUnsaved, setIsMoveReplenishmentModifiedAndUnsaved] =
    useState(false)

  const authState = useSelector((state: TRootState) => state.auth)
  const warehousesState = useSelector((state: TRootState) => state.warehouses)

  const [moveReplenishmentFilterState, setMoveReplenishmentFilterState] =
    useState<actionTypes.TFilterState>(undefined)
  const [moveReplenishmentSorterState, setMoveReplenishmentSorterState] =
    useState<actionTypes.TSorterState>({ column: '', asc: true })

  const closeMoveReplenishment = useCallback(() => {
    history.push(`/${tenant}/admin/warehouse-management/${paramsManagedWarehouseId}`)
  }, [dispatch, history, tenant, paramsManagedWarehouseId])

  const isWarehouseEditable = authState?.authData?.roles?.includes('Warehouses_write')

  // Fetch warehouse inventories if they were not fetched before
  useEffect(() => {
    if (
      location.pathname.includes('move-replenishment') &&
      tenant &&
      authState?.authData &&
      authState?.authData?.roles?.includes('Warehouses_read') &&
      isWarehouseEditable
    ) {
      dispatch({
        type: actionTypes.FETCH_INVENTORIES_OF_WAREHOUSES_REQUESTED,
        payload: { tenant: tenant, token: authState.authData?.token },
      })
    }
  }, [
    dispatch,
    tenant,
    authState.authData,
    authState.isAuthenticated,
    authState.isAuthenticationPending,
    warehousesState.isMoveReplenishmentDocumentGenerated,
  ])

  // Fetch warehouses if they were not fetched before
  useEffect(() => {
    if (
      tenant &&
      authState?.isAuthenticated &&
      authState?.authData &&
      authState?.authData?.roles?.includes('Warehouses_read')
    ) {
      dispatch({
        type: actionTypes.FETCH_WAREHOUSES_REQUESTED,
        payload: { tenant: tenant, token: authState.authData?.token },
      })
    }
  }, [
    dispatch,
    tenant,
    authState.authData,
    authState.isAuthenticated,
    authState.isAuthenticationPending,
  ])

  const managedWarehouseTypes: actionTypes.TWarehouseType[] | undefined = useMemo(
    () =>
      warehousesState?.warehouses?.find(
        (warehouse: actionTypes.TWarehouseLight) =>
          warehouse.id === Number(paramsManagedWarehouseId)
      )?.types,
    [warehousesState.warehouses, paramsManagedWarehouseId]
  )

  const [currentlyManagedFromTypeId, setCurrentlyManagedFromTypeId] = useState<number>(
    managedWarehouseTypes ? managedWarehouseTypes[0] : 1
  )

  const emptyWarehouseInventoryItemsInForm = []

  const generateInventoryFromSelectedFromWarehouseId = (warehouseId: number) => {
    const inventoryOfSelectedWarehouse = warehousesState?.inventoriesOfWarehouses?.filter(
      (inventoryItem: actionTypes.IInventoriesOfWarehousesDataItem) =>
        inventoryItem.warehouseId === warehouseId
    )

    if (inventoryOfSelectedWarehouse) {
      // This is needed to display the generated values in table properly
      setTimeout(() => convertNumbersInTableRows(['array'], [], [2]), 50)

      return inventoryOfSelectedWarehouse
        ?.map(
          (
            inventoryItem: actionTypes.IInventoriesOfWarehousesDataItem,
            inventoryItemIndex: number
          ) => ({
            bomElementId: inventoryItem.bomElementId,
            bomElementName: inventoryItem.bomElementName || '',
            type: inventoryItem.type,
            warehouseQuantity:
              // Never convert those values to string here with convertQuantityToNumericString. This destroys the table sorter.
              inventoryItem?.warehouseQuantity > 0 ? inventoryItem?.warehouseQuantity : 0,
            quantity: '',
            position: `${inventoryItemIndex + 1}.`,
            hasChanged: false,
          })
        )
        ?.filter((tableItem: actionTypes.TMoveReplenishmentTableItem) =>
          currentlyManagedFromTypeId ? tableItem.type === currentlyManagedFromTypeId : true
        )
    } else {
      return emptyWarehouseInventoryItemsInForm
    }
  }

  useEffect(() => {
    if (
      location.pathname.includes('move-replenishment') &&
      tenant &&
      authState?.authData &&
      authState?.authData?.roles?.includes('Warehouses_read') &&
      isWarehouseEditable &&
      paramsManagedWarehouseId
    ) {
      dispatch({
        type: actionTypes.SET_CURRENTLY_MANAGED_WAREHOUSE_ID,
        currentlyManagedWarehouseId: Number(paramsManagedWarehouseId),
      })

      dispatch({
        type: actionTypes.CLEAR_MOVE_REPLENISHMENT_ERROR,
      })
    }
  }, [
    dispatch,
    tenant,
    authState.authData,
    authState.isAuthenticated,
    authState.isAuthenticationPending,
    paramsManagedWarehouseId,
  ])

  // Prevent navigation back and forth plus reload if modified
  useEffect(() => {
    preventNavigationChange(
      history,
      isMoveReplenishmentModifiedAndUnsaved,
      isWarehouseEditable,
      'replenishment',
      paramsManagedWarehouseId,
      ''
    )

    // Without pathname in location there is no tab change detection
  }, [location.pathname, history, isMoveReplenishmentModifiedAndUnsaved, isWarehouseEditable])

  useEffect(() => {
    if (warehousesState.isMoveReplenishmentDocumentGenerated) {
      setIsMoveReplenishmentModifiedAndUnsaved(false)

      // Maybe this is not necessary but in case we want to see an updated warehouseQuantity in deliveries list
      if (
        tenant &&
        authState?.authData &&
        authState?.authData?.roles?.includes('Warehouses_read')
      ) {
        dispatch({
          type: actionTypes.FETCH_WAREHOUSE_MANAGEMENT_DELIVERIES_REQUESTED,
          payload: {
            tenant: tenant,
            token: authState.authData?.token,
            id: paramsManagedWarehouseId,
          },
        })
      }

      setTimeout(() => {
        closeMoveReplenishment()
      }, successMessageDuration)
    }
  }, [warehousesState.isMoveReplenishmentDocumentGenerated])

  // Convert numbers to numeric strings in table
  useEffect(() => {
    convertNumbersInTableRows(['array'], [], [2])
  }, [
    paramsManagedWarehouseId,
    currentlyManagedFromTypeId,
    moveReplenishmentSorterState,
    moveReplenishmentFilterState,
  ])

  // Unmount Component
  useEffect(() => {
    return () => {
      dispatch({
        type: actionTypes.CLOSE_MOVE_REPLENISHMENT,
      })
    }
  }, [])

  const MoveReplenishmentSchema = Yup.object().shape({
    referenceNumber: Yup.string().required('To pole jest wymagane!'),
    fromType: Yup.number().required('Wybierz typ!'),
    details: Yup.array().of(
      Yup.object().shape({
        warehouseQuantity: Yup.number().nullable(),
        quantity: Yup.number()
          .nullable()
          .test({
            name: 'max',
            exclusive: true,
            params: {},
            message: 'Ponad stan!',
            test: function (value) {
              if (value) {
                return (
                  Number((this as any)?.parent?.warehouseQuantity) >=
                  Number(convertNumericStringToNumber(value))
                )
              } else {
                return true
              }
            },
          }),
      })
    ),
  })

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center">
      <CCard>
        <CCardBody className="warehouse-details">
          <h4
            className={`text-center ${
              !warehousesState?.moveReplenishmentDocumentGenerateError ? 'mb-4' : 'mb-3'
            } pb-1`}
          >
            {authState?.authData?.roles?.includes('Warehouses_write')
              ? 'Przesunięcie międzymagazynowe'
              : ''}
          </h4>

          <div className="d-flex justify-content-end list-fixed-create-new-button-wrapper">
            <FormActionCancelButton closeFunction={closeMoveReplenishment} closeAction={''} />
          </div>

          {warehousesState?.areInventoriesOfWarehousesLoading &&
          warehousesState?.areWarehousesLoading ? (
            <div
              style={{
                height: '300px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Spinner />
            </div>
          ) : (
            <Formik
              initialValues={{
                date: toDateInputValue(new Date().toISOString()),
                referenceNumber: '',

                fromWarehouseId: Number(paramsManagedWarehouseId),
                fromType: currentlyManagedFromTypeId,
                toWarehouseId:
                  warehousesState?.warehouses[
                    Number(paramsManagedWarehouseId) !== warehousesState?.warehouses[0]?.id ? 0 : 1
                  ]?.id,
                toType:
                  warehousesState?.warehouses[
                    Number(paramsManagedWarehouseId) !== warehousesState?.warehouses[0]?.id ? 0 : 1
                  ]?.types[0],

                details: generateInventoryFromSelectedFromWarehouseId(
                  Number(paramsManagedWarehouseId)
                ),
              }}
              validationSchema={MoveReplenishmentSchema}
              onSubmit={(values) => {
                if (isWarehouseEditable) {
                  setDidFormValidationOccur(true)

                  const replenishmentDetails: actionTypes.TMoveReplenishmentItem[] | undefined =
                    values?.details
                      ?.map((replenishmentItem: actionTypes.TMoveReplenishmentTableItem) => {
                        return {
                          bomElementId: replenishmentItem.bomElementId,
                          quantity: Number(
                            convertNumericStringToNumber(replenishmentItem?.quantity)
                          ),
                        }
                      })
                      ?.filter(
                        (moveReplenishmentItem: actionTypes.TMoveReplenishmentItem) =>
                          !!convertNumericStringToNumber(moveReplenishmentItem?.quantity)
                      )

                  dispatch({
                    type: actionTypes.GENERATE_MOVE_REPLENISHMENT_DOCUMENT_REQUESTED,
                    payload: {
                      tenant: tenant,
                      token: authState.authData?.token,
                      documentData: {
                        date: values.date,
                        referenceNumber: values.referenceNumber,

                        fromWarehouseId: Number(values.fromWarehouseId),
                        fromType: Number(values.fromType),
                        toWarehouseId: Number(values.toWarehouseId),
                        toType: Number(values.toType),

                        details: replenishmentDetails,
                      },
                    },
                  })
                }
              }}
              enableReinitialize={!isMoveReplenishmentModifiedAndUnsaved}
              validateOnBlur={false}
              validateOnChange={didFormValidationOccur}
            >
              {({
                initialValues,
                values,
                errors,
                setFieldValue,
                handleChange,
                resetForm,
                setErrors,
              }) => (
                <Form>
                  {/*
                   * Display Network Error Message
                   */}

                  {warehousesState?.moveReplenishmentDocumentGenerateError && (
                    <CustomErrorMessage
                      wrapperClassNames="mb-4"
                      customErrorMessageText={getErrorMessageFromStatus(
                        'create',
                        warehousesState?.moveReplenishmentDocumentGenerateError?.status,
                        'danych przesunięcia międzymagazynowego'
                      )}
                    />
                  )}

                  <div className="settings-view-double-grid-fields">
                    <BasicFormField
                      fieldId="warehouse-management-move-replenishment-move-date"
                      fieldLabel="Data przesunięcia"
                      fieldIcon="cil-calendar"
                      formikFieldName="date"
                      fieldValue={values.date}
                      fieldError={errors.date}
                      fieldType="date"
                      placeholder=""
                    />

                    <BasicFormField
                      fieldId="warehouse-management-move-replenishment-move-document"
                      fieldLabel="Dokument przesunięcia"
                      fieldIcon="cil-short-text"
                      formikFieldName="referenceNumber"
                      fieldValue={values.referenceNumber}
                      fieldError={errors.referenceNumber}
                      fieldType="text"
                      placeholder="Wpisz numer MM"
                    />
                  </div>

                  <div className="settings-view-double-grid-fields">
                    <div>
                      <CLabel
                        htmlFor="warehouse-management-move-replenishment-from-warehouse-id"
                        className={inputLabelSpacingBottom}
                      >
                        Magazyn źródłowy
                      </CLabel>
                      <InputGroup
                        id="warehouse-management-move-replenishment-from-warehouse-id"
                        className={`${inputFieldSpacingBottom} dropdown-selector`}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText
                            className={errors.fromWarehouseId && 'text-danger input-error-icon'}
                          >
                            <i className="cil-storage"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <ErrorMessage
                          name="fromWarehouseId"
                          component="span"
                          className="text-danger input-error-message"
                        />
                        <Field
                          as={Select}
                          name="fromWarehouseId"
                          variant="outlined"
                          native
                          value={values?.fromWarehouseId}
                          className={`item-selector element-selector ${
                            errors.fromWarehouseId && 'invalid-native-selector'
                          }`}
                          onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                            const changeFromWarehouseId = (selectedFromWarehouseId: number) => {
                              handleChange(event)

                              setIsMoveReplenishmentModifiedAndUnsaved(false)
                              setDidFormValidationOccur(false)
                              resetForm()

                              if (errors) {
                                setErrors({})
                              }

                              // This is important to change the displayed value in select
                              event.target.value = String(selectedFromWarehouseId)

                              if (selectedFromWarehouseId) {
                                return history.push(
                                  `/${tenant}/admin/warehouse-management/${selectedFromWarehouseId}/move-replenishment`
                                )
                              } else {
                                setFieldValue('details', emptyWarehouseInventoryItemsInForm)
                              }

                              return handleChange(event)
                            }

                            if (isMoveReplenishmentModifiedAndUnsaved) {
                              confirmAlert({
                                closeOnEscape: true,
                                // This is a hack, message is the new selected fromWarehouseId passed down to scope
                                message: event?.target?.value,
                                customUI: ({ onClose, message }) => {
                                  return (
                                    <ConfirmActionModal
                                      mode="selectOther"
                                      onClose={onClose}
                                      confirmMessageJSX={
                                        <>
                                          <strong>Masz niezapisane zmiany!</strong>
                                          <br />
                                          Czy na pewno chcesz ustawić{' '}
                                          <strong>
                                            {warehousesState?.warehouses
                                              ?.find(
                                                (warehouse: actionTypes.TWarehouseLight) =>
                                                  Number(message) === warehouse.id
                                              )
                                              ?.name?.substring(
                                                0,
                                                lengthOfWarehouseNameInConfirmModal
                                              ) || 'magazyn bez nazwy'}
                                          </strong>
                                          <br />
                                          jako magazyn źródłowy?
                                        </>
                                      }
                                      onConfirmFunction={() => {
                                        changeFromWarehouseId(Number(message))
                                      }}
                                    />
                                  )
                                },
                              })
                            } else {
                              changeFromWarehouseId(Number(event?.target?.value))
                            }
                          }}
                        >
                          {warehousesState?.warehouses?.map(
                            (warehouse: actionTypes.TWarehouseLight) => {
                              return (
                                <option
                                  key={`from-warehouse-option-${warehouse.id}`}
                                  value={warehouse.id}
                                >
                                  {warehouse?.name || 'Brak nazwy magazynu!'}
                                </option>
                              )
                            }
                          )}
                        </Field>
                      </InputGroup>
                    </div>

                    <div>
                      <CLabel
                        htmlFor="move-replenishment-from-type-id-selector"
                        className={`${inputLabelSpacingBottom}`}
                      >
                        Typ źródłowy
                      </CLabel>
                      <InputGroup
                        id="move-replenishment-from-type-id-selector"
                        className={`${inputFieldSpacingBottom} dropdown-selector`}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="cil-inbox"></i>
                          </InputGroupText>
                        </InputGroupAddon>

                        <Field
                          as={Select}
                          name="fromType"
                          variant="outlined"
                          native
                          value={values?.fromType}
                          className={`item-selector warehouse-selector w-100`}
                          onChange={(event: ChangeEvent<SelectProps>) => {
                            const changeFromTypeId = (selectedFromTypeId: number) => {
                              handleChange(event)

                              if (selectedFromTypeId) {
                                setCurrentlyManagedFromTypeId(selectedFromTypeId)
                              }

                              dispatch({
                                type: actionTypes.CLEAR_MOVE_REPLENISHMENT_ERROR,
                              })

                              setIsMoveReplenishmentModifiedAndUnsaved(false)
                              setDidFormValidationOccur(false)

                              return handleChange(event)
                            }

                            if (isMoveReplenishmentModifiedAndUnsaved) {
                              confirmAlert({
                                closeOnEscape: true,
                                message: String(event?.target?.value),
                                customUI: ({ message, onClose }) => {
                                  return (
                                    <ConfirmActionModal
                                      mode="selectOther"
                                      onClose={onClose}
                                      confirmMessageJSX={
                                        <>
                                          <strong>Masz niezapisane zmiany!</strong>
                                          <br />
                                          Zmiana typu wyzeruje wpisane ilości MM!
                                          <br />
                                          Czy na pewno chcesz zmienić typ źródłowy?
                                        </>
                                      }
                                      onConfirmFunction={() => {
                                        changeFromTypeId(Number(message))
                                      }}
                                    />
                                  )
                                },
                              })
                            } else {
                              changeFromTypeId(Number(event?.target?.value))
                            }
                          }}
                        >
                          {managedWarehouseTypes?.map(
                            (warehouseType: actionTypes.TWarehouseType) => {
                              return (
                                <option
                                  key={`from-type-option-${warehouseType}`}
                                  value={warehouseType}
                                >
                                  {actionTypes.warehouseTypesObject[warehouseType]}
                                </option>
                              )
                            }
                          )}
                        </Field>
                      </InputGroup>
                    </div>
                  </div>

                  <div className="settings-view-double-grid-fields">
                    <div>
                      <CLabel
                        htmlFor="warehouse-management-move-replenishment-to-warehouse-id"
                        className={inputLabelSpacingBottom}
                      >
                        Magazyn docelowy
                      </CLabel>
                      <InputGroup
                        id="warehouse-management-move-replenishment-to-warehouse-id"
                        className={`${inputFieldSpacingBottom} dropdown-selector`}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText
                            className={errors.toWarehouseId && 'text-danger input-error-icon'}
                          >
                            <i className="cil-storage"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <ErrorMessage
                          name="toWarehouseId"
                          component="span"
                          className="text-danger input-error-message"
                        />
                        <Field
                          as={Select}
                          name="toWarehouseId"
                          variant="outlined"
                          native
                          value={values?.toWarehouseId}
                          className={`item-selector element-selector ${
                            errors.toWarehouseId && 'invalid-native-selector'
                          }`}
                          onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                            return handleChange(event)
                          }}
                        >
                          {warehousesState?.warehouses?.map(
                            (warehouse: actionTypes.TWarehouseLight) => {
                              return (
                                <option
                                  key={`to-warehouse-option-${warehouse.id}`}
                                  value={warehouse.id}
                                  disabled={Number(values.fromWarehouseId) === warehouse.id}
                                >
                                  {warehouse?.name || 'Brak nazwy magazynu!'}
                                </option>
                              )
                            }
                          )}
                        </Field>
                      </InputGroup>
                    </div>

                    <div>
                      <CLabel
                        htmlFor="move-replenishment-to-type-id-selector"
                        className={`${inputLabelSpacingBottom}`}
                      >
                        Typ docelowy
                      </CLabel>
                      <InputGroup
                        id="move-replenishment-to-type-id-selector"
                        className={`${inputFieldSpacingBottom} dropdown-selector`}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="cil-inbox"></i>
                          </InputGroupText>
                        </InputGroupAddon>

                        <Field
                          as={Select}
                          name="toType"
                          variant="outlined"
                          native
                          value={values?.toType || ''}
                          className="item-selector warehouse-selector w-100"
                          onChange={(event: ChangeEvent<SelectProps>) => {
                            return handleChange(event)
                          }}
                        >
                          {warehousesState?.warehouses
                            ?.find(
                              (warehouse: actionTypes.TWarehouseLight) =>
                                warehouse.id === Number(values.toWarehouseId)
                            )
                            ?.types?.map((warehouseType: actionTypes.TWarehouseType) => {
                              return (
                                <option
                                  key={`to-type-option-${warehouseType}`}
                                  value={warehouseType}
                                >
                                  {actionTypes.warehouseTypesObject[warehouseType]}
                                </option>
                              )
                            })}
                        </Field>
                      </InputGroup>
                    </div>
                  </div>

                  <CDataTable
                    tableFilterValue={moveReplenishmentFilterState}
                    sorterValue={moveReplenishmentSorterState}
                    onSorterValueChange={(sorterState: actionTypes.TSorterState) => {
                      setMoveReplenishmentSorterState(sorterState)
                    }}
                    onTableFilterChange={(tableFilterState: string) => {
                      setMoveReplenishmentFilterState(tableFilterState)
                    }}
                    loading={warehousesState?.areInventoriesOfWarehousesLoading}
                    striped={!!values?.details?.length}
                    border
                    sorter
                    tableFilter={{ label: ' ', placeholder: 'Szukaj w tabeli...' }}
                    addTableClasses="vertical-middle-list-table warehouse-replenishment-table warehouse-move-replenishment-table"
                    cleaner
                    onRowClick={undefined}
                    fields={[
                      {
                        key: 'position',
                        label: '#',
                        _style: { width: '45px', textAlign: 'center' },
                      },
                      {
                        key: 'bomElementName',
                        label:
                          Number(values?.fromType) === materialsTypeNumber
                            ? 'Nazwa elementu'
                            : Number(values?.fromType) === waresTypeNumber
                            ? 'Nazwa towaru'
                            : Number(values?.fromType) === finishedProductsTypeNumber
                            ? 'Nazwa produktu'
                            : 'Nazwa',
                        _style: { width: '270px' },
                      },
                      {
                        key: 'warehouseQuantity',
                        label: 'Stan',
                        _style: { width: '100px', textAlign: 'right' },
                      },
                      {
                        key: 'quantity',
                        label: 'Ilość MM',
                        _style: { width: '120px', textAlign: 'right' },
                      },
                    ]}
                    items={values?.details?.filter(
                      (tableItem: actionTypes.TMoveReplenishmentTableItem) =>
                        currentlyManagedFromTypeId
                          ? tableItem.type === currentlyManagedFromTypeId
                          : true
                    )}
                    noItemsViewSlot={
                      <div className="no-items-in-table">
                        {!initialValues?.details?.length
                          ? 'Brak elementów w magazynie źródłowym!'
                          : initialValues?.details?.length && currentlyManagedFromTypeId
                          ? 'Brak elementów o wybranym typie w magazynie źródłowym!'
                          : ''}
                      </div>
                    }
                    scopedSlots={{
                      position: (
                        moveReplenishmentTableItem: actionTypes.TMoveReplenishmentTableItem,
                        moveReplenishmentTableItemIndex: number
                      ) => (
                        <td
                          key={`move-replenishment-table-cell-${moveReplenishmentTableItemIndex}-${moveReplenishmentTableItem?.bomElementId}-position`}
                        >
                          {moveReplenishmentTableItemIndex + 1}.
                        </td>
                      ),
                      quantity: (
                        moveReplenishmentTableItem: actionTypes.TMoveReplenishmentTableItem,
                        moveReplenishmentTableItemIndex: number
                      ) => {
                        const indexOfStabilizedItem = values?.details.findIndex(
                          (tableItem: actionTypes.TMoveReplenishmentTableItem) =>
                            tableItem.bomElementId === moveReplenishmentTableItem.bomElementId &&
                            tableItem.type === moveReplenishmentTableItem.type
                        )

                        return (
                          <td>
                            <InputGroup
                              id={`move-replenishment-quantity-item-group-id-${moveReplenishmentTableItem.bomElementId}-${moveReplenishmentTableItem.type}`}
                              style={{ pointerEvents: 'all' }}
                            >
                              <ErrorMessage
                                name={`details.${indexOfStabilizedItem}.quantity`}
                                component="span"
                                className="text-danger input-error-message"
                              />
                              <FastField
                                as={NumberFormat}
                                displayType="input"
                                thousandSeparator={globalThousandSeparator}
                                decimalSeparator={globalDecimalSeparator}
                                decimalScale={0}
                                fixedDecimalScale={false}
                                allowNegative={false}
                                allowLeadingZeros={true}
                                placeholder={''}
                                name={`details.${indexOfStabilizedItem}.quantity`}
                                value={values?.details[indexOfStabilizedItem]?.quantity || ''}
                                className={`form-control text-right ${
                                  errors?.details &&
                                  errors?.details[indexOfStabilizedItem] &&
                                  (errors?.details[indexOfStabilizedItem] as any)?.quantity &&
                                  'invalid-field-without-exclamation invalid-field-without-exclamation--replenishment '
                                }`}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                  moveReplenishmentTableItem.hasChanged = true

                                  setIsMoveReplenishmentModifiedAndUnsaved(true)

                                  handleChange(event)
                                }}
                                onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                                  const moveQuantity = event?.target?.value
                                  const delimiterOfMaximumMove =
                                    moveReplenishmentTableItem?.warehouseQuantity
                                      ? Number(
                                          convertNumericStringToNumber(
                                            moveReplenishmentTableItem?.warehouseQuantity
                                          )
                                        )
                                      : -1

                                  if (
                                    moveReplenishmentTableItem &&
                                    moveReplenishmentTableItem?.hasChanged &&
                                    convertNumericStringToNumber(moveQuantity) &&
                                    Number(convertNumericStringToNumber(moveQuantity)) >
                                      delimiterOfMaximumMove
                                  ) {
                                    confirmAlert({
                                      closeOnEscape: true,
                                      customUI: ({ onClose }) => {
                                        return (
                                          <ConfirmActionModal
                                            mode="replenishment"
                                            onClose={() => {
                                              setFieldValue(
                                                `details.${indexOfStabilizedItem}.quantity`,
                                                ''
                                              )

                                              event?.target?.focus()

                                              onClose()
                                            }}
                                            confirmMessageJSX={
                                              <>
                                                {moveReplenishmentTableItem?.bomElementName ? (
                                                  <>
                                                    <strong>{`${moveReplenishmentTableItem?.bomElementName}: `}</strong>
                                                    <br />
                                                  </>
                                                ) : (
                                                  ''
                                                )}
                                                Wpisana ilość przekracza ilość na stanie!
                                                <br />
                                                Czy wartość <strong>{moveQuantity}</strong> jest
                                                prawidłowa?
                                              </>
                                            }
                                            onConfirmFunction={() => {
                                              moveReplenishmentTableItem.hasChanged = false
                                              moveReplenishmentTableItem.quantity = moveQuantity

                                              onClose()
                                            }}
                                          />
                                        )
                                      },
                                    })
                                  }
                                }}
                              />
                            </InputGroup>
                          </td>
                        )
                      },
                    }}
                  />

                  <CButton
                    color="success"
                    className="save-button w-100 mt-3 mb-2"
                    type="submit"
                    onClick={() => {
                      setDidFormValidationOccur && setDidFormValidationOccur(true)
                    }}
                    disabled={
                      !values?.details?.filter(
                        (tableItem) => !!convertNumericStringToNumber(tableItem?.quantity)
                      )?.length ||
                      warehousesState.isMoveReplenishmentDocumentGenerating ||
                      warehousesState.isMoveReplenishmentDocumentGenerated ||
                      (didFormValidationOccur ? Boolean(errors.referenceNumber) : false)
                    }
                  >
                    {warehousesState?.isMoveReplenishmentDocumentGenerating ? (
                      <>
                        Generowanie MM
                        <ThreeDots />
                      </>
                    ) : warehousesState?.isMoveReplenishmentDocumentGenerated ? (
                      <>Wygenerowano MM!</>
                    ) : (
                      'Generuj MM'
                    )}
                  </CButton>
                </Form>
              )}
            </Formik>
          )}
        </CCardBody>
      </CCard>
    </Container>
  )
}
