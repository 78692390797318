import axios from 'axios'
import * as actionTypes from '../action-types'

// Together 149 world currencies in NBP

export const currencyExchangeCallA: any = async () => {
  return await axios({
    method: 'GET',
    url: `https://api.nbp.pl/api/exchangerates/tables/A`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
}

export const currencyExchangeCallB: any = async () => {
  return await axios({
    method: 'GET',
    url: `https://api.nbp.pl/api/exchangerates/tables/B`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
}

/* Use this for all GET lists/arrays operation without specified id */

export const sectionFetch: any = async (payload: actionTypes.TAdminPayload, section: string) => {
  return await axios({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/${section}`,
    headers: {
      tenant: payload.tenant,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    },
  })
}

/* Use this to GET single item with specified id */

export const singleItemFetch: any = async (
  payload: actionTypes.ISingleItemActionPayload,
  section: string
) => {
  return await axios({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/${section}`,
    headers: {
      tenant: payload.tenant,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    },
  })
}

/* Use this to COPY an item */

export const copyItemCall: any = async (
  payload: actionTypes.IEditItemOnListPayload,
  section: string
) => {
  return await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/${section}/copy/${payload.id}`,
    headers: {
      tenant: payload.tenant,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    },
  })
}

/* Use this to ARCHIVE an item */

export const archiveItemCall: any = async (
  payload: actionTypes.ISingleItemActionPayload,
  section: string
) => {
  return await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/${section}/archive/${payload.id}`,
    headers: {
      tenant: payload.tenant,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    },
  })
}

/* Use this to DELETE an item */

export const deleteItemCall: any = async (
  payload: actionTypes.ISingleItemActionPayload,
  section: string
) => {
  return await axios({
    method: 'delete',
    url: `${process.env.REACT_APP_API_URL}/${section}/${payload.id}`,
    headers: {
      tenant: payload.tenant,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    },
    data: JSON.stringify(payload.id),
  })
}

/* AUTH */

export const loginCall: any = async (payload: actionTypes.TLoginPayload) => {
  return await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/security/`,
    headers: {
      tenant: payload.tenant,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: JSON.stringify({
      userName: payload.user,
      password: payload.password,
    }),
  })
}

export const authenticationCall: any = async (payload: actionTypes.TAdminPayload) => {
  return await axios({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/security/securedPing`,
    headers: {
      tenant: payload.tenant,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    },
  })
}

export const requestPasswordResetCall: any = async (
  payload: actionTypes.TRequestPasswordResetPayload
) => {
  return await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/security/requestPasswordReset`,
    headers: {
      tenant: payload.tenant,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: JSON.stringify({
      userName: payload.user,
    }),
  })
}

export const passwordResetTokenCheckCall: any = async (
  payload: actionTypes.TPasswordResetTokenCheckPayload
) => {
  return await axios({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/security/resetPasswordTokenCheck/${payload.token}`,
    headers: {
      tenant: payload.tenant,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
}

export const passwordResetCall: any = async (payload: actionTypes.TPasswordResetPayload) => {
  return await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/security/resetPassword`,
    headers: {
      tenant: payload.tenant,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: JSON.stringify({
      id: payload.id,
      password: payload.password,
      passwordConfirmation: payload.passwordConfirmation,
    }),
  })
}

/* SUPPLIER */

export const supplierCall: any = async (payload: actionTypes.ICreateSupplierPayload) => {
  return await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/suppliers/edit`,
    headers: {
      tenant: payload.tenant,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    },
    data: JSON.stringify({ ...payload.supplier }),
  })
}

/* BOM ELEMENT */

export const bomElementCall: any = async (payload: actionTypes.ICreateBomElementPayload) => {
  return await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/bomElements/edit`,
    headers: {
      tenant: payload.tenant,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    },
    data: JSON.stringify({ ...payload.bomElement }),
  })
}

/* SERVICE */

export const serviceCall: any = async (payload: actionTypes.ICreateServicePayload) => {
  return await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/services`,
    headers: {
      tenant: payload.tenant,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    },
    data: JSON.stringify({ ...payload.service }),
  })
}

/* IMPLEMENTATION_COST */

export const implementationCostCall: any = async (
  payload: actionTypes.ICreateImplementationCostPayload
) => {
  return await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/implementationCosts`,
    headers: {
      tenant: payload.tenant,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    },
    data: JSON.stringify({ ...payload.implementationCost }),
  })
}

/* BOM */

export const bomCall: any = async (payload: actionTypes.ICreateBomPayload) => {
  return await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/boms/edit`,
    headers: {
      tenant: payload.tenant,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    },
    data: JSON.stringify({ ...payload.bom }),
  })
}

/* PRODUCER */

export const producerCall: any = async (payload: actionTypes.ICreateProducerPayload) => {
  return await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/producers/edit`,
    headers: {
      tenant: payload.tenant,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    },
    data: JSON.stringify({ ...payload.producer }),
  })
}

/* OFFER */

export const offerCall: any = async (payload: actionTypes.IEditOfferPayload) => {
  return await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/offers/edit`,
    headers: {
      tenant: payload.tenant,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    },
    data: payload.offer,
  })
}

/* PURCHASE PLAN */

export const purchasePlanCall: any = async (
  payload: actionTypes.ICreateNewPurchasePlanPayload | actionTypes.IEditPurchasePlanPayload | any
) => {
  return await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/purchasePlans/edit`,
    headers: {
      tenant: payload.tenant,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    },
    data: payload.items ? payload.items : payload.purchasePlan,
  })
}

export const singlePurchasePlanBomElementFetch: any = async (
  payload: actionTypes.IGetPurchasePlanBomElementPayload
) => {
  return await axios({
    method: 'GET',
    url: `${
      process.env.REACT_APP_API_URL
    }/purchasePlans/editPurchasePlanBomElement?purchasePlanId=${
      payload?.purchasePlanId
    }&bomElementId=${payload?.bomElementId}${payload?.bomIds
      ?.map((bomId: number) => `&bomIds=${bomId}`)
      ?.join('')}`,
    headers: {
      tenant: payload.tenant,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    },
  })
}

export const singlePurchasePlanImplementationCostFetch: any = async (
  payload: actionTypes.IGetPurchasePlanImplementationCostPayload
) => {
  return await axios({
    method: 'GET',
    url: `${
      process.env.REACT_APP_API_URL
    }/purchasePlans/editPurchasePlanImplementationCost?purchasePlanId=${
      payload?.purchasePlanId
    }&implementationCostId=${payload?.implementationCostId}${payload?.bomIds
      ?.map((bomId: number) => `&bomIds=${bomId}`)
      ?.join('')}`,
    headers: {
      tenant: payload.tenant,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    },
  })
}

export const singlePurchasePlanServiceFetch: any = async (
  payload: actionTypes.IGetPurchasePlanServicePayload
) => {
  return await axios({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/purchasePlans/editPurchasePlanService?purchasePlanId=${
      payload?.purchasePlanId
    }&serviceId=${payload?.serviceId}${payload?.bomIds
      ?.map((bomId: number) => `&bomIds=${bomId}`)
      ?.join('')}`,
    headers: {
      tenant: payload.tenant,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    },
  })
}

export const generateOrderCall: any = async (payload: actionTypes.IGenerateOrderPayload) => {
  return await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/purchasePlans/generateOrder`,
    headers: {
      tenant: payload.tenant,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    },

    data: payload.orderInfo,
  })
}

/* ORDER */

export const orderCall: any = async (payload: actionTypes.ICreateOrderPayload) => {
  return await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/orders/edit`,
    headers: {
      tenant: payload.tenant,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    },
    data: JSON.stringify({ ...payload.order }),
  })
}

export const generateOrderPDFCall: any = async (payload: actionTypes.IGenerateOrderPDFPayload) => {
  return await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/orders/pdf`,
    headers: {
      tenant: payload.tenant,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    },
    data: payload.orderPDF.orderId,
  })
}

/* WAREHOUSES */

export const warehouseCall: any = async (payload: actionTypes.ICreateWarehousePayload) => {
  return await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/warehouses`,
    headers: {
      tenant: payload.tenant,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    },
    data: JSON.stringify({ ...payload.warehouse }),
  })
}

export const materialsReplenishmentCall: any = async (
  payload: actionTypes.IGenerateMaterialsOrWaresReplenishmentDocumentPayload
) => {
  return await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/replenishment/materialReplenishment`,
    headers: {
      tenant: payload.tenant,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    },
    data: JSON.stringify({ ...payload.documentData }),
  })
}

export const waresReplenishmentCall: any = async (
  payload: actionTypes.IGenerateMaterialsOrWaresReplenishmentDocumentPayload
) => {
  return await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/replenishment/waresReplenishment`,
    headers: {
      tenant: payload.tenant,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    },
    data: JSON.stringify({ ...payload.documentData }),
  })
}

export const finishedProductsReplenishmentCall: any = async (payload: any) => {
  return await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/replenishment/finishedProductsReplenishment`,
    headers: {
      tenant: payload.tenant,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    },
    data: JSON.stringify({ ...payload.documentData }),
  })
}

export const moveReplenishmentCall: any = async (
  payload: actionTypes.IGenerateMoveReplenishmentDocumentPayload
) => {
  return await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/replenishment/moveReplenishment`,
    headers: {
      tenant: payload.tenant,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    },
    data: JSON.stringify({ ...payload.documentData }),
  })
}

export const saveWarehouseInventoryControlCall: any = async (
  payload: actionTypes.ISaveWarehouseInventoryControlDataPayload
) => {
  return await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/warehouses/inventoryControl/${payload.saveInventoryControlData.inventoryControlWarehouseId}`,
    headers: {
      tenant: payload.tenant,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    },
    data: JSON.stringify({ ...payload.saveInventoryControlData }),
  })
}

/* SETTINGS */

export const settingsCall: any = async (payload: actionTypes.ISaveSettingsPayload) => {
  return await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/settings`,
    headers: {
      tenant: payload.tenant,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    },
    data: JSON.stringify({ ...payload.settings }),
  })
}

/* ATTACHMENTS */

export const addSingleAttachmentCall: any = async (
  payload: actionTypes.IUploadSingleAttachmentPayload
) => {
  return await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/fileAttachments/addAttachment`,
    headers: {
      tenant: payload.tenant,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    },
    data: JSON.stringify({ ...payload.uploadAttachment }),
  })
}

export const getSingleAttachmentCall: any = async (payload: actionTypes.IAttachmentFetch) => {
  return await axios({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/fileAttachments/getAttachment/${payload.id}`,
    headers: {
      tenant: payload.tenant,
      'Content-Disposition': 'attachment',
      Authorization: `Bearer ${payload.token}`,
    },
    responseType: 'blob',
  })
}

export const deleteSingleAttachmentCall: any = async (payload: actionTypes.IAttachmentFetch) => {
  return await axios({
    method: 'delete',
    url: `${process.env.REACT_APP_API_URL}/fileAttachments/deleteAttachment/${payload.id}`,
    headers: {
      tenant: payload.tenant,
      'Content-Disposition': 'attachment',
      Authorization: `Bearer ${payload.token}`,
    },
  })
}

// GET ALL ATTACHMENTS OF AN ITEM

export const itemAttachmentsFetch: any = async (
  payload: actionTypes.IFetchItemAttachmentsPayload
) => {
  return await axios({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/fileAttachments/getFileAttachments?fileAttachmentTypeId=${payload.fileAttachmentTypeId}&entityId=${payload.entityId}`,
    headers: {
      tenant: payload.tenant,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    },
  })
}

// DELETE NOT LINKED ATTACHMENTS

export const notLinkedAttachmentsDelete: any = async (
  payload: actionTypes.IUploadSingleAttachmentPayload
) => {
  return await axios({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/fileAttachments/deleteNotLinkedFileAttachments`,
    headers: {
      tenant: payload.tenant,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    },
  })
}

/* USERS */

export const userCall: any = async (payload: actionTypes.ICreateUserPayload) => {
  return await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/users/edit`,
    headers: {
      tenant: payload.tenant,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    },
    data: JSON.stringify({ ...payload.user }),
  })
}

/* PROFILES */

export const profilesCall: any = async (payload: actionTypes.ISaveProfilesPayload) => {
  return await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/usersProfiles`,
    headers: {
      tenant: payload.tenant,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    },
    data: JSON.stringify({ ...payload.profiles }),
  })
}
