import React, { ChangeEvent, useRef } from 'react'
import NumberFormat from 'react-number-format'
import { ErrorMessage, FastField, Field, FieldArray } from 'formik'
import { Typeahead } from 'react-bootstrap-typeahead'
import { useSelector } from 'react-redux'
import { CButton, CDataTable, CInput } from '@coreui/react'
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import { v4 as uuidv4 } from 'uuid'

import { TRootState } from '../../store/reducers'
import * as actionTypes from '../../store/action-types'
import { CurrencyPicker } from '../../components'
import {
  IBomDetailsTableValues,
  numeralsTextAlign,
  tableRowItemNameLeftPadding,
} from './bom-details'
import {
  globalCurrencyFallback,
  globalDecimalSeparator,
  globalThousandSeparator,
  handleNumberFormatPaste,
} from '../../utils'
import { convertNumericStringToNumber } from '../../utils/convert-numeric-string-to-number'

export const BomDetailsImplementationCostsTable: React.FC<IBomDetailsTableValues> = ({
  values,
  errors,
  handleChange,
  setFieldValue,
  setIsBomModifiedAndUnsaved,
  isBomChangeOccuring,
}) => {
  const implementationCostsState = useSelector((state: TRootState) => state.implementationCosts)
  const settingsState = useSelector((state: TRootState) => state.settings)

  const implementationCostNameTypeaheads = useRef([])

  return (
    <FieldArray
      name="bomImplementationCostDetails"
      render={(arrayHelpers) => (
        <>
          <div className="bom-other-items-table-wrapper">
            <CDataTable
              addTableClasses={`bom-other-items-table ${
                values.bomImplementationCostDetails.length ? '' : 'mb-0'
              }`}
              loading={implementationCostsState.areImplementationCostsLoading}
              striped={false}
              fields={[
                {
                  key: 'position',
                  label: '#',
                  _style: { width: '45px' },
                },
                {
                  key: 'bomImplementationCostName',
                  label: 'Nazwa',
                  _style: { paddingLeft: tableRowItemNameLeftPadding },
                },
                {
                  key: 'quantity',
                  label: 'Ilość',
                  _style: { width: '100px' },
                },
                {
                  key: 'estimatedPrice',
                  label: 'Cena szacunkowa',
                  _style: { width: '180px' },
                },
                {
                  key: 'offer',
                  label: 'Cena ofertowa',
                  _style: { width: '180px' },
                },
                {
                  key: 'lastOrderPrice',
                  label: 'Cena zamówienia',
                  _style: { width: '180px' },
                },
                {
                  key: 'cost',
                  label: 'Wartość',
                  _style: { width: '180px' },
                },
                {
                  key: 'remove',
                  label: '',
                  _style: { width: '50px' },
                },
                {
                  key: 'isChecked',
                  label: '',
                  _style: { width: '50px' },
                },
              ]}
              items={values.bomImplementationCostDetails}
              noItemsViewSlot={<div />}
              scopedSlots={{
                position: (
                  tableRowItem: actionTypes.IBomImplementationCost,
                  indexOfTableRowItem: number
                ) => (
                  <td
                    key={`bom-implementation-cost-table-cell-${indexOfTableRowItem}-${tableRowItem?.id}-position`}
                    style={{ lineHeight: '2.4', textAlign: numeralsTextAlign }}
                  >
                    {indexOfTableRowItem + 1}.
                  </td>
                ),
                bomImplementationCostName: (
                  tableRowItem: actionTypes.IBomImplementationCost,
                  indexOfTableRowItem: number
                ) => (
                  <td
                    key={`bom-implementation-cost-table-cell-${indexOfTableRowItem}-${tableRowItem?.id}-name-info`}
                    style={{ paddingLeft: tableRowItemNameLeftPadding }}
                  >
                    <div className="bom-table-element-typeahead-wrapper">
                      <Typeahead
                        placeholder="Wybierz z listy lub wpisz nazwę"
                        isInvalid={
                          errors.bomImplementationCostDetails &&
                          errors.bomImplementationCostDetails[indexOfTableRowItem] &&
                          Boolean(
                            errors?.bomImplementationCostDetails[indexOfTableRowItem]
                              ?.bomImplementationCostName
                          )
                        }
                        id={`bomImplementationCostDetails.${indexOfTableRowItem}.bomImplementationCostName`}
                        ref={(HTMLElement: never) => {
                          implementationCostNameTypeaheads.current[indexOfTableRowItem] =
                            HTMLElement
                        }}
                        onChange={(
                          selectedBomImplementationCostsArray: actionTypes.TImplementationCost[]
                        ) => {
                          const selectedImplementationCost = selectedBomImplementationCostsArray[0]

                          /* Fills or Clears the remaining fields of bomImplementationCost object in Formik values */

                          if (selectedImplementationCost) {
                            setFieldValue(
                              `bomImplementationCostDetails.${indexOfTableRowItem}.bomImplementationCostId`,
                              selectedImplementationCost.id || null,
                              false
                            )

                            /* If you clear the output the input should always be an empty string "" never null because it allows the menu to open again */

                            setFieldValue(
                              `bomImplementationCostDetails.${indexOfTableRowItem}.bomImplementationCostName`,
                              selectedImplementationCost.name || '',
                              false
                            )
                          }
                        }}
                        onInputChange={(text: string) => {
                          /* Delete other fields when the length of an input field when it is smaller than 1 */

                          if (text.length < 1) {
                            /* Removes the remaining fields of bomImplementationCost object in Formik values */

                            const fieldsToEmpty: Array<keyof actionTypes.IBomImplementationCost> = [
                              'bomImplementationCostId',
                              'bomImplementationCostName',
                              'quantity',
                              'estimatedPrice',
                              'offer',
                              'offerId',
                              'lastOrderPrice',
                            ]

                            fieldsToEmpty.forEach(
                              (field: keyof actionTypes.IBomImplementationCost) =>
                                setFieldValue(
                                  `bomImplementationCostDetails.${indexOfTableRowItem}.${field}`,
                                  field === 'offerId' ||
                                    field === 'offer' ||
                                    field === 'bomImplementationCostId'
                                    ? null
                                    : '',
                                  false
                                )
                            )
                          }
                        }}
                        defaultInputValue={tableRowItem?.bomImplementationCostName}
                        // Here we traverse an array of TImplementationCost objects
                        options={
                          tableRowItem.bomImplementationCostId &&
                          tableRowItem.bomImplementationCostName
                            ? []
                            : implementationCostsState.implementationCosts.filter(
                                (impCost: actionTypes.TImplementationCost) =>
                                  !values.bomImplementationCostDetails.some(
                                    (bomImpCostInTable: actionTypes.IBomImplementationCost) =>
                                      impCost.id === bomImpCostInTable.bomImplementationCostId
                                  )
                              )
                        }
                        labelKey="name"
                        emptyLabel={
                          tableRowItem.bomImplementationCostId &&
                          tableRowItem.bomImplementationCostName
                            ? `Wybrany tooling: ${
                                tableRowItem.bomImplementationCostName
                              } (oferta: ${tableRowItem?.offer?.offerNumber || 'brak'}; id: ${
                                tableRowItem.bomImplementationCostId
                              })`
                            : 'Nie znaleziono toolingu'
                        }
                        disabled={isBomChangeOccuring}
                      />
                    </div>
                  </td>
                ),
                quantity: (
                  tableRowItem: actionTypes.IBomImplementationCost,
                  indexOfTableRowItem: number
                ) => (
                  <td
                    key={`bom-implementation-cost-table-cell-${indexOfTableRowItem}-${tableRowItem?.id}-quantity`}
                  >
                    <InputGroup>
                      <FastField
                        as={NumberFormat}
                        displayType="input"
                        thousandSeparator={globalThousandSeparator}
                        decimalSeparator={globalDecimalSeparator}
                        decimalScale={2}
                        fixedDecimalScale={false}
                        allowNegative={false}
                        allowLeadingZeros={true}
                        name={`bomImplementationCostDetails.${indexOfTableRowItem}.quantity`}
                        placeholder="Ilość"
                        onPaste={(event: ChangeEvent<HTMLInputElement>) => {
                          handleNumberFormatPaste(event)
                        }}
                        className={`form-control text-right ${
                          errors.bomImplementationCostDetails !== undefined &&
                          typeof errors.bomImplementationCostDetails !== 'string' &&
                          errors.bomImplementationCostDetails[indexOfTableRowItem] &&
                          errors.bomImplementationCostDetails[indexOfTableRowItem]?.quantity
                            ? 'invalid-field-without-exclamation'
                            : ''
                        } ${isBomChangeOccuring && 'table-disabled-input'}`}
                        disabled={isBomChangeOccuring}
                      />
                    </InputGroup>
                  </td>
                ),
                estimatedPrice: (
                  tableRowItem: actionTypes.IBomImplementationCost,
                  indexOfTableRowItem: number
                ) => (
                  <td
                    key={`bom-implementation-cost-table-cell-${indexOfTableRowItem}-${tableRowItem?.id}-estimated-price`}
                  >
                    <InputGroup>
                      <FastField
                        as={NumberFormat}
                        displayType="input"
                        thousandSeparator={globalThousandSeparator}
                        decimalSeparator={globalDecimalSeparator}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        allowNegative={false}
                        allowLeadingZeros={true}
                        name={`bomImplementationCostDetails.${indexOfTableRowItem}.estimatedPrice`}
                        placeholder="Cena szacunkowa"
                        onPaste={(event: ChangeEvent<HTMLInputElement>) => {
                          handleNumberFormatPaste(event)
                        }}
                        className={`form-control text-right ${
                          errors.bomImplementationCostDetails !== undefined &&
                          typeof errors.bomImplementationCostDetails !== 'string' &&
                          errors.bomImplementationCostDetails[indexOfTableRowItem] &&
                          errors.bomImplementationCostDetails[indexOfTableRowItem]?.estimatedPrice
                            ? 'invalid-field-without-exclamation'
                            : 'currency-input-field'
                        } ${isBomChangeOccuring && 'table-disabled-input'}`}
                        disabled={isBomChangeOccuring}
                      />
                      <InputGroupAddon
                        addonType="append"
                        className={
                          errors.bomImplementationCostDetails !== undefined &&
                          typeof errors.bomImplementationCostDetails !== 'string' &&
                          errors.bomImplementationCostDetails[indexOfTableRowItem] &&
                          errors.bomImplementationCostDetails[indexOfTableRowItem]?.estimatedPrice
                            ? 'invalid-native-selector'
                            : ''
                        }
                      >
                        <CurrencyPicker
                          handleChange={handleChange}
                          fieldName={`bomImplementationCostDetails.${indexOfTableRowItem}.estimatedPriceCurrency`}
                          dynamicCurrency={tableRowItem?.estimatedPriceCurrency}
                        />
                      </InputGroupAddon>
                    </InputGroup>
                  </td>
                ),
                offer: (
                  tableRowItem: actionTypes.IBomImplementationCost,
                  indexOfTableRowItem: number
                ) => (
                  <td
                    key={`bom-implementation-cost-table-cell-${indexOfTableRowItem}-${tableRowItem?.id}-offer-price`}
                  >
                    <InputGroup>
                      {tableRowItem?.offer ? (
                        <FastField
                          as={NumberFormat}
                          displayType="input"
                          thousandSeparator={globalThousandSeparator}
                          decimalSeparator={globalDecimalSeparator}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          allowNegative={false}
                          allowLeadingZeros={true}
                          name={`bomImplementationCostDetails.${indexOfTableRowItem}.offer.implementationCostPrice`}
                          value={tableRowItem?.offer?.implementationCostPrice || ''}
                          placeholder="Cena ofertowa"
                          className="form-control text-right table-disabled-input"
                          disabled
                        />
                      ) : (
                        <Input
                          placeholder="Cena ofertowa"
                          className="table-disabled-input"
                          disabled
                        />
                      )}
                      {/* Disabled Currency */}
                      <InputGroupAddon addonType="append">
                        <InputGroupText className="disabled-currency">
                          {tableRowItem?.offer?.implementationCostPriceCurrency}
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </td>
                ),
                lastOrderPrice: (
                  tableRowItem: actionTypes.IBomImplementationCost,
                  indexOfTableRowItem: number
                ) => (
                  <td
                    key={`bom-implementation-cost-table-cell-${indexOfTableRowItem}-${tableRowItem?.id}-order-price`}
                  >
                    <InputGroup>
                      <FastField
                        as={NumberFormat}
                        displayType="input"
                        thousandSeparator={globalThousandSeparator}
                        decimalSeparator={globalDecimalSeparator}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        allowNegative={false}
                        allowLeadingZeros={true}
                        name={`bomImplementationCostDetails.${indexOfTableRowItem}.lastOrderPrice`}
                        placeholder="Cena zamówienia"
                        className="form-control text-right table-disabled-input"
                        disabled
                      />
                      {/* Disabled Currency */}
                      <InputGroupAddon addonType="append">
                        <InputGroupText className="disabled-currency">
                          {tableRowItem?.lastOrderPriceCurrency || ''}
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </td>
                ),
                cost: (
                  tableRowItem: actionTypes.IBomImplementationCost,
                  indexOfTableRowItem: number
                ) => (
                  <td
                    key={`bom-implementation-cost-table-cell-${indexOfTableRowItem}-${tableRowItem?.id}-cost`}
                  >
                    <InputGroup>
                      <Field
                        as={NumberFormat}
                        displayType="input"
                        thousandSeparator={globalThousandSeparator}
                        decimalSeparator={globalDecimalSeparator}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        allowNegative={false}
                        allowLeadingZeros={true}
                        name={`bomImplementationCostDetails.${indexOfTableRowItem}.cost`}
                        value={
                          tableRowItem?.quantity &&
                          (tableRowItem?.estimatedPrice ||
                            tableRowItem?.offer?.implementationCostPrice ||
                            tableRowItem?.estimatedPrice)
                            ? Number(convertNumericStringToNumber(tableRowItem?.quantity)) *
                              Number(
                                convertNumericStringToNumber(tableRowItem?.lastOrderPrice) ||
                                  convertNumericStringToNumber(
                                    tableRowItem?.offer?.implementationCostPrice
                                  ) ||
                                  convertNumericStringToNumber(tableRowItem.estimatedPrice)
                              )
                            : ''
                        }
                        placeholder="Wartość"
                        className="form-control text-right table-disabled-input"
                        disabled
                      />

                      {/* Disabled Currency */}
                      <InputGroupAddon addonType="append">
                        <InputGroupText className="disabled-currency">
                          {tableRowItem.offer
                            ? tableRowItem.offer?.implementationCostPriceCurrency
                            : tableRowItem.estimatedPriceCurrency}
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </td>
                ),
                remove: (
                  tableRowItem: actionTypes.IBomImplementationCost,
                  indexOfTableRowItem: number
                ) => (
                  <td className="text-center">
                    <CButton
                      color="danger"
                      type="button"
                      variant="outline"
                      onClick={() => {
                        for (
                          let i = indexOfTableRowItem;
                          i < values.bomImplementationCostDetails.length;
                          i++
                        ) {
                          const implementationCostNameTypeahead: any =
                            implementationCostNameTypeaheads?.current[i]

                          const implementationCostNameTypeaheadNext: any =
                            implementationCostNameTypeaheads?.current[i + 1]

                          if (
                            implementationCostNameTypeahead &&
                            implementationCostNameTypeaheadNext
                          ) {
                            implementationCostNameTypeahead.inputNode.value =
                              implementationCostNameTypeaheadNext?.inputNode?.value
                                ? String(implementationCostNameTypeaheadNext?.inputNode?.value)
                                : ''

                            implementationCostNameTypeahead.state.text =
                              implementationCostNameTypeaheadNext?.state?.text
                                ? String(implementationCostNameTypeaheadNext?.state?.text)
                                : ''

                            // This is very important to keep the order of deleted implementation costs
                            implementationCostNameTypeahead.state.selected =
                              implementationCostNameTypeaheadNext?.state?.selected
                          }

                          if (i + 1 === values.bomImplementationCostDetails.length) {
                            arrayHelpers.remove(indexOfTableRowItem)
                          }
                        }
                      }}
                      className="select-option-remove-button"
                      disabled={Boolean(tableRowItem?.offer) || isBomChangeOccuring}
                    >
                      <i className="cil-trash"></i>
                    </CButton>
                  </td>
                ),
                isChecked: (
                  tableRowItem: actionTypes.IBomImplementationCost,
                  indexOfTableRowItem: number
                ) => (
                  <td>
                    <Field
                      as={CInput}
                      name={`bomImplementationCostDetails.${indexOfTableRowItem}.isChecked`}
                      type="checkbox"
                      className="table-row-checkbox"
                      checked={tableRowItem.isChecked}
                      disabled={isBomChangeOccuring}
                    />
                  </td>
                ),
              }}
            />
          </div>

          {(errors.bomImplementationCostDetails &&
            typeof errors.bomImplementationCostDetails === 'string' &&
            (values.bomElementDetails?.length || values.bomServiceDetails?.length
              ? false
              : !values.bomImplementationCostDetails?.length) && (
              <ErrorMessage
                name="bomImplementationCostDetails"
                component="div"
                className="text-danger d-flex justify-content-center mb-4"
              />
            )) ||
            null}

          {(errors.bomImplementationCostDetails &&
            typeof errors.bomImplementationCostDetails !== 'string' &&
            values.bomImplementationCostDetails?.length && (
              <div className="text-danger d-flex justify-content-center mb-4">
                Zaznaczone na czerwono pola w tabeli są wymagane!
              </div>
            )) ||
            null}

          <div
            className={`p-0 ${
              values.bomImplementationCostDetails.length ? 'mt-3' : 'mt-0'
            } mb-0 d-flex justify-content-center`}
          >
            <CButton
              color="info"
              type="button"
              variant="outline"
              onClick={() => {
                const newBomImplementationCostInTable: actionTypes.IBomImplementationCost = {
                  bomImplementationCostId: null,
                  bomImplementationCostName: '',
                  quantity: '',

                  estimatedPrice: '',
                  estimatedPriceCurrency:
                    settingsState?.settings?.otherSettings?.defaultSystemCurrency ||
                    globalCurrencyFallback,

                  offer: null,
                  offerId: null,

                  lastOrderPrice: '',
                  lastOrderPriceCurrency:
                    settingsState?.settings?.otherSettings?.defaultSystemCurrency ||
                    globalCurrencyFallback,

                  cost: '',

                  isChecked: true,
                  bomId: undefined,
                  id: null,
                  uuId: uuidv4(),
                }

                arrayHelpers.push(newBomImplementationCostInTable)

                setIsBomModifiedAndUnsaved(true)
              }}
              className="px-5"
              disabled={isBomChangeOccuring}
            >
              Dodaj pozycję
            </CButton>
          </div>
        </>
      )}
    />
  )
}
