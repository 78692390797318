import {
  TAdminPayload,
  ISingleItemActionPayload,
  IAttachmentUpload,
  TAttachment,
  IEditItemOnListPayload,
  IUploadSingleAttachmentPayload,
  IFetchItemAttachmentsPayload,
  TFilterState,
} from './admin'
import { TNetworkError } from './auth'
import { TSupplier } from './suppliers'
import { TOffer } from './offers'
import { IBomFullElement, TBomLight, TCurrencyCode } from './boms'
import { TSorterState } from '.'

export const FETCH_BOM_ELEMENTS_REQUESTED = 'FETCH_BOM_ELEMENTS_REQUESTED'
export const FETCH_BOM_ELEMENTS_SUCCEEDED = 'FETCH_BOM_ELEMENTS_SUCCEEDED'
export const FETCH_BOM_ELEMENTS_ERROR = 'FETCH_BOM_ELEMENTS_ERROR'

export const SET_BOM_ELEMENTS_TABLE_FILTER_STATE = 'SET_BOM_ELEMENTS_TABLE_FILTER_STATE'
export const SET_BOM_ELEMENTS_TABLE_SORTER_STATE = 'SET_BOM_ELEMENTS_TABLE_SORTER_STATE'

export const CREATE_BOM_ELEMENT_REQUESTED = 'CREATE_BOM_ELEMENT_REQUESTED'
export const CREATE_BOM_ELEMENT_SUCCEEDED = 'CREATE_BOM_ELEMENT_SUCCEEDED'
export const CREATE_BOM_ELEMENT_ERROR = 'CREATE_BOM_ELEMENT_ERROR'

export const SET_EDITED_BOM_ELEMENT = 'SET_EDITED_BOM_ELEMENT'

export const FETCH_SINGLE_BOM_ELEMENT_REQUESTED = 'FETCH_SINGLE_BOM_ELEMENT_REQUESTED'
export const FETCH_SINGLE_BOM_ELEMENT_ERROR = 'FETCH_SINGLE_BOM_ELEMENT_ERROR'
export const FETCH_SINGLE_BOM_ELEMENT_SUCCEEDED = 'FETCH_SINGLE_BOM_ELEMENT_SUCCEEDED'

export const FETCH_BOM_ELEMENT_BOMS_REQUESTED = 'FETCH_BOM_ELEMENT_BOMS_REQUESTED'
export const FETCH_BOM_ELEMENT_BOMS_SUCCEEDED = 'FETCH_BOM_ELEMENT_BOMS_SUCCEEDED'
export const FETCH_BOM_ELEMENT_BOMS_ERROR = 'FETCH_BOM_ELEMENT_BOMS_ERROR'

export const FETCH_BOM_ELEMENT_OFFERS_REQUESTED = 'FETCH_BOM_ELEMENT_OFFERS_REQUESTED'
export const FETCH_BOM_ELEMENT_OFFERS_SUCCEEDED = 'FETCH_BOM_ELEMENT_OFFERS_SUCCEEDED'
export const FETCH_BOM_ELEMENT_OFFERS_ERROR = 'FETCH_BOM_ELEMENT_OFFERS_ERROR'

export const EDIT_BOM_ELEMENT_REQUESTED = 'EDIT_BOM_ELEMENT_REQUESTED'
export const EDIT_BOM_ELEMENT_SUCCEEDED = 'EDIT_BOM_ELEMENT_SUCCEEDED'
export const EDIT_BOM_ELEMENT_ERROR = 'EDIT_BOM_ELEMENT_ERROR'

export const COPY_BOM_ELEMENT_REQUESTED = 'COPY_BOM_ELEMENT_REQUESTED'
export const COPY_BOM_ELEMENT_SUCCEEDED = 'COPY_BOM_ELEMENT_SUCCEEDED'
export const COPY_BOM_ELEMENT_ERROR = 'COPY_BOM_ELEMENT_ERROR'

export const ARCHIVE_BOM_ELEMENT_REQUESTED = 'ARCHIVE_BOM_ELEMENT_REQUESTED'
export const ARCHIVE_BOM_ELEMENT_SUCCEEDED = 'ARCHIVE_BOM_ELEMENT_SUCCEEDED'
export const ARCHIVE_BOM_ELEMENT_ERROR = 'ARCHIVE_BOM_ELEMENT_ERROR'

export const DELETE_BOM_ELEMENT_REQUESTED = 'DELETE_BOM_ELEMENT_REQUESTED'
export const DELETE_BOM_ELEMENT_SUCCEEDED = 'DELETE_BOM_ELEMENT_SUCCEEDED'
export const DELETE_BOM_ELEMENT_ERROR = 'DELETE_BOM_ELEMENT_ERROR'

export const FETCH_BOM_ELEMENT_ATTACHMENTS_REQUESTED = 'FETCH_BOM_ELEMENT_ATTACHMENTS_REQUESTED'
export const FETCH_BOM_ELEMENT_ATTACHMENTS_SUCCEEDED = 'FETCH_BOM_ELEMENT_ATTACHMENTS_SUCCEEDED'
export const FETCH_BOM_ELEMENT_ATTACHMENTS_ERROR = 'FETCH_BOM_ELEMENT_ATTACHMENTS_ERROR'

export const UPLOAD_BOM_ELEMENT_ATTACHMENT_REQUESTED = 'UPLOAD_BOM_ELEMENT_ATTACHMENT_REQUESTED'
export const UPLOAD_BOM_ELEMENT_ATTACHMENT_SUCCEEDED = 'UPLOAD_BOM_ELEMENT_ATTACHMENT_SUCCEEDED'
export const UPLOAD_BOM_ELEMENT_ATTACHMENT_ERROR = 'UPLOAD_BOM_ELEMENT_ATTACHMENT_ERROR'

export const DELETE_BOM_ELEMENT_ATTACHMENT_SUCCEEDED = 'DELETE_BOM_ELEMENT_ATTACHMENT_SUCCEEDED'

export const BOM_ELEMENT_ACTIONS_UNLOCK = 'BOM_ELEMENT_ACTIONS_UNLOCK'

export const OBomElementCategory = {
  1: 'Elektronika',
  2: 'Mechanika',
  3: 'Opakowanie',
  4: 'Akcesoria',
  5: 'Pozostałe',
}

export const bomElementCategoryOtherValue = 5

export type TBomElementCategoryValue =
  | 1
  | 2
  | 3
  | 4
  | 5
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | number
  | string

export interface IBomElementCategory {
  categoryName: string
  categoryValue: TBomElementCategoryValue
}

export type TBomElement = {
  name: string
  bomElementName: string
  producerId: number
  producerName?: string | null
  mpn: string
  symbolDin: string | null
  category: TBomElementCategoryValue
  type: 0 | 1

  description: string
  centimetersInPiece: number | null

  measurementType: number
  supplierInfos: TSupplier[] | null
  replacementBomElementId: number

  orders: TBomElementInOrder[] | null

  canDelete: boolean

  uuid: string
  id: number
}

export type TLightBomElement = {
  name: string
  producerName: string | null
  producerId: number
  symbolDin: string | null
  mpn: string | null

  // Those two values below are needed in BOM details view
  measurementType: 1 | 2
  centimetersInPiece: number | null

  canDelete: boolean

  id: number
}

export type TBomElementInOrder = {
  orderId: number
  createDate: string
  quantity: number
  bomElementPrice: number
  bomElementPriceCurrency: TCurrencyCode
  orderNumber: string
}

export type TBomWithBomElements = {
  bomElementDetails: IBomFullElement[]
  name: string
  id: number
}

export type TBomElementAttachmentUpload = IAttachmentUpload & {
  bomElementId: number
}

export type TBomElementAttachment = TAttachment & {
  bomElementId: number
}

export interface ICreateBomElementPayload extends TAdminPayload {
  bomElement: TBomElement
}

// FETCH_BOM_ELEMENTS

export interface fetchBomElementsAction {
  type: typeof FETCH_BOM_ELEMENTS_REQUESTED
  payload: TAdminPayload
}

export interface fetchBomElementsSucceeded {
  type: typeof FETCH_BOM_ELEMENTS_SUCCEEDED
  bomElements: TLightBomElement[]
}

export interface fetchBomElementsError {
  type: typeof FETCH_BOM_ELEMENTS_ERROR
  error: TNetworkError
}

// SET_BOM_ELEMENTS_TABLE_FILTER_STATE

export interface setBomElementsTableFilterState {
  type: typeof SET_BOM_ELEMENTS_TABLE_FILTER_STATE
  tableFilterState: TFilterState
}

// SET_BOM_ELEMENTS_TABLE_SORTER_STATE

export interface setBomElementsTableSorterState {
  type: typeof SET_BOM_ELEMENTS_TABLE_SORTER_STATE
  tableSorterState: TSorterState
}

// SET_EDITED_BOM_ELEMENT

export interface setEditedBomElement {
  type: typeof SET_EDITED_BOM_ELEMENT
  bomElement: TBomElement
}

// CREATE_BOM_ELEMENT

export interface createBomElementAction {
  type: typeof CREATE_BOM_ELEMENT_REQUESTED
  payload: ICreateBomElementPayload
}

export interface createBomElementSucceeded {
  type: typeof CREATE_BOM_ELEMENT_SUCCEEDED
  bomElement: TBomElement
}

export interface createBomElementError {
  type: typeof CREATE_BOM_ELEMENT_ERROR
  error: TNetworkError
}

// FETCH_SINGLE_BOM_ELEMENT

export interface fetchSingleBomElementAction {
  type: typeof FETCH_SINGLE_BOM_ELEMENT_REQUESTED
  payload: ISingleItemActionPayload
}

export interface fetchSingleBomElementSucceeded {
  type: typeof FETCH_SINGLE_BOM_ELEMENT_SUCCEEDED
  bomElement: TBomElement
}

export interface fetchSingleBomElementError {
  type: typeof FETCH_SINGLE_BOM_ELEMENT_ERROR
  error: TNetworkError
}

// FETCH_BOM_ELEMENT_BOMS

export interface fetchBomElementBomsAction {
  type: typeof FETCH_BOM_ELEMENT_BOMS_REQUESTED
  payload: ISingleItemActionPayload
}

export interface fetchBomElementBomsSucceeded {
  type: typeof FETCH_BOM_ELEMENT_BOMS_SUCCEEDED
  bomElementBoms: TBomLight[]
}

export interface fetchBomElementBomsError {
  type: typeof FETCH_BOM_ELEMENT_BOMS_ERROR
  error: TNetworkError
}

// FETCH_BOM_ELEMENT_OFFERS

export interface fetchBomElementOffersAction {
  type: typeof FETCH_BOM_ELEMENT_OFFERS_REQUESTED
  payload: ISingleItemActionPayload
}

export interface fetchBomElementOffersSucceeded {
  type: typeof FETCH_BOM_ELEMENT_OFFERS_SUCCEEDED
  bomElementOffers: TOffer[]
}

export interface fetchBomElementOffersError {
  type: typeof FETCH_BOM_ELEMENT_OFFERS_ERROR
  error: TNetworkError
}

// EDIT_BOM_ELEMENT

export interface editBomElementAction {
  type: typeof EDIT_BOM_ELEMENT_REQUESTED
  payload: ICreateBomElementPayload
}

export interface editBomElementSucceeded {
  type: typeof EDIT_BOM_ELEMENT_SUCCEEDED
  bomElement: TBomElement
}

export interface editBomElementError {
  type: typeof EDIT_BOM_ELEMENT_ERROR
  error: TNetworkError
}

// COPY_BOM_ELEMENT

export interface copyBomElementAction {
  type: typeof COPY_BOM_ELEMENT_REQUESTED
  payload: IEditItemOnListPayload
}

export interface copyBomElementSucceeded {
  type: typeof COPY_BOM_ELEMENT_SUCCEEDED
  bomElement: TLightBomElement
}

export interface copyBomElementError {
  type: typeof COPY_BOM_ELEMENT_ERROR
  error: TNetworkError
}

// ARCHIVE_BOM_ELEMENT

export interface archiveBomElementAction {
  type: typeof ARCHIVE_BOM_ELEMENT_REQUESTED
  payload: ISingleItemActionPayload
}

export interface archiveBomElementSucceeded {
  type: typeof ARCHIVE_BOM_ELEMENT_SUCCEEDED
  bomElement: TLightBomElement
}

export interface archiveBomElementError {
  type: typeof ARCHIVE_BOM_ELEMENT_ERROR
  error: TNetworkError
}

// DELETE_BOM_ELEMENT

export interface deleteBomElementAction {
  type: typeof DELETE_BOM_ELEMENT_REQUESTED
  payload: ISingleItemActionPayload
}

export interface deleteBomElementSucceeded {
  type: typeof DELETE_BOM_ELEMENT_SUCCEEDED
  id: number
}

export interface deleteBomElementError {
  type: typeof DELETE_BOM_ELEMENT_ERROR
  error: TNetworkError
}

// FETCH_BOM_ELEMENT_ATTACHMENTS

export interface fetchBomElementAttachmentsAction {
  type: typeof FETCH_BOM_ELEMENT_ATTACHMENTS_REQUESTED
  payload: IFetchItemAttachmentsPayload
}

export interface fetchBomElementAttachmentsSucceeded {
  type: typeof FETCH_BOM_ELEMENT_ATTACHMENTS_SUCCEEDED
  bomElementAttachments: TBomElementAttachment[]
}

export interface fetchBomElementAttachmentsError {
  type: typeof FETCH_BOM_ELEMENT_ATTACHMENTS_ERROR
  error: TNetworkError
}

// UPLOAD_BOM_ELEMENT_ATTACHMENT

export interface uploadBomElementAttachmentAction {
  type: typeof UPLOAD_BOM_ELEMENT_ATTACHMENT_REQUESTED
  payload: IUploadSingleAttachmentPayload
}

export interface uploadBomElementAttachmentSucceeded {
  type: typeof UPLOAD_BOM_ELEMENT_ATTACHMENT_SUCCEEDED
  attachment: TBomElementAttachment
}

export interface uploadBomElementAttachmentError {
  type: typeof UPLOAD_BOM_ELEMENT_ATTACHMENT_ERROR
  error: TNetworkError
}

// DELETE_BOM_ELEMENT_ATTACHMENT

export interface deleteBomElementAttachmentSucceeded {
  type: typeof DELETE_BOM_ELEMENT_ATTACHMENT_SUCCEEDED
  payload: ISingleItemActionPayload
}

export interface bomElementActionsUnlockAction {
  type: typeof BOM_ELEMENT_ACTIONS_UNLOCK
}

export type BomElementsActionTypes =
  | fetchBomElementsAction
  | fetchBomElementsSucceeded
  | fetchBomElementsError
  | setBomElementsTableFilterState
  | setBomElementsTableSorterState
  | setEditedBomElement
  | fetchSingleBomElementAction
  | fetchSingleBomElementError
  | fetchSingleBomElementSucceeded
  | fetchBomElementBomsAction
  | fetchBomElementBomsSucceeded
  | fetchBomElementBomsError
  | fetchBomElementOffersAction
  | fetchBomElementOffersSucceeded
  | fetchBomElementOffersError
  | createBomElementAction
  | createBomElementSucceeded
  | createBomElementError
  | editBomElementAction
  | editBomElementSucceeded
  | editBomElementError
  | copyBomElementAction
  | copyBomElementSucceeded
  | copyBomElementError
  | archiveBomElementAction
  | archiveBomElementSucceeded
  | archiveBomElementError
  | deleteBomElementAction
  | deleteBomElementSucceeded
  | deleteBomElementError
  | fetchBomElementAttachmentsAction
  | fetchBomElementAttachmentsSucceeded
  | fetchBomElementAttachmentsError
  | uploadBomElementAttachmentAction
  | uploadBomElementAttachmentSucceeded
  | uploadBomElementAttachmentError
  | deleteBomElementAttachmentSucceeded
  | bomElementActionsUnlockAction
