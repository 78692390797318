import * as actionTypes from '../action-types'
import { TUser } from '../action-types'

export interface IUsersState {
  users: TUser[] | []
  areUsersLoading: boolean
  fetchUsersError: actionTypes.TNetworkError | null

  tableSorterState: actionTypes.TSorterState | undefined
  tableFilterState: actionTypes.TFilterState

  isUserCreating: boolean
  userCreateError: actionTypes.TNetworkError
  isUserCreated: boolean

  isSingleUserLoading: boolean
  editedUser: actionTypes.TUser | null
  singleUserFetchError: actionTypes.TNetworkError

  isUserEditSaving: boolean
  userEditSaveError: actionTypes.TNetworkError
  isUserEditSaved: boolean

  currentlyManagedUserId: number | null

  isUserDeleting: boolean
  isUserDeleted: boolean
  userDeleteError: actionTypes.TNetworkError

  profiles: actionTypes.TUserProfile[]
  areProfilesLoading: boolean
  fetchProfilesError: actionTypes.TNetworkError | null

  areProfilesSaving: boolean
  areProfilesSaved: boolean
  profilesSaveError: actionTypes.TNetworkError
}

const initialState: IUsersState = {
  users: [],
  areUsersLoading: false,
  fetchUsersError: null,

  tableSorterState: undefined,
  tableFilterState: undefined,

  isUserCreating: false,
  userCreateError: null,
  isUserCreated: false,

  isSingleUserLoading: false,
  editedUser: null,
  singleUserFetchError: null,

  isUserEditSaving: false,
  userEditSaveError: null,
  isUserEditSaved: false,

  currentlyManagedUserId: null,
  isUserDeleting: false,
  isUserDeleted: false,
  userDeleteError: null,

  profiles: [],
  areProfilesLoading: false,
  fetchProfilesError: null,

  areProfilesSaving: false,
  areProfilesSaved: false,
  profilesSaveError: null,
}

export default function usersReducer(
  state = initialState,
  action: actionTypes.UsersActionTypes
): IUsersState {
  switch (action.type) {
    case actionTypes.FETCH_USERS_REQUESTED: {
      return {
        ...state,
        fetchUsersError: null,
        areUsersLoading: true,
      }
    }

    case actionTypes.FETCH_USERS_SUCCEEDED: {
      return {
        ...state,
        fetchUsersError: null,
        areUsersLoading: false,
        users: action.users,
      }
    }

    case actionTypes.FETCH_USERS_ERROR: {
      return {
        ...state,
        fetchUsersError: action.error,
        areUsersLoading: false,
      }
    }

    case actionTypes.SET_USERS_TABLE_FILTER_STATE: {
      return {
        ...state,
        tableFilterState: action.tableFilterState,
      }
    }

    case actionTypes.SET_USERS_TABLE_SORTER_STATE: {
      return {
        ...state,
        tableSorterState: action.tableSorterState,
      }
    }

    case actionTypes.CREATE_USER_REQUESTED: {
      return {
        ...state,
        userCreateError: null,
        isUserCreating: true,
        isUserCreated: false,
      }
    }

    case actionTypes.CREATE_USER_SUCCEEDED: {
      return {
        ...state,
        isUserCreating: false,
        users: [...state.users, action.user],
        isUserCreated: true,
      }
    }

    case actionTypes.CREATE_USER_ERROR: {
      return {
        ...state,
        userCreateError: action.error,
        isUserCreating: false,
        isUserCreated: true,
      }
    }

    case actionTypes.FETCH_SINGLE_USER_REQUESTED: {
      return {
        ...state,
        isSingleUserLoading: true,
        singleUserFetchError: null,
      }
    }

    case actionTypes.FETCH_SINGLE_USER_SUCCEEDED: {
      return {
        ...state,
        isSingleUserLoading: false,
        editedUser: action.user,
      }
    }

    case actionTypes.FETCH_SINGLE_USER_ERROR: {
      return {
        ...state,
        singleUserFetchError: action.error,
        isSingleUserLoading: false,
        isUserEditSaving: false,
      }
    }

    case actionTypes.EDIT_USER_REQUESTED: {
      return {
        ...state,
        userEditSaveError: null,
        isUserEditSaving: true,
        isUserEditSaved: false,
      }
    }

    case actionTypes.EDIT_USER_SUCCEEDED: {
      return {
        ...state,
        isUserEditSaving: false,
        isUserEditSaved: true,
        users: state.users?.map((user: actionTypes.TUser) => {
          if (user.userId === action.user.userId) {
            return action.user
          } else {
            return user
          }
        }),
      }
    }

    case actionTypes.EDIT_USER_ERROR: {
      return {
        ...state,
        userEditSaveError: action.error,
        isUserEditSaving: false,
        isUserEditSaved: false,
      }
    }

    case actionTypes.DELETE_USER_REQUESTED: {
      return {
        ...state,
        userDeleteError: null,
        isUserDeleting: true,
        isUserDeleted: false,
        currentlyManagedUserId: action.payload.id,
      }
    }

    case actionTypes.DELETE_USER_SUCCEEDED: {
      return {
        ...state,
        isUserDeleting: false,
        isUserDeleted: true,
        users: state.users.filter((user: actionTypes.TUser) => user.userId !== user.userId),
        currentlyManagedUserId: null,
      }
    }

    case actionTypes.DELETE_USER_ERROR: {
      return {
        ...state,
        userDeleteError: action.error,
        isUserDeleting: false,
        isUserDeleted: false,
        currentlyManagedUserId: null,
      }
    }

    case actionTypes.USER_ACTIONS_UNLOCK: {
      return {
        ...state,
        isUserCreating: false,
        isUserEditSaving: false,
        isUserDeleting: false,

        isUserCreated: false,
        isUserEditSaved: false,
      }
    }

    case actionTypes.CLOSE_USER_DETAILS: {
      return {
        ...state,
        isUserCreating: false,
        isUserEditSaving: false,

        isUserCreated: false,
        isUserEditSaved: false,

        editedUser: null,
      }
    }

    /*
     * PROFILES
     */

    case actionTypes.FETCH_PROFILES_REQUESTED: {
      return {
        ...state,
        fetchProfilesError: null,
        areProfilesLoading: true,
      }
    }

    case actionTypes.FETCH_PROFILES_SUCCEEDED: {
      return {
        ...state,
        fetchProfilesError: null,
        areProfilesLoading: false,
        profiles: action.profiles,
      }
    }

    case actionTypes.FETCH_PROFILES_ERROR: {
      return {
        ...state,
        fetchProfilesError: action.error,
        areProfilesLoading: false,
      }
    }

    case actionTypes.SAVE_PROFILES_REQUESTED: {
      return {
        ...state,
        profilesSaveError: null,
        areProfilesSaving: true,
        areProfilesSaved: false,
      }
    }

    case actionTypes.SAVE_PROFILES_SUCCEEDED: {
      return {
        ...state,
        areProfilesSaving: false,
        profiles: action.profiles,
        areProfilesSaved: true,
        profilesSaveError: null,
      }
    }

    case actionTypes.SAVE_PROFILES_ERROR: {
      return {
        ...state,
        profilesSaveError: action.error,
        areProfilesSaving: false,
        areProfilesSaved: false,
      }
    }

    case actionTypes.PROFILES_ACTIONS_UNLOCK: {
      return {
        ...state,
        areProfilesSaving: false,
        areProfilesSaved: false,
      }
    }

    default:
      return state
  }
}
