export type TCountry = {
  symbol: string
  polishName: string
  englishName: string
}

// Source: https://stat.gov.pl/badania-statystyczne/sprawozdawczosc/intrastat/alfabetyczny-wykaz-krajow/#
export const countries: TCountry[] = [
  { symbol: 'AF', polishName: 'Afganistan', englishName: 'Afghanistan' },
  { symbol: 'AL', polishName: 'Albania', englishName: 'Albania' },
  { symbol: 'DZ', polishName: 'Algieria', englishName: 'Algeria' },
  { symbol: 'AD', polishName: 'Andora', englishName: 'Andorra' },
  { symbol: 'AO', polishName: 'Angola', englishName: 'Angola' },
  { symbol: 'AI', polishName: 'Anguilla', englishName: 'Anguilla' },
  { symbol: 'AQ', polishName: 'Antarktyda', englishName: 'Antarctica' },
  { symbol: 'AG', polishName: 'Antigua i Barbuda', englishName: 'Antigua and Barbuda' },
  { symbol: 'SA', polishName: 'Arabia Saudyjska', englishName: 'Saudi Arabia' },
  { symbol: 'AR', polishName: 'Argentyna', englishName: 'Argentina' },
  { symbol: 'AM', polishName: 'Armenia', englishName: 'Armenia' },
  { symbol: 'AW', polishName: 'Aruba', englishName: 'Aruba' },
  { symbol: 'AU', polishName: 'Australia', englishName: 'Australia' },
  { symbol: 'AT', polishName: 'Austria', englishName: 'Austria' },
  { symbol: 'AZ', polishName: 'Azerbejdżan', englishName: 'Azerbaijan' },
  { symbol: 'BS', polishName: 'Bahamy', englishName: 'Bahamas' },
  { symbol: 'BH', polishName: 'Bahrajn', englishName: 'Bahrain' },
  { symbol: 'BD', polishName: 'Bangladesz', englishName: 'Bangladesh' },
  { symbol: 'BB', polishName: 'Barbados', englishName: 'Barbados' },
  { symbol: 'BE', polishName: 'Belgia', englishName: 'Belgium' },
  { symbol: 'BZ', polishName: 'Belize', englishName: 'Belize' },
  { symbol: 'BJ', polishName: 'Benin', englishName: 'Benin' },
  { symbol: 'BM', polishName: 'Bermudy', englishName: 'Bermuda' },
  { symbol: 'BT', polishName: 'Bhutan', englishName: 'Bhutan' },
  { symbol: 'BY', polishName: 'Białoruś', englishName: 'Belarus' },
  { symbol: 'BO', polishName: 'Boliwia', englishName: 'Bolivia' },
  {
    symbol: 'BQ',
    polishName: 'Bonaire Sint Eustatius i Saba Bonaire',
    englishName: 'Sint Eustatius and Saba',
  },
  { symbol: 'BA', polishName: 'Bośnia i Hercegowina', englishName: 'Bosnia and Herzegovina' },
  { symbol: 'BW', polishName: 'Botswana', englishName: 'Botswana' },
  { symbol: 'BR', polishName: 'Brazylia', englishName: 'Brazil' },
  { symbol: 'BN', polishName: 'Brunei Darussalam', englishName: 'Brunei Darussalam' },
  {
    symbol: 'IO',
    polishName: 'Brytyjskie Terytorium Oceanu Indyjskiego',
    englishName: 'British Indian Ocean Territory',
  },
  { symbol: 'BG', polishName: 'Bułgaria', englishName: 'Bulgaria' },
  { symbol: 'BF', polishName: 'Burkina Faso', englishName: 'Burkina Faso' },
  { symbol: 'BI', polishName: 'Burundi', englishName: 'Burundi' },
  { symbol: 'XC', polishName: 'Ceuta', englishName: 'Ceuta' },
  { symbol: 'CL', polishName: 'Chile', englishName: 'Chile' },
  { symbol: 'CN', polishName: 'Chiny', englishName: 'China' },
  { symbol: 'CW', polishName: 'Curaçao', englishName: 'Curaçao' },
  { symbol: 'HR', polishName: 'Chorwacja', englishName: 'Croatia' },
  { symbol: 'CY', polishName: 'Cypr', englishName: 'Cyprus' },
  { symbol: 'TD', polishName: 'Czad', englishName: 'Chad' },
  { symbol: 'ME', polishName: 'Czarnogóra', englishName: 'Montenegro' },
  { symbol: 'CZ', polishName: 'Czechy', englishName: 'Czechia' },
  { symbol: 'DK', polishName: 'Dania', englishName: 'Denmark' },
  { symbol: 'DM', polishName: 'Dominika', englishName: 'Dominica' },
  { symbol: 'DO', polishName: 'Dominikana', englishName: 'Dominican Republic' },
  { symbol: 'DJ', polishName: 'Dżibuti', englishName: 'Djibouti' },
  { symbol: 'EG', polishName: 'Egipt', englishName: 'Egypt' },
  { symbol: 'EC', polishName: 'Ekwador', englishName: 'Ecuador' },
  { symbol: 'ER', polishName: 'Erytrea', englishName: 'Eritrea' },
  { symbol: 'EE', polishName: 'Estonia', englishName: 'Estonia' },
  { symbol: 'ET', polishName: 'Etiopia', englishName: 'Ethiopia' },
  { symbol: 'FK', polishName: 'Falklandy', englishName: 'Falkland Islands (Malvinas)' },
  { symbol: 'FJ', polishName: 'Fidżi', englishName: 'Fiji' },
  { symbol: 'PH', polishName: 'Filipiny', englishName: 'Philippines' },
  { symbol: 'FI', polishName: 'Finlandia', englishName: 'Finland' },
  {
    symbol: 'TF',
    polishName: 'Francuskie Terytorium Południowe',
    englishName: 'French Southern Territories',
  },
  { symbol: 'FR', polishName: 'Francja', englishName: 'France' },
  { symbol: 'MC', polishName: 'Monako', englishName: 'Monaco' },
  { symbol: 'GF', polishName: 'Gujana Francuska', englishName: 'French Guyana' },
  { symbol: 'GP', polishName: 'Gwadelupa', englishName: 'Guadeloupe' },
  { symbol: 'MQ', polishName: 'Martynika', englishName: 'Martynique' },
  { symbol: 'RE', polishName: 'Reunion', englishName: 'Reunion' },
  {
    symbol: 'MF',
    polishName: 'Wspólnota Saint-Martin',
    englishName: 'Collectivity of Saint Martin',
  },
  { symbol: 'GA', polishName: 'Gabon', englishName: 'Gabon' },
  { symbol: 'GM', polishName: 'Gambia', englishName: 'Gambia' },
  { symbol: 'GH', polishName: 'Ghana', englishName: 'Ghana' },
  { symbol: 'GI', polishName: 'Gibraltar', englishName: 'Gibraltar' },
  { symbol: 'GR', polishName: 'Grecja', englishName: 'Greece' },
  { symbol: 'GD', polishName: 'Grenada', englishName: 'Grenada' },
  { symbol: 'GL', polishName: 'Grenlandia', englishName: 'Greenland' },
  { symbol: 'GE', polishName: 'Gruzja', englishName: 'Georgia' },
  { symbol: 'GU', polishName: 'Guam', englishName: 'Guam' },
  { symbol: 'GY', polishName: 'Gujana', englishName: 'Guyana' },
  { symbol: 'GT', polishName: 'Gwatemala', englishName: 'Guatemala' },
  { symbol: 'GN', polishName: 'Gwinea', englishName: 'Guinea' },
  { symbol: 'GQ', polishName: 'Gwinea', englishName: 'Równikowa Equatorial Guinea' },
  { symbol: 'GW', polishName: 'Gwinea-Bissau', englishName: 'Guinea-Bissau' },
  { symbol: 'HT', polishName: 'Haiti', englishName: 'Haiti' },
  { symbol: 'ES', polishName: 'Hiszpania', englishName: 'Spain' },
  { symbol: 'HN', polishName: 'Honduras', englishName: 'Honduras' },
  { symbol: 'HK', polishName: 'Hongkong', englishName: 'Hong Kong' },
  { symbol: 'IN', polishName: 'Indie', englishName: 'India' },
  { symbol: 'ID', polishName: 'Indonezja', englishName: 'Indonesia' },
  { symbol: 'IQ', polishName: 'Irak', englishName: 'Iraq' },
  { symbol: 'IR', polishName: 'Iran', englishName: 'Islamic Republic of Iran' },
  { symbol: 'IE', polishName: 'Irlandia', englishName: 'Ireland' },
  { symbol: 'IS', polishName: 'Islandia', englishName: 'Iceland' },
  { symbol: 'IL', polishName: 'Izrael', englishName: 'Israel' },
  { symbol: 'JM', polishName: 'Jamajka', englishName: 'Jamaica' },
  { symbol: 'JP', polishName: 'Japonia', englishName: 'Japan' },
  { symbol: 'YE', polishName: 'Jemen', englishName: 'Yemen' },
  { symbol: 'JO', polishName: 'Jordania', englishName: 'Jordan' },
  { symbol: 'KY', polishName: 'Kajmany', englishName: 'Cayman Islands' },
  { symbol: 'KH', polishName: 'Kambodża', englishName: 'Cambodia' },
  { symbol: 'CM', polishName: 'Kamerun', englishName: 'Cameroon' },
  { symbol: 'CA', polishName: 'Kanada', englishName: 'Canada' },
  { symbol: 'QA', polishName: 'Katar', englishName: 'Qatar' },
  { symbol: 'KZ', polishName: 'Kazachstan', englishName: 'Kazakhstan' },
  { symbol: 'KE', polishName: 'Kenia', englishName: 'Kenya' },
  { symbol: 'KG', polishName: 'Kirgistan', englishName: 'Kyrgyztan' },
  { symbol: 'KI', polishName: 'Kiribati', englishName: 'Kiribati' },
  { symbol: 'CO', polishName: 'Kolumbia', englishName: 'Colombia' },
  { symbol: 'KM', polishName: 'Komory', englishName: 'Comoros' },
  { symbol: 'CG', polishName: 'Republika Konga', englishName: 'Congo (Rep.)' },
  { symbol: 'CD', polishName: 'Demokratyczna Republika Konga', englishName: 'Congo (Dem. Rep.)' },
  {
    symbol: 'KP',
    polishName: 'Koreańska Republika Ludowo-Demokratyczna',
    englishName: 'Democratic People’s Republic of Korea',
  },
  { symbol: 'XK', polishName: 'Kosowo', englishName: 'Kosovo' },
  { symbol: 'CR', polishName: 'Kostaryka', englishName: 'Costa Rica' },
  {
    symbol: 'QV',
    polishName: 'Kraje i terytoria niewyszczególnione w ramach handlu wewnątrzunijnego',
    englishName: 'Countries and territories not specified in the framework of intra-EU trade',
  },
  { symbol: 'CU', polishName: 'Kuba', englishName: 'Cuba' },
  { symbol: 'KW', polishName: 'Kuwejt', englishName: 'Kuwait' },
  { symbol: 'LA', polishName: 'Laos', englishName: 'People’s Democratic Republic of Lao' },
  { symbol: 'LS', polishName: 'Lesotho', englishName: 'Lesotho' },
  { symbol: 'LB', polishName: 'Liban', englishName: 'Lebanon' },
  { symbol: 'LR', polishName: 'Liberia', englishName: 'Liberia' },
  { symbol: 'LY', polishName: 'Libia', englishName: 'Libyan Arab Jamahiriya' },
  { symbol: 'LI', polishName: 'Liechtenstein', englishName: 'Liechtenstein' },
  { symbol: 'LT', polishName: 'Litwa', englishName: 'Lithuania' },
  { symbol: 'LU', polishName: 'Luksemburg', englishName: 'Luxembourg' },
  { symbol: 'LV', polishName: 'Łotwa', englishName: 'Latvia' },
  { symbol: 'MK', polishName: 'Macedonia Północna', englishName: 'North Macedonia' },
  { symbol: 'MG', polishName: 'Madagaskar', englishName: 'Madagascar' },
  { symbol: 'YT', polishName: 'Majotta', englishName: 'Mayotte' },
  { symbol: 'MO', polishName: 'Makau', englishName: 'Macao' },
  { symbol: 'MW', polishName: 'Malawi', englishName: 'Malawi' },
  { symbol: 'MV', polishName: 'Malediwy', englishName: 'Maldives' },
  { symbol: 'MY', polishName: 'Malezja', englishName: 'Malaysia' },
  { symbol: 'ML', polishName: 'Mali', englishName: 'Mali' },
  { symbol: 'MT', polishName: 'Malta', englishName: 'Malta' },
  { symbol: 'MP', polishName: 'Mariany Północne', englishName: 'Northern Mariana Islands' },
  { symbol: 'MA', polishName: 'Maroko', englishName: 'Morocco' },
  { symbol: 'MR', polishName: 'Mauretania', englishName: 'Mauritania' },
  { symbol: 'MU', polishName: 'Mauritius', englishName: 'Mauritius' },
  { symbol: 'MX', polishName: 'Meksyk', englishName: 'Mexico' },
  { symbol: 'XL', polishName: 'Melilla', englishName: 'Melilla' },
  { symbol: 'FM', polishName: 'Mikronezja', englishName: 'Federal States of Micronesia' },
  {
    symbol: 'UM',
    polishName: 'Minor (Powiernicze Wyspy Pacyfiku Stanów Zjednoczonych)',
    englishName: 'U.S. Minor Outlying Islands',
  },
  { symbol: 'MD', polishName: 'Mołdawia', englishName: 'Republic of Moldova' },
  { symbol: 'MN', polishName: 'Mongolia', englishName: 'Mongolia' },
  { symbol: 'MS', polishName: 'Montserrat', englishName: 'Montserrat' },
  { symbol: 'MZ', polishName: 'Mozambik', englishName: 'Mozambique' },
  { symbol: 'MM', polishName: 'Myanmar (Birma)', englishName: 'Myanmar (Burma)' },
  { symbol: 'NA', polishName: 'Namibia', englishName: 'Namibia' },
  { symbol: 'NR', polishName: 'Nauru', englishName: 'Nauru' },
  { symbol: 'NP', polishName: 'Nepal', englishName: 'Nepal' },
  { symbol: 'NL', polishName: 'Niderlandy (Holandia)', englishName: 'Netherlands (Holland)' },
  { symbol: 'DE', polishName: 'Niemcy', englishName: 'Germany' },
  { symbol: 'NE', polishName: 'Niger', englishName: 'Niger' },
  { symbol: 'NG', polishName: 'Nigeria', englishName: 'Nigeria' },
  { symbol: 'NI', polishName: 'Nikaragua', englishName: 'Nicaragua' },
  { symbol: 'NU', polishName: 'Niue', englishName: 'Niue' },
  { symbol: 'NF', polishName: 'Norfolk', englishName: 'Norfolk Island' },
  { symbol: 'NO', polishName: 'Norwegia', englishName: 'Norway' },
  { symbol: 'NC', polishName: 'Nowa Kaledonia', englishName: 'New Caledonia' },
  { symbol: 'NZ', polishName: 'Nowa Zelandia', englishName: 'New Zealand' },
  {
    symbol: 'PS',
    polishName: 'Okupowane Terytorium Palestyny',
    englishName: 'Occupied Palestinian Territory',
  },
  { symbol: 'OM', polishName: 'Oman', englishName: 'Oman' },
  { symbol: 'PK', polishName: 'Pakistan', englishName: 'Pakistan' },
  { symbol: 'PW', polishName: 'Palau', englishName: 'Palau' },
  { symbol: 'PA', polishName: 'Panama', englishName: 'Panama' },
  { symbol: 'PG', polishName: 'Papua Nowa Gwinea', englishName: 'Papua New Guinea' },
  { symbol: 'PY', polishName: 'Paragwaj', englishName: 'Paraguay' },
  { symbol: 'PE', polishName: 'Peru', englishName: 'Peru' },
  { symbol: 'PN', polishName: 'Pitcairn', englishName: 'Pitcairn' },
  { symbol: 'PF', polishName: 'Polinezja Francuska', englishName: 'French Polynesia' },
  { symbol: 'PL', polishName: 'Polska', englishName: 'Poland' },
  { symbol: 'ZA', polishName: 'Południowa Afryka', englishName: 'South Africa' },
  {
    symbol: 'GS',
    polishName: 'Południowa Georgia i Południowe Wyspy Sandwich',
    englishName: 'Georgia and South Sandwich Islands',
  },
  { symbol: 'PT', polishName: 'Portugalia', englishName: 'Portugal' },
  { symbol: 'KR', polishName: 'Republika Korei', englishName: 'Republic of Korea' },
  {
    symbol: 'CF',
    polishName: 'Republika Środkowoafryańska',
    englishName: 'Central African Republic',
  },
  { symbol: 'RU', polishName: 'Rosja', englishName: 'Russian Federation' },
  { symbol: 'RW', polishName: 'Rwanda', englishName: 'Rwanda' },
  { symbol: 'EH', polishName: 'Sahara Zachodnia', englishName: 'Western Sahara' },
  { symbol: 'BL', polishName: 'Saint Barthelemy', englishName: 'Saint Barthelemy' },
  { symbol: 'RO', polishName: 'Rumunia', englishName: 'Romania' },
  { symbol: 'SV', polishName: 'Salwador', englishName: 'EI Salvador' },
  { symbol: 'WS', polishName: 'Samoa', englishName: 'Samoa' },
  { symbol: 'AS', polishName: 'Samoa Amerykańskie', englishName: 'American Samoa' },
  { symbol: 'SM', polishName: 'San Marino', englishName: 'San Marino' },
  { symbol: 'SN', polishName: 'Senegal', englishName: 'Senegal' },
  { symbol: 'XS', polishName: 'Serbia', englishName: 'Serbia' },
  { symbol: 'SC', polishName: 'Seszele', englishName: 'Seychelles' },
  { symbol: 'SL', polishName: 'Sierra Leone', englishName: 'Sierra Leone' },
  { symbol: 'SG', polishName: 'Singapur', englishName: 'Singapore' },
  { symbol: 'SZ', polishName: 'Suazi', englishName: 'Swaziland' },
  { symbol: 'SK', polishName: 'Słowacja', englishName: 'Slovakia' },
  { symbol: 'SI', polishName: 'Słowenia', englishName: 'Slovenia' },
  { symbol: 'SO', polishName: 'Somalia', englishName: 'Somalia' },
  { symbol: 'LK', polishName: 'Sri Lanka', englishName: 'Sri Lanka' },
  { symbol: 'PM', polishName: 'St. Pierre i Miquelon', englishName: 'St. Pierre and Miquelon' },
  { symbol: 'KN', polishName: 'St. Kitts i Nevis', englishName: 'St. Kittsand and Nevis' },
  { symbol: 'LC', polishName: 'St. Lucia', englishName: 'St. Lucia' },
  {
    symbol: 'VC',
    polishName: 'St. Vincent i Grenadyny',
    englishName: 'St. Vincent and the Grenadines',
  },
  {
    symbol: 'US',
    polishName: 'Stany Zjednoczone Ameryki (USA)',
    englishName: 'United States of America (USA)',
  },
  { symbol: 'PR', polishName: 'Portoryko', englishName: 'Portorico' },
  { symbol: 'SD', polishName: 'Sudan', englishName: 'Sudan' },
  { symbol: 'SS', polishName: 'Sudan Południowy', englishName: 'South Sudan' },
  { symbol: 'SR', polishName: 'Surinam', englishName: 'Suriname' },
  { symbol: 'SY', polishName: 'Syria', englishName: 'Syrian Arab Republic' },
  { symbol: 'CH', polishName: 'Szwajcaria', englishName: 'Switzerland' },
  { symbol: 'SE', polishName: 'Szwecja', englishName: 'Sweden' },
  { symbol: 'SH', polishName: 'Święta Helena', englishName: 'St. Helena' },
  { symbol: 'TJ', polishName: 'Tadżykistan', englishName: 'Tajikistan' },
  { symbol: 'TH', polishName: 'Tajlandia', englishName: 'Thailand' },
  { symbol: 'TW', polishName: 'Tajwan', englishName: 'Taiwan' },
  { symbol: 'TZ', polishName: 'Tanzania', englishName: 'United Republic of Tanzania' },
  { symbol: 'TG', polishName: 'Togo', englishName: 'Togo' },
  { symbol: 'TK', polishName: 'Tokelau', englishName: 'Tokelau' },
  { symbol: 'TO', polishName: 'Tonga', englishName: 'Tonga' },
  { symbol: 'TT', polishName: 'Trynidad i Tobago', englishName: 'Trinidad and Tobago' },
  { symbol: 'TN', polishName: 'Tunezja', englishName: 'Tunisia' },
  { symbol: 'TR', polishName: 'Turcja', englishName: 'Turkey' },
  { symbol: 'TM', polishName: 'Turkmenistan', englishName: 'Turkmenistan' },
  { symbol: 'TC', polishName: 'Wyspy Turks i Caicos', englishName: 'Turks and Caicos Islands' },
  { symbol: 'TV', polishName: 'Tuvalu', englishName: 'Tuvalu' },
  { symbol: 'UG', polishName: 'Uganda', englishName: 'Uganda' },
  { symbol: 'UA', polishName: 'Ukraina', englishName: 'Ukraine' },
  { symbol: 'UY', polishName: 'Urugwaj', englishName: 'Uruguay' },
  { symbol: 'UZ', polishName: 'Uzbekistan', englishName: 'Uzbekistan' },
  { symbol: 'VU', polishName: 'Vanuatu', englishName: 'Vanuatu' },
  { symbol: 'WF', polishName: 'Wallis i Futuna', englishName: 'Wallis and Futuna Islands' },
  { symbol: 'VA', polishName: 'Watykan', englishName: 'Vatican City State' },
  { symbol: 'VE', polishName: 'Wenezuela', englishName: 'Venezuela' },
  { symbol: 'HU', polishName: 'Węgry', englishName: 'Hungary' },
  { symbol: 'GB', polishName: 'Wielka Brytania', englishName: 'United Kingdom' },
  { symbol: 'VN', polishName: 'Wietnam', englishName: 'Viet - Nam' },
  { symbol: 'IT', polishName: 'Włochy', englishName: 'Italy' },
  { symbol: 'TL', polishName: 'Wschodni Timor', englishName: 'Timor Leste' },
  { symbol: 'CI', polishName: 'Wybrzeże Kości Słoniowej', englishName: 'Cote D’Ivoire' },
  { symbol: 'BV', polishName: 'Wyspa Bouveta', englishName: 'Bouvet Island' },
  { symbol: 'CX', polishName: 'Wyspa Bożego Narodzenia', englishName: 'Christmas Islands' },
  { symbol: 'CK', polishName: 'Wyspy Cooka', englishName: 'Cook Islands' },
  { symbol: 'VI', polishName: 'Wyspy Dziewicze-USA', englishName: 'Virgin Islands (USA)' },
  {
    symbol: 'VG',
    polishName: 'Wyspy Dziewicze-Brytyjskie',
    englishName: 'Virgin Islands (British)',
  },
  { symbol: 'HM', polishName: 'Wyspy Heard i McDonald', englishName: 'Heard and McDonald Islands' },
  { symbol: 'CC', polishName: 'Wyspy Kokosowe (Keelinga)', englishName: 'Cocos (Keeling) Islands' },
  { symbol: 'FO', polishName: 'Wyspy Owcze', englishName: 'Faroe Islands' },
  { symbol: 'MH', polishName: 'Wyspy Marshalla', englishName: 'Marshall Islands' },
  { symbol: 'SB', polishName: 'Wyspy Salomona', englishName: 'Solomon Islands' },
  {
    symbol: 'SX',
    polishName: 'Wyspa Sint Maarten (część holenderska wyspy)',
    englishName: 'Sint Maarten (Dutch part)',
  },
  { symbol: 'ST', polishName: 'Wyspy Św.Tomasza i Książęca', englishName: 'Sao Tome and Principe' },
  { symbol: 'ZM', polishName: 'Zambia', englishName: 'Zambia' },
  { symbol: 'CV', polishName: 'Zielony Przylądek', englishName: 'Cape Verde' },
  { symbol: 'ZW', polishName: 'Zimbabwe', englishName: 'Zimbabwe' },
  { symbol: 'AE', polishName: 'Zjednoczone Emiraty Arabskie', englishName: 'United Arab Emirates' },
  {
    symbol: 'XI',
    polishName: 'Zjednoczone Królestwo (Irlandia Północna)',
    englishName: 'United Kingdom (Northern Ireland',
  },
]
