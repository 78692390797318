import { useSelector } from 'react-redux'

import { TUserRoleSpecificReadWrite } from '../store/action-types'
import { TRootState } from '../store/reducers'

export const toolingIconName: string = 'cil-crop-rotate'
export const serviceIconName: string = 'cil-paint'

export const sidebarNavigation = (
  tenant: string,
  roles: TUserRoleSpecificReadWrite[] | undefined
) => {
  let sidebarArray: any = []

  const warehousesState = useSelector((state: TRootState) => state.warehouses)

  if (
    roles?.includes('Suppliers_read') ||
    roles?.includes('BomElements_read') ||
    roles?.includes('Offers_read') ||
    roles?.includes('Boms_read') ||
    roles?.includes('PurchasePlans_read') ||
    roles?.includes('Orders_read')
  ) {
    sidebarArray.push({
      _tag: 'CSidebarNavTitle',
      _children: ['Logistyka'],
    })

    if (roles?.includes('Suppliers_read')) {
      sidebarArray.push({
        _tag: 'CSidebarNavItem',
        name: 'Dostawcy',
        to: `/${tenant}/admin/suppliers`,
        fontIcon: 'cil-address-book',
      })
    }

    if (roles?.includes('BomElements_read')) {
      sidebarArray.push({
        _tag: 'CSidebarNavItem',
        name: 'Elementy',
        to: `/${tenant}/admin/bom-elements`,
        fontIcon: 'cil-sitemap',
      })
    }

    if (roles?.includes('Offers_read')) {
      sidebarArray.push({
        _tag: 'CSidebarNavItem',
        name: 'Oferty',
        to: `/${tenant}/admin/offers`,
        fontIcon: 'cil-money',
      })
    }

    if (roles?.includes('Boms_read')) {
      sidebarArray.push({
        _tag: 'CSidebarNavItem',
        name: 'Lista BOM',
        to: `/${tenant}/admin/boms`,
        fontIcon: 'cil-list-rich',
      })
    }

    if (roles?.includes('PurchasePlans_read')) {
      sidebarArray.push({
        _tag: 'CSidebarNavItem',
        name: 'Zapotrzebowania',
        to: `/${tenant}/admin/purchase-plans`,
        fontIcon: 'cil-gradient',
      })
    }

    if (roles?.includes('Orders_read')) {
      sidebarArray.push({
        _tag: 'CSidebarNavItem',
        name: 'Zamówienia',
        to: `/${tenant}/admin/orders`,
        fontIcon: 'cil-truck',
      })
    }
  }

  if (
    roles?.includes('Producers_read') ||
    roles?.includes('ImplementationCosts_read') ||
    roles?.includes('Services_read')
  ) {
    sidebarArray.push({
      _tag: 'CSidebarNavTitle',
      _children: ['Słowniki'],
    })

    if (roles?.includes('Producers_read')) {
      sidebarArray.push({
        _tag: 'CSidebarNavItem',
        name: 'Producenci',
        to: `/${tenant}/admin/producers`,
        fontIcon: 'cil-factory',
      })
    }

    if (roles?.includes('ImplementationCosts_read')) {
      sidebarArray.push({
        _tag: 'CSidebarNavItem',
        name: 'Tooling',
        to: `/${tenant}/admin/implementation-costs`,
        fontIcon: toolingIconName,
      })
    }

    if (roles?.includes('Services_read')) {
      sidebarArray.push({
        _tag: 'CSidebarNavItem',
        name: 'Usługi',
        to: `/${tenant}/admin/services`,
        fontIcon: serviceIconName,
      })
    }
  }

  if (roles?.includes('Warehouses_read')) {
    sidebarArray.push({
      _tag: 'CSidebarNavTitle',
      _children: ['Magazyny'],
    })

    if (roles?.includes('Warehouses_read')) {
      sidebarArray.push({
        _tag: 'CSidebarNavItem',
        name: 'Magazyny',
        to: `/${tenant}/admin/warehouses`,
        fontIcon: 'cil-storage',
      })

      // sidebarArray.push({
      //   _tag: 'CSidebarNavItem',
      //   name: '?Gdańsk',
      //   to: `/${tenant}/admin/warehouse-management/3`,
      //   fontIcon: 'cib-server-fault',
      // })

      sidebarArray.push({
        _tag: 'CSidebarNavItem',
        name: 'Inwentaryzacja',
        to: `/${tenant}/admin/warehouse-management/${
          warehousesState?.warehouses[0]?.id || 6
        }/inventory-control`,
        fontIcon: 'cil-list',
      })

      sidebarArray.push({
        _tag: 'CSidebarNavItem',
        name: 'Stany magazynowe',
        to: `/${tenant}/admin/inventories-of-warehouses`,
        fontIcon: 'cil-chart',
      })

      sidebarArray.push({
        _tag: 'CSidebarNavItem',
        name: 'Przesunięcie',
        to: `/${tenant}/admin/warehouse-management/${
          warehousesState?.warehouses[0]?.id || 6
        }/move-replenishment`,
        fontIcon: 'cil-swap-horizontal',
      })

      sidebarArray.push({
        _tag: 'CSidebarNavItem',
        name: 'Magazyn R&D',
        to: `/${tenant}/admin/warehouse-management/7`,
        fontIcon: 'cib-electron',
      })
    }
  }

  if (roles?.includes('AuditLogs_read') || roles?.includes('Users_read')) {
    sidebarArray.push({
      _tag: 'CSidebarNavTitle',
      _children: ['Administracja'],
    })

    if (roles?.includes('AuditLogs_read')) {
      sidebarArray.push({
        _tag: 'CSidebarNavItem',
        name: 'Audyt akcji',
        to: `/${tenant}/admin/logs`,
        fontIcon: 'cil-vector',
      })
    }

    if (roles?.includes('Users_read')) {
      sidebarArray.push({
        _tag: 'CSidebarNavItem',
        name: 'Użytkownicy',
        to: `/${tenant}/admin/users`,
        fontIcon: 'cil-people',
      })
    }
  }

  return sidebarArray
}
