import { useEffect, useState } from 'react'
import { PieChart, Pie, Tooltip, Cell } from 'recharts'

import * as actionTypes from '../../store/action-types'

import {
  convertNumericStringToNumber,
  currencyExchanger,
  globalCurrencyFallback,
} from '../../utils'
import { useSelector } from 'react-redux'
import { TRootState } from '../../store/reducers'
import { offlineCurrencyExchangeRates } from '../admin'

type TPieChartData = {
  name: string
  quantity: number | null | undefined
  estimatedPrice: number | null | undefined
  color: string
}

export const BomDetailsGraphs = ({ values }) => {
  const adminState = useSelector((state: TRootState) => state.admin)
  const settingsState = useSelector((state: TRootState) => state.settings)

  const displayedCurrency =
    settingsState?.settings?.otherSettings?.defaultSystemCurrency || globalCurrencyFallback

  const exchangeRates = adminState.todayCurrencyExchange
    ? adminState.todayCurrencyExchange.rates
    : offlineCurrencyExchangeRates

  let [estimatedPriceOfElementsPieChart, setEstimatedPriceOfElementsPieChart] = useState<
    Array<TPieChartData>
  >([])

  useEffect(() => {
    const estimatedPriceOfElementsData: TPieChartData[] = values?.bomElementDetails?.map(
      (bomElement: actionTypes.IBomFullElement) => ({
        name: bomElement.bomElementName,
        quantity: bomElement.quantity,
        estimatedPrice: currencyExchanger(
          bomElement.bomElementMeasurementType === 2
            ? (Number(convertNumericStringToNumber(bomElement?.quantity)) /
                Number(convertNumericStringToNumber(bomElement?.bomElementCentimetersInPiece))) *
                Number(convertNumericStringToNumber(bomElement?.estimatedPrice))
            : Number(convertNumericStringToNumber(bomElement?.estimatedPrice)),
          bomElement.estimatedPriceCurrency,
          displayedCurrency,
          exchangeRates
        ),
        color: `rgb(${Math.floor(Math.random() * (200 + 1 - 50)) + 50}, ${
          Math.floor(Math.random() * (200 + 1 - 50)) + 50
        }, ${Math.floor(Math.random() * (255 + 1 - 50)) + 50}
        )`,
      })
    )

    setEstimatedPriceOfElementsPieChart(estimatedPriceOfElementsData)
    // The values can not be updated each time because the graph changes unexpectedly, it should only react to the bomElementDetails
    // eslint-disable-next-line
  }, [values.bomElementDetails, exchangeRates])

  return (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center mt-4">
        <h5 className="mt-2 mb-4 pb-2">Wykres szacunkowych cen elementów w {displayedCurrency}</h5>
        {estimatedPriceOfElementsPieChart &&
        estimatedPriceOfElementsPieChart.some(
          (data: TPieChartData) => data.name && data.quantity && data.estimatedPrice
        ) ? (
          <PieChart width={800} height={730}>
            <Pie
              data={estimatedPriceOfElementsPieChart}
              dataKey="estimatedPrice"
              isAnimationActive={false}
              cx="50%"
              cy="50%"
              outerRadius={220}
              fill="#5f708c"
              fillRule="evenodd"
              label={{ offsetRadius: 100 }}
            >
              {estimatedPriceOfElementsPieChart?.map((data: TPieChartData, index: number) => (
                <Cell key={`cell-${index}`} fill={data.color} />
              ))}
            </Pie>
            <Tooltip
              formatter={(value: number) =>
                `: : ${new Intl.NumberFormat('pl').format(value)} ${displayedCurrency}`
              }
            />
          </PieChart>
        ) : (
          <p className="mt-0">Brak elementów z wpisaną ilością i ceną szacunkową!</p>
        )}
      </div>
    </>
  )
}
