import { combineReducers } from 'redux'

import authReducer from './auth'
import adminReducer from './admin'
import bomElementsReducer from './bom-elements'
import servicesReducer from './services'
import suppliersReducer from './suppliers'
import implementationCostsReducer from './implementation-costs'
import bomsReducer from './boms'
import ordersReducer from './orders'
import producersReducer from './producers'
import offersReducer from './offers'
import purchasePlansReducer from './purchase-plans'
import warehousesReducer from './warehouses'
import auditLogsReducer from './audit-logs'
import settingsReducer from './settings'
import usersReducer from './users'

const rootReducer = combineReducers({
  auth: authReducer,
  admin: adminReducer,
  auditLogs: auditLogsReducer,
  users: usersReducer,
  settings: settingsReducer,
  suppliers: suppliersReducer,
  producers: producersReducer,
  bomElements: bomElementsReducer,
  services: servicesReducer,
  implementationCosts: implementationCostsReducer,
  boms: bomsReducer,
  offers: offersReducer,
  purchasePlans: purchasePlansReducer,
  orders: ordersReducer,
  warehouses: warehousesReducer,
})

export type TRootState = ReturnType<typeof rootReducer>

export default rootReducer
