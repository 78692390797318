import { CCol, CContainer, CRow } from '@coreui/react'

export const App404Page: React.FC = () => {
  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="6">
            <div className="clearfix">
              <h1 className="float-left display-3 mr-4">404</h1>
              <h4 className="pt-3">Oops!</h4>
              <p className="text-muted float-left">
                Strona, której szukasz nie została odnaleziona.
              </p>
            </div>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}
