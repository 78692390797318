import React, { SyntheticEvent, useEffect, useMemo } from 'react'
import { Container } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { CDataTable, CCard, CCol, CCardBody, CButton } from '@coreui/react'

import * as actionTypes from '../../store/action-types'
import { TRootState } from '../../store/reducers'
import { ListActionButton } from '../../components'
import { ListStickyErrorMessage } from '../../components/list-sticky-error-message'
import { getEmptyListMessage, makeSearchBoxFixed } from '../../utils'

const BOMs: React.FC<{}> = () => {
  const { tenant } = useParams<{ tenant: string }>()
  const history = useHistory()
  let dispatch = useDispatch()

  const bomsState = useSelector((state: TRootState) => state.boms)
  const authState = useSelector((state: TRootState) => state.auth)

  const memoizedTableItems = useMemo(
    () =>
      bomsState?.boms?.map((bom: actionTypes.TBomLight) => {
        return {
          ...bom,
          name: bom?.name || '',
          icon: '🖊️',
        }
      }),
    [bomsState.boms]
  )

  // Make search box fixed
  useEffect(() => {
    makeSearchBoxFixed()

    // Remove all errors on refresh or path change
    dispatch({
      type: actionTypes.CLOSE_BOM_DETAILS,
    })
  }, [dispatch, makeSearchBoxFixed])

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center">
      <CCol xl="8">
        <CCard>
          <CCardBody className="pb-2">
            {(bomsState?.bomArchiveError ||
              bomsState?.bomCopyError ||
              bomsState?.bomDeleteError) && (
              <ListStickyErrorMessage
                listErrorMessage={`Wystąpił błąd ${
                  bomsState?.bomArchiveError?.status ||
                  bomsState?.bomCopyError?.status ||
                  bomsState?.bomDeleteError?.status ||
                  'nieznany'
                } podczas ${
                  (bomsState?.bomArchiveError && 'archiwizowania') ||
                  (bomsState?.bomCopyError && 'kopiowania') ||
                  (bomsState?.bomDeleteError && 'usuwania') ||
                  'zmiany'
                } BOM!`}
              />
            )}

            {authState?.authData?.roles?.includes('Boms_write') && (
              <div className="d-flex justify-content-end list-fixed-create-new-button-wrapper">
                <CButton
                  color="info"
                  variant="outline"
                  size="50"
                  onClick={() => {
                    history.push(`/${tenant}/admin/boms/create/elements`)
                  }}
                >
                  Nowy BOM
                </CButton>
              </div>
            )}

            <CDataTable
              tableFilterValue={bomsState?.tableFilterState}
              sorterValue={bomsState?.tableSorterState}
              onSorterValueChange={(sorterState: actionTypes.TSorterState) =>
                dispatch({
                  type: actionTypes.SET_BOMS_TABLE_SORTER_STATE,
                  tableSorterState: sorterState,
                })
              }
              onTableFilterChange={(tableFilterState: string) =>
                dispatch({
                  type: actionTypes.SET_BOMS_TABLE_FILTER_STATE,
                  tableFilterState: tableFilterState,
                })
              }
              loading={bomsState.areBomsLoading}
              striped={!!bomsState.boms.length}
              border
              sorter
              tableFilter={{ label: ' ', placeholder: 'Szukaj w tabeli...' }}
              cleaner
              onRowClick={(bom: actionTypes.TBomLight) => {
                history.push(`/${tenant}/admin/boms/edit/${bom.id}/elements`)
              }}
              addTableClasses="item-boms-table"
              fields={[
                {
                  key: 'icon',
                  label: '',
                  sorter: false,
                  filter: false,
                  _style: { width: '45px', display: !bomsState.boms.length ? 'none' : '' },
                },
                {
                  key: 'name',
                  label: 'Nazwa',
                  _style: { width: '1fr', textAlign: 'left' },
                },
                {
                  key: 'copy',
                  label: '',
                  _style: { width: '45px' },
                },
                {
                  key: 'archive',
                  label: '',
                  _style: { width: '45px' },
                },
                {
                  key: 'delete',
                  label: '',
                  _style: { width: '45px' },
                },
              ]}
              items={memoizedTableItems}
              noItemsViewSlot={
                <div className="no-items-in-table">
                  {getEmptyListMessage(
                    bomsState.fetchBomsError,
                    bomsState.areBomsLoading,
                    'BOM',
                    bomsState?.boms?.length
                  )}
                </div>
              }
              scopedSlots={{
                copy: (itemFromList: actionTypes.TBomLight) => (
                  <td style={{ pointerEvents: 'none' }}>
                    <ListActionButton
                      mainActionName="copy"
                      buttonColor="info"
                      classNames="copy-list-item-button"
                      typeOfAction={actionTypes.COPY_BOM_REQUESTED}
                      iconName="cil-clone"
                      itemFromList={itemFromList}
                      currentlyManagedItemId={bomsState.currentlyManagedBomId}
                      isItemCopying={bomsState.isBomCopying}
                      isItemArchiving={bomsState.isBomArchiving}
                      isItemDeleting={bomsState.isBomDeleting}
                    />
                  </td>
                ),
                archive: (itemFromList: actionTypes.TBomLight) => (
                  <td style={{ pointerEvents: 'none' }}>
                    <ListActionButton
                      mainActionName="archive"
                      buttonColor="dark"
                      classNames="archive-list-item-button"
                      typeOfAction={actionTypes.ARCHIVE_BOM_REQUESTED}
                      iconName="cil-storage"
                      itemFromList={itemFromList}
                      currentlyManagedItemId={bomsState.currentlyManagedBomId}
                      isItemCopying={bomsState.isBomCopying}
                      isItemArchiving={bomsState.isBomArchiving}
                      isItemDeleting={bomsState.isBomDeleting}
                    />
                  </td>
                ),
                delete: (itemFromList: actionTypes.TBomLight) => (
                  <td
                    className="text-center"
                    style={{ pointerEvents: 'none' }}
                    onClick={(event: SyntheticEvent) => {
                      if (!itemFromList.canDelete) {
                        event.preventDefault()
                        event.stopPropagation()
                        return null
                      } else {
                        return undefined
                      }
                    }}
                  >
                    <ListActionButton
                      mainActionName="delete"
                      buttonColor="danger"
                      classNames="delete-list-item-button"
                      typeOfAction={actionTypes.DELETE_BOM_REQUESTED}
                      iconName="cil-trash"
                      itemFromList={itemFromList}
                      currentlyManagedItemId={bomsState.currentlyManagedBomId}
                      isItemCopying={bomsState.isBomCopying}
                      isItemArchiving={bomsState.isBomArchiving}
                      isItemDeleting={bomsState.isBomDeleting}
                    />
                  </td>
                ),
              }}
            />
          </CCardBody>
        </CCard>
      </CCol>
    </Container>
  )
}

export default BOMs
