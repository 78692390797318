import React from 'react'

export interface IListStickyErrorMessage {
  listErrorMessage: string
}

export const ListStickyErrorMessage: React.FC<IListStickyErrorMessage> = ({ listErrorMessage }) => {
  return (
    <div className="sticky-error-message-wrapper">
      <div className="custom-alert alert-danger">
        <span className="custom-alert-danger-icon">
          <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
        </span>
        <div className="alert-message text-center">{listErrorMessage}</div>
      </div>
    </div>
  )
}
