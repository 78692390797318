import React, { ChangeEvent, useState } from 'react'
import { CButton, CLabel } from '@coreui/react'
import { useSelector } from 'react-redux'
import { Document, Page, pdfjs } from 'react-pdf'
import { ErrorMessage, Field, FieldArray } from 'formik'
import { Input, InputGroup, InputGroupAddon, InputGroupText, Table } from 'reactstrap'
import CIcon from '@coreui/icons-react'
import { cilDataTransferDown, cilFindInPage } from '@coreui/icons'

import { CustomErrorMessage, ThreeDots } from '.'
import * as actionTypes from '../store/action-types'
import {
  allowedFileFormats,
  allowedImageFormats,
  getErrorMessageFromStatus,
  inputFieldSpacingBottom,
  inputLabelSpacingBottom,
} from '../utils'
import { TRootState } from '../store/reducers'
import { confirmAlert } from 'react-confirm-alert'
import { ConfirmActionModal } from './confirm-action-modal'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface IAttachmentsUploadPanel {
  attachments: any[] | null
  fetchAttachmentsError: actionTypes.TNetworkError
  isAttachmentUploading: boolean
  isAttachmentUploaded: boolean
  attachmentUploadError: actionTypes.TNetworkError
  newAttachmentFileInput: any
  newAttachmentAltNameError: any
  dispatchAttachmentUpload: (encodedFile: unknown, altName?: string) => void
  dispatchModalActionsUnlock: () => void
  dispatchSingleAttachmentFetch: (
    id: number,
    attachmentType: string | undefined,
    fileName: string,
    download: boolean
  ) => void
  dispatchSingleAttachmentDelete: (id: number) => void
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  isUploadDisabled: boolean
  noAttachmentsTextFirstWords:
    | 'Ten element'
    | 'Ta usługa'
    | 'Ta oferta'
    | 'Ten tooling'
    | 'To zamówienie'

  isEditable: boolean | undefined
}

export const getUploadedFileType = (builtInType: string, fileName: string): string =>
  !builtInType
    ? allowedFileFormats.find((fileFormat: string) =>
        fileName?.slice(-`.${fileFormat}`.length).toLowerCase().includes(`.${fileFormat}`)
      ) || ''
    : builtInType

export const AttachmentsUploadPanel: React.FC<IAttachmentsUploadPanel> = ({
  attachments,
  fetchAttachmentsError,
  isAttachmentUploading,
  isAttachmentUploaded,
  attachmentUploadError,
  newAttachmentFileInput,
  newAttachmentAltNameError,
  dispatchAttachmentUpload,
  dispatchModalActionsUnlock,
  dispatchSingleAttachmentFetch,
  dispatchSingleAttachmentDelete,
  setFieldValue,
  isUploadDisabled,
  noAttachmentsTextFirstWords,
  isEditable = true,
}) => {
  const initialFileInputLabel = `Dozwolony format: ${allowedFileFormats?.join(
    ', '
  )}, ${allowedImageFormats?.join(', ')}`

  let adminState = useSelector((state: TRootState) => state.admin)
  let [convertedImage, setConvertedImage] = useState('')

  function encodeToBase64(file: any) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader()
      reader.readAsBinaryString(file)

      reader.onload = () => {
        resolve(typeof reader.result === 'string' ? btoa(reader.result) : reader.result)
      }

      reader.onerror = reject
    })
  }

  function displayImagePreview(file: any): string {
    var reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onerror = function (error) {
      console.log('Image Preview Error: ', error)
    }

    reader.onload = function () {
      if (typeof reader.result === 'string') {
        setConvertedImage(reader.result)
      }
    }

    return convertedImage
  }

  return (
    <>
      {isEditable && (
        <>
          {attachmentUploadError && (
            <CustomErrorMessage
              wrapperClassNames="my-3"
              customErrorMessageText={getErrorMessageFromStatus(
                'create',
                attachmentUploadError?.status,
                'załącznika'
              )}
            />
          )}

          {/* File Input */}
          <CLabel htmlFor="upload-panel-custom-file-wrapper" className={inputLabelSpacingBottom}>
            Wybierz plik
          </CLabel>
          <div id="upload-panel-custom-file-wrapper" className="custom-file mb-3">
            <input
              id="new-attachment-file-input"
              className="custom-file-input"
              type="file"
              accept={`application/pdf, ${allowedImageFormats
                ?.map((imgType: string) => `image/${imgType}`)
                ?.join(', ')}, ${allowedImageFormats
                ?.map((imgType: string) => `.${imgType}`)
                ?.join(', ')}, ${allowedFileFormats
                ?.map((fileType: string) => `.${fileType}`)
                ?.join(', ')}`}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                dispatchModalActionsUnlock()

                if (event.currentTarget.files) {
                  setFieldValue(`newAttachmentFileInput`, event.currentTarget.files[0])

                  const label = document.getElementById(`new-attachment-file-input-label`)

                  if (label) {
                    label.innerHTML = event.currentTarget.files[0]
                      ? event.currentTarget.files[0].name
                      : initialFileInputLabel
                  }
                }
              }}
            />
            <label
              id="new-attachment-file-input-label"
              className="custom-file-label"
              htmlFor="newAttachmentFileInput"
              style={{ overflow: 'hidden' }}
            >
              {initialFileInputLabel}
            </label>
          </div>

          <CLabel
            htmlFor="upload-panel-new-attachment-alt-name"
            className={inputLabelSpacingBottom}
          >
            Zmień nazwę
          </CLabel>
          <InputGroup id="upload-panel-new-attachment-alt-name" className={inputFieldSpacingBottom}>
            <InputGroupAddon addonType="prepend">
              <InputGroupText
                className={newAttachmentAltNameError && 'text-danger input-error-icon'}
              >
                <i className="cil-text"></i>
              </InputGroupText>
            </InputGroupAddon>
            <ErrorMessage
              name="newAttachmentAltName"
              component="span"
              className="text-danger input-error-message"
            />
            <Field
              as={Input}
              name="newAttachmentAltName"
              placeholder="Opcjonalnie zmień nazwę pliku wyświetlaną w tabeli"
              type="text"
              className={newAttachmentAltNameError && 'form-control is-invalid'}
            />
          </InputGroup>
        </>
      )}

      {/* You do not have an access to the local file system so it is not possible to display a PDF with url from the local computer */}
      <FieldArray
        name="attachments"
        // The arrayHelpers do not work here because it is a component with a fixed name used in more than 1 section
        render={() => (
          <>
            {!!newAttachmentFileInput &&
              allowedImageFormats
                .map((img: string) => `image/${img}`)
                .includes(newAttachmentFileInput.type) && (
                <div className="d-flex justify-content-center mb-3 mt-4 pt-1">
                  <img
                    src={displayImagePreview(newAttachmentFileInput)}
                    alt={newAttachmentFileInput}
                    className="attachment-image-preview attachment-preview"
                  />
                </div>
              )}
            {!!newAttachmentFileInput && newAttachmentFileInput?.type === 'application/pdf' && (
              <Document
                className="d-flex justify-content-center mb-3 mt-4"
                file={newAttachmentFileInput}
              >
                <Page scale={0.5} pageNumber={1} className="attachment-preview" />
              </Document>
            )}
            {isEditable && (
              <div className="d-flex w-100 justify-content-center">
                <CButton
                  color="primary"
                  type="button"
                  variant=""
                  onClick={async () => {
                    let encodedFile = await encodeToBase64(newAttachmentFileInput)

                    dispatchAttachmentUpload(encodedFile)

                    // Clear the file input after sending an action to redux-saga

                    const fileInput: any = document.getElementById('new-attachment-file-input')
                    if (fileInput) {
                      fileInput.value = ''
                      setFieldValue('newAttachmentFileInput', undefined)
                    }

                    const label = document.getElementById(`new-attachment-file-input-label`)
                    if (label) {
                      label.innerHTML = initialFileInputLabel
                      setFieldValue('newAttachmentAltName', '')
                    }
                  }}
                  disabled={isUploadDisabled}
                  className="px-4 py-1 my-2"
                >
                  {isAttachmentUploading ? (
                    <>
                      Dodawanie załącznika
                      <ThreeDots />
                    </>
                  ) : isAttachmentUploaded ? (
                    <>Dodano załącznik!</>
                  ) : (
                    <>Dodaj załącznik</>
                  )}
                </CButton>
              </div>
            )}

            <CLabel htmlFor="upload-panel-attachments-list" className={inputLabelSpacingBottom}>
              Lista załączników
            </CLabel>
            <Table
              bordered
              striped
              className="item-inner-details-table mb-3"
              id="upload-panel-attachments-list"
            >
              <thead>
                <tr>
                  <th style={{ width: '40px', textAlign: 'center' }}>#</th>
                  <th style={{ width: '85px', textAlign: 'center' }}>Pobierz</th>
                  <th style={{ width: '85px', textAlign: 'center' }}>Podgląd</th>
                  <th style={{ width: '500px' }}>Nazwa</th>
                  <th style={{ width: '25px' }}>Usuń</th>
                </tr>
              </thead>

              {attachments && attachments?.length > 0 ? (
                <tbody>
                  {attachments?.map(
                    (attachment: actionTypes.TBomElementAttachment, indexOfAttachment: number) => {
                      const isFileViewableInTheBrowser =
                        allowedImageFormats.some(
                          (format: string) =>
                            attachment?.type?.toLowerCase()?.includes(format?.toLowerCase()) ||
                            attachment?.name?.toLowerCase()?.includes(`.${format?.toLowerCase()}`)
                        ) ||
                        attachment?.type?.toLowerCase()?.includes('pdf') ||
                        attachment?.name?.toLowerCase()?.includes('.pdf')

                      return (
                        <tr key={`attachment-${attachment.id}`}>
                          <td style={{ textAlign: 'center' }}>{indexOfAttachment + 1}.</td>

                          <td>
                            {attachment?.id ? (
                              <CButton
                                type="button"
                                onClick={() => {
                                  dispatchSingleAttachmentFetch(
                                    attachment.id,
                                    attachment.type,
                                    attachment.name,
                                    true
                                  )
                                }}
                                className="attachment-button"
                                disabled={adminState.isSingleAttachmentFetching}
                              >
                                {adminState.isSingleAttachmentFetching &&
                                !adminState.isAttachmentPreview &&
                                adminState.currentlyFetchedSingleAttachmentId === attachment.id ? (
                                  <div className="spinner-border very-small-spinner" role="status">
                                    <span className="sr-only">
                                      <ThreeDots />
                                    </span>
                                  </div>
                                ) : (
                                  <CIcon icon={cilDataTransferDown} />
                                )}
                              </CButton>
                            ) : null}
                          </td>

                          <td>
                            {attachment?.id ? (
                              <CButton
                                type="button"
                                onClick={() => {
                                  dispatchSingleAttachmentFetch(
                                    attachment.id,
                                    attachment.type,
                                    attachment.name,
                                    false
                                  )
                                }}
                                className="attachment-button"
                                disabled={
                                  !isFileViewableInTheBrowser ||
                                  adminState.isSingleAttachmentFetching
                                }
                              >
                                {adminState.isSingleAttachmentFetching &&
                                adminState.isAttachmentPreview &&
                                adminState.currentlyFetchedSingleAttachmentId === attachment.id ? (
                                  <div className="spinner-border very-small-spinner" role="status">
                                    <span className="sr-only">
                                      <ThreeDots />
                                    </span>
                                  </div>
                                ) : (
                                  <CIcon icon={cilFindInPage} />
                                )}
                              </CButton>
                            ) : null}
                          </td>

                          <td>{attachment.name}</td>

                          <td className="text-center">
                            <CButton
                              color="danger"
                              type="button"
                              variant="outline"
                              onClick={() => {
                                confirmAlert({
                                  closeOnEscape: true,
                                  customUI: ({ onClose }) => {
                                    return (
                                      <ConfirmActionModal
                                        mode="delete"
                                        onClose={onClose}
                                        confirmMessageJSX={
                                          <>
                                            Czy na pewno chcesz usunąć załącznik?
                                            {attachment?.name ? (
                                              <>
                                                <br />
                                                <strong>{attachment?.name}</strong>
                                              </>
                                            ) : (
                                              ''
                                            )}
                                          </>
                                        }
                                        onConfirmFunction={() =>
                                          dispatchSingleAttachmentDelete(attachment.id)
                                        }
                                      />
                                    )
                                  },
                                })
                              }}
                              disabled={!isEditable || adminState.isSingleAttachmentDeleting}
                              className="upload-panel-delete-attachment-button select-option-remove-button"
                            >
                              {adminState.isSingleAttachmentDeleting &&
                              adminState.currentlyDeletingSingleAttachmentId === attachment.id ? (
                                <div className="spinner-border very-small-spinner" role="status">
                                  <span className="sr-only">
                                    <ThreeDots />
                                  </span>
                                </div>
                              ) : (
                                <i className="cil-trash"></i>
                              )}
                            </CButton>
                          </td>
                        </tr>
                      )
                    }
                  )}
                </tbody>
              ) : (
                <caption className="item-inner-details-table-caption">
                  {fetchAttachmentsError
                    ? `Wystąpił błąd ${fetchAttachmentsError.status} podczas pobierania załączników!`
                    : noAttachmentsTextFirstWords + ' nie posiada obecnie żadnych załączników!'}
                </caption>
              )}
            </Table>

            {(adminState?.singleAttachmentFetchError ||
              adminState?.singleAttachmentDeleteError) && (
              <CustomErrorMessage
                wrapperClassNames="mt-4 mb-1"
                customErrorMessageText={getErrorMessageFromStatus(
                  adminState?.singleAttachmentDeleteError ? 'delete' : 'fetch',
                  adminState?.singleAttachmentFetchError?.status ||
                    adminState?.singleAttachmentDeleteError?.status,
                  'załącznika'
                )}
              />
            )}
          </>
        )}
      />
    </>
  )
}
