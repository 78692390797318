import {
  JsonHubProtocol,
  HubConnectionState,
  HubConnectionBuilder,
  LogLevel,
  HubConnection,
} from '@microsoft/signalr'
import { Dispatch } from 'redux'

import * as actionTypes from '../action-types'
import { IAuthState } from '../reducers/auth'

interface IBomElementChangeDetails {
  bomElementId: number
  clientIp: string
  userName: string
}

const isDev = process.env.NODE_ENV === 'development'

const startSignalRConnection = async (connection: HubConnection) => {
  try {
    await connection.start()
    console.assert(connection.state === HubConnectionState.Connected)
    console.log('SignalR connection established')
  } catch (err) {
    console.assert(connection.state === HubConnectionState.Disconnected)
    console.error('SignalR Connection Error: ', err)
    setTimeout(() => startSignalRConnection(connection), 5000)
  }
}

// Set up a SignalR connection to the specified hub URL, and actionEventMap.
// actionEventMap should be an object mapping event names, to eventHandlers that will
// be dispatched with the message body.
export const setupSignalRConnection = (
  connectionHub: string,
  actionEventMap = {},
  tenant: string,
  authState: IAuthState,
  dispatch: Dispatch
  // getAccessToken
): HubConnection => {
  const options = {
    logMessageContent: isDev,
    logger: isDev ? LogLevel.Warning : LogLevel.Error,
    // accessTokenFactory: () => getAccessToken(getState()),
  }
  // create the connection instance
  // withAutomaticReconnect will automatically try to reconnect
  // and generate a new socket connection if needed
  const connection: HubConnection = new HubConnectionBuilder()
    .withUrl(connectionHub, options)
    .withAutomaticReconnect()
    .withHubProtocol(new JsonHubProtocol())
    .configureLogging(LogLevel.Information)
    .build()

  startSignalRConnection(connection)

  // Note: to keep the connection open the serverTimeout should be
  // larger than the KeepAlive value that is set on the server
  // keepAliveIntervalInMilliseconds default is 15000 and we are using default
  // serverTimeoutInMilliseconds default is 30000 and we are using 60000 set below
  connection.serverTimeoutInMilliseconds = 60000

  // re-establish the connection if connection dropped
  connection.onclose((error) => {
    console.assert(connection.state === HubConnectionState.Disconnected)
    console.log(
      'SignlaR connection closed due to error! Try refreshing this page to restart the connection',
      error
    )
  })

  connection.onreconnecting((error) => {
    console.assert(connection.state === HubConnectionState.Reconnecting)
    console.log('SignalR connection lost due to error! Reconnecting... ', error)
  })

  connection.onreconnected((connectionId) => {
    console.assert(connection.state === HubConnectionState.Connected)
    console.log('SignalR connection reestablished. Connected with connectionId ' + connectionId)
  })

  // connection.on('OnEvent', (res) => {
  //   const eventHandler = actionEventMap[res.eventType]
  //   eventHandler && dispatch(eventHandler(res))
  // })

  connection.on('BomElementChanged', (bomElementChangeDetails: IBomElementChangeDetails) => {
    console.log(
      'The BOM Element with id ' +
        bomElementChangeDetails.bomElementId +
        ' has been changed by user' +
        bomElementChangeDetails.userName
    )

    if (tenant && authState.isAuthenticated && authState.authData) {
      dispatch({
        type: actionTypes.FETCH_BOM_ELEMENTS_REQUESTED,
        payload: { tenant: tenant, token: authState.authData?.token },
      })
    }
  })

  return connection
}
