import * as actionTypes from '../action-types'

export interface IOrdersState {
  orders: actionTypes.TOrder[] | any
  areOrdersLoading: boolean
  fetchOrdersError: actionTypes.TNetworkError

  tableSorterState: actionTypes.TSorterState | undefined
  tableFilterState: actionTypes.TFilterState

  isSingleOrderLoading: boolean
  editedOrder: actionTypes.TOrder | null
  singleOrderFetchError: actionTypes.TNetworkError

  isOrderEditSaving: boolean
  orderEditSaveError: actionTypes.TNetworkError
  isOrderEditSaved: boolean

  isOrderDeleting: boolean
  orderDeleteError: actionTypes.TNetworkError
  isOrderDeleted: boolean

  editedOrderAttachments: actionTypes.TOrderAttachment[] | null
  areOrderAttachmentsLoading: boolean
  fetchOrderAttachmentsError: actionTypes.TNetworkError

  isOrderAttachmentUploading: boolean
  orderAttachmentUploadingError: actionTypes.TNetworkError
  isOrderAttachmentUploaded: boolean

  isOrderPDFGenerating: boolean
  generateOrderPDFError: actionTypes.TNetworkError
  isOrderPDFGenerated: boolean
  isGenerateOrderPDFButtonLocked: boolean

  generatedOrderPDFInfo: null | actionTypes.IGeneratedOrderPDFInfo
}

const initialState: IOrdersState = {
  orders: [],
  fetchOrdersError: null,
  areOrdersLoading: false,

  tableSorterState: undefined,
  tableFilterState: undefined,

  isSingleOrderLoading: false,
  editedOrder: null,
  singleOrderFetchError: null,

  isOrderEditSaving: false,
  orderEditSaveError: null,
  isOrderEditSaved: false,

  isOrderDeleting: false,
  orderDeleteError: null,
  isOrderDeleted: false,

  editedOrderAttachments: null,
  areOrderAttachmentsLoading: false,
  fetchOrderAttachmentsError: null,

  isOrderAttachmentUploading: false,
  orderAttachmentUploadingError: null,
  isOrderAttachmentUploaded: false,

  isOrderPDFGenerating: false,
  generateOrderPDFError: null,
  isOrderPDFGenerated: false,
  isGenerateOrderPDFButtonLocked: false,

  generatedOrderPDFInfo: null,
}

export default function ordersReducer(
  state = initialState,
  action: actionTypes.OrdersActionTypes
): IOrdersState {
  switch (action.type) {
    case actionTypes.FETCH_ORDERS_REQUESTED: {
      return {
        ...state,
        fetchOrdersError: null,
        areOrdersLoading: true,
        isOrderDeleted: false,
      }
    }

    case actionTypes.FETCH_ORDERS_SUCCEEDED: {
      return {
        ...state,
        orders: action.orders,
        areOrdersLoading: false,
      }
    }

    case actionTypes.FETCH_ORDERS_ERROR: {
      return {
        ...state,
        fetchOrdersError: action.error,
        areOrdersLoading: false,
      }
    }

    case actionTypes.SET_ORDERS_TABLE_FILTER_STATE: {
      return {
        ...state,
        tableFilterState: action.tableFilterState,
      }
    }

    case actionTypes.SET_ORDERS_TABLE_SORTER_STATE: {
      return {
        ...state,
        tableSorterState: action.tableSorterState,
      }
    }

    case actionTypes.FETCH_SINGLE_ORDER_REQUESTED: {
      return {
        ...state,
        isSingleOrderLoading: true,
        singleOrderFetchError: null,

        isOrderDeleting: false,
        isOrderDeleted: false,
        orderDeleteError: null,

        isOrderEditSaving: false,
        isOrderEditSaved: false,
        orderEditSaveError: null,

        editedOrderAttachments: null,
        orderAttachmentUploadingError: null,
        isOrderAttachmentUploading: false,
        isOrderAttachmentUploaded: false,

        isOrderPDFGenerating: false,
        generateOrderPDFError: null,
        isOrderPDFGenerated: false,
        isGenerateOrderPDFButtonLocked: false,
      }
    }

    case actionTypes.FETCH_SINGLE_ORDER_SUCCEEDED: {
      return {
        ...state,
        singleOrderFetchError: null,
        isSingleOrderLoading: false,
        editedOrder: action.editedOrder,
        isOrderDeleted: false,
      }
    }

    case actionTypes.FETCH_SINGLE_ORDER_ERROR: {
      return {
        ...state,
        isSingleOrderLoading: false,
        singleOrderFetchError: action.error,
      }
    }

    case actionTypes.EDIT_ORDER_REQUESTED: {
      return {
        ...state,
        isOrderEditSaving: true,
        orderEditSaveError: null,
      }
    }

    case actionTypes.EDIT_ORDER_SUCCEEDED: {
      return {
        ...state,
        isOrderEditSaving: false,
        isOrderEditSaved: true,
      }
    }

    case actionTypes.EDIT_ORDER_ERROR: {
      return {
        ...state,
        isOrderEditSaving: false,
        isOrderEditSaved: false,
        orderEditSaveError: action.error,
      }
    }

    case actionTypes.DELETE_ORDER_REQUESTED: {
      return {
        ...state,
        isOrderDeleting: true,
        isOrderDeleted: false,
        orderDeleteError: null,
      }
    }

    case actionTypes.DELETE_ORDER_SUCCEEDED: {
      return {
        ...state,
        isOrderDeleting: false,
        isOrderDeleted: true,
        orders: state.orders.filter((order: actionTypes.TOrder) => order.id !== action.id),
        editedOrder: null,

        isOrderPDFGenerating: false,
        generateOrderPDFError: null,
        isOrderPDFGenerated: false,
        isGenerateOrderPDFButtonLocked: true,
      }
    }

    case actionTypes.DELETE_ORDER_ERROR: {
      return {
        ...state,
        isOrderDeleting: false,
        isOrderDeleted: false,
        orderDeleteError: action.error,
      }
    }

    case actionTypes.FETCH_ORDER_ATTACHMENTS_REQUESTED: {
      return {
        ...state,
        editedOrderAttachments: null,
        fetchOrderAttachmentsError: null,
        areOrderAttachmentsLoading: true,
      }
    }

    case actionTypes.FETCH_ORDER_ATTACHMENTS_SUCCEEDED: {
      return {
        ...state,
        editedOrderAttachments: action.orderAttachments,
        areOrderAttachmentsLoading: false,
      }
    }

    case actionTypes.FETCH_ORDER_ATTACHMENTS_ERROR: {
      return {
        ...state,
        fetchOrderAttachmentsError: action.error,
        areOrderAttachmentsLoading: false,
      }
    }

    case actionTypes.UPLOAD_ORDER_ATTACHMENT_REQUESTED: {
      return {
        ...state,
        orderAttachmentUploadingError: null,
        isOrderAttachmentUploading: true,
        isOrderAttachmentUploaded: false,
      }
    }

    case actionTypes.UPLOAD_ORDER_ATTACHMENT_SUCCEEDED: {
      return {
        ...state,
        isOrderAttachmentUploading: false,
        isOrderAttachmentUploaded: true,
        editedOrderAttachments: state.editedOrderAttachments
          ? [...state.editedOrderAttachments, action.attachment]
          : [action.attachment],
      }
    }

    case actionTypes.UPLOAD_ORDER_ATTACHMENT_ERROR: {
      return {
        ...state,
        orderAttachmentUploadingError: action.error,
        isOrderAttachmentUploading: false,
        isOrderAttachmentUploaded: false,
      }
    }

    case actionTypes.DELETE_ORDER_ATTACHMENT_SUCCEEDED: {
      return {
        ...state,
        editedOrderAttachments:
          state.editedOrderAttachments?.filter(
            (attachment: actionTypes.TAttachment) => attachment.id !== action.payload.id
          ) || [],
      }
    }

    case actionTypes.ORDER_ACTIONS_UNLOCK: {
      return {
        ...state,
        isOrderDeleting: false,
        isOrderEditSaving: false,

        isOrderDeleted: false,
        isOrderEditSaved: false,

        orderAttachmentUploadingError: null,
        isOrderAttachmentUploading: false,
        isOrderAttachmentUploaded: false,
      }
    }

    case actionTypes.GENERATE_ORDER_PDF_REQUESTED: {
      return {
        ...state,
        isOrderPDFGenerating: true,
        generateOrderPDFError: null,
        isOrderPDFGenerated: false,
        isGenerateOrderPDFButtonLocked: true,
        generatedOrderPDFInfo: null,
      }
    }

    case actionTypes.GENERATE_ORDER_PDF_SUCCEEDED: {
      return {
        ...state,
        isOrderPDFGenerating: false,
        generateOrderPDFError: null,
        isOrderPDFGenerated: true,
        isGenerateOrderPDFButtonLocked: true,
        generatedOrderPDFInfo: action.generatedOrderPDFInfo,
      }
    }

    case actionTypes.GENERATE_ORDER_PDF_ERROR: {
      return {
        ...state,
        isOrderPDFGenerating: false,
        generateOrderPDFError: action.error,
        isOrderPDFGenerated: false,
        isGenerateOrderPDFButtonLocked: false,
        generatedOrderPDFInfo: null,
      }
    }

    case actionTypes.UNLOCK_GENERATE_ORDER_PDF_BUTTON: {
      return {
        ...state,
        isOrderPDFGenerated: false,
        isOrderPDFGenerating: false,
        generateOrderPDFError: null,
        isGenerateOrderPDFButtonLocked: false,
        generatedOrderPDFInfo: null,
      }
    }

    default:
      return state
  }
}
