import * as actionTypes from '../action-types'

export interface ISuppliersState {
  suppliers: actionTypes.TSupplier[] | any
  areSuppliersLoading: boolean
  fetchSuppliersError: actionTypes.TNetworkError

  tableSorterState: actionTypes.TSorterState | undefined
  tableFilterState: actionTypes.TFilterState

  isSupplierCreating: boolean
  supplierCreateError: actionTypes.TNetworkError
  isSupplierCreated: boolean

  isSingleSupplierLoading: boolean
  singleSupplierFetchError: actionTypes.TNetworkError
  editedSupplier: actionTypes.TSupplierDetails | null

  isSupplierEditSaving: boolean
  supplierEditSaveError: actionTypes.TNetworkError
  isSupplierEditSaved: boolean

  isSupplierDeleting: boolean
  supplierDeleteError: actionTypes.TNetworkError
  isSupplierDeleted: boolean
}

const initialState: ISuppliersState = {
  suppliers: [],
  areSuppliersLoading: false,
  fetchSuppliersError: null,

  tableSorterState: undefined,
  tableFilterState: undefined,

  isSupplierCreating: false,
  supplierCreateError: null,
  isSupplierCreated: false,

  isSingleSupplierLoading: false,
  singleSupplierFetchError: null,
  editedSupplier: null,

  isSupplierEditSaving: false,
  supplierEditSaveError: null,
  isSupplierEditSaved: false,

  isSupplierDeleting: false,
  supplierDeleteError: null,
  isSupplierDeleted: false,
}

export default function suppliersReducer(
  state = initialState,
  action: actionTypes.SuppliersActionTypes
): ISuppliersState {
  switch (action.type) {
    case actionTypes.FETCH_SUPPLIERS_REQUESTED: {
      return {
        ...state,
        fetchSuppliersError: null,
        areSuppliersLoading: true,
      }
    }

    case actionTypes.FETCH_SUPPLIERS_SUCCEEDED: {
      return {
        ...state,
        suppliers: action.suppliers,
        areSuppliersLoading: false,
      }
    }

    case actionTypes.FETCH_SUPPLIERS_ERROR: {
      return {
        ...state,
        fetchSuppliersError: action.error,
        areSuppliersLoading: false,
      }
    }

    case actionTypes.SET_SUPPLIERS_TABLE_FILTER_STATE: {
      return {
        ...state,
        tableFilterState: action.tableFilterState,
      }
    }

    case actionTypes.SET_SUPPLIERS_TABLE_SORTER_STATE: {
      return {
        ...state,
        tableSorterState: action.tableSorterState,
      }
    }

    case actionTypes.FETCH_SINGLE_SUPPLIER_REQUESTED: {
      return {
        ...state,
        isSingleSupplierLoading: true,
        singleSupplierFetchError: null,
      }
    }

    case actionTypes.FETCH_SINGLE_SUPPLIER_SUCCEEDED: {
      return {
        ...state,
        singleSupplierFetchError: null,
        isSingleSupplierLoading: false,
        editedSupplier: action.editedSupplier,
      }
    }

    case actionTypes.FETCH_SINGLE_SUPPLIER_ERROR: {
      return {
        ...state,
        singleSupplierFetchError: action.error,
        isSingleSupplierLoading: false,
        isSupplierEditSaving: false,
      }
    }

    case actionTypes.CREATE_SUPPLIER_REQUESTED: {
      return {
        ...state,
        supplierCreateError: null,
        isSupplierEditSaving: true,
        isSupplierCreated: false,
      }
    }

    case actionTypes.CREATE_SUPPLIER_SUCCEEDED: {
      return {
        ...state,
        isSupplierEditSaving: false,
        isSupplierCreated: true,
        suppliers: [
          ...state.suppliers,
          {
            id: action?.supplier?.id,
            name: action?.supplier?.mainInfo?.name || '',
            contactPerson: action?.supplier?.mainInfo?.contacts[0]?.contactPerson || '',
            contactEmail: action?.supplier?.mainInfo?.contacts[0]?.email || '',
            contactPhone: action?.supplier?.mainInfo?.contacts[0]?.phone || '',
            country: action?.supplier?.address?.country || '',
          },
        ],
      }
    }

    case actionTypes.CREATE_SUPPLIER_ERROR: {
      return {
        ...state,
        supplierCreateError: action.error,
        isSupplierEditSaving: false,
        isSupplierCreated: false,
      }
    }

    case actionTypes.EDIT_SUPPLIER_REQUESTED: {
      return {
        ...state,
        supplierEditSaveError: null,
        isSupplierEditSaving: true,
        isSupplierEditSaved: false,
      }
    }

    case actionTypes.EDIT_SUPPLIER_SUCCEEDED: {
      return {
        ...state,
        isSupplierEditSaving: false,
        isSupplierEditSaved: true,
        suppliers: state.suppliers?.map((supplier: actionTypes.TSupplier) => {
          if (supplier.id === action.supplier.id) {
            return {
              id: supplier.id,
              name: action?.supplier?.mainInfo?.name || '',
              contactPerson: action?.supplier?.mainInfo?.contacts[0]?.contactPerson || '',
              contactEmail: action?.supplier?.mainInfo?.contacts[0]?.email || '',
              contactPhone: action?.supplier?.mainInfo?.contacts[0]?.phone || '',
              country: action?.supplier?.address?.country || '',
            }
          } else {
            return supplier
          }
        }),
      }
    }

    case actionTypes.EDIT_SUPPLIER_ERROR: {
      return {
        ...state,
        supplierEditSaveError: action.error,
        isSupplierEditSaving: false,
        isSupplierEditSaved: false,
      }
    }

    case actionTypes.DELETE_SUPPLIER_REQUESTED: {
      return {
        ...state,
        supplierDeleteError: null,
        isSupplierDeleting: true,
        isSupplierDeleted: false,
      }
    }

    case actionTypes.DELETE_SUPPLIER_SUCCEEDED: {
      return {
        ...state,
        areSuppliersLoading: false,
        isSupplierDeleting: false,
        isSupplierDeleted: true,
        suppliers: state.suppliers.filter(
          (supplier: actionTypes.TSupplier) => supplier.id !== action.id
        ),
      }
    }

    case actionTypes.DELETE_SUPPLIER_ERROR: {
      return {
        ...state,
        supplierDeleteError: action.error,
        isSupplierDeleting: false,
        isSupplierDeleted: false,
      }
    }

    case actionTypes.SET_EDITED_SUPPLIER: {
      return {
        ...state,
        editedSupplier: action.supplier,
        singleSupplierFetchError: null,
        supplierCreateError: null,
        supplierDeleteError: null,
        supplierEditSaveError: null,
      }
    }

    case actionTypes.SUPPLIER_ACTIONS_UNLOCK: {
      return {
        ...state,
        isSupplierCreating: false,
        isSingleSupplierLoading: false,
        isSupplierEditSaving: false,
        isSupplierDeleting: false,
        isSupplierCreated: false,
        isSupplierEditSaved: false,
        isSupplierDeleted: false,
      }
    }

    default:
      return state
  }
}
