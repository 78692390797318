import React from 'react'
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import { ErrorMessage } from 'formik'
import { CLabel } from '@coreui/react'

import { inputLabelSpacingBottom, inputFieldSpacingBottom, countries, TCountry } from '../utils'
import { Typeahead } from 'react-bootstrap-typeahead'

interface ICountrySelectField {
  fieldId: string
  fieldLabel: string
  formikFieldName: string
  fieldError: any
  fieldValue: any
  fieldIcon: string
  placeholder?: string
  setFieldValue: any
  refCountryTypeahead: any
  listOfRefs?: any
  indexOfItemInList?: number
}

export const CountrySelectField: React.FC<ICountrySelectField> = ({
  fieldId,
  fieldLabel,
  formikFieldName,
  fieldError,
  fieldValue,
  fieldIcon,
  placeholder,
  refCountryTypeahead,
  setFieldValue,
  listOfRefs = null,
  indexOfItemInList = 0,
}) => (
  <div className="basic-form-field-wrapper">
    <CLabel htmlFor={fieldId} className={`${inputLabelSpacingBottom}`}>
      {fieldLabel}
    </CLabel>
    <InputGroup id={fieldId} className={`${inputFieldSpacingBottom}`}>
      <InputGroupAddon addonType="prepend">
        <InputGroupText className={fieldError && 'text-danger input-error-icon'}>
          <i className={fieldIcon}></i>
        </InputGroupText>
      </InputGroupAddon>
      <ErrorMessage
        name={formikFieldName}
        component="span"
        className="text-danger input-error-message"
      />
      <Typeahead
        placeholder={placeholder}
        isInvalid={!!fieldError}
        id={`${fieldId}-typeahead`}
        onChange={(selected: any[]) => {
          if (selected.length > 0) {
            setFieldValue(formikFieldName, selected[0].polishName)
          }
        }}
        onInputChange={(text: string) => {
          if (text.length > 0) {
            const matchedCountry = countries
              ?.map((country: TCountry) => country.polishName)
              ?.find(
                (countryPolishName: string) =>
                  countryPolishName.toLowerCase() === text.toLowerCase()
              )

            if (matchedCountry) {
              setFieldValue(formikFieldName, matchedCountry)

              if (refCountryTypeahead?.current) {
                refCountryTypeahead.current.hideMenu()
              }

              if (refCountryTypeahead?.hideMenu) {
                refCountryTypeahead.hideMenu()
              }
            } else {
              setFieldValue(formikFieldName, text)
            }
          } else {
            setFieldValue(formikFieldName, '')
          }
        }}
        defaultInputValue={fieldValue || ''}
        options={countries?.map((country: TCountry) => ({
          polishName: country.polishName,
        }))}
        labelKey="polishName"
        emptyLabel={'Brak krajów do wyboru'}
        ref={
          listOfRefs
            ? (HTMLElement: never) => {
                listOfRefs.current[indexOfItemInList] = HTMLElement

                refCountryTypeahead = listOfRefs.current[indexOfItemInList]

                return listOfRefs.current[indexOfItemInList]
              }
            : refCountryTypeahead
        }
      />
    </InputGroup>
  </div>
)
