import React, { ChangeEvent } from 'react'
import { Field, FieldArray } from 'formik'
import { useSelector } from 'react-redux'
import { CButton, CDataTable } from '@coreui/react'
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import { v4 as uuidv4 } from 'uuid'
import NumberFormat from 'react-number-format'

import { TRootState } from '../../store/reducers'
import * as actionTypes from '../../store/action-types'
import { CurrencyPicker } from '../../components'
import { IBomDetailsTableValues, numeralsTextAlign } from './bom-details'
import {
  convertNumberToNumericString,
  convertNumericStringToNumber,
  currencyExchanger,
  globalCurrencyFallback,
  globalDecimalSeparator,
  globalThousandSeparator,
  handleNumberFormatPaste,
} from '../../utils'
import { offlineCurrencyExchangeRates } from '../admin'

export const getBomCalculationIncomeForAPI = (
  bomCalculation: actionTypes.IBomCalculation,
  exchangeRates: any
) => {
  if (
    (bomCalculation.dealerPrice && bomCalculation.dealerQuantity) ||
    (bomCalculation.distributorPrice && bomCalculation.distributorQuantity)
  ) {
    const calculationResult =
      (currencyExchanger(
        Number(convertNumericStringToNumber(bomCalculation.dealerPrice)),
        bomCalculation.dealerPriceCurrency,
        bomCalculation.incomeCurrency,
        exchangeRates
      ) * Number(convertNumericStringToNumber(bomCalculation.dealerQuantity)) || 0) +
      (Number(
        currencyExchanger(
          Number(convertNumericStringToNumber(bomCalculation.distributorPrice)),
          bomCalculation.distributorPriceCurrency,
          bomCalculation.incomeCurrency,
          exchangeRates
        )
      ) * Number(convertNumericStringToNumber(bomCalculation.distributorQuantity)) || 0)

    return calculationResult || ''
  } else {
    return ''
  }
}

export const BomDetailsCalculationsTable: React.FC<IBomDetailsTableValues> = ({
  values,
  errors,
  handleChange,
  setFieldValue,
  setIsBomModifiedAndUnsaved,
  isBomChangeOccuring,
}) => {
  const bomsState = useSelector((state: TRootState) => state.boms)
  const adminState = useSelector((state: TRootState) => state.admin)
  const settingsState = useSelector((state: TRootState) => state.settings)

  const exchangeRates = adminState.todayCurrencyExchange
    ? adminState.todayCurrencyExchange.rates
    : offlineCurrencyExchangeRates

  const getNettoMsrp = (msrp: string | number, taxRate: string | number) => {
    return (
      Number(convertNumericStringToNumber(msrp)) /
      Number(1 + Number(convertNumericStringToNumber(String(taxRate)?.replace(',', ''))) / 100)
    )
  }

  // DEALER

  const getNettoMsrpExchangedToDealerPrice = (
    msrp: string | number,
    msrpCurrency: actionTypes.TCurrencyCode,
    taxRate: string | number,
    dealerPriceCurrency: actionTypes.TCurrencyCode
  ) => {
    return currencyExchanger(
      getNettoMsrp(msrp, taxRate),
      msrpCurrency,
      dealerPriceCurrency,
      exchangeRates
    )
  }

  const getDealerPriceBasedOnMargin = (
    msrp: string | number,
    msrpCurrency: actionTypes.TCurrencyCode,
    taxRate: string | number,
    dealerPriceCurrency: actionTypes.TCurrencyCode,
    dealerMargin: string | number
  ) => {
    const exchangedDealerPriceToMsrpCurrency = getNettoMsrpExchangedToDealerPrice(
      msrp,
      msrpCurrency,
      taxRate,
      dealerPriceCurrency
    )

    if (msrp && msrpCurrency && taxRate && dealerPriceCurrency && dealerMargin) {
      return (
        exchangedDealerPriceToMsrpCurrency -
        (Number(convertNumericStringToNumber(dealerMargin)) / 100) *
          exchangedDealerPriceToMsrpCurrency
      )
    } else {
      return ''
    }
  }

  const getExchangedDealerPrice = (
    tableRowItem: actionTypes.IBomCalculation,
    dealerPrice: number | string
  ) => {
    return currencyExchanger(
      Number(convertNumericStringToNumber(dealerPrice)),
      tableRowItem.dealerPriceCurrency,
      tableRowItem.msrpCurrency,
      exchangeRates
    )
  }

  // DISTRIBUTOR

  const getDealerPriceOrNettoMsrpExchangedToDistributorCurrency = (
    msrp: number | string,
    msrpCurrency: actionTypes.TCurrencyCode,
    taxRate: number | string,
    dealerPrice: number | string,
    dealerPriceCurrency: actionTypes.TCurrencyCode,
    distributorPriceCurrency: actionTypes.TCurrencyCode
  ) => {
    return currencyExchanger(
      dealerPrice ? Number(convertNumericStringToNumber(dealerPrice)) : getNettoMsrp(msrp, taxRate),
      dealerPrice ? dealerPriceCurrency : msrpCurrency,
      distributorPriceCurrency,
      exchangeRates
    )
  }

  const getDistributorPriceBasedOnMargin = (
    msrp: number | string,
    msrpCurrency: actionTypes.TCurrencyCode,
    taxRate: number | string,
    dealerPriceCurrency: actionTypes.TCurrencyCode,
    distributorMargin: number | string,
    distributorPriceCurrency: actionTypes.TCurrencyCode,
    indexOfTableRowItem: number
  ) => {
    const documentDealerPrice: any = document.getElementsByName(
      `bomCalculations.${indexOfTableRowItem}.dealerPrice`
    )[0]

    if (
      msrp &&
      msrpCurrency &&
      (taxRate || String(taxRate) === '0') &&
      distributorMargin &&
      distributorPriceCurrency
    ) {
      const dealerOrMsrpExchanged = getDealerPriceOrNettoMsrpExchangedToDistributorCurrency(
        msrp,
        msrpCurrency,
        taxRate,
        documentDealerPrice?.value,
        dealerPriceCurrency,
        distributorPriceCurrency
      )

      const distributorMarginPrice =
        (Number(convertNumericStringToNumber(distributorMargin)) / 100) * dealerOrMsrpExchanged

      return dealerOrMsrpExchanged - distributorMarginPrice
    } else {
      return ''
    }
  }

  const getDistributorPriceExchangedToMsrpOrDealerCurrency = (
    tableRowItem: actionTypes.IBomCalculation,
    distributorPrice: number | string
  ) => {
    return currencyExchanger(
      Number(convertNumericStringToNumber(distributorPrice)),
      tableRowItem.distributorPriceCurrency,
      tableRowItem.dealerPrice ? tableRowItem.dealerPriceCurrency : tableRowItem.msrpCurrency,
      exchangeRates
    )
  }

  const calculateIncome = (indexOfTableRowItem: number) => {
    const dealerPrice: any = document.getElementsByName(
      `bomCalculations.${indexOfTableRowItem}.dealerPrice`
    )[0]

    const dealerPriceCurrency: any = document.getElementsByName(
      `bomCalculations.${indexOfTableRowItem}.dealerPriceCurrency`
    )[0]

    const dealerQuantity: any = document.getElementsByName(
      `bomCalculations.${indexOfTableRowItem}.dealerQuantity`
    )[0]

    const distributorPrice: any = document.getElementsByName(
      `bomCalculations.${indexOfTableRowItem}.distributorPrice`
    )[0]

    const distributorPriceCurrency: any = document.getElementsByName(
      `bomCalculations.${indexOfTableRowItem}.distributorPriceCurrency`
    )[0]

    const distributorQuantity: any = document.getElementsByName(
      `bomCalculations.${indexOfTableRowItem}.distributorQuantity`
    )[0]

    const incomeCurrency: any = document.getElementsByName(
      `bomCalculations.${indexOfTableRowItem}.incomeCurrency`
    )[0]

    if ((dealerPrice && dealerQuantity) || (distributorPrice && distributorQuantity)) {
      const calculationResult =
        (currencyExchanger(
          Number(convertNumericStringToNumber(dealerPrice?.value)),
          dealerPriceCurrency?.value,
          incomeCurrency?.value,
          exchangeRates
        ) * Number(convertNumericStringToNumber(dealerQuantity?.value)) || 0) +
        (Number(
          currencyExchanger(
            Number(convertNumericStringToNumber(distributorPrice?.value)),
            distributorPriceCurrency?.value,
            incomeCurrency?.value,
            exchangeRates
          )
        ) * Number(convertNumericStringToNumber(distributorQuantity?.value)) || 0)

      return calculationResult || ''
    } else {
      return ''
    }
  }

  const updateDealerAndDistributorValuesInForm = (
    tableRowItem: actionTypes.IBomCalculation,
    indexOfTableRowItem: number
  ) => {
    setTimeout(() => {
      setFieldValue(
        `bomCalculations.${indexOfTableRowItem}.dealerPrice`,
        convertNumberToNumericString(
          getDealerPriceBasedOnMargin(
            tableRowItem.msrp,
            tableRowItem.msrpCurrency,
            tableRowItem.taxRate,
            tableRowItem.dealerPriceCurrency,
            tableRowItem.dealerMargin
          )
        )
      )
    }, 200)

    setTimeout(() => {
      setFieldValue(
        `bomCalculations.${indexOfTableRowItem}.distributorPrice`,
        convertNumberToNumericString(
          getDistributorPriceBasedOnMargin(
            tableRowItem.msrp,
            tableRowItem.msrpCurrency,
            tableRowItem.taxRate,
            tableRowItem.dealerPriceCurrency,
            tableRowItem.distributorMargin,
            tableRowItem.distributorPriceCurrency,
            indexOfTableRowItem
          )
        )
      )
    }, 300)
  }

  return (
    <FieldArray
      name="bomCalculations"
      render={(arrayHelpers) => (
        <>
          <div className="bom-other-items-table-wrapper">
            <CDataTable
              addTableClasses={`bom-other-items-table ${
                values.bomCalculations.length ? '' : 'mb-0'
              }`}
              loading={bomsState.isSingleBomLoading}
              striped={false}
              fields={[
                {
                  key: 'position',
                  label: '#',
                  _style: { width: '45px' },
                },
                {
                  key: 'msrp',
                  label: 'MSRP',
                  _style: { width: '190px' },
                },
                {
                  key: 'taxRate',
                  label: 'VAT',
                  _style: { width: '115px' },
                },
                {
                  key: 'dealerPrice',
                  label: 'Cena dealera',
                  _style: { width: '190px' },
                },
                {
                  key: 'dealerMargin',
                  label: 'Marża',
                  _style: { width: '130px' },
                },
                {
                  key: 'dealerQuantity',
                  label: 'Ilość',
                  _style: { width: '100px' },
                },
                {
                  key: 'distributorPrice',
                  label: 'Cena dystrybutora',
                  _style: { width: '190px' },
                },
                {
                  key: 'distributorMargin',
                  label: 'Marża',
                  _style: { width: '130px' },
                },
                {
                  key: 'distributorQuantity',
                  label: 'Ilość',
                  _style: { width: '100px' },
                },
                {
                  key: 'income',
                  label: 'Przychody',
                  _style: { width: '230px' },
                },
                {
                  key: 'remove',
                  label: '',
                  _style: { width: '50px' },
                },
              ]}
              items={values.bomCalculations}
              noItemsViewSlot={<div></div>}
              scopedSlots={{
                position: (
                  tableRowItem: actionTypes.IBomCalculation,
                  indexOfTableRowItem: number
                ) => (
                  <td
                    key={`bom-calculations-table-cell-${indexOfTableRowItem}-${tableRowItem?.id}-position`}
                    style={{ lineHeight: '2.4', textAlign: numeralsTextAlign }}
                  >
                    {indexOfTableRowItem + 1}.
                  </td>
                ),

                msrp: (tableRowItem: actionTypes.IBomCalculation, indexOfTableRowItem: number) => (
                  <td
                    key={`bom-calculations-table-cell-${indexOfTableRowItem}-${tableRowItem?.id}-msrp`}
                  >
                    <InputGroup>
                      <Field
                        as={NumberFormat}
                        displayType="input"
                        thousandSeparator={globalThousandSeparator}
                        decimalSeparator={globalDecimalSeparator}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        allowNegative={false}
                        allowLeadingZeros={true}
                        name={`bomCalculations.${indexOfTableRowItem}.msrp`}
                        placeholder={`msrp ${indexOfTableRowItem + 1}`}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          return handleChange(event)
                        }}
                        onPaste={(event: ChangeEvent<HTMLInputElement>) => {
                          handleNumberFormatPaste(event)
                        }}
                        onBlur={() => {
                          updateDealerAndDistributorValuesInForm(tableRowItem, indexOfTableRowItem)
                        }}
                        className={`form-control text-right ${
                          errors.bomCalculations !== undefined &&
                          typeof errors.bomCalculations !== 'string' &&
                          errors.bomCalculations[indexOfTableRowItem] &&
                          errors.bomCalculations[indexOfTableRowItem]?.msrp
                            ? 'invalid-field-without-exclamation'
                            : 'currency-input-field'
                        } ${isBomChangeOccuring && 'table-disabled-input'}`}
                        disabled={isBomChangeOccuring}
                      />
                      <InputGroupAddon addonType="append">
                        <CurrencyPicker
                          handleChange={handleChange}
                          fieldName={`bomCalculations.${indexOfTableRowItem}.msrpCurrency`}
                          // Do not set here the adminState.mainCurrency because Formik will anyway remember it as null so it is better to show a placeholder
                          dynamicCurrency={tableRowItem?.msrpCurrency || ''}
                          formSetter={(event: ChangeEvent<HTMLInputElement>) => {
                            handleChange(event)

                            updateDealerAndDistributorValuesInForm(
                              tableRowItem,
                              indexOfTableRowItem
                            )

                            return handleChange(event)
                          }}
                        />
                      </InputGroupAddon>
                    </InputGroup>
                  </td>
                ),

                taxRate: (
                  tableRowItem: actionTypes.IBomCalculation,
                  indexOfTableRowItem: number
                ) => (
                  <td
                    key={`bom-calculations-table-cell-${indexOfTableRowItem}-${tableRowItem?.id}-tax-rate`}
                  >
                    {
                      // Adjusted for numeric field: remove comment
                    }
                    <InputGroup>
                      <Field
                        as={NumberFormat}
                        displayType="input"
                        thousandSeparator={globalThousandSeparator}
                        decimalSeparator={globalDecimalSeparator}
                        decimalScale={2}
                        fixedDecimalScale={false}
                        allowNegative={false}
                        allowLeadingZeros={true}
                        name={`bomCalculations.${indexOfTableRowItem}.taxRate`}
                        placeholder="VAT"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          return handleChange(event)
                        }}
                        onPaste={(event: ChangeEvent<HTMLInputElement>) => {
                          handleNumberFormatPaste(event)
                        }}
                        onBlur={() => {
                          updateDealerAndDistributorValuesInForm(tableRowItem, indexOfTableRowItem)
                        }}
                        className={`form-control text-right ${
                          errors.bomCalculations !== undefined &&
                          typeof errors.bomCalculations !== 'string' &&
                          errors.bomCalculations[indexOfTableRowItem] &&
                          errors.bomCalculations[indexOfTableRowItem]?.taxRate
                            ? 'invalid-field-without-exclamation'
                            : 'currency-input-field'
                        } ${isBomChangeOccuring && 'table-disabled-input'}`}
                        disabled={isBomChangeOccuring}
                      />

                      <InputGroupAddon addonType="append">
                        <InputGroupText
                          className="disabled-currency"
                          style={{
                            width: '35px',
                          }}
                        >
                          %
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </td>
                ),

                dealerPrice: (
                  tableRowItem: actionTypes.IBomCalculation,
                  indexOfTableRowItem: number
                ) => (
                  <td
                    key={`bom-calculations-table-cell-${indexOfTableRowItem}-${tableRowItem?.id}-dealer-price`}
                  >
                    <InputGroup>
                      <Field
                        as={NumberFormat}
                        displayType="input"
                        thousandSeparator={globalThousandSeparator}
                        decimalSeparator={globalDecimalSeparator}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        allowNegative={false}
                        allowLeadingZeros={true}
                        name={`bomCalculations.${indexOfTableRowItem}.dealerPrice`}
                        placeholder="cena dealera"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          // Keep event.currentTarget.value to enable input change on demand
                          return event.currentTarget.value
                        }}
                        onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                          if (event.currentTarget.value) {
                            const dealerPrice = event.currentTarget.value

                            const dealerMargin =
                              dealerPrice &&
                              tableRowItem.msrp &&
                              (tableRowItem.taxRate || String(tableRowItem.taxRate) === '0') &&
                              tableRowItem.dealerPriceCurrency
                                ? Math.round(
                                    10000 *
                                      (1 -
                                        getExchangedDealerPrice(tableRowItem, dealerPrice) /
                                          getNettoMsrp(tableRowItem.msrp, tableRowItem.taxRate))
                                  ) / 100
                                : ''

                            if (dealerMargin) {
                              setFieldValue(
                                `bomCalculations.${indexOfTableRowItem}.dealerMargin`,
                                dealerMargin
                              )
                            }
                          }

                          return handleChange(event)
                        }}
                        onPaste={(event: ChangeEvent<HTMLInputElement>) => {
                          handleNumberFormatPaste(event)
                        }}
                        value={convertNumberToNumericString(
                          getDealerPriceBasedOnMargin(
                            tableRowItem.msrp,
                            tableRowItem.msrpCurrency,
                            tableRowItem.taxRate,
                            tableRowItem.dealerPriceCurrency,
                            tableRowItem.dealerMargin
                          )
                        )}
                        className={`form-control text-right ${
                          (String(tableRowItem.taxRate) === '0' ? false : !tableRowItem.taxRate) ||
                          !tableRowItem.msrp ||
                          !tableRowItem.dealerPriceCurrency
                            ? 'table-disabled-input'
                            : ''
                        } ${isBomChangeOccuring && 'table-disabled-input'}`}
                        disabled={
                          (String(tableRowItem.taxRate) === '0' ? false : !tableRowItem.taxRate) ||
                          !tableRowItem.msrp ||
                          !tableRowItem.dealerPriceCurrency ||
                          isBomChangeOccuring
                        }
                      />
                      <InputGroupAddon addonType="append">
                        <CurrencyPicker
                          handleChange={handleChange}
                          fieldName={`bomCalculations.${indexOfTableRowItem}.dealerPriceCurrency`}
                          // Do not set here the adminState.mainCurrency because Formik will anyway remember it as null so it is better to show a placeholder
                          dynamicCurrency={tableRowItem?.dealerPriceCurrency || ''}
                          formSetter={(event: ChangeEvent<HTMLInputElement>) => {
                            // This is very important to keep the income value stable in form
                            setTimeout(() => {
                              setFieldValue(
                                `bomCalculations.${indexOfTableRowItem}.dealerPrice`,
                                convertNumberToNumericString(tableRowItem.dealerPrice)
                              )
                            }, 250)

                            return handleChange(event)
                          }}
                        />
                      </InputGroupAddon>
                    </InputGroup>
                  </td>
                ),

                dealerMargin: (
                  tableRowItem: actionTypes.IBomCalculation,
                  indexOfTableRowItem: number
                ) => (
                  <td
                    key={`bom-calculations-table-cell-${indexOfTableRowItem}-${tableRowItem?.id}-dealer-margin`}
                  >
                    <InputGroup>
                      <Field
                        as={NumberFormat}
                        displayType="input"
                        thousandSeparator={globalThousandSeparator}
                        decimalSeparator={globalDecimalSeparator}
                        decimalScale={2}
                        fixedDecimalScale={false}
                        allowNegative={true}
                        allowLeadingZeros={true}
                        name={`bomCalculations.${indexOfTableRowItem}.dealerMargin`}
                        placeholder="marża"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          setFieldValue(
                            `bomCalculations.${indexOfTableRowItem}.dealerMargin`,
                            event.currentTarget.value || ''
                          )

                          return handleChange(event)
                        }}
                        onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                          updateDealerAndDistributorValuesInForm(tableRowItem, indexOfTableRowItem)

                          return handleChange(event)
                        }}
                        onPaste={(event: ChangeEvent<HTMLInputElement>) => {
                          handleNumberFormatPaste(event)
                        }}
                        className={`form-control text-right ${
                          errors.bomCalculations !== undefined &&
                          typeof errors.bomCalculations !== 'string' &&
                          errors.bomCalculations[indexOfTableRowItem] &&
                          errors.bomCalculations[indexOfTableRowItem]?.dealerMargin
                            ? 'invalid-field-without-exclamation'
                            : 'currency-input-field'
                        } ${isBomChangeOccuring && 'table-disabled-input'}`}
                        disabled={isBomChangeOccuring}
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText
                          className="disabled-currency"
                          style={{
                            width: '35px',
                          }}
                        >
                          %
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </td>
                ),

                dealerQuantity: (
                  tableRowItem: actionTypes.IBomCalculation,
                  indexOfTableRowItem: number
                ) => (
                  <td
                    key={`bom-calculations-table-cell-${indexOfTableRowItem}-${tableRowItem?.id}-quantity-from-dealer`}
                  >
                    <InputGroup>
                      <Field
                        as={NumberFormat}
                        displayType="input"
                        thousandSeparator={globalThousandSeparator}
                        decimalSeparator={globalDecimalSeparator}
                        decimalScale={2}
                        fixedDecimalScale={false}
                        allowNegative={false}
                        allowLeadingZeros={true}
                        name={`bomCalculations.${indexOfTableRowItem}.dealerQuantity`}
                        placeholder="ilość"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          return handleChange(event)
                        }}
                        onPaste={(event: ChangeEvent<HTMLInputElement>) => {
                          handleNumberFormatPaste(event)
                        }}
                        className={`form-control text-right ${
                          isBomChangeOccuring && 'table-disabled-input'
                        }`}
                        disabled={isBomChangeOccuring}
                      />
                    </InputGroup>
                  </td>
                ),

                distributorPrice: (
                  tableRowItem: actionTypes.IBomCalculation,
                  indexOfTableRowItem: number
                ) => (
                  <td
                    key={`bom-calculations-table-cell-${indexOfTableRowItem}-${tableRowItem?.id}-distributor-price`}
                  >
                    <InputGroup>
                      <Field
                        as={NumberFormat}
                        displayType="input"
                        thousandSeparator={globalThousandSeparator}
                        decimalSeparator={globalDecimalSeparator}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        allowNegative={false}
                        allowLeadingZeros={true}
                        name={`bomCalculations.${indexOfTableRowItem}.distributorPrice`}
                        placeholder="cena dystrybutora"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          return event.currentTarget.value
                        }}
                        onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                          if (event?.currentTarget?.value) {
                            const distributorPrice = event.currentTarget.value

                            if (distributorPrice) {
                              const exchangedPriceToDistributorCurrency =
                                getDealerPriceOrNettoMsrpExchangedToDistributorCurrency(
                                  tableRowItem.msrp,
                                  tableRowItem.msrpCurrency,
                                  tableRowItem.taxRate,
                                  tableRowItem.dealerPrice,
                                  tableRowItem.dealerPriceCurrency,
                                  tableRowItem.distributorPriceCurrency
                                )

                              const distributorMargin =
                                tableRowItem.msrp &&
                                (tableRowItem.taxRate || String(tableRowItem.taxRate) === '0') &&
                                tableRowItem.distributorPriceCurrency
                                  ? Math.round(
                                      10000 *
                                        (1 -
                                          getDistributorPriceExchangedToMsrpOrDealerCurrency(
                                            tableRowItem,
                                            distributorPrice
                                          ) /
                                            exchangedPriceToDistributorCurrency)
                                    ) / 100
                                  : ''

                              if (distributorMargin) {
                                setFieldValue(
                                  `bomCalculations.${indexOfTableRowItem}.distributorMargin`,
                                  distributorMargin
                                )
                              }
                            }

                            return handleChange(event)
                          }
                        }}
                        onPaste={(event: ChangeEvent<HTMLInputElement>) => {
                          handleNumberFormatPaste(event)
                        }}
                        value={convertNumberToNumericString(
                          getDistributorPriceBasedOnMargin(
                            tableRowItem.msrp,
                            tableRowItem.msrpCurrency,
                            tableRowItem.taxRate,
                            tableRowItem.dealerPriceCurrency,
                            tableRowItem.distributorMargin,
                            tableRowItem.distributorPriceCurrency,
                            indexOfTableRowItem
                          )
                        )}
                        className={`form-control text-right ${
                          (String(tableRowItem.taxRate) === '0' ? false : !tableRowItem.taxRate) ||
                          !tableRowItem.msrp ||
                          !tableRowItem.distributorPriceCurrency
                            ? 'table-disabled-input'
                            : ''
                        } ${isBomChangeOccuring && 'table-disabled-input'}`}
                        disabled={
                          (String(tableRowItem.taxRate) === '0' ? false : !tableRowItem.taxRate) ||
                          !tableRowItem.msrp ||
                          !tableRowItem.distributorPriceCurrency ||
                          isBomChangeOccuring
                        }
                      />
                      <InputGroupAddon addonType="append">
                        <CurrencyPicker
                          handleChange={handleChange}
                          fieldName={`bomCalculations.${indexOfTableRowItem}.distributorPriceCurrency`}
                          // Do not set here the adminState.mainCurrency because Formik will anyway remember it as null so it is better to show a placeholder
                          dynamicCurrency={tableRowItem?.distributorPriceCurrency || ''}
                          formSetter={(event: ChangeEvent<HTMLInputElement>) => {
                            // This is very important to keep the income value stable in form
                            setTimeout(() => {
                              setFieldValue(
                                `bomCalculations.${indexOfTableRowItem}.distributorPrice`,
                                convertNumberToNumericString(tableRowItem.distributorPrice)
                              )
                            }, 250)

                            return handleChange(event)
                          }}
                        />
                      </InputGroupAddon>
                    </InputGroup>
                  </td>
                ),

                distributorMargin: (
                  tableRowItem: actionTypes.IBomCalculation,
                  indexOfTableRowItem: number
                ) => (
                  <td
                    key={`bom-calculations-table-cell-${indexOfTableRowItem}-${tableRowItem?.id}-distributor-margin`}
                  >
                    <InputGroup>
                      <Field
                        as={NumberFormat}
                        displayType="input"
                        thousandSeparator={globalThousandSeparator}
                        decimalSeparator={globalDecimalSeparator}
                        decimalScale={2}
                        fixedDecimalScale={false}
                        allowNegative={true}
                        allowLeadingZeros={true}
                        name={`bomCalculations.${indexOfTableRowItem}.distributorMargin`}
                        placeholder="marża"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          setFieldValue(
                            `bomCalculations.${indexOfTableRowItem}.distributorMargin`,
                            event.currentTarget.value || ''
                          )

                          return handleChange(event)
                        }}
                        onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                          updateDealerAndDistributorValuesInForm(tableRowItem, indexOfTableRowItem)

                          return handleChange(event)
                        }}
                        onPaste={(event: ChangeEvent<HTMLInputElement>) => {
                          handleNumberFormatPaste(event)
                        }}
                        className={`form-control text-right ${
                          errors.bomCalculations !== undefined &&
                          typeof errors.bomCalculations !== 'string' &&
                          errors.bomCalculations[indexOfTableRowItem] &&
                          errors.bomCalculations[indexOfTableRowItem]?.distributorMargin
                            ? 'invalid-field-without-exclamation'
                            : ''
                        } ${isBomChangeOccuring && 'table-disabled-input'}`}
                        disabled={isBomChangeOccuring}
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText
                          className="disabled-currency"
                          style={{
                            width: '35px',
                          }}
                        >
                          %
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </td>
                ),

                distributorQuantity: (
                  tableRowItem: actionTypes.IBomCalculation,
                  indexOfTableRowItem: number
                ) => (
                  <td
                    key={`bom-calculations-table-cell-${indexOfTableRowItem}-${tableRowItem?.id}-quantity-from-distributor`}
                  >
                    <InputGroup>
                      <Field
                        as={NumberFormat}
                        displayType="input"
                        thousandSeparator={globalThousandSeparator}
                        decimalSeparator={globalDecimalSeparator}
                        decimalScale={2}
                        fixedDecimalScale={false}
                        allowNegative={false}
                        allowLeadingZeros={true}
                        name={`bomCalculations.${indexOfTableRowItem}.distributorQuantity`}
                        placeholder="ilość"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          return handleChange(event)
                        }}
                        onPaste={(event: ChangeEvent<HTMLInputElement>) => {
                          handleNumberFormatPaste(event)
                        }}
                        className={`form-control text-right ${
                          isBomChangeOccuring && 'table-disabled-input'
                        }`}
                        disabled={isBomChangeOccuring}
                      />
                    </InputGroup>
                  </td>
                ),

                income: (
                  tableRowItem: actionTypes.IBomCalculation,
                  indexOfTableRowItem: number
                ) => (
                  <td
                    key={`bom-calculations-table-cell-${indexOfTableRowItem}-${tableRowItem?.id}-income`}
                  >
                    <InputGroup>
                      <Field
                        as={NumberFormat}
                        displayType="input"
                        thousandSeparator={globalThousandSeparator}
                        decimalSeparator={globalDecimalSeparator}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        allowNegative={false}
                        allowLeadingZeros={true}
                        name={`bomCalculations.${indexOfTableRowItem}.income`}
                        placeholder="przychody"
                        value={calculateIncome(indexOfTableRowItem)}
                        className="form-control text-right table-disabled-input"
                        disabled
                      />
                      <InputGroupAddon addonType="append">
                        <CurrencyPicker
                          handleChange={handleChange}
                          fieldName={`bomCalculations.${indexOfTableRowItem}.incomeCurrency`}
                          // Do not set here the adminState.mainCurrency because Formik will anyway remember it as null so it is better to show a placeholder
                          dynamicCurrency={tableRowItem?.incomeCurrency || ''}
                        />
                      </InputGroupAddon>
                    </InputGroup>
                  </td>
                ),

                remove: (
                  tableRowItem: actionTypes.IBomCalculation,
                  indexOfTableRowItem: number
                ) => (
                  <td className="text-center">
                    <CButton
                      color="danger"
                      type="button"
                      variant="outline"
                      onClick={() => {
                        arrayHelpers.remove(indexOfTableRowItem)
                      }}
                      className="select-option-remove-button"
                      disabled={isBomChangeOccuring}
                    >
                      <i className="cil-trash"></i>
                    </CButton>
                  </td>
                ),
              }}
            />
          </div>

          {(errors.bomCalculations &&
            typeof errors.bomCalculations !== 'string' &&
            values.bomCalculations?.length && (
              <div className="text-danger d-flex justify-content-center mb-4 text-center">
                {errors?.bomCalculations?.some((error: any) => error?.msrp || error?.taxRate) &&
                  'Zaznaczone na czerwono pola MSRP i VAT w tabeli są wymagane!'}

                {errors?.bomCalculations?.some(
                  (error: any) => error?.dealerMargin || error?.distributorMargin
                ) ? (
                  <>
                    {errors?.bomCalculations?.some(
                      (error: any) => error?.msrp || error?.taxRate
                    ) && <br />}
                    Marża dealera i dystrybutora nie może być ujemna!
                  </>
                ) : null}
              </div>
            )) ||
            null}

          <div
            className={`p-0 ${
              values.bomCalculations.length ? 'mt-3' : 'mt-0'
            } mb-0 d-flex justify-content-center`}
          >
            <CButton
              color="info"
              type="button"
              variant="outline"
              onClick={() => {
                const newBomCalculationInTable: actionTypes.IBomCalculation = {
                  msrp: '',
                  msrpCurrency:
                    settingsState?.settings?.otherSettings?.defaultSystemCurrency ||
                    globalCurrencyFallback,

                  taxRate: '',

                  distributorPrice: '',
                  distributorPriceCurrency:
                    settingsState?.settings?.otherSettings?.defaultSystemCurrency ||
                    globalCurrencyFallback,
                  distributorMargin: '',
                  distributorQuantity: '',

                  dealerPrice: '',
                  dealerPriceCurrency:
                    settingsState?.settings?.otherSettings?.defaultSystemCurrency ||
                    globalCurrencyFallback,
                  dealerMargin: '',
                  dealerQuantity: '',

                  income: '',
                  incomeCurrency:
                    settingsState?.settings?.otherSettings?.defaultSystemCurrency ||
                    globalCurrencyFallback,

                  bomId: 0,
                  id: null,
                  uuId: uuidv4(),
                }

                arrayHelpers.push(newBomCalculationInTable)

                setIsBomModifiedAndUnsaved(true)
              }}
              className="px-5"
              disabled={isBomChangeOccuring}
            >
              Dodaj pozycję
            </CButton>
          </div>
        </>
      )}
    />
  )
}
