import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilAccountLogout, cilCog, cilUser } from '@coreui/icons'

import { TRootState } from '../store/reducers'

export const DropdownsHeader: React.FC<{}> = () => {
  const history = useHistory()
  const { tenant } = useParams<{ tenant: string }>()

  const authState = useSelector((state: TRootState) => state.auth)

  return (
    <CDropdown inNav className="c-header-nav-items">
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <div className="c-avatar border">
          <CIcon icon={cilUser} />
        </div>
      </CDropdownToggle>

      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem header tag="div" color="light" className="text-center">
          Konto
          {authState.authData?.username ? (
            <strong>
              <br />
              {authState.authData?.username}
            </strong>
          ) : (
            ''
          )}
        </CDropdownItem>
        <CDropdownItem
          onClick={() => {
            history.push(`/${tenant}/admin/settings`, { fromPageToSettings: history.location })
          }}
          className="mt-2 d-flex justify-content-center align-items-center"
        >
          <CIcon icon={cilCog} style={{ marginLeft: '-4px', marginTop: '1px' }} className="mr-2" />
          Ustawienia
        </CDropdownItem>
        <CDropdownItem divider />
        <CDropdownItem
          onClick={() => {
            history.push(`/${tenant}/logout`)
          }}
          className="mb-1 d-flex justify-content-center align-items-center"
        >
          <div
            style={{ width: '20px', paddingBottom: '2px', marginLeft: '-2px' }}
            className="mr-2 text-center"
          >
            <CIcon icon={cilAccountLogout} />
          </div>
          Wyloguj się
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}
