import React, { useEffect, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { CModal, CModalHeader } from '@coreui/react'

import * as actionTypes from '../../store/action-types'
import { TRootState } from '../../store/reducers'
import { BasicFormField, FormActionsPanel, CustomErrorMessage } from '../../components'
import { getErrorMessageFromStatus, successMessageDuration } from '../../utils'

interface IProducerElementModal {
  mode: actionTypes.TFormMode
}

export const ProducerModal: React.FC<IProducerElementModal> = ({ mode }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tenant } = useParams<{ tenant: string }>()

  const [didFormValidationOccur, setDidFormValidationOccur] = useState(false)

  const authState = useSelector((state: TRootState) => state.auth)
  const producersState = useSelector((state: TRootState) => state.producers)

  const isProducerEditable =
    authState?.authData?.roles?.includes('Producers_write') &&
    (mode === 'edit' ? !!producersState.editedProducer : true)

  const closeProducerModal = useCallback(() => {
    history.push(`/${tenant}/admin/producers`)
  }, [dispatch, history, tenant])

  // Close modal on a successful create or delete
  useEffect(() => {
    if (
      (!producersState.isProducerCreating &&
        !producersState.producerCreateError &&
        producersState.isProducerCreated) ||
      (!producersState.isProducerDeleting &&
        !producersState.producerDeleteError &&
        producersState.isProducerDeleted)
    ) {
      setTimeout(() => {
        closeProducerModal()
      }, successMessageDuration)
    }
  }, [producersState.isProducerCreated, producersState.isProducerDeleted, closeProducerModal])

  // Unmount Component
  useEffect(() => {
    return () => {
      dispatch({
        type: actionTypes.CLOSE_PRODUCER_MODAL,
      })
    }
  }, [])

  const ProducerSchema = Yup.object().shape({
    name: Yup.string()
      .required('To pole jest wymagane!')
      .test('name', 'Wpisany producent już istnieje!', (producerNameInInput) =>
        producersState.editedProducer?.name.toLowerCase() !==
        String(producerNameInInput).toLowerCase()
          ? !producersState.producers?.find(
              (producerInList: actionTypes.TProducer) =>
                producerInList?.name.toLowerCase() === String(producerNameInInput).toLowerCase()
            )
          : true
      ),
  })

  return (
    <CModal
      onClose={() => {
        closeProducerModal()
      }}
      size=""
      show={producersState.producerModalVisibility}
      centered
      backdrop
      addContentClass="producer-modal py-4 px-5"
    >
      <CModalHeader className="c-modal-header-grid" closeButton>
        <h4 className="mb-3 pb-1 text-center">
          {mode === 'edit'
            ? !authState?.authData?.roles?.includes('Producers_write')
              ? 'Przeglądaj Producenta'
              : 'Edytuj Producenta'
            : 'Nowy Producent'}
        </h4>
      </CModalHeader>
      <Formik
        initialValues={{
          name: producersState.editedProducer?.name || '',
          code: producersState.editedProducer?.code || '',
        }}
        validationSchema={ProducerSchema}
        onSubmit={(values) => {
          if (isProducerEditable) {
            dispatch({
              type:
                mode === 'create'
                  ? actionTypes.CREATE_PRODUCER_REQUESTED
                  : actionTypes.EDIT_PRODUCER_REQUESTED,
              payload: {
                tenant: tenant,
                token: authState.authData?.token,
                producer: {
                  name: values.name,
                  code: values.code,
                  canDelete: true,
                  ...(producersState.editedProducer?.id && {
                    id: producersState.editedProducer?.id,
                  }),
                },
              },
            })
          }
        }}
        validateOnBlur={false}
        validateOnChange={didFormValidationOccur}
      >
        {({ initialValues, values, errors, resetForm }) => (
          <Form>
            {/*
             * Display Network Error Message
             */}

            {!errors.name && producersState?.producerCreateError && (
              <CustomErrorMessage
                wrapperClassNames="my-3"
                customErrorMessageText={getErrorMessageFromStatus(
                  'create',
                  producersState?.producerCreateError?.status
                )}
              />
            )}

            {!errors.name && producersState?.producerEditSaveError && (
              <CustomErrorMessage
                wrapperClassNames="my-3"
                customErrorMessageText={getErrorMessageFromStatus(
                  'edit',
                  producersState?.producerEditSaveError?.status,
                  'producenta'
                )}
              />
            )}

            {producersState?.producerDeleteError && (
              <CustomErrorMessage
                wrapperClassNames="my-3"
                customErrorMessageText={getErrorMessageFromStatus(
                  'delete',
                  producersState?.producerDeleteError?.status,
                  'producenta'
                )}
              />
            )}

            <BasicFormField
              fieldId="producer-name"
              fieldLabel="Nazwa"
              fieldIcon="cil-short-text"
              formikFieldName="name"
              fieldValue={values.name}
              fieldError={errors?.name}
              fieldType="text"
              placeholder="Wprowadź nazwę producenta"
            />

            <BasicFormField
              fieldId="producer-code"
              fieldLabel="Kod (Skrót)"
              fieldIcon="cil-barcode"
              formikFieldName="code"
              fieldValue={values.code}
              fieldError={errors?.code}
              fieldType="text"
              placeholder="Wprowadź kod producenta"
            />

            <FormActionsPanel
              mode={mode}
              padding="pt-4 pb-1"
              isSaving={producersState.isProducerCreating || producersState.isProducerEditSaving}
              isSaved={
                mode === 'create'
                  ? producersState.isProducerCreated
                  : producersState.isProducerEditSaved
              }
              isDeleting={producersState.isProducerDeleting}
              isDeleted={producersState.isProducerDeleted}
              setDidFormValidationOccur={setDidFormValidationOccur}
              didFormValidationOccur={didFormValidationOccur}
              formErrorsBool={Boolean(errors.name)}
              closeAction={actionTypes.CLOSE_PRODUCER_MODAL}
              deleteAction={actionTypes.DELETE_PRODUCER_REQUESTED}
              deletePayload={{
                tenant: tenant,
                token: authState.authData?.token,
                id: producersState.editedProducer?.id,
              }}
              closeFunction={closeProducerModal}
              canDelete={Boolean(producersState.editedProducer?.canDelete)}
              isEditable={isProducerEditable}
              disabledDeleteButtonClassNames="delete-details-button"
              confirmDeleteMessageJSX={
                <>
                  Czy na pewno chcesz usunąć producenta?
                  {initialValues?.name ? (
                    <>
                      <br />
                      <strong>{initialValues?.name}</strong>
                    </>
                  ) : (
                    ''
                  )}
                </>
              }
            />
          </Form>
        )}
      </Formik>
    </CModal>
  )
}
