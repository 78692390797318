import React from 'react'
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from '@coreui/react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { sidebarNavigation } from './sidebar-nav'
import { TRootState } from '../store/reducers'

export const Sidebar: React.FC<{}> = () => {
  const { tenant } = useParams<{ tenant: string }>()
  const dispatch = useDispatch()
  const sidebarVisibility = useSelector((state: TRootState) => state.admin.sidebarVisibility)
  const sidebarMinimize = useSelector((state: TRootState) => state.admin.sidebarMinimize)
  const authState = useSelector((state: TRootState) => state.auth)

  return (
    <CSidebar
      className="sidebar"
      style={{ zIndex: 2000 }}
      show={sidebarVisibility}
      onShowChange={(visibility: boolean) =>
        dispatch({ type: 'SET_SIDEBAR_VISIBILITY', sidebarVisibility: visibility })
      }
      onMinimizeChange={(minimize: boolean) =>
        dispatch({ type: 'SET_SIDEBAR_MINIMIZE', sidebarMinimize: !minimize })
      }
    >
      <CSidebarBrand className="d-md-down-none sidebar-title" to={`/${tenant}/admin/boms`}>
        {tenant === 'polyend' && sidebarVisibility && (
          <img
            src={
              process.env.PUBLIC_URL +
              `/images/polyend_${sidebarMinimize ? 'icon' : 'logo_wide'}.png`
            }
            className={sidebarMinimize ? 'sidebar-logo' : 'sidebar-logo-wide'}
            alt=""
          />
        )}
        {tenant !== 'polyend' && tenant.replace(/\b\w/g, (letter: string) => letter.toUpperCase())}
      </CSidebarBrand>
      <CSidebarNav>
        <CCreateElement
          items={sidebarNavigation(tenant, authState.authData?.roles)}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle,
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  )
}
