import { call, cancelled, put, takeLatest, takeLeading } from 'redux-saga/effects'

import {
  loginCall,
  authenticationCall,
  requestPasswordResetCall,
  passwordResetCall,
  passwordResetTokenCheckCall,
} from './api'
import * as actionTypes from '../action-types'

export function* login(action: actionTypes.LoginRequestedAction) {
  try {
    const response = yield call(loginCall, action.payload)
    yield put({ type: actionTypes.LOGIN_SUCCEEDED })
    yield put({ type: actionTypes.SAVE_AUTH_DATA, authData: response.data })
    yield localStorage.setItem('polysystemAuthData', JSON.stringify(response.data))
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.LOGIN_ERROR,
      error: error?.response,
    })
  } finally {
    if (yield cancelled()) {
      yield put({ type: actionTypes.LOGIN_CANCELLED })
    }
  }
}

export function* logout() {
  try {
    yield localStorage.removeItem('polysystemAuthData')
    yield put({ type: actionTypes.DELETE_TOKEN })
  } catch (error: any) {
    console.log(error)
  } finally {
    yield put({ type: actionTypes.LOGOUT_SUCCEEDED })
  }
}

export function* authenticate(action: actionTypes.AuthenticationRequestedAction) {
  try {
    yield call(authenticationCall, action.payload)
    yield put({ type: actionTypes.AUTHENTICATION_SUCCEEDED })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.AUTHENTICATION_ERROR,
      error: error?.response,
    })
  } finally {
    if (yield cancelled()) {
      yield put({ type: actionTypes.AUTHENTICATION_CANCELLED })
    }
  }
}

export function* requestPasswordReset(action: actionTypes.RequestPasswordResetAction) {
  try {
    yield call(requestPasswordResetCall, action.payload)
    yield put({ type: actionTypes.REQUEST_PASSWORD_RESET_SUCCEEDED })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.REQUEST_PASSWORD_RESET_ERROR,
      error: error?.response,
    })
  } finally {
    if (yield cancelled()) {
      yield put({ type: actionTypes.REQUEST_PASSWORD_RESET_CANCELLED })
    }
  }
}

export function* passwordResetTokenCheck(action: actionTypes.PasswordResetTokenCheckAction) {
  try {
    yield call(passwordResetTokenCheckCall, action.payload)
    yield put({ type: actionTypes.PASSWORD_RESET_TOKEN_CHECK_SUCCEEDED })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.PASSWORD_RESET_TOKEN_CHECK_ERROR,
      error: error?.response,
    })
  } finally {
    if (yield cancelled()) {
      yield put({ type: actionTypes.PASSWORD_RESET_TOKEN_CHECK_CANCELLED })
    }
  }
}

export function* passwordReset(action: actionTypes.PasswordResetAction) {
  try {
    yield call(passwordResetCall, action.payload)
    yield put({ type: actionTypes.PASSWORD_RESET_SUCCEEDED })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.PASSWORD_RESET_ERROR,
      error: error?.response,
    })
  } finally {
    if (yield cancelled()) {
      yield put({ type: actionTypes.PASSWORD_RESET_CANCELLED })
    }
  }
}

export function* authSaga() {
  yield takeLatest(actionTypes.LOGIN_REQUESTED, login)
  yield takeLatest(actionTypes.LOGOUT_REQUESTED, logout)
  yield takeLatest(actionTypes.AUTHENTICATION_REQUESTED, authenticate)
  yield takeLeading(actionTypes.REQUEST_PASSWORD_RESET_REQUESTED, requestPasswordReset)
  yield takeLatest(actionTypes.PASSWORD_RESET_TOKEN_CHECK_REQUESTED, passwordResetTokenCheck)
  yield takeLatest(actionTypes.PASSWORD_RESET_REQUESTED, passwordReset)
}
