import React, { ChangeEvent } from 'react'
import { FastField } from 'formik'
import { useLocation } from 'react-router-dom'
import Select from '@material-ui/core/Select'

import { SimplifiedCurrencies } from '../utils'
import * as actionTypes from '../store/action-types'
import { TCurrencyCode } from '../store/action-types'

interface Picker {
  handleChange?: (e: React.ChangeEvent<any>) => void
  setterFunction?: any
  dynamicCurrency: TCurrencyCode | null
  fieldName: string
  formSetter?: any
}

export const CurrencyPicker: React.FC<Picker> = ({
  handleChange,
  dynamicCurrency,
  fieldName,
  formSetter,
}) => {
  let location = useLocation()

  return (
    <FastField
      as={Select}
      native
      className={`item-selector currency-selector ${
        location.pathname.includes('order/') && location.pathname.includes('delivery-')
          ? 'delivery-currency-selector'
          : ''
      }`}
      variant="outlined"
      onChange={(event: ChangeEvent<HTMLSelectElement>) => {
        if (document && document.activeElement) {
          const elem: any = document.activeElement
          elem.blur()
        }

        if (handleChange) {
          return handleChange(event)
        }
      }}
      onBlur={(event: ChangeEvent<HTMLSelectElement>) => {
        if (formSetter) {
          formSetter(event)
        }
      }}
      value={dynamicCurrency}
      name={fieldName}
    >
      {!dynamicCurrency && (
        <option key={`${fieldName}-null-currency-...`} value={''}>
          ...
        </option>
      )}

      {SimplifiedCurrencies?.map(
        (currencyCode: actionTypes.TCurrencyCode, currencyIndex: number) => {
          return (
            <option key={`${fieldName}-${currencyIndex}-${currencyCode}`} value={currencyCode}>
              {currencyCode}
            </option>
          )
        }
      )}
    </FastField>
  )
}
