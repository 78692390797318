import React from 'react'

export interface ICustomErrorMessage {
  wrapperClassNames: string
  messageClassNames?: string
  customErrorMessageText: string
}

export const CustomErrorMessage: React.FC<ICustomErrorMessage> = ({
  wrapperClassNames,
  messageClassNames = '',
  customErrorMessageText,
}) => {
  return (
    <div className={`custom-alert alert-danger ${wrapperClassNames}`}>
      <span className="custom-alert-danger-icon">
        <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
      </span>

      <div className={`alert-message ${messageClassNames}`}>{customErrorMessageText}</div>
    </div>
  )
}
