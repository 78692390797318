export const getErrorMessageFromStatus = (
  mode: 'create' | 'fetch' | 'edit' | 'delete' | 'generatePDF',
  status: number | undefined | null,
  dataAltName?: string
) => {
  switch (status) {
    case 400:
      return `Błąd 400! Wysłano niepoprawny format danych${
        mode === 'generatePDF' ? ' do wygenerowania PDF' : ''
      }!`
    case 401:
      return `Nie masz wymaganych uprawnień by ${
        mode === 'delete' ? 'usunąć' : mode === 'fetch' ? 'pobrać' : 'zmienić'
      } te dane!`
    case 403:
      return 'Nie masz dostępu do tego zasobu!'
    case 404:
      return 'Błąd 404! Nie odnaleziono danych lub zapytania do serwera!'
    case 405:
      return 'Błąd 405! Zastosowano niedozwoloną metodę HTTP!'
    case 417:
      return 'Podana firma nie istnieje w naszej bazie danych!'
    default:
      return `Wystąpił ${status ? `błąd ${status}` : 'nieznany błąd'} podczas ${
        mode === 'create' || mode === 'generatePDF'
          ? `wysyłania`
          : mode === 'fetch'
          ? `pobierania`
          : mode === 'delete'
          ? `usuwania`
          : 'edycji'
      } ${dataAltName ? dataAltName : 'danych'}!`
  }
}
