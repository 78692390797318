import * as actionTypes from '../action-types'

export interface IBomElementsState {
  bomElements: actionTypes.TBomElement[] | any
  areBomElementsLoading: boolean
  fetchBomElementsError: actionTypes.TNetworkError

  tableSorterState: actionTypes.TSorterState | undefined
  tableFilterState: actionTypes.TFilterState

  isSingleBomElementLoading: boolean
  editedBomElement: actionTypes.TBomElement | null
  singleBomElementFetchError: actionTypes.TNetworkError

  editedBomElementBoms: actionTypes.TBomLight[] | null
  areBomElementBomsLoading: boolean
  fetchBomElementBomsError: actionTypes.TNetworkError

  editedBomElementOffers: actionTypes.TOffer[] | null
  areBomElementOffersLoading: boolean
  fetchBomElementOffersError: actionTypes.TNetworkError

  editedBomElementOrders: actionTypes.TOrder[] | null
  areBomElementOrdersLoading: boolean
  fetchBomElementOrdersError: actionTypes.TNetworkError

  editedBomElementAttachments: actionTypes.TBomElementAttachment[] | null
  areBomElementAttachmentsLoading: boolean
  fetchBomElementAttachmentsError: actionTypes.TNetworkError
  isBomElementAttachmentUploading: boolean
  bomElementAttachmentUploadingError: actionTypes.TNetworkError
  isBomElementAttachmentUploaded: boolean

  isBomElementCreating: boolean
  bomElementCreateError: actionTypes.TNetworkError
  isBomElementCreated: boolean

  isBomElementEditSaving: boolean
  bomElementEditSaveError: actionTypes.TNetworkError
  isBomElementEditSaved: boolean

  isBomElementDeleting: boolean
  bomElementDeleteError: actionTypes.TNetworkError
  isBomElementDeleted: boolean

  currentlyManagedBomElementId: number | null

  isBomElementCopying: boolean
  isBomElementCopied: boolean
  bomElementCopyError: actionTypes.TNetworkError

  isBomElementArchiving: boolean
  isBomElementArchived: boolean
  bomElementArchiveError: actionTypes.TNetworkError
}

const initialState: IBomElementsState = {
  bomElements: [],
  areBomElementsLoading: false,
  fetchBomElementsError: null,

  tableSorterState: undefined,
  tableFilterState: undefined,

  isSingleBomElementLoading: false,
  editedBomElement: null,
  singleBomElementFetchError: null,

  editedBomElementBoms: null,
  areBomElementBomsLoading: false,
  fetchBomElementBomsError: null,

  editedBomElementOffers: null,
  areBomElementOffersLoading: false,
  fetchBomElementOffersError: null,

  editedBomElementOrders: null,
  areBomElementOrdersLoading: false,
  fetchBomElementOrdersError: null,

  editedBomElementAttachments: null,
  areBomElementAttachmentsLoading: false,
  fetchBomElementAttachmentsError: null,
  isBomElementAttachmentUploading: false,
  bomElementAttachmentUploadingError: null,
  isBomElementAttachmentUploaded: false,

  isBomElementCreating: false,
  bomElementCreateError: null,
  isBomElementCreated: false,

  isBomElementEditSaving: false,
  bomElementEditSaveError: null,
  isBomElementEditSaved: false,

  isBomElementDeleting: false,
  bomElementDeleteError: null,
  isBomElementDeleted: false,

  currentlyManagedBomElementId: null,

  isBomElementCopying: false,
  isBomElementCopied: false,
  bomElementCopyError: null,

  isBomElementArchiving: false,
  isBomElementArchived: false,
  bomElementArchiveError: null,
}

export default function bomElementsReducer(
  state = initialState,
  action: actionTypes.BomElementsActionTypes
): IBomElementsState {
  switch (action.type) {
    case actionTypes.FETCH_BOM_ELEMENTS_REQUESTED: {
      return {
        ...state,
        fetchBomElementsError: null,
        areBomElementsLoading: true,
      }
    }

    case actionTypes.FETCH_BOM_ELEMENTS_SUCCEEDED: {
      return {
        ...state,
        bomElements: action.bomElements,
        areBomElementsLoading: false,
      }
    }

    case actionTypes.FETCH_BOM_ELEMENTS_ERROR: {
      return {
        ...state,
        fetchBomElementsError: action.error,
        areBomElementsLoading: false,
      }
    }

    case actionTypes.SET_BOM_ELEMENTS_TABLE_FILTER_STATE: {
      return {
        ...state,
        tableFilterState: action.tableFilterState,
      }
    }

    case actionTypes.SET_BOM_ELEMENTS_TABLE_SORTER_STATE: {
      return {
        ...state,
        tableSorterState: action.tableSorterState,
      }
    }

    case actionTypes.SET_EDITED_BOM_ELEMENT: {
      return {
        ...state,
        editedBomElement: action.bomElement,
        singleBomElementFetchError: null,
        bomElementCreateError: null,
        bomElementEditSaveError: null,
        bomElementDeleteError: null,
        editedBomElementAttachments: null,
      }
    }

    case actionTypes.FETCH_SINGLE_BOM_ELEMENT_REQUESTED: {
      return {
        ...state,
        isSingleBomElementLoading: true,
        singleBomElementFetchError: null,
      }
    }

    case actionTypes.FETCH_SINGLE_BOM_ELEMENT_SUCCEEDED: {
      return {
        ...state,
        singleBomElementFetchError: null,
        isSingleBomElementLoading: false,
        editedBomElement: action.bomElement,
      }
    }

    case actionTypes.FETCH_SINGLE_BOM_ELEMENT_ERROR: {
      return {
        ...state,
        singleBomElementFetchError: action.error,
        isSingleBomElementLoading: false,
      }
    }

    case actionTypes.FETCH_BOM_ELEMENT_BOMS_REQUESTED: {
      return {
        ...state,
        fetchBomElementBomsError: null,
        areBomElementBomsLoading: true,
      }
    }

    case actionTypes.FETCH_BOM_ELEMENT_BOMS_SUCCEEDED: {
      return {
        ...state,
        editedBomElementBoms: action.bomElementBoms,
        areBomElementBomsLoading: false,
      }
    }

    case actionTypes.FETCH_BOM_ELEMENT_BOMS_ERROR: {
      return {
        ...state,
        fetchBomElementBomsError: action.error,
        areBomElementBomsLoading: false,
      }
    }

    case actionTypes.FETCH_BOM_ELEMENT_OFFERS_REQUESTED: {
      return {
        ...state,
        fetchBomElementOffersError: null,
        areBomElementOffersLoading: true,
      }
    }

    case actionTypes.FETCH_BOM_ELEMENT_OFFERS_SUCCEEDED: {
      return {
        ...state,
        editedBomElementOffers: action.bomElementOffers,
        areBomElementOffersLoading: false,
      }
    }

    case actionTypes.FETCH_BOM_ELEMENT_OFFERS_ERROR: {
      return {
        ...state,
        fetchBomElementOffersError: action.error,
        areBomElementOffersLoading: false,
      }
    }

    case actionTypes.CREATE_BOM_ELEMENT_REQUESTED: {
      return {
        ...state,
        bomElementCreateError: null,
        isBomElementCreating: true,
        isBomElementCreated: false,
      }
    }

    case actionTypes.CREATE_BOM_ELEMENT_SUCCEEDED: {
      return {
        ...state,
        isBomElementCreating: false,
        bomElements: [...state.bomElements, action.bomElement],
        isBomElementCreated: true,
      }
    }

    case actionTypes.CREATE_BOM_ELEMENT_ERROR: {
      return {
        ...state,
        bomElementCreateError: action.error,
        isBomElementCreating: false,
        isBomElementCreated: true,
      }
    }

    case actionTypes.EDIT_BOM_ELEMENT_REQUESTED: {
      return {
        ...state,
        bomElementEditSaveError: null,
        isBomElementEditSaving: true,
        isBomElementEditSaved: false,
      }
    }

    case actionTypes.EDIT_BOM_ELEMENT_SUCCEEDED: {
      return {
        ...state,
        isBomElementEditSaving: false,
        isBomElementEditSaved: true,
        bomElements: state.bomElements?.map((bomElement: actionTypes.TBomElement) => {
          if (bomElement.id === action.bomElement.id) {
            return action.bomElement
          } else {
            return bomElement
          }
        }),
      }
    }

    case actionTypes.EDIT_BOM_ELEMENT_ERROR: {
      return {
        ...state,
        bomElementEditSaveError: action.error,
        isBomElementEditSaving: false,
        isBomElementEditSaved: false,
      }
    }

    case actionTypes.COPY_BOM_ELEMENT_REQUESTED: {
      return {
        ...state,
        bomElementCopyError: null,
        isBomElementCopying: true,
        isBomElementCopied: false,
        currentlyManagedBomElementId: action.payload.id,
      }
    }

    case actionTypes.COPY_BOM_ELEMENT_SUCCEEDED: {
      return {
        ...state,
        isBomElementCopying: false,
        isBomElementCopied: true,
        bomElements: [...state.bomElements, action.bomElement],
        currentlyManagedBomElementId: null,
      }
    }

    case actionTypes.COPY_BOM_ELEMENT_ERROR: {
      return {
        ...state,
        bomElementCopyError: action.error,
        isBomElementCopying: false,
        isBomElementCopied: false,
        currentlyManagedBomElementId: null,
      }
    }

    case actionTypes.ARCHIVE_BOM_ELEMENT_REQUESTED: {
      return {
        ...state,
        bomElementArchiveError: null,
        isBomElementArchiving: true,
        isBomElementArchived: false,
        currentlyManagedBomElementId: action.payload.id,
      }
    }

    case actionTypes.ARCHIVE_BOM_ELEMENT_SUCCEEDED: {
      return {
        ...state,
        isBomElementArchiving: false,
        isBomElementArchived: true,
        bomElements: state.bomElements.filter(
          (bomElement: actionTypes.TLightBomElement) => bomElement.id !== action.bomElement.id
        ),
        currentlyManagedBomElementId: null,
      }
    }

    case actionTypes.ARCHIVE_BOM_ELEMENT_ERROR: {
      return {
        ...state,
        bomElementArchiveError: action.error,
        isBomElementArchiving: false,
        isBomElementArchived: false,
        currentlyManagedBomElementId: null,
      }
    }

    case actionTypes.DELETE_BOM_ELEMENT_REQUESTED: {
      return {
        ...state,
        bomElementDeleteError: null,
        isBomElementDeleting: true,
        isBomElementDeleted: false,
      }
    }

    case actionTypes.DELETE_BOM_ELEMENT_SUCCEEDED: {
      return {
        ...state,
        isBomElementDeleting: false,
        isBomElementDeleted: true,
        bomElements: state.bomElements.filter(
          (bomElement: actionTypes.TBomElement) => bomElement.id !== action.id
        ),
        editedBomElementAttachments: null,
      }
    }

    case actionTypes.DELETE_BOM_ELEMENT_ERROR: {
      return {
        ...state,
        bomElementDeleteError: action.error,
        isBomElementDeleting: false,
        isBomElementDeleted: false,
      }
    }

    case actionTypes.FETCH_BOM_ELEMENT_ATTACHMENTS_REQUESTED: {
      return {
        ...state,
        editedBomElementAttachments: null,
        fetchBomElementAttachmentsError: null,
        areBomElementAttachmentsLoading: true,
      }
    }

    case actionTypes.FETCH_BOM_ELEMENT_ATTACHMENTS_SUCCEEDED: {
      return {
        ...state,
        editedBomElementAttachments: action.bomElementAttachments,
        areBomElementAttachmentsLoading: false,
      }
    }

    case actionTypes.FETCH_BOM_ELEMENT_ATTACHMENTS_ERROR: {
      return {
        ...state,
        fetchBomElementAttachmentsError: action.error,
        areBomElementAttachmentsLoading: false,
      }
    }

    case actionTypes.UPLOAD_BOM_ELEMENT_ATTACHMENT_REQUESTED: {
      return {
        ...state,
        bomElementAttachmentUploadingError: null,
        isBomElementAttachmentUploading: true,
        isBomElementAttachmentUploaded: false,
      }
    }

    case actionTypes.UPLOAD_BOM_ELEMENT_ATTACHMENT_SUCCEEDED: {
      return {
        ...state,
        isBomElementAttachmentUploading: false,
        isBomElementAttachmentUploaded: true,
        editedBomElementAttachments: state.editedBomElementAttachments
          ? [...state.editedBomElementAttachments, action.attachment]
          : [action.attachment],
      }
    }

    case actionTypes.UPLOAD_BOM_ELEMENT_ATTACHMENT_ERROR: {
      return {
        ...state,
        bomElementAttachmentUploadingError: action.error,
        isBomElementAttachmentUploading: false,
        isBomElementAttachmentUploaded: false,
      }
    }

    case actionTypes.DELETE_BOM_ELEMENT_ATTACHMENT_SUCCEEDED: {
      return {
        ...state,
        editedBomElementAttachments:
          state.editedBomElementAttachments?.filter(
            (attachment: actionTypes.TAttachment) => attachment.id !== action.payload.id
          ) || [],
      }
    }

    case actionTypes.BOM_ELEMENT_ACTIONS_UNLOCK: {
      return {
        ...state,
        isSingleBomElementLoading: false,
        isBomElementEditSaving: false,
        isBomElementDeleting: false,
        isBomElementCreating: false,

        isBomElementEditSaved: false,
        isBomElementDeleted: false,
        isBomElementCreated: false,

        bomElementAttachmentUploadingError: null,
        isBomElementAttachmentUploading: false,
        isBomElementAttachmentUploaded: false,
      }
    }

    default:
      return state
  }
}
