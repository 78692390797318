import * as actionTypes from '../action-types'

export interface IProducersState {
  producers: actionTypes.TProducer[] | any
  areProducersLoading: boolean
  fetchProducersError: actionTypes.TNetworkError

  tableSorterState: actionTypes.TSorterState | undefined
  tableFilterState: actionTypes.TFilterState
  producerModalVisibility: boolean
  editedProducer: actionTypes.TProducer | null

  isProducerCreating: boolean
  producerCreateError: actionTypes.TNetworkError
  isProducerCreated: boolean

  isProducerDeleting: boolean
  producerDeleteError: actionTypes.TNetworkError
  isProducerDeleted: boolean

  isProducerEditSaving: boolean
  producerEditSaveError: actionTypes.TNetworkError
  isProducerEditSaved: boolean
}

const initialState: IProducersState = {
  producers: [],
  areProducersLoading: false,
  fetchProducersError: null,

  tableSorterState: undefined,
  tableFilterState: undefined,
  producerModalVisibility: false,
  editedProducer: null,

  isProducerCreating: false,
  producerCreateError: null,
  isProducerCreated: false,

  isProducerDeleting: false,
  producerDeleteError: null,
  isProducerDeleted: false,

  isProducerEditSaving: false,
  producerEditSaveError: null,
  isProducerEditSaved: false,
}

export default function producersReducer(
  state = initialState,
  action: actionTypes.ProducersActionTypes
): IProducersState {
  switch (action.type) {
    case actionTypes.FETCH_PRODUCERS_REQUESTED: {
      return {
        ...state,
        fetchProducersError: null,
        areProducersLoading: true,
      }
    }

    case actionTypes.FETCH_PRODUCERS_SUCCEEDED: {
      return {
        ...state,
        producers: action.producers,
        areProducersLoading: false,
      }
    }

    case actionTypes.FETCH_PRODUCERS_ERROR: {
      return {
        ...state,
        fetchProducersError: action.error,
        areProducersLoading: false,
      }
    }

    case actionTypes.SET_PRODUCERS_TABLE_FILTER_STATE: {
      return {
        ...state,
        tableFilterState: action.tableFilterState,
      }
    }

    case actionTypes.SET_PRODUCERS_TABLE_SORTER_STATE: {
      return {
        ...state,
        tableSorterState: action.tableSorterState,
      }
    }

    case actionTypes.CREATE_PRODUCER_REQUESTED: {
      return {
        ...state,
        producerCreateError: null,
        isProducerCreating: true,
        isProducerCreated: false,
      }
    }

    case actionTypes.CREATE_PRODUCER_SUCCEEDED: {
      return {
        ...state,
        isProducerCreating: false,
        isProducerCreated: true,
        producers: [...state.producers, action.producer],
      }
    }

    case actionTypes.CREATE_PRODUCER_ERROR: {
      return {
        ...state,
        producerCreateError: action.error,
        isProducerCreating: false,
        isProducerCreated: false,
      }
    }

    case actionTypes.EDIT_PRODUCER_REQUESTED: {
      return {
        ...state,
        producerEditSaveError: null,
        isProducerEditSaving: true,
        isProducerEditSaved: false,
      }
    }

    case actionTypes.EDIT_PRODUCER_SUCCEEDED: {
      return {
        ...state,
        isProducerEditSaving: false,
        isProducerEditSaved: true,
        producers: state.producers?.map((producer: actionTypes.TProducer) => {
          if (producer.id === action.producer.id) {
            return action.producer
          } else {
            return producer
          }
        }),
      }
    }

    case actionTypes.EDIT_PRODUCER_ERROR: {
      return {
        ...state,
        producerEditSaveError: action.error,
        isProducerEditSaving: false,
        isProducerEditSaved: false,
      }
    }

    case actionTypes.DELETE_PRODUCER_REQUESTED: {
      return {
        ...state,
        producerDeleteError: null,
        isProducerDeleting: true,
        isProducerDeleted: false,
      }
    }

    case actionTypes.DELETE_PRODUCER_SUCCEEDED: {
      return {
        ...state,
        isProducerDeleting: false,
        isProducerDeleted: true,
        producers: state.producers.filter(
          (producer: actionTypes.TProducer) => producer.id !== action.id
        ),
      }
    }

    case actionTypes.DELETE_PRODUCER_ERROR: {
      return {
        ...state,
        producerDeleteError: action.error,
        isProducerDeleting: false,
        isProducerDeleted: false,
      }
    }

    case actionTypes.ADD_PRODUCER_TO_STORE: {
      return {
        ...state,
        producers: state.producers.find(
          (producer: actionTypes.TProducer) => producer?.id === action.producer?.id
        )
          ? state.producers
          : [...state.producers, action.producer],
      }
    }

    case actionTypes.OPEN_PRODUCER_MODAL: {
      return {
        ...state,
        producerModalVisibility: true,
        editedProducer: action.editedProducer,
      }
    }

    case actionTypes.CLOSE_PRODUCER_MODAL: {
      return {
        ...state,
        producerCreateError: null,
        producerEditSaveError: null,
        producerDeleteError: null,

        isProducerCreating: false,
        isProducerEditSaving: false,
        isProducerDeleting: false,

        isProducerCreated: false,
        isProducerEditSaved: false,
        isProducerDeleted: false,

        producerModalVisibility: false,
        editedProducer: null,
      }
    }

    default:
      return state
  }
}
