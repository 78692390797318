import { TNetworkError } from './auth'
import { TAdminPayload, ISingleItemActionPayload, TFilterState, TSorterState } from './admin'

export const FETCH_SUPPLIERS_REQUESTED = 'FETCH_SUPPLIERS_REQUESTED'
export const FETCH_SUPPLIERS_SUCCEEDED = 'FETCH_SUPPLIERS_SUCCEEDED'
export const FETCH_SUPPLIERS_ERROR = 'FETCH_SUPPLIERS_ERROR'

export const SET_SUPPLIERS_TABLE_FILTER_STATE = 'SET_SUPPLIERS_TABLE_FILTER_STATE'
export const SET_SUPPLIERS_TABLE_SORTER_STATE = 'SET_SUPPLIERS_TABLE_SORTER_STATE'

export const FETCH_SINGLE_SUPPLIER_REQUESTED = 'FETCH_SINGLE_SUPPLIER_REQUESTED'
export const FETCH_SINGLE_SUPPLIER_ERROR = 'FETCH_SINGLE_SUPPLIER_ERROR'
export const FETCH_SINGLE_SUPPLIER_SUCCEEDED = 'FETCH_SINGLE_SUPPLIER_SUCCEEDED'

export const CREATE_SUPPLIER_REQUESTED = 'CREATE_SUPPLIER_REQUESTED'
export const CREATE_SUPPLIER_SUCCEEDED = 'CREATE_SUPPLIER_SUCCEEDED'
export const CREATE_SUPPLIER_ERROR = 'CREATE_SUPPLIER_ERROR'

export const EDIT_SUPPLIER_REQUESTED = 'EDIT_SUPPLIER_REQUESTED'
export const EDIT_SUPPLIER_SUCCEEDED = 'EDIT_SUPPLIER_SUCCEEDED'
export const EDIT_SUPPLIER_ERROR = 'EDIT_SUPPLIER_ERROR'

export const DELETE_SUPPLIER_REQUESTED = 'DELETE_SUPPLIER_REQUESTED'
export const DELETE_SUPPLIER_SUCCEEDED = 'DELETE_SUPPLIER_SUCCEEDED'
export const DELETE_SUPPLIER_ERROR = 'DELETE_SUPPLIER_ERROR'

export const SET_EDITED_SUPPLIER = 'SET_EDITED_SUPPLIER'

export const SUPPLIER_ACTIONS_UNLOCK = 'SUPPLIER_ACTIONS_UNLOCK'

export type TSupplier = {
  name: string
  contactPerson: string
  contactEmail: string
  country: string
  contactPhone?: string
  icon?: string

  id: number
  supplierId?: number
  canDelete: boolean
}

export type TPaymentInfo = {
  id: number
  percent: string | number
  description: string
}

export type TSupplierContact = {
  contactPerson: string
  email: string
  phone: string
  info: string | null
  id: number | null
  uuid: string
}

export type TSupplierDetails = {
  id?: number
  canDelete: boolean
  mainInfo: {
    name: string
    contacts: TSupplierContact[]
  }
  tradeInfo: {
    status: 1 | 2 | 3 | 4
    producerIsAlsoSupplier: boolean
    paymentType: 1 | 2
    days: number
    iban: string
    bicSwift: string
    bankAddress: string
    prepaidPaymentInfos: TPaymentInfo[]
    taxNumber1: string
    taxNumber2: string
  }
  address: {
    id: number
    address1: string
    address2: string
    address3: string
    postCode: string
    city: string
    country: string
  }
}

export interface ICreateSupplierPayload extends TAdminPayload {
  supplier: TSupplierDetails
}

// FETCH_SUPPLIERS_REQUESTED

export interface fetchSuppliersAction {
  type: typeof FETCH_SUPPLIERS_REQUESTED
  payload: TAdminPayload
}

export interface fetchSuppliersSucceeded {
  type: typeof FETCH_SUPPLIERS_SUCCEEDED
  suppliers: TSupplier[]
}

export interface fetchSuppliersError {
  type: typeof FETCH_SUPPLIERS_ERROR
  error: TNetworkError
}

// SET_SUPPLIERS_TABLE_FILTER_STATE

export interface setSuppliersTableFilterState {
  type: typeof SET_SUPPLIERS_TABLE_FILTER_STATE
  tableFilterState: TFilterState
}

// SET_SUPPLIERS_TABLE_SORTER_STATE

export interface setSuppliersTableSorterState {
  type: typeof SET_SUPPLIERS_TABLE_SORTER_STATE
  tableSorterState: TSorterState
}

// FETCH_SINGLE_SUPPLIER_REQUESTED

export interface fetchSingleSupplierAction {
  type: typeof FETCH_SINGLE_SUPPLIER_REQUESTED
  payload: ISingleItemActionPayload
}

export interface fetchSingleSupplierSucceeded {
  type: typeof FETCH_SINGLE_SUPPLIER_SUCCEEDED
  editedSupplier: TSupplierDetails
}

export interface fetchSingleSupplierError {
  type: typeof FETCH_SINGLE_SUPPLIER_ERROR
  error: TNetworkError
}

// CREATE_SUPPLIER_REQUESTED

export interface createSupplierAction {
  type: typeof CREATE_SUPPLIER_REQUESTED
  payload: ICreateSupplierPayload
}

export interface createSupplierSucceeded {
  type: typeof CREATE_SUPPLIER_SUCCEEDED
  supplier: TSupplierDetails
}

export interface createSupplierError {
  type: typeof CREATE_SUPPLIER_ERROR
  error: TNetworkError
}

// EDIT_SUPPLIER_REQUESTED

export interface editSupplierAction {
  type: typeof EDIT_SUPPLIER_REQUESTED
  payload: ICreateSupplierPayload
}

export interface editSupplierSucceeded {
  type: typeof EDIT_SUPPLIER_SUCCEEDED
  supplier: TSupplierDetails
}

export interface editSupplierError {
  type: typeof EDIT_SUPPLIER_ERROR
  error: TNetworkError
}

// DELETE_SUPPLIER_REQUESTED

export interface deleteSupplierAction {
  type: typeof DELETE_SUPPLIER_REQUESTED
  payload: ISingleItemActionPayload
}

export interface deleteSupplierSucceeded {
  type: typeof DELETE_SUPPLIER_SUCCEEDED
  id: number
}

export interface deleteSupplierError {
  type: typeof DELETE_SUPPLIER_ERROR
  error: TNetworkError
}

// SET_EDITED_SUPPLIER

export interface setEditedSupplier {
  type: typeof SET_EDITED_SUPPLIER
  supplier: TSupplierDetails | null
}

// SUPPLIER_ACTIONS_UNLOCK

export interface supplierActionsUnlockAction {
  type: typeof SUPPLIER_ACTIONS_UNLOCK
}

export type SuppliersActionTypes =
  | fetchSuppliersAction
  | fetchSuppliersSucceeded
  | fetchSuppliersError
  | setSuppliersTableFilterState
  | setSuppliersTableSorterState
  | fetchSingleSupplierAction
  | fetchSingleSupplierError
  | fetchSingleSupplierSucceeded
  | createSupplierAction
  | createSupplierSucceeded
  | createSupplierError
  | editSupplierAction
  | editSupplierSucceeded
  | editSupplierError
  | deleteSupplierAction
  | deleteSupplierSucceeded
  | deleteSupplierError
  | setEditedSupplier
  | supplierActionsUnlockAction
