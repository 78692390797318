import * as actionTypes from '../action-types'

export interface IOffersState {
  offers: actionTypes.TLightOfferInTable[] | []
  areOffersLoading: boolean
  fetchOffersError: actionTypes.TNetworkError

  tableSorterState: actionTypes.TSorterState | undefined
  tableFilterState: actionTypes.TFilterState

  isSingleOfferLoading: boolean
  editedOffer: actionTypes.TOffer | null
  singleOfferFetchError: actionTypes.TNetworkError

  isOfferCreating: boolean
  offerCreateError: actionTypes.TNetworkError
  isOfferCreated: boolean

  isOfferEditSaving: boolean
  offerEditSaveError: actionTypes.TNetworkError
  isOfferEditSaved: boolean

  isOfferDeleting: boolean
  offerDeleteError: actionTypes.TNetworkError
  isOfferDeleted: boolean

  editedOfferAttachments: actionTypes.TOfferAttachment[] | null
  areOfferAttachmentsLoading: boolean
  fetchOfferAttachmentsError: actionTypes.TNetworkError

  isOfferAttachmentUploading: boolean
  offerAttachmentUploadingError: actionTypes.TNetworkError
  isOfferAttachmentUploaded: boolean
}

const initialState: IOffersState = {
  offers: [],
  fetchOffersError: null,
  areOffersLoading: false,

  tableSorterState: undefined,
  tableFilterState: undefined,

  isSingleOfferLoading: false,
  editedOffer: null,
  singleOfferFetchError: null,

  isOfferCreating: false,
  offerCreateError: null,
  isOfferCreated: false,

  isOfferEditSaving: false,
  offerEditSaveError: null,
  isOfferEditSaved: false,

  isOfferDeleting: false,
  offerDeleteError: null,
  isOfferDeleted: false,

  editedOfferAttachments: null,
  areOfferAttachmentsLoading: false,
  fetchOfferAttachmentsError: null,
  isOfferAttachmentUploading: false,
  offerAttachmentUploadingError: null,
  isOfferAttachmentUploaded: false,
}

export default function offersReducer(
  state = initialState,
  action: actionTypes.OffersActionTypes
): IOffersState {
  switch (action.type) {
    case actionTypes.FETCH_OFFERS_REQUESTED: {
      return {
        ...state,
        fetchOffersError: null,
        areOffersLoading: true,
      }
    }

    case actionTypes.FETCH_OFFERS_SUCCEEDED: {
      return {
        ...state,
        offers: action.offers,
        areOffersLoading: false,
      }
    }

    case actionTypes.FETCH_OFFERS_ERROR: {
      return {
        ...state,
        fetchOffersError: action.error,
        areOffersLoading: false,
      }
    }

    case actionTypes.SET_OFFERS_TABLE_FILTER_STATE: {
      return {
        ...state,
        tableFilterState: action.tableFilterState,
      }
    }

    case actionTypes.SET_OFFERS_TABLE_SORTER_STATE: {
      return {
        ...state,
        tableSorterState: action.tableSorterState,
      }
    }

    case actionTypes.CREATE_OFFER_REQUESTED: {
      return {
        ...state,
        offerCreateError: null,
        isOfferCreating: true,
        isOfferCreated: false,
      }
    }

    case actionTypes.CREATE_OFFER_SUCCEEDED: {
      return {
        ...state,
        isOfferCreating: false,
        isOfferCreated: true,
      }
    }

    case actionTypes.CREATE_OFFER_ERROR: {
      return {
        ...state,
        offerCreateError: action.error,
        isOfferCreating: false,
        isOfferCreated: false,
      }
    }

    case actionTypes.FETCH_SINGLE_OFFER_REQUESTED: {
      return {
        ...state,
        editedOfferAttachments: null,
        isSingleOfferLoading: true,
        singleOfferFetchError: null,
      }
    }

    case actionTypes.FETCH_SINGLE_OFFER_SUCCEEDED: {
      return {
        ...state,
        singleOfferFetchError: null,
        isSingleOfferLoading: false,
        editedOffer: action.editedOffer,
      }
    }

    case actionTypes.FETCH_SINGLE_OFFER_ERROR: {
      return {
        ...state,
        singleOfferFetchError: action.error,
        isSingleOfferLoading: false,
      }
    }

    case actionTypes.EDIT_OFFER_REQUESTED: {
      return {
        ...state,
        offerEditSaveError: null,
        isOfferEditSaving: true,
        isOfferEditSaved: false,
      }
    }

    case actionTypes.EDIT_OFFER_SUCCEEDED: {
      return {
        ...state,
        isOfferEditSaving: false,
        isOfferEditSaved: true,
      }
    }

    case actionTypes.EDIT_OFFER_ERROR: {
      return {
        ...state,
        offerEditSaveError: action.error,
        isOfferEditSaving: false,
        isOfferEditSaved: false,
      }
    }

    case actionTypes.DELETE_OFFER_REQUESTED: {
      return {
        ...state,
        offerDeleteError: null,
        isOfferDeleting: true,
        isOfferDeleted: false,
      }
    }

    case actionTypes.DELETE_OFFER_SUCCEEDED: {
      return {
        ...state,
        areOffersLoading: false,
        isOfferDeleting: false,
        isOfferDeleted: true,
        offers: state.offers.filter(
          (offer: actionTypes.TLightOfferInTable) => offer.id !== action.id
        ),
      }
    }

    case actionTypes.DELETE_OFFER_ERROR: {
      return {
        ...state,
        offerDeleteError: action.error,
        isOfferDeleting: false,
        isOfferDeleted: false,
      }
    }

    case actionTypes.FETCH_OFFER_ATTACHMENTS_REQUESTED: {
      return {
        ...state,
        editedOfferAttachments: null,
        fetchOfferAttachmentsError: null,
        areOfferAttachmentsLoading: true,
      }
    }

    case actionTypes.FETCH_OFFER_ATTACHMENTS_SUCCEEDED: {
      return {
        ...state,
        editedOfferAttachments: action.offerAttachments,
        areOfferAttachmentsLoading: false,
      }
    }

    case actionTypes.FETCH_OFFER_ATTACHMENTS_ERROR: {
      return {
        ...state,
        fetchOfferAttachmentsError: action.error,
        areOfferAttachmentsLoading: false,
      }
    }

    case actionTypes.UPLOAD_OFFER_ATTACHMENT_REQUESTED: {
      return {
        ...state,
        offerAttachmentUploadingError: null,
        isOfferAttachmentUploading: true,
        isOfferAttachmentUploaded: false,
      }
    }

    case actionTypes.UPLOAD_OFFER_ATTACHMENT_SUCCEEDED: {
      return {
        ...state,
        isOfferAttachmentUploading: false,
        isOfferAttachmentUploaded: true,
        editedOfferAttachments: state.editedOfferAttachments
          ? [...state.editedOfferAttachments, action.attachment]
          : [action.attachment],
      }
    }

    case actionTypes.UPLOAD_OFFER_ATTACHMENT_ERROR: {
      return {
        ...state,
        offerAttachmentUploadingError: action.error,
        isOfferAttachmentUploading: false,
        isOfferAttachmentUploaded: false,
      }
    }

    case actionTypes.DELETE_OFFER_ATTACHMENT_SUCCEEDED: {
      return {
        ...state,
        editedOfferAttachments:
          state.editedOfferAttachments?.filter(
            (attachment: actionTypes.TAttachment) => attachment.id !== action.payload.id
          ) || [],
      }
    }

    case actionTypes.CLOSE_OFFER_DETAILS: {
      return {
        ...state,
        singleOfferFetchError: null,
        offerCreateError: null,
        offerEditSaveError: null,
        offerDeleteError: null,

        isSingleOfferLoading: false,
        isOfferDeleting: false,
        isOfferEditSaving: false,
        isOfferCreating: false,

        editedOffer: null,
        isOfferDeleted: false,
        isOfferEditSaved: false,
        isOfferCreated: false,

        editedOfferAttachments: null,
        areOfferAttachmentsLoading: false,
        fetchOfferAttachmentsError: null,
        isOfferAttachmentUploading: false,
        offerAttachmentUploadingError: null,
        isOfferAttachmentUploaded: false,
      }
    }

    case actionTypes.OFFER_ACTIONS_UNLOCK: {
      return {
        ...state,
        isOfferEditSaved: false,
        isOfferEditSaving: false,
        isOfferCreated: false,
        isOfferCreating: false,
        isOfferDeleted: false,
        isOfferDeleting: false,

        offerAttachmentUploadingError: null,
        isOfferAttachmentUploading: false,
        isOfferAttachmentUploaded: false,
      }
    }

    default:
      return state
  }
}
