import { convertNumberToNumericString } from '.'

export const handleNumberFormatPaste = (event: any): void => {
  const text = (event.originalEvent || event).clipboardData.getData('text/plain').replace(/\s/g, '')

  // If decimal with dot is pasted
  if (text && !isNaN(Number(text))) {
    const converted = convertNumberToNumericString(Number(text))

    if (event.currentTarget) {
      event.currentTarget.setAttribute('value', converted)
      event.currentTarget.value = converted
    }

    if (event.target) {
      event.target.setAttribute('value', converted)
      event.target.value = converted
    }
  }
}
