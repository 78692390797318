import { convertNumberToNumericString, convertQuantityToNumericString, toDateTableValue } from '.'

export const convertNumbersInTableRows = (
  itemsArray: any[] | [] | null | undefined,
  priceColumnsArray: number[],
  quantityColumnsArray: number[]
) => {
  if (document) {
    let itemsListTableRows: any = document.getElementsByTagName('tr')

    if (itemsArray && itemsListTableRows.length) {
      for (let i = 1, len = itemsListTableRows.length; i < len; i++) {
        let row = itemsListTableRows[i]

        priceColumnsArray?.forEach((column: number) => {
          if (Number(row?.children[column]?.innerHTML)) {
            row.children[column].innerHTML = convertNumberToNumericString(
              Number(row.children[column].innerHTML)
            )
          }
        })

        quantityColumnsArray?.forEach((column: number) => {
          if (Number(row?.children[column]?.innerHTML)) {
            row.children[column].innerHTML = convertQuantityToNumericString(
              Number(row.children[column].innerHTML)
            )
          }
        })
      }
    }
  }
}

export const convertDateInTableRows = (
  itemsArray: any[] | [] | null | undefined,
  dateColumnsArray: number[]
) => {
  if (document) {
    let itemsListTableRows: any = document.getElementsByTagName('tr')

    if (itemsArray && itemsListTableRows.length) {
      dateColumnsArray?.forEach((column: number) => {
        for (let i = 1, len = itemsListTableRows.length; i < len; i++) {
          let row = itemsListTableRows[i]
          const dateCell = row.children[column]

          if (dateCell?.innerHTML?.includes('T')) {
            dateCell.innerHTML = toDateTableValue(dateCell?.innerHTML, false)

            if (dateCell?.style) {
              dateCell.style.color = 'inherit'
            }
          }
        }
      })
    }
  }
}

export const coverTimestamp = (
  itemsArray: any[] | [] | null | undefined,
  dateColumnsArray: number[]
) => {
  if (document) {
    let itemsListTableRows: any = document.getElementsByTagName('tr')

    if (itemsArray && itemsListTableRows.length) {
      dateColumnsArray?.forEach((column: number) => {
        for (let i = 1, len = itemsListTableRows.length; i < len; i++) {
          let row = itemsListTableRows[i]
          const dateCell = row.children[column]

          if (dateCell?.innerHTML?.includes('T')) {
            if (dateCell?.style) {
              dateCell.style.color = 'transparent'
            }
          }
        }
      })
    }
  }
}
