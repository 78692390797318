import * as actionTypes from '../action-types'

export interface IBomsState {
  boms: actionTypes.TBomLight[] | any
  areBomsLoading: boolean
  fetchBomsError: actionTypes.TNetworkError

  tableSorterState: actionTypes.TSorterState | undefined
  tableFilterState: actionTypes.TFilterState
  lastTabOpen: string | undefined

  isBomCreating: boolean
  bomCreateError: actionTypes.TNetworkError
  isBomCreated: boolean

  isSingleBomLoading: boolean
  editedBom: actionTypes.IBomFull | null
  singleBomFetchError: actionTypes.TNetworkError

  isBomEditSaving: boolean
  bomEditSaveError: actionTypes.TNetworkError
  isBomEditSaved: boolean

  isBomDeleting: boolean
  isBomDeleted: boolean
  bomDeleteError: actionTypes.TNetworkError

  currentlyManagedBomId: number | null

  isBomCopying: boolean
  isBomCopied: boolean
  bomCopyError: actionTypes.TNetworkError

  isBomArchiving: boolean
  isBomArchived: boolean
  bomArchiveError: actionTypes.TNetworkError
}

const initialState: IBomsState = {
  boms: [],
  areBomsLoading: false,
  fetchBomsError: null,

  tableSorterState: undefined,
  tableFilterState: undefined,
  lastTabOpen: undefined,

  isBomCreating: false,
  bomCreateError: null,
  isBomCreated: false,

  isSingleBomLoading: false,
  editedBom: null,
  singleBomFetchError: null,

  isBomEditSaving: false,
  bomEditSaveError: null,
  isBomEditSaved: false,

  isBomDeleting: false,
  isBomDeleted: false,
  bomDeleteError: null,

  currentlyManagedBomId: null,

  isBomCopying: false,
  isBomCopied: false,
  bomCopyError: null,

  isBomArchiving: false,
  isBomArchived: false,
  bomArchiveError: null,
}

export default function bomsReducer(
  state = initialState,
  action: actionTypes.BomsActionTypes
): IBomsState {
  switch (action.type) {
    case actionTypes.FETCH_BOMS_REQUESTED: {
      return {
        ...state,
        areBomsLoading: true,
        fetchBomsError: null,
      }
    }

    case actionTypes.FETCH_BOMS_SUCCEEDED: {
      return {
        ...state,
        areBomsLoading: false,
        boms: action.boms,
      }
    }

    case actionTypes.FETCH_BOMS_ERROR: {
      return {
        ...state,
        areBomsLoading: false,
        fetchBomsError: action.error,
      }
    }

    case actionTypes.SET_BOMS_TABLE_FILTER_STATE: {
      return {
        ...state,
        tableFilterState: action.tableFilterState,
      }
    }

    case actionTypes.SET_BOMS_TABLE_SORTER_STATE: {
      return {
        ...state,
        tableSorterState: action.tableSorterState,
      }
    }

    case actionTypes.CREATE_BOM_REQUESTED: {
      return {
        ...state,
        isBomCreating: true,
        isBomCreated: false,
        lastTabOpen: action.payload.lastTabOpen,
      }
    }

    case actionTypes.CREATE_BOM_SUCCEEDED: {
      return {
        ...state,
        isBomCreating: false,
        isBomCreated: true,
        boms: [...state.boms, action.bom],
      }
    }

    case actionTypes.CREATE_BOM_ERROR: {
      return {
        ...state,
        bomCreateError: action.error,
        isBomCreating: false,
        isBomCreated: false,
      }
    }

    case actionTypes.FETCH_SINGLE_BOM_REQUESTED: {
      return {
        ...state,
        isSingleBomLoading: true,
        // This is needed to reset the form
        isBomCreated: false,
        singleBomFetchError: null,
      }
    }

    case actionTypes.FETCH_SINGLE_BOM_SUCCEEDED: {
      return {
        ...state,
        singleBomFetchError: null,
        isSingleBomLoading: false,
        editedBom: action.editedBom,
      }
    }

    case actionTypes.FETCH_SINGLE_BOM_ERROR: {
      return {
        ...state,
        singleBomFetchError: action.error,
        isSingleBomLoading: false,
        isBomEditSaving: false,
      }
    }

    case actionTypes.EDIT_BOM_REQUESTED: {
      return {
        ...state,
        bomEditSaveError: null,
        isBomEditSaving: true,
        isBomEditSaved: false,
      }
    }

    case actionTypes.EDIT_BOM_SUCCEEDED: {
      return {
        ...state,
        isBomEditSaving: false,
        isBomEditSaved: true,
        boms: state.boms?.map((bom: actionTypes.TBomLight) => {
          if (bom.id === action.bom.id) {
            return action.bom
          } else {
            return bom
          }
        }),
      }
    }

    case actionTypes.EDIT_BOM_ERROR: {
      return {
        ...state,
        bomEditSaveError: action.error,
        isBomEditSaving: false,
        isBomEditSaved: false,
      }
    }

    case actionTypes.COPY_BOM_REQUESTED: {
      return {
        ...state,
        bomCopyError: null,
        isBomCopying: true,
        isBomCopied: false,
        currentlyManagedBomId: action.payload.id,
      }
    }

    case actionTypes.COPY_BOM_SUCCEEDED: {
      return {
        ...state,
        isBomCopying: false,
        isBomCopied: true,
        boms: [...state.boms, action.bom],
        currentlyManagedBomId: null,
      }
    }

    case actionTypes.COPY_BOM_ERROR: {
      return {
        ...state,
        bomCopyError: action.error,
        isBomCopying: false,
        isBomCopied: false,
        currentlyManagedBomId: null,
      }
    }

    case actionTypes.ARCHIVE_BOM_REQUESTED: {
      return {
        ...state,
        bomArchiveError: null,
        isBomArchiving: true,
        isBomArchived: false,
        currentlyManagedBomId: action.payload.id,
      }
    }

    case actionTypes.ARCHIVE_BOM_SUCCEEDED: {
      return {
        ...state,
        isBomArchiving: false,
        isBomArchived: true,
        boms: state.boms.filter((bom: actionTypes.TBomLight) => bom.id !== action.bom.id),
        currentlyManagedBomId: null,
      }
    }

    case actionTypes.ARCHIVE_BOM_ERROR: {
      return {
        ...state,
        bomArchiveError: action.error,
        isBomArchiving: false,
        isBomArchived: false,
        currentlyManagedBomId: null,
      }
    }

    case actionTypes.DELETE_BOM_REQUESTED: {
      return {
        ...state,
        bomDeleteError: null,
        isBomDeleting: true,
        isBomDeleted: false,
        currentlyManagedBomId: action.payload.id,
      }
    }

    case actionTypes.DELETE_BOM_SUCCEEDED: {
      return {
        ...state,
        isBomDeleting: false,
        isBomDeleted: true,
        boms: state.boms.filter((bom: actionTypes.TBomLight) => bom.id !== action.bom.id),
        currentlyManagedBomId: null,
      }
    }

    case actionTypes.DELETE_BOM_ERROR: {
      return {
        ...state,
        bomDeleteError: action.error,
        isBomDeleting: false,
        isBomDeleted: false,
        currentlyManagedBomId: null,
      }
    }

    case actionTypes.OPEN_BOM_DETAILS: {
      return {
        ...state,
        bomCreateError: null,
        bomEditSaveError: null,
        bomDeleteError: null,
        bomCopyError: null,
        bomArchiveError: null,
      }
    }

    case actionTypes.CLOSE_BOM_DETAILS: {
      return {
        ...state,
        bomCreateError: null,
        bomEditSaveError: null,
        bomDeleteError: null,
        singleBomFetchError: null,

        isSingleBomLoading: false,
        isBomCreating: false,
        isBomEditSaving: false,
        isBomDeleting: false,

        editedBom: null,
        isBomCreated: false,
        isBomEditSaved: false,
        isBomDeleted: false,

        bomCopyError: null,
        bomArchiveError: null,
      }
    }

    case actionTypes.BOM_ACTIONS_UNLOCK: {
      return {
        ...state,
        isBomEditSaved: false,
        isBomCreated: false,
        isBomDeleted: false,
        isBomEditSaving: false,
        isBomCreating: false,
        isBomDeleting: false,
      }
    }

    default:
      return state
  }
}
