export const toDateInputValue = function (date: string | null | undefined) {
  if (!date) {
    return ''
  } else {
    let local = new Date(date)
    local.setMinutes(local.getMinutes() - local.getTimezoneOffset())

    return local?.toJSON()?.slice(0, 10)
  }
}
