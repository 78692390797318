import React, {
  ChangeEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Container, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { CDataTable, CCard, CCardBody, CLabel, CButton } from '@coreui/react'
import { Select, SelectProps } from '@material-ui/core'
import { cilListHighPriority } from '@coreui/icons'
import CIcon from '@coreui/icons-react'

import * as actionTypes from '../../store/action-types'
import { TRootState } from '../../store/reducers'
import {
  convertNumbersInTableRows,
  getEmptyListMessage,
  inputFieldSpacingBottom,
  inputLabelSpacingBottom,
  makeSearchBoxFixed,
} from '../../utils'
import { minimumWidthForWideDetailsView } from '../admin'
import { FormFixedActionsPanel } from '../../components'

export const DocumentsOfWarehouses: React.FC<{}> = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { tenant } = useParams<{ tenant: string }>()

  const authState = useSelector((state: TRootState) => state.auth)
  const warehousesState = useSelector((state: TRootState) => state.warehouses)

  const [currentlyBrowsedWarehouseId, setCurrentlyBrowsedWarehouseId] = useState<null | number>(
    null
  )

  const [currentlyBrowsedWarehouseTypeId, setCurrentlyBrowsedWarehouseTypeId] = useState<
    null | number
  >(null)

  const [documentsOfWarehousesFilterState, setDocumentsOfWarehousesFilterState] =
    useState<actionTypes.TFilterState>(undefined)

  const [documentsOfWarehousesSorterState, setDocumentsOfWarehousesSorterState] =
    useState<actionTypes.TSorterState>({ column: '', asc: true })

  const memoizedTableItems = useMemo(() => {
    const documents: actionTypes.IDocumentsOfWarehousesTableItem[] | undefined =
      warehousesState?.documentsOfWarehouses

    //   warehousesState?.documentsOfWarehouses?.map(
    //     (inventoryItem: actionTypes.IDocumentsOfWarehousesDataItem) => {
    //       return {
    //         bomElementId: inventoryItem.bomElementId,
    //         bomElementName: inventoryItem.bomElementName || '',
    //         bomElementCode: inventoryItem.bomElementSymbolDin || inventoryItem.bomElementMpn || '',

    //         warehouseId: inventoryItem.warehouseId,
    //         warehouseName: inventoryItem.warehouseName || '',
    //         type: inventoryItem.type,
    //         warehouseTypeName: inventoryItem?.type
    //           ? actionTypes.warehouseTypesObject[inventoryItem.type]
    //           : '',

    //         lastDeliveryDate: inventoryItem?.lastDeliveryDate || '',
    //         referenceNumber: inventoryItem?.referenceNumber || '',
    //         warehouseQuantity: inventoryItem?.warehouseQuantity || '',
    //         bookedPlanned: inventoryItem?.bookedPlanned || '',
    //         bookedUnplanned: inventoryItem?.bookedUnplanned || '',
    //         nextDeliveryDate: inventoryItem?.nextDeliveryDate || '',
    //         nextDeliveryValue: inventoryItem?.nextDeliveryValue || '',
    //         productName: inventoryItem?.bomInfos?.join(', ') || '',
    //       }
    //     }
    //   )

    if (currentlyBrowsedWarehouseId || currentlyBrowsedWarehouseTypeId) {
      return documents?.filter(
        (inventoryItem: actionTypes.IDocumentsOfWarehousesTableItem) =>
          currentlyBrowsedWarehouseId
            ? inventoryItem.warehouseId === currentlyBrowsedWarehouseId
            : true

        //     &&
        //   (currentlyBrowsedWarehouseTypeId
        //     ? inventoryItem.type === currentlyBrowsedWarehouseTypeId
        //     : true)
      )
    } else {
      return documents
    }
  }, [
    warehousesState?.documentsOfWarehouses,
    currentlyBrowsedWarehouseId,
    currentlyBrowsedWarehouseTypeId,
  ])

  const closeDocumentsOfWarehouses = useCallback(() => {
    if (history.length) {
      history.goBack()
    } else {
      history.push(`/${tenant}/admin/warehouses`)
    }
  }, [history, tenant])

  // Fetch inventory control data
  useEffect(() => {
    if (
      location.pathname.includes(`documents-of-warehouses`) &&
      tenant &&
      authState?.authData &&
      authState?.authData?.roles?.includes('Warehouses_read')
    ) {
      dispatch({
        type: actionTypes.FETCH_INVENTORIES_OF_WAREHOUSES_REQUESTED,
        payload: { tenant: tenant, token: authState.authData?.token },
      })
    }
  }, [
    dispatch,
    tenant,
    authState.authData,
    authState.isAuthenticated,
    authState.isAuthenticationPending,
    location,
  ])

  // Hide sidebar on smaller screens
  useEffect(() => {
    if (window.innerWidth < minimumWidthForWideDetailsView) {
      dispatch({ type: actionTypes.SET_SIDEBAR_VISIBILITY, sidebarVisibility: false })
    }
  }, [dispatch])

  // Here the numbers are converted in table on each state change of the table
  useEffect(() => {
    convertNumbersInTableRows(warehousesState.documentsOfWarehouses, [7, 9], [6])
  }, [memoizedTableItems, documentsOfWarehousesFilterState, documentsOfWarehousesSorterState])

  // Make search box fixed
  useEffect(() => {
    makeSearchBoxFixed()
  }, [makeSearchBoxFixed])

  return (
    <Container
      className="d-flex flex-column align-items-center justify-content-center documents-of-warehouses-container"
      style={{ maxWidth: '1600px' }}
    >
      <CCard>
        <CCardBody className="px-4 pt-4 pb-2">
          {authState?.authData?.roles?.includes('Warehouses_read') && (
            <FormFixedActionsPanel
              title={<></>}
              mode={'edit'}
              section="documents-of-warehouses"
              isSaving={false}
              isSaved={false}
              isDeleting={false}
              isDeleted={false}
              didFormValidationOccur={false}
              formErrorsBool={false}
              closeAction={''}
              deleteAction={''}
              deletePayload={null}
              closeFunction={closeDocumentsOfWarehouses}
              setDidFormValidationOccur={null}
              canDelete={false}
              isEditable={false}
              disabledDeleteButtonClassNames=""
              canSave={false}
              confirmDeleteMessageJSX={<></>}
            />
          )}

          <div className="d-flex justify-content-start">
            <div style={{ maxWidth: '260px' }}>
              <CLabel
                htmlFor="documents-warehouse-id-selector"
                className={`${inputLabelSpacingBottom}`}
              >
                Magazyn
              </CLabel>
              <InputGroup
                id="documents-warehouse-id-selector"
                className={`${inputFieldSpacingBottom} dropdown-selector`}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="cil-storage"></i>
                  </InputGroupText>
                </InputGroupAddon>

                <Select
                  variant="outlined"
                  native
                  value={currentlyBrowsedWarehouseId || ''}
                  className="item-selector warehouse-selector"
                  style={{ minWidth: '220px' }}
                  disabled={!warehousesState?.warehouses}
                  onChange={(event: ChangeEvent<SelectProps>) => {
                    setCurrentlyBrowsedWarehouseId(
                      event?.target?.value ? Number(event?.target?.value) : null
                    )

                    return event
                  }}
                >
                  <option value={''}>Wszystkie</option>
                  {warehousesState?.warehouses?.map(
                    (warehouse: actionTypes.TWarehouseLight, warehouseIndex: number) => {
                      return (
                        <option
                          key={`warehouse-select-option-${warehouse.id}-${warehouseIndex}`}
                          value={warehouse.id}
                        >
                          {warehouse?.name || '...'}
                        </option>
                      )
                    }
                  )}
                </Select>
              </InputGroup>
            </div>

            <div className="pl-4" style={{ maxWidth: '260px' }}>
              <CLabel htmlFor="documents-type-id-selector" className={`${inputLabelSpacingBottom}`}>
                Rodzaj
              </CLabel>
              <InputGroup
                id="documents-type-id-selector"
                className={`${inputFieldSpacingBottom} dropdown-selector`}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="cil-inbox"></i>
                  </InputGroupText>
                </InputGroupAddon>

                <Select
                  variant="outlined"
                  native
                  value={currentlyBrowsedWarehouseTypeId || ''}
                  className="item-selector warehouse-selector"
                  style={{ minWidth: '220px' }}
                  onChange={(event: ChangeEvent<SelectProps>) => {
                    setCurrentlyBrowsedWarehouseTypeId(
                      event?.target?.value ? Number(event?.target?.value) : null
                    )

                    return event
                  }}
                >
                  <option value={''}>Wszystkie</option>
                  <option value={actionTypes.receiveOrderType}>
                    {actionTypes.documentsOfWarehousesTypesObject[actionTypes.receiveOrderType]}
                  </option>
                  <option value={actionTypes.warehouseShiftType}>
                    {actionTypes.documentsOfWarehousesTypesObject[actionTypes.warehouseShiftType]}
                  </option>
                </Select>
              </InputGroup>
            </div>

            <div className="documents-of-warehouses-reset-filters-button-wrapper">
              <CButton
                color="primary"
                variant="outline"
                className="ml-3 documents-of-warehouses-reset-filters-button"
                type="button"
                disabled={!currentlyBrowsedWarehouseId && !currentlyBrowsedWarehouseTypeId}
                onClick={() => {
                  setCurrentlyBrowsedWarehouseId(null)
                  setCurrentlyBrowsedWarehouseTypeId(null)
                  setDocumentsOfWarehousesFilterState(undefined)
                }}
              >
                <CIcon icon={cilListHighPriority} />
              </CButton>
            </div>
          </div>

          <CDataTable
            tableFilterValue={documentsOfWarehousesFilterState}
            sorterValue={documentsOfWarehousesSorterState}
            onSorterValueChange={(sorterState: actionTypes.TSorterState) => {
              setDocumentsOfWarehousesSorterState(sorterState)
            }}
            onTableFilterChange={(tableFilterState: string) => {
              setDocumentsOfWarehousesFilterState(tableFilterState)
            }}
            loading={warehousesState?.areDocumentsOfWarehousesLoading}
            striped={!!warehousesState?.documentsOfWarehouses?.length}
            border
            sorter
            tableFilter={{ label: ' ', placeholder: 'Szukaj w tabeli...' }}
            addTableClasses="vertical-middle-list-table documents-of-warehouses-table mt-2 mb-4"
            cleaner
            onRowClick={undefined}
            pagination
            itemsPerPage={20}
            onPageChange={() => {
              setTimeout(
                () => convertNumbersInTableRows(warehousesState.documentsOfWarehouses, [7, 9], [6]),
                10
              )
            }}
            fields={[
              {
                key: 'documentType',
                label: 'Rodzaj dokumentu',
                _style: { width: '100px', textAlign: 'left' },
              },
              {
                key: 'documentNumber',
                label: 'Numer',
                _style: { width: '250px' },
              },
              {
                key: 'invoices',
                label: 'Faktury',
                _style: { width: '120px', textAlign: 'left' },
              },
              {
                key: 'orderNumber',
                label: 'Nr zamówienia',
                _style: { width: '100px', textAlign: 'left' },
              },
              {
                key: 'warehouseName',
                label: 'Magazyn',
                _style: { width: '70px', textAlign: 'left' },
              },
              {
                key: 'supplierReceiver',
                label: 'Dostawca \n Odbiorca',
                _style: { width: '70px', textAlign: 'left' },
              },
              {
                key: 'quantity',
                label: 'Ilość',
                _style: { width: '70px', textAlign: 'left' },
              },
              {
                key: 'nettoValue',
                label: 'Wartość netto (zak)',
                _style: { width: '100px', textAlign: 'left' },
              },
              {
                key: 'nettoValueCurrency',
                label: 'CCY',
                _style: { width: '70px', textAlign: 'left' },
              },
              {
                key: 'nettoValue',
                label: 'Wartość netto',
                _style: { width: '100px', textAlign: 'left' },
              },
              {
                key: 'nettoValueCurrency',
                label: 'CCY',
                _style: { width: '70px', textAlign: 'left' },
              },
              {
                key: 'documentCreateDate',
                label: 'Utworzono',
                _style: { width: '100px', textAlign: 'left' },
              },
              {
                key: 'edit',
                label: '',
                _style: { width: '45px' },
              },
            ]}
            items={memoizedTableItems}
            noItemsViewSlot={
              <div className="no-items-in-table">
                {currentlyBrowsedWarehouseId &&
                currentlyBrowsedWarehouseTypeId &&
                !documentsOfWarehousesFilterState
                  ? 'Brak wyników dla wybranego typu w wybranym magazynie!'
                  : currentlyBrowsedWarehouseId && !documentsOfWarehousesFilterState
                  ? 'Brak wyników w wybranym magazynie!'
                  : currentlyBrowsedWarehouseTypeId && !documentsOfWarehousesFilterState
                  ? 'Brak wyników dla wybranego typu!'
                  : getEmptyListMessage(
                      warehousesState.fetchDocumentsOfWarehousesError,
                      warehousesState.areDocumentsOfWarehousesLoading,
                      'dokumentów magazynowych',
                      warehousesState?.documentsOfWarehouses?.length
                    )}
              </div>
            }
            scopedSlots={{
              edit: (itemFromList: actionTypes.TWarehouseLight) => (
                <td className="text-center" style={{ pointerEvents: 'none' }}>
                  <CButton
                    color="dark"
                    variant="outline"
                    size="50"
                    className="list-action-button edit-list-item-button"
                    style={{ pointerEvents: 'all' }}
                    onClick={(event: SyntheticEvent) => {
                      history.push(
                        `/${tenant}/admin/documents-of-warehouses/edit/${itemFromList?.id}`
                      )

                      // This line blocks the opening of item on checkbox/row click
                      event.stopPropagation()
                    }}
                    disabled
                  >
                    <i className="cil-pen"></i>
                  </CButton>
                </td>
              ),
            }}
          />
        </CCardBody>
      </CCard>
    </Container>
  )
}
