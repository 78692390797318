import { call, put, takeLatest } from 'redux-saga/effects'

import { sectionFetch } from './api'
import * as actionTypes from '../action-types'

export function* fetchAuditLogs(action: actionTypes.fetchAuditLogsAction) {
  try {
    const response = yield call(sectionFetch, action.payload, 'audit')

    yield put({ type: actionTypes.FETCH_AUDIT_LOGS_SUCCEEDED, auditLogs: response.data })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_AUDIT_LOGS_ERROR,
      error: error?.response,
    })
  }
}

export function* auditLogsSaga() {
  yield takeLatest(actionTypes.FETCH_AUDIT_LOGS_REQUESTED, fetchAuditLogs)
}
