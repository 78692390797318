import * as actionTypes from '../action-types'

export interface IAdminState {
  sidebarVisibility: boolean | 'responsive'
  sidebarMinimize: boolean

  todayCurrencyExchange: any
  isTodayCurrencyExchangeLoading: boolean
  fetchTodayCurrencyExchangeError: actionTypes.TNetworkError

  isAttachmentPreview: boolean
  isSingleAttachmentFetching: boolean
  currentlyFetchedSingleAttachmentId: number | null
  singleAttachmentFetchError: actionTypes.TNetworkError | null

  isSingleAttachmentDeleting: boolean
  currentlyDeletingSingleAttachmentId: number | null
  singleAttachmentDeleteError: actionTypes.TNetworkError | null
}

const initialState: IAdminState = {
  sidebarVisibility: 'responsive',
  sidebarMinimize: false,

  todayCurrencyExchange: null,
  fetchTodayCurrencyExchangeError: null,
  isTodayCurrencyExchangeLoading: false,

  isAttachmentPreview: false,
  isSingleAttachmentFetching: false,
  currentlyFetchedSingleAttachmentId: null,
  singleAttachmentFetchError: null,

  isSingleAttachmentDeleting: false,
  currentlyDeletingSingleAttachmentId: null,
  singleAttachmentDeleteError: null,
}

export default function adminReducer(
  state = initialState,
  action: actionTypes.AdminActionTypes
): IAdminState {
  switch (action.type) {
    case actionTypes.SET_SIDEBAR_VISIBILITY: {
      return {
        ...state,
        sidebarVisibility: action.sidebarVisibility,
      }
    }

    case actionTypes.SET_SIDEBAR_MINIMIZE: {
      return {
        ...state,
        sidebarMinimize: action.sidebarMinimize,
      }
    }

    case actionTypes.FETCH_TODAY_CURRENCY_EXCHANGE_REQUESTED: {
      return {
        ...state,
        fetchTodayCurrencyExchangeError: null,
        isTodayCurrencyExchangeLoading: true,
      }
    }

    case actionTypes.FETCH_TODAY_CURRENCY_EXCHANGE_SUCCEEDED: {
      return {
        ...state,
        fetchTodayCurrencyExchangeError: null,
        isTodayCurrencyExchangeLoading: false,
        todayCurrencyExchange: action.todayCurrencyExchange,
      }
    }

    case actionTypes.FETCH_TODAY_CURRENCY_EXCHANGE_ERROR: {
      return {
        ...state,
        fetchTodayCurrencyExchangeError: action.error,
        isTodayCurrencyExchangeLoading: false,
      }
    }

    case actionTypes.FETCH_SINGLE_ATTACHMENT_REQUESTED: {
      return {
        ...state,
        isSingleAttachmentFetching: true,
        isAttachmentPreview: !action.payload.download,
        currentlyFetchedSingleAttachmentId: action.payload.id,
        singleAttachmentFetchError: null,
        singleAttachmentDeleteError: null,
      }
    }

    case actionTypes.FETCH_SINGLE_ATTACHMENT_SUCCEEDED: {
      return {
        ...state,
        isSingleAttachmentFetching: false,
        currentlyFetchedSingleAttachmentId: null,
        singleAttachmentFetchError: null,
      }
    }

    case actionTypes.FETCH_SINGLE_ATTACHMENT_ERROR: {
      return {
        ...state,
        isSingleAttachmentFetching: false,
        currentlyFetchedSingleAttachmentId: null,
        singleAttachmentFetchError: action.error,
      }
    }

    case actionTypes.DELETE_SINGLE_ATTACHMENT_REQUESTED: {
      return {
        ...state,
        isSingleAttachmentDeleting: true,
        currentlyDeletingSingleAttachmentId: action.payload.id,
        singleAttachmentDeleteError: null,
        singleAttachmentFetchError: null,
      }
    }

    case actionTypes.DELETE_SINGLE_ATTACHMENT_SUCCEEDED: {
      return {
        ...state,
        isSingleAttachmentDeleting: false,
        currentlyDeletingSingleAttachmentId: null,
        singleAttachmentDeleteError: null,
      }
    }

    case actionTypes.DELETE_SINGLE_ATTACHMENT_ERROR: {
      return {
        ...state,
        isSingleAttachmentDeleting: false,
        currentlyDeletingSingleAttachmentId: null,
        singleAttachmentDeleteError: action.error,
      }
    }

    case actionTypes.CLEAR_SINGLE_ATTACHMENT_ERRORS: {
      return {
        ...state,
        isSingleAttachmentFetching: false,
        currentlyFetchedSingleAttachmentId: null,
        singleAttachmentFetchError: null,

        isSingleAttachmentDeleting: false,
        currentlyDeletingSingleAttachmentId: null,
        singleAttachmentDeleteError: null,
      }
    }

    default:
      return state
  }
}
