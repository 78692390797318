import React, { useEffect, useMemo } from 'react'
import { Container } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { CDataTable, CCard, CCol, CCardBody } from '@coreui/react'

import * as actionTypes from '../../store/action-types'
import { TRootState } from '../../store/reducers'
import { getEmptyListMessage, makeSearchBoxFixed, toDateTableValue } from '../../utils'

const AuditLogs: React.FC<{}> = () => {
  const auditLogsState = useSelector((state: TRootState) => state.auditLogs)
  let dispatch = useDispatch()

  const memoizedTableItems = useMemo(
    () =>
      auditLogsState.auditLogs?.map((auditLog: actionTypes.TAuditLog) => {
        return {
          date: toDateTableValue(auditLog.date, true) || '',
          user: auditLog.user || '',
          clientIp: auditLog.clientIp || '',
          info: auditLog.info || '',
          id: auditLog.id,
        }
      }),
    [auditLogsState.auditLogs]
  )

  // Make search box fixed
  useEffect(() => {
    makeSearchBoxFixed()
  }, [makeSearchBoxFixed])

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center">
      <CCol xl="10">
        <CCard>
          <CCardBody className="pb-2">
            <CDataTable
              tableFilterValue={auditLogsState?.tableFilterState}
              sorterValue={auditLogsState?.tableSorterState}
              onSorterValueChange={(sorterState: actionTypes.TSorterState) =>
                dispatch({
                  type: actionTypes.SET_AUDIT_LOGS_TABLE_SORTER_STATE,
                  tableSorterState: sorterState,
                })
              }
              onTableFilterChange={(tableFilterState: string) =>
                dispatch({
                  type: actionTypes.SET_AUDIT_LOGS_TABLE_FILTER_STATE,
                  tableFilterState: tableFilterState,
                })
              }
              loading={auditLogsState.areAuditLogsLoading}
              striped={!!auditLogsState.auditLogs.length}
              pagination
              itemsPerPage={50}
              border
              sorter
              tableFilter={{ label: ' ', placeholder: 'Szukaj w tabeli...' }}
              cleaner
              addTableClasses="vertical-middle-list-table audit-logs-table"
              fields={[
                { key: 'date', label: 'Data', _style: { width: '150px' } },
                { key: 'user', label: 'Użytkownik' },
                { key: 'clientIp', label: 'IP' },
                { key: 'info', label: 'Informacja' },
              ]}
              items={memoizedTableItems}
              noItemsViewSlot={
                <div className="no-items-in-table">
                  {getEmptyListMessage(
                    auditLogsState.fetchAuditLogsError,
                    auditLogsState.areAuditLogsLoading,
                    'audytu wykonanych akcji',
                    auditLogsState?.auditLogs?.length
                  )}
                </div>
              }
            />
          </CCardBody>
        </CCard>
      </CCol>
    </Container>
  )
}

export default AuditLogs
