import { call, put, takeLatest, delay } from 'redux-saga/effects'

import { sectionFetch, settingsCall } from './api'
import * as actionTypes from '../action-types'
import { editSuccessMessageDuration } from '../../utils'

export function* fetchSettings(action: actionTypes.fetchSettingsAction) {
  try {
    const response = yield call(sectionFetch, action.payload, 'settings')

    yield put({ type: actionTypes.FETCH_SETTINGS_SUCCEEDED, settings: response.data })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_SETTINGS_ERROR,
      error: error?.response,
    })
  }
}

export function* saveSettings(action: actionTypes.saveSettingsAction) {
  try {
    yield call(settingsCall, action.payload)

    yield put({
      type: actionTypes.SAVE_SETTINGS_SUCCEEDED,
      settings: action.payload.settings,
    })

    yield delay(editSuccessMessageDuration)
    yield put({
      type: actionTypes.SETTINGS_ACTIONS_UNLOCK,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.SAVE_SETTINGS_ERROR,
      error: error?.response,
    })
    yield put({
      type: actionTypes.SETTINGS_ACTIONS_UNLOCK,
    })
  }
}

export function* settingsSaga() {
  yield takeLatest(actionTypes.FETCH_SETTINGS_REQUESTED, fetchSettings)
  yield takeLatest(actionTypes.SAVE_SETTINGS_REQUESTED, saveSettings)
}
