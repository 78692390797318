import { CRow } from '@coreui/react'

export const Admin404Page: React.FC = () => {
  return (
    <CRow className="justify-content-center" style={{ height: '80vh' }}>
      <div className="clearfix d-flex flex-row align-items-center">
        <h1 className="display-3 m-0">404</h1>
        <h4 style={{ width: '220px' }} className="m-0 ml-4">
          Żądana podstrona nie została odnaleziona!
        </h4>
      </div>
    </CRow>
  )
}
