import { TNetworkError } from './auth'
import {
  TAdminPayload,
  ISingleItemActionPayload,
  IAttachmentUpload,
  TAttachment,
  IUploadSingleAttachmentPayload,
  TFilterState,
  TSorterState,
} from './admin'
import { TCurrencyCode } from '.'

export const FETCH_OFFERS_REQUESTED = 'FETCH_OFFERS_REQUESTED'
export const FETCH_OFFERS_ERROR = 'FETCH_OFFERS_ERROR'
export const FETCH_OFFERS_SUCCEEDED = 'FETCH_OFFERS_SUCCEEDED'

export const SET_OFFERS_TABLE_FILTER_STATE = 'SET_OFFERS_TABLE_FILTER_STATE'
export const SET_OFFERS_TABLE_SORTER_STATE = 'SET_OFFERS_TABLE_SORTER_STATE'

export const CREATE_OFFER_REQUESTED = 'CREATE_OFFER_REQUESTED'
export const CREATE_OFFER_SUCCEEDED = 'CREATE_OFFER_SUCCEEDED'
export const CREATE_OFFER_ERROR = 'CREATE_OFFER_ERROR'

export const FETCH_SINGLE_OFFER_REQUESTED = 'FETCH_SINGLE_OFFER_REQUESTED'
export const FETCH_SINGLE_OFFER_ERROR = 'FETCH_SINGLE_OFFER_ERROR'
export const FETCH_SINGLE_OFFER_SUCCEEDED = 'FETCH_SINGLE_OFFER_SUCCEEDED'

export const EDIT_OFFER_REQUESTED = 'EDIT_OFFER_REQUESTED'
export const EDIT_OFFER_SUCCEEDED = 'EDIT_OFFER_SUCCEEDED'
export const EDIT_OFFER_ERROR = 'EDIT_OFFER_ERROR'

export const DELETE_OFFER_REQUESTED = 'DELETE_OFFER_REQUESTED'
export const DELETE_OFFER_SUCCEEDED = 'DELETE_OFFER_SUCCEEDED'
export const DELETE_OFFER_ERROR = 'DELETE_OFFER_ERROR'

export const FETCH_OFFER_ATTACHMENTS_REQUESTED = 'FETCH_OFFER_ATTACHMENTS_REQUESTED'
export const FETCH_OFFER_ATTACHMENTS_SUCCEEDED = 'FETCH_OFFER_ATTACHMENTS_SUCCEEDED'
export const FETCH_OFFER_ATTACHMENTS_ERROR = 'FETCH_OFFER_ATTACHMENTS_ERROR'

export const UPLOAD_OFFER_ATTACHMENT_REQUESTED = 'UPLOAD_OFFER_ATTACHMENT_REQUESTED'
export const UPLOAD_OFFER_ATTACHMENT_SUCCEEDED = 'UPLOAD_OFFER_ATTACHMENT_SUCCEEDED'
export const UPLOAD_OFFER_ATTACHMENT_ERROR = 'UPLOAD_OFFER_ATTACHMENT_ERROR'

export const DELETE_OFFER_ATTACHMENT_SUCCEEDED = 'DELETE_OFFER_ATTACHMENT_SUCCEEDED'

export const OFFER_ACTIONS_UNLOCK = 'OFFER_ACTIONS_UNLOCK'

export const CLOSE_OFFER_DETAILS = 'CLOSE_OFFER_DETAILS'

export type TOffer = {
  number: string
  offerNumber?: string
  customOfferNumber: string | null
  bomElementPrice: number | null
  bomElementPriceCurrency: TCurrencyCode | null
  quantity: number

  bomElementId: number
  bomElementName?: string

  bomsIds: any[] | null
  createDate: string | null
  expirationDate: string | null
  supplierId: number | null
  supplierName: string

  implementationCostId: number | null
  implementationCostName: string
  implementationCostPrice: number | null
  implementationCostPriceCurrency: TCurrencyCode | null

  serviceId: number | null
  serviceName: string
  servicePrice: number | null
  servicePriceCurrency: TCurrencyCode | null

  isExpired: boolean
  deliveryLeadTime: number | null
  productionLeadTime: number | null

  canDelete: boolean

  uuid: string
  // This is a typo from BE back-end to be removed
  uuId?: string
  id: number
}

export type TLightOfferInTable = {
  offerNumber: string | null
  customOfferNumber: string | null

  bomElementName: string
  bomElementMpn: string | null
  bomElementDin: string | null
  bomElementPrice: number | null | string
  finalPrice: number | null
  bomElementPriceCurrency: TCurrencyCode | null
  bomElementId: number
  quantity: number | null

  supplierId: number | null
  supplierName: string

  createDate: string | null
  expirationDate: string | null

  implementationCostId: number | null
  implementationCostPrice: number | null
  implementationCostPriceCurrency: TCurrencyCode | null

  serviceId: number | null
  servicePrice: number | null
  servicePriceCurrency: TCurrencyCode | null

  deliveryLeadTime: number | null
  productionLeadTime: number | null

  id: number
}

export interface IEditOfferPayload extends TAdminPayload {
  offer: TOffer
}

// FETCH_OFFERS

export interface fetchOffersAction {
  type: typeof FETCH_OFFERS_REQUESTED
  payload: TAdminPayload
}

export interface fetchOffersSucceeded {
  type: typeof FETCH_OFFERS_SUCCEEDED
  offers: TLightOfferInTable[]
}

export interface fetchOffersError {
  type: typeof FETCH_OFFERS_ERROR
  error: TNetworkError
}

// SET_OFFERS_TABLE_FILTER_STATE

export interface setOffersTableFilterState {
  type: typeof SET_OFFERS_TABLE_FILTER_STATE
  tableFilterState: TFilterState
}

// SET_OFFERS_TABLE_SORTER_STATE

export interface setOffersTableSorterState {
  type: typeof SET_OFFERS_TABLE_SORTER_STATE
  tableSorterState: TSorterState
}

// CREATE_OFFER

export interface createOfferAction {
  type: typeof CREATE_OFFER_REQUESTED
  payload: IEditOfferPayload
}

export interface createOfferSucceeded {
  type: typeof CREATE_OFFER_SUCCEEDED
  offer: TOffer
}

export interface createOfferError {
  type: typeof CREATE_OFFER_ERROR
  error: TNetworkError
}

// FETCH_SINGLE_OFFER

export interface fetchSingleOfferAction {
  type: typeof FETCH_SINGLE_OFFER_REQUESTED
  payload: ISingleItemActionPayload
}

export interface fetchSingleOfferSucceeded {
  type: typeof FETCH_SINGLE_OFFER_SUCCEEDED
  editedOffer: TOffer
}

export interface fetchSingleOfferError {
  type: typeof FETCH_SINGLE_OFFER_ERROR
  error: TNetworkError
}

// EDIT_OFFER

export interface editOfferAction {
  type: typeof EDIT_OFFER_REQUESTED
  payload: IEditOfferPayload
}

export interface editOfferSucceeded {
  type: typeof EDIT_OFFER_SUCCEEDED
  offer: TOffer
}

export interface editOfferError {
  type: typeof EDIT_OFFER_ERROR
  error: TNetworkError
}

// DELETE_OFFER

export interface deleteOfferAction {
  type: typeof DELETE_OFFER_REQUESTED
  payload: ISingleItemActionPayload
}

export interface deleteOfferSucceeded {
  type: typeof DELETE_OFFER_SUCCEEDED
  id: number
}

export interface deleteOfferError {
  type: typeof DELETE_OFFER_ERROR
  error: TNetworkError
}

// FETCH_OFFER_ATTACHMENTS

export type TOfferAttachmentUpload = IAttachmentUpload & {
  offerId: number
}

export type TOfferAttachment = TAttachment & {
  offerId: number
}

export interface fetchOfferAttachmentsAction {
  type: typeof FETCH_OFFER_ATTACHMENTS_REQUESTED
  payload: ISingleItemActionPayload
}

export interface fetchOfferAttachmentsSucceeded {
  type: typeof FETCH_OFFER_ATTACHMENTS_SUCCEEDED
  offerAttachments: TOfferAttachment[]
}

export interface fetchOfferAttachmentsError {
  type: typeof FETCH_OFFER_ATTACHMENTS_ERROR
  error: TNetworkError
}

// UPLOAD_OFFER_ATTACHMENT

export interface uploadOfferAttachmentAction {
  type: typeof UPLOAD_OFFER_ATTACHMENT_REQUESTED
  payload: IUploadSingleAttachmentPayload
}

export interface uploadOfferAttachmentSucceeded {
  type: typeof UPLOAD_OFFER_ATTACHMENT_SUCCEEDED
  attachment: TOfferAttachment
}

export interface uploadOfferAttachmentError {
  type: typeof UPLOAD_OFFER_ATTACHMENT_ERROR
  error: TNetworkError
}

// DELTE_OFFER_ATTACHMENT

export interface deleteOfferAttachmentSucceeded {
  type: typeof DELETE_OFFER_ATTACHMENT_SUCCEEDED
  payload: ISingleItemActionPayload
}

// OFFER_ACTIONS_UNLOCK

export interface offerActionsUnlockAction {
  type: typeof OFFER_ACTIONS_UNLOCK
}

// CLOSE_OFFER_DETAILS

export interface closeOfferDetailsAction {
  type: typeof CLOSE_OFFER_DETAILS
}

export type OffersActionTypes =
  | fetchOffersAction
  | fetchOffersError
  | fetchOffersSucceeded
  | setOffersTableFilterState
  | setOffersTableSorterState
  | createOfferAction
  | createOfferSucceeded
  | createOfferError
  | fetchSingleOfferAction
  | fetchSingleOfferSucceeded
  | fetchSingleOfferError
  | editOfferAction
  | editOfferSucceeded
  | editOfferError
  | deleteOfferAction
  | deleteOfferSucceeded
  | deleteOfferError
  | fetchOfferAttachmentsAction
  | fetchOfferAttachmentsSucceeded
  | fetchOfferAttachmentsError
  | uploadOfferAttachmentAction
  | uploadOfferAttachmentSucceeded
  | uploadOfferAttachmentError
  | deleteOfferAttachmentSucceeded
  | offerActionsUnlockAction
  | closeOfferDetailsAction
