import React, { useState, useEffect, useCallback, ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { Container, InputGroup, InputGroupAddon, InputGroupText, Table } from 'reactstrap'
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik'
import * as Yup from 'yup'
import {
  CButton,
  CLabel,
  CTabs,
  CTabContent,
  CNav,
  CNavItem,
  CNavLink,
  CTabPane,
  CTextarea,
  CCardBody,
  CCard,
} from '@coreui/react'
import Select from '@material-ui/core/Select'
import deepEqual from 'deep-equal'
import { v4 as uuidv4 } from 'uuid'

import * as actionTypes from '../../store/action-types'
import { TRootState } from '../../store/reducers'
import {
  FormActionsPanel,
  AttachmentsUploadPanel,
  ConnectedBomsTable,
  ConnectedOrdersTable,
  IConnectedOrdersTableItem,
  ConnectedOffersTable,
  getUploadedFileType,
  CustomErrorMessage,
  BasicFormField,
} from '../../components'
import {
  inputLabelSpacingBottom,
  inputFieldSpacingBottom,
  successMessageDuration,
  maximumDescriptionLength,
  getErrorMessageFromStatus,
  preventNavigationChange,
} from '../../utils'
import { TFormTab } from '../admin'

export const serviceNavTabs: TFormTab[] = [
  { name: 'general-information', title: 'Informacje ogólne', isEditOnly: false },
  { name: 'additional-information', title: 'Informacje dodatkowe', isEditOnly: false },
  { name: 'offers', title: 'Oferty', isEditOnly: true },
  { name: 'orders', title: 'Zamówienia', isEditOnly: true },
  { name: 'attachments', title: 'Załączniki', isEditOnly: false },
]

export const ServiceDetails: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tenant, id, mode, tab } = useParams<{
    tenant: string
    id: string
    mode: actionTypes.TFormMode
    tab: string
  }>()
  const paramsServiceId = id
  const paramsServiceTab = tab

  const [didFormValidationOccur, setDidFormValidationOccur] = useState(false)
  const [isOneFieldChanged, setIsOneFieldChanged] = useState(false)
  const [isServiceModifiedAndUnsaved, setIsServiceModifiedAndUnsaved] = useState<boolean>(false)

  const authState = useSelector((state: TRootState) => state.auth)
  const servicesState = useSelector((state: TRootState) => state.services)
  const suppliersState = useSelector((state: TRootState) => state.suppliers)

  const closeServiceDetails = useCallback(() => {
    history.push(`/${tenant}/admin/services`)
  }, [dispatch, history, tenant])

  const mainTab = serviceNavTabs[0].name

  const isServiceEditable =
    authState?.authData?.roles?.includes('Services_write') &&
    (mode === 'edit' ? !!servicesState.editedService : true)

  const isThereNetworkError =
    servicesState?.serviceCreateError?.status ||
    servicesState?.serviceEditSaveError?.status ||
    servicesState?.serviceDeleteError?.status

  // Set data for editedService in modal when clicked on row or opened in new window
  useEffect(() => {
    if (
      tenant &&
      authState.authData &&
      paramsServiceId &&
      servicesState.services &&
      servicesState.services.length &&
      mode === 'edit'
    ) {
      const foundService = servicesState.services.find(
        (service: actionTypes.TService) => Number(service.id) === Number(paramsServiceId)
      )

      if (foundService) {
        dispatch({
          type: actionTypes.OPEN_SERVICE_DETAILS,
          editedService: foundService,
        })
      } else {
        history.replace(`/${tenant}/admin/services`)
      }
    }
  }, [
    dispatch,
    tenant,
    authState,
    history,
    paramsServiceId,
    servicesState.services,
    paramsServiceTab,
  ])

  // Fix the path in edit
  useEffect(() => {
    if (tenant && authState.authData) {
      const tabsArray = serviceNavTabs?.map((navTab: TFormTab) =>
        mode === 'edit' ? navTab.name : !navTab.isEditOnly ? navTab.name : undefined
      )

      if (paramsServiceTab === undefined || !tabsArray.includes(paramsServiceTab)) {
        history.replace(
          `/${tenant}/admin/services/${
            mode === 'create' ? 'create' : `edit/${paramsServiceId}`
          }/${mainTab}`
        )
      }
    }
  }, [tenant, authState, history, paramsServiceId, paramsServiceTab, mode])

  // Fetch more data for edit
  useEffect(() => {
    if (
      tenant &&
      authState.isAuthenticated &&
      authState.authData &&
      paramsServiceId &&
      mode === 'edit'
    ) {
      dispatch({
        type: actionTypes.FETCH_SERVICE_BOMS_REQUESTED,
        payload: { tenant: tenant, token: authState.authData?.token, id: paramsServiceId },
      })

      dispatch({
        type: actionTypes.FETCH_SERVICE_OFFERS_REQUESTED,
        payload: { tenant: tenant, token: authState.authData?.token, id: paramsServiceId },
      })

      dispatch({
        type: actionTypes.FETCH_SERVICE_ATTACHMENTS_REQUESTED,
        payload: {
          tenant: tenant,
          token: authState.authData?.token,
          entityId: Number(paramsServiceId),
          fileAttachmentTypeId: 3,
        },
      })
    }
  }, [dispatch, tenant, authState.isAuthenticated, authState.authData, paramsServiceId])

  // Close modal on a successful create
  useEffect(() => {
    if (
      !servicesState.isServiceCreating &&
      !servicesState.serviceCreateError &&
      servicesState.isServiceCreated
    ) {
      setTimeout(() => {
        closeServiceDetails()
      }, successMessageDuration)
    }
  }, [servicesState.isServiceCreated, closeServiceDetails])

  // Track the changes again
  useEffect(() => {
    if (
      servicesState.isServiceEditSaved ||
      servicesState.isServiceCreated ||
      servicesState.isServiceDeleted
    ) {
      setIsServiceModifiedAndUnsaved(false)
    }
  }, [
    servicesState.isServiceEditSaved,
    servicesState.isServiceCreated,
    servicesState.isServiceDeleted,
  ])

  // Prevent navigation back and forth plus reload if modified
  useEffect(() => {
    preventNavigationChange(
      history,
      isServiceModifiedAndUnsaved,
      isServiceEditable,
      'services',
      paramsServiceId,
      mode
    )
    // Without pathname in location there is no tab change detection
  }, [location.pathname, history, isServiceModifiedAndUnsaved, isServiceEditable])

  // Unmount Component
  useEffect(() => {
    return () => {
      dispatch({
        type: actionTypes.CLOSE_SERVICE_DETAILS,
      })
      dispatch({
        type: actionTypes.CLEAR_SINGLE_ATTACHMENT_ERRORS,
      })
    }
  }, [])

  const ServiceSchema = Yup.object().shape({
    name: Yup.string().required('To pole jest wymagane!'),
  })

  const initialFileInput: any = undefined

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center">
      <CCard>
        <CCardBody className="bom-element-details">
          <h4 className={`text-center ${isThereNetworkError ? 'mb-2 pb-2' : 'mb-3 pb-2'}`}>
            {mode === 'edit'
              ? !authState?.authData?.roles?.includes('Services_write')
                ? 'Przeglądaj Usługę'
                : 'Edytuj Usługę'
              : 'Nowa Usługa'}
          </h4>
          {((mode === 'edit' && servicesState.editedService) || mode === 'create') && (
            <Formik
              initialValues={{
                name: servicesState.editedService?.name || '',
                supplierInfos: servicesState.editedService?.supplierInfos || [],
                description: servicesState.editedService?.description || '',
                newAttachmentFileInput: initialFileInput,
                newAttachmentAltName: '',
                uuid: mode === 'create' ? uuidv4() : servicesState.editedService?.uuid || null,
              }}
              validationSchema={ServiceSchema}
              onSubmit={(values) => {
                if (isServiceEditable) {
                  setDidFormValidationOccur(true)
                  setIsOneFieldChanged(false)

                  const shortenedSuppliers = values?.supplierInfos?.map(
                    (supplierInfo: actionTypes.TSupplier) => ({
                      supplierId: supplierInfo.id || supplierInfo.supplierId,
                      serviceId: mode === 'create' ? null : servicesState?.editedService?.id,
                    })
                  )

                  dispatch({
                    type:
                      mode === 'create'
                        ? actionTypes.CREATE_SERVICE_REQUESTED
                        : actionTypes.EDIT_SERVICE_REQUESTED,
                    payload: {
                      tenant: tenant,
                      token: authState.authData?.token,
                      service: {
                        name: values.name,
                        description: values.description,
                        supplierInfos: shortenedSuppliers,
                        uuid: values?.uuid || uuidv4(),
                        ...(servicesState.editedService?.id && {
                          id: servicesState.editedService?.id,
                        }),
                      },
                    },
                  })
                }
              }}
              enableReinitialize={false}
              validateOnBlur={false}
              validateOnChange={didFormValidationOccur}
            >
              {({ initialValues, values, errors, setFieldValue, resetForm }) => (
                <Form
                  onChange={() => {
                    // Values here are always 1 step behind
                    let isModified = !deepEqual(values, initialValues, { strict: false })

                    if (isModified) {
                      /* If form is brought to its initial state then it is not modified */
                      setIsServiceModifiedAndUnsaved(true)
                    } else {
                      setIsServiceModifiedAndUnsaved(false)
                    }

                    if (errors.name) {
                      setDidFormValidationOccur(true)
                    }
                    setIsOneFieldChanged(true)
                  }}
                >
                  {/*
                   * Display Network Error Message
                   */}

                  {!isOneFieldChanged && servicesState?.serviceCreateError && (
                    <CustomErrorMessage
                      wrapperClassNames="my-3"
                      customErrorMessageText={getErrorMessageFromStatus(
                        'create',
                        servicesState?.serviceCreateError?.status
                      )}
                    />
                  )}

                  {!isOneFieldChanged && servicesState?.serviceEditSaveError && (
                    <CustomErrorMessage
                      wrapperClassNames="my-3"
                      customErrorMessageText={getErrorMessageFromStatus(
                        'edit',
                        servicesState?.serviceEditSaveError?.status,
                        'usługi'
                      )}
                    />
                  )}

                  {servicesState?.serviceDeleteError && (
                    <CustomErrorMessage
                      wrapperClassNames="my-3"
                      customErrorMessageText={getErrorMessageFromStatus(
                        'delete',
                        servicesState?.serviceDeleteError?.status,
                        'usługi'
                      )}
                    />
                  )}

                  {/*
                   * Form Tabs
                   */}

                  <CTabs activeTab={paramsServiceTab}>
                    <CNav variant="tabs" className="mb-4 mt-4">
                      {serviceNavTabs?.map((modalTab: TFormTab) => {
                        if (modalTab.isEditOnly && mode === 'create') {
                          return null
                        } else {
                          return (
                            <CNavItem key={modalTab.name}>
                              <CNavLink
                                className={`${
                                  modalTab.name === 'general-information' && errors.name
                                    ? // (modalTab.name === 'additional-information' &&
                                      //   (errors.producerId || errors.code))
                                      'text-danger is-invalid'
                                    : ''
                                }`}
                                data-tab={modalTab.name}
                                onClick={() => {
                                  history.replace(
                                    `/${tenant}/admin/services/${
                                      mode === 'edit' ? `edit/${paramsServiceId}` : 'create'
                                    }/${modalTab.name}`
                                  )
                                }}
                              >
                                {modalTab.title}
                              </CNavLink>
                            </CNavItem>
                          )
                        }
                      })}
                    </CNav>
                    <CTabContent>
                      {/*
                       * General Information Tab
                       */}

                      <CTabPane data-tab="general-information">
                        <BasicFormField
                          fieldId="service-name"
                          fieldLabel="Nazwa"
                          fieldIcon="cil-short-text"
                          formikFieldName="name"
                          fieldValue={values.name}
                          fieldError={errors.name}
                          fieldType="text"
                          placeholder="Wprowadź nazwę usługi"
                        />

                        <FieldArray
                          name="supplierInfos"
                          render={(arrayHelpers) => (
                            <div>
                              <CLabel
                                htmlFor="service-supplier-selector"
                                className={`${inputLabelSpacingBottom}`}
                              >
                                Dostawcy
                              </CLabel>
                              <InputGroup
                                id="service-supplier-selector"
                                className={`${inputFieldSpacingBottom} flex-nowrap`}
                              >
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText
                                    className={
                                      errors.supplierInfos && 'text-danger input-error-icon'
                                    }
                                  >
                                    <i className="cil-address-book"></i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <ErrorMessage
                                  name="supplierSelector"
                                  component="span"
                                  className="text-danger input-error-message"
                                />
                                <Field
                                  as={Select}
                                  name="supplierSelector"
                                  variant="outlined"
                                  native
                                  value="placeholder"
                                  className="item-selector  supplier-selector w-100"
                                  onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                                    if (document && document.activeElement) {
                                      const elem: any = document.activeElement
                                      elem.blur()
                                    }

                                    const foundSupplierInList = suppliersState?.suppliers?.find(
                                      (supplierInList: actionTypes.TSupplier) =>
                                        Number(supplierInList.id) ===
                                        Number(event.currentTarget.value)
                                    )

                                    arrayHelpers.push(foundSupplierInList)
                                  }}
                                >
                                  <option value="placeholder" disabled hidden>
                                    {values.supplierInfos.length
                                      ? 'Wybierz dodatkowego dostawcę'
                                      : 'Wybierz dostawcę'}
                                  </option>
                                  {suppliersState?.suppliers?.length > 0 ? (
                                    suppliersState?.suppliers?.map(
                                      (supplierInList: actionTypes.TSupplier) => {
                                        if (
                                          !values.supplierInfos.find(
                                            (supplierInfo: any) =>
                                              (supplierInfo.id || supplierInfo.supplierId) ===
                                              supplierInList.id
                                          )
                                        ) {
                                          return (
                                            <option
                                              key={supplierInList.id || supplierInList.supplierId}
                                              value={supplierInList.id || supplierInList.supplierId}
                                            >
                                              {supplierInList.name}
                                            </option>
                                          )
                                        } else {
                                          return null
                                        }
                                      }
                                    )
                                  ) : (
                                    <option value="null" disabled>
                                      Brak dostawców do wyboru!
                                    </option>
                                  )}
                                  )
                                </Field>
                              </InputGroup>

                              {/*
                               * Suppliers Table
                               */}

                              {values?.supplierInfos?.length > 0 && (
                                <Table bordered striped className="item-inner-details-table mb-3">
                                  <thead>
                                    <tr>
                                      <th style={{ width: '45px', textAlign: 'center' }}>#</th>
                                      <th style={{ width: '70%' }}>Dostawca</th>
                                      <th style={{ width: '30%' }}>Kraj</th>
                                      <th style={{ width: '50px' }}></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {values?.supplierInfos?.map(
                                      (supplierInfo: any, indexOfSupplier: number) => {
                                        // Those should be returned from back-end
                                        const foundSupplierInList = suppliersState.suppliers.find(
                                          (supplierInList: actionTypes.TSupplier) =>
                                            supplierInList.id ===
                                            (supplierInfo?.supplierId || supplierInfo?.id)
                                        )

                                        const supplierName =
                                          supplierInfo?.name || foundSupplierInList?.name

                                        const supplierCountry =
                                          supplierInfo?.country || foundSupplierInList?.country

                                        return (
                                          <tr key={`supplier-${supplierName}-${indexOfSupplier}`}>
                                            <td style={{ textAlign: 'center' }}>
                                              {indexOfSupplier + 1}.
                                            </td>
                                            <td>{supplierName}</td>
                                            <td>{supplierCountry}</td>
                                            <td>
                                              <CButton
                                                color="danger"
                                                type="button"
                                                variant="outline"
                                                onClick={() => arrayHelpers.remove(indexOfSupplier)}
                                                className="list-action-button"
                                              >
                                                <i className="cil-trash"></i>
                                              </CButton>
                                            </td>
                                          </tr>
                                        )
                                      }
                                    )}
                                  </tbody>
                                </Table>
                              )}
                            </div>
                          )}
                        />
                      </CTabPane>

                      {/*
                       * Additional Information Tab
                       */}

                      <CTabPane data-tab="additional-information">
                        <CLabel
                          htmlFor="service-description"
                          className={`${inputLabelSpacingBottom}`}
                        >
                          Opis usługi
                        </CLabel>
                        <InputGroup id="service-description" className={`mb-4`}>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText
                              className={errors.description && 'text-danger input-error-icon'}
                            >
                              <i className="cil-layers"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <ErrorMessage
                            name="description"
                            component="span"
                            className="text-danger input-error-message"
                          />
                          <Field
                            as={CTextarea}
                            name="description"
                            placeholder="Wprowadź opis usługi..."
                            maxLength={maximumDescriptionLength}
                            className={
                              errors.description
                                ? 'item-description form-control is-invalid'
                                : 'item-description'
                            }
                          />
                        </InputGroup>

                        {mode === 'edit' && (
                          <ConnectedBomsTable
                            mode="service"
                            editedItemBoms={servicesState.editedServiceBoms}
                            fetchItemBomsError={servicesState.fetchServiceBomsError}
                            tenant={tenant}
                          />
                        )}
                      </CTabPane>

                      {/*
                       * Offers Tab
                       */}

                      <CTabPane data-tab="offers">
                        <ConnectedOffersTable
                          mode="service"
                          areItemOffersLoading={servicesState.areServiceOffersLoading}
                          editedItemOffers={servicesState.editedServiceOffers}
                          fetchItemOffersError={servicesState.fetchServiceOffersError}
                          tenant={tenant}
                        />
                      </CTabPane>

                      {/*
                       * Orders Tab
                       */}

                      <CTabPane data-tab="orders">
                        <ConnectedOrdersTable
                          mode="service"
                          areItemOrdersLoading={servicesState.areServiceOrdersLoading}
                          editedItemOrders={
                            (servicesState?.editedService?.orders as IConnectedOrdersTableItem[]) ||
                            null
                          }
                          fetchItemOrdersError={servicesState.fetchServiceOrdersError}
                          tenant={tenant}
                        />
                      </CTabPane>

                      {/*
                       * Attachments Tab
                       */}

                      <CTabPane data-tab="attachments">
                        <AttachmentsUploadPanel
                          attachments={servicesState.editedServiceAttachments}
                          fetchAttachmentsError={servicesState.fetchServiceAttachmentsError}
                          isAttachmentUploading={servicesState.isServiceAttachmentUploading}
                          isAttachmentUploaded={servicesState.isServiceAttachmentUploaded}
                          attachmentUploadError={servicesState.serviceAttachmentUploadingError}
                          newAttachmentFileInput={values.newAttachmentFileInput}
                          newAttachmentAltNameError={errors.newAttachmentAltName}
                          dispatchAttachmentUpload={(encodedFile) => {
                            dispatch({
                              type: actionTypes.UPLOAD_SERVICE_ATTACHMENT_REQUESTED,
                              payload: {
                                tenant: tenant,
                                token: authState.authData?.token,
                                uploadAttachment: {
                                  fileAttachmentTypeId: 3,
                                  entityUuid: values.uuid,
                                  entityId: paramsServiceId ? Number(paramsServiceId) : null,

                                  type: getUploadedFileType(
                                    values.newAttachmentFileInput?.type,
                                    values.newAttachmentFileInput?.name
                                  ),
                                  name: values.newAttachmentFileInput.name,
                                  base64EncodedFile: encodedFile,
                                  altName: values?.newAttachmentAltName || null,
                                },
                              },
                            })

                            mode === 'create' && setIsServiceModifiedAndUnsaved(true)
                          }}
                          dispatchModalActionsUnlock={() =>
                            dispatch({ type: actionTypes.SERVICE_DETAILS_ACTIONS_UNLOCK })
                          }
                          dispatchSingleAttachmentFetch={(
                            attachmentId,
                            attachmentType,
                            fileName,
                            download
                          ) =>
                            dispatch({
                              type: actionTypes.FETCH_SINGLE_ATTACHMENT_REQUESTED,
                              payload: {
                                tenant: tenant,
                                token: authState.authData?.token,
                                id: attachmentId,
                                attachmentType: attachmentType,
                                fileName: fileName,
                                download: download,
                              },
                            })
                          }
                          dispatchSingleAttachmentDelete={(attachmentId) =>
                            dispatch({
                              type: actionTypes.DELETE_SINGLE_ATTACHMENT_REQUESTED,
                              payload: {
                                tenant: tenant,
                                token: authState.authData?.token,
                                id: attachmentId,
                              },
                              section: 'services',
                            })
                          }
                          isUploadDisabled={
                            servicesState.isServiceCreating ||
                            servicesState.isServiceDeleting ||
                            servicesState.isServiceEditSaving ||
                            servicesState.isServiceCreated ||
                            servicesState.isServiceDeleted ||
                            servicesState.isServiceEditSaved ||
                            servicesState.isServiceAttachmentUploading ||
                            !values.newAttachmentFileInput
                          }
                          isEditable={isServiceEditable}
                          setFieldValue={setFieldValue}
                          noAttachmentsTextFirstWords="Ta usługa"
                        />
                      </CTabPane>
                    </CTabContent>
                  </CTabs>

                  <FormActionsPanel
                    mode={mode}
                    padding="pt-3 pb-1"
                    isSaving={
                      mode === 'create'
                        ? servicesState.isServiceCreating
                        : servicesState.isServiceEditSaving
                    }
                    isSaved={
                      mode === 'create'
                        ? servicesState.isServiceCreated
                        : servicesState.isServiceEditSaved
                    }
                    isDeleting={servicesState.isServiceDeleting}
                    isDeleted={servicesState.isServiceDeleted}
                    setDidFormValidationOccur={setDidFormValidationOccur}
                    didFormValidationOccur={didFormValidationOccur}
                    formErrorsBool={Boolean(errors.name)}
                    closeAction={actionTypes.CLOSE_SERVICE_DETAILS}
                    deleteAction={actionTypes.DELETE_SERVICE_REQUESTED}
                    deletePayload={{
                      tenant: tenant,
                      token: authState.authData?.token,
                      id: servicesState.editedService?.id,
                    }}
                    closeFunction={closeServiceDetails}
                    canDelete={servicesState.editedService?.canDelete}
                    isEditable={isServiceEditable}
                    disabledDeleteButtonClassNames="delete-details-button"
                    confirmDeleteMessageJSX={
                      <>
                        Czy na pewno chcesz usunąć usługę?
                        {initialValues?.name ? (
                          <>
                            <br />
                            <strong>{initialValues?.name}</strong>
                          </>
                        ) : (
                          ''
                        )}
                      </>
                    }
                  />
                </Form>
              )}
            </Formik>
          )}
        </CCardBody>
      </CCard>
    </Container>
  )
}
