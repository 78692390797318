import React from 'react'

export const CancelFormMessagePrompt: React.FC<any> = () => {
  return (
    <>
      <strong>Masz niezapisane zmiany!</strong>
      <br />
      Czy na pewno chcesz wyjść?
    </>
  )
}
