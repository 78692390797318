import NumberFormat from 'react-number-format'
import { globalDecimalSeparator, globalThousandSeparator } from '.'

const localNumberFormatClassForCurrency: any = new NumberFormat({
  displayType: 'input',
  thousandSeparator: globalThousandSeparator,
  decimalSeparator: globalDecimalSeparator,
  decimalScale: 2,
  fixedDecimalScale: true,
  allowNegative: false,
  allowLeadingZeros: true,
})

export const convertNumberToNumericString = (
  numberToConvert: string | number | null | undefined
): string | '' => {
  if (typeof numberToConvert === 'number') {
    let convertedString = localNumberFormatClassForCurrency.formatValueProp(numberToConvert)

    if (convertedString && typeof convertedString === 'string') {
      return convertedString
    }
  }

  if (typeof numberToConvert === 'string') {
    return numberToConvert
  }

  return ''
}

const localNumberFormatClassForQuantity: any = new NumberFormat({
  displayType: 'input',
  thousandSeparator: globalThousandSeparator,
  decimalSeparator: globalDecimalSeparator,
  decimalScale: 0,
  fixedDecimalScale: false,
  allowNegative: false,
  allowLeadingZeros: true,
})

export const convertQuantityToNumericString = (
  numberToConvert: string | number | null | undefined
): string | '' => {
  if (typeof numberToConvert === 'number') {
    let convertedString = localNumberFormatClassForQuantity.formatValueProp(numberToConvert)

    if (convertedString && typeof convertedString === 'string') {
      return convertedString
    }
  }

  if (typeof numberToConvert === 'string') {
    return numberToConvert
  }

  return ''
}
