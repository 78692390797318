export const makeDoubleDigit = (num: number) => {
  return num < 10 ? '0' + String(num) : num
}

export const displayLocalDate = (date: string | null | undefined) => {
  if (!date) {
    return null
  } else {
    const localDate = new Date(date)

    const localDateString = `${makeDoubleDigit(localDate.getDate())}.${makeDoubleDigit(
      localDate.getMonth() + 1
    )}.${localDate.getFullYear()}${' '} \u00a0${makeDoubleDigit(
      localDate.getHours() - localDate.getTimezoneOffset() / 60 - 1
    )}:${makeDoubleDigit(localDate.getMinutes())}:${makeDoubleDigit(localDate.getSeconds())}`

    return localDateString
  }
}
