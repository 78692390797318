import { call, put, takeLatest, delay } from 'redux-saga/effects'

import { sectionFetch, supplierCall, deleteItemCall, singleItemFetch } from './api'
import * as actionTypes from '../action-types'
import { successMessageDuration } from '../../utils'

export function* fetchSuppliers(action: actionTypes.fetchSuppliersAction) {
  try {
    const response = yield call(sectionFetch, action.payload, 'suppliers')
    yield put({ type: actionTypes.FETCH_SUPPLIERS_SUCCEEDED, suppliers: response.data })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_SUPPLIERS_ERROR,
      error: error?.response,
    })
  }
}

export function* fetchSingleSupplier(action: actionTypes.fetchSingleSupplierAction) {
  try {
    const response = yield call(
      singleItemFetch,
      action.payload,
      `suppliers/edit/${action.payload.id}`
    )
    yield put({
      type: actionTypes.FETCH_SINGLE_SUPPLIER_SUCCEEDED,
      editedSupplier: response.data,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_SINGLE_SUPPLIER_ERROR,
      error: error?.response,
    })
  }
}

export function* createSupplier(action: actionTypes.createSupplierAction) {
  try {
    const response = yield call(supplierCall, action.payload)
    yield put({
      type: actionTypes.CREATE_SUPPLIER_SUCCEEDED,
      supplier: { ...action.payload.supplier, id: response.data.id },
    })
    yield delay(successMessageDuration)
    yield put({
      type: actionTypes.SUPPLIER_ACTIONS_UNLOCK,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.CREATE_SUPPLIER_ERROR,
      error: error?.response,
    })
    yield put({
      type: actionTypes.SUPPLIER_ACTIONS_UNLOCK,
    })
  }
}

export function* editSupplier(action: actionTypes.editSupplierAction) {
  try {
    yield call(supplierCall, action.payload)
    yield put({
      type: actionTypes.EDIT_SUPPLIER_SUCCEEDED,
      supplier: action.payload.supplier,
    })
    yield delay(successMessageDuration)
    yield put({
      type: actionTypes.SUPPLIER_ACTIONS_UNLOCK,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.EDIT_SUPPLIER_ERROR,
      error: error?.response,
    })
    yield put({
      type: actionTypes.SUPPLIER_ACTIONS_UNLOCK,
    })
  }
}

export function* deleteSupplier(action: actionTypes.deleteSupplierAction) {
  try {
    yield call(deleteItemCall, action.payload, 'suppliers')
    yield put({
      type: actionTypes.DELETE_SUPPLIER_SUCCEEDED,
      id: action.payload.id,
    })
    yield delay(successMessageDuration)
    yield put({
      type: actionTypes.SUPPLIER_ACTIONS_UNLOCK,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.DELETE_SUPPLIER_ERROR,
      error: error?.response,
    })
    yield put({
      type: actionTypes.SUPPLIER_ACTIONS_UNLOCK,
    })
  }
}

export function* suppliersSaga() {
  yield takeLatest(actionTypes.FETCH_SUPPLIERS_REQUESTED, fetchSuppliers)
  yield takeLatest(actionTypes.FETCH_SINGLE_SUPPLIER_REQUESTED, fetchSingleSupplier)
  yield takeLatest(actionTypes.CREATE_SUPPLIER_REQUESTED, createSupplier)
  yield takeLatest(actionTypes.DELETE_SUPPLIER_REQUESTED, deleteSupplier)
  yield takeLatest(actionTypes.EDIT_SUPPLIER_REQUESTED, editSupplier)
}
