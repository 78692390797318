import { CCol, CContainer, CRow } from '@coreui/react'

export const PasswordResetErrorPage: React.FC<{ status: number }> = ({ status }) => {
  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="6">
            <div className="clearfix">
              <h1 className="float-left display-3 mr-4">
                {status === 500 || status === 410 ? status : '404'}
              </h1>
              <h4 className="pt-3">Oops!</h4>
              <p className="text-muted float-left">
                {status === 500 && 'Wystąpił wewnętrzny błąd serwera!'}
                {status === 410 && 'Sesja zmiany hasła wygasła!'}
                {status !== 500 &&
                  status !== 410 &&
                  'Strona, której szukasz nie została odnaleziona!'}
              </p>
            </div>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}
