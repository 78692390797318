import {
  TAdminPayload,
  ISingleItemActionPayload,
  TAttachment,
  IUploadSingleAttachmentPayload,
  IFetchItemAttachmentsPayload,
  TFilterState,
  TSorterState,
} from './admin'
import { TNetworkError } from './auth'
import { TSupplier } from './suppliers'
import { TOffer } from './offers'
import { TCurrencyCode, TOrder } from '.'

export const FETCH_IMPLEMENTATION_COSTS_REQUESTED = 'FETCH_IMPLEMENTATION_COSTS_REQUESTED'
export const FETCH_IMPLEMENTATION_COSTS_SUCCEEDED = 'FETCH_IMPLEMENTATION_COSTS_SUCCEEDED'
export const FETCH_IMPLEMENTATION_COSTS_ERROR = 'FETCH_IMPLEMENTATION_COSTS_ERROR'

export const SET_IMPLEMENTATION_COSTS_TABLE_FILTER_STATE =
  'SET_IMPLEMENTATION_COSTS_TABLE_FILTER_STATE'
export const SET_IMPLEMENTATION_COSTS_TABLE_SORTER_STATE =
  'SET_IMPLEMENTATION_COSTS_TABLE_SORTER_STATE'

export const FETCH_IMPLEMENTATION_COST_BOMS_REQUESTED = 'FETCH_IMPLEMENTATION_COST_BOMS_REQUESTED'
export const FETCH_IMPLEMENTATION_COST_BOMS_SUCCEEDED = 'FETCH_IMPLEMENTATION_COST_BOMS_SUCCEEDED'
export const FETCH_IMPLEMENTATION_COST_BOMS_ERROR = 'FETCH_IMPLEMENTATION_COST_BOMS_ERROR'

export const FETCH_IMPLEMENTATION_COST_OFFERS_REQUESTED =
  'FETCH_IMPLEMENTATION_COST_OFFERS_REQUESTED'
export const FETCH_IMPLEMENTATION_COST_OFFERS_SUCCEEDED =
  'FETCH_IMPLEMENTATION_COST_OFFERS_SUCCEEDED'
export const FETCH_IMPLEMENTATION_COST_OFFERS_ERROR = 'FETCH_IMPLEMENTATION_COST_OFFERS_ERROR'

export const FETCH_IMPLEMENTATION_COST_ORDERS_REQUESTED =
  'FETCH_IMPLEMENTATION_COST_ORDERS_REQUESTED'
export const FETCH_IMPLEMENTATION_COST_ORDERS_SUCCEEDED =
  'FETCH_IMPLEMENTATION_COST_ORDERS_SUCCEEDED'
export const FETCH_IMPLEMENTATION_COST_ORDERS_ERROR = 'FETCH_IMPLEMENTATION_COST_ORDERS_ERROR'

export const CREATE_IMPLEMENTATION_COST_REQUESTED = 'CREATE_IMPLEMENTATION_COST_REQUESTED'
export const CREATE_IMPLEMENTATION_COST_SUCCEEDED = 'CREATE_IMPLEMENTATION_COST_SUCCEEDED'
export const CREATE_IMPLEMENTATION_COST_ERROR = 'CREATE_IMPLEMENTATION_COST_ERROR'

export const EDIT_IMPLEMENTATION_COST_REQUESTED = 'EDIT_IMPLEMENTATION_COST_REQUESTED'
export const EDIT_IMPLEMENTATION_COST_SUCCEEDED = 'EDIT_IMPLEMENTATION_COST_SUCCEEDED'
export const EDIT_IMPLEMENTATION_COST_ERROR = 'EDIT_IMPLEMENTATION_COST_ERROR'

export const DELETE_IMPLEMENTATION_COST_REQUESTED = 'DELETE_IMPLEMENTATION_COST_REQUESTED'
export const DELETE_IMPLEMENTATION_COST_SUCCEEDED = 'DELETE_IMPLEMENTATION_COST_SUCCEEDED'
export const DELETE_IMPLEMENTATION_COST_ERROR = 'DELETE_IMPLEMENTATION_COST_ERROR'

export const FETCH_IMPLEMENTATION_COST_ATTACHMENTS_REQUESTED =
  'FETCH_IMPLEMENTATION_COST_ATTACHMENTS_REQUESTED'
export const FETCH_IMPLEMENTATION_COST_ATTACHMENTS_SUCCEEDED =
  'FETCH_IMPLEMENTATION_COST_ATTACHMENTS_SUCCEEDED'
export const FETCH_IMPLEMENTATION_COST_ATTACHMENTS_ERROR =
  'FETCH_IMPLEMENTATION_COST_ATTACHMENTS_ERROR'

export const UPLOAD_IMPLEMENTATION_COST_ATTACHMENT_REQUESTED =
  'UPLOAD_IMPLEMENTATION_COST_ATTACHMENT_REQUESTED'
export const UPLOAD_IMPLEMENTATION_COST_ATTACHMENT_SUCCEEDED =
  'UPLOAD_IMPLEMENTATION_COST_ATTACHMENT_SUCCEEDED'
export const UPLOAD_IMPLEMENTATION_COST_ATTACHMENT_ERROR =
  'UPLOAD_IMPLEMENTATION_COST_ATTACHMENT_ERROR'

export const DELETE_IMPLEMENTATION_COST_ATTACHMENT_SUCCEEDED =
  'DELETE_IMPLEMENTATION_COST_ATTACHMENT_SUCCEEDED'

export const OPEN_IMPLEMENTATION_COST_DETAILS = 'OPEN_IMPLEMENTATION_COST_DETAILS'
export const CLOSE_IMPLEMENTATION_COST_DETAILS = 'CLOSE_IMPLEMENTATION_COST_DETAILS'

export const IMPLEMENTATION_COST_DETAILS_ACTIONS_UNLOCK =
  'IMPLEMENTATION_COST_DETAILS_ACTIONS_UNLOCK'

export type TImplementationCost = {
  name: string
  price?: number

  supplierId: number
  supplierInfos?: TSupplier[] | null

  description: string

  orders: TImplementationCostInOrder[] | null

  canDelete: boolean

  id: number
  uuid: string | null
}

export type TImplementationCostInOrder = {
  orderId: number
  createDate: string
  quantity: number
  implementationCostPrice: number
  implementationCostPriceCurrency: TCurrencyCode
  orderNumber: string
}

export type TImplementationCostBom = {
  implementationCostDetails: any[]
  name: string
  id: number
}

export type TImplementationCostAttachment = TAttachment & {
  implementationCostId: number
}

export interface ICreateImplementationCostPayload extends TAdminPayload {
  implementationCost: TImplementationCost
}

// FETCH_IMPLEMENTATION_COSTS

export interface fetchImplementationCostsAction {
  type: typeof FETCH_IMPLEMENTATION_COSTS_REQUESTED
  payload: TAdminPayload
}

export interface fetchImplementationCostsSucceeded {
  type: typeof FETCH_IMPLEMENTATION_COSTS_SUCCEEDED
  implementationCosts: TImplementationCost[]
}

export interface fetchImplementationCostsError {
  type: typeof FETCH_IMPLEMENTATION_COSTS_ERROR
  error: TNetworkError
}

// SET_IMPLEMENTATION_COSTS_TABLE_FILTER_STATE

export interface setImplementationCostsTableFilterState {
  type: typeof SET_IMPLEMENTATION_COSTS_TABLE_FILTER_STATE
  tableFilterState: TFilterState
}

// SET_IMPLEMENTATION_COSTS_TABLE_SORTER_STATE

export interface setImplementationCostsTableSorterState {
  type: typeof SET_IMPLEMENTATION_COSTS_TABLE_SORTER_STATE
  tableSorterState: TSorterState
}

// CREATE_IMPLEMENTATION_COST

export interface createImplementationCostAction {
  type: typeof CREATE_IMPLEMENTATION_COST_REQUESTED
  payload: ICreateImplementationCostPayload
}

export interface createImplementationCostSucceeded {
  type: typeof CREATE_IMPLEMENTATION_COST_SUCCEEDED
  implementationCost: TImplementationCost
}

export interface createImplementationCostError {
  type: typeof CREATE_IMPLEMENTATION_COST_ERROR
  error: TNetworkError
}

// FETCH_IMPLEMENTATION_COST_BOMS

export interface fetchImplementationCostBomsAction {
  type: typeof FETCH_IMPLEMENTATION_COST_BOMS_REQUESTED
  payload: ISingleItemActionPayload
}

export interface fetchImplementationCostBomsSucceeded {
  type: typeof FETCH_IMPLEMENTATION_COST_BOMS_SUCCEEDED
  implementationCostBoms: TImplementationCostBom[]
}

export interface fetchImplementationCostBomsError {
  type: typeof FETCH_IMPLEMENTATION_COST_BOMS_ERROR
  error: TNetworkError
}

// FETCH_IMPLEMENTATION_COST_OFFERS

export interface fetchImplementationCostOffersAction {
  type: typeof FETCH_IMPLEMENTATION_COST_OFFERS_REQUESTED
  payload: ISingleItemActionPayload
}

export interface fetchImplementationCostOffersSucceeded {
  type: typeof FETCH_IMPLEMENTATION_COST_OFFERS_SUCCEEDED
  implementationCostOffers: TOffer[]
}

export interface fetchImplementationCostOffersError {
  type: typeof FETCH_IMPLEMENTATION_COST_OFFERS_ERROR
  error: TNetworkError
}

// FETCH_IMPLEMENTATION_COST_ORDERS

export interface fetchImplementationCostOrdersAction {
  type: typeof FETCH_IMPLEMENTATION_COST_ORDERS_REQUESTED
  payload: ISingleItemActionPayload
}

export interface fetchImplementationCostOrdersSucceeded {
  type: typeof FETCH_IMPLEMENTATION_COST_ORDERS_SUCCEEDED
  implementationCostOrders: TOrder[]
}

export interface fetchImplementationCostOrdersError {
  type: typeof FETCH_IMPLEMENTATION_COST_ORDERS_ERROR
  error: TNetworkError
}

// EDIT_IMPLEMENTATION_COST

export interface editImplementationCostAction {
  type: typeof EDIT_IMPLEMENTATION_COST_REQUESTED
  payload: ICreateImplementationCostPayload
}

export interface editImplementationCostSucceeded {
  type: typeof EDIT_IMPLEMENTATION_COST_SUCCEEDED
  implementationCost: TImplementationCost
}

export interface editImplementationCostError {
  type: typeof EDIT_IMPLEMENTATION_COST_ERROR
  error: TNetworkError
}

// DELETE_IMPLEMENTATION_COST

export interface deleteImplementationCostAction {
  type: typeof DELETE_IMPLEMENTATION_COST_REQUESTED
  payload: ISingleItemActionPayload
}

export interface deleteImplementationCostSucceeded {
  type: typeof DELETE_IMPLEMENTATION_COST_SUCCEEDED
  id: number
}

export interface deleteImplementationCostError {
  type: typeof DELETE_IMPLEMENTATION_COST_ERROR
  error: TNetworkError
}

// FETCH_IMPLEMENTATION_COST_ATTACHMENTS

export interface fetchImplementationCostAttachmentsAction {
  type: typeof FETCH_IMPLEMENTATION_COST_ATTACHMENTS_REQUESTED
  payload: IFetchItemAttachmentsPayload
}

export interface fetchImplementationCostAttachmentsSucceeded {
  type: typeof FETCH_IMPLEMENTATION_COST_ATTACHMENTS_SUCCEEDED
  implementationCostAttachments: TImplementationCostAttachment[]
}

export interface fetchImplementationCostAttachmentsError {
  type: typeof FETCH_IMPLEMENTATION_COST_ATTACHMENTS_ERROR
  error: TNetworkError
}

// UPLOAD_IMPLEMENTATION_COST_ATTACHMENT

export interface uploadImplementationCostAttachmentAction {
  type: typeof UPLOAD_IMPLEMENTATION_COST_ATTACHMENT_REQUESTED
  payload: IUploadSingleAttachmentPayload
}

export interface uploadImplementationCostAttachmentSucceeded {
  type: typeof UPLOAD_IMPLEMENTATION_COST_ATTACHMENT_SUCCEEDED
  attachment: TImplementationCostAttachment
}

export interface uploadImplementationCostAttachmentError {
  type: typeof UPLOAD_IMPLEMENTATION_COST_ATTACHMENT_ERROR
  error: TNetworkError
}

// DELETE_IMPLEMENTATION_COST_ATTACHMENT

export interface deleteImplementationCostAttachmentSucceeded {
  type: typeof DELETE_IMPLEMENTATION_COST_ATTACHMENT_SUCCEEDED
  payload: ISingleItemActionPayload
}

// DETAILS

export interface openImplementationCostDetailsAction {
  type: typeof OPEN_IMPLEMENTATION_COST_DETAILS
  editedImplementationCost: TImplementationCost | null
}

export interface closeImplementationCostDetailsAction {
  type: typeof CLOSE_IMPLEMENTATION_COST_DETAILS
}

export interface implementationCostActionsUnlockAction {
  type: typeof IMPLEMENTATION_COST_DETAILS_ACTIONS_UNLOCK
}

export type ImplementationCostsActionTypes =
  | fetchImplementationCostsAction
  | fetchImplementationCostsSucceeded
  | fetchImplementationCostsError
  | setImplementationCostsTableFilterState
  | setImplementationCostsTableSorterState
  | fetchImplementationCostBomsAction
  | fetchImplementationCostBomsSucceeded
  | fetchImplementationCostBomsError
  | fetchImplementationCostOffersAction
  | fetchImplementationCostOffersSucceeded
  | fetchImplementationCostOffersError
  | fetchImplementationCostOrdersAction
  | fetchImplementationCostOrdersSucceeded
  | fetchImplementationCostOrdersError
  | createImplementationCostAction
  | createImplementationCostSucceeded
  | createImplementationCostError
  | editImplementationCostAction
  | editImplementationCostSucceeded
  | editImplementationCostError
  | deleteImplementationCostAction
  | deleteImplementationCostSucceeded
  | deleteImplementationCostError
  | fetchImplementationCostAttachmentsAction
  | fetchImplementationCostAttachmentsSucceeded
  | fetchImplementationCostAttachmentsError
  | uploadImplementationCostAttachmentAction
  | uploadImplementationCostAttachmentSucceeded
  | uploadImplementationCostAttachmentError
  | deleteImplementationCostAttachmentSucceeded
  | openImplementationCostDetailsAction
  | closeImplementationCostDetailsAction
  | implementationCostActionsUnlockAction
