import React from 'react'

export const ThreeDots: React.FC<{}> = () => {
  return (
    <>
      <span className="blinking">.</span>
      <span className="blinking">.</span>
      <span className="blinking">.</span>
    </>
  )
}
