import React, { useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { InputGroup, InputGroupAddon, InputGroupText, Spinner, Table, Container } from 'reactstrap'
import { Formik, Form, Field, ErrorMessage, FastField } from 'formik'
import * as Yup from 'yup'
import {
  CLabel,
  CTabs,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CCard,
  CCardBody,
} from '@coreui/react'
import Select from '@material-ui/core/Select'
import NumberFormat from 'react-number-format'
import deepEqual from 'deep-equal'
import { confirmAlert } from 'react-confirm-alert'
import { makeStyles } from '@material-ui/core'

import * as actionTypes from '../../store/action-types'
import { TRootState } from '../../store/reducers'
import {
  BasicFormField,
  AttachmentsUploadPanel,
  FormFixedActionsPanel,
  ThreeDots,
  getUploadedFileType,
  CustomErrorMessage,
  ConfirmActionModal,
} from '../../components'
import {
  inputLabelSpacingBottom,
  inputFieldSpacingBottom,
  toDateInputValue,
  currencyExchanger,
  convertNumberToNumericString,
  globalThousandSeparator,
  globalDecimalSeparator,
  convertNumericStringToNumber,
  convertQuantityToNumericString,
  successMessageDuration,
  getErrorMessageFromStatus,
  globalCurrencyFallback,
  preventNavigationChange,
} from '../../utils'
import { offlineCurrencyExchangeRates } from '../admin'
import { OrderDeliveries } from './order-deliveries'

// You can only override one of the following: root, select, filled, outlined, selectMenu, disabled, icon, iconOpen, iconFilled, iconOutlined, nativeInput.
export const useStylesForOrder = makeStyles({
  root: {
    height: '35px',
    maxHeight: '35px',
    padding: '1px 14px',
    width: '165px',
    fontSize: '0.875rem',
    paddingTop: 0,
    paddingBottom: 0,
  },

  deliveryPayment: {
    height: '35px',
    maxHeight: '35px',
    padding: '1px 14px',
    width: '140px',
    fontSize: '0.875rem',
    paddingTop: 0,
    paddingBottom: 0,
  },

  warehouse: {
    height: '35px',
    maxHeight: '35px',
    padding: '1px 14px',
    width: '95px',
    fontSize: '0.875rem',
    paddingTop: 0,
    paddingBottom: 0,

    // This selector from their documentation does not work!
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white',
    },
  },
})

export const OrderDetails: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const materialUISelectClasses = useStylesForOrder()
  const { tenant, id, tab } = useParams<{ tenant: string; id: string; tab: string }>()
  const paramsOrderId = id
  const paramsOrderTab = tab

  const [didFormValidationOccur, setDidFormValidationOccur] = useState(false)
  const [isOneFieldChanged, setIsOneFieldChanged] = useState(false)
  const [isOrderModifiedAndUnsaved, setIsOrderModifiedAndUnsaved] = useState<boolean>(false)

  const authState = useSelector((state: TRootState) => state.auth)
  const ordersState = useSelector((state: TRootState) => state.orders)
  const adminState = useSelector((state: TRootState) => state.admin)
  const settingsState = useSelector((state: TRootState) => state.settings)

  const mode = 'edit'
  const unchosenWarehouseValue = -1
  const initialFileInput: any = undefined

  const exchangeRates = adminState.todayCurrencyExchange
    ? adminState.todayCurrencyExchange.rates
    : offlineCurrencyExchangeRates

  const isOrderEditable =
    authState?.authData?.roles?.includes('Orders_write') && !ordersState?.singleOrderFetchError

  const closeOrderDetails = useCallback(() => {
    history.push(`/${tenant}/admin/orders`)
  }, [history, tenant])

  // Fetch single order
  useEffect(() => {
    dispatch({
      type: actionTypes.FETCH_SINGLE_ORDER_REQUESTED,
      payload: { tenant: tenant, token: authState.authData?.token, id: Number(paramsOrderId) },
    })

    dispatch({
      type: actionTypes.FETCH_ORDER_ATTACHMENTS_REQUESTED,
      payload: {
        tenant: tenant,
        token: authState.authData?.token,
        entityId: Number(paramsOrderId),
        fileAttachmentTypeId: 4,
      },
    })
  }, [tenant, authState.authData, paramsOrderId, dispatch])

  // Update attachments after PDF is generated
  useEffect(() => {
    if (ordersState.isOrderPDFGenerated) {
      dispatch({
        type: actionTypes.FETCH_ORDER_ATTACHMENTS_REQUESTED,
        payload: {
          tenant: tenant,
          token: authState.authData?.token,
          entityId: Number(paramsOrderId),
          fileAttachmentTypeId: 4,
        },
      })
    }
  }, [tenant, authState.authData, paramsOrderId, dispatch, ordersState.isOrderPDFGenerated])

  // Redirect on not found order
  useEffect(() => {
    if (
      ordersState?.singleOrderFetchError?.status === 404 &&
      !ordersState.isSingleOrderLoading &&
      !ordersState.editedOrder
    ) {
      history.replace(`/${tenant}/admin/orders`)
    }
  }, [
    paramsOrderId,
    history,
    tenant,
    paramsOrderTab,
    ordersState.editedOrder,
    ordersState.singleOrderFetchError,
    ordersState.isSingleOrderLoading,
  ])

  // Run this once when order is fetched and check if proper delivery tab exists
  useEffect(() => {
    if (
      !ordersState?.singleOrderFetchError &&
      !ordersState?.isSingleOrderLoading &&
      ordersState?.editedOrder &&
      paramsOrderTab !== 'general-information' &&
      ordersState?.editedOrder?.orderDeliveries?.some(
        (orderDelivery: actionTypes.TOrderDelivery, orderDeliveryIndex: number) => {
          return paramsOrderTab !== `delivery-${orderDeliveryIndex + 1}`
        }
      ) &&
      paramsOrderTab !== 'attachments'
    ) {
      history.replace(`/${tenant}/admin/order/${paramsOrderId}/general-information`)
    }
  }, [ordersState.editedOrder, ordersState.singleOrderFetchError, ordersState.isSingleOrderLoading])

  // Close order view after deleting it
  useEffect(() => {
    if (ordersState?.isOrderDeleted) {
      setTimeout(() => closeOrderDetails(), successMessageDuration)
    }
  }, [ordersState.isOrderDeleted])

  // Track the changes again after successful save
  useEffect(() => {
    if (ordersState.isOrderEditSaved) {
      setIsOrderModifiedAndUnsaved(false)
    }
  }, [ordersState.isOrderEditSaved])

  // Prevent navigation back and forth plus reload if modified
  useEffect(() => {
    preventNavigationChange(
      history,
      isOrderModifiedAndUnsaved,
      isOrderEditable,
      'order',
      paramsOrderId,
      ''
    )
    // Without pathname in location there is no tab change detection
  }, [location.pathname, history, isOrderModifiedAndUnsaved, isOrderEditable])

  // Unmount Component
  useEffect(() => {
    return () => {
      dispatch({
        type: actionTypes.CLEAR_SINGLE_ATTACHMENT_ERRORS,
      })
    }
  }, [])

  const OrderSchema = Yup.object().shape({
    orderedDate: Yup.string().required('To pole jest wymagane!'),
    orderDeliveries: Yup.array().of(
      Yup.object().shape(
        {
          dispatchDate: Yup.date().nullable(),
          warehouseId: Yup.number().positive('Wybierz magazyn!').required('Wybierz magazyn!'),
          expectedArrivalDateFrom: Yup.date()
            .min(Yup.ref('dispatchDate'), 'Wsteczna data!')
            .nullable(),
          expectedArrivalDateTo: Yup.date().when(['expectedArrivalDateFrom', 'dispatchDate'], {
            is: (expectedArrivalDateFrom, dispatchDate) => {
              return expectedArrivalDateFrom && expectedArrivalDateFrom > dispatchDate
            },
            then: Yup.date().min(Yup.ref('expectedArrivalDateFrom'), 'Wsteczna data!').nullable(),
            otherwise: Yup.date().min(Yup.ref('dispatchDate'), 'Wsteczna data!').nullable(),
          }),

          invoices: Yup.array().of(
            Yup.object()
              .shape({
                // invoiceNumber: Yup.string().min(1).required('Wymagane!'),
                // invoicePrice: Yup.string().nullable().min(1).required('Wymagane!'),
                invoiceCreateDate: Yup.date().nullable(),
                invoicePaymentFinalDate: Yup.date()
                  .min(Yup.ref('invoiceCreateDate'), 'Wsteczna data!')
                  .nullable(),
              })
              .required()
          ),
        },
        [['expectedArrivalDateFrom', 'expectedArrivalDateTo']]
      )
    ),
  })

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center ">
      <CCard>
        <CCardBody className="order-details">
          <Formik
            initialValues={{
              orderStatus: ordersState.editedOrder?.orderStatus || 0,
              paymentStatus: ordersState.editedOrder?.paymentStatus || 0,

              bomNames: ordersState.editedOrder?.bomNames || '',

              orderedDate: ordersState.editedOrder?.orderedDate
                ? toDateInputValue(String(ordersState.editedOrder?.orderedDate))
                : null,

              bomElementsTotalPrice: ordersState.editedOrder?.bomElementsTotalPrice || '',
              bomElementsTotalPriceCurrency:
                ordersState.editedOrder?.bomElementsTotalPriceCurrency ||
                settingsState?.settings?.otherSettings?.defaultSystemCurrency ||
                globalCurrencyFallback,
              toolingTotalPrice: ordersState.editedOrder?.toolingTotalPrice || '',
              toolingTotalPriceCurrency:
                ordersState.editedOrder?.toolingTotalPriceCurrency ||
                settingsState?.settings?.otherSettings?.defaultSystemCurrency ||
                globalCurrencyFallback,
              servicesTotalPrice: ordersState.editedOrder?.servicesTotalPrice || '',
              servicesTotalPriceCurrency:
                ordersState.editedOrder?.servicesTotalPriceCurrency ||
                settingsState?.settings?.otherSettings?.defaultSystemCurrency ||
                globalCurrencyFallback,
              orderNumber: ordersState.editedOrder?.orderNumber || '',
              customOrderNumber: ordersState.editedOrder?.customOrderNumber || '',

              orderLines: ordersState.editedOrder?.orderLines || [],
              orderServices: ordersState.editedOrder?.orderServices || [],
              orderImplementationCosts: ordersState.editedOrder?.orderImplementationCosts || [],

              deliveriesTotalPrice: ordersState.editedOrder?.deliveriesTotalPrice || '',
              deliveriesTotalPriceCurrency:
                ordersState.editedOrder?.deliveriesTotalPriceCurrency ||
                settingsState?.settings?.otherSettings?.defaultSystemCurrency ||
                globalCurrencyFallback,

              orderDeliveries: ordersState.editedOrder?.orderDeliveries?.length
                ? ordersState.editedOrder?.orderDeliveries?.map(
                    (orderDelivery: actionTypes.TOrderDelivery) => {
                      return {
                        ...orderDelivery,

                        warehouseId: orderDelivery?.warehouseId
                          ? Number(orderDelivery?.warehouseId)
                          : unchosenWarehouseValue,
                        shipmentCost: orderDelivery?.shipmentCost || '',
                        shipmentCostCurrency:
                          (orderDelivery?.shipmentCostCurrency as actionTypes.TCurrencyCode) ||
                          settingsState?.settings?.otherSettings?.defaultSystemCurrency ||
                          globalCurrencyFallback,

                        invoices: orderDelivery?.invoices?.map(
                          (invoice: actionTypes.TDeliveryInvoice, invoiceIndex: number) => {
                            return {
                              ...invoice,
                              invoicePrice:
                                convertNumericStringToNumber(invoice?.invoicePrice) || '',
                              invoicePriceCurrency:
                                (invoice?.invoicePriceCurrency as actionTypes.TCurrencyCode) ||
                                settingsState?.settings?.otherSettings?.defaultSystemCurrency ||
                                globalCurrencyFallback,
                            }
                          }
                        ),

                        tax: orderDelivery?.tax || '',
                        taxCurrency:
                          (orderDelivery?.taxCurrency as actionTypes.TCurrencyCode) ||
                          settingsState?.settings?.otherSettings?.defaultSystemCurrency ||
                          globalCurrencyFallback,

                        customsCost: orderDelivery?.customsCost || '',
                        customsCostCurrency:
                          (orderDelivery?.customsCostCurrency as actionTypes.TCurrencyCode) ||
                          settingsState?.settings?.otherSettings?.defaultSystemCurrency ||
                          globalCurrencyFallback,

                        remainingCosts: orderDelivery?.remainingCosts || '',
                        remainingCostsCurrency:
                          (orderDelivery?.remainingCostsCurrency as actionTypes.TCurrencyCode) ||
                          settingsState?.settings?.otherSettings?.defaultSystemCurrency ||
                          globalCurrencyFallback,

                        totalDeliveryCost:
                          // This should come from BE
                          orderDelivery?.totalDeliveryCost ||
                          currencyExchanger(
                            Number(convertNumericStringToNumber(orderDelivery?.shipmentCost)) || 0,
                            (orderDelivery?.shipmentCostCurrency as actionTypes.TCurrencyCode) ||
                              settingsState?.settings?.otherSettings?.defaultSystemCurrency ||
                              globalCurrencyFallback,
                            (orderDelivery?.totalDeliveryCostCurrency as actionTypes.TCurrencyCode) ||
                              settingsState?.settings?.otherSettings?.defaultSystemCurrency ||
                              globalCurrencyFallback,
                            exchangeRates
                          ) +
                            currencyExchanger(
                              Number(convertNumericStringToNumber(orderDelivery?.tax)) || 0,
                              (orderDelivery?.taxCurrency as actionTypes.TCurrencyCode) ||
                                settingsState?.settings?.otherSettings?.defaultSystemCurrency ||
                                globalCurrencyFallback,
                              (orderDelivery?.totalDeliveryCostCurrency as actionTypes.TCurrencyCode) ||
                                settingsState?.settings?.otherSettings?.defaultSystemCurrency ||
                                globalCurrencyFallback,
                              exchangeRates
                            ) +
                            currencyExchanger(
                              Number(convertNumericStringToNumber(orderDelivery?.customsCost)) || 0,
                              (orderDelivery?.customsCostCurrency as actionTypes.TCurrencyCode) ||
                                settingsState?.settings?.otherSettings?.defaultSystemCurrency ||
                                globalCurrencyFallback,
                              (orderDelivery?.totalDeliveryCostCurrency as actionTypes.TCurrencyCode) ||
                                settingsState?.settings?.otherSettings?.defaultSystemCurrency ||
                                globalCurrencyFallback,
                              exchangeRates
                            ) +
                            currencyExchanger(
                              Number(convertNumericStringToNumber(orderDelivery?.remainingCosts)) ||
                                0,
                              (orderDelivery?.remainingCostsCurrency as actionTypes.TCurrencyCode) ||
                                settingsState?.settings?.otherSettings?.defaultSystemCurrency ||
                                globalCurrencyFallback,
                              (orderDelivery?.totalDeliveryCostCurrency as actionTypes.TCurrencyCode) ||
                                settingsState?.settings?.otherSettings?.defaultSystemCurrency ||
                                globalCurrencyFallback,
                              exchangeRates
                            ) ||
                          0,
                        totalDeliveryCostCurrency:
                          (orderDelivery?.totalDeliveryCostCurrency as actionTypes.TCurrencyCode) ||
                          settingsState?.settings?.otherSettings?.defaultSystemCurrency ||
                          globalCurrencyFallback,

                        notes: orderDelivery?.notes || '',
                      }
                    }
                  )
                : [],

              newAttachmentFileInput: initialFileInput,
              newAttachmentAltName: '',

              uuid: ordersState.editedOrder?.uuid || '',
            }}
            validationSchema={OrderSchema}
            onSubmit={(values) => {
              if (isOrderEditable) {
                setDidFormValidationOccur(true)
                setIsOneFieldChanged(false)

                dispatch({
                  type: actionTypes.EDIT_ORDER_REQUESTED,
                  payload: {
                    tenant: tenant,
                    token: authState.authData?.token,
                    order: {
                      ...values,
                      orderStatus: Number(values.orderStatus),
                      paymentStatus: Number(values.paymentStatus),
                      bomElementsTotalPrice: Number(
                        convertNumericStringToNumber(values?.bomElementsTotalPrice)
                      ),
                      deliveriesTotalPrice: Number(
                        convertNumericStringToNumber(values.deliveriesTotalPrice)
                      ),

                      orderLines: values?.orderLines?.map((orderLine: actionTypes.TOrderLine) => ({
                        ...orderLine,
                        orderLinePrice:
                          Number(convertNumericStringToNumber(orderLine.orderLinePrice)) || null,

                        orderedQuantity:
                          Number(convertNumericStringToNumber(orderLine.orderedQuantity)) || null,
                      })),

                      orderDeliveries: values?.orderDeliveries?.map(
                        (delivery: actionTypes.TOrderDelivery) => ({
                          ...delivery,

                          shipmentCost:
                            Number(convertNumericStringToNumber(delivery?.shipmentCost)) || null,

                          invoices: delivery?.invoices
                            ?.map((invoice: actionTypes.TDeliveryInvoice) => {
                              return {
                                ...invoice,
                                invoicePrice:
                                  Number(convertNumericStringToNumber(invoice?.invoicePrice)) ||
                                  null,
                              }
                            })
                            .filter(
                              (invoice: actionTypes.TDeliveryInvoice) =>
                                invoice?.invoiceNumber && invoice?.invoicePrice
                            ),

                          tax: Number(convertNumericStringToNumber(delivery?.tax)) || null,
                          customsCost:
                            Number(convertNumericStringToNumber(delivery?.customsCost)) || null,
                          remainingCosts:
                            Number(convertNumericStringToNumber(delivery?.remainingCosts)) || null,
                          orderId: Number(paramsOrderId),
                          status: Number(delivery.status),
                          payment: Number(delivery.payment),

                          deliveryLines: delivery?.deliveryLines?.map(
                            (deliveryLine: actionTypes.TDeliveryLine) => ({
                              ...deliveryLine,
                              orderedQuantity:
                                Number(
                                  convertNumericStringToNumber(deliveryLine.orderedQuantity)
                                ) || null,
                              expectedQuantity:
                                Number(
                                  convertNumericStringToNumber(deliveryLine.expectedQuantity)
                                ) || null,
                              receivedQuantity:
                                Number(
                                  convertNumericStringToNumber(deliveryLine.receivedQuantity)
                                ) || null,
                            })
                          ),
                        })
                      ),

                      ...(ordersState.editedOrder?.id && { id: ordersState.editedOrder?.id }),
                    },
                  },
                })
              }
            }}
            // Run tests if this does not break forms on change events
            // For example if initialValue is an array with or case, then it will always refresh it to initial state without possible onChange
            enableReinitialize={true}
            /* Do not use that as it creates a very big lag while typing in a big dynamic form */
            validateOnBlur={true}
            validateOnChange={false}
          >
            {({ initialValues, values, errors, handleChange, setFieldValue }) => {
              return (
                <Form
                  onChange={() => {
                    // Values here are always 1 step behind
                    let isModified = !deepEqual(values, initialValues, { strict: false })

                    if (isModified) {
                      /* If form is brought to its initial state then it is not modified */
                      setIsOrderModifiedAndUnsaved(true)
                    } else {
                      setIsOrderModifiedAndUnsaved(false)
                    }

                    if (errors.orderedDate || errors.orderDeliveries) {
                      setDidFormValidationOccur(true)
                    }

                    setIsOneFieldChanged(true)
                  }}
                >
                  <FormFixedActionsPanel
                    title={
                      !ordersState.isSingleOrderLoading ? (
                        `Zamówienie nr
                        ${
                          values?.orderNumber ||
                          ordersState?.orders?.find(
                            (order: actionTypes.TLightOrder) => order.id === Number(paramsOrderId)
                          )?.number ||
                          '...'
                        }`
                      ) : (
                        <>
                          Zamówienie nr
                          <ThreeDots />
                        </>
                      )
                    }
                    mode={mode}
                    section="order"
                    isSaving={ordersState.isOrderEditSaving}
                    isSaved={ordersState.isOrderEditSaved}
                    isDeleting={ordersState.isOrderDeleting}
                    isDeleted={ordersState.isOrderDeleted}
                    setDidFormValidationOccur={setDidFormValidationOccur}
                    didFormValidationOccur={didFormValidationOccur}
                    formErrorsBool={false}
                    closeAction={''}
                    deleteAction={actionTypes.DELETE_ORDER_REQUESTED}
                    deletePayload={{
                      tenant: tenant,
                      token: authState.authData?.token,
                      id: ordersState.editedOrder?.id,
                    }}
                    closeFunction={closeOrderDetails}
                    canDelete={!ordersState.isSingleOrderLoading}
                    isEditable={isOrderEditable}
                    disabledDeleteButtonClassNames="delete-details-button"
                    canSave={!ordersState.isSingleOrderLoading}
                    confirmDeleteMessageJSX={
                      <>
                        Czy na pewno chcesz usunąć całe zamówienie?
                        {ordersState?.editedOrder?.orderNumber ? (
                          <>
                            <br />
                            <strong>{ordersState?.editedOrder?.orderNumber}</strong>
                          </>
                        ) : (
                          ''
                        )}
                      </>
                    }
                    orderToPurchasePlanFn={() =>
                      history.push(
                        `/${tenant}/admin/purchase-plan/${
                          ordersState?.editedOrder?.purchasePlanId || 0
                        }/bom-elements`
                      )
                    }
                    generateOrderPDF={() =>
                      dispatch({
                        type: actionTypes.GENERATE_ORDER_PDF_REQUESTED,
                        payload: {
                          tenant: tenant,
                          token: authState.authData?.token,
                          orderPDF: {
                            orderId: Number(paramsOrderId),
                          },
                        },
                      })
                    }
                    isOrderPDFGenerating={ordersState?.isOrderPDFGenerating}
                    isOrderPDFGenerated={ordersState?.isOrderPDFGenerated}
                    isGenerateOrderPDFButtonDisabled={
                      isOrderModifiedAndUnsaved || ordersState?.isGenerateOrderPDFButtonLocked
                    }
                  />

                  {ordersState?.isOrderPDFGenerated &&
                    ordersState?.generatedOrderPDFInfo &&
                    confirmAlert({
                      closeOnEscape: true,
                      customUI: ({ onClose }) => {
                        return (
                          <ConfirmActionModal
                            mode="generateOrderPDF"
                            onClose={() => {
                              dispatch({ type: actionTypes.UNLOCK_GENERATE_ORDER_PDF_BUTTON })
                              onClose()
                            }}
                            confirmMessageJSX={<>Czy chcesz pobrać wygenerowany PDF?</>}
                            onConfirmFunction={() => {
                              dispatch({
                                type: actionTypes.FETCH_SINGLE_ATTACHMENT_REQUESTED,
                                payload: {
                                  tenant: tenant,
                                  token: authState.authData?.token,
                                  id: ordersState?.generatedOrderPDFInfo?.id,
                                  attachmentType: ordersState?.generatedOrderPDFInfo?.type,
                                  fileName: ordersState?.generatedOrderPDFInfo?.name,
                                  download: true,
                                },
                              })
                            }}
                          />
                        )
                      },
                    })}

                  {ordersState.isSingleOrderLoading ? (
                    <div
                      style={{
                        height: '400px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Spinner />
                    </div>
                  ) : (
                    <>
                      {/*
                       * Display Network Error Message
                       */}

                      {ordersState.singleOrderFetchError && (
                        <CustomErrorMessage
                          wrapperClassNames="mb-4"
                          messageClassNames="text-center"
                          customErrorMessageText={getErrorMessageFromStatus(
                            'fetch',
                            ordersState.singleOrderFetchError.status,
                            'zamówienia'
                          )}
                        />
                      )}

                      {!isOneFieldChanged && ordersState?.orderEditSaveError && (
                        <CustomErrorMessage
                          wrapperClassNames="mb-4"
                          messageClassNames="text-center"
                          customErrorMessageText={getErrorMessageFromStatus(
                            'edit',
                            ordersState?.orderEditSaveError?.status,
                            'zamówienia'
                          )}
                        />
                      )}

                      {ordersState?.orderDeleteError && (
                        <CustomErrorMessage
                          wrapperClassNames="mb-4"
                          messageClassNames="text-center"
                          customErrorMessageText={getErrorMessageFromStatus(
                            'delete',
                            ordersState?.orderDeleteError?.status,
                            'zamówienia'
                          )}
                        />
                      )}

                      {ordersState?.generateOrderPDFError && (
                        <CustomErrorMessage
                          wrapperClassNames="mb-4"
                          messageClassNames="text-center"
                          customErrorMessageText={getErrorMessageFromStatus(
                            'generatePDF',
                            ordersState.generateOrderPDFError.status,
                            'danych do wygenerowania PDF'
                          )}
                        />
                      )}

                      {/*
                       * Form Tabs
                       */}

                      <CTabs activeTab={paramsOrderTab}>
                        <CNav variant="tabs" className="mb-3">
                          <CNavItem
                            key={'general-information'}
                            onClick={() => {
                              history.replace(
                                `/${tenant}/admin/order/${paramsOrderId}/general-information`
                              )
                            }}
                          >
                            <CNavLink data-tab={'general-information'}>Informacje ogólne</CNavLink>
                          </CNavItem>
                          {values.orderDeliveries?.map(
                            (_delivery: actionTypes.TOrderDelivery, deliveryIndex: number) => (
                              <CNavItem
                                key={`delivery-${deliveryIndex + 1}`}
                                onClick={() => {
                                  history.replace(
                                    `/${tenant}/admin/order/${paramsOrderId}/delivery-${
                                      deliveryIndex + 1
                                    }`
                                  )
                                }}
                              >
                                <CNavLink
                                  className={`${
                                    errors?.orderDeliveries &&
                                    errors?.orderDeliveries[deliveryIndex]
                                      ? 'text-danger is-invalid'
                                      : ''
                                  }`}
                                  data-tab={`delivery-${deliveryIndex + 1}`}
                                >
                                  {`Dostawa ${deliveryIndex + 1}`}
                                </CNavLink>
                              </CNavItem>
                            )
                          )}
                          <CNavItem
                            key={'attachments'}
                            onClick={() => {
                              history.replace(`/${tenant}/admin/order/${paramsOrderId}/attachments`)
                            }}
                          >
                            <CNavLink data-tab={'attachments'}>Załączniki</CNavLink>
                          </CNavItem>
                        </CNav>

                        <CTabContent className="position-relative" fade={true}>
                          <CTabPane data-tab="general-information">
                            <div className="order-grid-fields">
                              <div>
                                <CLabel
                                  htmlFor="order-status"
                                  className={`${inputLabelSpacingBottom}`}
                                >
                                  Status
                                </CLabel>
                                <InputGroup
                                  id="order-status"
                                  className={`${inputFieldSpacingBottom} dropdown-selector`}
                                >
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText
                                      className={
                                        errors?.orderStatus ? 'text-danger input-error-icon' : ''
                                      }
                                    >
                                      <i className="cil-bell"></i>
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <ErrorMessage
                                    name="orderStatus"
                                    component="span"
                                    className="text-danger input-error-message"
                                  />

                                  <FastField
                                    as={Select}
                                    variant="outlined"
                                    native
                                    classes={{
                                      root: materialUISelectClasses.root,
                                    }}
                                    name="orderStatus"
                                  >
                                    <option value={1}>Niewysłane</option>
                                    <option value={2}>Wysłane</option>
                                    <option value={3}>Wysłane częściowo</option>
                                    <option value={4}>Dostarczone</option>
                                    <option value={5}>Dostarczone częściowo</option>
                                    <option value={6}>Niedostarczone</option>
                                    <option value={7}>Anulowane</option>
                                  </FastField>
                                </InputGroup>
                              </div>

                              <div>
                                <CLabel
                                  htmlFor="order-payment-status"
                                  className={`${inputLabelSpacingBottom}`}
                                >
                                  Płatność
                                </CLabel>
                                <InputGroup
                                  id="order-payment-status"
                                  className={`${inputFieldSpacingBottom} dropdown-selector`}
                                >
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText
                                      className={
                                        errors?.paymentStatus ? 'text-danger input-error-icon' : ''
                                      }
                                    >
                                      <i className="cil-cash"></i>
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <ErrorMessage
                                    name="paymentStatus"
                                    component="span"
                                    className="text-danger input-error-message"
                                  />

                                  <Field
                                    as={Select}
                                    variant="outlined"
                                    native
                                    classes={{
                                      root: materialUISelectClasses.root,
                                    }}
                                    name="paymentStatus"
                                  >
                                    <option value={1}>Nieopłacone</option>
                                    <option value={2}>Częściowo opłacone</option>
                                    <option value={3}>Opłacone</option>
                                  </Field>
                                </InputGroup>
                              </div>

                              <BasicFormField
                                fieldId="date-of-order"
                                fieldLabel="Data złożenia zamówienia"
                                fieldError={errors.orderedDate}
                                formikFieldName="orderedDate"
                                fieldValue={values.orderedDate}
                                fieldIcon="cil-calendar"
                                fieldType="date"
                              />
                            </div>

                            <div className="order-grid-fields order-grid-fields--general-second-row">
                              <BasicFormField
                                fieldId="order-number"
                                fieldLabel="Numer wewnętrzny"
                                formikFieldName="orderNumber"
                                fieldValue={values?.orderNumber}
                                fieldError={errors.orderNumber}
                                fieldIcon="cil-barcode"
                                fieldType="text"
                                isDisabled
                              />

                              <BasicFormField
                                fieldId="custom-order-number"
                                fieldLabel="Zewnętrzny numer"
                                formikFieldName="customOrderNumber"
                                fieldValue={values.customOrderNumber || ''}
                                fieldError={null}
                                fieldIcon="cil-barcode"
                                fieldType="text"
                              />

                              <BasicFormField
                                fieldId="order-bom-names"
                                fieldLabel="Powiązane BOMy"
                                formikFieldName="bomNames"
                                fieldValue={values.bomNames}
                                fieldError={errors.bomNames}
                                fieldIcon="cil-short-text"
                                fieldType="text"
                                isDisabled
                              />
                            </div>

                            <div className="order-grid-fields order-grid-fields--general-third-row">
                              <BasicFormField
                                fieldId="order-elements-cost"
                                fieldLabel="Wartość elementów"
                                formikFieldName="bomElementsTotalPrice"
                                fieldValue={
                                  values.orderLines.reduce(
                                    (accumulator: number, orderLine: actionTypes.TOrderLine) =>
                                      accumulator +
                                      (orderLine?.orderLinePrice
                                        ? currencyExchanger(
                                            Number(
                                              convertNumericStringToNumber(orderLine.orderLinePrice)
                                            ),
                                            orderLine.orderLinePriceCurrency
                                              ? (orderLine.orderLinePriceCurrency as actionTypes.TCurrencyCode)
                                              : settingsState?.settings?.otherSettings
                                                  ?.defaultSystemCurrency || globalCurrencyFallback,
                                            values.bomElementsTotalPriceCurrency,
                                            exchangeRates
                                          )
                                        : 0),
                                    0
                                  ) || 0
                                }
                                fieldError={errors.bomElementsTotalPrice}
                                fieldIcon="cil-cash"
                                fieldType="number"
                                fieldCurrencyName="bomElementsTotalPriceCurrency"
                                fieldCurrencyValue={values.bomElementsTotalPriceCurrency}
                                isDisabled
                                handleChange={handleChange}
                              />

                              <BasicFormField
                                fieldId="order-tooling-cost"
                                fieldLabel="Koszt toolingu"
                                formikFieldName="toolingTotalPrice"
                                fieldValue={Number(
                                  convertNumericStringToNumber(values.toolingTotalPrice)
                                )}
                                fieldError={errors.toolingTotalPrice}
                                fieldIcon="cil-cash"
                                fieldType="number"
                                fieldCurrencyName="toolingTotalPriceCurrency"
                                fieldCurrencyValue={values.toolingTotalPriceCurrency}
                                handleChange={handleChange}
                                isDisabled
                              />

                              <BasicFormField
                                fieldId="order-services-cost"
                                fieldLabel="Koszt usług"
                                formikFieldName="servicesTotalPrice"
                                fieldValue={Number(
                                  convertNumericStringToNumber(values.servicesTotalPrice)
                                )}
                                fieldError={errors.servicesTotalPrice}
                                fieldIcon="cil-cash"
                                fieldType="number"
                                fieldCurrencyName="servicesTotalPriceCurrency"
                                fieldCurrencyValue={values.servicesTotalPriceCurrency}
                                handleChange={handleChange}
                                isDisabled
                              />

                              <BasicFormField
                                fieldId="order-deliveries-total-cost"
                                fieldLabel="Całkowity koszt dostaw"
                                formikFieldName="deliveriesTotalPrice"
                                fieldValue={
                                  initialValues?.orderDeliveries?.length ||
                                  values?.orderDeliveries?.length
                                    ? values?.orderDeliveries?.reduce(
                                        (
                                          accumulator: number,
                                          delivery: actionTypes.TOrderDelivery,
                                          index: number
                                        ) => {
                                          // This is a hack, otherwise the field will not update
                                          const singleDeliveryTotalCostList: NodeListOf<HTMLElement> =
                                            window.document.getElementsByName(
                                              `orderDeliveries.${index}.totalDeliveryCost`
                                            )

                                          const singleDeliveryTotalCostInputs =
                                            singleDeliveryTotalCostList as NodeListOf<HTMLInputElement>

                                          if (singleDeliveryTotalCostInputs.length) {
                                            return (
                                              accumulator +
                                              Number(
                                                currencyExchanger(
                                                  Number(
                                                    convertNumericStringToNumber(
                                                      singleDeliveryTotalCostInputs[0]?.value
                                                    )
                                                  ),
                                                  delivery.totalDeliveryCostCurrency as actionTypes.TCurrencyCode,
                                                  values.deliveriesTotalPriceCurrency as actionTypes.TCurrencyCode,
                                                  exchangeRates
                                                )
                                              )
                                            )
                                          } else {
                                            return accumulator
                                          }
                                        },
                                        0
                                      ) || ''
                                    : ''
                                }
                                fieldError={null}
                                fieldIcon="cil-truck"
                                fieldType="number"
                                placeholder="Brak"
                                fieldCurrencyName="deliveriesTotalPriceCurrency"
                                fieldCurrencyValue={values.deliveriesTotalPriceCurrency}
                                handleChange={handleChange}
                                isDisabled
                              />
                            </div>

                            <h5 className="my-2" style={{ fontWeight: 400, fontSize: '0.95rem' }}>
                              Linie
                            </h5>
                            <Table
                              bordered
                              striped
                              className="item-inner-details-table mb-3"
                              id="order-all-lines-table"
                            >
                              <thead>
                                <tr>
                                  <th style={{ width: '40px', textAlign: 'center' }}>#</th>
                                  <th style={{ width: '1fr' }}>Element BOM</th>
                                  <th style={{ width: '160px' }}>Cena</th>
                                  <th style={{ width: '140px' }}>Ilość planowana</th>
                                  <th style={{ width: '170px' }}>Ilość na zamówieniu</th>
                                  <th style={{ width: '140px' }}>Ilość otrzymana</th>
                                </tr>
                              </thead>

                              {ordersState?.editedOrder &&
                              ordersState?.editedOrder?.orderLines &&
                              ordersState?.editedOrder?.orderLines?.length > 0 ? (
                                <tbody>
                                  {values?.orderLines?.map(
                                    (orderLine: actionTypes.TOrderLine, indexOfLine: number) => (
                                      <tr key={`line-in-order-${orderLine.id}`}>
                                        <td style={{ textAlign: 'center' }}>{indexOfLine + 1}.</td>

                                        <td>{orderLine?.bomElementName || ''}</td>

                                        <td className="text-right">
                                          {convertNumberToNumericString(orderLine?.orderLinePrice) +
                                            ' ' +
                                            (orderLine?.orderLinePriceCurrency || '')}
                                        </td>

                                        <td className="text-right">
                                          {orderLine?.plannedQuantity !== null
                                            ? convertQuantityToNumericString(
                                                orderLine?.plannedQuantity
                                              )?.concat(' szt.')
                                            : ''}
                                        </td>

                                        <td>
                                          <InputGroup
                                            id={`inputgroup-order-lines-${indexOfLine}-ordered-quantity`}
                                          >
                                            <ErrorMessage
                                              name={`orderLines.${indexOfLine}.orderedQuantity`}
                                              component="span"
                                              className="text-danger input-error-message"
                                            />

                                            <FastField
                                              as={NumberFormat}
                                              displayType="input"
                                              thousandSeparator={globalThousandSeparator}
                                              decimalSeparator={globalDecimalSeparator}
                                              decimalScale={0}
                                              fixedDecimalScale={false}
                                              allowNegative={false}
                                              allowLeadingZeros={true}
                                              name={`orderLines.${indexOfLine}.orderedQuantity`}
                                              placeholder="Na zamówieniu"
                                              value={orderLine?.orderedQuantity}
                                              onChange={handleChange}
                                              className="form-control text-right"
                                            />

                                            <InputGroupAddon addonType="append">
                                              <InputGroupText
                                                className="disabled-currency"
                                                style={{
                                                  width: '45px',
                                                  border: '1px solid #d8dbe0',
                                                  borderLeft: 'none',
                                                }}
                                              >
                                                szt.
                                              </InputGroupText>
                                            </InputGroupAddon>
                                          </InputGroup>
                                        </td>

                                        <td className="text-right">
                                          {orderLine?.receivedQuantity !== null
                                            ? convertQuantityToNumericString(
                                                orderLine?.receivedQuantity
                                              )?.concat(' szt.')
                                            : ''}
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              ) : (
                                <caption className="item-inner-details-table-caption">
                                  {ordersState?.fetchOrdersError
                                    ? 'Wystąpił błąd podczas pobierania linii!'
                                    : 'To zamówienie nie posiada linii!'}
                                </caption>
                              )}
                            </Table>

                            {/*
                             * Order Implementation Costs
                             */}

                            {values?.orderImplementationCosts?.length > 0 && (
                              <>
                                <h5
                                  className="my-2 pt-1"
                                  style={{ fontWeight: 400, fontSize: '0.95rem' }}
                                >
                                  Tooling
                                </h5>
                                <Table
                                  bordered
                                  striped
                                  className="item-inner-details-table mb-3"
                                  id="order-implementation-costs-table"
                                >
                                  <thead>
                                    <tr>
                                      <th style={{ width: '40px', textAlign: 'center' }}>#</th>
                                      <th style={{ width: '1fr' }}>Tooling</th>
                                      <th style={{ width: '160px' }}>Cena</th>
                                      <th style={{ width: '140px' }}>Ilość planowana</th>
                                      <th style={{ width: '170px' }}>Ilość na zamówieniu</th>
                                    </tr>
                                  </thead>

                                  {ordersState?.editedOrder &&
                                  ordersState?.editedOrder?.orderImplementationCosts &&
                                  ordersState?.editedOrder?.orderImplementationCosts?.length > 0 ? (
                                    <tbody>
                                      {values?.orderImplementationCosts?.map(
                                        (
                                          orderImplementationCost: actionTypes.TOrderImplementationCost,
                                          indexOfLine: number
                                        ) => (
                                          <tr
                                            key={`implementation-cost-in-order-${orderImplementationCost.id}`}
                                          >
                                            <td style={{ textAlign: 'center' }}>
                                              {indexOfLine + 1}.
                                            </td>

                                            <td>
                                              {orderImplementationCost.implementationCostId || ''}
                                            </td>

                                            <td className="text-right">
                                              {convertNumberToNumericString(
                                                orderImplementationCost?.price
                                              ) +
                                                ' ' +
                                                orderImplementationCost.priceCurrency}
                                            </td>

                                            <td className="text-right">
                                              {orderImplementationCost?.plannedQuantity
                                                ? convertQuantityToNumericString(
                                                    orderImplementationCost?.plannedQuantity
                                                  )?.concat(' szt.')
                                                : ''}
                                            </td>

                                            <td className="text-right">
                                              {orderImplementationCost?.orderedQuantity
                                                ? convertQuantityToNumericString(
                                                    orderImplementationCost?.orderedQuantity
                                                  )?.concat(' szt.')
                                                : ''}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  ) : (
                                    <caption className="item-inner-details-table-caption">
                                      {ordersState?.fetchOrdersError
                                        ? 'Wystąpił błąd podczas pobierania toolingu!'
                                        : 'To zamówienie nie posiada toolingu!'}
                                    </caption>
                                  )}
                                </Table>
                              </>
                            )}

                            {/*
                             * Order Services
                             */}

                            {values?.orderServices?.length > 0 && (
                              <>
                                <h5
                                  className="my-2 pt-1"
                                  style={{ fontWeight: 400, fontSize: '0.95rem' }}
                                >
                                  Usługi
                                </h5>
                                <Table
                                  bordered
                                  striped
                                  className="item-inner-details-table mb-3"
                                  id="order-services-table"
                                >
                                  <thead>
                                    <tr>
                                      <th style={{ width: '40px', textAlign: 'center' }}>#</th>
                                      <th style={{ width: '1fr' }}>Usługa</th>
                                      <th style={{ width: '160px' }}>Cena</th>
                                      <th style={{ width: '140px' }}>Ilość planowana</th>
                                      <th style={{ width: '170px' }}>Ilość na zamówieniu</th>
                                      <th style={{ width: '170px' }}>Ilość zrealizowana</th>
                                    </tr>
                                  </thead>

                                  {ordersState?.editedOrder &&
                                  ordersState?.editedOrder?.orderServices &&
                                  ordersState?.editedOrder?.orderServices?.length > 0 ? (
                                    <tbody>
                                      {values?.orderServices?.map(
                                        (
                                          orderService: actionTypes.TOrderService,
                                          indexOfLine: number
                                        ) => (
                                          <tr key={`service-in-order-${orderService.id}`}>
                                            <td style={{ textAlign: 'center' }}>
                                              {indexOfLine + 1}.
                                            </td>

                                            <td>{orderService.serviceId || ''}</td>

                                            <td className="text-right">
                                              {convertNumberToNumericString(orderService?.price) +
                                                ' ' +
                                                orderService.priceCurrency}
                                            </td>

                                            <td className="text-right">
                                              {orderService?.plannedQuantity
                                                ? convertQuantityToNumericString(
                                                    orderService?.plannedQuantity
                                                  )?.concat(' szt.')
                                                : ''}
                                            </td>

                                            <td className="text-right">
                                              {orderService?.orderedQuantity
                                                ? convertQuantityToNumericString(
                                                    orderService?.orderedQuantity
                                                  )?.concat(' szt.')
                                                : ''}
                                            </td>

                                            <td className="text-right">
                                              {orderService?.completedQuantity
                                                ? convertQuantityToNumericString(
                                                    orderService?.completedQuantity
                                                  )?.concat(' szt.')
                                                : ''}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  ) : (
                                    <caption className="item-inner-details-table-caption">
                                      {ordersState?.fetchOrdersError
                                        ? 'Wystąpił błąd podczas pobierania usług!'
                                        : 'To zamówienie nie posiada usług!'}
                                    </caption>
                                  )}
                                </Table>
                              </>
                            )}
                          </CTabPane>

                          {/*
                           * Deliveries Tabs
                           */}

                          <OrderDeliveries
                            values={values}
                            errors={errors}
                            handleChange={handleChange}
                            exchangeRates={exchangeRates}
                          />

                          {/*
                           * Attachments Tab
                           */}

                          <CTabPane
                            data-tab="attachments"
                            style={{ margin: '24px auto 0', maxWidth: '850px' }}
                          >
                            <AttachmentsUploadPanel
                              attachments={ordersState.editedOrderAttachments}
                              fetchAttachmentsError={ordersState.fetchOrderAttachmentsError}
                              isAttachmentUploading={ordersState.isOrderAttachmentUploading}
                              isAttachmentUploaded={ordersState.isOrderAttachmentUploaded}
                              attachmentUploadError={ordersState.orderAttachmentUploadingError}
                              newAttachmentFileInput={values.newAttachmentFileInput}
                              newAttachmentAltNameError={errors.newAttachmentAltName}
                              dispatchAttachmentUpload={(encodedFile) => {
                                dispatch({
                                  type: actionTypes.UPLOAD_ORDER_ATTACHMENT_REQUESTED,
                                  payload: {
                                    tenant: tenant,
                                    token: authState.authData?.token,
                                    uploadAttachment: {
                                      fileAttachmentTypeId: 4,
                                      entityUuid: values.uuid,
                                      entityId: paramsOrderId ? Number(paramsOrderId) : null,

                                      type: getUploadedFileType(
                                        values.newAttachmentFileInput?.type,
                                        values.newAttachmentFileInput?.name
                                      ),
                                      name: values.newAttachmentFileInput?.name,
                                      base64EncodedFile: encodedFile,
                                      altName: values?.newAttachmentAltName || null,
                                    },
                                  },
                                })
                              }}
                              dispatchModalActionsUnlock={() =>
                                dispatch({ type: actionTypes.ORDER_ACTIONS_UNLOCK })
                              }
                              dispatchSingleAttachmentFetch={(
                                attachmentId,
                                attachmentType,
                                fileName,
                                download
                              ) =>
                                dispatch({
                                  type: actionTypes.FETCH_SINGLE_ATTACHMENT_REQUESTED,
                                  payload: {
                                    tenant: tenant,
                                    token: authState.authData?.token,
                                    id: attachmentId,
                                    attachmentType: attachmentType,
                                    fileName: fileName,
                                    download: download,
                                  },
                                })
                              }
                              dispatchSingleAttachmentDelete={(attachmentId) =>
                                dispatch({
                                  type: actionTypes.DELETE_SINGLE_ATTACHMENT_REQUESTED,
                                  payload: {
                                    tenant: tenant,
                                    token: authState.authData?.token,
                                    id: attachmentId,
                                  },
                                  section: 'orders',
                                })
                              }
                              isUploadDisabled={
                                ordersState.isOrderEditSaving ||
                                ordersState.isOrderDeleting ||
                                ordersState.isOrderEditSaved ||
                                ordersState.isOrderDeleted ||
                                ordersState.isOrderAttachmentUploading ||
                                !values.newAttachmentFileInput
                              }
                              isEditable={isOrderEditable}
                              setFieldValue={setFieldValue}
                              noAttachmentsTextFirstWords="To zamówienie"
                            />
                          </CTabPane>
                        </CTabContent>
                      </CTabs>
                    </>
                  )}
                </Form>
              )
            }}
          </Formik>
        </CCardBody>
      </CCard>
    </Container>
  )
}
