import React, { useState, useEffect, useCallback, ChangeEvent, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { Container, InputGroup, InputGroupAddon, InputGroupText, Spinner } from 'reactstrap'
import { Formik, Form, ErrorMessage, Field, FastField } from 'formik'
import * as Yup from 'yup'
import { CCard, CCardBody, CButton, CDataTable, CLabel } from '@coreui/react'
import { Select } from '@material-ui/core'
import NumberFormat from 'react-number-format'

import * as actionTypes from '../../../store/action-types'
import { TRootState } from '../../../store/reducers'
import {
  BasicFormField,
  CustomErrorMessage,
  ThreeDots,
  FormActionCancelButton,
} from '../../../components'
import {
  getErrorMessageFromStatus,
  globalThousandSeparator,
  globalDecimalSeparator,
  inputLabelSpacingBottom,
  inputFieldSpacingBottom,
  toDateInputValue,
} from '../../../utils'

export const FinishedProductsReplenishment: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tenant, id } = useParams<{
    tenant: string
    id: string
  }>()
  const paramsManagedWarehouseId = id

  const [didFormValidationOccur, setDidFormValidationOccur] = useState(false)
  const [
    isFinishedProductsReplenishmentModifiedAndUnsaved,
    setIsFinishedProductsReplenishmentModifiedAndUnsaved,
  ] = useState(false)

  const authState = useSelector((state: TRootState) => state.auth)
  const warehousesState = useSelector((state: TRootState) => state.warehouses)

  const [materialsReplenishmentFilterState, setFinishedProductsReplenishmentFilterState] =
    useState<actionTypes.TFilterState>(undefined)

  const [materialsReplenishmentSorterState, setFinishedProductsReplenishmentSorterState] =
    useState<actionTypes.TSorterState>({ column: '', asc: true })

  const closeFinishedProductsReplenishment = useCallback(() => {
    history.push(`/${tenant}/admin/warehouse-management/${paramsManagedWarehouseId}`)
  }, [dispatch, history, tenant, paramsManagedWarehouseId])

  const generateFinishedProductsListFromCommission = (finishedProductsInCommission: any[]) =>
    finishedProductsInCommission?.map((finishedProduct: any, finishedProductIndex: number) => ({
      ...finishedProduct,
      position: finishedProductIndex + 1,
    }))

  const isWarehouseEditable = authState?.authData?.roles?.includes('Warehouses_write')

  const emptyCommissionItemsInForm = []
  const selectCommissionPlaceholderValue = ''

  const managedWarehouseTypes: actionTypes.TWarehouseType[] | undefined = useMemo(
    () =>
      warehousesState?.warehouses?.find(
        (warehouse: actionTypes.TWarehouseLight) =>
          warehouse.id === Number(paramsManagedWarehouseId)
      )?.types,
    [warehousesState.warehouses, paramsManagedWarehouseId]
  )

  useEffect(() => {
    if (!managedWarehouseTypes?.includes(actionTypes.finishedProductsTypeNumber)) {
      closeFinishedProductsReplenishment()
    }
  }, [managedWarehouseTypes, history])

  // Unmount Component
  useEffect(() => {
    return () => {
      dispatch({
        type: actionTypes.CLOSE_FINISHED_PRODUCTS_REPLENISHMENT,
      })
    }
  }, [])

  const FinishedProductsReplenishmentSchema = Yup.object().shape({
    deliveryDocument: Yup.string().required('To pole jest wymagane!'),
    selectedCommission: Yup.string().required('Wybierz zlecenie!'),
  })

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center">
      <CCard>
        <CCardBody className="warehouse-details">
          <h4 className="text-center mb-4">
            {authState?.authData?.roles?.includes('Warehouses_write')
              ? 'Przyjęcie wyrobów gotowych'
              : ''}
          </h4>

          <div className="d-flex justify-content-end list-fixed-create-new-button-wrapper">
            <FormActionCancelButton
              closeFunction={closeFinishedProductsReplenishment}
              closeAction={''}
            />
          </div>
          {warehousesState.areManagedWarehouseDeliveriesLoading ? (
            <div
              style={{
                height: '300px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Spinner />
            </div>
          ) : (
            <Formik
              initialValues={{
                deliveryDate: toDateInputValue(new Date().toISOString()),
                deliveryDocument: '',
                selectedCommission: '',
                commissionItems: emptyCommissionItemsInForm,
              }}
              validationSchema={FinishedProductsReplenishmentSchema}
              onSubmit={(values) => {
                if (isWarehouseEditable) {
                  setDidFormValidationOccur(true)
                  setIsFinishedProductsReplenishmentModifiedAndUnsaved(false)

                  const replenishmentDetails: any[] | undefined = values?.commissionItems?.map(
                    (replenishmentItem: any) => {
                      return {
                        commissionLineId: null,
                        quantity: Number(replenishmentItem?.receivedQuantity) || null,
                      }
                    }
                  )

                  const selectedCommission: any = values?.selectedCommission
                    ? JSON.parse(values?.selectedCommission)
                    : undefined

                  dispatch({
                    type: actionTypes.GENERATE_FINISHED_PRODUCTS_REPLENISHMENT_DOCUMENT_REQUESTED,
                    payload: {
                      tenant: tenant,
                      token: authState.authData?.token,
                      documentData: {
                        warehouseId: Number(paramsManagedWarehouseId),
                        date: values?.deliveryDate || '',
                        referenceNumber: selectedCommission?.orderNumber || '',
                        replenishmentDetails: replenishmentDetails,
                        orderCommissionId: selectedCommission?.id || 0,
                      },
                    },
                  })
                }
              }}
              enableReinitialize={false}
              validateOnBlur={false}
              validateOnChange={didFormValidationOccur}
            >
              {({ values, errors, setFieldValue, handleChange }) => (
                <Form
                  onChange={() => {
                    setIsFinishedProductsReplenishmentModifiedAndUnsaved(true)
                  }}
                >
                  {/*
                   * Display Network Error Message
                   */}

                  {!isFinishedProductsReplenishmentModifiedAndUnsaved &&
                    warehousesState?.materialsReplenishmentDocumentGenerateError && (
                      <CustomErrorMessage
                        wrapperClassNames="mb-4"
                        customErrorMessageText={getErrorMessageFromStatus(
                          'create',
                          warehousesState?.materialsReplenishmentDocumentGenerateError?.status,
                          'danych przyjęcia materiałów'
                        )}
                      />
                    )}

                  <div className="settings-view-double-grid-fields">
                    <BasicFormField
                      fieldId="warehouse-management-finished-products-replenishment-delivery-date"
                      fieldLabel="Data dostawy"
                      fieldIcon="cil-calendar"
                      formikFieldName="deliveryDate"
                      fieldValue={values.deliveryDate}
                      fieldError={errors.deliveryDate}
                      fieldType="date"
                      placeholder=""
                    />

                    <BasicFormField
                      fieldId="warehouse-management-finished-products-replenishment-delivery-document"
                      fieldLabel="Dokument przyjęcia"
                      fieldIcon="cil-short-text"
                      formikFieldName="deliveryDocument"
                      fieldValue={values.deliveryDocument}
                      fieldError={errors.deliveryDocument}
                      fieldType="text"
                      placeholder="Wpisz numer PW"
                    />
                  </div>

                  <div className="mb-4">
                    <CLabel
                      htmlFor="warehouse-management-finished-products-replenishment-delivery-id"
                      className={inputLabelSpacingBottom}
                    >
                      Zlecenie produkcyjne
                    </CLabel>
                    <InputGroup
                      id="warehouse-management-finished-products-replenishment-delivery-id"
                      className={`${inputFieldSpacingBottom} dropdown-selector`}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText
                          className={errors.selectedCommission && 'text-danger input-error-icon'}
                        >
                          <i className="cil-truck"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <ErrorMessage
                        name="selectedCommission"
                        component="span"
                        className="text-danger input-error-message"
                      />
                      <Field
                        as={Select}
                        variant="outlined"
                        native
                        className={`item-selector element-selector w-100 text-center ${
                          String(values.selectedCommission) === selectCommissionPlaceholderValue &&
                          'element-selector-placeholder'
                        } ${errors.selectedCommission && 'invalid-native-selector'}`}
                        name="selectedCommission"
                        onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                          // const selectElement: HTMLSelectElement = event.currentTarget

                          // if (selectElement?.value) {
                          //   const selectedCommission: actionTypes.IWarehouseManagementCommission =
                          //     JSON.parse(selectElement?.value)

                          //   if (selectedCommission) {
                          //     const finishedProductsInCommission = generateFinishedProductsListFromCommission(
                          //       selectedCommission?.finishedProducts
                          //     )

                          //     setFieldValue('commissionItems', finishedProductsInCommission)
                          //   }
                          // } else {
                          //   setFieldValue('commissionItems', emptyCommissionItemsInForm)
                          // }

                          return handleChange(event)
                        }}
                        disabled={
                          !warehousesState?.managedWarehouseFinishedProductsProductionComissions
                        }
                      >
                        <option
                          className="select-option-placeholder text-center"
                          value={selectCommissionPlaceholderValue}
                          disabled={false}
                        >
                          {warehousesState?.managedWarehouseFinishedProductsProductionComissions
                            ? 'Wybierz zlecenie'
                            : 'Brak zleceń do wyboru!'}
                        </option>
                        {warehousesState?.managedWarehouseFinishedProductsProductionComissions?.map(
                          (productionCommission: any) => {
                            return (
                              <option
                                key={`warehouse-finished-product-production-commission-option-${productionCommission.id}`}
                                value={JSON.stringify(productionCommission)}
                                className="text-center"
                              >
                                Zlecenie: {productionCommission?.commissionNumber || 'brak nazwy'}
                              </option>
                            )
                          }
                        )}
                      </Field>
                    </InputGroup>
                  </div>

                  <CDataTable
                    tableFilterValue={materialsReplenishmentFilterState}
                    sorterValue={materialsReplenishmentSorterState}
                    onSorterValueChange={(sorterState: actionTypes.TSorterState) => {
                      setFinishedProductsReplenishmentSorterState(sorterState)
                    }}
                    onTableFilterChange={(tableFilterState: string) => {
                      setFinishedProductsReplenishmentFilterState(tableFilterState)
                    }}
                    loading={warehousesState?.areManagedWarehouseDeliveriesLoading}
                    striped={!!warehousesState?.managedWarehouseDeliveries?.length}
                    border
                    sorter
                    tableFilter={{ label: ' ', placeholder: 'Szukaj w tabeli...' }}
                    addTableClasses="vertical-middle-list-table warehouse-replenishment-table"
                    cleaner
                    onRowClick={undefined}
                    fields={[
                      {
                        key: 'position',
                        label: '#',
                        _style: { width: '45px', textAlign: 'center' },
                      },
                      {
                        key: 'finishedProductName',
                        label: 'Nazwa produktu',
                        _style: { width: '250px' },
                      },
                      {
                        key: 'receivedQuantity',
                        label: 'Ilość',
                        _style: { width: '120px', textAlign: 'right' },
                      },
                    ]}
                    items={values?.commissionItems || undefined}
                    noItemsViewSlot={
                      <div className="no-items-in-table">
                        {values?.commissionItems && values?.selectedCommission
                          ? 'Brak produktów w zleceniu!'
                          : 'Nie wybrano zlecenia!'}
                      </div>
                    }
                    scopedSlots={{
                      receivedQuantity: (
                        itemFromCommission: any,
                        indexOfItemFromCommission: number
                      ) => {
                        const indexOfStabilizedItem = values?.commissionItems?.findIndex(
                          (commissionItem: any) =>
                            commissionItem.bomElementId === itemFromCommission.bomElementId
                        )

                        return (
                          <td>
                            <InputGroup
                              id={`${
                                values?.selectedCommission
                                  ? JSON.parse(values?.selectedCommission)?.id
                                  : 'empty'
                              }-${
                                itemFromCommission.bomElementId
                              }-finished-products-replenishment-delivery-item-group-id`}
                              style={{ pointerEvents: 'all' }}
                            >
                              <ErrorMessage
                                name={`commissionItems.${indexOfStabilizedItem}.receivedQuantity`}
                                component="span"
                                className="text-danger input-error-message"
                              />
                              <FastField
                                as={NumberFormat}
                                displayType="input"
                                thousandSeparator={globalThousandSeparator}
                                decimalSeparator={globalDecimalSeparator}
                                decimalScale={0}
                                fixedDecimalScale={false}
                                allowNegative={false}
                                allowLeadingZeros={true}
                                placeholder={''}
                                name={`commissionItems.${indexOfStabilizedItem}.receivedQuantity`}
                                value={itemFromCommission.receivedQuantity || ''}
                                className="form-control text-right"
                                // Might be useful:
                                required
                              />
                            </InputGroup>
                          </td>
                        )
                      },
                    }}
                  />

                  <CButton
                    color="success"
                    className="save-button w-100 mt-3 mb-2"
                    type="submit"
                    onClick={() => {
                      setDidFormValidationOccur && setDidFormValidationOccur(true)
                    }}
                    disabled={
                      warehousesState.isFinishedProductsReplenishmentDocumentGenerating ||
                      (didFormValidationOccur
                        ? Boolean(errors.commissionItems && errors.deliveryDocument)
                        : false)
                    }
                  >
                    {warehousesState?.isFinishedProductsReplenishmentDocumentGenerating ? (
                      <>
                        Generowanie PW
                        <ThreeDots />
                      </>
                    ) : warehousesState?.isFinishedProductsReplenishmentDocumentGenerated ? (
                      <>Wygenerowano PW!</>
                    ) : (
                      'Generuj PW'
                    )}
                  </CButton>
                </Form>
              )}
            </Formik>
          )}
        </CCardBody>
      </CCard>
    </Container>
  )
}
