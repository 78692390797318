import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { Container, InputGroup, InputGroupAddon, InputGroupText, Spinner } from 'reactstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import { Formik, Form, ErrorMessage, FastField } from 'formik'
import * as Yup from 'yup'
import {
  CLabel,
  CTabs,
  CNav,
  CTabContent,
  CTabPane,
  CNavItem,
  CNavLink,
  CCard,
  CCardBody,
} from '@coreui/react'
import NumberFormat from 'react-number-format'
import deepEqual from 'deep-equal'
import { v4 as uuidv4 } from 'uuid'

import * as actionTypes from '../../store/action-types'
import { TRootState } from '../../store/reducers'
import {
  FormActionsPanel,
  CustomErrorMessage,
  getUploadedFileType,
  ThreeDots,
} from '../../components'
import {
  inputLabelSpacingBottom,
  inputFieldSpacingBottom,
  successMessageDuration,
  toDateInputValue,
  globalThousandSeparator,
  globalDecimalSeparator,
  getErrorMessageFromStatus,
  globalCurrencyFallback,
  preventNavigationChange,
} from '../../utils'
import { BasicFormField, AttachmentsUploadPanel } from '../../components'
import { serviceIconName, toolingIconName } from '../../components/sidebar-nav'
import { TFormTab } from '../admin'
import { TBomElement } from '../../store/action-types'
import { convertNumericStringToNumber } from '../../utils/convert-numeric-string-to-number'

export const offerNavTabs = [
  { name: 'information', title: 'Informacje', isEditOnly: false },
  { name: 'attachments', title: 'Załączniki', isEditOnly: false },
]

export const OfferDetails: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tenant, mode, id, tab } = useParams<{
    tenant: string
    mode: actionTypes.TFormMode
    id: string
    tab: string
  }>()
  const paramsOfferId = id
  const paramsOfferTab = tab
  const initialFileInput: any = undefined

  const [didFormValidationOccur, setDidFormValidationOccur] = useState(false)
  const [isOneFieldChanged, setIsOneFieldChanged] = useState(false)
  const [isOfferModifiedAndUnsaved, setIsOfferModifiedAndUnsaved] = useState<boolean>(false)

  const authState = useSelector((state: TRootState) => state.auth)
  const settingsState = useSelector((state: TRootState) => state.settings)
  const offersState = useSelector((state: TRootState) => state.offers)
  const suppliersState = useSelector((state: TRootState) => state.suppliers)
  const bomElementsState = useSelector((state: TRootState) => state.bomElements)
  const implementationCostsState = useSelector((state: TRootState) => state.implementationCosts)
  const servicesState = useSelector((state: TRootState) => state.services)

  const isThereNetworkError =
    offersState?.offerCreateError?.status ||
    offersState?.offerEditSaveError?.status ||
    offersState?.offerDeleteError?.status ||
    offersState?.singleOfferFetchError?.status

  const isOfferEditable =
    authState?.authData?.roles?.includes('Offers_write') && !offersState?.singleOfferFetchError

  const closeOfferDetails = useCallback(() => {
    history.push(`/${tenant}/admin/offers`)
  }, [history, tenant, dispatch])

  // Set data for edit modal
  useEffect(() => {
    if (tenant && authState.authData && paramsOfferId && mode === 'edit') {
      if (
        !bomElementsState.areBomElementsLoading &&
        !suppliersState.areSuppliersLoading &&
        !implementationCostsState.areImplementationCostsLoading &&
        !servicesState.areServicesLoading &&
        !offersState.editedOffer &&
        !offersState.isSingleOfferLoading
      ) {
        dispatch({
          type: actionTypes.FETCH_SINGLE_OFFER_REQUESTED,
          payload: { tenant: tenant, token: authState.authData?.token, id: paramsOfferId },
        })
      }
    }
  }, [
    dispatch,
    tenant,
    authState,
    paramsOfferId,
    history,
    paramsOfferTab,
    mode,
    offersState.editedOffer,
    offersState.isSingleOfferLoading,
    bomElementsState.bomElements,
    suppliersState.suppliers,
    implementationCostsState.implementationCosts,
    servicesState.services,
  ])

  // Fix the path
  useEffect(() => {
    if (tenant && authState.authData) {
      const tabsArray = offerNavTabs?.map((navTab: TFormTab) =>
        mode === 'edit' ? navTab.name : !navTab.isEditOnly ? navTab.name : undefined
      )

      if (paramsOfferTab === undefined || !tabsArray.includes(paramsOfferTab)) {
        history.replace(
          `/${tenant}/admin/offers/${mode === 'create' ? 'create' : `edit/${paramsOfferId}`}/${
            offerNavTabs[0].name
          }`
        )
      }
    }
  }, [tenant, authState, history, paramsOfferId, paramsOfferTab, mode])

  // Fetch additional data for edit
  useEffect(() => {
    if (
      tenant &&
      authState.isAuthenticated &&
      authState.authData &&
      paramsOfferId &&
      mode === 'edit'
    ) {
      dispatch({
        type: actionTypes.FETCH_OFFER_ATTACHMENTS_REQUESTED,
        payload: {
          tenant: tenant,
          token: authState.authData?.token,
          entityId: Number(paramsOfferId),
          fileAttachmentTypeId: 2,
        },
      })
    }
  }, [dispatch, tenant, authState.isAuthenticated, authState.authData, paramsOfferId])

  // Redirect on not found editable offer
  useEffect(() => {
    if (offersState.singleOfferFetchError && offersState.singleOfferFetchError.status === 404) {
      history.replace(`/${tenant}/admin/offers`)
    }
  }, [dispatch, offersState.singleOfferFetchError, history, tenant])

  // Close offer on a successful create or delete
  useEffect(() => {
    if (
      (!offersState.isOfferCreating &&
        !offersState.offerCreateError &&
        offersState.isOfferCreated &&
        mode === 'create') ||
      (!offersState.isOfferDeleting && !offersState.offerDeleteError && offersState.isOfferDeleted)
    ) {
      setTimeout(() => {
        closeOfferDetails()
      }, successMessageDuration)
    }
  }, [offersState.isOfferCreating, offersState.isOfferCreated, offersState.isOfferDeleted])

  // Track the changes again
  useEffect(() => {
    if (offersState.isOfferEditSaved || offersState.isOfferCreated || offersState.isOfferDeleted) {
      setIsOfferModifiedAndUnsaved(false)
    }
  }, [offersState.isOfferEditSaved, offersState.isOfferCreated, offersState.isOfferDeleted])

  // Prevent navigation back and forth plus reload if modified
  useEffect(() => {
    preventNavigationChange(
      history,
      isOfferModifiedAndUnsaved,
      isOfferEditable,
      'offers',
      paramsOfferId,
      mode
    )
  }, [location.pathname, history, isOfferModifiedAndUnsaved, isOfferEditable])

  // Unmount Component
  useEffect(() => {
    return () => {
      window.onbeforeunload = function () {
        return null
      }

      dispatch({ type: actionTypes.CLOSE_OFFER_DETAILS })
      dispatch({
        type: actionTypes.CLEAR_SINGLE_ATTACHMENT_ERRORS,
      })
    }
  }, [])

  const equalsZeroMessage = 'Wartość 0 niedozwolona!'

  // Validation Schema
  const OfferSchema = Yup.object().shape(
    {
      bomElementId: Yup.number()
        .required('To pole jest wymagane!')
        .positive('Ten element nie istnieje!')
        .nullable(),
      supplierId: Yup.number()
        .required('To pole jest wymagane!')
        .positive('Ten dostawca nie istnieje!')
        .nullable(),
      quantity: Yup.number().required('To pole jest wymagane!').min(0.01, equalsZeroMessage),
      expirationDate: Yup.string().required('To pole jest wymagane!'),

      bomElementPrice: Yup.string()
        .required('To pole jest wymagane!')
        .nullable()
        .test(
          'bomElementPriceEqualsZero',
          equalsZeroMessage,
          (price) => Number(convertNumericStringToNumber(price)) !== 0
        ),
      bomElementPriceCurrency: Yup.string().required('To pole jest wymagane!'),

      implementationCostId: Yup.number().when('implementationCostPrice', {
        is: (implementationCostPrice) => implementationCostPrice?.length,
        then: Yup.number().required('Uzupełnij to pole!').positive('Ten tooling nie istnieje!'),
        otherwise: Yup.number().nullable(),
      }),
      implementationCostPrice: Yup.string().when('implementationCostId', {
        is: (implementationCostId) => !!implementationCostId,
        then: Yup.string()
          .required('Uzupełnij to pole!')
          .test(
            'implementationCostPriceEqualsZero',
            equalsZeroMessage,
            (price) => Number(convertNumericStringToNumber(price)) !== 0
          ),
        otherwise: Yup.string().nullable(),
      }),

      serviceId: Yup.number().when('servicePrice', {
        is: (servicePrice) => servicePrice?.length,
        then: Yup.number().required('Uzupełnij to pole!').positive('Ta usługa nie istnieje!'),
        otherwise: Yup.number().nullable(),
      }),
      servicePrice: Yup.string().when('serviceId', {
        is: (serviceId) => !!serviceId,
        then: Yup.string()
          .required('Uzupełnij to pole!')
          .test(
            'servicePriceEqualsZero',
            equalsZeroMessage,
            (price) => Number(convertNumericStringToNumber(price)) !== 0
          ),
        otherwise: Yup.string().nullable(),
      }),

      deliveryLeadTime: Yup.number().required('Wymagane!').min(0, 'Ujemna wartość!'),
      productionLeadTime: Yup.number().required('Wymagane!').min(0, 'Ujemna wartość!'),
    },
    [
      ['implementationCostId', 'implementationCostPrice'],
      ['serviceId', 'servicePrice'],
    ]
  )

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center">
      <CCard>
        <CCardBody className="offer-details">
          <h4 className={`text-center ${isThereNetworkError ? 'mb-2' : 'mb-3'}`}>
            {!offersState.isSingleOfferLoading &&
              (mode === 'edit'
                ? !authState?.authData?.roles?.includes('Offers_write')
                  ? 'Przeglądaj Ofertę'
                  : 'Edytuj Ofertę'
                : 'Nowa Oferta')}
            {suppliersState.isSingleSupplierLoading && 'Oferta'}
            {suppliersState.isSingleSupplierLoading && <ThreeDots />}
          </h4>

          {offersState.isSingleOfferLoading ||
          bomElementsState.areBomElementsLoading ||
          suppliersState.areSuppliersLoading ||
          implementationCostsState.areImplementationCostsLoading ||
          servicesState.areServicesLoading ? (
            <div
              style={{
                height: '600px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Spinner />
            </div>
          ) : (
            <Formik
              initialValues={{
                uuid:
                  mode === 'create'
                    ? uuidv4()
                    : offersState.editedOffer?.uuId || offersState.editedOffer?.uuid,

                bomElementId: offersState.editedOffer?.bomElementId,
                bomElementName: offersState.editedOffer?.bomElementName || '',

                supplierId: offersState.editedOffer?.supplierId,
                supplierName: offersState.editedOffer?.supplierName || '',

                quantity: offersState.editedOffer?.quantity || '',
                bomElementPrice: offersState.editedOffer?.bomElementPrice,
                bomElementPriceCurrency:
                  offersState.editedOffer?.bomElementPriceCurrency ||
                  settingsState?.settings?.otherSettings?.defaultSystemCurrency ||
                  globalCurrencyFallback,

                implementationCostId: offersState.editedOffer?.implementationCostId || '',
                implementationCostName: offersState.editedOffer?.implementationCostName || '',
                implementationCostPrice: offersState.editedOffer?.implementationCostPrice,
                implementationCostPriceCurrency:
                  offersState.editedOffer?.implementationCostPriceCurrency ||
                  settingsState?.settings?.otherSettings?.defaultSystemCurrency ||
                  globalCurrencyFallback,

                serviceId: offersState.editedOffer?.serviceId || '',
                serviceName: offersState.editedOffer?.serviceName || '',
                servicePrice: offersState.editedOffer?.servicePrice,
                servicePriceCurrency:
                  offersState.editedOffer?.servicePriceCurrency ||
                  settingsState?.settings?.otherSettings?.defaultSystemCurrency ||
                  globalCurrencyFallback,

                expirationDate: offersState.editedOffer?.expirationDate || '',

                deliveryLeadTime: offersState.editedOffer?.deliveryLeadTime || '',
                deliveryLeadTimeInWeeks: offersState.editedOffer?.deliveryLeadTime
                  ? Number(offersState.editedOffer?.deliveryLeadTime / 7)
                  : '',
                productionLeadTime: offersState.editedOffer?.productionLeadTime || '',
                productionLeadTimeInWeeks: offersState.editedOffer?.productionLeadTime
                  ? Number(offersState.editedOffer?.productionLeadTime / 7)
                  : '',

                newAttachmentFileInput: initialFileInput,
                newAttachmentAltName: '',

                number:
                  offersState.editedOffer?.number ||
                  (mode === 'create' &&
                    `O/${Number(new Date().getDate())}/${
                      Number(new Date().getMonth()) + 1
                    }/${Number(new Date().getFullYear())}/${
                      offersState.offers.filter((offer: actionTypes.TLightOfferInTable) => {
                        if (offer.createDate) {
                          const today = new Date()
                          const offerDate = new Date(offer.createDate)

                          return (
                            offerDate.getDate() === today.getDate() &&
                            offerDate.getMonth() === today.getMonth() &&
                            offerDate.getFullYear() === today.getFullYear()
                          )
                        } else {
                          return false
                        }
                      }).length + 1
                    }`) ||
                  '',

                customOfferNumber: offersState.editedOffer?.customOfferNumber || '',
                canDelete: offersState?.editedOffer?.canDelete,
              }}
              validationSchema={OfferSchema}
              onSubmit={(values) => {
                if (isOfferEditable) {
                  setDidFormValidationOccur(true)
                  setIsOneFieldChanged(false)

                  const { productionLeadTimeInWeeks, deliveryLeadTimeInWeeks, ...apiValues } =
                    values

                  dispatch({
                    type:
                      mode === 'create'
                        ? actionTypes.CREATE_OFFER_REQUESTED
                        : actionTypes.EDIT_OFFER_REQUESTED,
                    payload: {
                      tenant: tenant,
                      token: authState.authData?.token,
                      offer: {
                        ...apiValues,
                        quantity: convertNumericStringToNumber(apiValues.quantity),
                        bomElementPrice: convertNumericStringToNumber(apiValues.bomElementPrice),
                        expirationDate: new Date(apiValues.expirationDate).toISOString(),

                        implementationCostPrice: convertNumericStringToNumber(
                          apiValues?.implementationCostPrice
                        ),
                        implementationCostId: apiValues?.implementationCostId || null,
                        servicePrice: convertNumericStringToNumber(apiValues?.servicePrice),
                        serviceId: apiValues?.serviceId || null,
                        deliveryLeadTime: convertNumericStringToNumber(apiValues?.deliveryLeadTime),
                        productionLeadTime: convertNumericStringToNumber(
                          apiValues?.productionLeadTime
                        ),
                        ...(offersState.editedOffer?.id && {
                          id: offersState.editedOffer?.id,
                        }),
                      },
                    },
                  })
                }
              }}
              enableReinitialize={false}
              validateOnBlur={false}
              validateOnChange={didFormValidationOccur}
            >
              {({ initialValues, values, errors, resetForm, handleChange, setFieldValue }) => (
                <Form
                  onChange={() => {
                    // Values here are always 1 step behind
                    let isModified = !deepEqual(values, initialValues, { strict: false })

                    if (isModified) {
                      /* If form is brought to its initial state then it is not modified */
                      setIsOfferModifiedAndUnsaved(true)
                    } else {
                      setIsOfferModifiedAndUnsaved(false)
                    }

                    setIsOneFieldChanged(true)
                  }}
                >
                  {/*
                   * Display Network Error Message
                   */}

                  {!isOneFieldChanged && offersState?.offerCreateError && (
                    <CustomErrorMessage
                      wrapperClassNames="mt-3 mb-4"
                      customErrorMessageText={getErrorMessageFromStatus(
                        'create',
                        offersState?.offerCreateError?.status
                      )}
                    />
                  )}

                  {!isOneFieldChanged && offersState?.singleOfferFetchError && (
                    <CustomErrorMessage
                      wrapperClassNames="mt-3 mb-4"
                      customErrorMessageText={getErrorMessageFromStatus(
                        'fetch',
                        offersState?.singleOfferFetchError?.status,
                        'oferty'
                      )}
                    />
                  )}

                  {!isOneFieldChanged && offersState?.offerEditSaveError && (
                    <CustomErrorMessage
                      wrapperClassNames="mt-3 mb-4"
                      customErrorMessageText={getErrorMessageFromStatus(
                        'edit',
                        offersState?.offerEditSaveError?.status,
                        'oferty'
                      )}
                    />
                  )}

                  {offersState?.offerDeleteError && (
                    <CustomErrorMessage
                      wrapperClassNames="mt-3 mb-4"
                      customErrorMessageText={getErrorMessageFromStatus(
                        'delete',
                        offersState?.offerDeleteError?.status,
                        'oferty'
                      )}
                    />
                  )}

                  {/*
                   * Form Tabs
                   */}

                  <CTabs activeTab={paramsOfferTab}>
                    <CNav variant="tabs" className="mb-4">
                      {offerNavTabs?.map((modalTab: TFormTab) => {
                        if (modalTab.isEditOnly && mode === 'create') {
                          return null
                        } else {
                          return (
                            <CNavItem key={modalTab.name}>
                              <CNavLink
                                className={`${
                                  modalTab.name === 'information' &&
                                  Boolean(
                                    errors.bomElementId ||
                                      errors.supplierId ||
                                      errors.quantity ||
                                      errors.bomElementPrice ||
                                      errors.expirationDate ||
                                      errors.implementationCostId ||
                                      errors.implementationCostPrice ||
                                      errors.serviceId ||
                                      errors.servicePrice ||
                                      errors.deliveryLeadTime ||
                                      errors.productionLeadTime
                                  )
                                    ? 'text-danger is-invalid'
                                    : ''
                                }`}
                                data-tab={modalTab.name}
                                onClick={() => {
                                  if (mode === 'edit') {
                                    history.replace(
                                      `/${tenant}/admin/offers/edit/${paramsOfferId}/${modalTab.name}`
                                    )
                                  } else {
                                    history.replace(
                                      `/${tenant}/admin/offers/create/${modalTab.name}`
                                    )
                                  }
                                }}
                              >
                                {modalTab.title}
                              </CNavLink>
                            </CNavItem>
                          )
                        }
                      })}
                    </CNav>
                    <CTabContent>
                      {/*
                       * Offer Information Tab
                       */}

                      <CTabPane data-tab="information">
                        <div className="grid-fields three-grid-fields">
                          <BasicFormField
                            fieldId="offer-inner-number"
                            fieldLabel="Numer wewnętrzny"
                            fieldIcon="cil-barcode"
                            formikFieldName="number"
                            fieldValue={values.number}
                            fieldError={errors.number}
                            fieldType="text"
                            placeholder="Brak"
                            isDisabled
                          />

                          <BasicFormField
                            fieldId="offer-custom-number"
                            fieldLabel="Numer oferty dostawcy"
                            fieldIcon="cil-barcode"
                            formikFieldName="customOfferNumber"
                            fieldValue={values.customOfferNumber}
                            fieldError={errors.customOfferNumber}
                            fieldType="text"
                            placeholder="Wprowadź numer oferty od dostawcy"
                            isShortLengthInput
                          />

                          <div className="offer-supplier-wrapper">
                            <CLabel
                              htmlFor="offer-supplier"
                              className={`${inputLabelSpacingBottom}`}
                            >
                              Dostawca
                            </CLabel>
                            <InputGroup
                              id="offer-supplier"
                              className={`${inputFieldSpacingBottom} flex-nowrap`}
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText
                                  className={errors.supplierId && 'text-danger input-error-icon'}
                                >
                                  <i className="cil-address-book"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <ErrorMessage
                                name="supplierId"
                                component="span"
                                className="text-danger input-error-message"
                              />

                              <Typeahead
                                placeholder="Wybierz z listy lub wpisz nazwę dostawcy"
                                isInvalid={!!errors.supplierId}
                                id="offer-supplier-typeahead"
                                onChange={(selected: actionTypes.TSupplier[]) => {
                                  if (selected.length > 0) {
                                    setFieldValue('supplierId', selected[0].id)
                                  }
                                }}
                                onInputChange={(text) => {
                                  if (text.length > 0) {
                                    const matchedSupplier = suppliersState.suppliers.find(
                                      (producer: actionTypes.TProducer) =>
                                        producer.name.toLowerCase() === text.toLowerCase()
                                    )

                                    if (matchedSupplier) {
                                      setFieldValue('supplierId', matchedSupplier.id)
                                    } else {
                                      setFieldValue('supplierId', -1)
                                    }
                                  } else {
                                    setFieldValue('supplierId', undefined)
                                  }
                                }}
                                defaultInputValue={values.supplierName}
                                options={suppliersState.suppliers}
                                labelKey="name"
                                emptyLabel={'Nie znaleziono dostawców'}
                              />
                            </InputGroup>
                          </div>
                        </div>

                        <div>
                          <CLabel
                            htmlFor="offer-bom-element"
                            className={`${inputLabelSpacingBottom}`}
                          >
                            Element
                          </CLabel>
                          <InputGroup
                            id="offer-bom-element"
                            className={`${inputFieldSpacingBottom} flex-nowrap`}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText
                                className={errors.bomElementId && 'text-danger input-error-icon'}
                              >
                                <i className="cil-memory"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <ErrorMessage
                              name="bomElementId"
                              component="span"
                              className="text-danger input-error-message"
                            />

                            <Typeahead
                              placeholder="Wybierz z listy lub wpisz nazwę elementu"
                              isInvalid={!!errors.bomElementId}
                              id="offer-element-typeahead"
                              onChange={(selected: actionTypes.TSupplier[]) => {
                                if (selected.length > 0) {
                                  setFieldValue('bomElementId', selected[0].id)
                                }
                              }}
                              onInputChange={(text) => {
                                if (text.length > 0) {
                                  const matchedBomElement = bomElementsState.bomElements.find(
                                    (bomElement: actionTypes.TBomElement) =>
                                      bomElement.name.toLowerCase() === text.toLowerCase()
                                  )

                                  if (matchedBomElement) {
                                    setFieldValue('bomElementId', matchedBomElement.id)
                                  } else {
                                    setFieldValue('bomElementId', -1)
                                  }
                                } else {
                                  setFieldValue('bomElementId', undefined)
                                }
                              }}
                              defaultInputValue={values.bomElementName}
                              options={bomElementsState?.bomElements?.map(
                                (bomElement: TBomElement) => ({
                                  ...bomElement,
                                  name: `${bomElement.name} | MPN: ${
                                    bomElement?.mpn || 'brak'
                                  } | DIN: ${bomElement?.symbolDin || 'brak'}`,
                                })
                              )}
                              labelKey="name"
                              emptyLabel={'Nie znaleziono elementów'}
                            />
                          </InputGroup>
                        </div>

                        {
                          // pt-1 is a proper value here
                        }
                        <div className="grid-fields three-grid-fields pt-1">
                          <BasicFormField
                            fieldId="offer-quantity"
                            fieldLabel="Ilość"
                            fieldIcon="cil-asterisk"
                            formikFieldName="quantity"
                            fieldValue={values.quantity}
                            fieldError={errors.quantity}
                            fieldType="number"
                            placeholder="Wprowadź ilość"
                            amountMeasurementType={1}
                          />

                          <BasicFormField
                            fieldId="offer-bom-element-price"
                            fieldLabel="Cena jednostkowa"
                            fieldIcon="cil-cash"
                            formikFieldName="bomElementPrice"
                            fieldValue={values.bomElementPrice}
                            fieldError={errors.bomElementPrice}
                            fieldType="number"
                            placeholder="Wprowadź cenę jednostkową"
                            fieldCurrencyName="bomElementPriceCurrency"
                            fieldCurrencyValue={values.bomElementPriceCurrency}
                            handleChange={handleChange}
                          />

                          <BasicFormField
                            fieldId="offer-expiration-date"
                            fieldLabel="Data wygaśnięcia"
                            fieldIcon="cil-calendar"
                            formikFieldName="expirationDate"
                            fieldValue={toDateInputValue(values.expirationDate)}
                            fieldError={errors.expirationDate}
                            fieldType="date"
                            placeholder="Wprowadź datę wygaśnięcia"
                          />
                        </div>

                        <div className="grid-fields two-grid-fields">
                          <div>
                            <CLabel
                              htmlFor="offer-implementation-cost"
                              className={`${inputLabelSpacingBottom} mt-1`}
                            >
                              Tooling
                            </CLabel>
                            <InputGroup
                              id="offer-implementation-cost"
                              className={`${inputFieldSpacingBottom} flex-nowrap`}
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText
                                  className={
                                    errors.implementationCostId && 'text-danger input-error-icon'
                                  }
                                >
                                  <i className={toolingIconName}></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <ErrorMessage
                                name="implementationCostId"
                                component="span"
                                className="text-danger input-error-message"
                              />

                              <Typeahead
                                placeholder="Wybierz z listy lub wpisz nazwę toolingu"
                                isInvalid={!!errors.implementationCostId}
                                id="offer-implementation-cost-typeahead"
                                onChange={(selected: actionTypes.TSupplier[]) => {
                                  if (selected.length > 0) {
                                    setFieldValue('implementationCostId', selected[0].id)
                                  }
                                }}
                                onInputChange={(text) => {
                                  if (text.length > 0) {
                                    const matchedImplementationCost =
                                      implementationCostsState.implementationCosts.find(
                                        (implementationCost: actionTypes.TImplementationCost) =>
                                          implementationCost.name.toLowerCase() ===
                                          text.toLowerCase()
                                      )

                                    if (matchedImplementationCost) {
                                      setFieldValue(
                                        'implementationCostId',
                                        matchedImplementationCost.id
                                      )
                                    } else {
                                      setFieldValue('implementationCostId', -1)
                                    }
                                  } else {
                                    setFieldValue('implementationCostId', undefined)
                                  }
                                }}
                                defaultInputValue={values.implementationCostName}
                                options={implementationCostsState.implementationCosts}
                                labelKey="name"
                                emptyLabel={'Nie znaleziono toolingu'}
                              />
                            </InputGroup>
                          </div>

                          <BasicFormField
                            fieldId="offer-implementation-cost-price"
                            fieldLabel="Cena toolingu"
                            fieldIcon="cil-money"
                            formikFieldName="implementationCostPrice"
                            fieldValue={values.implementationCostPrice}
                            fieldError={errors.implementationCostPrice}
                            fieldType="number"
                            placeholder="Wprowadź cenę toolingu"
                            fieldCurrencyName="implementationCostPriceCurrency"
                            fieldCurrencyValue={values.implementationCostPriceCurrency}
                            handleChange={handleChange}
                          />
                        </div>

                        <div className="grid-fields two-grid-fields">
                          <div>
                            <CLabel
                              htmlFor="offer-service"
                              className={`${inputLabelSpacingBottom} mt-1`}
                            >
                              Usługa
                            </CLabel>
                            <InputGroup
                              id="offer-service"
                              className={`${inputFieldSpacingBottom} flex-nowrap`}
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText
                                  className={errors.serviceId && 'text-danger input-error-icon'}
                                >
                                  <i className={serviceIconName}></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <ErrorMessage
                                name="serviceId"
                                component="span"
                                className="text-danger input-error-message"
                              />

                              <Typeahead
                                placeholder="Wybierz z listy lub wpisz nazwę usługi"
                                isInvalid={!!errors.serviceId}
                                id="offer-service-typeahead"
                                onChange={(selected: actionTypes.TSupplier[]) => {
                                  if (selected.length > 0) {
                                    setFieldValue('serviceId', selected[0].id)
                                  }
                                }}
                                onInputChange={(text) => {
                                  if (text.length > 0) {
                                    const matchedService = servicesState.services.find(
                                      (service: actionTypes.TService) =>
                                        service.name.toLowerCase() === text.toLowerCase()
                                    )

                                    if (matchedService) {
                                      setFieldValue('serviceId', matchedService.id)
                                    } else {
                                      setFieldValue('serviceId', -1)
                                    }
                                  } else {
                                    setFieldValue('serviceId', undefined)
                                  }
                                }}
                                defaultInputValue={values.serviceName}
                                options={servicesState.services}
                                labelKey="name"
                                emptyLabel={'Nie znaleziono usług'}
                              />
                            </InputGroup>
                          </div>

                          <BasicFormField
                            fieldId="offer-service-price"
                            fieldLabel="Cena usługi"
                            fieldIcon="cil-money"
                            formikFieldName="servicePrice"
                            fieldValue={values.servicePrice}
                            fieldError={errors.servicePrice}
                            fieldType="number"
                            placeholder="Wprowadź cenę usługi"
                            fieldCurrencyName="servicePriceCurrency"
                            fieldCurrencyValue={values.servicePriceCurrency}
                            handleChange={handleChange}
                          />
                        </div>

                        <div className="grid-fields time-grid-fields">
                          <div className="d-flex align-items-center">
                            <div>
                              <CLabel
                                htmlFor="offer-production-lt-in-days"
                                className={`${inputLabelSpacingBottom} mt-1`}
                              >
                                LT produkcji
                              </CLabel>
                              <InputGroup
                                id="offer-production-lt"
                                className={`${inputFieldSpacingBottom}`}
                              >
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText
                                    className={
                                      errors.productionLeadTime && 'text-danger input-error-icon'
                                    }
                                  >
                                    <i className="cil-av-timer"></i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <ErrorMessage
                                  name="productionLeadTime"
                                  component="span"
                                  className="text-danger input-error-message"
                                />
                                <FastField
                                  as={NumberFormat}
                                  name="productionLeadTime"
                                  placeholder="Wprowadź LT produkcji"
                                  displayType="input"
                                  thousandSeparator={globalThousandSeparator}
                                  decimalSeparator={globalDecimalSeparator}
                                  decimalScale={0}
                                  fixedDecimalScale={false}
                                  allowNegative={false}
                                  allowLeadingZeros={false}
                                  value={values.productionLeadTime}
                                  onChange={handleChange}
                                  onBlur={() => {
                                    setFieldValue(
                                      'productionLeadTimeInWeeks',
                                      values.productionLeadTime
                                        ? Number(
                                            convertNumericStringToNumber(values.productionLeadTime)
                                          ) / 7
                                        : ''
                                    )
                                  }}
                                  className={`form-control text-right ${
                                    errors.productionLeadTime && 'is-invalid'
                                  }`}
                                />
                                <InputGroupAddon addonType="append">
                                  <InputGroupText
                                    className="disabled-currency"
                                    style={{
                                      width: '45px',
                                      border: '1px solid #d8dbe0',
                                      borderLeft: 'none',
                                      background: 'transparent',
                                    }}
                                  >
                                    dni
                                  </InputGroupText>
                                </InputGroupAddon>
                              </InputGroup>
                            </div>

                            <div className="px-2 mt-2">=</div>

                            <div
                              className={`${inputFieldSpacingBottom}`}
                              style={{ marginTop: '28px' }}
                            >
                              <InputGroup id="offer-production-lt-in-weeks">
                                <FastField
                                  as={NumberFormat}
                                  name="productionLeadTimeInWeeks"
                                  placeholder="Wprowadź LT produkcji"
                                  displayType="input"
                                  thousandSeparator={globalThousandSeparator}
                                  decimalSeparator={globalDecimalSeparator}
                                  decimalScale={2}
                                  fixedDecimalScale={false}
                                  allowNegative={false}
                                  allowLeadingZeros={true}
                                  value={values.productionLeadTimeInWeeks}
                                  onChange={handleChange}
                                  onBlur={() => {
                                    setFieldValue(
                                      'productionLeadTime',
                                      values?.productionLeadTimeInWeeks
                                        ? Math.round(
                                            Number(
                                              convertNumericStringToNumber(
                                                values?.productionLeadTimeInWeeks
                                              )
                                            ) * 7
                                          )
                                        : ''
                                    )
                                  }}
                                  className={`form-control text-right ${
                                    errors.productionLeadTimeInWeeks && 'is-invalid'
                                  }`}
                                />
                                <InputGroupAddon addonType="append">
                                  <InputGroupText
                                    className="disabled-currency"
                                    style={{
                                      width: '45px',
                                      border: '1px solid #d8dbe0',
                                      borderLeft: 'none',
                                      background: 'transparent',
                                    }}
                                  >
                                    tyg.
                                  </InputGroupText>
                                </InputGroupAddon>
                              </InputGroup>
                            </div>
                          </div>

                          <div className="d-flex align-items-center">
                            <div>
                              <CLabel
                                htmlFor="offer-delivery-lt"
                                className={`${inputLabelSpacingBottom} mt-1`}
                              >
                                LT dostawy
                              </CLabel>
                              <InputGroup
                                id="offer-delivery-lt"
                                className={`${inputFieldSpacingBottom}`}
                              >
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText
                                    className={
                                      errors.deliveryLeadTime && 'text-danger input-error-icon'
                                    }
                                  >
                                    <i className="cil-av-timer"></i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <ErrorMessage
                                  name="deliveryLeadTime"
                                  component="span"
                                  className="text-danger input-error-message"
                                />
                                <FastField
                                  as={NumberFormat}
                                  name="deliveryLeadTime"
                                  placeholder="Wprowadź LT produkcji"
                                  displayType="input"
                                  thousandSeparator={globalThousandSeparator}
                                  decimalSeparator={globalDecimalSeparator}
                                  decimalScale={0}
                                  fixedDecimalScale={false}
                                  allowNegative={false}
                                  allowLeadingZeros={false}
                                  value={values.deliveryLeadTime}
                                  onChange={handleChange}
                                  onBlur={() => {
                                    setFieldValue(
                                      'deliveryLeadTimeInWeeks',
                                      values.deliveryLeadTime
                                        ? Number(
                                            convertNumericStringToNumber(values.deliveryLeadTime)
                                          ) / 7
                                        : ''
                                    )
                                  }}
                                  className={`form-control text-right ${
                                    errors.deliveryLeadTime && 'is-invalid'
                                  }`}
                                />
                                <InputGroupAddon addonType="append">
                                  <InputGroupText
                                    className="disabled-currency"
                                    style={{
                                      width: '45px',
                                      border: '1px solid #d8dbe0',
                                      borderLeft: 'none',
                                      background: 'transparent',
                                    }}
                                  >
                                    dni
                                  </InputGroupText>
                                </InputGroupAddon>
                              </InputGroup>
                            </div>

                            <div className="px-2 mt-2">=</div>

                            <div
                              className={`${inputFieldSpacingBottom}`}
                              style={{ marginTop: '28px' }}
                            >
                              <InputGroup id="offer-delivery-lt-in-weeks">
                                <FastField
                                  as={NumberFormat}
                                  name="deliveryLeadTimeInWeeks"
                                  placeholder="Wprowadź LT dostawy"
                                  displayType="input"
                                  thousandSeparator={globalThousandSeparator}
                                  decimalSeparator={globalDecimalSeparator}
                                  decimalScale={2}
                                  fixedDecimalScale={false}
                                  allowNegative={false}
                                  allowLeadingZeros={true}
                                  value={values.deliveryLeadTimeInWeeks}
                                  onChange={handleChange}
                                  onBlur={() => {
                                    setFieldValue(
                                      'deliveryLeadTime',
                                      values?.deliveryLeadTimeInWeeks
                                        ? Math.round(
                                            Number(
                                              convertNumericStringToNumber(
                                                values?.deliveryLeadTimeInWeeks
                                              )
                                            ) * 7
                                          )
                                        : ''
                                    )
                                  }}
                                  className={`form-control text-right ${
                                    errors.deliveryLeadTimeInWeeks && 'is-invalid'
                                  }`}
                                />
                                <InputGroupAddon addonType="append">
                                  <InputGroupText
                                    className="disabled-currency"
                                    style={{
                                      width: '45px',
                                      border: '1px solid #d8dbe0',
                                      borderLeft: 'none',
                                      background: 'transparent',
                                    }}
                                  >
                                    tyg.
                                  </InputGroupText>
                                </InputGroupAddon>
                              </InputGroup>
                            </div>
                          </div>
                        </div>
                      </CTabPane>

                      {/*
                       * Attachments Tab
                       */}

                      <CTabPane data-tab="attachments">
                        <AttachmentsUploadPanel
                          attachments={offersState.editedOfferAttachments}
                          fetchAttachmentsError={offersState.fetchOfferAttachmentsError}
                          isAttachmentUploading={offersState.isOfferAttachmentUploading}
                          isAttachmentUploaded={offersState.isOfferAttachmentUploaded}
                          attachmentUploadError={offersState.offerAttachmentUploadingError}
                          newAttachmentFileInput={values.newAttachmentFileInput}
                          newAttachmentAltNameError={errors.newAttachmentAltName}
                          dispatchAttachmentUpload={(encodedFile) => {
                            dispatch({
                              type: actionTypes.UPLOAD_OFFER_ATTACHMENT_REQUESTED,
                              payload: {
                                tenant: tenant,
                                token: authState.authData?.token,
                                uploadAttachment: {
                                  fileAttachmentTypeId: 2,
                                  entityUuid: values.uuid,
                                  entityId: paramsOfferId ? Number(paramsOfferId) : null,

                                  type: getUploadedFileType(
                                    values.newAttachmentFileInput?.type,
                                    values.newAttachmentFileInput?.name
                                  ),
                                  name: values.newAttachmentFileInput.name,
                                  base64EncodedFile: encodedFile,
                                  altName: values.newAttachmentAltName || null,
                                },
                              },
                            })

                            mode === 'create' && setIsOfferModifiedAndUnsaved(true)
                          }}
                          dispatchModalActionsUnlock={() =>
                            dispatch({ type: actionTypes.OFFER_ACTIONS_UNLOCK })
                          }
                          dispatchSingleAttachmentFetch={(
                            attachmentId,
                            attachmentType,
                            fileName,
                            download
                          ) =>
                            dispatch({
                              type: actionTypes.FETCH_SINGLE_ATTACHMENT_REQUESTED,
                              payload: {
                                tenant: tenant,
                                token: authState.authData?.token,
                                id: attachmentId,
                                attachmentType: attachmentType,
                                fileName: fileName,
                                offerUUid: values.uuid,
                                download: download,
                              },
                            })
                          }
                          dispatchSingleAttachmentDelete={(attachmentId) =>
                            dispatch({
                              type: actionTypes.DELETE_SINGLE_ATTACHMENT_REQUESTED,
                              payload: {
                                tenant: tenant,
                                token: authState.authData?.token,
                                id: attachmentId,
                              },
                              section: 'offers',
                            })
                          }
                          isEditable={isOfferEditable}
                          isUploadDisabled={
                            offersState.isSingleOfferLoading ||
                            offersState.isOfferCreating ||
                            offersState.isOfferEditSaving ||
                            offersState.isOfferDeleting ||
                            offersState.isOfferCreated ||
                            offersState.isOfferEditSaved ||
                            offersState.isOfferDeleted ||
                            offersState.isOfferAttachmentUploading ||
                            !values.newAttachmentFileInput
                          }
                          setFieldValue={setFieldValue}
                          noAttachmentsTextFirstWords="Ta oferta"
                        />
                      </CTabPane>
                    </CTabContent>
                  </CTabs>

                  <FormActionsPanel
                    mode={mode}
                    padding="pt-4 pb-1"
                    isSaving={
                      mode === 'create'
                        ? offersState.isOfferCreating
                        : offersState.isOfferEditSaving
                    }
                    isSaved={
                      mode === 'create' ? offersState.isOfferCreated : offersState.isOfferEditSaved
                    }
                    isDeleting={offersState.isOfferDeleting}
                    isDeleted={offersState.isOfferDeleted}
                    setDidFormValidationOccur={setDidFormValidationOccur}
                    didFormValidationOccur={didFormValidationOccur}
                    setIsOneFieldChanged={setIsOneFieldChanged}
                    formErrorsBool={Boolean(
                      errors.bomElementId ||
                        errors.supplierId ||
                        errors.quantity ||
                        errors.bomElementPrice ||
                        errors.expirationDate ||
                        errors.implementationCostId ||
                        errors.implementationCostPrice ||
                        errors.serviceId ||
                        errors.servicePrice ||
                        errors.deliveryLeadTime ||
                        errors.productionLeadTime
                    )}
                    closeAction={actionTypes.CLOSE_OFFER_DETAILS}
                    deleteAction={actionTypes.DELETE_OFFER_REQUESTED}
                    deletePayload={{
                      tenant: tenant,
                      token: authState.authData?.token,
                      id: offersState.editedOffer?.id,
                    }}
                    closeFunction={closeOfferDetails}
                    isEditable={isOfferEditable}
                    canDelete={values.canDelete}
                    disabledDeleteButtonClassNames="delete-details-button"
                    confirmDeleteMessageJSX={
                      <>
                        Czy na pewno chcesz usunąć ofertę?
                        {initialValues?.number || initialValues?.customOfferNumber ? (
                          <>
                            <br />
                            <strong>
                              {initialValues?.number || initialValues?.customOfferNumber}
                            </strong>
                          </>
                        ) : (
                          ''
                        )}
                      </>
                    }
                  />
                </Form>
              )}
            </Formik>
          )}
        </CCardBody>
      </CCard>
    </Container>
  )
}
