import { call, put, takeLatest, delay } from 'redux-saga/effects'

import {
  sectionFetch,
  bomCall,
  copyItemCall,
  archiveItemCall,
  deleteItemCall,
  singleItemFetch,
} from './api'
import * as actionTypes from '../action-types'
import { successMessageDuration, editSuccessMessageDuration } from '../../utils'

export function* fetchBoms(action: actionTypes.fetchBomsAction) {
  try {
    const response = yield call(sectionFetch, action.payload, 'boms')
    yield put({ type: actionTypes.FETCH_BOMS_SUCCEEDED, boms: response.data })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_BOMS_ERROR,
      error: error?.response,
    })
  }
}

export function* fetchSingleBom(action: actionTypes.fetchSingleBomAction) {
  try {
    const response = yield call(singleItemFetch, action.payload, `boms/edit/${action.payload.id}`)

    yield put({ type: actionTypes.FETCH_SINGLE_BOM_SUCCEEDED, editedBom: response.data })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.FETCH_SINGLE_BOM_ERROR,
      error: error?.response,
    })
  }
}

export function* createBom(action: actionTypes.createBomAction) {
  try {
    const response = yield call(bomCall, action.payload)
    yield put({
      type: actionTypes.CREATE_BOM_SUCCEEDED,
      bom: { name: action.payload.bom.name, id: response.data.id, canDelete: true },
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.CREATE_BOM_ERROR,
      error: error?.response,
    })
    if (window && window.document) {
      window.scrollTo({ top: 0 })
    }
  }
}

export function* editBom(action: actionTypes.editBomAction) {
  try {
    yield call(bomCall, action.payload)
    yield put({
      type: actionTypes.EDIT_BOM_SUCCEEDED,
      bom: {
        name: action.payload.bom.name,
        id: action.payload.bom.id,
        canDelete: action.payload.canDelete,
      },
    })
    yield delay(successMessageDuration)
    yield put({
      type: actionTypes.BOM_ACTIONS_UNLOCK,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.EDIT_BOM_ERROR,
      error: error?.response,
    })
    if (window && window.document) {
      window.scrollTo({ top: 0 })
    }
  }
}

export function* copyBom(action: actionTypes.copyBomAction) {
  try {
    const response = yield call(copyItemCall, action.payload, 'boms')

    yield put({
      type: actionTypes.COPY_BOM_SUCCEEDED,
      bom: { name: action.payload.name, id: response.data, canDelete: true },
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.COPY_BOM_ERROR,
      error: error?.response,
    })
  }
}

export function* archiveBom(action: actionTypes.archiveBomAction) {
  try {
    yield call(archiveItemCall, action.payload, 'boms')
    yield put({
      type: actionTypes.ARCHIVE_BOM_SUCCEEDED,
      bom: { id: action.payload.id },
    })
    yield delay(successMessageDuration)
    yield put({
      type: actionTypes.CLOSE_BOM_DETAILS,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.ARCHIVE_BOM_ERROR,
      error: error?.response,
    })
  }
}

export function* deleteBom(action: actionTypes.deleteBomAction) {
  try {
    yield call(deleteItemCall, action.payload, 'boms')
    yield put({
      type: actionTypes.DELETE_BOM_SUCCEEDED,
      bom: { id: action.payload.id },
    })
    yield delay(editSuccessMessageDuration)
    yield put({
      type: actionTypes.CLOSE_BOM_DETAILS,
    })
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.DELETE_BOM_ERROR,
      error: error?.response,
    })
  }
}

export function* bomsSaga() {
  yield takeLatest(actionTypes.FETCH_BOMS_REQUESTED, fetchBoms)

  yield takeLatest(actionTypes.COPY_BOM_REQUESTED, copyBom)
  yield takeLatest(actionTypes.ARCHIVE_BOM_REQUESTED, archiveBom)
  yield takeLatest(actionTypes.DELETE_BOM_REQUESTED, deleteBom)

  yield takeLatest(actionTypes.FETCH_SINGLE_BOM_REQUESTED, fetchSingleBom)
  yield takeLatest(actionTypes.EDIT_BOM_REQUESTED, editBom)
  yield takeLatest(actionTypes.CREATE_BOM_REQUESTED, createBom)
}
