import { TNetworkError } from './auth'
import {
  TAdminPayload,
  ISingleItemActionPayload,
  TAttachment,
  IAttachmentUpload,
  IUploadSingleAttachmentPayload,
  IFetchItemAttachmentsPayload,
  TFilterState,
  TSorterState,
  IAttachmentFetch,
  IGeneratedOrderPDFInfo,
} from './admin'
import { TCurrencyCode } from './boms'

export const FETCH_ORDERS_REQUESTED = 'FETCH_ORDERS_REQUESTED'
export const FETCH_ORDERS_SUCCEEDED = 'FETCH_ORDERS_SUCCEEDED'
export const FETCH_ORDERS_ERROR = 'FETCH_ORDERS_ERROR'

export const SET_ORDERS_TABLE_FILTER_STATE = 'SET_ORDERS_TABLE_FILTER_STATE'
export const SET_ORDERS_TABLE_SORTER_STATE = 'SET_ORDERS_TABLE_SORTER_STATE'

export const FETCH_SINGLE_ORDER_REQUESTED = 'FETCH_SINGLE_ORDER_REQUESTED'
export const FETCH_SINGLE_ORDER_SUCCEEDED = 'FETCH_SINGLE_ORDER_SUCCEEDED'
export const FETCH_SINGLE_ORDER_ERROR = 'FETCH_SINGLE_ORDER_ERROR'

export const EDIT_ORDER_REQUESTED = 'EDIT_ORDER_REQUESTED'
export const EDIT_ORDER_SUCCEEDED = 'EDIT_ORDER_SUCCEEDED'
export const EDIT_ORDER_ERROR = 'EDIT_ORDER_ERROR'

export const DELETE_ORDER_REQUESTED = 'DELETE_ORDER_REQUESTED'
export const DELETE_ORDER_SUCCEEDED = 'DELETE_ORDER_SUCCEEDED'
export const DELETE_ORDER_ERROR = 'DELETE_ORDER_ERROR'

export const FETCH_ORDER_ATTACHMENTS_REQUESTED = 'FETCH_ORDER_ATTACHMENTS_REQUESTED'
export const FETCH_ORDER_ATTACHMENTS_SUCCEEDED = 'FETCH_ORDER_ATTACHMENTS_SUCCEEDED'
export const FETCH_ORDER_ATTACHMENTS_ERROR = 'FETCH_ORDER_ATTACHMENTS_ERROR'

export const UPLOAD_ORDER_ATTACHMENT_REQUESTED = 'UPLOAD_ORDER_ATTACHMENT_REQUESTED'
export const UPLOAD_ORDER_ATTACHMENT_SUCCEEDED = 'UPLOAD_ORDER_ATTACHMENT_SUCCEEDED'
export const UPLOAD_ORDER_ATTACHMENT_ERROR = 'UPLOAD_ORDER_ATTACHMENT_ERROR'

export const DELETE_ORDER_ATTACHMENT_SUCCEEDED = 'DELETE_ORDER_ATTACHMENT_SUCCEEDED'

export const ORDER_ACTIONS_UNLOCK = 'ORDER_ACTIONS_UNLOCK'

export const GENERATE_ORDER_PDF_REQUESTED = 'GENERATE_ORDER_PDF_REQUESTED'
export const GENERATE_ORDER_PDF_SUCCEEDED = 'GENERATE_ORDER_PDF_SUCCEEDED'
export const GENERATE_ORDER_PDF_ERROR = 'GENERATE_ORDER_PDF_ERROR'
export const UNLOCK_GENERATE_ORDER_PDF_BUTTON = 'UNLOCK_GENERATE_ORDER_PDF_BUTTON'

export type TLightOrder = {
  id: number
  number: string
  customOrderNumber: string | null
  bomNames: string[]
  bomElementsQuantity: number
  totalPrice: number
  currency: string
  createdDate: string
  supplierName: string
  deliveries: string | null
}

export type TOrder = {
  id: number
  supplierId: number
  purchasePlanId: number
  orderStatus: number
  paymentStatus: number
  bomNames: string | null
  orderedDate: string | number | Date | null
  orderNumber: TInnerOrderNumber | string
  customOrderNumber: string | null
  orderDeliveries: TOrderDelivery[] | null
  orderLines: TOrderLine[] | null
  orderImplementationCosts: TOrderImplementationCost[] | null
  orderServices: TOrderService[] | null

  dispatchDate: string | null
  estimatedArrivalDate: string | null
  trackingNumber: string | null
  deliveriesTotalPrice: number | string
  deliveriesTotalPriceCurrency: TCurrencyCode | null
  shipmentCost: number
  shipmentCostCurrency: TCurrencyCode | null

  bomElementsTotalPrice: number | string
  bomElementsTotalPriceCurrency: TCurrencyCode | null
  toolingTotalPrice: number | string
  toolingTotalPriceCurrency: TCurrencyCode | null
  servicesTotalPrice: number | string
  servicesTotalPriceCurrency: TCurrencyCode | null

  canDelete: boolean

  uuid: string
}

export type TOrderFormikValues = Omit<
  TOrder,
  | 'id'
  | 'supplierId'
  | 'purchasePlanId'
  | 'dispatchDate'
  | 'estimatedArrivalDate'
  | 'trackingNumber'
  | 'shipmentCost'
  | 'shipmentCostCurrency'
  | 'canDelete'
>

export type TOrderLine = {
  id: number
  bomElementId: number
  bomElementName: string
  orderLinePrice: number | null
  orderLinePriceCurrency: string | null
  tax: number | null
  taxCurrency: string
  orderId: number
  plannedQuantity: number
  orderedQuantity: number | null
  receivedQuantity: number | null
  uuid?: string
}

export type TOrderImplementationCost = {
  id: number
  orderId: number
  offerId: number
  implementationCostId: number
  plannedQuantity: number
  orderedQuantity: number | null
  price: number
  priceCurrency: string
  bomElementId: number
}

export type TOrderService = {
  id: number
  orderId: number
  offerId: number
  serviceId: number
  bomElementId: number
  plannedQuantity: number
  orderedQuantity: number | null
  completedQuantity: number | null
  price: number
  priceCurrency: string
}

export type TOrderDelivery = {
  id: number | null
  uuid: string
  status: number
  orderId?: number
  payment: number
  deliveryCreateDate: string | null
  warehouseId: number | undefined | null
  arrivalDate: string | null
  //
  dispatchDate: string | null
  expectedArrivalDateFrom: string | null
  expectedArrivalDateTo: string | null
  shipmentCost: number | string | null
  shipmentCostCurrency: TCurrencyCode | string | null
  //
  invoices: TDeliveryInvoice[]
  //
  customsCost: number | string | null
  customsCostCurrency: TCurrencyCode | string | null
  tax: number | string | null
  taxCurrency: TCurrencyCode | string | null
  remainingCosts: number | string | null
  remainingCostsCurrency: TCurrencyCode | string | null

  totalDeliveryCost: number | string | null
  totalDeliveryCostCurrency: TCurrencyCode | string | null
  //
  notes: string | null

  deliveryLines: TDeliveryLine[] | undefined | null
}

export type TDeliveryInvoice = {
  invoiceNumber: string | null
  invoicePrice: number | string | null
  invoicePriceCurrency: TCurrencyCode | string | null
  invoicePaymentFinalDate: string | null
  invoiceDeadline: number | null
  invoiceCreateDate: string | null
  // new
  invoiceDescription: string
  uuid: string
}

export type TDeliveryLine = {
  bomElementName: string
  bomElementId: number
  orderLinePrice: number
  orderLinePriceCurrency: TCurrencyCode | string | null
  orderedQuantity: number
  expectedQuantity: number | null
  receivedQuantity: number | null
  orderLineId: number
  orderDeliveryId?: number
}

export type TInnerOrderNumber = {
  year: number
  month: number
  day: number
  sequence: number
  orderId: number
}

// CREATE_ORDER

export interface ICreateOrderPayload extends TAdminPayload {
  order: TOrder
}

// FETCH_ORDERS

export interface fetchOrdersAction {
  type: typeof FETCH_ORDERS_REQUESTED
  payload: TAdminPayload
}

export interface fetchOrdersSucceeded {
  type: typeof FETCH_ORDERS_SUCCEEDED
  orders: TOrder[]
}

export interface fetchOrdersError {
  type: typeof FETCH_ORDERS_ERROR
  error: TNetworkError
}

// SET_ORDERS_TABLE_FILTER_STATE

export interface setOrdersTableFilterState {
  type: typeof SET_ORDERS_TABLE_FILTER_STATE
  tableFilterState: TFilterState
}

// SET_ORDERS_TABLE_SORTER_STATE

export interface setOrdersTableSorterState {
  type: typeof SET_ORDERS_TABLE_SORTER_STATE
  tableSorterState: TSorterState
}

// FETCH_SINGLE_ORDER

export interface fetchSingleOrderAction {
  type: typeof FETCH_SINGLE_ORDER_REQUESTED
  payload: ISingleItemActionPayload
}

export interface fetchSingleOrderSucceeded {
  type: typeof FETCH_SINGLE_ORDER_SUCCEEDED
  editedOrder: TOrder
}

export interface fetchSingleOrderError {
  type: typeof FETCH_SINGLE_ORDER_ERROR
  error: TNetworkError
}

// EDIT_ORDER

export interface editOrderAction {
  type: typeof EDIT_ORDER_REQUESTED
  payload: ICreateOrderPayload
}

export interface editOrderSucceeded {
  type: typeof EDIT_ORDER_SUCCEEDED
  lightOrder: TOrder
}

export interface editOrderError {
  type: typeof EDIT_ORDER_ERROR
  error: TNetworkError
}

// DELETE_ORDER

export interface deleteOrderAction {
  type: typeof DELETE_ORDER_REQUESTED
  payload: ISingleItemActionPayload
}

export interface deleteOrderSucceeded {
  type: typeof DELETE_ORDER_SUCCEEDED
  id: number
}

export interface deleteOrderError {
  type: typeof DELETE_ORDER_ERROR
  error: TNetworkError
}

// FETCH_ORDER_ATTACHMENTS

export type TOrderAttachmentUpload = IAttachmentUpload & {
  orderId: number
}

export type TOrderAttachment = TAttachment & {
  orderId: number
}

export interface fetchOrderAttachmentsAction {
  type: typeof FETCH_ORDER_ATTACHMENTS_REQUESTED
  payload: IFetchItemAttachmentsPayload
}

export interface fetchOrderAttachmentsSucceeded {
  type: typeof FETCH_ORDER_ATTACHMENTS_SUCCEEDED
  orderAttachments: TOrderAttachment[]
}

export interface fetchOrderAttachmentsError {
  type: typeof FETCH_ORDER_ATTACHMENTS_ERROR
  error: TNetworkError
}

// UPLOAD_ORDER_ATTACHMENT

export interface uploadOrderAttachmentAction {
  type: typeof UPLOAD_ORDER_ATTACHMENT_REQUESTED
  payload: IUploadSingleAttachmentPayload
}

export interface uploadOrderAttachmentSucceeded {
  type: typeof UPLOAD_ORDER_ATTACHMENT_SUCCEEDED
  attachment: TOrderAttachment
}

export interface uploadOrderAttachmentError {
  type: typeof UPLOAD_ORDER_ATTACHMENT_ERROR
  error: TNetworkError
}

// DELTE_ORDER_ATTACHMENT

export interface deleteOrderAttachmentSucceeded {
  type: typeof DELETE_ORDER_ATTACHMENT_SUCCEEDED
  payload: ISingleItemActionPayload
}

// ORDER_ACTIONS_UNLOCK

export interface orderActionsUnlockAction {
  type: typeof ORDER_ACTIONS_UNLOCK
}

// GENERATE_ORDER_PDF

export interface IGenerateOrderPDFPayload extends TAdminPayload {
  orderPDF: { orderId: number | string }
}

export interface generateOrderPDFAction {
  type: typeof GENERATE_ORDER_PDF_REQUESTED
  payload: IGenerateOrderPDFPayload
}

export interface generateOrderPDFSucceeded {
  type: typeof GENERATE_ORDER_PDF_SUCCEEDED
  generatedOrderPDFInfo: IGeneratedOrderPDFInfo
}

export interface generateOrderPDFError {
  type: typeof GENERATE_ORDER_PDF_ERROR
  error: TNetworkError
}

export interface generateOrderPDFButtonUnlockAction {
  type: typeof UNLOCK_GENERATE_ORDER_PDF_BUTTON
}

export type OrdersActionTypes =
  | fetchOrdersAction
  | fetchOrdersSucceeded
  | fetchOrdersError
  | setOrdersTableFilterState
  | setOrdersTableSorterState
  | fetchSingleOrderAction
  | fetchSingleOrderSucceeded
  | fetchSingleOrderError
  | editOrderAction
  | editOrderSucceeded
  | editOrderError
  | deleteOrderAction
  | deleteOrderSucceeded
  | deleteOrderError
  | fetchOrderAttachmentsAction
  | fetchOrderAttachmentsSucceeded
  | fetchOrderAttachmentsError
  | uploadOrderAttachmentAction
  | uploadOrderAttachmentSucceeded
  | uploadOrderAttachmentError
  | deleteOrderAttachmentSucceeded
  | orderActionsUnlockAction
  | generateOrderPDFAction
  | generateOrderPDFSucceeded
  | generateOrderPDFError
  | generateOrderPDFButtonUnlockAction
