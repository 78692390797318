import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { Spinner } from 'reactstrap'

import { Footer, Header, Sidebar } from '../components'
import Content from '../components/content'
import * as actionTypes from '../store/action-types'
import { TRootState } from '../store/reducers'
import { setupSignalRConnection } from '../store/signalr/signalr-connection'

export type TFormTab = {
  name: string
  title: string
  isEditOnly: boolean
}

export const topLabelsOffsetFromNavigationTabs = '-5px'
export const minimumWidthForWideDetailsView = 1800
export const offlineCurrencyExchangeRates = {
  PLN: 1,
  USD: 4.12,
  EUR: 4.66,
  CNY: 0.564,
  CHF: 4.21,
  JPY: 3.58,
  GBP: 5.53,
}

const connectionHub = `${process.env.REACT_APP_SIGNLAR_URL}/bomelements`

export const Admin: React.FC<{}> = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tenant } = useParams<{ tenant: string }>()
  let location = useLocation()

  const authState = useSelector((state: TRootState) => state.auth)

  const makeDoubleDigit = (num: number) => {
    return num < 10 ? '0' + String(num) : num
  }

  useEffect(() => {
    if (
      (!location.pathname.includes('/boms/edit') &&
        !location.pathname.includes('/boms/create') &&
        !location.pathname.includes('/purchase-plan/') &&
        (location.pathname.includes('/offers/') || !location.pathname.includes('/offers')) &&
        !location.pathname.includes('/warehouse-management/') &&
        !location.pathname.includes('/inventories-of-warehouses') &&
        !location.pathname.includes('/documents-of-warehouses')) ||
      location.pathname.includes('-replenishment')
    ) {
      dispatch({ type: actionTypes.SET_SIDEBAR_VISIBILITY, sidebarVisibility: 'responsive' })
    }
  }, [dispatch, location])

  useEffect(() => {
    if (authState.isAuthDataSaved) {
      if (authState.isAuthenticated === undefined && authState?.authData?.token) {
        dispatch({
          type: actionTypes.AUTHENTICATION_REQUESTED,
          payload: {
            token: authState?.authData?.token,
            tenant: tenant,
          },
        })
      } else if (
        (!authState.isAuthenticationPending && authState.isAuthenticated === false) ||
        !authState?.authData?.token
      ) {
        history.replace(`/${tenant}/login`)
      }
    }

    // Go back to login if there is no internet connection

    // setTimeout(() => {
    //   if (authState.isAuthenticationPending === true && authState.isAuthenticated === undefined) {
    //     history.replace(`/${tenant}/login`)
    //   }
    // }, 30000)
  }, [
    dispatch,
    tenant,
    authState?.authData?.token,
    authState.isAuthDataSaved,
    authState.isAuthenticated,
    authState.isAuthenticationPending,
    history,
  ])

  useEffect(() => {
    if (
      location.pathname.includes('admin/logs') &&
      tenant &&
      authState?.authData &&
      authState?.authData?.roles?.includes('AuditLogs_read')
    ) {
      dispatch({
        type: actionTypes.FETCH_AUDIT_LOGS_REQUESTED,
        payload: { tenant: tenant, token: authState.authData?.token },
      })
    }
  }, [
    dispatch,
    tenant,
    authState.authData,
    authState.isAuthenticated,
    authState.isAuthenticationPending,
    location,
  ])

  useEffect(() => {
    if (
      (location.pathname.includes('admin/warehouse') ||
        location.pathname.includes('admin/order') ||
        location.pathname.includes('admin/inventories-of-warehouses')) &&
      !location.pathname.includes('move-replenishment') &&
      tenant &&
      authState?.authData &&
      authState?.authData?.roles?.includes('Warehouses_read')
    ) {
      dispatch({
        type: actionTypes.FETCH_WAREHOUSES_REQUESTED,
        payload: { tenant: tenant, token: authState.authData?.token },
      })
    }
  }, [
    dispatch,
    tenant,
    authState.authData,
    authState.isAuthenticated,
    authState.isAuthenticationPending,
    location,
  ])

  useEffect(() => {
    if (tenant && authState.isAuthenticated && authState.authData) {
      ;[
        actionTypes.FETCH_SETTINGS_REQUESTED,
        authState?.authData?.roles?.includes('Suppliers_read')
          ? actionTypes.FETCH_SUPPLIERS_REQUESTED
          : '',
        authState?.authData?.roles?.includes('Producers_read')
          ? actionTypes.FETCH_PRODUCERS_REQUESTED
          : '',
        authState?.authData?.roles?.includes('BomElements_read')
          ? actionTypes.FETCH_BOM_ELEMENTS_REQUESTED
          : '',
        authState?.authData?.roles?.includes('Services_read')
          ? actionTypes.FETCH_SERVICES_REQUESTED
          : '',
        authState?.authData?.roles?.includes('ImplementationCosts_read')
          ? actionTypes.FETCH_IMPLEMENTATION_COSTS_REQUESTED
          : '',
        authState?.authData?.roles?.includes('Boms_read') ? actionTypes.FETCH_BOMS_REQUESTED : '',
        authState?.authData?.roles?.includes('Offers_read')
          ? actionTypes.FETCH_OFFERS_REQUESTED
          : '',
        authState?.authData?.roles?.includes('PurchasePlans_read')
          ? actionTypes.FETCH_PURCHASE_PLANS_REQUESTED
          : '',
        authState?.authData?.roles?.includes('Orders_read')
          ? actionTypes.FETCH_ORDERS_REQUESTED
          : '',
        authState?.authData?.roles?.includes('Users_read') ? actionTypes.FETCH_USERS_REQUESTED : '',
        authState?.authData?.roles?.includes('Users_read')
          ? actionTypes.FETCH_PROFILES_REQUESTED
          : '',
      ].forEach((fetchType: string) =>
        fetchType
          ? dispatch({
              type: fetchType,
              payload: { tenant: tenant, token: authState.authData?.token },
            })
          : null
      )

      // Open SignalR connection
      setupSignalRConnection(connectionHub, {}, tenant, authState, dispatch)
    }

    /* This is Currency Caching that works only with currency fetched from Monday until Friday */
    // It will still try to fetch Currency Rates at night around 2 AM or 3 AM, maybe there should be added a time boundary when they upload it on a work day for the first time

    if (localStorage && localStorage.getItem('todayCurrencyExchange')) {
      const storageExchange = JSON.parse(localStorage.getItem('todayCurrencyExchange') || '')
      const today = new Date()
      const storageDate = new Date(storageExchange.date)
      const isWeekendCurrencyFromFriday =
        [6, 0].includes(today.getUTCDay()) && storageDate.getDay() === 5

      if (
        typeof storageExchange === 'object' &&
        (isWeekendCurrencyFromFriday ||
          `${today.getFullYear()}-${makeDoubleDigit(today.getMonth() + 1)}-${makeDoubleDigit(
            today.getUTCDate()
          )}` === storageExchange.date)
      ) {
        dispatch({
          type: actionTypes.FETCH_TODAY_CURRENCY_EXCHANGE_SUCCEEDED,
          todayCurrencyExchange: storageExchange || null,
        })
      } else {
        dispatch({
          type: actionTypes.FETCH_TODAY_CURRENCY_EXCHANGE_REQUESTED,
        })
      }
    } else {
      dispatch({
        type: actionTypes.FETCH_TODAY_CURRENCY_EXCHANGE_REQUESTED,
      })
    }
  }, [dispatch, tenant, authState.isAuthenticated, authState.authData])

  if (authState.isAuthenticated === true) {
    return (
      <div className="c-app c-default-layout">
        <Sidebar />
        <div className="c-wrapper">
          <Header />
          <div className="c-body">
            <Content />
          </div>
          <Footer />
        </div>
      </div>
    )
  } else {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Spinner />
      </div>
    )
  }
}
