import { TCurrencyISO, TCurrencyCode } from '../store/action-types'

export const SimplifiedCurrencies: TCurrencyCode[] = ['CNY', 'EUR', 'GBP', 'PLN', 'USD']

export const ISO4217Currencies: TCurrencyISO[] = [
  // {
  //   code: 'PLZ',
  //   decimalDigits: -1,
  //   englishName: 'Polish zloty A/94',
  //   number: '616',
  //   symbol: 'zł',
  //   isObsolete: true,
  //   majorUnit: 1,
  //   minorUnit: 1,
  //   namespace: 'ISO-4217-HISTORIC',
  //   validFrom: '1950-10-30T00:00:00',
  //   validTo: '1994-12-31T00:00:00',
  // },
  // {
  //   code: 'AED',
  //   decimalDigits: 2,
  //   englishName: 'United Arab Emirates dirham',
  //   number: '784',
  //   symbol: 'د.إ',
  // },
  // {
  //   code: 'AFN',
  //   decimalDigits: 2,
  //   englishName: 'Afghan afghani',
  //   number: '971',
  //   symbol: '؋',
  // },
  // {
  //   code: 'ALL',
  //   decimalDigits: 2,
  //   englishName: 'Albanian lek',
  //   number: '8',
  //   symbol: 'Lek',
  // },
  // {
  //   code: 'AMD',
  //   decimalDigits: 2,
  //   englishName: 'Armenian dram',
  //   number: '51',
  // },
  // {
  //   code: 'ANG',
  //   decimalDigits: 2,
  //   englishName: 'Netherlands Antillean guilder',
  //   number: '532',
  // },
  // {
  //   code: 'AOA',
  //   decimalDigits: 2,
  //   englishName: 'Angolan kwanza',
  //   number: '973',
  // },
  // {
  //   code: 'ARS',
  //   decimalDigits: 2,
  //   englishName: 'Argentine peso',
  //   number: '32',
  // },
  // {
  //   code: 'AUD',
  //   decimalDigits: 2,
  //   englishName: 'Australian dollar',
  //   number: '36',
  // },
  // {
  //   code: 'AWG',
  //   decimalDigits: 2,
  //   englishName: 'Aruban florin',
  //   number: '533',
  // },
  // {
  //   code: 'AZN',
  //   decimalDigits: 2,
  //   englishName: 'Azerbaijani manat',
  //   number: '944',
  // },
  // {
  //   code: 'BAM',
  //   decimalDigits: 2,
  //   englishName: 'Bosnia and Herzegovina convertible mark',
  //   number: '977',
  // },
  // {
  //   code: 'BBD',
  //   decimalDigits: 2,
  //   englishName: 'Barbados dollar',
  //   number: '52',
  // },
  // {
  //   code: 'BDT',
  //   decimalDigits: 2,
  //   englishName: 'Bangladeshi taka',
  //   number: '50',
  // },
  // {
  //   code: 'BGN',
  //   decimalDigits: 2,
  //   englishName: 'Bulgarian lev',
  //   number: '975',
  // },
  // {
  //   code: 'BHD',
  //   decimalDigits: 3,
  //   englishName: 'Bahraini dinar',
  //   number: '48',
  // },
  // {
  //   code: 'BIF',
  //   decimalDigits: 0,
  //   englishName: 'Burundian franc',
  //   number: '108',
  // },
  // // {
  // //   code: 'BMD',
  // //   decimalDigits: 2,
  // //   englishName: 'Bermudian dollar (customarily known as Bermuda dollar)',
  // //   number: '60',
  // // },
  // {
  //   code: 'BND',
  //   decimalDigits: 2,
  //   englishName: 'Brunei dollar',
  //   number: '96',
  // },
  // {
  //   code: 'BOB',
  //   decimalDigits: 2,
  //   englishName: 'Boliviano',
  //   number: '68',
  // },
  // // {
  // //   code: 'BOV',
  // //   decimalDigits: 2,
  // //   englishName: 'Bolivian Mvdol (funds code)',
  // //   number: '984',
  // // },
  // {
  //   code: 'BRL',
  //   decimalDigits: 2,
  //   englishName: 'Brazilian real',
  //   number: '986',
  // },
  // {
  //   code: 'BSD',
  //   decimalDigits: 2,
  //   englishName: 'Bahamian dollar',
  //   number: '44',
  // },
  // // {
  // //   code: 'BTN',
  // //   decimalDigits: 2,
  // //   englishName: 'Bhutanese ngultrum',
  // //   number: '64',
  // // },
  // {
  //   code: 'BWP',
  //   decimalDigits: 2,
  //   englishName: 'Botswana pula',
  //   number: '72',
  // },
  // {
  //   code: 'BYN',
  //   decimalDigits: 0,
  //   englishName: 'Belarusian ruble',
  //   number: '974',
  // },
  // {
  //   code: 'BZD',
  //   decimalDigits: 2,
  //   englishName: 'Belize dollar',
  //   number: '84',
  // },
  // {
  //   code: 'CAD',
  //   decimalDigits: 2,
  //   englishName: 'Canadian dollar',
  //   number: '124',
  // },
  // {
  //   code: 'CDF',
  //   decimalDigits: 2,
  //   englishName: 'Congolese franc',
  //   number: '976',
  // },
  // // {
  // //   code: 'CHE',
  // //   decimalDigits: 2,
  // //   englishName: 'WIR Euro (complementary currency)',
  // //   number: '947',
  // // },
  // {
  //   code: 'CHF',
  //   decimalDigits: 2,
  //   englishName: 'Swiss franc',
  //   number: '756',
  // },
  // // {
  // //   code: 'CHW',
  // //   decimalDigits: 2,
  // //   englishName: 'WIR Franc (complementary currency)',
  // //   number: '948',
  // // },
  // // {
  // //   code: 'CLF',
  // //   decimalDigits: 0,
  // //   englishName: 'Unidad de Fomento (funds code)',
  // //   number: '990',
  // // },
  // {
  //   code: 'CLP',
  //   decimalDigits: 0,
  //   englishName: 'Chilean peso',
  //   number: '152',
  // },
  {
    code: 'CNY',
    decimalDigits: 2,
    englishName: 'Chinese yuan',
    number: '156',
  },
  // {
  //   code: 'COP',
  //   decimalDigits: 2,
  //   englishName: 'Colombian peso',
  //   number: '170',
  // },
  // {
  //   code: 'COU',
  //   decimalDigits: 2,
  //   englishName: 'Unidad de Valor Real',
  //   number: '970',
  // },
  // {
  //   code: 'CRC',
  //   decimalDigits: 2,
  //   englishName: 'Costa Rican colon',
  //   number: '188',
  // },
  // // {
  // //   code: 'CUC',
  // //   decimalDigits: 2,
  // //   englishName: 'Cuban convertible peso',
  // //   number: '931',
  // // },
  // {
  //   code: 'CUP',
  //   decimalDigits: 2,
  //   englishName: 'Cuban peso',
  //   number: '192',
  // },
  // {
  //   code: 'CVE',
  //   decimalDigits: 0,
  //   englishName: 'Cape Verde escudo',
  //   number: '132',
  // },
  // {
  //   code: 'CZK',
  //   decimalDigits: 2,
  //   englishName: 'Czech koruna',
  //   number: '203',
  // },
  // {
  //   code: 'DJF',
  //   decimalDigits: 0,
  //   englishName: 'Djiboutian franc',
  //   number: '262',
  // },
  // {
  //   code: 'DKK',
  //   decimalDigits: 2,
  //   englishName: 'Danish krone',
  //   number: '208',
  // },
  // {
  //   code: 'DOP',
  //   decimalDigits: 2,
  //   englishName: 'Dominican peso',
  //   number: '214',
  // },
  // {
  //   code: 'DZD',
  //   decimalDigits: 2,
  //   englishName: 'Algerian dinar',
  //   number: '12',
  // },
  // {
  //   code: 'EGP',
  //   decimalDigits: 2,
  //   englishName: 'Egyptian pound',
  //   number: '818',
  // },
  // {
  //   code: 'ERN',
  //   decimalDigits: 2,
  //   englishName: 'Eritrean nakfa',
  //   number: '232',
  // },
  // {
  //   code: 'ETB',
  //   decimalDigits: 2,
  //   englishName: 'Ethiopian birr',
  //   number: '230',
  // },
  {
    code: 'EUR',
    decimalDigits: 2,
    englishName: 'Euro',
    number: '978',
  },
  // {
  //   code: 'FJD',
  //   decimalDigits: 2,
  //   englishName: 'Fiji dollar',
  //   number: '242',
  // },
  // // {
  // //   code: 'FKP',
  // //   decimalDigits: 2,
  // //   englishName: 'Falkland Islands pound',
  // //   number: '238',
  // // },
  {
    code: 'GBP',
    decimalDigits: 2,
    englishName: 'Pound sterling',
    number: '826',
  },
  // {
  //   code: 'GEL',
  //   decimalDigits: 2,
  //   englishName: 'Georgian lari',
  //   number: '981',
  // },
  // {
  //   code: 'GHS',
  //   decimalDigits: 2,
  //   englishName: 'Ghanaian cedi',
  //   number: '936',
  // },
  // {
  //   code: 'GIP',
  //   decimalDigits: 2,
  //   englishName: 'Gibraltar pound',
  //   number: '292',
  // },
  // {
  //   code: 'GMD',
  //   decimalDigits: 2,
  //   englishName: 'Gambian dalasi',
  //   number: '270',
  // },
  // {
  //   code: 'GNF',
  //   decimalDigits: 0,
  //   englishName: 'Guinean franc',
  //   number: '324',
  // },
  // {
  //   code: 'GTQ',
  //   decimalDigits: 2,
  //   englishName: 'Guatemalan quetzal',
  //   number: '320',
  // },
  // {
  //   code: 'GYD',
  //   decimalDigits: 2,
  //   englishName: 'Guyanese dollar',
  //   number: '328',
  // },
  // {
  //   code: 'HKD',
  //   decimalDigits: 2,
  //   englishName: 'Hong Kong dollar',
  //   number: '344',
  // },
  // {
  //   code: 'HNL',
  //   decimalDigits: 2,
  //   englishName: 'Honduran lempira',
  //   number: '340',
  // },
  // {
  //   code: 'HRK',
  //   decimalDigits: 2,
  //   englishName: 'Croatian kuna',
  //   number: '191',
  // },
  // {
  //   code: 'HTG',
  //   decimalDigits: 2,
  //   englishName: 'Haitian gourde',
  //   number: '332',
  // },
  // {
  //   code: 'HUF',
  //   decimalDigits: 2,
  //   englishName: 'Hungarian forint',
  //   number: '348',
  // },
  // {
  //   code: 'IDR',
  //   decimalDigits: 2,
  //   englishName: 'Indonesian rupiah',
  //   number: '360',
  // },
  // {
  //   code: 'ILS',
  //   decimalDigits: 2,
  //   englishName: 'Israeli new shekel',
  //   number: '376',
  // },
  // {
  //   code: 'INR',
  //   decimalDigits: 2,
  //   englishName: 'Indian rupee',
  //   number: '356',
  // },
  // {
  //   code: 'IQD',
  //   decimalDigits: 3,
  //   englishName: 'Iraqi dinar',
  //   number: '368',
  // },
  // {
  //   code: 'IRR',
  //   decimalDigits: 0,
  //   englishName: 'Iranian rial',
  //   number: '364',
  // },
  // {
  //   code: 'ISK',
  //   decimalDigits: 0,
  //   englishName: 'Icelandic króna',
  //   number: '352',
  // },
  // {
  //   code: 'JMD',
  //   decimalDigits: 2,
  //   englishName: 'Jamaican dollar',
  //   number: '388',
  // },
  // {
  //   code: 'JOD',
  //   decimalDigits: 3,
  //   englishName: 'Jordanian dinar',
  //   number: '400',
  // },
  // {
  //   code: 'JPY',
  //   decimalDigits: 0,
  //   englishName: 'Japanese yen',
  //   number: '392',
  // },
  // {
  //   code: 'KES',
  //   decimalDigits: 2,
  //   englishName: 'Kenyan shilling',
  //   number: '404',
  // },
  // {
  //   code: 'KGS',
  //   decimalDigits: 2,
  //   englishName: 'Kyrgyzstani som',
  //   number: '417',
  // },
  // {
  //   code: 'KHR',
  //   decimalDigits: 2,
  //   englishName: 'Cambodian riel',
  //   number: '116',
  // },
  // {
  //   code: 'KMF',
  //   decimalDigits: 0,
  //   englishName: 'Comoro franc',
  //   number: '174',
  // },
  // // {
  // //   code: 'KPW',
  // //   decimalDigits: 0,
  // //   englishName: 'North Korean won',
  // //   number: '408',
  // // },
  // {
  //   code: 'KRW',
  //   decimalDigits: 0,
  //   englishName: 'South Korean won',
  //   number: '410',
  // },
  // {
  //   code: 'KWD',
  //   decimalDigits: 3,
  //   englishName: 'Kuwaiti dinar',
  //   number: '414',
  // },
  // {
  //   code: 'KYD',
  //   decimalDigits: 2,
  //   englishName: 'Cayman Islands dollar',
  //   number: '136',
  // },
  // {
  //   code: 'KZT',
  //   decimalDigits: 2,
  //   englishName: 'Kazakhstani tenge',
  //   number: '398',
  // },
  // {
  //   code: 'LAK',
  //   decimalDigits: 0,
  //   englishName: 'Lao kip',
  //   number: '418',
  // },
  // {
  //   code: 'LBP',
  //   decimalDigits: 0,
  //   englishName: 'Lebanese pound',
  //   number: '422',
  // },
  // {
  //   code: 'LKR',
  //   decimalDigits: 2,
  //   englishName: 'Sri Lankan rupee',
  //   number: '144',
  // },
  // {
  //   code: 'LRD',
  //   decimalDigits: 2,
  //   englishName: 'Liberian dollar',
  //   number: '430',
  // },
  // {
  //   code: 'LSL',
  //   decimalDigits: 2,
  //   englishName: 'Lesotho loti',
  //   number: '426',
  // },
  // // {
  // //   code: 'LTL',
  // //   decimalDigits: 2,
  // //   englishName: 'Lithuanian litas',
  // //   number: '440',
  // // },
  // // {
  // //   code: 'LVL',
  // //   decimalDigits: 2,
  // //   englishName: 'Latvian lats',
  // //   number: '428',
  // // },
  // {
  //   code: 'LYD',
  //   decimalDigits: 3,
  //   englishName: 'Libyan dinar',
  //   number: '434',
  // },
  // {
  //   code: 'MAD',
  //   decimalDigits: 2,
  //   englishName: 'Moroccan dirham',
  //   number: '504',
  // },
  // {
  //   code: 'MDL',
  //   decimalDigits: 2,
  //   englishName: 'Moldovan leu',
  //   number: '498',
  // },
  // {
  //   code: 'MGA',
  //   decimalDigits: 0,
  //   englishName: 'Malagasy ariary',
  //   number: '969',
  // },
  // {
  //   code: 'MKD',
  //   decimalDigits: 0,
  //   englishName: 'Macedonian denar',
  //   number: '807',
  // },
  // {
  //   code: 'MMK',
  //   decimalDigits: 0,
  //   englishName: 'Myanma kyat',
  //   number: '104',
  // },
  // {
  //   code: 'MNT',
  //   decimalDigits: 2,
  //   englishName: 'Mongolian tugrik',
  //   number: '496',
  // },
  // {
  //   code: 'MOP',
  //   decimalDigits: 2,
  //   englishName: 'Macanese pataca',
  //   number: '446',
  // },
  // // {
  // //   code: 'MRO',
  // //   decimalDigits: 0,
  // //   englishName: 'Mauritanian ouguiya',
  // //   number: '478',
  // // },
  // {
  //   code: 'MUR',
  //   decimalDigits: 2,
  //   englishName: 'Mauritian rupee',
  //   number: '480',
  // },
  // {
  //   code: 'MVR',
  //   decimalDigits: 2,
  //   englishName: 'Maldivian rufiyaa',
  //   number: '462',
  // },
  // {
  //   code: 'MWK',
  //   decimalDigits: 2,
  //   englishName: 'Malawian kwacha',
  //   number: '454',
  // },
  // {
  //   code: 'MXN',
  //   decimalDigits: 2,
  //   englishName: 'Mexican peso',
  //   number: '484',
  // },
  // // {
  // //   code: 'MXV',
  // //   decimalDigits: 2,
  // //   englishName: 'Mexican Unidad de Inversion (UDI) (funds code)',
  // //   number: '979',
  // // },
  // {
  //   code: 'MYR',
  //   decimalDigits: 2,
  //   englishName: 'Malaysian ringgit',
  //   number: '458',
  // },
  // {
  //   code: 'MZN',
  //   decimalDigits: 2,
  //   englishName: 'Mozambican metical',
  //   number: '943',
  // },
  // {
  //   code: 'NAD',
  //   decimalDigits: 2,
  //   englishName: 'Namibian dollar',
  //   number: '516',
  // },
  // {
  //   code: 'NGN',
  //   decimalDigits: 2,
  //   englishName: 'Nigerian naira',
  //   number: '566',
  // },
  // {
  //   code: 'NIO',
  //   decimalDigits: 2,
  //   englishName: 'Nicaraguan córdoba',
  //   number: '558',
  // },
  // {
  //   code: 'NOK',
  //   decimalDigits: 2,
  //   englishName: 'Norwegian krone',
  //   number: '578',
  // },
  // {
  //   code: 'NPR',
  //   decimalDigits: 2,
  //   englishName: 'Nepalese rupee',
  //   number: '524',
  // },
  // {
  //   code: 'NZD',
  //   decimalDigits: 2,
  //   englishName: 'New Zealand dollar',
  //   number: '554',
  // },
  // {
  //   code: 'OMR',
  //   decimalDigits: 3,
  //   englishName: 'Omani rial',
  //   number: '512',
  // },
  // {
  //   code: 'PAB',
  //   decimalDigits: 2,
  //   englishName: 'Panamanian balboa',
  //   number: '590',
  // },
  // {
  //   code: 'PEN',
  //   decimalDigits: 2,
  //   englishName: 'Peruvian nuevo sol',
  //   number: '604',
  // },
  // {
  //   code: 'PGK',
  //   decimalDigits: 2,
  //   englishName: 'Papua New Guinean kina',
  //   number: '598',
  // },
  // {
  //   code: 'PHP',
  //   decimalDigits: 2,
  //   englishName: 'Philippine peso',
  //   number: '608',
  // },
  // {
  //   code: 'PKR',
  //   decimalDigits: 2,
  //   englishName: 'Pakistani rupee',
  //   number: '586',
  // },
  {
    code: 'PLN',
    decimalDigits: 2,
    englishName: 'Polish złoty',
    number: '985',
  },
  // {
  //   code: 'PYG',
  //   decimalDigits: 0,
  //   englishName: 'Paraguayan guaraní',
  //   number: '600',
  // },
  // {
  //   code: 'QAR',
  //   decimalDigits: 2,
  //   englishName: 'Qatari riyal',
  //   number: '634',
  // },
  // {
  //   code: 'RON',
  //   decimalDigits: 2,
  //   englishName: 'Romanian new leu',
  //   number: '946',
  // },
  // {
  //   code: 'RSD',
  //   decimalDigits: 2,
  //   englishName: 'Serbian dinar',
  //   number: '941',
  // },
  // {
  //   code: 'RUB',
  //   decimalDigits: 2,
  //   englishName: 'Russian rouble',
  //   number: '643',
  // },
  // {
  //   code: 'RWF',
  //   decimalDigits: 0,
  //   englishName: 'Rwandan franc',
  //   number: '646',
  // },
  // {
  //   code: 'SAR',
  //   decimalDigits: 2,
  //   englishName: 'Saudi riyal',
  //   number: '682',
  // },
  // {
  //   code: 'SBD',
  //   decimalDigits: 2,
  //   englishName: 'Solomon Islands dollar',
  //   number: '90',
  // },
  // {
  //   code: 'SCR',
  //   decimalDigits: 2,
  //   englishName: 'Seychelles rupee',
  //   number: '690',
  // },
  // {
  //   code: 'SDG',
  //   decimalDigits: 2,
  //   englishName: 'Sudanese pound',
  //   number: '938',
  // },
  // {
  //   code: 'SEK',
  //   decimalDigits: 2,
  //   englishName: 'Swedish krona/kronor',
  //   number: '752',
  // },
  // {
  //   code: 'SGD',
  //   decimalDigits: 2,
  //   englishName: 'Singapore dollar',
  //   number: '702',
  // },
  // // {
  // //   code: 'SHP',
  // //   decimalDigits: 2,
  // //   englishName: 'Saint Helena pound',
  // //   number: '654',
  // // },
  // {
  //   code: 'SLL',
  //   decimalDigits: 0,
  //   englishName: 'Sierra Leonean leone',
  //   number: '694',
  // },
  // {
  //   code: 'SOS',
  //   decimalDigits: 2,
  //   englishName: 'Somali shilling',
  //   number: '706',
  // },
  // {
  //   code: 'SRD',
  //   decimalDigits: 2,
  //   englishName: 'Surinamese dollar',
  //   number: '968',
  // },
  // {
  //   code: 'SSP',
  //   decimalDigits: 2,
  //   englishName: 'South Sudanese pound',
  //   number: '728',
  // },
  // {
  //   code: 'STN',
  //   decimalDigits: 0,
  //   englishName: 'São Tomé and Príncipe dobra',
  //   number: '678',
  // },
  // {
  //   code: 'SYP',
  //   decimalDigits: 2,
  //   englishName: 'Syrian pound',
  //   number: '760',
  // },
  // {
  //   code: 'SZL',
  //   decimalDigits: 2,
  //   englishName: 'Swazi lilangeni',
  //   number: '748',
  // },
  // {
  //   code: 'THB',
  //   decimalDigits: 2,
  //   englishName: 'Thai baht',
  //   number: '764',
  // },
  // {
  //   code: 'TJS',
  //   decimalDigits: 2,
  //   englishName: 'Tajikistani somoni',
  //   number: '972',
  // },
  // {
  //   code: 'TMT',
  //   decimalDigits: 2,
  //   englishName: 'Turkmenistani manat',
  //   number: '934',
  // },
  // {
  //   code: 'TND',
  //   decimalDigits: 3,
  //   englishName: 'Tunisian dinar',
  //   number: '788',
  // },
  // {
  //   code: 'TOP',
  //   decimalDigits: 2,
  //   englishName: 'Tongan paʻanga',
  //   number: '776',
  // },
  // {
  //   code: 'TRY',
  //   decimalDigits: 2,
  //   englishName: 'Turkish lira',
  //   number: '949',
  // },
  // {
  //   code: 'TTD',
  //   decimalDigits: 2,
  //   englishName: 'Trinidad and Tobago dollar',
  //   number: '780',
  // },
  // {
  //   code: 'TWD',
  //   decimalDigits: 2,
  //   englishName: 'New Taiwan dollar',
  //   number: '901',
  // },
  // {
  //   code: 'TZS',
  //   decimalDigits: 2,
  //   englishName: 'Tanzanian shilling',
  //   number: '834',
  // },
  // {
  //   code: 'UAH',
  //   decimalDigits: 2,
  //   englishName: 'Ukrainian hryvnia',
  //   number: '980',
  // },
  // {
  //   code: 'UGX',
  //   decimalDigits: 2,
  //   englishName: 'Ugandan shilling',
  //   number: '800',
  // },
  {
    code: 'USD',
    decimalDigits: 2,
    englishName: 'United States dollar',
    number: '840',
  },
  // // {
  // //   code: 'USN',
  // //   decimalDigits: 2,
  // //   englishName: 'United States dollar (next day) (funds code)',
  // //   number: '997',
  // // },
  // // {
  // //   code: 'USS',
  // //   decimalDigits: 2,
  // //   englishName: 'United States dollar (same day) (funds code)',
  // //   number: '998',
  // // },
  // // {
  // //   code: 'UYI',
  // //   decimalDigits: 0,
  // //   englishName: 'Uruguay Peso en Unidades Indexadas (URUIURUI) (funds code)',
  // //   number: '940',
  // // },
  // {
  //   code: 'UYU',
  //   decimalDigits: 2,
  //   englishName: 'Uruguayan peso',
  //   number: '858',
  // },
  // {
  //   code: 'UZS',
  //   decimalDigits: 2,
  //   englishName: 'Uzbekistan som',
  //   number: '860',
  // },
  // {
  //   code: 'VES',
  //   decimalDigits: 2,
  //   englishName: 'Venezuelan bolívar fuerte',
  //   number: '937',
  // },
  // {
  //   code: 'VND',
  //   decimalDigits: 0,
  //   englishName: 'Vietnamese dong',
  //   number: '704',
  // },
  // {
  //   code: 'VUV',
  //   decimalDigits: 0,
  //   englishName: 'Vanuatu vatu',
  //   number: '548',
  // },
  // {
  //   code: 'WST',
  //   decimalDigits: 2,
  //   englishName: 'Samoan tala',
  //   number: '882',
  // },
  // {
  //   code: 'XAF',
  //   decimalDigits: 0,
  //   englishName: 'CFA franc BEAC',
  //   number: '950',
  // },
  // //   {
  // //     code: 'XAG',
  // //     decimalDigits: null,
  // //     englishName: 'Silver (one troy ounce)',
  // //     number: '961',
  // //   },
  // //   {
  // //     code: 'XAU',
  // //     decimalDigits: null,
  // //     englishName: 'Gold (one troy ounce)',
  // //     number: '959',
  // //   },
  // //   {
  // //     code: 'XBA',
  // //     decimalDigits: null,
  // //     englishName: 'European Composite Unit (EURCO) (bond market unit)',
  // //     number: '955',
  // //   },
  // //   {
  // //     code: 'XBB',
  // //     decimalDigits: null,
  // //     englishName: 'European Monetary Unit (E.M.U.-6) (bond market unit)',
  // //     number: '956',
  // //   },
  // //   {
  // //     code: 'XBC',
  // //     decimalDigits: null,
  // //     englishName: 'European Unit of Account 9 (E.U.A.-9) (bond market unit)',
  // //     number: '957',
  // //   },
  // //   {
  // //     code: 'XBD',
  // //     decimalDigits: null,
  // //     englishName: 'European Unit of Account 17 (E.U.A.-17) (bond market unit)',
  // //     number: '958',
  // //   },
  // {
  //   code: 'XCD',
  //   decimalDigits: 2,
  //   englishName: 'East Caribbean dollar',
  //   number: '951',
  // },
  // {
  //   code: 'XDR',
  //   decimalDigits: 0,
  //   englishName: 'Special drawing rights',
  //   number: '960',
  // },
  // //   {
  // //     code: 'XFU',
  // //     decimalDigits: null,
  // //     englishName: 'UIC franc (special settlement currency)',
  // //     number: 'Nil',
  // //   },
  // {
  //   code: 'XOF',
  //   decimalDigits: 0,
  //   englishName: 'CFA franc BCEAO',
  //   number: '952',
  // },
  // //   {
  // //     code: 'XPD',
  // //     decimalDigits: null,
  // //     englishName: 'Palladium (one troy ounce)',
  // //     number: '964',
  // //   },
  // {
  //   code: 'XPF',
  //   decimalDigits: 0,
  //   englishName: 'CFP franc',
  //   number: '953',
  // },
  // //   {
  // //     code: 'XPT',
  // //     decimalDigits: null,
  // //     englishName: 'Platinum (one troy ounce)',
  // //     number: '962',
  // //   },
  // //   {
  // //     code: 'XTS',
  // //     decimalDigits: null,
  // //     englishName: 'Code reserved for testing purposes',
  // //     number: '963',
  // //   },
  // //   {
  // //     code: 'XXX',
  // //     decimalDigits: null,
  // //     englishName: 'No currency',
  // //     number: '999',
  // //   },
  // {
  //   code: 'YER',
  //   decimalDigits: 2,
  //   englishName: 'Yemeni rial',
  //   number: '886',
  // },
  // {
  //   code: 'ZAR',
  //   decimalDigits: 2,
  //   englishName: 'South African rand',
  //   number: '710',
  // },
  // {
  //   code: 'ZMW',
  //   decimalDigits: 2,
  //   englishName: 'Zambian kwacha',
  //   number: '967',
  // },
]
