import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { Container, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { CDataTable, CCard, CCardBody, CLabel, CButton } from '@coreui/react'
import { Select, SelectProps } from '@material-ui/core'
import { cilListHighPriority } from '@coreui/icons'
import CIcon from '@coreui/icons-react'

import * as actionTypes from '../../store/action-types'
import { TRootState } from '../../store/reducers'
import {
  convertNumbersInTableRows,
  getEmptyListMessage,
  inputFieldSpacingBottom,
  inputLabelSpacingBottom,
  makeSearchBoxFixed,
} from '../../utils'
import { minimumWidthForWideDetailsView } from '../admin'
import { FormFixedActionsPanel } from '../../components'

export const InventoriesOfWarehouses: React.FC<{}> = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { tenant } = useParams<{ tenant: string }>()

  const authState = useSelector((state: TRootState) => state.auth)
  const warehousesState = useSelector((state: TRootState) => state.warehouses)

  const [currentlyBrowsedWarehouseId, setCurrentlyBrowsedWarehouseId] = useState<null | number>(
    null
  )

  const [currentlyBrowsedWarehouseTypeId, setCurrentlyBrowsedWarehouseTypeId] = useState<
    null | number
  >(null)

  const [inventoriesOfWarehousesFilterState, setInventoriesOfWarehousesFilterState] =
    useState<actionTypes.TFilterState>(undefined)

  const [inventoriesOfWarehousesSorterState, setInventoriesOfWarehousesSorterState] =
    useState<actionTypes.TSorterState>({ column: '', asc: true })

  const memoizedTableItems = useMemo(() => {
    const inventories: actionTypes.IInventoriesOfWarehousesTableItem[] | undefined =
      warehousesState?.inventoriesOfWarehouses?.map(
        (inventoryItem: actionTypes.IInventoriesOfWarehousesDataItem) => {
          return {
            bomElementId: inventoryItem.bomElementId,
            bomElementName: inventoryItem.bomElementName || '',
            bomElementCode: inventoryItem.bomElementSymbolDin || inventoryItem.bomElementMpn || '',

            warehouseId: inventoryItem.warehouseId,
            warehouseName: inventoryItem.warehouseName || '',
            type: inventoryItem.type,
            warehouseTypeName: inventoryItem?.type
              ? actionTypes.warehouseTypesObject[inventoryItem.type]
              : '',

            lastDeliveryDate: inventoryItem?.lastDeliveryDate || '',
            referenceNumber: inventoryItem?.referenceNumber || '',
            warehouseQuantity: inventoryItem?.warehouseQuantity || '',
            bookedPlanned: inventoryItem?.bookedPlanned || '',
            bookedUnplanned: inventoryItem?.bookedUnplanned || '',
            nextDeliveryDate: inventoryItem?.nextDeliveryDate || '',
            nextDeliveryValue: inventoryItem?.nextDeliveryValue || '',
            productName: inventoryItem?.bomInfos?.join(', ') || '',
          }
        }
      )

    if (currentlyBrowsedWarehouseId || currentlyBrowsedWarehouseTypeId) {
      return inventories?.filter(
        (inventoryItem: actionTypes.IInventoriesOfWarehousesTableItem) =>
          (currentlyBrowsedWarehouseId
            ? inventoryItem.warehouseId === currentlyBrowsedWarehouseId
            : true) &&
          (currentlyBrowsedWarehouseTypeId
            ? inventoryItem.type === currentlyBrowsedWarehouseTypeId
            : true)
      )
    } else {
      return inventories
    }
  }, [
    warehousesState?.inventoriesOfWarehouses,
    currentlyBrowsedWarehouseId,
    currentlyBrowsedWarehouseTypeId,
  ])

  const closeInventoriesOfWarehouses = useCallback(() => {
    if (history.length) {
      history.goBack()
    } else {
      history.push(`/${tenant}/admin/warehouses`)
    }
  }, [history, tenant])

  // Fetch inventory control data
  useEffect(() => {
    if (
      location.pathname.includes(`inventories-of-warehouses`) &&
      tenant &&
      authState?.authData &&
      authState?.authData?.roles?.includes('Warehouses_read')
    ) {
      dispatch({
        type: actionTypes.FETCH_INVENTORIES_OF_WAREHOUSES_REQUESTED,
        payload: { tenant: tenant, token: authState.authData?.token },
      })
    }
  }, [
    dispatch,
    tenant,
    authState.authData,
    authState.isAuthenticated,
    authState.isAuthenticationPending,
    location,
  ])

  // Hide sidebar on smaller screens
  useEffect(() => {
    if (window.innerWidth < minimumWidthForWideDetailsView) {
      dispatch({ type: actionTypes.SET_SIDEBAR_VISIBILITY, sidebarVisibility: false })
    }
  }, [dispatch])

  // Here the numbers are converted in table on each state change of the table
  useEffect(() => {
    convertNumbersInTableRows(warehousesState.inventoriesOfWarehouses, [], [4, 5, 6, 8])
  }, [memoizedTableItems, inventoriesOfWarehousesFilterState, inventoriesOfWarehousesSorterState])

  // Make search box fixed
  useEffect(() => {
    makeSearchBoxFixed()
  }, [makeSearchBoxFixed])

  return (
    <Container
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ maxWidth: '1600px' }}
    >
      <CCard>
        <CCardBody className="p-4">
          {authState?.authData?.roles?.includes('Warehouses_write') && (
            <FormFixedActionsPanel
              title={<></>}
              mode={'edit'}
              section="inventories"
              isSaving={false}
              isSaved={false}
              isDeleting={false}
              isDeleted={false}
              didFormValidationOccur={false}
              formErrorsBool={false}
              closeAction={''}
              deleteAction={''}
              deletePayload={null}
              closeFunction={closeInventoriesOfWarehouses}
              setDidFormValidationOccur={null}
              canDelete={false}
              isEditable={false}
              disabledDeleteButtonClassNames=""
              canSave={false}
              confirmDeleteMessageJSX={<></>}
            />
          )}

          <div className="d-flex justify-content-start">
            <div style={{ maxWidth: '260px' }}>
              <CLabel
                htmlFor="inventories-warehouse-id-selector"
                className={`${inputLabelSpacingBottom}`}
              >
                Magazyn
              </CLabel>
              <InputGroup
                id="inventories-warehouse-id-selector"
                className={`${inputFieldSpacingBottom} dropdown-selector`}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="cil-storage"></i>
                  </InputGroupText>
                </InputGroupAddon>

                <Select
                  variant="outlined"
                  native
                  value={currentlyBrowsedWarehouseId || ''}
                  className="item-selector warehouse-selector"
                  style={{ minWidth: '220px' }}
                  disabled={!warehousesState?.warehouses}
                  onChange={(event: ChangeEvent<SelectProps>) => {
                    setCurrentlyBrowsedWarehouseId(
                      event?.target?.value ? Number(event?.target?.value) : null
                    )

                    return event
                  }}
                >
                  <option value={''}>Wszystkie</option>
                  {warehousesState?.warehouses?.map(
                    (warehouse: actionTypes.TWarehouseLight, warehouseIndex: number) => {
                      return (
                        <option
                          key={`warehouse-select-option-${warehouse.id}-${warehouseIndex}`}
                          value={warehouse.id}
                        >
                          {warehouse?.name || '...'}
                        </option>
                      )
                    }
                  )}
                </Select>
              </InputGroup>
            </div>

            <div className="pl-4" style={{ maxWidth: '260px' }}>
              <CLabel
                htmlFor="inventories-type-id-selector"
                className={`${inputLabelSpacingBottom}`}
              >
                Typ
              </CLabel>
              <InputGroup
                id="inventories-type-id-selector"
                className={`${inputFieldSpacingBottom} dropdown-selector`}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="cil-inbox"></i>
                  </InputGroupText>
                </InputGroupAddon>

                <Select
                  variant="outlined"
                  native
                  value={currentlyBrowsedWarehouseTypeId || ''}
                  className="item-selector warehouse-selector"
                  style={{ minWidth: '220px' }}
                  onChange={(event: ChangeEvent<SelectProps>) => {
                    setCurrentlyBrowsedWarehouseTypeId(
                      event?.target?.value ? Number(event?.target?.value) : null
                    )

                    return event
                  }}
                >
                  <option value={''}>Wszystkie</option>
                  <option value={actionTypes.materialsTypeNumber}>
                    {actionTypes.warehouseTypesObject[actionTypes.materialsTypeNumber]}
                  </option>
                  <option value={actionTypes.waresTypeNumber}>
                    {actionTypes.warehouseTypesObject[actionTypes.waresTypeNumber]}
                  </option>
                  <option value={actionTypes.finishedProductsTypeNumber}>
                    {actionTypes.warehouseTypesObject[actionTypes.finishedProductsTypeNumber]}
                  </option>
                </Select>
              </InputGroup>
            </div>

            <div className="inventories-of-warehouses-reset-filters-button-wrapper">
              <CButton
                color="primary"
                variant="outline"
                className="ml-3 inventories-of-warehouses-reset-filters-button"
                type="button"
                disabled={!currentlyBrowsedWarehouseId && !currentlyBrowsedWarehouseTypeId}
                onClick={() => {
                  setCurrentlyBrowsedWarehouseId(null)
                  setCurrentlyBrowsedWarehouseTypeId(null)
                  setInventoriesOfWarehousesFilterState(undefined)
                }}
              >
                <CIcon icon={cilListHighPriority} />
              </CButton>
            </div>
          </div>

          <CDataTable
            tableFilterValue={inventoriesOfWarehousesFilterState}
            sorterValue={inventoriesOfWarehousesSorterState}
            onSorterValueChange={(sorterState: actionTypes.TSorterState) => {
              setInventoriesOfWarehousesSorterState(sorterState)
            }}
            onTableFilterChange={(tableFilterState: string) => {
              setInventoriesOfWarehousesFilterState(tableFilterState)
            }}
            loading={warehousesState?.areInventoriesOfWarehousesLoading}
            striped={!!warehousesState?.inventoriesOfWarehouses?.length}
            border
            sorter
            tableFilter={{ label: ' ', placeholder: 'Szukaj w tabeli...' }}
            addTableClasses="vertical-middle-list-table inventories-of-warehouses-table my-1"
            cleaner
            onRowClick={undefined}
            fields={[
              {
                key: 'bomElementCode',
                label: 'Symbol elementu',
                _style: { width: '200px', textAlign: 'left' },
              },
              {
                key: 'bomElementName',
                label: 'Nazwa elementu',
                _style: { width: '250px' },
              },
              {
                key: 'warehouseName',
                label: 'Magazyn',
                _style: { width: '120px', textAlign: 'left' },
              },
              {
                key: 'warehouseTypeName',
                label: 'Typ',
                _style: { width: '100px', textAlign: 'left' },
              },
              {
                key: 'warehouseQuantity',
                label: 'Stan',
                _style: { width: '70px', textAlign: 'left' },
              },
              {
                key: 'bookedPlanned',
                label: 'Brak \n niezaplan.',
                _style: { width: '70px', textAlign: 'left' },
              },
              {
                key: 'bookedUnplanned',
                label: 'Brak \n zaplan.',
                _style: { width: '70px', textAlign: 'left' },
              },
              {
                key: 'nextDeliveryDate',
                label: 'Data najbliższej dostawy',
                _style: { width: '100px', textAlign: 'left' },
              },
              {
                key: 'nextDeliveryValue',
                label: 'Spodziewana ilość',
                _style: { width: '70px', textAlign: 'left' },
              },
              {
                key: 'lastDeliveryDate',
                label: 'Data ostatniej dostawy',
                _style: { width: '100px', textAlign: 'left' },
              },
              {
                key: 'referenceNumber',
                label: 'Dokument WZ',
                _style: { width: '1fr', textAlign: 'left' },
              },
              {
                key: 'productName',
                label: 'Produkt',
                _style: { width: '100px', textAlign: 'left' },
              },
            ]}
            items={memoizedTableItems}
            noItemsViewSlot={
              <div className="no-items-in-table">
                {currentlyBrowsedWarehouseId &&
                currentlyBrowsedWarehouseTypeId &&
                !inventoriesOfWarehousesFilterState
                  ? 'Brak wyników dla wybranego typu w wybranym magazynie!'
                  : currentlyBrowsedWarehouseId && !inventoriesOfWarehousesFilterState
                  ? 'Brak wyników w wybranym magazynie!'
                  : currentlyBrowsedWarehouseTypeId && !inventoriesOfWarehousesFilterState
                  ? 'Brak wyników dla wybranego typu!'
                  : getEmptyListMessage(
                      warehousesState.fetchInventoriesOfWarehousesError,
                      warehousesState.areInventoriesOfWarehousesLoading,
                      'stanów magazynowych',
                      warehousesState?.inventoriesOfWarehouses?.length
                    )}
              </div>
            }
          />
        </CCardBody>
      </CCard>
    </Container>
  )
}
