import { TAdminPayload, TNetworkError } from '.'
import { TCurrencyCode } from './boms'

export const FETCH_SETTINGS_REQUESTED = 'FETCH_SETTINGS_REQUESTED'
export const FETCH_SETTINGS_SUCCEEDED = 'FETCH_SETTINGS_SUCCEEDED'
export const FETCH_SETTINGS_ERROR = 'FETCH_SETTINGS_ERROR'

export const SAVE_SETTINGS_REQUESTED = 'SAVE_SETTINGS_REQUESTED'
export const SAVE_SETTINGS_SUCCEEDED = 'SAVE_SETTINGS_SUCCEEDED'
export const SAVE_SETTINGS_ERROR = 'SAVE_SETTINGS_ERROR'

export const SETTINGS_ACTIONS_UNLOCK = 'SETTINGS_ACTIONS_UNLOCK'

export interface ISettings {
  companySettings?: ICompanySettings
  financeSettings?: IFinanceSettings
  otherSettings?: IOtherSettings
}

export interface ICompanySettings {
  name: string
  nip: string
  regon: string
  krs: string
  euVatId: string
  eori: string
  addresses: TAddressSetting[]
}

export type TAddressSetting = {
  name: string
  address1: string
  address2: string
  city: string
  post: string
  country: string
  uuid: string
}

export interface IFinanceSettings {
  bankAccounts: TBankAccountSetting[]
}

export type TBankAccountSetting = {
  currencyCode: TCurrencyCode
  iban: string
  swiftCode: string
  routingNumber: string
  bankName: string
  bankBranchName: string
  bankAddress1: string
  bankAddress2: string
  bankPost: string
  bankCity: string
  bankCountry: string
  uuid: string
}

export interface IOtherSettings {
  defaultSystemCurrency: TCurrencyCode
  timeZone?: string
}

export interface ISaveSettingsPayload extends TAdminPayload {
  settings: ISettings
}

// FETCH_SETTINGS

export interface fetchSettingsAction {
  type: typeof FETCH_SETTINGS_REQUESTED
  payload: ISettings
}

export interface fetchSettingsSucceeded {
  type: typeof FETCH_SETTINGS_SUCCEEDED
  settings: ISettings
}

export interface fetchSettingsError {
  type: typeof FETCH_SETTINGS_ERROR
  error: TNetworkError
}

export interface settingsActionsUnlockAction {
  type: typeof SETTINGS_ACTIONS_UNLOCK
}

// SAVE_SETTINGS

export interface saveSettingsAction {
  type: typeof SAVE_SETTINGS_REQUESTED
  payload: ISaveSettingsPayload
}

export interface saveSettingsSucceeded {
  type: typeof SAVE_SETTINGS_SUCCEEDED
  settings: ISettings
}

export interface saveSettingsError {
  type: typeof SAVE_SETTINGS_ERROR
  error: TNetworkError
}

export type SettingsActionTypes =
  | fetchSettingsAction
  | fetchSettingsSucceeded
  | fetchSettingsError
  | saveSettingsAction
  | saveSettingsSucceeded
  | saveSettingsError
  | settingsActionsUnlockAction
