import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'

import { Login } from './views/auth/login'
import { Logout } from './views/auth/logout'
import { RequestPasswordReset } from './views/auth/request-password-reset'
import { PasswordReset } from './views/auth/password-reset'
import { Admin } from './views/admin'
import { App404Page } from './components'
import * as actionTypes from './store/action-types'

import './app.scss'

function App() {
  const dispatch = useDispatch()

  useEffect(() => {
    const storedAuthData = localStorage.getItem('polysystemAuthData')

    dispatch({
      type: actionTypes.SAVE_AUTH_DATA,
      authData: storedAuthData ? JSON.parse(storedAuthData || '"test": "1"') : null,
    })
  }, [dispatch])

  return (
    <BrowserRouter basename="client">
      <Switch>
        <Route path="/:tenant/admin" component={Admin} />

        <Route exact path="/:tenant/login" component={Login} />

        <Route exact path="/:tenant/logout" component={Logout} />

        <Route exact path="/:tenant/request-password-reset" component={RequestPasswordReset} />

        <Route exact path="/:tenant/password-reset/:id" component={PasswordReset} />

        <Route
          path="/:tenant/admin/settings"
          render={(props) => (
            <Redirect to={`/${props.match.params.tenant}/admin/settings/company`} />
          )}
        />

        <Route
          exact
          path="/:tenant"
          render={(props) => <Redirect to={`/${props.match.params.tenant}/admin/suppliers`} />}
        />

        <Route exact path="/" render={() => <Redirect to="/polyend/admin/suppliers" />} />

        <Route component={App404Page} />
      </Switch>
    </BrowserRouter>
  )
}

export default App
