import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { Container, InputGroup, InputGroupAddon, InputGroupText, Spinner } from 'reactstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import { Formik, Form, ErrorMessage, FieldArray, FormikErrors } from 'formik'
import * as Yup from 'yup'
import { CCard, CCardBody, CButton } from '@coreui/react'
import deepEqual from 'deep-equal'
import { v4 as uuidv4 } from 'uuid'

import * as actionTypes from '../../store/action-types'
import { TRootState } from '../../store/reducers'
import { FormActionsPanel, CustomErrorMessage, ThreeDots } from '../../components'
import {
  getErrorMessageFromStatus,
  preventNavigationChange,
  successMessageDuration,
} from '../../utils'
import { BasicFormField } from '../../components'
import { TUserProfile } from '../../store/action-types'

export const UserDetails: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tenant, id, mode } = useParams<{
    tenant: string
    id: string
    tab: string
    mode: actionTypes.TFormMode
  }>()
  const paramsUserUuid = id

  const [didFormValidationOccur, setDidFormValidationOccur] = useState(false)

  const authState = useSelector((state: TRootState) => state.auth)
  const usersState = useSelector((state: TRootState) => state.users)
  const [isUserModifiedAndUnsaved, setIsUserModifiedAndUnsaved] = useState<boolean>(false)

  const userProfilesInUserRefs = useRef([])

  const isThereNetworkError =
    usersState?.userCreateError?.status ||
    usersState?.userEditSaveError?.status ||
    usersState?.userDeleteError?.status ||
    usersState?.singleUserFetchError?.status

  const isUserEditable =
    authState?.authData?.roles?.includes('Users_write') && !usersState.singleUserFetchError

  const closeUserDetails = useCallback(
    (returnsToList: boolean) => {
      if (returnsToList) {
        history.push(`/${tenant}/admin/users`)
      }

      dispatch({ type: actionTypes.CLOSE_USER_DETAILS })
    },
    [history, tenant, dispatch]
  )

  // Fetch data for User edit and clean store for User create
  useEffect(() => {
    if (tenant && authState.authData) {
      if (paramsUserUuid && mode === 'edit') {
        dispatch({
          type: actionTypes.FETCH_SINGLE_USER_REQUESTED,
          payload: { tenant: tenant, token: authState.authData?.token, id: paramsUserUuid },
        })
      } else if (mode === 'create') {
        dispatch({
          type: actionTypes.CLOSE_USER_DETAILS,
        })
      }
    }
  }, [dispatch, tenant, authState, paramsUserUuid, mode])

  // Redirect on not found editable user
  useEffect(() => {
    if (
      (mode !== 'create' && mode !== 'edit') ||
      (usersState?.singleUserFetchError && usersState?.singleUserFetchError?.status === 404)
    ) {
      history.replace(`/${tenant}/admin/users`)
    }
  }, [dispatch, usersState.singleUserFetchError, history, tenant, mode])

  // Close the view on a successful user create or delete
  useEffect(() => {
    if (
      (!usersState.isUserCreating && !usersState.userCreateError && usersState.isUserCreated) ||
      (!usersState.isUserDeleting && !usersState.userDeleteError && usersState.isUserDeleted)
    ) {
      setTimeout(() => {
        closeUserDetails(true)
      }, successMessageDuration)
    }
  }, [usersState.isUserCreating, usersState.isUserDeleted, closeUserDetails])

  // Track the changes again
  useEffect(() => {
    if (usersState.isUserEditSaved || usersState.isUserCreated || usersState.isUserDeleted) {
      setIsUserModifiedAndUnsaved(false)
    }
  }, [usersState.isUserEditSaved, usersState.isUserCreated, usersState.isUserDeleted])

  // Prevent navigation back and forth plus reload if modified
  useEffect(() => {
    preventNavigationChange(
      history,
      isUserModifiedAndUnsaved,
      isUserEditable,
      'users',
      paramsUserUuid,
      mode
    )

    // Without pathname in location there is no tab change detection
  }, [location.pathname, history, isUserModifiedAndUnsaved, isUserEditable])

  // Unmount Component
  useEffect(() => {
    return () => {
      closeUserDetails(false)
    }
  }, [])

  // Validation Schema
  const UserSchema = Yup.object().shape({
    userName: Yup.string()
      .required('To pole jest wymagane!')
      .email('Nieprawidłowy format adresu e-mail!'),
    userProfiles: Yup.array().of(
      Yup.object().shape({
        userProfileUuid: Yup.string().required(
          !usersState.profiles.length
            ? 'Musisz stworzyć minimum 1 profil!'
            : 'Wybierz profil z listy!'
        ),
      })
    ),
  })

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center">
      <CCard>
        <CCardBody className="user-details">
          <h4 className={`text-center ${isThereNetworkError ? 'mb-2' : 'mb-4'}`}>
            {!usersState.isSingleUserLoading &&
              (mode === 'edit'
                ? !authState?.authData?.roles?.includes('Users_write')
                  ? 'Przeglądaj Użytkownika'
                  : 'Edytuj Użytkownika'
                : 'Nowy Użytkownik')}
            {usersState?.isSingleUserLoading &&
              `Użytkownik${usersState?.isSingleUserLoading ? <ThreeDots /> : ''}`}
          </h4>

          {usersState.isSingleUserLoading ? (
            <div
              style={{
                height: '400px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Spinner />
            </div>
          ) : (
            <Formik
              initialValues={{
                userName: usersState?.editedUser?.userName || '',
                firstName: usersState?.editedUser?.firstName || '',
                lastName: usersState?.editedUser?.lastName || '',
                userProfiles: usersState?.editedUser?.userProfiles || [
                  { name: '', userProfileUuid: '', uuid: uuidv4() },
                ],
                userId: usersState?.editedUser?.userId || uuidv4(),
              }}
              validationSchema={UserSchema}
              onSubmit={(values) => {
                if (isUserEditable) {
                  setDidFormValidationOccur(true)

                  dispatch({
                    type:
                      mode === 'create'
                        ? actionTypes.CREATE_USER_REQUESTED
                        : actionTypes.EDIT_USER_REQUESTED,
                    payload: {
                      tenant: tenant,
                      token: authState.authData?.token,
                      user: values,
                    },
                  })
                }
              }}
              enableReinitialize={false}
              validateOnBlur={false}
              validateOnChange={didFormValidationOccur}
            >
              {({ initialValues, values, errors, resetForm, setFieldValue }) => (
                <Form
                  onChange={() => {
                    // Values here are always 1 step behind
                    let isModified = !deepEqual(values, initialValues, { strict: false })

                    if (isModified) {
                      /* If form is brought to its initial state then it is not modified */
                      setIsUserModifiedAndUnsaved(true)
                    } else {
                      setIsUserModifiedAndUnsaved(false)
                    }
                  }}
                >
                  {/*
                   * Display Network Error Message
                   */}

                  {usersState?.userCreateError && (
                    <CustomErrorMessage
                      wrapperClassNames="my-3"
                      customErrorMessageText={getErrorMessageFromStatus(
                        'create',
                        usersState?.userCreateError?.status,
                        'danych użytkownika'
                      )}
                    />
                  )}

                  {usersState?.singleUserFetchError && (
                    <CustomErrorMessage
                      wrapperClassNames="my-3"
                      customErrorMessageText={getErrorMessageFromStatus(
                        'fetch',
                        usersState?.singleUserFetchError?.status,
                        'użytkownika'
                      )}
                    />
                  )}

                  {usersState?.userEditSaveError && (
                    <CustomErrorMessage
                      wrapperClassNames="my-3"
                      customErrorMessageText={getErrorMessageFromStatus(
                        'edit',
                        usersState?.userEditSaveError?.status,
                        'użytkownika'
                      )}
                    />
                  )}

                  {usersState?.userDeleteError && (
                    <CustomErrorMessage
                      wrapperClassNames="my-3"
                      customErrorMessageText={getErrorMessageFromStatus(
                        'delete',
                        usersState?.userDeleteError?.status,
                        'użytkownika'
                      )}
                    />
                  )}

                  <BasicFormField
                    fieldId="user-username"
                    fieldLabel="Polyend ID"
                    fieldIcon="cil-contact"
                    formikFieldName="userName"
                    fieldValue={values.userName}
                    fieldError={errors.userName}
                    fieldType="text"
                    placeholder="Wprowadź adres e-mail"
                  />

                  <BasicFormField
                    fieldId="user-first-name"
                    fieldLabel="Imię"
                    fieldIcon="cil-short-text"
                    formikFieldName="firstName"
                    fieldValue={values.firstName}
                    fieldError={errors.firstName}
                    fieldType="text"
                    placeholder="Opcjonalnie wprowadź imię użytkownika"
                  />

                  <BasicFormField
                    fieldId="user-last-name"
                    fieldLabel="Nazwisko"
                    fieldIcon="cil-short-text"
                    formikFieldName="lastName"
                    fieldValue={values.lastName}
                    fieldError={errors.lastName}
                    fieldType="text"
                    placeholder="Opcjonalnie wprowadź nazwisko użytkownika"
                  />

                  <p className="mt-3 mb-1">Profile</p>
                  <FieldArray
                    name="userProfiles"
                    render={(arrayHelpers) => (
                      <div>
                        {values?.userProfiles?.map(
                          (profile: actionTypes.TLightUserProfile, profileIndex: number) => {
                            return (
                              <div
                                className={`user-details-profiles-double-grid mb-3 ${
                                  profileIndex > 0 && 'pt-1'
                                }`}
                                key={`user-profiles-key-${profileIndex}`}
                              >
                                <div className="basic-form-field-wrapper">
                                  <InputGroup id={`user-profiles-${profileIndex}`}>
                                    <InputGroupAddon addonType="prepend">
                                      <InputGroupText
                                        className={
                                          errors?.userProfiles &&
                                          (
                                            errors?.userProfiles[
                                              profileIndex
                                            ] as FormikErrors<actionTypes.TLightUserProfile>
                                          )?.userProfileUuid
                                            ? 'text-danger input-error-icon'
                                            : ''
                                        }
                                      >
                                        <i className="cil-badge"></i>
                                      </InputGroupText>
                                    </InputGroupAddon>
                                    <ErrorMessage
                                      name={`userProfiles.${profileIndex}.userProfileUuid`}
                                      component="span"
                                      className="text-danger input-error-message"
                                    />
                                    <Typeahead
                                      placeholder={'Wpisz nazwę profilu lub wybierz z listy'}
                                      isInvalid={
                                        !!(
                                          errors?.userProfiles &&
                                          (
                                            errors?.userProfiles[
                                              profileIndex
                                            ] as FormikErrors<actionTypes.TLightUserProfile>
                                          )?.userProfileUuid
                                        )
                                      }
                                      id={`user-profiles-${profileIndex}-typeahead`}
                                      onChange={(selected: TUserProfile[]) => {
                                        if (selected.length > 0) {
                                          setFieldValue(`userProfiles.${profileIndex}`, {
                                            name: selected[0]?.name || '',
                                            userProfileUuid: selected[0]?.uuid || '',
                                          })
                                        }
                                      }}
                                      onInputChange={(text: string) => {
                                        if (text.length > 0) {
                                          const matchedProfile = usersState.profiles.find(
                                            (profile: actionTypes.TUserProfile) =>
                                              profile.name.toLowerCase() === text.toLowerCase()
                                          )

                                          let profileRef: any =
                                            userProfilesInUserRefs.current[profileIndex]

                                          if (matchedProfile) {
                                            setFieldValue(`userProfiles.${profileIndex}`, {
                                              name: matchedProfile?.name || '',
                                              userProfileUuid: matchedProfile?.uuid || '',
                                            })

                                            if (profileRef?.hideMenu) {
                                              profileRef.hideMenu()
                                            }
                                          } else {
                                            setFieldValue(`userProfiles.${profileIndex}.name`, text)
                                            setFieldValue(
                                              `userProfiles.${profileIndex}.userProfileUuid`,
                                              ''
                                            )
                                          }
                                        } else {
                                          setFieldValue(`userProfiles.${profileIndex}.name`, '')
                                          setFieldValue(
                                            `userProfiles.${profileIndex}.userProfileUuid`,
                                            ''
                                          )
                                        }
                                      }}
                                      defaultInputValue={profile?.name || ''}
                                      options={usersState?.profiles.filter(
                                        (profileFromState: actionTypes.TUserProfile) => {
                                          if (values?.userProfiles) {
                                            return !values?.userProfiles?.find(
                                              (profileInUser: actionTypes.TLightUserProfile) =>
                                                profileInUser?.name === profileFromState?.name
                                            )
                                          } else {
                                            return false
                                          }
                                        }
                                      )}
                                      labelKey="name"
                                      emptyLabel={
                                        values?.userProfiles?.length ===
                                        usersState?.profiles?.length
                                          ? 'Wykorzystano wszystkie profile'
                                          : 'Brak profili do wyboru'
                                      }
                                      ref={(HTMLElement: never) => {
                                        userProfilesInUserRefs.current[profileIndex] = HTMLElement
                                      }}
                                    />
                                  </InputGroup>
                                </div>

                                <CButton
                                  color="danger"
                                  type="button"
                                  variant="outline"
                                  onClick={() => {
                                    if (values?.userProfiles) {
                                      for (
                                        let i = profileIndex;
                                        i < values?.userProfiles?.length;
                                        i++
                                      ) {
                                        const userProfileTypeahead: any =
                                          userProfilesInUserRefs?.current[i]

                                        const userProfileTypeaheadNext: any =
                                          userProfilesInUserRefs?.current[i + 1]

                                        if (userProfileTypeahead && userProfileTypeaheadNext) {
                                          userProfileTypeahead.inputNode.value =
                                            userProfileTypeaheadNext?.inputNode?.value
                                              ? String(userProfileTypeaheadNext?.inputNode?.value)
                                              : ''

                                          userProfileTypeahead.state.text = userProfileTypeaheadNext
                                            ?.state?.text
                                            ? String(userProfileTypeaheadNext?.state?.text)
                                            : ''

                                          // This is very important to keep the order of deleted elements
                                          userProfileTypeahead.state.selected =
                                            userProfileTypeaheadNext?.state?.selected
                                        }

                                        if (i + 1 === values.userProfiles.length) {
                                          arrayHelpers.remove(profileIndex)
                                        }
                                      }
                                    }
                                  }}
                                  className="select-option-remove-button"
                                  disabled={
                                    values?.userProfiles ? values?.userProfiles?.length < 2 : false
                                  }
                                >
                                  <i className="cil-trash"></i>
                                </CButton>
                              </div>
                            )
                          }
                        )}

                        <div className="d-flex justify-content-center mt-4 mb-3">
                          {!usersState.profiles.length ? (
                            <CButton
                              color="info"
                              type="button"
                              variant=""
                              onClick={() => {
                                history.push(`/${tenant}/admin/profiles`)
                              }}
                              disabled={!!usersState.profiles.length}
                              className="px-4 mt-2"
                            >
                              Przejdź do tworzenia profili
                            </CButton>
                          ) : (
                            <CButton
                              color="info"
                              type="button"
                              variant="outline"
                              onClick={() => {
                                let newProfileInUser: actionTypes.TLightUserProfile = {
                                  name: '',
                                  userProfileUuid: '',
                                  uuid: uuidv4(),
                                }

                                arrayHelpers.push(newProfileInUser)
                              }}
                              disabled={
                                usersState.isSingleUserLoading ||
                                usersState.isUserDeleting ||
                                usersState.isUserCreating ||
                                usersState.isUserEditSaving ||
                                !usersState.profiles.length ||
                                values.userProfiles.length >= usersState.profiles.length
                              }
                              className="px-4"
                            >
                              Dodaj profil
                            </CButton>
                          )}
                        </div>
                      </div>
                    )}
                  />

                  <FormActionsPanel
                    mode={mode}
                    padding="pt-4 pb-1"
                    isSaving={
                      mode === 'create' ? usersState.isUserCreating : usersState.isUserEditSaving
                    }
                    isSaved={
                      mode === 'create' ? usersState.isUserCreated : usersState.isUserEditSaved
                    }
                    isDeleting={usersState.isUserDeleting}
                    isDeleted={usersState.isUserDeleted}
                    setDidFormValidationOccur={setDidFormValidationOccur}
                    didFormValidationOccur={didFormValidationOccur}
                    formErrorsBool={false}
                    closeAction={actionTypes.CLOSE_USER_DETAILS}
                    deleteAction={actionTypes.DELETE_USER_REQUESTED}
                    deletePayload={{
                      tenant: tenant,
                      token: authState.authData?.token,
                      id: undefined,
                      userId: paramsUserUuid,
                    }}
                    closeFunction={() => closeUserDetails(true)}
                    isEditable={isUserEditable}
                    canDelete={true}
                    disabledDeleteButtonClassNames="delete-details-button"
                    confirmDeleteMessageJSX={
                      <>
                        Czy na pewno chcesz usunąć użytkownika?
                        {initialValues?.userName ? (
                          <>
                            <br />
                            <strong>{initialValues?.userName}</strong>
                          </>
                        ) : (
                          ''
                        )}
                      </>
                    }
                  />
                </Form>
              )}
            </Formik>
          )}
        </CCardBody>
      </CCard>
    </Container>
  )
}
