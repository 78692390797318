import { TAdminPayload, TFilterState, TNetworkError, TSorterState } from '.'

export const FETCH_AUDIT_LOGS_REQUESTED = 'FETCH_AUDIT_LOGS_REQUESTED'
export const FETCH_AUDIT_LOGS_SUCCEEDED = 'FETCH_AUDIT_LOGS_SUCCEEDED'
export const FETCH_AUDIT_LOGS_ERROR = 'FETCH_AUDIT_LOGS_ERROR'

export const SET_AUDIT_LOGS_TABLE_FILTER_STATE = 'SET_AUDIT_LOGS_TABLE_FILTER_STATE'
export const SET_AUDIT_LOGS_TABLE_SORTER_STATE = 'SET_AUDIT_LOGS_TABLE_SORTER_STATE'

export type TAuditLog = {
  clientIp: string
  date: string
  id: number
  info: string
  user: null | any
}

// FETCH_AUDIT_LOGS_REQUESTED

export interface fetchAuditLogsAction {
  type: typeof FETCH_AUDIT_LOGS_REQUESTED
  payload: TAdminPayload
}

export interface fetchAuditLogsSucceeded {
  type: typeof FETCH_AUDIT_LOGS_SUCCEEDED
  auditLogs: TAuditLog[]
}

export interface fetchAuditLogsError {
  type: typeof FETCH_AUDIT_LOGS_ERROR
  error: TNetworkError
}

// SET_AUDIT_LOGS_TABLE_FILTER_STATE

export interface setAuditLogsTableFilterState {
  type: typeof SET_AUDIT_LOGS_TABLE_FILTER_STATE
  tableFilterState: TFilterState
}

// SET_AUDIT_LOGS_TABLE_SORTER_STATE

export interface setAuditLogsTableSorterState {
  type: typeof SET_AUDIT_LOGS_TABLE_SORTER_STATE
  tableSorterState: TSorterState
}

export type AuditLogsActionTypes =
  | fetchAuditLogsAction
  | fetchAuditLogsSucceeded
  | fetchAuditLogsError
  | setAuditLogsTableFilterState
  | setAuditLogsTableSorterState
