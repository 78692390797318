import React, { useEffect, useMemo } from 'react'
import { Container } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { CDataTable, CCard, CCol, CCardBody } from '@coreui/react'
import { useParams, useHistory } from 'react-router-dom'

import * as actionTypes from '../../store/action-types'
import { TRootState } from '../../store/reducers'
import {
  convertDateInTableRows,
  convertNumbersInTableRows,
  coverTimestamp,
  getEmptyListMessage,
  makeSearchBoxFixed,
} from '../../utils'

const Orders: React.FC<{}> = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tenant, orderId } = useParams<{ tenant: string; orderId: string }>()

  const ordersState = useSelector((state: TRootState) => state.orders)

  // Redirect on not found order
  useEffect(() => {
    if (ordersState.singleOrderFetchError && ordersState.singleOrderFetchError.status === 404) {
      history.replace(`/${tenant}/admin/orders`)
    }
  }, [dispatch, ordersState.singleOrderFetchError, history, tenant])

  // Make search box fixed
  useEffect(() => {
    makeSearchBoxFixed()
  }, [makeSearchBoxFixed])

  const memoizedTableItems = useMemo(
    () =>
      ordersState?.orders?.map((order: actionTypes.TLightOrder) => {
        return {
          ...order,
          number:
            order?.number || order?.customOrderNumber
              ? `${order?.number}${order?.customOrderNumber && order?.number ? '\n| ' : ''}${
                  order?.customOrderNumber || ''
                }`
              : '',
          createDate: order?.createdDate || '',
          supplierName: order?.supplierName || '',
          bomNames: order?.bomNames?.join(';\n') || '',
          bomElementsQuantity: order?.bomElementsQuantity || '',
          totalPrice: order?.totalPrice || '',
          currency: order?.currency || '',
          deliveries: order?.deliveries || '',
          icon: ordersState.isSingleOrderLoading && Number(orderId) === order.id ? '🔄' : '🖊️',
        }
      }),
    [ordersState.orders, orderId, ordersState.isSingleOrderLoading]
  )

  useEffect(() => {
    convertNumbersInTableRows(ordersState.orders, [6], [5])
    convertDateInTableRows(ordersState.orders, [2])
  }, [memoizedTableItems, ordersState.tableFilterState, ordersState.tableSorterState])

  return (
    <Container
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ cursor: ordersState.isSingleOrderLoading ? 'wait' : 'default' }}
    >
      <CCol xl="10" style={{ minWidth: '1200px' }}>
        <CCard>
          <CCardBody className="pb-2 pt-3">
            <CDataTable
              tableFilterValue={ordersState?.tableFilterState}
              sorterValue={ordersState?.tableSorterState}
              onSorterValueChange={(sorterState: actionTypes.TSorterState) => {
                coverTimestamp(ordersState.orders, [2])

                dispatch({
                  type: actionTypes.SET_ORDERS_TABLE_SORTER_STATE,
                  tableSorterState: sorterState,
                })
              }}
              onTableFilterChange={(tableFilterState: string) => {
                coverTimestamp(ordersState.orders, [2])

                dispatch({
                  type: actionTypes.SET_ORDERS_TABLE_FILTER_STATE,
                  tableFilterState: tableFilterState,
                })
              }}
              loading={ordersState.areOrdersLoading}
              striped={!!ordersState.orders.length}
              border
              sorter
              tableFilter={{ label: ' ', placeholder: 'Szukaj w tabeli...' }}
              cleaner
              addTableClasses="vertical-middle-list-table orders-list-table"
              onRowClick={(order: actionTypes.TLightOrder) => {
                history.push(`/${tenant}/admin/order/${order.id}/general-information`)
              }}
              fields={[
                {
                  key: 'icon',
                  label: '',
                  sorter: false,
                  filter: false,
                  _style: { width: '45px', display: !ordersState.orders.length ? 'none' : '' },
                },

                {
                  key: 'number',
                  label: 'Numer',
                  _style: { width: '180px' },
                },
                {
                  key: 'createDate',
                  label: 'Data utworzenia',
                  _style: { width: '180px' },
                },
                {
                  key: 'supplierName',
                  label: 'Dostawca',
                  _style: { width: '200px' },
                },
                {
                  key: 'bomNames',
                  label: 'Nazwy BOM',
                  _style: { width: '150px' },
                },
                {
                  key: 'bomElementsQuantity',
                  label: 'Ilość elementów',
                  _style: { width: '100px' },
                },
                {
                  key: 'totalPrice',
                  label: 'Wartość',
                  _style: { width: '140px' },
                },
                {
                  key: 'currency',
                  label: 'CCY',
                  _style: { width: '60px' },
                },
                {
                  key: 'deliveries',
                  label: 'Dostawy',
                  _style: { width: '80px' },
                },
              ]}
              items={memoizedTableItems}
              noItemsViewSlot={
                <div className="no-items-in-table">
                  {getEmptyListMessage(
                    ordersState.fetchOrdersError,
                    ordersState.areOrdersLoading,
                    'zamówień',
                    ordersState?.orders?.length
                  )}
                </div>
              }
            />
          </CCardBody>
        </CCard>
      </CCol>
    </Container>
  )
}

export default Orders
